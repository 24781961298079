import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Menu, Item, useContextMenu } from "react-contexify";
import {
  createColumnHelper,
  flexRender,
  useReactTable,
  getCoreRowModel,
  getSortedRowModel,
} from "@tanstack/react-table";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisVertical } from "@fortawesome/free-solid-svg-icons";

import { tokenCheck } from "../../../../utils/tokenCheck";
import SensorInform from "./SensorInform";
import SensorEdit from "./SensorEdit";

const MENU_ID = "dvcList";
const snsrTypes = [
  { value: "01", text: "강우량계" },
  { value: "02", text: "수위계" },
  { value: "03", text: "변위계" },
  { value: "04", text: "토양함수비" },
  { value: "05", text: "거리측정기" },
  { value: "06", text: "적설계" },
  { value: "07", text: "지하수위계" },
  { value: "08", text: "경사계" },
  { value: "09", text: "간극수압계" },
  { value: "10", text: "진동계/진동가속도계" },
  { value: "11", text: "지중경사계" },
  { value: "12", text: "하중계" },
  { value: "13", text: "구조물경사계" },
  { value: "14", text: "GNSS(GPS)" },
  { value: "15", text: "지표변위계" },
  { value: "16", text: "조위계" },
  { value: "17", text: "균열감지기" },
  { value: "18", text: "온도계" },
  { value: "19", text: "습도계" },
  { value: "20", text: "침수감지기" },
  { value: "21", text: "가속도계" },
  { value: "22", text: "변형률계" },
  { value: "23", text: "풍향계" },
  { value: "24", text: "유량계" },
  { value: "25", text: "유속계" },
];

function Sensor({ snsrData, selectId, delData, setDelData, isOk, setIsOk }) {
  const movePage = useNavigate();
  const [isInform, setIsInform] = useState({
    isOpen: false,
    data: {},
  });
  const [isEdit, setIsEdit] = useState({
    isOpen: false,
    data: {},
  });
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);
  const columnHelper = createColumnHelper();
  const checkColumn = {
    id: "check",
    accessor: "check",
    header: "",
    size: 20,
    enableSorting: false,
    cell: ({ row }) => (
      <input
        type="checkbox"
        onChange={() => onChangeCheck(row.original)}
        checked={delData.find(
          (item) => item.groupEqDvcId === row.original.groupEqDvcId
        )}
        style={{ cursor: "pointer" }}
      />
    ),
  };
  const menuIconColumn = {
    id: "menuIcon",
    accessor: "menu",
    header: "",
    size: 20,
    enableSorting: false,
    cell: ({ row }) => (
      <FontAwesomeIcon className="table_icon" icon={faEllipsisVertical} />
    ),
  };
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });
  const { show } = useContextMenu({
    id: MENU_ID,
  });

  function onChangeCheck(row) {
    // 체크 변환.
    if (delData.find((item) => item.groupEqDvcId === row.groupEqDvcId)) {
      // 체크 => 체크해제.
      const newData = delData.filter(
        (item) => item.groupEqDvcId !== row.groupEqDvcId
      );
      setDelData(newData);
    } else {
      // 체크해제 => 체크.
      setDelData([...delData, row]);
    }
  }
  function onClickMenu(e, row) {
    // 메뉴 클릭 시.
    e.stopPropagation();
    show({ event: e, props: { data: row } });
  }
  function menuItemClick({ id, props }) {
    // 메뉴 Item 클릭 시.
    // 토큰 확인
    tokenCheck().then((result) => {
      if (!result) {
        alert("인증 토큰이 만료되었습니다. 다시 로그인 해주세요");
        movePage("/");
      } else {
        switch (id) {
          // 정보.
          case "inform":
            setIsInform({
              isOpen: true,
              data: props.data,
            });
            break;
          // 수정.
          case "edit":
            setIsEdit({
              isOpen: true,
              data: props.data,
            });
            break;
          default:
            break;
        }
      }
    });
  }

  useEffect(() => {
    const column = [
      checkColumn,
      columnHelper.accessor(
        (row) => {
          return row.info.dvcNm;
        },
        {
          id: "dvcNm",
          header: "장비명",
          size: 100,
        }
      ),
      columnHelper.accessor(
        (row) => {
          return snsrTypes.find((type) => type.value === row.info.dvcDtlType)
            ?.text;
        },
        { id: "dvcDtlType", header: "타입", size: 100 }
      ),
      columnHelper.accessor(
        (row) => {
          return row.info.mnfct;
        },
        {
          id: "mnfct",
          header: "제조업체",
          size: 90,
        }
      ),
      columnHelper.accessor(
        (row) => {
          return row.info.modelNm;
        },
        {
          id: "modelNm",
          header: "모델명",
          size: 70,
        }
      ),
      menuIconColumn,
    ];

    setData(snsrData);
    setColumns(column);
  }, [snsrData]);
  useEffect(() => {
    const column = [
      checkColumn,
      columnHelper.accessor(
        (row) => {
          return row.info.dvcNm;
        },
        {
          id: "dvcNm",
          header: "장비명",
          size: 100,
        }
      ),
      columnHelper.accessor(
        (row) => {
          return snsrTypes.find((type) => type.value === row.info.dvcDtlType)
            ?.text;
        },
        { id: "dvcDtlType", header: "타입", size: 100 }
      ),
      columnHelper.accessor(
        (row) => {
          return row.info.mnfct;
        },
        {
          id: "mnfct",
          header: "제조업체",
          size: 90,
        }
      ),
      columnHelper.accessor(
        (row) => {
          return row.info.modelNm;
        },
        {
          id: "modelNm",
          header: "모델명",
          size: 70,
        }
      ),
      menuIconColumn,
    ];

    setColumns(column);
  }, [delData]);

  return (
    <>
      {isInform.isOpen && (
        <SensorInform isOpen={isInform} setIsOpen={setIsInform} />
      )}
      {isEdit.isOpen && (
        <SensorEdit
          isOpen={isEdit}
          setIsOpen={setIsEdit}
          snsrData={snsrData}
          isOk={isOk}
          setIsOk={setIsOk}
        />
      )}
      {data.length > 0 ? (
        <table className="react_table">
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th
                    key={header.id}
                    onClick={header.column.getToggleSortingHandler()}
                    style={{
                      width: isNaN(header.getSize())
                        ? "auto"
                        : `${header.getSize()}px`,
                    }}
                  >
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row, index) => (
              <tr
                key={row.id}
                className={
                  selectId === row.original.eqId
                    ? "tr_select_sub"
                    : index % 2 === 0
                    ? "tr_even"
                    : "tr_odd"
                }
              >
                {row.getVisibleCells().map((cell) => (
                  <td
                    key={cell.id}
                    onClick={(e) => {
                      if (cell.column.id === "check") {
                        e.stopPropagation();
                      }
                      if (cell.column.id === "menuIcon") {
                        onClickMenu(e, row.original);
                      }
                    }}
                    style={{
                      width: isNaN(cell.column.getSize())
                        ? "auto"
                        : `${cell.column.getSize()}px`,
                      maxWidth: isNaN(cell.column.getSize())
                        ? "auto"
                        : `${cell.column.getSize()}px`,
                      minWidth: isNaN(cell.column.getSize())
                        ? "auto"
                        : `${cell.column.getSize()}px`,
                      textAlign:
                        (cell.column.id === "check" ||
                          cell.column.id === "modelNm" ||
                          cell.column.id === "menuIcon") &&
                        "center",
                    }}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <div className="setting_data_none_container">
          <div>등록된 센서가 없습니다</div>
        </div>
      )}

      {/* Context 메뉴 */}
      <Menu id={MENU_ID}>
        <Item id="inform" onClick={menuItemClick}>
          정보
        </Item>
        <Item id="edit" onClick={menuItemClick}>
          수정
        </Item>
      </Menu>
    </>
  );
}

export default Sensor;
