import { dateToStringYYYYMMDD } from "../../utils/dateFormat";

const broadMsg = `주민 여러분께 알려드립니다.
기상청에서 호우주의보가 발령되어 많은 비가 내리고 있어, 광암 저수지 수위상승으로 만수위를 초과하였습니다. 저수지 월류 대비를 위하여 재난방송에 귀기울여 주시기 바라며, 수위 추가 상승 시 대피 할 수 있도록 준비하여 주시기 바랍니다.
다시 한번 알려드립니다.
기상청에서 호우주의보가 발령되어 많은 비가 내리고 있어, 광암 저수지 수위상승으로 만수위를 초과하였습니다. 저수지 월류 대비를 위하여 재난방송에 귀기울여 주시기 바라며, 수위 추가 상승 시 대피 할 수 있도록 준비하여 주시기 바랍니다.`;
const brdHistorySample = [
  {
    id: 1,
    dvcNm: "광암저수지 방송장비",
    title: "저수위 주의",
    msg: broadMsg,
    sendDt: `${dateToStringYYYYMMDD(new Date())} 13:25:02`,
    status: "정상",
  },
  {
    id: 4,
    dvcNm: "백암저수지 방송장비",
    title: "저수위 주의",
    msg: broadMsg,
    sendDt: `${dateToStringYYYYMMDD(new Date())} 12:20:41`,
    status: "정상",
  },
  {
    id: 1,
    dvcNm: "광암저수지 방송장비",
    title: "저수위 주의",
    msg: broadMsg,
    sendDt: `${dateToStringYYYYMMDD(new Date())} 10:40:20`,
    status: "정상",
  },
  {
    id: 3,
    dvcNm: "남정저수지 방송장비",
    title: "저수위 주의",
    msg: broadMsg,
    sendDt: `${dateToStringYYYYMMDD(new Date())} 10:40:12`,
    status: "정상",
  },
];
const menuSample = [
  {
    parentId: "0",
    menuId: "001001000",
    menuNm: "종합현황",
    menuDesc: "종합현황",
    menuLvl: 1,
    orderSeq: 1,
    linkUrl: "/allstatus",
    menuList: [],
  },
  {
    parentId: "0",
    menuId: "003001000",
    menuNm: "SMS",
    menuDesc: "SMS",
    menuLvl: 1,
    orderSeq: 3,
    linkUrl: "/sms",
    menuList: [
      {
        parentId: "003001000",
        menuId: "003001001",
        menuNm: "SMS내역",
        menuDesc: "SMS내역",
        menuLvl: 2,
        orderSeq: 1,
        linkUrl: "/sms",
      },
      {
        parentId: "003001000",
        menuId: "003002001",
        menuNm: "SMS전송",
        menuDesc: "SMS전송",
        menuLvl: 2,
        orderSeq: 2,
        linkUrl: "/sms/send",
      },
    ],
  },
  {
    parentId: "0",
    menuId: "004001000",
    menuNm: "경보방송",
    menuDesc: "경보방송",
    menuLvl: 1,
    orderSeq: 4,
    linkUrl: "/broadcast",
    menuList: [
      {
        parentId: "004001000",
        menuId: "004001001",
        menuNm: "방송전송",
        menuDesc: "방송전송",
        menuLvl: 2,
        orderSeq: 1,
        linkUrl: "/broadcast",
      },
      {
        parentId: "004001000",
        menuId: "004002001",
        menuNm: "방송문안",
        menuDesc: "방송문안",
        menuLvl: 2,
        orderSeq: 2,
        linkUrl: "/broadcast/text",
      },
      {
        parentId: "004001000",
        menuId: "004003001",
        menuNm: "방송내역",
        menuDesc: "방송내역",
        menuLvl: 2,
        orderSeq: 3,
        linkUrl: "/broadcast/list",
      },
    ],
  },
  {
    parentId: "0",
    menuId: "005001000",
    menuNm: "CCTV",
    menuDesc: "CCTV",
    menuLvl: 1,
    orderSeq: 5,
    linkUrl: "/cctv",
    menuList: [],
  },
  {
    parentId: "0",
    menuId: "007001000",
    menuNm: "자료관리",
    menuDesc: "자료관리",
    menuLvl: 1,
    orderSeq: 7,
    linkUrl: "/data",
    menuList: [
      {
        parentId: "007001000",
        menuId: "007001001",
        menuNm: "지하수위자료",
        menuDesc: "지하수위자료",
        menuLvl: 2,
        orderSeq: 1,
        linkUrl: "/data",
      },
      {
        parentId: "007001000",
        menuId: "007002001",
        menuNm: "저수위자료",
        menuDesc: "저수위자료",
        menuLvl: 2,
        orderSeq: 2,
        linkUrl: "/data/reservoir-level",
      },
      {
        parentId: "007001000",
        menuId: "007003001",
        menuNm: "지표변위자료",
        menuDesc: "지표변위자료",
        menuLvl: 2,
        orderSeq: 3,
        linkUrl: "/data/displacement",
      },
      {
        parentId: "007001000",
        menuId: "007004001",
        menuNm: "강우자료",
        menuDesc: "강우자료",
        menuLvl: 2,
        orderSeq: 4,
        linkUrl: "/data/rain",
      },
      {
        parentId: "007001000",
        menuId: "007005001",
        menuNm: "센서그룹핑",
        menuDesc: "센서그룹핑",
        menuLvl: 2,
        orderSeq: 5,
        linkUrl: "/data/sensor-grouping",
      },
    ],
  },
  {
    parentId: "0",
    menuId: "999001000",
    menuNm: "설정",
    menuDesc: "설정",
    menuLvl: 1,
    orderSeq: 99,
    linkUrl: "/setting",
    menuList: [
      {
        parentId: "999001000",
        menuId: "999001001",
        menuNm: "관리지역",
        menuDesc: "관리지역",
        menuLvl: 2,
        orderSeq: 1,
        linkUrl: "/setting",
      },
      {
        parentId: "999001000",
        menuId: "999002001",
        menuNm: "방송그룹",
        menuDesc: "방송그룹",
        menuLvl: 2,
        orderSeq: 2,
        linkUrl: "/setting/broad-group",
      },
      {
        parentId: "999001000",
        menuId: "999003001",
        menuNm: "센서/방송장비",
        menuDesc: "센서/방송장비",
        menuLvl: 2,
        orderSeq: 3,
        linkUrl: "/setting/device",
      },
      {
        parentId: "999001000",
        menuId: "999004001",
        menuNm: "CCTV",
        menuDesc: "CCTV",
        menuLvl: 2,
        orderSeq: 4,
        linkUrl: "/setting/cctv",
      },
      {
        parentId: "999001000",
        menuId: "999005001",
        menuNm: "사용자",
        menuDesc: "사용자",
        menuLvl: 2,
        orderSeq: 5,
        linkUrl: "/setting/user",
      },
    ],
  },
];

export const sampleData = {
  menu: menuSample,
  broadHistory: brdHistorySample,
};
