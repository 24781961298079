// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Noto+Sans+KR&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.react-datepicker {
  font-family: "Noto Sans KR", sans-serif;
}

.react-datepicker__day--mon {
  color: blue;
}`, "",{"version":3,"sources":["webpack://./src/styles/common/datepicker.scss"],"names":[],"mappings":"AAEA;EACE,uCAAA;AAAF;;AAEA;EACE,WAAA;AACF","sourcesContent":["@import url(\"https://fonts.googleapis.com/css2?family=Noto+Sans+KR&display=swap\");\n\n.react-datepicker {\n  font-family: \"Noto Sans KR\", sans-serif;\n}\n.react-datepicker__day--mon {\n  color: blue;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
