import { useState, useEffect } from "react";
import Modal from "react-modal";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";

import { MODALSTYLES } from "../../../constants/modalStyles";
import { saveEdboardMessage } from "../../../services/internal/edboardAPI";
import Alert from "../../common/window/Alert";
import Edit from "../../common/window/Edit";

Modal.setAppElement("#root");

function EditMessage({ isOpen, setIsOpen, setIsChange }) {
  const [effect, setEffect] = useState([]);
  const [speed, setSpeed] = useState([]);
  const [inform, setInform] = useState(isOpen.data);
  const [isInputAlert, setIsInputAlert] = useState(false);
  const [isEditAlert, setIsEditAlert] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  function onClickClose() {
    // 닫기 버튼 클릭 시
    setIsOpen({ isOpen: false, data: {} });
  }
  function onClickEdit() {
    // 수정 버튼 클릭 시
    if (inform.holdTime === "" || inform.msg === "") {
      // 정보 입력X
      setIsInputAlert(true);
    } else {
      // 메시지 수정
      setIsEdit(true);
    }
  }
  function editMessage() {
    // 메시지 수정
    const tokenInform = localStorage.getItem("tokenInform");
    const tokenInformParse = JSON.parse(tokenInform);

    const sendInform = { ...inform, modUsr: tokenInformParse.userId };
    saveEdboardMessage(sendInform).then((result) => {
      if (result === "Success") {
        setIsChange(true);
        setIsOpen(false);
      } else {
        setIsEditAlert(true);
      }
    });
  }

  useEffect(() => {
    const effectArr = [];
    const speedArr = [];

    for (let i = 1; i <= 99; i++) {
      if (i <= 15) {
        effectArr.push(i);
      }
      speedArr.push(i);
    }

    setEffect(effectArr);
    setSpeed(speedArr);

    setInform(isOpen.data);
  }, [isOpen.data]);

  return (
    <Modal isOpen={isOpen.isOpen} style={MODALSTYLES.edboardMsg}>
      {
        // 정보 입력X
        isInputAlert && (
          <Alert
            isOpen={isInputAlert}
            setIsOpen={setIsInputAlert}
            text="모든 정보를 입력해주세요"
          />
        )
      }
      {
        // 메시지 수정
        isEdit && (
          <Edit isOpen={isEdit} setIsOpen={setIsEdit} editData={editMessage} />
        )
      }
      {
        // 메시지 수정 실패
        isEditAlert && (
          <Alert
            isOpen={isEditAlert}
            setIsOpen={setIsEditAlert}
            text="메시지를 수정하는데 실패하였습니다"
          />
        )
      }
      <div className="modal_title">전광판 메시지 수정</div>

      <div className="modal_container">
        <div className="modal_main_column">
          <div className="edboard-message-add-edit-modal">
            <div className="typing-container">
              <div className="title">입장 효과</div>
              <select
                className="one-select"
                value={inform.entryEffect}
                onChange={(e) =>
                  setInform({ ...inform, entryEffect: e.target.value })
                }
              >
                {effect.map((item) => (
                  <option key={item} value={item}>
                    {item}
                  </option>
                ))}
              </select>
            </div>
            <div className="typing-container">
              <div className="title">표시 시간</div>
              <input
                className="time-input"
                type="number"
                placeholder="표시 시간(초) 입력"
                value={inform.holdTime}
                onChange={(e) =>
                  setInform({ ...inform, holdTime: e.target.value })
                }
              />
              <div className="inner-title">속도</div>
              <div className="spd-container">
                <div className="spd-slider">
                  <Slider
                    className="slider"
                    min={1}
                    max={99}
                    step={1}
                    value={inform.speed}
                    onChange={(value) => setInform({ ...inform, speed: value })}
                    trackStyle={{ background: "#418dff" }}
                    handleStyle={{ borderColor: "#000" }}
                  />
                </div>
                <select
                  className="spd-select"
                  value={inform.speed}
                  onChange={(e) =>
                    setInform({ ...inform, speed: e.target.value })
                  }
                >
                  {speed.map((item) => (
                    <option key={item} value={item}>
                      {item}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="typing-container flex-typing-container">
              <div className="title">내용</div>
              <textarea
                type="text"
                placeholder="내용 입력"
                value={inform.msg}
                onChange={(e) => setInform({ ...inform, msg: e.target.value })}
              />
            </div>
            <div className="typing-container last-typing-container">
              <div className="title">퇴장 효과</div>
              <select
                className="one-select"
                value={inform.exitEffect}
                onChange={(e) =>
                  setInform({ ...inform, exitEffect: e.target.value })
                }
              >
                {effect.map((item) => (
                  <option key={item} value={item}>
                    {item}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>

        <div className="modal_footer">
          <div className="button-nor-modal" onClick={onClickClose}>
            닫기
          </div>
          <div className="button-imp right-button" onClick={onClickEdit}>
            수정
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default EditMessage;
