import { useState, useEffect } from "react";

import { reservoirs } from "../../constants/latlon";
import MapType from "../map/MapType";

const { kakao } = window;

function AllMap({ setSelected }) {
  const [mapType, setMapType] = useState("satellite");
  const [map, setMap] = useState(null);

  function clickReservoir(item) {
    // 저수지리스트 및 오버레이 클릭 시
    setSelected(item);
  }

  useEffect(() => {
    const container = document.getElementById("allstatus-all-kakao-map");
    const options = {
      center: new kakao.maps.LatLng(35.451678, 127.066541),
      level: 8,
      disableDoubleClickZoom: true,
    };
    const defaultMap = new kakao.maps.Map(container, options);

    defaultMap.setMapTypeId(kakao.maps.MapTypeId.SKYVIEW);
    defaultMap.setDraggable(false); // 드래그 막기
    defaultMap.setZoomable(false); // 확대/축소 막기

    setMap(defaultMap);
  }, []);
  useEffect(() => {
    /* 지도 위성/일반 변경 */
    if (map !== null) {
      switch (mapType) {
        case "satellite":
          map.setMapTypeId(kakao.maps.MapTypeId.SKYVIEW);
          break;
        case "normal":
          map.setMapTypeId(kakao.maps.MapTypeId.ROADMAP);
          break;
        default:
          break;
      }
    }
  }, [mapType]);
  useEffect(() => {
    if (map !== null) {
      /* 마커 표시 */
      reservoirs.forEach((item) => {
        // 위치
        const reservoirPosition = new kakao.maps.LatLng(item.lat, item.lon);
        // 오버레이
        function click() {
          clickReservoir(item);
        }
        const reservoirContent = document.createElement("div");
        reservoirContent.className = "reservoir-map-overlay";
        reservoirContent.onclick = click;
        const reservoirName = document.createElement("div");
        reservoirName.className = "name";
        reservoirName.appendChild(document.createTextNode(item.name));
        const reservoirAddr = document.createElement("div");
        reservoirAddr.className = "addr";
        reservoirAddr.appendChild(document.createTextNode(item.addr));
        reservoirContent.appendChild(reservoirName);
        reservoirContent.appendChild(reservoirAddr);

        const reservoirOverlay = new kakao.maps.CustomOverlay({
          position: reservoirPosition,
          content: reservoirContent,
        });
        reservoirOverlay.setMap(map);
      });
    }
  }, [map]);

  return (
    <div id="allstatus-map-component">
      <div id="allstatus-all-kakao-map" />
      <MapType mapType={mapType} setMapType={setMapType} />
      <div className="map-reservoir-name">순창군</div>
    </div>
  );
}

export default AllMap;
