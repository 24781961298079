import { useState, useEffect } from "react";

import { getSatellite, getRadar } from "../../services/external/kmaAPI";

function SatelliteRadar() {
  const [type, setType] = useState("satellite");
  const [sateImg, setSateImg] = useState("");
  const [radarImg, setRadarImg] = useState("");

  useEffect(() => {
    getSatellite().then((satellite) => {
      getRadar().then((radar) => {
        setSateImg(satellite);
        setRadarImg(radar);
      });
    });

    const intervalId = setInterval(() => {
      getSatellite().then((satellite) => {
        getRadar().then((radar) => {
          setSateImg(satellite);
          setRadarImg(radar);
        });
      });
    }, 1800000); // 30분
    return () => clearInterval(intervalId);
  }, []);

  return (
    <div id="allstatus-sate-radar-component">
      {/* 선택 */}
      <div className="select-container">
        <div
          className={
            type === "satellite"
              ? "select-item select-item-select left"
              : "select-item left"
          }
          onClick={() => setType("satellite")}
        >
          위성
        </div>
        <div
          className={
            type === "radar"
              ? "select-item select-item-select right"
              : "select-item right"
          }
          onClick={() => setType("radar")}
        >
          레이더
        </div>
      </div>

      {/* 이미지 */}
      <div className="img-container">
        {
          /* 위성 */
          type === "satellite" ? (
            // 이미지 X
            sateImg === "" ? (
              <div className="data-none-container">
                자료 조회에 실패하였습니다
              </div>
            ) : (
              // 이미지 O
              <img src={sateImg} alt="" />
            )
          ) : (
            /* 레이더 */
            type === "radar" &&
            // 이미지 X
            (radarImg === "" ? (
              <div className="data-none-container">
                자료 조회에 실패하였습니다
              </div>
            ) : (
              // 이미지 O
              <img src={radarImg} alt="" />
            ))
          )
        }
      </div>
    </div>
  );
}

export default SatelliteRadar;
