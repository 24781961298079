import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setMenu, setSubmenu } from "../../../redux/modules/menu";
import { useNavigate } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome, faDesktop, faList } from "@fortawesome/free-solid-svg-icons";

import { tokenCheck } from "../../../utils/tokenCheck";
import { getEdboardMessageLog } from "../../../services/internal/edboardAPI";
import Loading from "../../../components/common/window/Loading";
import EdboardCurr from "../../../components/edboard/list/EdboardCurr";
import EdboardHistory from "../../../components/edboard/list/EdboardHistory";

const PAGE_VALUE = "전광판";
const SUBPAGE_VALUE = "전광판내역";

function EdboardList() {
  const dispatch = useDispatch();
  const [isToken, setIsToken] = useState(false);
  const movePage = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [edbData, setEdbData] = useState([]);
  const [selectedEdb, setSelectedEdb] = useState({});

  useEffect(() => {
    dispatch(setMenu(PAGE_VALUE));
    dispatch(setSubmenu(SUBPAGE_VALUE));

    // 토큰 확인
    tokenCheck().then((result) => {
      if (!result) {
        alert("인증 토큰이 만료되었습니다. 다시 로그인 해주세요");
        movePage("/");
      } else {
        setIsToken(true);
        setIsLoading(true);
        getEdboardMessageLog().then((response) => {
          setIsLoading(false);
          setEdbData(response);

          if (Array.isArray(response) && response.length > 0) {
            setSelectedEdb(response[0]);
          } else {
            setSelectedEdb({});
          }
        });
      }
    });
  }, []);

  return (
    <div className="outlet_container">
      {
        // 로딩화면
        isLoading && <Loading isOpen={isLoading} />
      }
      {isToken && (
        <div id="edboard-list-page">
          <div className="header_container">
            <div className="title">
              <div className="title_imp">전광판 내역</div>
            </div>
            <div className="path">
              <FontAwesomeIcon icon={faHome} />
              <div className="text">&gt; 전광판 &gt; 전광판내역</div>
            </div>
          </div>

          <div className="main_container_row">
            {/* 전광판 현황 */}
            <div className="edboard-list-curr contents_container">
              <div className="contents_header">
                <div className="title">
                  <FontAwesomeIcon icon={faDesktop} />
                  <div className="text">전광판 현황</div>
                </div>
              </div>

              <div className="contents_main">
                <EdboardCurr
                  edbData={edbData}
                  selected={selectedEdb}
                  setSelected={setSelectedEdb}
                />
              </div>

              <div className="contents_footer"></div>
            </div>

            {/* 전광판 내역 */}
            <div className="edboard-list-history contents_container">
              <div className="contents_header">
                <div className="title">
                  <FontAwesomeIcon icon={faList} />
                  <div className="text">전광판 내역</div>
                </div>
              </div>

              <div className="contents_main">
                {edbData.length > 0 && (
                  <EdboardHistory selected={selectedEdb} />
                )}
              </div>

              <div className="contents_footer"></div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default EdboardList;
