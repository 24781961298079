import React, { useEffect, useState } from "react";
import {
  createColumnHelper,
  flexRender,
  useReactTable,
  getCoreRowModel,
  getSortedRowModel,
} from "@tanstack/react-table";
import Modal from "react-modal";
import axios from "axios";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";

import apiAddress from "../../../services/apiAddress";
import Reg from "../../common/window/Reg";
import RtuInform from "../../common/inform/RtuInform";
import EdboardInform from "../../common/inform/EdboardInform";
import CCTVInform from "../../common/inform/CCTVInform";
import EquipRegDevice from "./EquipRegDevice";

Modal.setAppElement("#root");

function EquipReg({ isOpen, setIsOpen, selectedId, isOk, setIsOk }) {
  const [regData, setRegData] = useState([]);
  const [type, setType] = useState("RTU");
  const [isInform, setIsInform] = useState({
    isOpen: false,
    data: {},
  });
  const [isReg, setIsReg] = useState({
    isOpen: false,
    text: "",
  });
  const [rtuData, setRtuData] = useState([]);
  const [edbData, setEdbData] = useState([]);
  const [cctvData, setCctvData] = useState([]);
  const [cctvDtlData, setCctvDtlData] = useState([]);
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);
  const columnHelper = createColumnHelper();
  const checkColumn = {
    id: "check",
    accessor: "check",
    header: "",
    size: 20,
    enableSorting: false,
    cell: ({ row }) => (
      <input
        type="checkbox"
        checked={
          regData.length > 0 &&
          regData.find((item) => item.eqId === row.original.eqId)
        }
        onChange={(e) => onChangeCheck(row.original)}
        style={{ cursor: "pointer" }}
      />
    ),
  };
  const angleIconColumn = {
    id: "angleIcon",
    accessor: "angle",
    header: "",
    enableSorting: false,
    size: 10,
    cell: ({ row }) => {
      return row.original.angle ? (
        <FontAwesomeIcon icon={faAngleUp} />
      ) : (
        <FontAwesomeIcon icon={faAngleDown} />
      );
    },
  };
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  function onChangeCheck(row) {
    // 체크에 따른 이벤트.
    if (regData.find((item) => item.eqId === row.eqId)) {
      // 체크 => 체크해제.
      const clearData = regData.filter((item) => item.eqId !== row.eqId);
      setRegData(clearData);
    } else {
      // 체크해제 => 체크.
      setRegData([
        ...regData,
        { rgnId: selectedId, eqId: row.eqId, useYn: "Y", regUsr: "SH" },
      ]);
    }
  }
  function onClickRow(row) {
    // 행 클릭.
    const newData = rtuData.map((item) => {
      if (item.eqId === row.eqId) {
        return { ...item, angle: !row.angle };
      } else {
        return item;
      }
    });
    setData(newData);
  }
  function onClickClose() {
    // 닫기.
    setIsOpen(false);
  }
  function onClickReg() {
    // 등록.
    setIsReg({
      isOpen: true,
      text: regData.length + "개의 장비를 등록하시겠습니까?",
    });
  }
  /* type에 따라 데이터 분류 */
  function classificationData(type) {
    switch (type) {
      // RTU
      case "RTU":
        const columnRtu = [
          checkColumn,
          columnHelper.accessor("eqNm", {
            header: "장비명",
            size: 100,
          }),
          columnHelper.accessor("bdongCd", {
            header: "법정동코드",
            size: 80,
          }),
          columnHelper.accessor("addr", {
            header: "주소",
            size: "auto",
          }),
          angleIconColumn,
        ];
        setData(rtuData);
        setColumns(columnRtu);
        break;
      // 전광판
      case "EDB":
        const columnEdb = [
          checkColumn,
          columnHelper.accessor("eqNm", {
            header: "장비명",
            size: 100,
          }),
          columnHelper.accessor("bdongCd", {
            header: "법정동코드",
            size: 80,
          }),
          columnHelper.accessor("addr", {
            header: "주소",
            size: "auto",
          }),
        ];
        setData(edbData);
        setColumns(columnEdb);
        break;
      // CCTV
      case "CCTV":
        // equip_inf, cctv_inf 데이터 합치기.
        const cctvTotalData = cctvData.map((equip) => {
          const cctvDataItem = cctvDtlData.find(
            (dtl) => equip.eqId === dtl.eqId
          );

          if (cctvDataItem) {
            return { ...equip, ...cctvDataItem };
          }
          return equip;
        });
        const columnCctv = [
          checkColumn,
          columnHelper.accessor("eqNm", {
            header: "장비명",
            size: 100,
          }),
          columnHelper.accessor("bdongCd", {
            header: "법정동코드",
            size: 80,
          }),
          columnHelper.accessor("addr", {
            header: "주소",
            size: "auto",
          }),
        ];
        setData(cctvTotalData);
        setColumns(columnCctv);
        break;
      default:
        break;
    }
  }

  /* 관리지역 장비 미등록 조회 API */
  async function fetchData() {
    try {
      // equip_inf 조회.
      axios
        .post(
          apiAddress.regionEquipGet,
          {
            rgnId: 0,
            searchType: "NA",
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          const responseData = response.data;

          const reservoirEqId = [];
          for (let i = 26; i <= 61; i++) {
            reservoirEqId.push(i);
          }
          const eqData = responseData.filter((item) =>
            reservoirEqId.includes(parseInt(item.eqId))
          );
          // 처음 RTU 데이터로 초기화.
          const rtuEquip = eqData
            .filter((item) => item.eqType === "RTU")
            .map((item2) => {
              return { ...item2, angle: false };
            });
          const edb = eqData.filter((item) => item.eqType === "EDB");
          const cctv = eqData.filter((item) => item.eqType === "CCTV");
          const column = [
            checkColumn,
            columnHelper.accessor("eqNm", {
              header: "장비명",
              size: 100,
            }),
            columnHelper.accessor("bdongCd", {
              header: "법정동코드",
              size: 80,
            }),
            columnHelper.accessor("addr", {
              header: "주소",
              size: "auto",
            }),
            angleIconColumn,
          ];

          const fetchDataDvc = async (item) => {
            try {
              const response = await axios.post(
                apiAddress.deviceGet,
                {
                  eqId: item.eqId,
                },
                {
                  headers: {
                    "Content-Type": "application/json",
                  },
                }
              );
              const responseData = response.data.filter(
                (device) => device.useYn === "Y"
              );
              return { ...item, dvcData: responseData };
            } catch (e) {
              console.log(e);
              return null;
            }
          };
          const fetchRtuDvc = async () => {
            const rtu = await Promise.all(rtuEquip.map(fetchDataDvc));

            setRtuData(rtu);
            setEdbData(edb);
            setCctvData(cctv);

            setData(rtu);
            setColumns(column);
          };

          fetchRtuDvc();
        });
      // cctv_inf 조회.
      axios
        .post(
          apiAddress.cctvGet,
          { cctvInfId: 0 },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          const responseData = response.data;
          setCctvDtlData(responseData);
        });
    } catch (e) {
      console.log(e);
    }
  }
  /* 관리지역 장비 추가 API */
  async function fetchDataReg() {
    try {
      axios
        .post(apiAddress.regionEquipSave, regData, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          setIsOk(!isOk);
          setIsOpen(false);
        });
    } catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    fetchData();
  }, []);
  useEffect(() => {
    classificationData(type);
  }, [type]);
  useEffect(() => {
    if (type === "RTU") {
      const column = [
        checkColumn,
        columnHelper.accessor("eqNm", {
          header: "장비명",
          size: 100,
        }),
        columnHelper.accessor("bdongCd", {
          header: "법정동코드",
          size: 80,
        }),
        columnHelper.accessor("addr", {
          header: "주소",
          size: "auto",
        }),
        angleIconColumn,
      ];
      setColumns(column);
    } else {
      const column = [
        checkColumn,
        columnHelper.accessor("eqNm", {
          header: "장비명",
          size: 100,
        }),
        columnHelper.accessor("bdongCd", {
          header: "법정동코드",
          size: 80,
        }),
        columnHelper.accessor("addr", {
          header: "주소",
          size: "auto",
        }),
      ];
      setColumns(column);
    }
  }, [data, regData]);

  return (
    <Modal isOpen={isOpen} style={modalStyles}>
      {isReg.isOpen && (
        <Reg isOpen={isReg} setIsOpen={setIsReg} regData={fetchDataReg} />
      )}
      <div className="modal_title">
        <div>장비 추가 등록</div>
      </div>
      <div className="setting_rgn_dvc_reg_type_container">
        <div
          className={type === "RTU" ? "select_button" : "button"}
          onClick={() => setType("RTU")}
        >
          RTU
        </div>
        <div
          className={type === "EDB" ? "select_button" : "button"}
          onClick={() => setType("EDB")}
          style={{ marginLeft: "5px" }}
        >
          전광판
        </div>
        <div
          className={type === "CCTV" ? "select_button" : "button"}
          onClick={() => setType("CCTV")}
          style={{ marginLeft: "5px" }}
        >
          CCTV
        </div>
      </div>
      <div
        className="modal_container"
        style={{ height: "calc(100% - 110px)", padding: "0px 20px 20px 20px" }}
      >
        {/* Main */}
        <div
          className="modal_main_column"
          style={{ overflowX: "hidden", overflowY: "auto" }}
        >
          {data.length === 0 ? (
            <div className="setting_data_none_container">
              등록할 장비가 없습니다
            </div>
          ) : (
            <table className="react_table">
              <thead>
                {table.getHeaderGroups().map((headerGroup) => (
                  <tr key={headerGroup.id}>
                    {headerGroup.headers.map((header) => (
                      <th
                        key={header.id}
                        style={{
                          width: isNaN(header.getSize())
                            ? "auto"
                            : `${header.getSize()}px`,
                          minWidth: isNaN(header.getSize())
                            ? "auto"
                            : `${header.getSize()}px`,
                          maxWidth: isNaN(header.getSize())
                            ? "auto"
                            : `${header.getSize()}px`,
                        }}
                      >
                        {header.isPlaceholder
                          ? null
                          : flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody>
                {table.getRowModel().rows.map((row, index) => (
                  <React.Fragment key={row.id}>
                    <tr
                      key={row.id}
                      onClick={() => {
                        if (type === "RTU") {
                          onClickRow(row.original);
                        }
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      {row.getVisibleCells().map((cell) => (
                        <td
                          key={cell.id}
                          onClick={(e) => {
                            if (cell.column.id === "check") {
                              e.stopPropagation();
                            }
                          }}
                          style={{
                            width: isNaN(cell.column.getSize())
                              ? "auto"
                              : `${cell.column.getSize()}px`,
                            minWidth: isNaN(cell.column.getSize())
                              ? "auto"
                              : `${cell.column.getSize()}px`,
                            maxWidth: isNaN(cell.column.getSize())
                              ? "auto"
                              : `${cell.column.getSize()}px`,
                            textAlign:
                              (cell.column.id === "check" ||
                                cell.column.id === "bdongCd" ||
                                cell.column.id === "angleIcon") &&
                              "center",
                          }}
                        >
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </td>
                      ))}
                    </tr>
                    {type === "RTU" &&
                      row.original.angle &&
                      row.original.dvcData.length > 0 && (
                        <tr key="sub_table-tr">
                          <td
                            className="sub_table-td"
                            colSpan={5}
                            style={{ padding: "0" }}
                          >
                            <EquipRegDevice dvcData={row.original.dvcData} />
                          </td>
                        </tr>
                      )}
                  </React.Fragment>
                ))}
              </tbody>
            </table>
          )}
        </div>

        {/* Footer */}
        <div className="modal_footer">
          <div className="button_nor" onClick={onClickClose}>
            닫기
          </div>
          {regData.length > 0 && (
            <div
              className="button_imp"
              onClick={onClickReg}
              style={{ marginLeft: "10px" }}
            >
              등록
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
}

const modalStyles = {
  content: {
    width: "650px",
    height: "650px",
    padding: "0px",
    background: "#202020",
    border: "none",
    borderRadius: "5px",
    boxShadow: "-5px 5px 10px rgba(0, 0, 0, 0.7)",
    margin: "auto",
  },
  overlay: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "fixed",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: "rgba(0, 0, 0, 0.4)",
  },
};

export default EquipReg;
