import { useState, useEffect } from "react";
import {
  createColumnHelper,
  flexRender,
  useReactTable,
  getCoreRowModel,
  getSortedRowModel,
} from "@tanstack/react-table";

function BroadList() {
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);
  const columnHelper = createColumnHelper();
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  useEffect(() => {
    const sampleData = [
      {
        brdTitle: "시험방송문구",
        brdStt: "2024-01-11 17:49:55",
        brdFin: "2024-01-11 17:49:55",
        brdCntn:
          "재난안전대책본부에서 알려드립니다. 비상예경보 방송 시스템 성능 검사를 위하여 시험방송 중이오니 주민 여러분께서는 양해하여 주시기 바랍니다.",
        brdUsr: "SH",
        brdCon: "정상 3/3",
      },
      {
        brdTitle: "시험방송문구",
        brdStt: "2024-01-11 11:19:20",
        brdFin: "2024-01-11 11:19:20",
        brdCntn:
          "재난안전대책본부에서 알려드립니다. 비상예경보 방송 시스템 성능 검사를 위하여 시험방송 중이오니 주민 여러분께서는 양해하여 주시기 바랍니다.",
        brdUsr: "SYSTEM",
        brdCon: "에러 0/1",
      },
    ];
    const column = [
      columnHelper.accessor("brdTitle", {
        header: "방송제목",
      }),
      columnHelper.accessor("brdStt", {
        header: "방송시작",
      }),
      columnHelper.accessor("brdFin", {
        header: "방송종료",
      }),
      columnHelper.accessor("brdCntn", {
        header: "내용",
      }),
      columnHelper.accessor("brdUsr", {
        header: "사용자",
      }),
      columnHelper.accessor("brdCon", {
        header: "처리상태",
      }),
    ];

    setData(sampleData);
    setColumns(column);
  }, []);

  return (
    <table className="react_table">
      <thead>
        {table.getHeaderGroups().map((headerGroup) => (
          <tr key={headerGroup.id}>
            {headerGroup.headers.map((header) => (
              <th
                key={header.id}
                onClick={header.column.getToggleSortingHandler()}
                style={{
                  width: isNaN(header.getSize())
                    ? "auto"
                    : `${header.getSize()}px`,
                }}
              >
                {header.isPlaceholder
                  ? null
                  : flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody>
        {table.getRowModel().rows.map((row, index) => (
          <tr key={row.id} className={index % 2 === 0 ? "tr_even" : "tr_odd"}>
            {row.getVisibleCells().map((cell) => (
              <td
                key={cell.id}
                style={{
                  width: isNaN(cell.column.getSize())
                    ? "auto"
                    : `${cell.column.getSize()}px`,
                  textAlign:
                    (cell.column.id === "brdStt" ||
                      cell.column.id === "brdFin" ||
                      cell.column.id === "brdUsr" ||
                      cell.column.id === "brdCon") &&
                    "center",
                  color:
                    cell.column.id === "brdCon" &&
                    row.original.brdCon.includes("에러") &&
                    "#ED0000",
                }}
              >
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
}

export default BroadList;
