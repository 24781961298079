import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  createColumnHelper,
  flexRender,
  useReactTable,
  getCoreRowModel,
  getSortedRowModel,
} from "@tanstack/react-table";
import { Menu, Item, useContextMenu } from "react-contexify";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisVertical } from "@fortawesome/free-solid-svg-icons";

import { tokenCheck } from "../../../../utils/tokenCheck";
import { dateToFormat } from "../../../../utils/dateFormat";
import { RTUOPTIONS } from "../../../../constants/rtuOptions";
import { getGroupEquip } from "../../../../services/internal/groupAPI";
import { getRegionEquip } from "../../../../services/internal/regionAPI";
import { getDevice } from "../../../../services/internal/deviceAPI";
import Alert from "../../../common/window/Alert";
import RtuInform from "../../../common/inform/RtuInform";
import RtuEdit from "./RtuEdit";
import Delete from "../../../common/window/Delete";
import { saveEquip } from "../../../../services/internal/equipAPI";

const MENU_ID = "rtuList";

function RtuList({ rtuData, setIsChange, selected, setSelected }) {
  const movePage = useNavigate();
  const [isInform, setIsInform] = useState({
    isOpen: false,
    data: {},
  });
  const [isEdit, setIsEdit] = useState({
    isOpen: false,
    data: {},
  });
  const [isGroupAlert, setIsGroupAlert] = useState(false);
  const [isDeviceAlert, setIsDeviceAlert] = useState(false);
  const [isDel, setIsDel] = useState({
    isOpen: false,
    text: "",
    data: {},
  });
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);
  const columnHelper = createColumnHelper();
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });
  const menuIconColumn = {
    id: "menuIcon",
    accessor: "menu",
    header: "",
    size: 20,
    enableSorting: false,
    cell: ({ row }) => (
      <FontAwesomeIcon className="table_icon" icon={faEllipsisVertical} />
    ),
  };
  const { show } = useContextMenu({
    id: MENU_ID,
  });

  function onClickRow(row) {
    // 행 클릭시
    setSelected(row.original);
  }
  function onClickMenu(e, row) {
    // 메뉴 클릭 시.
    e.stopPropagation();
    show({ event: e, props: { data: row } });
  }
  function menuItemClick({ id, props }) {
    // 메뉴 Item 클릭 시.
    // 토큰 확인
    tokenCheck().then((result) => {
      if (!result) {
        alert("인증 토큰이 만료되었습니다. 다시 로그인 해주세요");
        movePage("/");
      } else {
        switch (id) {
          // 정보.
          case "inform":
            setIsInform({
              isOpen: true,
              data: props.data,
            });
            break;
          case "edit":
            setIsEdit({
              isOpen: true,
              data: props.data,
            });
            break;
          case "delete":
            rtuDelCheck(props.data);
            break;
          default:
            break;
        }
      }
    });
  }
  function rtuDelCheck(data) {
    getGroupEquip().then((response) => {
      if (response.find((item) => item.eqId === data.eqId)) {
        // 등록되어 있는 그룹 존재
        setIsGroupAlert(true);
      } else {
        getRegionEquip().then((response) => {
          if (!response.find((item) => item.eqId === data.eqId)) {
            // 등록되어 있는 지역 존재
            setIsGroupAlert(true);
          } else {
            getDevice(data.eqId).then((response) => {
              if (response.length > 0) {
                // 해당 RTU에 소속되어 있는 장비 존재
                setIsDeviceAlert(true);
              } else {
                // 삭제 가능
                const tokenInform = localStorage.getItem("tokenInform");
                const tokenInformParse = JSON.parse(tokenInform);
                const delData = {
                  ...data,
                  modUsr: tokenInformParse.userId,
                  useYn: "D",
                };
                setIsDel({
                  isOpen: true,
                  text: "'" + data.eqNm + "' RTU를 삭제하시겠습니까?",
                  data: delData,
                });
              }
            });
          }
        });
      }
    });
  }
  function deleteRtu(data) {
    saveEquip(data).then((response) => {
      setIsChange(true);
    });
  }

  useEffect(() => {
    const column = [
      columnHelper.accessor("eqNm", { header: "RTU명" }),
      columnHelper.accessor(
        (row) => {
          return dateToFormat(row.instlDt);
        },
        { id: "instlDt", header: "설치일자", size: 50 }
      ),
      columnHelper.accessor(
        (row) => {
          return RTUOPTIONS.connectType.find(
            (item) => item.value === row.commType
          )?.text;
        },
        { id: "commType", header: "통신방식", size: 50 }
      ),
      columnHelper.accessor("commAddr", { header: "통신정보", size: 100 }),
      columnHelper.accessor("bdongCd", { header: "법정동코드", size: 70 }),
      columnHelper.accessor("addr", { header: "주소" }),
      menuIconColumn,
    ];

    setData(rtuData);
    setColumns(column);
  }, [rtuData]);

  return (
    <div id="setting-rtu-list-component">
      {
        // RTU 정보
        isInform.isOpen && (
          <RtuInform isOpen={isInform} setIsOpen={setIsInform} />
        )
      }
      {
        // RTU 수정
        isEdit.isOpen && (
          <RtuEdit
            isOpen={isEdit}
            setIsOpen={setIsEdit}
            setIsChange={setIsChange}
          />
        )
      }
      {
        // 등록되어 있는 그룹 및 지역 존재, RTU 삭제 불가
        isGroupAlert && (
          <Alert
            isOpen={isGroupAlert}
            setIsOpen={setIsGroupAlert}
            text="먼저 등록되어 있는 그룹 및 지역에서부터 삭제해주세요"
          />
        )
      }
      {
        // 해당 RTU에 등록되어 있는 장비 존재, RTU 삭제 불가
        isDeviceAlert && (
          <Alert
            isOpen={isDeviceAlert}
            setIsOpen={setIsDeviceAlert}
            text="먼저 해당 RTU에 등록되어 있는 장비부터 삭제해주세요"
          />
        )
      }
      {
        // RTU 삭제
        isDel.isOpen && (
          <Delete
            isOpen={isDel}
            setIsOpen={setIsDel}
            delData={() => deleteRtu(isDel.data)}
          />
        )
      }
      {data.length === 0 ? (
        <div className="setting-data-none-container">등록된 RTU가 없습니다</div>
      ) : (
        <div className="list">
          <table className="react_table">
            <thead>
              {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <th
                      key={header.id}
                      onClick={header.column.getToggleSortingHandler()}
                      style={{
                        width: isNaN(header.getSize())
                          ? "auto"
                          : `${header.getSize()}px`,
                      }}
                    >
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody>
              {table.getRowModel().rows.map((row, index) => (
                <tr
                  key={row.id}
                  className={
                    selected === row.original
                      ? "tr_select"
                      : index % 2 === 0
                      ? "tr_even"
                      : "tr_odd"
                  }
                  onClick={() => onClickRow(row)}
                >
                  {row.getVisibleCells().map((cell) => (
                    <td
                      key={cell.id}
                      onClick={(e) => {
                        if (cell.column.id === "menuIcon") {
                          onClickMenu(e, row.original);
                        }
                      }}
                      style={{
                        width: isNaN(cell.column.getSize())
                          ? "auto"
                          : `${cell.column.getSize()}px`,
                        textAlign:
                          (cell.column.id === "instlDt" ||
                            cell.column.id === "commType" ||
                            cell.column.id === "bdongCd" ||
                            cell.column.id === "menuIcon") &&
                          "center",
                      }}
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      {/* Context 메뉴 */}
      <Menu id={MENU_ID}>
        <Item id="inform" onClick={menuItemClick}>
          정보
        </Item>
        <Item id="edit" onClick={menuItemClick}>
          수정
        </Item>
        <Item id="delete" onClick={menuItemClick}>
          삭제
        </Item>
      </Menu>
    </div>
  );
}

export default RtuList;
