import React, { useState, useEffect } from "react";
import {
  createColumnHelper,
  flexRender,
  useReactTable,
  getCoreRowModel,
  getSortedRowModel,
} from "@tanstack/react-table";
import axios from "axios";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleUp, faAngleDown } from "@fortawesome/free-solid-svg-icons";

import apiAddress from "../../../services/apiAddress";
import BroadDevice from "./BroadDevice";

function Broad({ selectedId, isRegOk, isDelOk, delData, setDelData }) {
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);
  const columnHelper = createColumnHelper();
  const checkColumn = {
    id: "check",
    accessor: "check",
    header: "",
    enableSorting: false,
    size: 10,
    cell: ({ row }) => (
      <input
        type="checkbox"
        onChange={() => onChangeCheck(row.original)}
        checked={delData.find((item) => item === row.original.groupEqId)}
        style={{ cursor: "pointer" }}
      />
    ),
  };
  const angleIconColumn = {
    id: "angleIcon",
    accessor: "angle",
    header: "",
    enableSorting: false,
    size: 10,
    cell: ({ row }) => {
      return row.original.angle ? (
        <FontAwesomeIcon icon={faAngleUp} />
      ) : (
        <FontAwesomeIcon icon={faAngleDown} />
      );
    },
  };
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  function onChangeCheck(row) {
    // 체크 선택시.
    if (delData.find((item) => item === row.groupEqId)) {
      const newData = delData.filter((item) => item !== row.groupEqId);
      setDelData(newData);
    } else {
      setDelData([...delData, row.groupEqId]);
    }
  }
  function onClickRow(row) {
    // 행 클릭.
    const newData = data.map((item) => {
      if (item.eqId === row.eqId) {
        return { ...item, angle: !item.angle };
      }
      return item;
    });

    setData(newData);
  }

  /* 방송그룹 장비 조회 API */
  async function fetchData() {
    try {
      axios
        .post(
          apiAddress.groupEquipGet,
          {
            groupId: selectedId,
            searchType: "A",
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          // RTU 데이터 가공.
          const rtuResponse = response.data.filter(
            (item) => item.eqType === "RTU" && item.useYn === "Y"
          );
          const rtu = rtuResponse.map((item) => {
            return { ...item, angle: false };
          });

          // 장비 데이터 조회.
          const fetchDataDev = async (item) => {
            try {
              const response = await axios.post(
                apiAddress.deviceGet,
                {
                  eqId: item.eqId,
                },
                {
                  headers: {
                    "Content-Type": "application/json",
                  },
                }
              );

              const responseData = response.data.filter(
                (device) => device.dvcType === "BRD" && device.useYn === "Y"
              );
              return { ...item, brdData: responseData };
            } catch (e) {
              console.log(e);
              return null;
            }
          };

          const fetchRtuDevice = async () => {
            // equip_inf + dvc_inf.
            const rtuBrd = await Promise.all(rtu.map(fetchDataDev));
            // Column.
            const column = [
              checkColumn,
              columnHelper.accessor("eqNm", {
                header: "RTU명",
                size: 170,
              }),
              columnHelper.accessor("commType", {
                header: "통신",
                size: 90,
              }),
              columnHelper.accessor("commAddr", {
                header: "통신정보",
                size: 90,
              }),
              columnHelper.accessor("bdongCd", {
                header: "법정동코드",
                size: 80,
              }),
              columnHelper.accessor("addr", {
                header: "주소",
                size: "auto",
              }),
              angleIconColumn,
            ];

            setData(rtuBrd);
            setColumns(column);
          };

          fetchRtuDevice();
        });
    } catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    fetchData();
  }, [selectedId, isRegOk, isDelOk]);
  useEffect(() => {
    const column = [
      checkColumn,
      columnHelper.accessor("eqNm", {
        header: "RTU명",
        size: 170,
      }),
      columnHelper.accessor("commType", {
        header: "통신",
        size: 90,
      }),
      columnHelper.accessor("commAddr", {
        header: "통신정보",
        size: 90,
      }),
      columnHelper.accessor("bdongCd", {
        header: "법정동코드",
        size: 80,
      }),
      columnHelper.accessor("addr", {
        header: "주소",
        size: "auto",
      }),
      angleIconColumn,
    ];
    setColumns(column);
  }, [delData]);

  return (
    <>
      {data.length > 0 ? (
        <table className="react_table">
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th
                    key={header.id}
                    style={{
                      width: isNaN(header.getSize())
                        ? "auto"
                        : `${header.getSize()}px`,
                      minWidth: isNaN(header.getSize())
                        ? "auto"
                        : `${header.getSize()}px`,
                      maxWidth: isNaN(header.getSize())
                        ? "auto"
                        : `${header.getSize()}px`,
                      textAlign:
                        header.id === "eqNm" || header.id === "commAddr" || header.id === "addr"
                          ? "left"
                          : "center",
                    }}
                  >
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row, index) => (
              <React.Fragment key={row.id}>
                <tr
                  key={row.id}
                  onClick={() => onClickRow(row.original)}
                  style={{ cursor: "pointer" }}
                >
                  {row.getVisibleCells().map((cell) => (
                    <td
                      key={cell.id}
                      onClick={(e) => {
                        if (cell.column.id === "check") {
                          e.stopPropagation();
                        }
                      }}
                      style={{
                        width: isNaN(cell.column.getSize())
                          ? "auto"
                          : `${cell.column.getSize()}px`,
                        minWidth: isNaN(cell.column.getSize())
                          ? "auto"
                          : `${cell.column.getSize()}px`,
                        maxWidth: isNaN(cell.column.getSize())
                          ? "auto"
                          : `${cell.column.getSize()}px`,
                        textAlign:
                          (cell.column.id === "check" ||
                            cell.column.id === "commType" ||
                            cell.column.id === "bdongCd" ||
                            cell.column.id === "angleIcon") &&
                          "center",
                      }}
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </td>
                  ))}
                </tr>
                {row.original.angle && row.original.brdData.length > 0 && (
                  <tr key="sub_table-tr">
                    <td
                      className="sub_table-td"
                      colSpan={7}
                      style={{ padding: "0" }}
                    >
                      <BroadDevice brdData={row.original.brdData} />
                    </td>
                  </tr>
                )}
              </React.Fragment>
            ))}
          </tbody>
        </table>
      ) : (
        <div className="setting_data_none_container">
          <div>등록된 장비가 없습니다</div>
        </div>
      )}
    </>
  );
}

export default Broad;
