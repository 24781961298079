import { useRef, useState } from "react";
import Modal from "react-modal";
import axios from "axios";

import apiAddress from "../../../services/apiAddress";
import Bdong from "../../common/function/Bdong";
import Alert from "../../common/window/Alert";
import Add from "../../common/window/Add";

Modal.setAppElement("#root");

function RegionAdd({ isOpen, setIsOpen, isOk, setIsOk }) {
  const [isBdong, setIsBdong] = useState(false);
  const [isAlert, setIsAlert] = useState(false);
  const [isAdd, setIsAdd] = useState(false);
  const [data, setData] = useState({
    rgnNm: "",
    bdongCd: "",
    addr: "",
    rgnDtl: "",
    useYn: "Y",
  });
  const rgnNmRef = useRef(null);

  function onClickBdong() {
    setIsBdong(true);
  }
  function onClickClose() {
    setIsOpen(false);
  }
  function onClickAdd() {
    if (data.rgnNm === "" || data.bdongCd === "" || data.addr === "") {
      setIsAlert(true);
      if (data.rgnNm === "") {
        rgnNmRef.current.focus();
      }
    } else {
      setIsAdd(true);
    }
  }

  /* 관리지역 추가 API */
  async function fetchData() {
    try {
      setIsAdd(false);
      axios
        .post(
          apiAddress.regionSave,
          // regUsr: 등록자ID.
          { ...data, regUsr: "SH" },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          setIsOk(!isOk);
          setIsOpen(false);
        });
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <Modal isOpen={isOpen} style={modalStyles}>
      {isBdong && (
        <Bdong
          isOpen={isBdong}
          setIsOpen={setIsBdong}
          bdong={data}
          setBdong={setData}
        />
      )}
      {isAlert && (
        <Alert
          isOpen={isAlert}
          setIsOpen={setIsAlert}
          text="필수 정보를 입력해주세요"
        />
      )}
      {isAdd && <Add isOpen={isAdd} setIsOpen={setIsAdd} addData={fetchData} />}
      <div className="modal_title">
        <div>관리 지역 추가</div>
      </div>
      <div className="modal_container">
        {/* Main */}
        <div className="modal_main_column">
          <div className="modal_input">
            <div className="title">관리지역명</div>
            <input
              ref={rgnNmRef}
              type="text"
              placeholder="필수 입력 *"
              value={data.rgnNm}
              onChange={(e) => setData({ ...data, rgnNm: e.target.value })}
              spellCheck="false"
            />
          </div>
          <div className="modal_input">
            <div className="title">법정동코드</div>
            <input
              className="bdong_input input_disable"
              type="text"
              placeholder="필수 입력 *"
              value={data.bdongCd}
              onChange={(e) => setData({ ...data, bdongCd: e.target.value })}
              disabled={true}
              spellCheck="false"
            />
            <div className="title" style={{ borderLeft: "1px solid #585858" }}>
              주소
            </div>
            <input
              className="input_disable"
              type="text"
              placeholder="필수 입력 *"
              value={data.addr}
              onChange={(e) => setData({ ...data, addr: e.target.value })}
              disabled={true}
              spellCheck="false"
            />
            <div className="button_modal" onClick={onClickBdong}>
              코드조회
            </div>
          </div>
          <div
            className="modal_input"
            style={{ flex: "1", borderBottom: "none" }}
          >
            <div className="title">메모</div>
            <textarea
              placeholder="메모 입력"
              value={data.rgnDtl}
              onChange={(e) => setData({ ...data, rgnDtl: e.target.value })}
              spellCheck="false"
            />
          </div>
        </div>

        {/* Button */}
        <div className="modal_footer">
          <div className="button_nor" onClick={onClickClose}>
            닫기
          </div>
          <div
            className="button_imp"
            onClick={onClickAdd}
            style={{ marginLeft: "10px" }}
          >
            추가
          </div>
        </div>
      </div>
    </Modal>
  );
}

const modalStyles = {
  content: {
    width: "730px",
    height: "350px",
    padding: "0px",
    background: "#202020",
    border: "none",
    borderRadius: "5px",
    boxShadow: "-5px 5px 10px rgba(0, 0, 0, 0.7)",
    margin: "auto",
  },
  overlay: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "fixed",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: "rgba(0, 0, 0, 0.4)",
  },
};

export default RegionAdd;
