import apiAddress from "../../services/apiAddress";
import axios from "axios";

import { getDevice } from "./deviceAPI";

export function getRtu() {
  return axios
    .post(
      apiAddress.equipGet,
      {
        eqId: 0,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      const equip = response.data;
      if (equip.length !== 0) {
        const rtu = equip.filter(
          (item) => item.eqType === "RTU" && item.useYn === "Y"
        );
        const sortArray = rtu;
        sortArray.sort((a, b) => {
          return a.eqId - b.eqId;
        });
        return sortArray;
      } else {
        return [];
      }
    });
}

export function getRtuDevice() {
  return getRtu().then((rtu) => {
    if (rtu.length !== 0) {
      const promise = rtu.map((rtuItem) =>
        getDevice(rtuItem.eqId).then((dvc) => ({ ...rtuItem, dvcData: dvc }))
      );

      return Promise.all(promise);
    } else {
      return [];
    }
  });
}

/* 저수지 */
export function getReservoirDevice() {
  return getRtuDevice().then((response) => {
    if (Array.isArray(response) && response.length > 0) {
      const reservoirEqId = [];
      for (let i = 26; i <= 53; i++) {
        reservoirEqId.push(i);
      }

      const filterReservoir = response.filter((item) =>
        reservoirEqId.includes(parseInt(item.eqId))
      );
      return filterReservoir;
    } else {
      return [];
    }
  });
}
export function getRsvLevelSensor() {
  return getReservoirDevice().then((response) => {
    if (Array.isArray(response) && response.length > 0) {
      const filterLevel = response.filter(
        (item) =>
          Array.isArray(item.dvcData) &&
          item.dvcData.length === 1 &&
          item.dvcData[0].dvcType === "SNSR" &&
          item.dvcData[0].dvcDtlType === "SNSR02"
      );
      if (Array.isArray(filterLevel) && filterLevel.length > 0) {
        return filterLevel;
      } else {
        return [];
      }
    } else {
      return [];
    }
  });
}
export function getRsvGroundSensor() {
  return getReservoirDevice().then((response) => {
    if (Array.isArray(response) && response.length > 0) {
      const filterLevel = response.filter(
        (item) =>
          Array.isArray(item.dvcData) &&
          item.dvcData.length === 1 &&
          item.dvcData[0].dvcType === "SNSR" &&
          item.dvcData[0].dvcDtlType === "SNSR07"
      );
      if (Array.isArray(filterLevel) && filterLevel.length > 0) {
        return filterLevel;
      } else {
        return [];
      }
    } else {
      return [];
    }
  });
}
export function getRsvDisSensor() {
  return getReservoirDevice().then((response) => {
    if (Array.isArray(response) && response.length > 0) {
      const filterLevel = response.filter(
        (item) =>
          Array.isArray(item.dvcData) &&
          item.dvcData.length === 1 &&
          item.dvcData[0].dvcType === "SNSR" &&
          item.dvcData[0].dvcDtlType === "SNSR15"
      );
      if (Array.isArray(filterLevel) && filterLevel.length > 0) {
        return filterLevel;
      } else {
        return [];
      }
    } else {
      return [];
    }
  });
}
export function getRsvRainSensor() {
  return getReservoirDevice().then((response) => {
    if (Array.isArray(response) && response.length > 0) {
      const filterLevel = response.filter(
        (item) =>
          Array.isArray(item.dvcData) &&
          item.dvcData.length === 1 &&
          item.dvcData[0].dvcType === "SNSR" &&
          item.dvcData[0].dvcDtlType === "SNSR01"
      );
      if (Array.isArray(filterLevel) && filterLevel.length > 0) {
        return filterLevel;
      } else {
        return [];
      }
    } else {
      return [];
    }
  });
}
