import { useState, useEffect } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faX } from "@fortawesome/free-solid-svg-icons";

import CCTV from "./type/CCTV";
import Edboard from "./type/Edboard";
import Sensor from "./type/Sensor";

function MarkerInform({ selected, setSelected, setIsChange }) {
  const [type, setType] = useState("");
  const [inform, setInform] = useState({});

  /* 정보 닫기 */
  function onClickClose() {
    setSelected({});
  }

  useEffect(() => {
    if (selected.eqType === "CCTV") {
      setType("CCTV");
    } else if (selected.eqType === "EDB") {
      setType("EDB");
    } else {
      if (
        selected.eqType === "RTU" &&
        Array.isArray(selected.dvcData) &&
        selected.dvcData.length > 0
      ) {
        setType("SNSR");
      } else {
        setType("");
      }
    }

    setInform(selected);
  }, [selected]);

  return (
    <div className="marker-inform-container">
      {type !== "" && (
        <>
          <div className="marker-inform-header">
            <div>{inform.eqNm}</div>
            <FontAwesomeIcon
              icon={faX}
              className="close-button"
              onClick={onClickClose}
            />
          </div>
          <div className="marker-inform-main">
            {type === "CCTV" ? (
              <CCTV selected={selected} />
            ) : type === "EDB" ? (
              <Edboard selected={selected} setIsChange={setIsChange} />
            ) : (
              type === "SNSR" && <Sensor selected={selected} />
            )}
          </div>
        </>
      )}
    </div>
  );
}

export default MarkerInform;
