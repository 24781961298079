import Modal from "react-modal";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";

import { MODALSTYLES } from "../../../constants/modalStyles";

Modal.setAppElement("#root");

function Alert({ isOpen, setIsOpen, text }) {
  setTimeout(() => {
    setIsOpen(false);
  }, 800);

  return (
    <Modal isOpen={isOpen} style={MODALSTYLES.alert}>
      <div className="modal_alert_container">
        <FontAwesomeIcon
          icon={faTriangleExclamation}
          style={{ color: "#FFE400", fontSize: "1.5rem" }}
        />
        <div style={{ marginLeft: "10px" }}>{text}</div>
      </div>
    </Modal>
  );
}
export default Alert;
