import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  createColumnHelper,
  flexRender,
  useReactTable,
  getCoreRowModel,
  getSortedRowModel,
} from "@tanstack/react-table";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

import { tokenCheck } from "../../../../utils/tokenCheck";
import RtuDeviceReg from "./RtuDeviceReg";

function Rtu({ rtuData, selectId, setSelectId, isRegOk, setIsRegOk }) {
  const movePage = useNavigate();
  const [isAdd, setIsAdd] = useState({
    isOpen: false,
  });
  const columnHelper = createColumnHelper();
  const [data, setData] = useState(rtuData);
  const [columns, setColumns] = useState([]);
  const plusIconColumn = {
    id: "plusIcon",
    accessor: "plus",
    header: "",
    size: 20,
    enableSorting: false,
    cell: ({ row }) => <FontAwesomeIcon icon={faPlus} />,
  };
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  function onClickRow(row) {
    // 행 클릭.
    if (selectId === row.eqId) {
      // 선택 => 선택해제.
      setSelectId(0);
    } else {
      // 선택해제 => 선택.
      setSelectId(row.eqId);
    }
  }
  function onClickAdd(row) {
    // 추가.
    // 토큰 확인
    tokenCheck().then((result) => {
      if (!result) {
        alert("인증 토큰이 만료되었습니다. 다시 로그인 해주세요");
        movePage("/");
      } else {
        setIsAdd({
          isOpen: true,
          groupId: row.groupId,
          eqId: row.eqId,
          eqNm: row.eqNm,
        });
      }
    });
  }

  useEffect(() => {
    const column = [
      columnHelper.accessor("eqNm", {
        header: "RTU명",
        size: "auto",
      }),
      plusIconColumn,
    ];

    setData(rtuData);
    setColumns(column);
  }, [rtuData]);

  return (
    <>
      {isAdd.isOpen && (
        <RtuDeviceReg
          isOpen={isAdd}
          setIsOpen={setIsAdd}
          isOk={isRegOk}
          setIsOk={setIsRegOk}
        />
      )}
      {data.length > 0 ? (
        <table className="react_table">
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th
                    key={header.id}
                    onClick={header.column.getToggleSortingHandler()}
                    style={{
                      width: isNaN(header.getSize())
                        ? "auto"
                        : `${header.getSize()}px`,
                      textAlign: "center",
                    }}
                  >
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row, index) => (
              <tr
                key={row.id}
                className={
                  selectId === row.original.eqId ? "tr_select_sub" : ""
                }
                onClick={() => onClickRow(row.original)}
                style={{ cursor: "pointer" }}
              >
                {row.getVisibleCells().map((cell) => (
                  <td
                    key={cell.id}
                    onClick={(e) => {
                      if (cell.column.id === "plusIcon") {
                        e.stopPropagation();
                        onClickAdd(row.original);
                      }
                    }}
                    style={{
                      width: isNaN(cell.column.getSize())
                        ? "auto"
                        : `${cell.column.getSize()}px`,
                      maxWidth: isNaN(cell.column.getSize())
                        ? "auto"
                        : `${cell.column.getSize()}px`,
                      minWidth: isNaN(cell.column.getSize())
                        ? "auto"
                        : `${cell.column.getSize()}px`,
                      textAlign: cell.column.id === "plusIcon" && "center",
                    }}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <div className="setting_data_none_container">
          <div>등록된 RTU가 없습니다.</div>
        </div>
      )}
    </>
  );
}

export default Rtu;
