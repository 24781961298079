import { useState, useEffect } from "react";
import CryptoJS from "crypto-js";

import CCTVScreens from "../../../components/cctv/CCTVScreens";

const secretKey = "ict-suhyun";

function CCTVs() {
  const [cctvs, setCctvs] = useState([]);
  const [isQuit, setIsQuit] = useState(false);

  /* 복호화 */
  function decryptData(cipherText) {
    const bytes = CryptoJS.AES.decrypt(cipherText, secretKey);
    return bytes.toString(CryptoJS.enc.Utf8);
  }

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const data = JSON.parse(decryptData(params.get("data")));
    setCctvs(data);

    return () => {
      setIsQuit(true);
    };
  }, []);

  return (
    <div id="cctv-show-page">
      <div className="header">
        <div>CCTV Viewer</div>
      </div>
      <div className="main">
        {cctvs.map((cctvInf) => (
          <div
            key={cctvInf.eqId}
            className={
              cctvs.length === 1
                ? "cctv-container one"
                : cctvs.length <= 2
                ? "cctv-container two"
                : cctvs.length <= 4
                ? "cctv-container four"
                : cctvs.length <= 6
                ? "cctv-container six"
                : ""
            }
          >
            <CCTVScreens cctv={cctvInf} isQuit={isQuit} />
          </div>
        ))}
      </div>
      <div className="footer">
        <div>Copyright2023. Ahjoo Engineering Inc. All rights reserved.</div>
      </div>
    </div>
  );
}

export default CCTVs;
