import Modal from "react-modal";

import { MODALSTYLES } from "../../../constants/modalStyles";
import loadingImg from "../../../assets/common/loading.gif";

Modal.setAppElement("#root");

function Loading({ isOpen }) {
  return (
    <Modal isOpen={isOpen} style={MODALSTYLES.loading}>
      <img src={loadingImg} alt="" style={{ width: "100%", height: "100%" }} />
    </Modal>
  );
}

export default Loading;
