import { useState } from "react";

import CCTVScreen from "./CCTVScreen";
import CCTVPtz from "./CCTVPtz";

function CCTVControl({ selectCctv, setCctvErrText }) {
  const [result, setResult] = useState(false);

  return (
    <div id="cctv-control-component">
      {/* CCTV 화면 */}
      <div className="cctv-screen">
        <CCTVScreen
          selectCctv={selectCctv}
          setCctvErrText={setCctvErrText}
          result={result}
          setResult={setResult}
        />
      </div>

      {/* CCTV PTZ 제어 */}
      <div className="cctv-ptz">
        <CCTVPtz selectCctv={selectCctv} result={result} />
      </div>
    </div>
  );
}

export default CCTVControl;
