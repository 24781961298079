import React, { useEffect, useState } from "react";
import {
  createColumnHelper,
  flexRender,
  useReactTable,
  getCoreRowModel,
  getSortedRowModel,
} from "@tanstack/react-table";
import Modal from "react-modal";
import axios from "axios";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleUp, faAngleDown } from "@fortawesome/free-solid-svg-icons";

import apiAddress from "../../../services/apiAddress";
import Reg from "../../common/window/Reg";
import BroadRegDevice from "./BroadRegDevice";

function BroadReg({ selectedId, isOpen, setIsOpen, isOk, setIsOk }) {
  const [regData, setRegData] = useState([]);
  const [isReg, setIsReg] = useState({
    isOpen: false,
    text: "",
  });
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);
  const columnHelper = createColumnHelper();
  const checkColumn = {
    id: "check",
    accessor: "check",
    header: "",
    enableSorting: false,
    size: 10,
    cell: ({ row }) => (
      <input
        type="checkbox"
        checked={regData.find((item) => item.eqId === row.original.eqId)}
        onChange={() => onChangeCheck(row.original)}
        style={{ cursor: "pointer" }}
      />
    ),
  };
  const angleIconColumn = {
    id: "angleIcon",
    accessor: "angle",
    header: "",
    enableSorting: false,
    size: 10,
    cell: ({ row }) => {
      return row.original.angle ? (
        <FontAwesomeIcon icon={faAngleUp} />
      ) : (
        <FontAwesomeIcon icon={faAngleDown} />
      );
    },
  };
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  function onChangeCheck(row) {
    // 체크 버튼 클릭.
    if (regData.find((item) => item.eqId === row.eqId)) {
      // 체크 => 체크 해제.
      const newData = regData.filter((item) => item.eqId !== row.eqId);
      setRegData(newData);
    } else {
      // 체크 해제 => 체크.
      setRegData([
        ...regData,
        {
          groupId: selectedId,
          eqId: row.eqId,
          useYn: "Y",
          regUsr: "SH",
        },
      ]);
    }
  }
  function onClickRow(row) {
    // 행 클릭.
    const newData = data.map((item) => {
      if (item.eqId === row.eqId) {
        return { ...item, angle: !item.angle };
      }
      return item;
    });

    setData(newData);
  }
  function onClickReg() {
    // 등록 버튼 클릭.
    setIsReg({
      isOpen: true,
      text: regData.length + "개의 장비를 등록하시겠습니까?",
    });
  }

  /* RTU 및 DVC 조회 API */
  async function fetchData() {
    try {
      axios
        .post(
          apiAddress.equipGet,
          {
            eqId: 0,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          const rtuAllData = response.data.filter(
            (item) => item.eqType === "RTU" && item.useYn === "Y"
          );

          // 등록된 장비 조회 API.
          axios
            .post(
              apiAddress.groupEquipGet,
              {
                groupId: selectedId,
                searchType: "A",
              },
              {
                headers: {
                  "Content-Type": "application/json",
                },
              }
            )
            .then((response) => {
              const rtuRegData = response.data;
              const rtuData = rtuAllData.filter(
                // 등록된 장비 제외 추출.
                (item) =>
                  !rtuRegData.some((regItem) => regItem.eqId === item.eqId)
              );

              // 장비 데이터 조회.
              const fetchDataDev = async (item) => {
                try {
                  const response = await axios.post(
                    apiAddress.deviceGet,
                    {
                      eqId: item.eqId,
                    },
                    {
                      headers: {
                        "Content-Type": "application/json",
                      },
                    }
                  );

                  const responseData = response.data.filter(
                    (device) => device.dvcType === "BRD" && device.useYn === "Y"
                  );
                  return { ...item, brdData: responseData, angle: false };
                } catch (e) {
                  console.log(e);
                  return null;
                }
              };

              const fetchRtuDevice = async () => {
                // equip_inf + dvc_inf.
                const rtuBrd = await Promise.all(rtuData.map(fetchDataDev));
                const rtuBrdData = rtuBrd.filter(
                  (item) => item.brdData.length > 0
                );
                // Column.
                const column = [
                  checkColumn,
                  columnHelper.accessor("eqNm", {
                    header: "RTU명",
                    size: 130,
                  }),
                  columnHelper.accessor("commType", {
                    header: "통신",
                    size: 50,
                  }),
                  columnHelper.accessor("commAddr", {
                    header: "통신정보",
                    size: 90,
                  }),
                  columnHelper.accessor("bdongCd", {
                    header: "법정동코드",
                    size: 80,
                  }),
                  columnHelper.accessor("addr", {
                    header: "주소",
                    size: "auto",
                  }),
                  angleIconColumn,
                ];

                setData(rtuBrdData);
                setColumns(column);
              };

              fetchRtuDevice();
            });
        });
    } catch (e) {
      console.log(e);
    }
  }
  /* 방송그룹 - 장비 등록 API */
  async function fetchDataReg() {
    try {
      axios
        .post(apiAddress.groupEquipSave, regData, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          setIsOk(!isOk);
          setIsOpen(false);
        });
    } catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    fetchData();
  }, []);
  useEffect(() => {
    const column = [
      checkColumn,
      columnHelper.accessor("eqNm", {
        header: "RTU명",
        size: 130,
      }),
      columnHelper.accessor("commType", {
        header: "통신",
        size: 50,
      }),
      columnHelper.accessor("commAddr", {
        header: "통신정보",
        size: 90,
      }),
      columnHelper.accessor("bdongCd", {
        header: "법정동코드",
        size: 80,
      }),
      columnHelper.accessor("addr", {
        header: "주소",
        size: "auto",
      }),
      angleIconColumn,
    ];
    setColumns(column);
  }, [regData]);

  return (
    <Modal isOpen={isOpen} style={modalStyles}>
      {isReg.isOpen && (
        <Reg isOpen={isReg} setIsOpen={setIsReg} regData={fetchDataReg} />
      )}
      <div className="modal_title">
        <div>방송 장비 등록</div>
      </div>
      <div className="modal_container">
        {/* Main */}
        <div className="modal_main_column" style={{ overflow: "auto" }}>
          {data.length > 0 ? (
            <table className="react_table">
              <thead>
                {table.getHeaderGroups().map((headerGroup) => (
                  <tr key={headerGroup.id}>
                    {headerGroup.headers.map((header) => (
                      <th
                        key={header.id}
                        style={{
                          width: isNaN(header.getSize())
                            ? "auto"
                            : `${header.getSize()}px`,
                          minWidth: isNaN(header.getSize())
                            ? "auto"
                            : `${header.getSize()}px`,
                          maxWidth: isNaN(header.getSize())
                            ? "auto"
                            : `${header.getSize()}px`,
                        }}
                      >
                        {header.isPlaceholder
                          ? null
                          : flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody>
                {table.getRowModel().rows.map((row, index) => (
                  <React.Fragment key={row.id}>
                    <tr
                      key={row.id}
                      className={index % 2 === 0 ? "tr_even" : "tr_odd"}
                      onClick={() => onClickRow(row.original)}
                    >
                      {row.getVisibleCells().map((cell) => (
                        <td
                          key={cell.id}
                          onClick={(e) => {
                            if (cell.column.id === "check") {
                              e.stopPropagation();
                            }
                          }}
                          style={{
                            width: isNaN(cell.column.getSize())
                              ? "auto"
                              : `${cell.column.getSize()}px`,
                            minWidth: isNaN(cell.column.getSize())
                              ? "auto"
                              : `${cell.column.getSize()}px`,
                            maxWidth: isNaN(cell.column.getSize())
                              ? "auto"
                              : `${cell.column.getSize()}px`,
                            textAlign:
                              (cell.column.id === "check" ||
                                cell.column.id === "commType" ||
                                cell.column.id === "bdongCd" ||
                                cell.column.id === "angleIcon") &&
                              "center",
                          }}
                        >
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </td>
                      ))}
                    </tr>
                    {row.original.angle && row.original.brdData.length > 0 && (
                      <tr key="sub_table-tr">
                        <td
                          className="sub_table-td"
                          colSpan={7}
                          style={{ padding: "0" }}
                        >
                          <BroadRegDevice brdData={row.original.brdData} />
                        </td>
                      </tr>
                    )}
                  </React.Fragment>
                ))}
              </tbody>
            </table>
          ) : (
            <div className="setting_data_none_container">
              <div>등록할 장비가 없습니다</div>
            </div>
          )}
        </div>

        {/* Footer */}
        <div className="modal_footer">
          <div className="button_nor" onClick={() => setIsOpen(false)}>
            닫기
          </div>
          {regData.length > 0 && (
            <div
              className="button_imp"
              onClick={onClickReg}
              style={{ marginLeft: "10px" }}
            >
              등록
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
}

const modalStyles = {
  content: {
    width: "900px",
    height: "650px",
    padding: "0px",
    background: "#202020",
    border: "none",
    borderRadius: "5px",
    boxShadow: "-5px 5px 10px rgba(0, 0, 0, 0.7)",
    margin: "auto",
  },
  overlay: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "fixed",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: "rgba(0, 0, 0, 0.4)",
  },
};

export default BroadReg;
