import { useState, useEffect } from "react";

import { getCurrTime, zeroPlusNum } from "../utils/time";

function Time() {
  const [year, setYear] = useState(getCurrTime().year);
  const [month, setMonth] = useState(getCurrTime().month);
  const [day, setDay] = useState(getCurrTime().day);
  const [hour, setHour] = useState(getCurrTime().hour);
  const [minute, setMinute] = useState(getCurrTime().minute);
  const [second, setSecond] = useState(getCurrTime().second);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setYear(getCurrTime().year);
      setMonth(getCurrTime().month);
      setDay(getCurrTime().day);
      setHour(getCurrTime().hour);
      setMinute(getCurrTime().minute);
      setSecond(getCurrTime().second);
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className="time">
      <div className="time-container">
        <div className="time-item">{`${zeroPlusNum(year)}년`}</div>
        <div className="time-item">{`${zeroPlusNum(month)}월`}</div>
        <div className="time-item">{`${zeroPlusNum(day)}일`}</div>
      </div>
      <div className="time-container">
        <div className="time-item">{`${zeroPlusNum(hour)}시`}</div>
        <div className="time-item">{`${zeroPlusNum(minute)}분`}</div>
        <div className="time-item">{`${zeroPlusNum(second)}초`}</div>
      </div>
    </div>
  );
}

export default Time;
