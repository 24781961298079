import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Menu, Item, useContextMenu } from "react-contexify";
import {
  createColumnHelper,
  flexRender,
  useReactTable,
  getCoreRowModel,
  getSortedRowModel,
} from "@tanstack/react-table";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisVertical } from "@fortawesome/free-solid-svg-icons";

import { tokenCheck } from "../../../utils/tokenCheck";
import { dateTimeToFormat } from "../../../utils/dateFormat";
import EdboardHistoryInform from "../../common/inform/EdboardHistoryInform";

const MENU_ID = "edboardMessageList";

function EdboardHistory({ selected }) {
  const movePage = useNavigate();
  const [isInform, setIsInform] = useState({ isOpen: false, data: {} });
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);
  const columnHelper = createColumnHelper();
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });
  const menuIconColumn = {
    id: "menuIcon",
    accessor: "menu",
    header: "",
    size: 10,
    enableSorting: false,
    cell: ({ row }) => (
      <FontAwesomeIcon className="table_icon" icon={faEllipsisVertical} />
    ),
  };
  const { show } = useContextMenu({
    id: MENU_ID,
  });

  function onClickMenu(e, row) {
    // Context 메뉴.
    e.stopPropagation();
    show({ event: e, props: { data: row } });
  }
  function menuItemClick({ id, props }) {
    // 메뉴 Item 클릭 시.
    // 토큰 확인
    tokenCheck().then((result) => {
      if (!result) {
        alert("인증 토큰이 만료되었습니다. 다시 로그인 해주세요");
        movePage("/");
      } else {
        switch (id) {
          // 정보.
          case "inform":
            setIsInform({ isOpen: true, data: props.data });
            break;
          default:
            break;
        }
      }
    });
  }

  useEffect(() => {
    if (Array.isArray(selected.msgLog) && selected.msgLog.length > 0) {
      const sortData = selected.msgLog.sort((a, b) => {
        if (a.regDt > b.regDt) return -1;
        if (a.regDt < b.regDt) return 1;
        return 0;
      });

      const column = [
        columnHelper.accessor(
          (row) => {
            return dateTimeToFormat(row.regDt);
          },
          { id: "regDt", header: "전송일자", size: 130 }
        ),
        columnHelper.accessor("sendMsg", { header: "내용", size: 250 }),
        columnHelper.accessor("regUsr", { header: "전송자", size: 100 }),
        columnHelper.accessor("siteId", { header: "고객사ID", size: 50 }),
        menuIconColumn,
      ];

      setData(sortData);
      setColumns(column);
    } else {
      setData([]);
      setColumns([]);
    }
  }, [selected]);

  return (
    <div id="edboard-list-history-component">
      {
        // 전광판 내역 정보
        isInform.isOpen && (
          <EdboardHistoryInform isOpen={isInform} setIsOpen={setIsInform} />
        )
      }
      {data.length === 0 ? (
        <div className="data-none-container">
          해당 전광판의 내역 조회 결과가 없습니다
        </div>
      ) : (
        <div className="list">
          <table className="react_table">
            <thead>
              {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <th
                      key={header.id}
                      onClick={header.column.getToggleSortingHandler()}
                      style={{
                        width: isNaN(header.getSize())
                          ? "auto"
                          : `${header.getSize()}px`,
                        minWidth: isNaN(header.getSize())
                          ? "auto"
                          : `${header.getSize()}px`,
                        maxWidth: isNaN(header.getSize())
                          ? "auto"
                          : `${header.getSize()}px`,
                      }}
                    >
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody>
              {table.getRowModel().rows.map((row, index) => (
                <tr
                  key={row.id}
                  className={index % 2 === 0 ? "tr_even" : "tr_odd"}
                >
                  {row.getVisibleCells().map((cell) => (
                    <td
                      key={cell.id}
                      onClick={(e) => {
                        if (cell.column.id === "menuIcon") {
                          onClickMenu(e, row.original);
                        }
                      }}
                      style={{
                        width: isNaN(cell.column.getSize())
                          ? "auto"
                          : `${cell.column.getSize()}px`,
                        minWidth: isNaN(cell.column.getSize())
                          ? "auto"
                          : `${cell.column.getSize()}px`,
                        maxWidth: isNaN(cell.column.getSize())
                          ? "auto"
                          : `${cell.column.getSize()}px`,
                        textAlign:
                          (cell.column.id === "regDt" ||
                            cell.column.id === "menuIcon") &&
                          "center",
                      }}
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      {/* Context 메뉴 */}
      <Menu id={MENU_ID}>
        <Item id="inform" onClick={menuItemClick}>
          정보
        </Item>
      </Menu>
    </div>
  );
}

export default EdboardHistory;
