import { useState } from "react";

import CurrWeather from "./currfore/CurrWeather";
import Forecast from "./currfore/Forecast";

function CurrFore() {
  const [type, setType] = useState("curr");

  return (
    <>
      <div className="select-container">
        <div
          className={`select-item ${
            type === "curr" ? "select-item-select" : ""
          }`}
          onClick={() => setType("curr")}
        >
          날씨
        </div>
        <div
          className={`select-item ${
            type === "fore" ? "select-item-select" : ""
          }`}
          onClick={() => setType("fore")}
        >
          예보
        </div>
      </div>
      <div className="curr-fore-contents">
        {type === "curr" ? <CurrWeather /> : type === "fore" && <Forecast />}
      </div>
    </>
  );
}

export default CurrFore;
