import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setMenu, setSubmenu } from "../../../redux/modules/menu";
import {
  createColumnHelper,
  flexRender,
  useReactTable,
  getCoreRowModel,
  getSortedRowModel,
} from "@tanstack/react-table";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome, faList } from "@fortawesome/free-solid-svg-icons";

import { tokenCheck } from "../../../utils/tokenCheck";

const PAGE_VALUE = "경보방송";
const SUBPAGE_VALUE = "방송내역";

function BroadcastList() {
  const dispatch = useDispatch();
  const [isToken, setIsToken] = useState(false);
  const movePage = useNavigate();
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);
  const columnHelper = createColumnHelper();

  function onClickRow() {}
  function onDoubleClickRow() {}
  function onClickSearch() {
    // 검색 버튼 클릭
    // 토큰 확인
    tokenCheck().then((result) => {
      if (!result) {
        alert("인증 토큰이 만료되었습니다. 다시 로그인 해주세요");
        movePage("/");
      } else {
      }
    });
  }
  function onClickExcel() {
    // Excel 버튼 클릭
    // 토큰 확인
    tokenCheck().then((result) => {
      if (!result) {
        alert("인증 토큰이 만료되었습니다. 다시 로그인 해주세요");
        movePage("/");
      } else {
      }
    });
  }

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });
  async function fetchData() {
    const sample = [
      {
        rgnNm: "테스트지역",
        brdStrt: "2023-10-16",
        brdEnd: "2023-10-16",
        brdTitle: "테스트 방송",
        brdCntn: "테스트 방송입니다.",
        brdUsr: "SUHYUN",
        brdState: "정상",
      },
      {
        rgnNm: "테스트지역",
        brdStrt: "2023-10-16",
        brdEnd: "2023-10-16",
        brdTitle: "테스트 방송",
        brdCntn: "테스트 방송입니다.",
        brdUsr: "SUHYUN",
        brdState: "정상",
      },
      {
        rgnNm: "테스트지역",
        brdStrt: "2023-10-16",
        brdEnd: "2023-10-16",
        brdTitle: "테스트 방송",
        brdCntn: "테스트 방송입니다.",
        brdUsr: "SUHYUN",
        brdState: "정상",
      },
    ];
    const column = [
      columnHelper.accessor("rgnNm", {
        header: "방송지역",
        size: 80,
      }),
      columnHelper.accessor("brdStrt", {
        header: "방송시작",
        size: 20,
      }),
      columnHelper.accessor("brdEnd", {
        header: "방송종료",
        size: 20,
      }),
      columnHelper.accessor("brdTitle", {
        id: "brdTitle",
        header: "방송제목",
        size: 80,
      }),
      columnHelper.accessor("brdCntn", {
        header: "방송내용",
        width: "auto",
      }),
      columnHelper.accessor("brdUsr", {
        header: "사용자",
        size: 20,
      }),
      columnHelper.accessor("brdState", {
        header: "처리상태",
        size: 10,
      }),
    ];

    setData(sample);
    setColumns(column);
  }

  useEffect(() => {
    dispatch(setMenu(PAGE_VALUE));
    dispatch(setSubmenu(SUBPAGE_VALUE));

    // 토큰 확인
    tokenCheck().then((result) => {
      if (!result) {
        alert("인증 토큰이 만료되었습니다. 다시 로그인 해주세요");
        movePage("/");
      } else {
        setIsToken(true);
      }
    });

    fetchData();
  }, []);

  return (
    <div className="outlet_container">
      {isToken && (
        <>
          <div className="header_container">
            <div className="title">
              <div className="title_imp">방송내역</div>
            </div>
            <div className="path">
              <FontAwesomeIcon icon={faHome} />
              <div className="text">&gt; 경보방송 &gt; 방송내역</div>
            </div>
          </div>

          {/* Main */}
          <div className="main_container">
            <div className="contents_container">
              <div className="contents_header">
                <div className="title">
                  <FontAwesomeIcon icon={faList} />
                  <div className="text">방송내역</div>
                </div>

                <div className="search">
                  <input
                    value={"2023-10-12"}
                    style={{
                      width: "70px",
                      textAlign: "center",
                      cursor: "pointer",
                    }}
                    disabled={true}
                  />
                  <div style={{ margin: "0px 10px" }}>~</div>
                  <input
                    value={"2023-10-12"}
                    style={{
                      width: "70px",
                      textAlign: "center",
                      cursor: "pointer",
                    }}
                    disabled={true}
                  />
                  <select style={{ marginLeft: "20px" }}>
                    <option>----- 관리지역 선택 -----</option>
                    <option>테스트 관리지역</option>
                    <option>테스트 관리지역2</option>
                  </select>

                  <input
                    placeholder="방송제목, 방송내용 검색"
                    style={{
                      marginLeft: "10px",
                    }}
                    spellCheck={false}
                  />
                  <div
                    className="button_imp"
                    onClick={onClickSearch}
                    style={{ marginLeft: "15px" }}
                  >
                    검색
                  </div>
                  <div
                    className="button_excel"
                    onClick={onClickExcel}
                    style={{ marginLeft: "10px" }}
                  >
                    Excel 저장
                  </div>
                </div>
              </div>

              <div className="contents_main">
                <table className="react_table">
                  <thead>
                    {table.getHeaderGroups().map((headerGroup) => (
                      <tr key={headerGroup.id}>
                        {headerGroup.headers.map((header) => (
                          <th
                            key={header.id}
                            onClick={header.column.getToggleSortingHandler()}
                            style={{
                              width: isNaN(header.getSize())
                                ? "auto"
                                : `${header.getSize()}px`,
                            }}
                          >
                            {header.isPlaceholder
                              ? null
                              : flexRender(
                                  header.column.columnDef.header,
                                  header.getContext()
                                )}
                          </th>
                        ))}
                      </tr>
                    ))}
                  </thead>
                  <tbody>
                    {table.getRowModel().rows.map((row, index) => (
                      <tr
                        key={row.id}
                        className={index % 2 === 0 ? "tr_even" : "tr_odd"}
                        onClick={() => onClickRow(row.original)}
                        onDoubleClick={() => onDoubleClickRow(row.original)}
                      >
                        {row.getVisibleCells().map((cell) => (
                          <td
                            key={cell.id}
                            style={{
                              width: isNaN(cell.column.getSize())
                                ? "auto"
                                : `${cell.column.getSize()}px`,
                              textAlign:
                                cell.column.id === "rgnNm" ||
                                cell.column.id === "brdTitle" ||
                                cell.column.id === "brdCntn"
                                  ? ""
                                  : "center",
                            }}
                          >
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext()
                            )}
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>

              {/* Page */}
              <div className="contents_footer">
                <div className="button_page">&lt;&lt;</div>
                <div className="button_page" style={{ marginLeft: "5px" }}>
                  &lt;
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    margin: "0px 10px",
                  }}
                >
                  <div style={{ margin: "0px 7px" }}>1</div>
                  <div style={{ margin: "0px 7px" }}>2</div>
                  <div style={{ margin: "0px 7px" }}>3</div>
                  <div style={{ margin: "0px 7px" }}>4</div>
                  <div
                    style={{
                      margin: "0px 7px",
                      color: "#6180D8",
                      fontWeight: "bold",
                    }}
                  >
                    5
                  </div>
                  <div style={{ margin: "0px 7px" }}>6</div>
                  <div style={{ margin: "0px 7px" }}>7</div>
                  <div style={{ margin: "0px 7px" }}>8</div>
                  <div style={{ margin: "0px 7px" }}>9</div>
                </div>
                <div className="button_page" style={{ marginLeft: "5px" }}>
                  &gt;
                </div>
                <div className="button_page" style={{ marginLeft: "5px" }}>
                  &gt;&gt;
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default BroadcastList;
