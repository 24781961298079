import apiAddress from "../../services/apiAddress";
import axios from "axios";
import { getCurrTime, zeroPlusNum } from "../../utils/time";
import { dataDateToFormat } from "../../utils/data";
import { getRtuDevice } from "./rtuAPI";

export function getData(term, fromDt, toDt, dvcId) {
  return axios
    .post(
      apiAddress.getData,
      {
        term: term,
        fromDt: fromDt,
        toDt: toDt,
        dvcId: dvcId,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      return response.data;
    });
}

// 강우계 조회
export function getRainSnsr() {
  return getRtuDevice().then((rtuDevice) => {
    const rainRtu = rtuDevice.filter(
      (rtuDeviceItem) =>
        rtuDeviceItem.dvcData.length > 0 &&
        rtuDeviceItem.dvcData.find(
          (dvcItem) =>
            dvcItem.dvcType === "SNSR" && dvcItem.dvcDtlType === "SNSR01"
        )
    );
    return rainRtu;
  });
}
// 수위계 조회
export function getLevelSnsr() {
  return getRtuDevice().then((rtuDevice) => {
    const levelRtu = rtuDevice.filter(
      (rtuDeviceItem) =>
        rtuDeviceItem.dvcData.length > 0 &&
        rtuDeviceItem.dvcData.find(
          (dvcItem) =>
            dvcItem.dvcType === "SNSR" && dvcItem.dvcDtlType === "SNSR02"
        )
    );
    return levelRtu;
  });
}
// 경사계 조회
export function getSlopeSnsr() {
  return getRtuDevice().then((rtuDevice) => {
    const slopeRtu = rtuDevice.filter(
      (rtuDeviceItem) =>
        rtuDeviceItem.dvcData.length > 0 &&
        rtuDeviceItem.dvcData.find(
          (dvcItem) =>
            dvcItem.dvcType === "SNSR" && dvcItem.dvcDtlType === "SNSR08"
        )
    );
    return slopeRtu;
  });
}
// 변위계 조회
export function getDisSnsr() {
  return getRtuDevice().then((rtuDevice) => {
    const disRtu = rtuDevice.filter(
      (rtuDeviceItem) =>
        rtuDeviceItem.dvcData.length > 0 &&
        rtuDeviceItem.dvcData.find(
          (dvcItem) =>
            dvcItem.dvcType === "SNSR" && dvcItem.dvcDtlType === "SNSR15"
        )
    );
    return disRtu;
  });
}

// 데이터 조회
export function getDataUpdate(term, fromDt, toDt, dvcInform) {
  return axios
    .post(
      apiAddress.getData,
      {
        cdDistObsv: parseInt(dvcInform.dvcId) - 1,
        term: term,
        fromDt: fromDt,
        toDt: toDt,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      const responseData = response.data;
      if (Object.keys(responseData).length > 0) {
        const target = responseData[dvcInform.dvcId - 1];
        if (Array.isArray(target) && target.length > 0) {
          const data = target.map((item) => ({
            date: dataDateToFormat(term, item),
            avgVal: item.avgVal,
          }));
          return data;
        } else {
          return [];
        }
      } else {
        return [];
      }
    });
}

// 센서 통신 상태 조회
export function getSnsrStatus() {
  return axios.get(apiAddress.getStatus).then((response) => {
    const responseData = response.data.sensor_status;
    return responseData;
  });
}
