import { useState, useEffect } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocation } from "@fortawesome/free-solid-svg-icons";

import { location } from "../../../constants/latlon";
import { getIconAndText } from "../../../utils/kma";

function Forecast({ foreInform }) {
  const [data, setData] = useState([]);

  useEffect(() => {
    if (Array.isArray(foreInform) && foreInform.length > 0) {
      setData(foreInform);
    } else {
      setData([]);
    }
  }, [foreInform]);

  return (
    <div id="allstatus-fore-component">
      {data.length === 0 ? (
        <div className="data-none-container">자료 조회에 실패하였습니다</div>
      ) : (
        <>
          {/* 위치 & 발표시각 */}
          <div className="location-time-container">
            <div className="item">
              <FontAwesomeIcon className="icon" icon={faLocation} />
              <div className="right">{location.locationNm}</div>
            </div>
            <div className="item">
              <div>{data[0].data[0].baseDate}</div>
              <div className="right">{data[0].data[0].baseTime}</div>
            </div>
          </div>

          <div className="fore-contents">
            {data.map((item, index) => (
              <div
                key={item.time}
                className={
                  index !== data.length - 1
                    ? "fore-item"
                    : "fore-item fore-item-last"
                }
              >
                <div className="fore-time">{`${item.time}시`}</div>
                <img
                  src={
                    getIconAndText(
                      item.data.find((dataItem) => dataItem.category === "SKY")
                        ?.fcstValue,
                      item.data.find((dataItem) => dataItem.category === "PTY")
                        ?.fcstValue
                    ).icon
                  }
                  alt=""
                />
                <div className="temp">
                  {`${
                    item.data.find((dataItem) => dataItem.category === "TMP")
                      ?.fcstValue
                  }°C`}
                </div>
                <div className="rain">
                  <div className="rain-title">강수확률</div>
                  <div className="rain-value">
                    {`${
                      item.data.find((dataItem) => dataItem.category === "POP")
                        ?.fcstValue
                    }%`}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
}

export default Forecast;
