import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setMenu } from "../../../redux/modules/menu";
import CryptoJS from "crypto-js";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome, faList, faGamepad } from "@fortawesome/free-solid-svg-icons";

import { tokenCheck } from "../../../utils/tokenCheck";
import { getCCTV } from "../../../services/internal/cctvAPI";
import CCTVList from "../../../components/cctv/CCTVList";
import CCTVControl from "../../../components/cctv/CCTVControl";

const secretKey = "ict-suhyun";

const PAGE_VALUE = "CCTV";

function CCTV() {
  const dispatch = useDispatch();
  const movePage = useNavigate();
  const [isToken, setIsToken] = useState(false);
  const [cctv, setCctv] = useState([]);
  const [cctvErrText, setCctvErrText] = useState("");
  const [selectCctv, setSelectCctv] = useState({}); // 한 화면
  const [showCctvs, setShowCctvs] = useState([]); // 여러 개 CCTV

  function fetchCCTV() {
    /* CCTV 조회 */
    getCCTV().then((response) => {
      setCctv(response);
    });
  }
  /* 암호화 */
  function encryptData(data) {
    return CryptoJS.AES.encrypt(data, secretKey).toString();
  }
  function onClickCCTVViewer() {
    // 토큰 확인
    tokenCheck().then((result) => {
      if (!result) {
        alert("인증 토큰이 만료되었습니다. 다시 로그인 해주세요");
        movePage("/");
      } else {
        const queryString = new URLSearchParams({
          data: encryptData(JSON.stringify(showCctvs)),
        }).toString();
        window.open(
          `/cctv/viewer?${queryString}`,
          "_blank",
          "width=1200,height=700,toolbar=no,menubar=no,scrollbars=no,resizable=no,location=no,status=no"
        );
      }
    });
  }
  // function onClickNvrCctv(num) {
  //   /* NVR CCTV */
  //   // 토큰 확인
  //   tokenCheck().then((result) => {
  //     if (!result) {
  //       alert("인증 토큰이 만료되었습니다. 다시 로그인 해주세요");
  //       movePage("/");
  //     } else {
  //       const nvrAddress = `rtsp://admin:ahjoo2300!@@192.168.10.6:558/LiveChannel/${
  //         num - 1
  //       }/media.smp`;
  //       const cctvInf = [
  //         {
  //           eqId: `nvr${num}`,
  //           eqNm: `CCTV NVR ${num}`,
  //           nvrAddress: nvrAddress,
  //         },
  //       ];

  //       const queryString = new URLSearchParams({
  //         data: encryptData(JSON.stringify(cctvInf)),
  //       }).toString();
  //       window.open(
  //         `/cctv/viewer?${queryString}`,
  //         "_blank",
  //         "width=1200,height=700,toolbar=no,menubar=no,scrollbars=no,resizable=no,location=no,status=no"
  //       );
  //     }
  //   });
  // }

  useEffect(() => {
    dispatch(setMenu(PAGE_VALUE));

    // 토큰 확인
    tokenCheck().then((result) => {
      if (!result) {
        alert("인증 토큰이 만료되었습니다. 다시 로그인 해주세요");
        movePage("/");
      } else {
        setIsToken(true);
        fetchCCTV();
      }
    });
  }, []);

  return (
    <div className="outlet_container">
      <div id="cctv-page">
        {isToken && (
          <>
            <div className="header_container">
              <div className="title">
                <div className="title_imp">CCTV</div>
              </div>
              <div className="path">
                <FontAwesomeIcon icon={faHome} />
                <div className="text">&gt; CCTV</div>
              </div>
            </div>

            <div className="main_container_row">
              {/* CCTV 리스트 */}
              <div className="contents_container cctv-list">
                <div className="contents_header">
                  <div className="title">
                    <FontAwesomeIcon icon={faList} />
                    <div className="text">CCTV</div>
                    {showCctvs.length > 0 && (
                      <div
                        className="button_imp cctv-show-button"
                        onClick={onClickCCTVViewer}
                      >
                        화면띄우기
                      </div>
                    )}
                  </div>
                </div>
                <div className="contents_main">
                  <CCTVList
                    cctv={cctv}
                    selectCctv={selectCctv}
                    setSelectCctv={setSelectCctv}
                    showCctvs={showCctvs}
                    setShowCctvs={setShowCctvs}
                  />
                </div>
                <div className="contents_footer"></div>
              </div>

              {/* CCTV 제어 */}
              <div className="contents_container cctv-control">
                <div className="contents_header">
                  <div className="title">
                    <FontAwesomeIcon icon={faGamepad} />
                    <div className="text">CCTV 제어</div>
                  </div>
                  {Object.keys(selectCctv).length !== 0 && (
                    <div className="err">{cctvErrText}</div>
                  )}
                </div>
                <div className="contents_main">
                  <CCTVControl
                    selectCctv={selectCctv}
                    setCctvErrText={setCctvErrText}
                  />
                </div>
                <div className="contents_footer"></div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default CCTV;
