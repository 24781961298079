import Modal from "react-modal";

Modal.setAppElement("#root");

function SuccessAlert({ isOpen, setIsOpen, text }) {
  setTimeout(() => {
    setIsOpen(false);
  }, 1500);

  return (
    <Modal isOpen={isOpen} style={modalStyles}>
      <div className="modal_alert_container">
        <div style={{ marginLeft: "10px" }}>{text}</div>
      </div>
    </Modal>
  );
}
const modalStyles = {
  content: {
    width: "350px",
    height: "80px",
    padding: "20px",
    background: "#1A1A1A",
    border: "none",
    borderRadius: "5px",
    boxShadow: "-5px 5px 10px rgba(0, 0, 0, 0.7)",
    margin: "auto",
  },
  overlay: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "fixed",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 999,
    backgroundColor: "rgba(0, 0, 0, 0)",
  },
};

export default SuccessAlert;
