import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./redux/store";

import App from "./App";

/* sass 파일 */
// 공통
import "./index.css";
import "./styles/common/button.scss";
import "./styles/common/table.scss";
import "./styles/common/modal.scss";
import "./styles/common/datepicker.scss";
import "./styles/common/layout.scss";
import "./styles/common/inner-layout.scss";
// 메뉴
import "./styles/login.scss";
import "./styles/sign.scss";
import "./styles/allstatus.scss";
import "./styles/map.scss";
import "./styles/sms.scss";
import "./styles/broadcast.scss";
import "./styles/edboard.scss";
import "./styles/cctv.scss";
import "./styles/data.scss";
import "./styles/setting.scss";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>
);
