import { useState, useEffect } from "react";

import { getSatellite, getRadar } from "../../services/external/kmaAPI";
import loadingImg from "../../assets/common/loading.gif";

function SatelliteRadar() {
  const [isLoading, setIsLoading] = useState(false);
  const [type, setType] = useState("satellite");
  const [sateImg, setSateImg] = useState("");
  const [radarImg, setRadarImg] = useState("");

  function fetchSateRadar() {
    /* 위성 & 레이더 조회 API */
    setIsLoading(true);
    getSatellite().then((satellite) => {
      getRadar().then((radar) => {
        setIsLoading(false);
        //위성
        if (satellite !== null && satellite !== undefined) {
          setSateImg(satellite);
        } else {
          setSateImg("");
        }
        // 레이더
        if (radar !== null && radar !== undefined) {
          setRadarImg(radar);
        } else {
          setRadarImg("");
        }
      });
    });
  }

  useEffect(() => {
    fetchSateRadar();

    const intervalId = setInterval(() => {
      fetchSateRadar();
    }, 1800000); // 30분
    return () => clearInterval(intervalId);
  }, []);

  return (
    <div id="allstatus-sate-radar-component">
      {/* 선택 */}
      <div className="select-container">
        <div
          className={`select-item ${
            type === "satellite" ? "select-item-select" : ""
          }`}
          onClick={() => setType("satellite")}
        >
          위성
        </div>
        <div
          className={`select-item ${
            type === "radar" ? "select-item-select" : ""
          }`}
          onClick={() => setType("radar")}
        >
          레이더
        </div>
      </div>

      {/* 이미지 */}
      <div className="sate-radar-contents">
        {isLoading ? (
          <div className="loading-container">
            <img src={loadingImg} alt="" />
          </div>
        ) : (type === "satellite" && sateImg === "") ||
          (type === "radar" && radarImg === "") ? (
          <div className="data-none-container" style={{ fontSize: "0.8rem" }}>
            자료조회에 실패하였습니다
          </div>
        ) : // 위성
        type === "satellite" ? (
          <img className="sate-radar-img" src={sateImg} alt="" />
        ) : (
          // 레이더
          type === "radar" && (
            <img className="sate-radar-img" src={radarImg} alt="" />
          )
        )}
      </div>
    </div>
  );
}

export default SatelliteRadar;
