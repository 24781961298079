import axios from "axios";
import apiAddress from "../../services/apiAddress";

/* 계정 등록 */
export function sign(signInform) {
  return axios.post(
    apiAddress.sign,
    { ...signInform, regUsr: signInform.userId, useYn: "Y" },
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
}
/* 공통코드 조회 (고객사ID) */
export function getSiteID() {
  return axios
    .post(
      apiAddress.getCommonCode,
      {
        groupCode: "SIT",
        orderBy: "commonCode",
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      return response.data;
    });
}
/* 아이디 중복 체크 */
export function checkDuplicate(id) {
  return axios
    .get(apiAddress.checkDuplicate, {
      params: {
        userId: id,
      },
    })
    .then((response) => {
      return response.data;
    });
}
/* 휴대폰 인증 */
export function authCellNum(phoneNum) {
  return axios
    .get(apiAddress.authCellNum, {
      params: {
        phoneNumber: phoneNum,
      },
    })
    .then((response) => {
      return response.data;
    });
}
/* 고객사ID에 따른 메뉴조회 */
export function getTargetMenu(siteId) {
  return axios
    .get(apiAddress.getTargetMenu, { params: { targetGroup: siteId } })
    .then((response) => {
      return response.data;
    });
}
/* 이용약관 */
export function getTermCondition() {
  return axios.get(apiAddress.getTermCondition).then((response) => {
    return response.data;
  });
}
/* 메뉴저장 */
export function saveMenu(menuAuth) {
  return axios.post(apiAddress.saveMenu, menuAuth, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}
