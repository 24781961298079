import { useState, useEffect } from "react";
import axios from "axios";

import apiAddress from "../../../services/apiAddress";
import Rtu from "./device/Rtu";
import Sensor from "./device/Sensor";
import Broadcast from "./device/Broadcast";

function Device({
  selectedId,
  delData,
  setDelData,
  setIsRegOk,
  isRegOk,
  isDelOk,
}) {
  const [isEditOk, setIsEditOk] = useState(false);
  const [selectRtuId, setSelectRtuId] = useState(0);
  const [rtuData, setRtuData] = useState([]);
  const [brdData, setBrdData] = useState([]);
  const [snsrData, setSnsrData] = useState([]);

  /* RTU 정보 호출 API */
  async function fetchDataRtu() {
    try {
      // RTU 조회.
      axios
        .post(
          apiAddress.groupEquipGet,
          {
            groupId: selectedId,
            searchType: "A",
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          const responseData = response.data;
          setRtuData(responseData);
        });
    } catch (e) {
      console.log(e);
    }
  }
  /* DVC 정보 호출 API */
  async function fetchDataDvc() {
    try {
      axios
        .post(
          apiAddress.groupEquipDeviceGet,
          {
            groupId: selectedId,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          const responseData = response.data;

          // DVC 정보 호출 API.
          const fetchDvcInf = async (item) => {
            try {
              const response = await axios.post(
                apiAddress.deviceGet,
                {
                  dvcId: item.dvcId,
                },
                {
                  headers: {
                    "Content-Type": "application/json",
                  },
                }
              );
              return { ...item, info: response.data[0] };
            } catch (e) {
              console.log(e);
              return null;
            }
          };

          const fetchDvc = async () => {
            // 전체적인 DVC.
            const dvc = await Promise.all(responseData.map(fetchDvcInf));

            const snsr = dvc.filter((item) => item.info.dvcType === "SNSR");
            const brd = dvc.filter((item) => item.info.dvcType === "BRD");
            setSnsrData(snsr);
            setBrdData(brd);
          };

          fetchDvc();
        });
    } catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    fetchDataRtu();
    fetchDataDvc();
  }, [selectedId, isRegOk, isEditOk, isDelOk]);

  return (
    <>
      {/* RTU */}
      <div className="setting_alert_rtu_container">
        <div className="setting_alert_header">
          <div>RTU</div>
        </div>
        <div className="setting_alert_main">
          <Rtu
            rtuData={rtuData}
            selectId={selectRtuId}
            setSelectId={setSelectRtuId}
            isRegOk={isRegOk}
            setIsRegOk={setIsRegOk}
          />
        </div>
      </div>

      {/* 장비 */}
      <div className="setting_alert_device_container">
        {/* 센서 */}
        <div className="setting_alert_header">
          <div>센서</div>
        </div>
        <div className="setting_alert_main" style={{ overflow: "auto" }}>
          <Sensor
            snsrData={snsrData}
            selectId={selectRtuId}
            delData={delData}
            setDelData={setDelData}
            isOk={isEditOk}
            setIsOk={setIsEditOk}
          />
        </div>

        {/* 방송장비 */}
        <div
          className="setting_alert_header"
          style={{ borderTop: "1px solid #000000" }}
        >
          <div>방송장비</div>
        </div>
        <div className="setting_alert_main">
          <Broadcast
            brdData={brdData}
            selectId={selectRtuId}
            delData={delData}
            setDelData={setDelData}
          />
        </div>
      </div>
    </>
  );
}

export default Device;
