import { useState, useEffect } from "react";
import Modal from "react-modal";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";

import { MODALSTYLES } from "../../../constants/modalStyles";
import { dateTimeToFormat } from "../../../utils/dateFormat";

Modal.setAppElement("#root");

function EdboardHistoryInform({ isOpen, setIsOpen }) {
  const [inform, setInform] = useState(isOpen.data);

  function onClickClose() {
    // 닫기 버튼 클릭 시
    setIsOpen({ isOpen: false, data: {} });
  }

  useEffect(() => {
    setInform(isOpen.data);
  }, [isOpen.data]);

  return (
    <Modal isOpen={isOpen.isOpen} style={MODALSTYLES.edboardLog}>
      <div className="modal_title">
        <div>전광판 내역 정보</div>
      </div>

      <div className="modal_container">
        <div className="modal_main_column">
          <div className="edboard-history-inform-modal">
            <div className="typing-container">
              <div className="title">전송일자</div>
              <div className="one-div">{dateTimeToFormat(inform.regDt)}</div>
            </div>
            <div className="typing-container">
              <div className="title">고객사ID</div>
              <div className="two-div">{inform.siteId}</div>
              <div className="inner-title">전송자</div>
              <div className="two-div">{inform.regUsr}</div>
            </div>
            <div className="typing-container">
              <div className="title">입장 효과</div>
              <div className="one-div">{inform.entryEffect}</div>
            </div>
            <div className="typing-container">
              <div className="title">표시 시간</div>
              <div className="time-div">{inform.holdTime}</div>
              <div className="inner-title">속도</div>
              <div className="spd-container">
                <div className="spd-slider">
                  <Slider
                    className="slider"
                    min={1}
                    max={99}
                    step={1}
                    value={inform.speed}
                    trackStyle={{ background: "#418dff" }}
                    handleStyle={{ borderColor: "#000" }}
                    disabled={true}
                  />
                </div>
                <div className="spd-div">{inform.speed}</div>
              </div>
            </div>
            <div className="typing-container flex-typing-container">
              <div className="title">내용</div>
              <textarea type="text" value={inform.sendMsg} disabled={true} />
            </div>
            <div className="typing-container last-typing-container">
              <div className="title">퇴장 효과</div>
              <div className="one-div">{inform.exitEffect}</div>
            </div>
          </div>
        </div>

        <div className="modal_footer">
          <div className="button-nor-modal" onClick={onClickClose}>
            닫기
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default EdboardHistoryInform;
