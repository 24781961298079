import apiAddress from "../../services/apiAddress";
import axios from "axios";

/* 관리지역 - Equip 조회 (Equip 삭제하기 위해 관리지역에 등록되어 있는지 확인) */
export function getRegionEquip() {
  return axios
    .post(
      apiAddress.regionEquipGet,
      {
        rgnId: 0,
        searchType: "NA",
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      return response.data;
    });
}
