export function dateToFormat(date) {
  if (date !== undefined) {
    return date.slice(0, 4) + "-" + date.slice(4, 6) + "-" + date.slice(6, 8);
  } else {
    return "";
  }
}

export function dateTimeMonthToFormat(date) {
  if (date !== undefined) {
    return date.slice(0, 4) + "-" + date.slice(4, 6);
  } else {
    return "";
  }
}

export function dateTimeHourToFormat(date) {
  if (date !== undefined) {
    return (
      date.slice(0, 4) +
      "-" +
      date.slice(4, 6) +
      "-" +
      date.slice(6, 8) +
      " " +
      date.slice(8, 10)
    );
  } else {
    return "";
  }
}

export function dateTimeMinToFormat(date) {
  if (date !== undefined) {
    return (
      date.slice(0, 4) +
      "-" +
      date.slice(4, 6) +
      "-" +
      date.slice(6, 8) +
      " " +
      date.slice(8, 10) +
      ":" +
      date.slice(10, 12)
    );
  } else {
    return "";
  }
}

export function dateTimeToFormat(date) {
  if (date !== undefined) {
    return (
      date.slice(0, 4) +
      "-" +
      date.slice(4, 6) +
      "-" +
      date.slice(6, 8) +
      " " +
      date.slice(8, 10) +
      ":" +
      date.slice(10, 12) +
      ":" +
      date.slice(12, 14)
    );
  } else {
    return "";
  }
}

export function dateToString(date) {
  // 날짜 형식을 'yyyyMMdd' 형식으로 변경
  if (date !== undefined) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}${month}${day}`;
  } else {
    return "";
  }
}

export function dateToStringYYYYMMDD(date) {
  // Date()형식 'yyyy-MM-dd' 으로 변경
  if (date !== undefined) {
    const year = String(date.getFullYear());
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  } else {
    return "";
  }
}
export function dateToStringYYYYMMDDHHMin(date) {
  // Date()형식 'yyyy-MM-dd HH:Min'으로 변경
  if (date !== undefined) {
    const year = String(date.getFullYear());
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const hour = String(date.getHours()).padStart(2, "0");
    const minute = String(date.getMinutes()).padStart(2, "0");
    return `${year}-${month}-${day} ${hour}:${minute}`;
  } else {
    return "";
  }
}

export function stringToDate(date) {
  // 'yyyyMMdd'형식을 날짜 형식으로 변경.
  if (date !== undefined) {
    const year = parseInt(date.slice(0, 4));
    const month = parseInt(date.slice(4, 6)) - 1;
    const day = parseInt(date.slice(6, 8));
    const newDate = new Date(year, month, day);
    return newDate;
  }
}
