import Slider from "rc-slider";
import "rc-slider/assets/index.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faMinus } from "@fortawesome/free-solid-svg-icons";

import { CONTROLBARSTYLE } from "../../constants/mapControlbarStyle";

function MapControlBar({ mapLevel, setMapLevel }) {
  function onClickPlus() {
    // 지도 확대
    const changed = mapLevel - 1;
    if (changed > 0) {
      setMapLevel(changed);
    }
  }
  function onClickMinus() {
    // 지도 축소
    const changed = mapLevel + 1;
    if (changed < 14) {
      setMapLevel(changed);
    }
  }

  return (
    <div className="map-controlbar">
      <div
        className="controlbar-edge controlbar-edge-plus"
        onClick={onClickPlus}
      >
        <FontAwesomeIcon icon={faPlus} />
      </div>
      <div className="controlbar-slider">
        <Slider
          vertical
          reverse
          min={1}
          max={13}
          step={1}
          value={mapLevel}
          onChange={(value) => setMapLevel(value)}
          railStyle={CONTROLBARSTYLE.railStyle}
          trackStyle={CONTROLBARSTYLE.trackStyle}
          handleStyle={CONTROLBARSTYLE.handleStyle}
        />
      </div>
      <div
        className="controlbar-edge controlbar-edge-minus"
        onClick={onClickMinus}
      >
        <FontAwesomeIcon icon={faMinus} />
      </div>
    </div>
  );
}

export default MapControlBar;
