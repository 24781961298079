import React, { useState, useEffect } from "react";
import {
  ResponsiveContainer,
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  ReferenceLine,
  CartesianGrid,
} from "recharts";

import { DEVICEOPTIONS } from "../../../constants/deviceOptions";

function DisplacementGraph({ selected }) {
  const [data, setData] = useState([]);
  const [minMax1, setMinMax1] = useState({ min: 0, max: 0 });
  const [minMax2, setMinMax2] = useState({ min: 0, max: 0 });
  const [alertLine1, setAlertLine1] = useState([]);
  const [alertLine2, setAlertLine2] = useState([]);

  /* Tooltip Custom */
  const TooltipCustom = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      const date =
        payload[0].payload.date === undefined ? "" : payload[0].payload.date;

      return (
        <div className="tooltip-container">
          <div className="date-text">{date}</div>
          <div className="value-container">
            {payload.map((item) => (
              <div key={`value-item-${item.dataKey}`} className="value-item">
                <div
                  className="value-color"
                  style={{ background: item.color }}
                />
                <div className="value-title">{`${
                  item.dataKey === "avgVal1" ? "지표변위계01" : "지표변위계02"
                }(mm):`}</div>
                <div className="value">{item.payload[item.dataKey]}</div>
              </div>
            ))}
          </div>
        </div>
      );
    }
  };

  useEffect(() => {
    /* 데이터가공 & 최대값 찾기 */
    if (Array.isArray(selected) && selected.length > 0) {
      if (selected[0].data.length > 0 || selected[1].data.length > 0) {
        const selectedInData = selected[0].data;
        const selectedOutData = selected[1].data;
        let mergedData;
        if (selectedInData.length > 0) {
          mergedData = selectedInData.map((inItem) => {
            const outItem = selectedOutData.find(
              (outItem) => outItem.date === inItem.date
            );
            if (outItem !== null && outItem !== undefined) {
              return {
                date: inItem.date,
                avgVal1: inItem.avgVal,
                avgVal2: outItem.avgVal,
              };
            } else {
              return {
                date: inItem.date,
                avgVal1: inItem.avgVal,
                avgVal2: "-",
              };
            }
          });
        } else {
          mergedData = selectedOutData.map((outItem) => {
            const inItem = selectedInData.find(
              (inItem) => outItem.date === inItem.date
            );
            if (inItem !== null && inItem !== undefined) {
              return {
                date: outItem.date,
                avgVal1: inItem.avgVal,
                avgVal2: outItem.avgVal,
              };
            } else {
              return {
                date: outItem.date,
                avgVal1: "-",
                avgVal2: outItem.avgVal,
              };
            }
          });
        }

        let dataMin = 999999;
        let dataMax = -999999;
        const nullData = mergedData.map((dataItem) => {
          const tmpItem = { ...dataItem };
          for (let key in dataItem) {
            if (key !== "date") {
              if (dataItem[key] === "-") {
                tmpItem[key] = null; // "-" → null
              } else {
                const val = parseFloat(dataItem[key]); // 최대값 찾기
                dataMin = val < dataMin ? val : dataMin;
                dataMax = val > dataMax ? val : dataMax;
              }
            }
          }
          return tmpItem;
        });
        setData(nullData);
        setMinMax1({
          min: Math.round(dataMin - 2),
          max: Math.round(dataMax + 2),
        });
        setMinMax2({
          min: Math.round(dataMin - 2),
          max: Math.round(dataMax + 2),
        });
      } else {
        setData([]);
        setMinMax1({ min: 0, max: 0 });
        setMinMax2({ min: 0, max: 0 });
      }
    } else {
      setData([]);
      setMinMax1({ min: 0, max: 0 });
      setMinMax2({ min: 0, max: 0 });
    }

    /* 임계치 */
    if (Array.isArray(selected[0].dvcData) && selected[0].dvcData.length > 0) {
      const dvcData = selected[0].dvcData;
      const alert = dvcData.map((dvcItem) => {
        const alertItem = {
          unit: "mm",
          min: null,
          min2: null,
          min3: null,
          min4: null,
          max: null,
          max2: null,
          max3: null,
          max4: null,
        };
        for (let key in alertItem) {
          if (key !== "unit") {
            const val =
              dvcItem[key].toString() === DEVICEOPTIONS.sensorAnomaly.toString()
                ? null
                : Number(dvcItem[key]);
            alertItem[key] = val;
          }
        }
        return alertItem;
      });
      setAlertLine1(alert);
    }
    if (Array.isArray(selected[1].dvcData) && selected[1].dvcData.length > 0) {
      const dvcData = selected[1].dvcData;
      const alert = dvcData.map((dvcItem) => {
        const alertItem = {
          unit: "mm",
          min: null,
          min2: null,
          min3: null,
          min4: null,
          max: null,
          max2: null,
          max3: null,
          max4: null,
        };
        for (let key in alertItem) {
          if (key !== "unit") {
            const val =
              dvcItem[key].toString() === DEVICEOPTIONS.sensorAnomaly.toString()
                ? null
                : Number(dvcItem[key]);
            alertItem[key] = val;
          }
        }
        return alertItem;
      });
      setAlertLine2(alert);
    }
  }, [selected]);

  return (
    <>
      {Array.isArray(data) && data.length > 0 ? (
        <ResponsiveContainer width="100%" height="90%">
          <LineChart
            data={data}
            margin={{ left: 0, right: 20, top: 10, bottom: -10 }}
          >
            <CartesianGrid
              stroke="#595959"
              strokeWidth={0.5}
              vertical={false}
            />
            <XAxis dataKey="date" tick={false} axisLine={false} />
            <YAxis
              type="number"
              yAxisId="avgVal1YAxis"
              domain={[minMax1.min, minMax1.max]}
              tick={{ fontSize: 11 }}
              tickCount={10}
              label={{
                value: "지표변위계01",
                angle: -90,
                position: "insideLeft",
                offset: 18,
                style: {
                  textAnchor: "middle", // 텍스트 정렬
                  fontSize: 12,
                },
              }}
              axisLine={false}
            />
            <YAxis
              type="number"
              yAxisId="avgVal2YAxis"
              domain={[minMax2.min, minMax2.max]}
              tick={{ fontSize: 11 }}
              tickCount={10}
              label={{
                value: "지표변위계02",
                angle: -90,
                position: "insideLeft",
                offset: 18,
                style: {
                  textAnchor: "middle", // 텍스트 정렬
                  fontSize: 12,
                },
              }}
              axisLine={false}
            />
            <Tooltip
              content={<TooltipCustom />}
              filterNull={true}
              cursor={{
                stroke: "#757575",
                strokeWidth: 1,
                strokeDasharray: "3 3",
              }}
            />
            <Line
              type="monotone"
              dataKey="avgVal1"
              yAxisId="avgVal1YAxis"
              stroke="#1DDB16"
              strokeWidth={2}
              dot={false}
              activeDot={{ r: 4 }}
            />
            <Line
              type="monotone"
              dataKey="avgVal2"
              yAxisId="avgVal2YAxis"
              stroke="#CA09D5"
              strokeWidth={2}
              dot={false}
              activeDot={{ r: 4 }}
            />

            {alertLine1.length > 0 &&
              alertLine1.map((alertItem, index) => (
                <React.Fragment key={`${index}-alert`}>
                  {/* 최소 */}
                  <ReferenceLine
                    y={alertItem.min}
                    yAxisId="avgVal1YAxis"
                    stroke="#006B99"
                    strokeDasharray="7 4"
                    strokeWidth={1}
                  />
                  <ReferenceLine
                    y={alertItem.min2}
                    yAxisId="avgVal1YAxis"
                    stroke="#928400"
                    strokeDasharray="7 4"
                    strokeWidth={1}
                  />
                  <ReferenceLine
                    y={alertItem.min3}
                    yAxisId="avgVal1YAxis"
                    stroke="#A15300"
                    strokeDasharray="7 4"
                    strokeWidth={1}
                  />
                  <ReferenceLine
                    y={alertItem.min4}
                    yAxisId="avgVal1YAxis"
                    stroke="#8F0303"
                    strokeDasharray="7 4"
                    strokeWidth={1}
                  />
                  {/* 최대 */}
                  <ReferenceLine
                    y={alertItem.max}
                    yAxisId="avgVal1YAxis"
                    stroke="#006B99"
                    strokeDasharray="7 4"
                    strokeWidth={1}
                  />
                  <ReferenceLine
                    y={alertItem.max2}
                    yAxisId="avgVal1YAxis"
                    stroke="#928400"
                    strokeDasharray="7 4"
                    strokeWidth={1}
                  />
                  <ReferenceLine
                    y={alertItem.max3}
                    yAxisId="avgVal1YAxis"
                    stroke="#A15300"
                    strokeDasharray="7 4"
                    strokeWidth={1}
                  />
                  <ReferenceLine
                    y={alertItem.max4}
                    yAxisId="avgVal1YAxis"
                    stroke="#8F0303"
                    strokeDasharray="7 4"
                    strokeWidth={1}
                  />
                </React.Fragment>
              ))}
            {alertLine2.length > 0 &&
              alertLine2.map((alertItem, index) => (
                <React.Fragment key={`${index}-alert`}>
                  {/* 최소 */}
                  <ReferenceLine
                    y={alertItem.min}
                    yAxisId="avgVal2YAxis"
                    stroke="#006B99"
                    strokeDasharray="7 4"
                    strokeWidth={1}
                  />
                  <ReferenceLine
                    y={alertItem.min2}
                    yAxisId="avgVal2YAxis"
                    stroke="#928400"
                    strokeDasharray="7 4"
                    strokeWidth={1}
                  />
                  <ReferenceLine
                    y={alertItem.min3}
                    yAxisId="avgVal2YAxis"
                    stroke="#A15300"
                    strokeDasharray="7 4"
                    strokeWidth={1}
                  />
                  <ReferenceLine
                    y={alertItem.min4}
                    yAxisId="avgVal2YAxis"
                    stroke="#8F0303"
                    strokeDasharray="7 4"
                    strokeWidth={1}
                  />
                  {/* 최대 */}
                  <ReferenceLine
                    y={alertItem.max}
                    yAxisId="avgVal2YAxis"
                    stroke="#006B99"
                    strokeDasharray="7 4"
                    strokeWidth={1}
                  />
                  <ReferenceLine
                    y={alertItem.max2}
                    yAxisId="avgVal2YAxis"
                    stroke="#928400"
                    strokeDasharray="7 4"
                    strokeWidth={1}
                  />
                  <ReferenceLine
                    y={alertItem.max3}
                    yAxisId="avgVal2YAxis"
                    stroke="#A15300"
                    strokeDasharray="7 4"
                    strokeWidth={1}
                  />
                  <ReferenceLine
                    y={alertItem.max4}
                    yAxisId="avgVal2YAxis"
                    stroke="#8F0303"
                    strokeDasharray="7 4"
                    strokeWidth={1}
                  />
                </React.Fragment>
              ))}
          </LineChart>
        </ResponsiveContainer>
      ) : (
        <div className="data-none-container" style={{ fontSize: "0.8rem" }}>
          금일 데이터 내역이 없습니다
        </div>
      )}
    </>
  );
}

export default DisplacementGraph;
