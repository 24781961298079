import React, { useState, useEffect } from "react";
import {
  createColumnHelper,
  flexRender,
  useReactTable,
  getCoreRowModel,
  getSortedRowModel,
} from "@tanstack/react-table";
import axios from "axios";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";

import apiAddress from "../../../../services/apiAddress";
import RtuInform from "../../../common/inform/RtuInform";
import RtuDevice from "./RtuDevice";

function Rtu({ rtuData, delData, setDelData }) {
  const [isInform, setIsInform] = useState({
    isOpen: false,
    data: {},
  });
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);
  const columnHelper = createColumnHelper();
  const checkColumn = {
    id: "check",
    accessor: "check",
    header: "",
    size: 20,
    enableSorting: false,
    cell: ({ row }) => (
      <input
        type="checkbox"
        checked={
          delData.length > 0 &&
          delData.find((item) => item === row.original.rgnEqId)
        }
        onChange={() => onChangeCheck(row.original)}
        style={{ cursor: "pointer" }}
      />
    ),
  };
  const angleIconColumn = {
    id: "angleIcon",
    accessor: "angle",
    header: "",
    enableSorting: false,
    size: 10,
    cell: ({ row }) => {
      return row.original.angle ? (
        <FontAwesomeIcon icon={faAngleUp} />
      ) : (
        <FontAwesomeIcon icon={faAngleDown} />
      );
    },
  };
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  function dateToFormat(date) {
    return date.slice(0, 4) + "-" + date.slice(4, 6) + "-" + date.slice(6, 8);
  }
  function onChangeCheck(row) {
    // 체크 이벤트.
    if (delData.find((item) => item === row.rgnEqId)) {
      // 체크 => 체크해제.
      const clearData = delData.filter((item) => item !== row.rgnEqId);
      setDelData(clearData);
    } else {
      // 체크해제 => 체크.
      setDelData([...delData, row.rgnEqId]);
    }
  }
  function onClickRow(row) {
    // 행 클릭.
    const newData = data.map((item) => {
      if (item.eqId === row.eqId) {
        return { ...item, angle: !item.angle };
      } else {
        return item;
      }
    });
    setData(newData);
  }
  function onDoubleClickRow(row) {
    // 행 더블 클릭.
    setIsInform({
      isOpen: true,
      data: row,
    });
  }

  /* RTU - DVC 조회 API */
  async function fetchData() {
    try {
      const fetchDataDvc = async (item) => {
        try {
          const response = await axios.post(
            apiAddress.deviceGet,
            {
              eqId: item.eqId,
            },
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          );
          const responseData = response.data.filter(
            (device) => device.useYn === "Y"
          );
          return { ...item, dvcData: responseData };
        } catch (e) {
          console.log(e);
          return null;
        }
      };

      const fetchRtuDvc = async () => {
        const rtuDvc = await Promise.all(rtuData.map(fetchDataDvc));
        const rtu = rtuDvc.map((item) => {
          return { ...item, angle: false };
        });
        const column = [
          checkColumn,
          columnHelper.accessor("eqNm", {
            header: "RTU명",
            size: "auto",
          }),
          columnHelper.accessor(
            (row) => {
              return dateToFormat(row.instlDt);
            },
            {
              id: "instlDt",
              header: "설치일자",
              size: 80,
            }
          ),
          columnHelper.accessor("mnfct", { header: "제조업체", size: 120 }),
          columnHelper.accessor("commType", {
            header: "통신",
            size: 50,
          }),
          columnHelper.accessor("commAddr", {
            header: "통신정보",
            size: 100,
          }),
          columnHelper.accessor("commSpd", {
            header: "BaudRate",
            size: 50,
          }),
          angleIconColumn,
        ];

        setData(rtu);
        setColumns(column);
      };

      fetchRtuDvc();
    } catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    fetchData();
  }, [rtuData]);
  useEffect(() => {
    const column = [
      checkColumn,
      columnHelper.accessor("eqNm", {
        header: "RTU명",
        size: "auto",
      }),
      columnHelper.accessor(
        (row) => {
          return dateToFormat(row.instlDt);
        },
        {
          id: "instlDt",
          header: "설치일자",
          size: 80,
        }
      ),
      columnHelper.accessor("mnfct", { header: "제조업체", size: 120 }),
      columnHelper.accessor("commType", {
        header: "통신",
        size: 50,
      }),
      columnHelper.accessor("commAddr", {
        header: "통신정보",
        size: 100,
      }),
      columnHelper.accessor("commSpd", {
        header: "BaudRate",
        size: 50,
      }),
      angleIconColumn,
    ];
    setColumns(column);
  }, [delData]);

  return (
    <>
      {isInform.isOpen && (
        <RtuInform isOpen={isInform} setIsOpen={setIsInform} />
      )}
      {rtuData.length > 0 ? ( // 데이터 있을 경우.
        <table className="react_table">
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th
                    key={header.id}
                    style={{
                      width: isNaN(header.getSize())
                        ? "auto"
                        : `${header.getSize()}px`,
                      minWidth: isNaN(header.getSize())
                        ? "auto"
                        : `${header.getSize()}px`,
                      maxWidth: isNaN(header.getSize())
                        ? "auto"
                        : `${header.getSize()}px`,
                      textAlign:
                        header.id === "eqNm" ||
                        header.id === "mnfct" ||
                        header.id === "commAddr"
                          ? "left"
                          : "center",
                    }}
                  >
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row, index) => (
              <React.Fragment key={row.id}>
                <tr
                  key={row.id}
                  onClick={() => onClickRow(row.original)}
                  style={{ cursor: "pointer" }}
                >
                  {row.getVisibleCells().map((cell) => (
                    <td
                      key={cell.id}
                      onClick={(e) => {
                        if (cell.column.id === "check") {
                          e.stopPropagation();
                        }
                      }}
                      style={{
                        width: isNaN(cell.column.getSize())
                          ? "auto"
                          : `${cell.column.getSize()}px`,
                        minWidth: isNaN(cell.column.getSize())
                          ? "auto"
                          : `${cell.column.getSize()}px`,
                        maxWidth: isNaN(cell.column.getSize())
                          ? "auto"
                          : `${cell.column.getSize()}px`,
                        textAlign:
                          (cell.column.id === "check" ||
                            cell.column.id === "instlDt" ||
                            cell.column.id === "commType" ||
                            cell.column.id === "commSpd" ||
                            cell.column.id === "angleIcon") &&
                          "center",
                      }}
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </td>
                  ))}
                </tr>
                {row.original.angle && row.original.dvcData.length > 0 && (
                  <tr key="sub_table-tr">
                    <td
                      className="sub_table-td"
                      colSpan={8}
                      style={{ padding: "0" }}
                    >
                      <RtuDevice dvcData={row.original.dvcData} />
                    </td>
                  </tr>
                )}
              </React.Fragment>
            ))}
          </tbody>
        </table>
      ) : (
        // 데이터 없을 경우
        <div className="setting_data_none_container">
          <div>등록된 RTU가 없습니다</div>
        </div>
      )}
    </>
  );
}

export default Rtu;
