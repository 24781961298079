import { useState, useEffect } from "react";

import { getReservoirDevice } from "../../services/internal/rtuAPI";
import {
  getRsvLevel,
  getRsvGroundLevel,
  getRsvDisplacement,
  getRsvRain,
} from "../../services/internal/dataAPI";
import { getFromDtAndToDt } from "../../utils/data";
import loadingImg from "../../assets/common/loading.gif";
import ReservoirGraph from "./data-reservoir/ReservoirGraph";
import GroundLevelGraph from "./data-reservoir/GroundLevelGraph";
import DisplacementGraph from "./data-reservoir/DisplacementGraph";
import RainGraph from "./data-reservoir/RainGraph";

function DataReservoir({ selected }) {
  const [isLoading, setIsLoading] = useState(false);
  const [selectedReservoir, setSelectedReservoir] = useState({}); // 저수지에 따른 데이터
  const [selectedGround, setSelectedGround] = useState([]);
  const [selectedDis, setSelectedDis] = useState([]);
  const [selectedRain, setSelectedRain] = useState({});

  function fetchData() {
    /* 데이터 조회 API */
    const term = "min";
    const fromDt = getFromDtAndToDt(term, new Date()).fromDt;
    const toDt = getFromDtAndToDt(term, new Date()).toDt;
    getReservoirDevice().then((rsvDevice) => {
      getRsvLevel(rsvDevice, term, fromDt, toDt).then((rsvItem) => {
        getRsvGroundLevel(rsvDevice, term, fromDt, toDt).then((groundItem) => {
          getRsvDisplacement(rsvDevice, term, fromDt, toDt).then((disItem) => {
            getRsvRain(rsvDevice, term, fromDt, toDt).then((rainItem) => {
              // 저수위
              if (Array.isArray(rsvItem) && rsvItem.length > 0) {
                let target;
                switch (selected.id) {
                  case 1:
                    target = rsvItem.find((item) => item.eqId === 26);
                    break;
                  case 2:
                    target = rsvItem.find((item) => item.eqId === 33);
                    break;
                  case 3:
                    target = rsvItem.find((item) => item.eqId === 40);
                    break;
                  case 4:
                    target = rsvItem.find((item) => item.eqId === 47);
                    break;
                }
                if (target !== null && target !== undefined) {
                  setSelectedReservoir(target);
                } else {
                  setSelectedReservoir({});
                }
              } else {
                setSelectedReservoir({});
              }
              // 지하수위
              if (Array.isArray(groundItem) && groundItem.length > 0) {
                let targetIn, targetOut;
                switch (selected.id) {
                  case 1:
                    targetIn = groundItem.find((item) => item.eqId === 27);
                    targetOut = groundItem.find((item) => item.eqId === 28);
                    break;
                  case 2:
                    targetIn = groundItem.find((item) => item.eqId === 34);
                    targetOut = groundItem.find((item) => item.eqId === 35);
                    break;
                  case 3:
                    targetIn = groundItem.find((item) => item.eqId === 41);
                    targetOut = groundItem.find((item) => item.eqId === 42);
                    break;
                  case 4:
                    targetIn = groundItem.find((item) => item.eqId === 48);
                    targetOut = groundItem.find((item) => item.eqId === 49);
                    break;
                }
                if (
                  (targetIn !== null && targetIn !== undefined) ||
                  (targetOut !== null && targetOut !== undefined)
                ) {
                  setSelectedGround([targetIn, targetOut]);
                } else {
                  setSelectedGround([]);
                }
              } else {
                setSelectedGround({});
              }
              // 지표변위
              if (Array.isArray(disItem) && disItem.length > 0) {
                let target1, target2;
                switch (selected.id) {
                  case 1:
                    target1 = disItem.find((item) => item.eqId === 29);
                    target2 = disItem.find((item) => item.eqId === 30);
                    break;
                  case 2:
                    target1 = disItem.find((item) => item.eqId === 36);
                    target2 = disItem.find((item) => item.eqId === 37);
                    break;
                  case 3:
                    target1 = disItem.find((item) => item.eqId === 43);
                    target2 = disItem.find((item) => item.eqId === 44);
                    break;
                  case 4:
                    target1 = disItem.find((item) => item.eqId === 50);
                    target2 = disItem.find((item) => item.eqId === 51);
                    break;
                }
                if (
                  (target1 !== null && target1 !== undefined) ||
                  (target2 !== null && target2 !== undefined)
                ) {
                  setSelectedDis([target1, target2]);
                } else {
                  setSelectedDis([]);
                }
              } else {
                setSelectedDis([]);
              }
              // 강우
              if (Array.isArray(rainItem) && rainItem.length > 0) {
                let target;
                switch (selected.id) {
                  case 1:
                    target = rainItem.find((item) => item.eqId === 31);
                    break;
                  case 2:
                    target = rainItem.find((item) => item.eqId === 38);
                    break;
                  case 3:
                    target = rainItem.find((item) => item.eqId === 45);
                    break;
                  case 4:
                    target = rainItem.find((item) => item.eqId === 52);
                    break;
                }
                if (target !== null && target !== undefined) {
                  setSelectedRain(target);
                } else {
                  setSelectedRain({});
                }
              } else {
                setSelectedRain({});
              }
              setIsLoading(false);
            });
          });
        });
      });
    });
  }
  useEffect(() => {
    setIsLoading(true);
    fetchData();

    const intervalId = setInterval(() => {
      fetchData();
    }, 60000); // 1분
    return () => clearInterval(intervalId);
  }, [selected]);

  return (
    <div id="allstatus-data-component">
      {/* 저수위계 */}
      <div className="data-graph-container">
        <div className="title">저수위계</div>
        <div className="graph">
          {isLoading ? (
            <div className="loading-container">
              <img src={loadingImg} alt="" />
            </div>
          ) : Object.keys(selectedReservoir).length === 0 ? (
            <div className="data-none-container" style={{ fontSize: "0.8rem" }}>
              등록된 저수위계가 없습니다
            </div>
          ) : (
            <ReservoirGraph selected={selectedReservoir} />
          )}
        </div>
      </div>
      {/* 지하수위계 */}
      <div className="data-graph-container">
        <div className="title">지하수위계</div>
        <div className="graph">
          {isLoading ? (
            <div className="loading-container">
              <img src={loadingImg} alt="" />
            </div>
          ) : !Array.isArray(selectedGround) || selectedGround.length <= 0 ? (
            <div className="data-none-container">
              등록된 지하수위계가 없습니다
            </div>
          ) : (
            <GroundLevelGraph selected={selectedGround} />
          )}
        </div>
      </div>
      {/* 지표변위계 */}
      <div className="data-graph-container">
        <div className="title">지표변위계</div>
        <div className="graph">
          {isLoading ? (
            <div className="loading-container">
              <img src={loadingImg} alt="" />
            </div>
          ) : !Array.isArray(selectedDis) || selectedDis.length <= 0 ? (
            <div className="data-none-container">
              등록된 지표변위계가 없습니다
            </div>
          ) : (
            <DisplacementGraph selected={selectedDis} />
          )}
        </div>
      </div>
      {/* 강우량계 */}
      <div className="data-graph-container">
        <div className="title">강우량계</div>
        <div className="graph">
          {isLoading ? (
            <div className="loading-container">
              <img src={loadingImg} alt="" />
            </div>
          ) : Object.keys(selectedRain).length === 0 ? (
            <div className="data-none-container">
              등록된 강우량계가 없습니다
            </div>
          ) : (
            <RainGraph selected={selectedRain} />
          )}
        </div>
      </div>
    </div>
  );
}

export default DataReservoir;
