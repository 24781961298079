import { useState, useEffect } from "react";
import {
  flexRender,
  useReactTable,
  getCoreRowModel,
  createColumnHelper,
} from "@tanstack/react-table";

import { getDataTableColumn } from "../../../utils/data";
import MinLevelTable from "./MinLevelTable";

function LevelTable({ term, levelData, selected, setSelected }) {
  const [data, setData] = useState(levelData);
  const [columns, setColumns] = useState([]);
  const columnHelper = createColumnHelper();
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  function onClickRow(row) {
    // 행 클릭 시
    setSelected(row.original);
  }

  useEffect(() => {
    if (levelData.find((levelItem) => levelItem.data.length !== 0)) {
      if (term !== "min") {
        const column = [
          columnHelper.accessor("eqNm", { header: "센서명", size: 200 }),
          ...getDataTableColumn(term),
        ];
        setColumns(column);
      }
    }
    setData(levelData);
  }, [levelData]);

  return (
    <div id="data-table-component">
      {term === "min" ? (
        // 분별 조회
        <MinLevelTable
          levelData={levelData}
          selected={selected}
          setSelected={setSelected}
        />
      ) : (
        // 월별/일별/시간 조회
        <div className="list">
          <table className="react-data-table">
            <thead>
              {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <th
                      key={header.id}
                      onClick={header.column.getToggleSortingHandler()}
                      style={{
                        width: isNaN(header.getSize())
                          ? "auto"
                          : `${header.getSize()}px`,
                      }}
                    >
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody>
              {table.getRowModel().rows.map((row, index) => (
                <tr
                  key={row.id}
                  className={
                    selected.eqId === row.original.eqId
                      ? "tr-select"
                      : index % 2 === 0
                      ? "tr-pointer tr-even"
                      : "tr-pointer tr-odd"
                  }
                  onClick={() => onClickRow(row)}
                >
                  {row.getVisibleCells().map((cell) => (
                    <td
                      key={cell.id}
                      style={{
                        width: isNaN(cell.column.getSize())
                          ? "auto"
                          : `${cell.column.getSize()}px`,
                      }}
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}

export default LevelTable;
