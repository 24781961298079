import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { setMenu, setSubmenu } from "../../../redux/modules/menu";
import { useNavigate } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHome,
  faUser,
  faList,
  faMessage,
} from "@fortawesome/free-solid-svg-icons";

import { tokenCheck } from "../../../utils/tokenCheck";
import { getCurrTime, zeroPlusNum } from "../../../utils/time";
import {
  sendSMS,
  getSMS,
  getDetailSMS,
  getReceiverSMS,
} from "../../../services/internal/smsAPI";
import Alert from "../../../components/common/window/Alert";
import Confirm from "../../../components/common/window/Confirm";
import ReceiverList from "../../../components/sms/smsTransmission/ReceiverList";
import Transmission from "../../../components/sms/smsTransmission/Transmission";
import SMSList from "../../../components/sms/smsTransmission/SMSList";

const PAGE_VALUE = "SMS";
const SUBPAGE_VALUE = "SMS전송";

function SMSTransmission() {
  const dispatch = useDispatch();
  const [isToken, setIsToken] = useState(false);
  const movePage = useNavigate();
  // 수신자
  const [receiverData, setReceiverData] = useState([]);
  const [receiver, setReceiver] = useState([]);
  // SMS 전송 정보
  const [isConfirmSend, setIsConfirmSend] = useState({
    isOpen: false,
    text: "",
  });
  const [isAlertTransInform, setIsAlertTransInform] = useState(false); // SMS 전송 제목 및 내용 입력X
  const [isAlertTransRec, setIsAlertTransRec] = useState(false);
  const [transInform, setTransInform] = useState({
    title: "",
    content: "",
  });
  const [receiverString, setReceiverString] = useState("");
  const [msgString, setMsgString] = useState("");
  // SMS 최근 내역
  const [isSendOk, setIsSendOk] = useState(false);
  const [smsHistory, setSmsHistory] = useState([]);

  function onClickTransmission() {
    // 전송 버튼
    // 토큰 확인
    tokenCheck().then((result) => {
      if (!result) {
        alert("인증 토큰이 만료되었습니다. 다시 로그인 해주세요");
        movePage("/");
      } else {
        if (transInform.title === "" || transInform.content === "") {
          // 입력X
          setIsAlertTransInform(true);
        } else if (receiver.length === 0) {
          // 수신자X
          setIsAlertTransRec(true);
        } else {
          // 전송
          const receiverStringPr = receiver
            .map((inform) => {
              return inform.commonRef7;
            })
            .join(",");
          const msgStringPr = transInform.content;
          setReceiverString(receiverStringPr);
          setMsgString(msgStringPr);

          setIsConfirmSend({
            isOpen: true,
            text: "입력하신 정보로 전송을 하시겠습니까?",
          });
        }
      }
    });
  }

  /* SMS 내역 조회 */
  function fetchSMS(page, startDate, endDate) {
    getSMS(page, 10, startDate, endDate).then((sms) => {
      // 데이터 가공
      if (sms.list.length !== 0) {
        const promises = sms.list.map((smsInform) => {
          const msgLog = JSON.parse(smsInform.sendLog);
          return getDetailSMS(1, 35, msgLog.msg_id).then((detail) => {
            let smsState = "";
            let failCount = 0;
            detail.list.forEach((detailInform) => {
              if (
                detailInform.sms_state !== "발송완료" &&
                detailInform.sms_state !== "전송중"
              ) {
                // 발송 실패 건 O
                failCount = failCount + 1;
              }
              if (detailInform.sms_state === "전송중") {
                // 발송 중
                failCount = failCount + 999;
              }
            });

            const smsCnt =
              parseInt(msgLog.success_cnt) + parseInt(msgLog.error_cnt);
            if (failCount >= 999) {
              // 전송중
              smsState = "전송요청완료";
            } else if (failCount > 0) {
              // 전송 실패
              smsState = "전송실패 " + (smsCnt - failCount) + "/" + smsCnt;
            } else {
              // 전송 성공
              smsState = "전송성공 " + smsCnt + "/" + smsCnt;
            }

            return { ...smsInform, smsState: smsState, detail: detail.list };
          });
        });

        Promise.all(promises).then((promise) => {
          setSmsHistory(promise);
        });
      } else {
        setSmsHistory([]);
      }
    });
  }
  /* SMS 수신자 목록 조회 */
  function fetchSMSReceiver() {
    getReceiverSMS().then((receivers) => {
      setReceiverData(receivers);
    });
  }

  useEffect(() => {
    dispatch(setMenu(PAGE_VALUE));
    dispatch(setSubmenu(SUBPAGE_VALUE));

    // 토큰 확인
    tokenCheck().then((result) => {
      if (!result) {
        alert("인증 토큰이 만료되었습니다. 다시 로그인 해주세요");
        movePage("/");
      } else {
        setIsToken(true);
      }
    });

    const today =
      getCurrTime().year +
      zeroPlusNum(getCurrTime().month) +
      zeroPlusNum(getCurrTime().day);
    fetchSMS(0, today + "000000", today + "235959");
    fetchSMSReceiver();
  }, []);
  useEffect(() => {
    if (isSendOk) {
      const today =
        getCurrTime().year +
        zeroPlusNum(getCurrTime().month) +
        zeroPlusNum(getCurrTime().day);
      fetchSMS(0, today + "000000", today + "235959");

      setIsSendOk(false);
    }
  }, [isSendOk]);

  return (
    <div className="outlet_container">
      {
        // SMS 전송 제목 및 내용 입력X
        isAlertTransInform && (
          <Alert
            isOpen={isAlertTransInform}
            setIsOpen={setIsAlertTransInform}
            text="전송할 제목과 내용을 모두 입력해주세요"
          />
        )
      }
      {
        // SMS 전송 수신자X
        isAlertTransRec && (
          <Alert
            isOpen={isAlertTransRec}
            setIsOpen={setIsAlertTransRec}
            text="SMS를 받을 수신자를 추가해주세요"
          />
        )
      }
      {
        // SMS 전송
        isConfirmSend.isOpen && (
          <Confirm
            isOpen={isConfirmSend}
            setIsOpen={setIsConfirmSend}
            active={() => sendSMS(receiverString, msgString, setIsSendOk)}
          />
        )
      }

      {isToken && (
        <>
          <div className="header_container">
            <div className="title">
              <div className="title_imp">SMS 전송</div>
            </div>
            <div className="path">
              <FontAwesomeIcon icon={faHome} />
              <div className="text">&gt; SMS &gt; SMS전송</div>
            </div>
          </div>

          <div className="main_container">
            <div id="sms-transmission-page">
              <div id="sms-trans-receiver-transmission">
                {/* 수신자 목록 */}
                <div id="sms-trans-receiver">
                  <div className="contents_container">
                    <div className="contents_header">
                      <div className="title">
                        <FontAwesomeIcon icon={faUser} />
                        <div className="text">수신자</div>
                      </div>
                    </div>
                    <div className="contents_main">
                      <ReceiverList
                        receiverData={receiverData}
                        receiver={receiver}
                        setReceiver={setReceiver}
                      />
                    </div>
                    <div className="contents_footer"></div>
                  </div>
                </div>

                {/* 전송 정보 */}
                <div id="sms-trans-transmission">
                  <div className="contents_container">
                    <div className="contents_header">
                      <div className="title">
                        <FontAwesomeIcon icon={faMessage} />
                        <div className="text">SMS 전송</div>
                      </div>
                      <div className="button_imp" onClick={onClickTransmission}>
                        전송
                      </div>
                    </div>
                    <div className="contents_main">
                      <Transmission
                        inform={transInform}
                        setInform={setTransInform}
                      />
                    </div>
                    <div className="contents_footer"></div>
                  </div>
                </div>
              </div>

              {/* SMS 최근 내역 */}
              <div id="sms-trans-history-list">
                <div className="contents_container">
                  <div className="contents_header">
                    <div className="title">
                      <FontAwesomeIcon icon={faList} />
                      <div className="text">SMS 최근 내역</div>
                    </div>
                    <div>
                      {zeroPlusNum(getCurrTime().year) +
                        "-" +
                        zeroPlusNum(getCurrTime().month) +
                        "-" +
                        zeroPlusNum(getCurrTime().day)}
                    </div>
                  </div>
                  <div className="contents_main">
                    <SMSList smsHistory={smsHistory} />
                  </div>
                  <div className="contents_footer"></div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default SMSTransmission;
