import { useState, useEffect } from "react";
import {
  createColumnHelper,
  flexRender,
  useReactTable,
  getCoreRowModel,
  getSortedRowModel,
} from "@tanstack/react-table";

import { sampleData } from "../../../constants/data/sampleData";

function BroadList() {
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);
  const columnHelper = createColumnHelper();
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  useEffect(() => {
    const sample = sampleData.broadHistory;
    const column = [
      columnHelper.accessor("dvcNm", {
        header: "방송그룹",
        size: 90,
      }),
      columnHelper.accessor("title", {
        header: "방송제목",
        size: 80,
      }),
      columnHelper.accessor("sendDt", {
        header: "방송시작",
        size: 60,
      }),
      columnHelper.accessor("sendDt", {
        header: "방송종료",
        size: 60,
      }),
      columnHelper.accessor("msg", {
        header: "내용",
        size: 150,
      }),
      columnHelper.accessor(
        () => {
          return "SYSTEM";
        },
        {
          id: "brdUsr",
          header: "사용자",
          size: 50,
        }
      ),
      columnHelper.accessor("status", {
        header: "처리상태",
        size: 30,
      }),
    ];

    setData(sample);
    setColumns(column);
  }, []);

  return (
    <div className="brd-send-history">
      <div className="list">
        <table className="react_table">
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th
                    key={header.id}
                    onClick={header.column.getToggleSortingHandler()}
                    style={{
                      width: isNaN(header.getSize())
                        ? "auto"
                        : `${header.getSize()}px`,
                      minWidth: isNaN(header.getSize())
                        ? "auto"
                        : `${header.getSize()}px`,
                      maxWidth: isNaN(header.getSize())
                        ? "auto"
                        : `${header.getSize()}px`,
                      textAlign:
                        header.id === "brdTitle" || header.id === "brdCntn"
                          ? "left"
                          : "center",
                    }}
                  >
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row, index) => (
              <tr key={row.id}>
                {row.getVisibleCells().map((cell) => (
                  <td
                    key={cell.id}
                    style={{
                      width: isNaN(cell.column.getSize())
                        ? "auto"
                        : `${cell.column.getSize()}px`,
                      minWidth: isNaN(cell.column.getSize())
                        ? "auto"
                        : `${cell.column.getSize()}px`,
                      maxWidth: isNaN(cell.column.getSize())
                        ? "auto"
                        : `${cell.column.getSize()}px`,
                      textAlign:
                        (cell.column.id === "sendDt" ||
                          cell.column.id === "msg" ||
                          cell.column.id === "brdUsr" ||
                          cell.column.id === "status") &&
                        "center",
                      color:
                        cell.column.id === "status" &&
                        row.original.status.includes("에러") &&
                        "#ED0000",
                    }}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default BroadList;
