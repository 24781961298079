import { useRef, useState } from "react";
import Modal from "react-modal";
import axios from "axios";

import apiAddress from "../../../services/apiAddress";
import Alert from "../../common/window/Alert";
import Edit from "../../common/window/Edit";

Modal.setAppElement("#root");

function GroupEdit({ isOpen, setIsOpen, isOk, setIsOk }) {
  const [isAlert, setIsAlert] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [data, setData] = useState(isOpen.data);
  const groupNmRef = useRef(null);

  function onClickClose() {
    setIsOpen({
      isOpen: false,
      data: {},
    });
  }
  function onClickEdit() {
    if (data.groupNm === "") {
      setIsAlert(true);
      groupNmRef.current.focus();
    } else {
      setIsEdit(true);
    }
  }

  /* 그룹 수정 API */
  async function fetchData() {
    try {
      axios
        .post(
          apiAddress.groupSave,
          {
            ...data,
            modUsr: "SHMOD",
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          setIsOk(!isOk);
          setIsOpen({
            isOpen: false,
            data: {},
          });
        });
    } catch (e) {
      console.log(e);
    }
  }

  return (
    <Modal isOpen={isOpen.isOpen} style={modalStyles}>
      {isAlert && (
        <Alert
          isOpen={isAlert}
          setIsOpen={setIsAlert}
          text="필수 정보를 입력해주세요"
        />
      )}
      {isEdit && (
        <Edit isOpen={isEdit} setIsOpen={setIsEdit} editData={fetchData} />
      )}
      <div className="modal_title">
        <div>방송 그룹 수정</div>
      </div>
      <div className="modal_container">
        {/* Main */}
        <div className="modal_main_column">
          <div className="modal_input">
            <div className="title">방송 그룹명</div>
            <input
              ref={groupNmRef}
              type="text"
              placeholder="필수 입력 *"
              value={data.groupNm}
              onChange={(e) => setData({ ...data, groupNm: e.target.value })}
              spellCheck={false}
            />
          </div>
          <div
            className="modal_input"
            style={{ flex: "1", borderBottom: "none" }}
          >
            <div className="title">메모</div>
            <textarea
              placeholder="메모 입력"
              value={data.groupDtl}
              onChange={(e) => setData({ ...data, groupDtl: e.target.value })}
              spellCheck={false}
            />
          </div>
        </div>

        {/* Footer */}
        <div className="modal_footer">
          <div className="button_nor" onClick={onClickClose}>
            닫기
          </div>
          <div
            className="button_imp"
            onClick={onClickEdit}
            style={{ marginLeft: "10px" }}
          >
            수정
          </div>
        </div>
      </div>
    </Modal>
  );
}

const modalStyles = {
  content: {
    width: "620px",
    height: "300px",
    padding: "0px",
    background: "#202020",
    border: "none",
    borderRadius: "5px",
    boxShadow: "-5px 5px 10px rgba(0, 0, 0, 0.7)",
    margin: "auto",
  },
  overlay: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "fixed",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: "rgba(0, 0, 0, 0.4)",
  },
};

export default GroupEdit;
