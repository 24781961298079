import React, { useState, useEffect } from "react";
import {
  createColumnHelper,
  flexRender,
  useReactTable,
  getCoreRowModel,
  getSortedRowModel,
} from "@tanstack/react-table";
import Modal from "react-modal";

Modal.setAppElement("#root");

const snsrTypes = [
  { value: "01", text: "강우량계" },
  { value: "02", text: "수위계" },
  { value: "03", text: "변위계" },
  { value: "04", text: "토양함수비" },
  { value: "05", text: "거리측정기" },
  { value: "06", text: "적설계" },
  { value: "07", text: "지하수위계" },
  { value: "08", text: "경사계" },
  { value: "09", text: "간극수압계" },
  { value: "10", text: "진동계/진동가속도계" },
  { value: "11", text: "지중경사계" },
  { value: "12", text: "하중계" },
  { value: "13", text: "구조물경사계" },
  { value: "14", text: "GNSS(GPS)" },
  { value: "15", text: "지표변위계" },
  { value: "16", text: "조위계" },
  { value: "17", text: "균열감지기" },
  { value: "18", text: "온도계" },
  { value: "19", text: "습도계" },
  { value: "20", text: "침수감지기" },
  { value: "21", text: "가속도계" },
  { value: "22", text: "변형률계" },
  { value: "23", text: "풍향계" },
  { value: "24", text: "유량계" },
  { value: "25", text: "유속계" },
];

function SameTypeSensor({
  isOpen,
  setIsOpen,
  snsrData,
  setSnsrData,
  regData,
  setRegData,
  selectData,
  setSelectData,
  selectSetting,
}) {
  const [saveData, setSaveData] = useState([]);
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);
  const columnHelper = createColumnHelper();
  const checkColumn = {
    id: "check",
    accessor: "check",
    header: (
      <input
        type="checkbox"
        onChange={onChangeAllCheck}
        checked={saveData.length - 1 === data.length}
        style={{ cursor: "pointer" }}
      />
    ),
    enableSorting: false,
    size: 10,
    cell: ({ row }) => {
      return (
        <input
          type="checkbox"
          onChange={() => onChangeCheck(row.original)}
          checked={saveData.find((item) => item.dvcId === row.original.dvcId)}
          style={{ cursor: "pointer" }}
        />
      );
    },
  };
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  function onChangeAllCheck() {
    // 행 전체 체크박스.
    if (saveData.length - 1 === data.length) {
      // 전체 체크 => 체크 해제.
      setSaveData([{ ...selectData, ...selectSetting }]);
    } else {
      if (saveData.length === 1) {
        // 전체 체크 해제 => 전체 체크.
        const newData = data.map((item) => {
          return { ...item, ...selectSetting };
        });
        setSaveData([...saveData, ...newData]);
      } else {
        // 일부 체크 => 전체 체크.
        const filterData = data.filter(
          (item) => !saveData.some((item2) => item2.dvcId === item.dvcId)
        );
        const newData = filterData.map((item) => {
          return { ...item, ...selectSetting };
        });
        setSaveData([...saveData, ...newData]);
      }
    }
  }
  function onChangeCheck(row) {
    // 행 안의 체크박스 클릭.
    if (saveData.find((item) => item.dvcId === row.dvcId)) {
      // 체크 => 체크 해제.
      const newData = saveData.filter((item) => item.dvcId !== row.dvcId);
      setSaveData(newData);
    } else {
      // 체크 해제 => 체크.
      setSaveData([...saveData, { ...row, ...selectSetting }]);
    }
  }
  function onClickCancel() {
    // 취소.
    setIsOpen(false);
  }
  function onClickSetting() {
    // 확인.
    const filterData = data.filter(
      (item) => !saveData.some((item2) => item2.dvcId === item.dvcId)
    );
    const newRegData = regData.map((item) => {
      let updateObject = item;
      saveData.map((item2) => {
        if (item.dvcId === item2.dvcId) {
          updateObject = {
            groupId: item.groupId,
            eqId: item.eqId,
            dvcId: item2.dvcId,
            warningMin: item2.warningMin,
            warningMin2: item2.warningMin2,
            warningMin3: item2.warningMin3,
            warningMax: item2.warningMax,
            warningMax2: item2.warningMax2,
            warningMax3: item2.warningMax3,
            alertMin: item2.alertMin,
            alertMin2: item2.alertMin2,
            alertMin3: item2.alertMin3,
            alertMax: item2.alertMax,
            alertMax2: item2.alertMax2,
            alertMax3: item2.alertMax3,
            evctnMin: item2.evctnMin,
            evctnMin2: item2.evctnMin2,
            evctnMin3: item2.evctnMin3,
            evctnMax: item2.evctnMax,
            evctnMax2: item2.evctnMax2,
            evctnMax3: item2.evctnMax3,
            warningBrdCntnId: item2.warningBrdCntnId,
            alertBrdCntnId: item2.alertBrdCntnId,
            evctnBrdCntnId: item2.evctnBrdCntnId,
            isSetting: item2.isSetting,
            useYn: "Y",
            regUsr: "SH",
          };
        }
      });
      return updateObject;
    });

    setSnsrData(
      [...filterData, ...saveData].sort((a, b) => {
        const dvcNmA = a.dvcNm.toLowerCase();
        const dvcNmB = b.dvcNm.toLowerCase();

        if (dvcNmA < dvcNmB) return -1;
        if (dvcNmA > dvcNmB) return 1;
        return 0;
      })
    );
    setRegData(newRegData);
    setSelectData({});
    setIsOpen(false);
  }

  useEffect(() => {
    const sameType = snsrData.filter(
      (item) =>
        item.dvcDtlType === selectData.dvcDtlType &&
        item.dvcId !== selectData.dvcId
    );
    const column = [
      checkColumn,
      columnHelper.accessor("dvcNm", {
        header: "장비명",
        size: 110,
      }),
      columnHelper.accessor(
        (row) => {
          return snsrTypes.find((type) => type.value === row.dvcDtlType)?.text;
        },
        {
          header: "타입",
          size: 152.5,
        }
      ),
    ];

    setSaveData([{ ...selectData, ...selectSetting }]);
    setData(
      sameType.sort((a, b) => {
        const dvcNmA = a.dvcNm.toLowerCase();
        const dvcNmB = b.dvcNm.toLowerCase();

        if (dvcNmA < dvcNmB) return -1;
        if (dvcNmA > dvcNmB) return 1;
        return 0;
      })
    );
    setColumns(column);
  }, []);
  useEffect(() => {
    const column = [
      checkColumn,
      columnHelper.accessor("dvcNm", {
        header: "장비명",
        size: 110,
      }),
      columnHelper.accessor(
        (row) => {
          return snsrTypes.find((type) => type.value === row.dvcDtlType)?.text;
        },
        {
          header: "타입",
          size: 152.5,
        }
      ),
    ];

    setColumns(column);
  }, [saveData]);

  return (
    <Modal isOpen={isOpen} style={modalStyles}>
      <div className="modal_container" style={{ height: "calc(100% - 40px)" }}>
        {/* Header */}
        <div className="same_header">
          <div className="title">동일 타입 센서 저장</div>
          <div className="sub_title">
            <div>선택하신 센서와 같은 타입의 센서가 존재합니다. </div>
            <div>
              입력하신 정보로 저장할 센서가 있다면 아래 목록에서 선택해 주세요.
            </div>
          </div>
        </div>

        {/* Main */}
        <div
          className="modal_main"
          style={{ marginTop: "20px", overflowX: "hidden", overflowY: "auto" }}
        >
          <table className="react_table">
            <thead>
              {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <th
                      key={header.id}
                      style={{
                        width: isNaN(header.getSize())
                          ? "auto"
                          : `${header.getSize()}px`,
                        minWidth: isNaN(header.getSize())
                          ? "auto"
                          : `${header.getSize()}px`,
                        maxWidth: isNaN(header.getSize())
                          ? "auto"
                          : `${header.getSize()}px`,
                      }}
                    >
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody>
              {table.getRowModel().rows.map((row, index) => (
                <React.Fragment key={row.id}>
                  <tr
                    key={row.id}
                    className={index % 2 === 0 ? "tr_even" : "tr_odd"}
                  >
                    {row.getVisibleCells().map((cell) => (
                      <td
                        key={cell.id}
                        onClick={(e) => {
                          if (cell.column.id === "check") {
                            e.stopPropagation();
                          }
                        }}
                        style={{
                          width: isNaN(cell.column.getSize())
                            ? "auto"
                            : `${cell.column.getSize()}px`,
                          minWidth: isNaN(cell.column.getSize())
                            ? "auto"
                            : `${cell.column.getSize()}px`,
                          maxWidth: isNaN(cell.column.getSize())
                            ? "auto"
                            : `${cell.column.getSize()}px`,
                          textAlign: cell.column.id === "check" && "center",
                        }}
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </td>
                    ))}
                  </tr>
                </React.Fragment>
              ))}
            </tbody>
          </table>
        </div>

        {/* Footer */}
        <div className="modal_footer">
          <div className="button_nor" onClick={onClickCancel}>
            취소
          </div>
          <div
            className="button_imp"
            onClick={onClickSetting}
            style={{ marginLeft: "10px" }}
          >
            확인
          </div>
        </div>
      </div>
    </Modal>
  );
}

const modalStyles = {
  content: {
    width: "470px",
    height: "500px",
    padding: "0px",
    background: "#202020",
    border: "none",
    borderRadius: "5px",
    boxShadow: "-5px 5px 10px rgba(0, 0, 0, 0.7)",
    margin: "auto",
  },
  overlay: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "fixed",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: "rgba(0, 0, 0, 0.0)",
  },
};

export default SameTypeSensor;
