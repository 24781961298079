import { forwardRef } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar } from "@fortawesome/free-solid-svg-icons";

const modalDatepicker = forwardRef(({ value, onClick }, ref) => (
  <div className="datepicker-modal" name="instlDt" onClick={onClick} ref={ref}>
    <div>{value}</div>
    <FontAwesomeIcon icon={faCalendar} />
  </div>
));

const dataDatepicker = forwardRef(({ value, onClick }, ref) => (
  <div className="datepicker-data" onClick={onClick} ref={ref}>
    <div>{value}</div>
    <FontAwesomeIcon icon={faCalendar} />
  </div>
));

const smsDatePicker = forwardRef(({ value, onClick }, ref) => (
  <div className="datepicker-sms" onClick={onClick} ref={ref}>
    <div>{value}</div>
    <FontAwesomeIcon icon={faCalendar} />
  </div>
));

export const DATEPICKERSTYLES = {
  modal: modalDatepicker,
  data: dataDatepicker,
  sms: smsDatePicker,
};
