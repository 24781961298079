import { useState } from "react";
import Modal from "react-modal";

import { MODALSTYLES } from "../../../constants/modalStyles";
import { changePwd } from "../../../services/internal/userAPI";
import Alert from "../../common/window/Alert";
import Confirm from "../../common/window/Confirm";

Modal.setAppElement("#root");

function PwdEdit({ isOpen, setIsOpen, inform, setIsChange, setIsSuccess }) {
  const [editInform, setEditInform] = useState({
    userId: inform.userId,
    oldPassword: "",
    newPassword: "",
  });
  const [newPwdCheck, setNewPwdCheck] = useState("");
  const [isSamePwd, setIsSamePwd] = useState(false);
  const [samePwdText, setSamePwdText] = useState("");
  const [isEdit, setIsEdit] = useState({ isOpen: false, text: "" });

  const [isInputAlert, setIsInputAlert] = useState(false);
  const [isLengthAlert, setIsLengthAlert] = useState(false);
  const [isSameAlert, setIsSameAlert] = useState(false);
  const [isEditAlert, setIsEditAlert] = useState();

  function onClickClose() {
    // 닫기 버튼 클릭 시
    setIsOpen(false);
  }
  function onClickApply() {
    // 변경 버튼 클릭 시
    if (
      editInform.oldPassword === "" ||
      editInform.newPassword === "" ||
      newPwdCheck === ""
    ) {
      // 입력 x
      setIsInputAlert(true);
    } else {
      if (editInform.newPassword.length < 8) {
        // 비밀번호 8자리 미만
        setIsLengthAlert(true);
      } else {
        if (!isSamePwd) {
          // 비밀번호 일치x
          setIsSameAlert(true);
        } else {
          // 변경
          setIsEdit({ isOpen: true, text: "비밀번호를 변경하시겠습니까?" });
        }
      }
    }
  }
  function changePassword() {
    // 비밀번호 변경
    changePwd(editInform).then((response) => {
      if (response.success === true) {
        // 변경 성공
        setIsChange(true);
        setIsOpen(false);
        setIsSuccess(true);
      } else {
        // 변경 실패
        setIsEditAlert(true);
      }
    });
  }

  return (
    <Modal isOpen={isOpen} style={MODALSTYLES.pwdEdit}>
      {
        // 정보 입력x
        isInputAlert && (
          <Alert
            isOpen={isInputAlert}
            setIsOpen={setIsInputAlert}
            text="정보를 입력해주세요"
          />
        )
      }
      {
        // 비밀번호 8자리 미만
        isLengthAlert && (
          <Alert
            isOpen={isLengthAlert}
            setIsOpen={setIsLengthAlert}
            text="변경할 비밀번호를 8자리 이상 입력해주세요"
          />
        )
      }
      {
        // 비밀번호 일치x
        isSameAlert && (
          <Alert
            isOpen={isSameAlert}
            setIsOpen={setIsSameAlert}
            text="변경할 비밀번호가 일치하지 않습니다"
          />
        )
      }
      {
        // 비밀번호 변경
        isEdit.isOpen && (
          <Confirm
            isOpen={isEdit}
            setIsOpen={setIsEdit}
            active={changePassword}
          />
        )
      }
      {
        // 기존 비밀번호 일치x
        isEditAlert && (
          <Alert
            isOpen={isEditAlert}
            setIsOpen={setIsEditAlert}
            text="기존 비밀번호가 일치하지 않습니다"
          />
        )
      }
      <div className="modal_title">비밀번호 변경</div>
      <div className="modal_container">
        <form className="my-edit-pwd-modal">
          {/* 현재 비밀번호 */}
          <div className="cur-pwd-container">
            <div className="title">현재 비밀번호</div>
            <input
              type="password"
              placeholder="현재 비밀번호 입력"
              value={editInform.oldPassword}
              onChange={(e) => {
                const newValue = e.target.value.replace(/\s/g, "");
                setEditInform({ ...editInform, oldPassword: newValue });
              }}
            />
          </div>

          {/* 변경 비밀번호 */}
          <div
            className="new-pwd-check-text"
            style={{ color: isSamePwd === true ? "#13e700" : "#ff4040" }}
          >
            {samePwdText}
          </div>
          <div className="new-pwd-container">
            <div className="title">새 비밀번호</div>
            <input
              type="password"
              placeholder="새 비밀번호 입력 (8자리 이상)"
              value={editInform.newPassword}
              onChange={(e) => {
                const newValue = e.target.value.replace(/\s/g, "");
                setEditInform({ ...editInform, newPassword: newValue });
                setNewPwdCheck("");
                setSamePwdText("");
                setIsSamePwd(false);
              }}
            />
          </div>
          <div className="new-pwd-check-container">
            <div className="title">새 비밀번호 확인</div>
            <input
              type="password"
              placeholder="새 비밀번호 확인 입력"
              value={newPwdCheck}
              onChange={(e) => {
                const newValue = e.target.value.replace(/\s/g, "");
                setNewPwdCheck(newValue);

                if (newValue !== "") {
                  if (newValue === editInform.newPassword) {
                    setIsSamePwd(true);
                    setSamePwdText("비밀번호가 일치합니다");
                  } else {
                    setIsSamePwd(false);
                    setSamePwdText("비밀번호가 일치하지 않습니다");
                  }
                } else {
                  setSamePwdText("");
                  setIsSamePwd(false);
                }
              }}
            />
          </div>
        </form>

        <div className="modal_footer">
          <div className="button-nor-modal" onClick={onClickClose}>
            닫기
          </div>
          <div className="button-imp right-button" onClick={onClickApply}>
            변경
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default PwdEdit;
