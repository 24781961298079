import { useState, useEffect } from "react";

import { getCurrTime, zeroPlusNum } from "../../utils/time";
import { getMinColumn } from "../../utils/data";
import {
  getDataUpdate,
  getRainSnsr,
  getLevelSnsr,
  getSlopeSnsr,
  getDisSnsr,
} from "../../services/internal/dataAPI";

import RainGraph from "./data/sensor-graph/RainGraph";
import LevelGraph from "./data/sensor-graph/LevelGraph";
import SlopeGraph from "./data/sensor-graph/SlopeGraph";
import DisGraph from "./data/sensor-graph/DisGraph";
import SensorTable from "./data/SensorTable";

function Data() {
  const [rainRtu, setRainRtu] = useState([]);
  const [levelRtu, setLevelRtu] = useState([]);
  const [slopeRtu, setSlopeRtu] = useState([]);
  const [disRtu, setDisRtu] = useState([]);
  const [tableData, setTableData] = useState([
    { typeId: 1, type: "강우계", cnt: 0, alert: 0, object: [] },
    { typeId: 2, type: "수위계", cnt: 0, alert: 0, object: [] },
    { typeId: 3, type: "경사계", cnt: 0, alert: 0, object: [] },
    { typeId: 4, type: "변위계", cnt: 0, alert: 0, object: [] },
  ]);
  const [selected, setSelected] = useState({});

  useEffect(() => {
    getRainSnsr().then((rainRtu) => {
      getLevelSnsr().then((levelRtu) => {
        getSlopeSnsr().then((slopeRtu) => {
          getDisSnsr().then((disRtu) => {
            setRainRtu(rainRtu);
            setLevelRtu(levelRtu);
            setSlopeRtu(slopeRtu);
            setDisRtu(disRtu);
          });
        });
      });
    });
  }, []);
  useEffect(() => {
    const todayDate = getCurrTime().date;
    const today =
      zeroPlusNum(getCurrTime().year) +
      zeroPlusNum(getCurrTime().month) +
      zeroPlusNum(getCurrTime().day);
    const fromDt = today + "000000";
    const toDt = today + "235959";
    // 강우계
    const fetchRainData = () => {
      if (Array.isArray(rainRtu) && rainRtu.length > 0) {
        const promiseRain = rainRtu.map((rainItem) => {
          if (rainItem.dvcData.length > 0) {
            const dvcItem = rainItem.dvcData[0];
            return getDataUpdate("min", fromDt, toDt, dvcItem).then(
              (rainData) => {
                if (Array.isArray(rainData) && rainData.length > 0) {
                  const dateColumn = getMinColumn(todayDate);
                  const dateData = dateColumn.map((dateItem) => {
                    const check = rainData.find(
                      (item) => item.date === dateItem
                    );
                    const avgVal = check
                      ? parseFloat(check.avgVal).toFixed(2)
                      : "-";
                    return { date: dateItem, [dvcItem.dvcNm]: avgVal };
                  });
                  return { ...rainItem, data: dateData };
                } else {
                  return { ...rainItem, data: [] };
                }
              }
            );
          } else {
            return { ...rainItem, data: [] };
          }
        });
        return Promise.all(promiseRain);
      }
      return Promise.resolve([]);
    };
    // 수위계
    const fetchLevelData = () => {
      if (Array.isArray(levelRtu) && levelRtu.length > 0) {
        const promiseLevel = levelRtu.map((levelItem) => {
          if (levelItem.dvcData.length > 0) {
            const dvcItem = levelItem.dvcData[0];
            return getDataUpdate("min", fromDt, toDt, dvcItem).then(
              (levelData) => {
                if (Array.isArray(levelData) && levelData.length > 0) {
                  const dateColumn = getMinColumn(todayDate);
                  const dateData = dateColumn.map((dateItem) => {
                    const check = levelData.find(
                      (item) => item.date === dateItem
                    );
                    const avgVal = check
                      ? parseFloat(check.avgVal).toFixed(2)
                      : "-";
                    return { date: dateItem, [dvcItem.dvcNm]: avgVal };
                  });
                  return { ...levelItem, data: dateData };
                } else {
                  return { ...levelItem, data: [] };
                }
              }
            );
          } else {
            return { ...levelItem, data: [] };
          }
        });
        return Promise.all(promiseLevel);
      }
      return Promise.resolve([]);
    };
    // 경사계
    const fetchSlopeData = () => {
      if (Array.isArray(slopeRtu) && slopeRtu.length > 0) {
        const promiseSlope = slopeRtu.map((slopeItem) => {
          if (slopeItem.dvcData.length > 0) {
            const dvcXItem = slopeItem.dvcData[0];
            const dvcYItem = slopeItem.dvcData[1];
            return getDataUpdate("min", fromDt, toDt, dvcXItem).then(
              (slopeXData) => {
                return getDataUpdate("min", fromDt, toDt, dvcYItem).then(
                  (slopeYData) => {
                    if (
                      Array.isArray(slopeXData) &&
                      Array.isArray(slopeYData) &&
                      (slopeXData.length > 0 || slopeYData.length > 0)
                    ) {
                      const dateColumn = getMinColumn(todayDate);
                      const dateData = dateColumn.map((dateItem) => {
                        const checkX = slopeXData.find(
                          (item) => item.date === dateItem
                        );
                        const checkY = slopeYData.find(
                          (item) => item.date === dateItem
                        );
                        const xVal = checkX
                          ? parseFloat(checkX.avgVal).toFixed(2)
                          : "-";
                        const yVal = checkY
                          ? parseFloat(checkY.avgVal).toFixed(2)
                          : "-";
                        return {
                          date: dateItem,
                          [dvcXItem.dvcNm]: xVal,
                          [dvcYItem.dvcNm]: yVal,
                        };
                      });
                      return { ...slopeItem, data: dateData };
                    } else {
                      return { ...slopeItem, data: [] };
                    }
                  }
                );
              }
            );
          } else {
            return { ...slopeItem, data: [] };
          }
        });
        return Promise.all(promiseSlope);
      }
      return Promise.resolve([]);
    };
    // 변위계
    const fetchDisData = () => {
      if (Array.isArray(disRtu) && disRtu.length > 0) {
        const promiseDis = disRtu.map((disItem) => {
          if (disItem.dvcData.length > 0) {
            const dvcXItem = disItem.dvcData[0];
            const dvcYItem = disItem.dvcData[1];
            const dvcZItem = disItem.dvcData[2];
            return getDataUpdate("min", fromDt, toDt, dvcXItem).then(
              (disXData) => {
                return getDataUpdate("min", fromDt, toDt, dvcYItem).then(
                  (disYData) => {
                    return getDataUpdate("min", fromDt, toDt, dvcZItem).then(
                      (disZData) => {
                        if (
                          Array.isArray(disXData) &&
                          Array.isArray(disYData) &&
                          Array.isArray(disZData) &&
                          (disXData.length > 0 ||
                            disYData.length > 0 ||
                            disZData.length > 0)
                        ) {
                          const dateColumn = getMinColumn(todayDate);
                          const dateData = dateColumn.map((dateItem) => {
                            const checkX = disXData.find(
                              (item) => item.date === dateItem
                            );
                            const checkY = disYData.find(
                              (item) => item.date === dateItem
                            );
                            const checkZ = disZData.find(
                              (item) => item.date === dateItem
                            );
                            const xVal = checkX
                              ? parseFloat(checkX.avgVal).toFixed(2)
                              : "-";
                            const yVal = checkY
                              ? parseFloat(checkY.avgVal).toFixed(2)
                              : "-";
                            const zVal = checkZ
                              ? parseFloat(checkZ.avgVal).toFixed(2)
                              : "-";
                            return {
                              date: dateItem,
                              [dvcXItem.dvcNm]: xVal,
                              [dvcYItem.dvcNm]: yVal,
                              [dvcZItem.dvcNm]: zVal,
                            };
                          });
                          return { ...disItem, data: dateData };
                        } else {
                          return { ...disItem, data: [] };
                        }
                      }
                    );
                  }
                );
              }
            );
          } else {
            return { ...disItem, data: [] };
          }
        });
        return Promise.all(promiseDis);
      }
      return Promise.resolve([]);
    };

    // 모든 데이터 fetch 실행
    Promise.all([
      fetchRainData(),
      fetchLevelData(),
      fetchSlopeData(),
      fetchDisData(),
    ]).then(([rainResult, levelResult, slopeResult, disResult]) => {
      const processResult = (result) => {
        const sortResult = result;
        sortResult.sort((a, b) => a.eqId - b.eqId);
        return {
          cnt: sortResult.length,
          object: sortResult,
        };
      };
      const newTableData = tableData.map((item) => {
        switch (item.type) {
          case "강우계":
            return { ...item, ...processResult(rainResult) };
          case "수위계":
            return { ...item, ...processResult(levelResult) };
          case "경사계":
            return { ...item, ...processResult(slopeResult) };
          case "변위계":
            return { ...item, ...processResult(disResult) };
          default:
            return item;
        }
      });

      setTableData(newTableData);
    });
  }, [rainRtu, levelRtu, slopeRtu, disRtu]);
  useEffect(() => {
    setSelected(tableData[0]);
  }, [tableData]);

  return (
    <div id="allstatus-data-component">
      {/* 그래프 */}
      <div className="graph-container">
        {Object.keys(selected).length !== 0 &&
          (selected.cnt === 0 ? (
            <div className="data-none-container">
              해당 센서가 등록되어 있지 않습니다
            </div>
          ) : selected.type === "강우계" ? (
            <RainGraph selected={selected} />
          ) : selected.type === "수위계" ? (
            <LevelGraph selected={selected} />
          ) : selected.type === "경사계" ? (
            <SlopeGraph selected={selected} />
          ) : (
            selected.type === "변위계" && <DisGraph selected={selected} />
          ))}
      </div>

      {/* 테이블 */}
      <div className="table-container">
        <SensorTable
          tableData={tableData}
          selected={selected}
          setSelected={setSelected}
        />
      </div>
    </div>
  );
}

export default Data;
