import { useState, useEffect } from "react";
import {
  createColumnHelper,
  flexRender,
  useReactTable,
  getCoreRowModel,
} from "@tanstack/react-table";

function List({ type, rtus, selected, setSelected }) {
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });
  const columnHelper = createColumnHelper();
  const emptyColumn = {
    id: "empty",
    accessor: "empty",
    header: "",
    enableSorting: false,
    size: 20,
  };
  const checkColumn = {
    id: "check",
    accessor: "check",
    header: "",
    size: 20,
    enableSorting: false,
    cell: ({ row }) => (
      <input
        type="checkbox"
        checked={selected[type].find((item) => item.eqId === row.original.eqId)}
        style={{ cursor: "pointer" }}
        readOnly
      />
    ),
  };

  function onClickRow(row) {
    // 행 클릭 시
    if (selected[type].find((item) => item.eqId === row.original.eqId)) {
      const newSelected = selected[type].filter(
        (item) => item.eqId !== row.original.eqId
      );
      setSelected({ ...selected, [type]: newSelected });
    } else {
      setSelected({ ...selected, [type]: [...selected[type], row.original] });
    }
  }

  useEffect(() => {
    if (Array.isArray(rtus) && rtus.length > 0) {
      const column = [
        emptyColumn,
        checkColumn,
        columnHelper.accessor("eqNm", { size: 400 }),
      ];

      setData(rtus);
      setColumns(column);
    }
  }, [rtus]);
  useEffect(() => {
    const column = [
      emptyColumn,
      checkColumn,
      columnHelper.accessor("eqNm", { size: 400 }),
    ];

    setColumns(column);
  }, [selected]);

  return (
    <div className="sensor-table">
      <table className="react_table">
        <tbody>
          {table.getRowModel().rows.map((row, index) => (
            <tr
              key={row.id}
              className={index % 2 === 0 ? "tr_even" : "tr_odd"}
              onClick={() => onClickRow(row)}
            >
              {row.getVisibleCells().map((cell) => (
                <td
                  key={cell.id}
                  style={{
                    width: isNaN(cell.column.getSize())
                      ? "auto"
                      : `${cell.column.getSize()}px`,
                    textAlign: cell.column.id === "check" && "center",
                  }}
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default List;
