import apiAddress from "../../services/apiAddress";
import axios from "axios";

/* 그룹 - Equip 조회 (equip 삭제 위해 그룹에 등록되어있는지 확인) */
export function getGroupEquip() {
  return axios
    .post(
      apiAddress.groupEquipGet,
      {
        groupId: 0,
        searchType: "A",
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      return response.data;
    });
}

/* 그룹 - Equip - Device 조회 */
export function getGroupEquipDevice(dvcId) {
  return axios
    .post(
      apiAddress.groupEquipDeviceGet,
      {
        dvcId: dvcId,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      return response.data;
    });
}
