import { useState, useEffect } from "react";
import Modal from "react-modal";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faX } from "@fortawesome/free-solid-svg-icons";

import { MODALSTYLES } from "../../constants/modalStyles";

Modal.setAppElement("#root");

function FindIdInform({ isOpen, setIsOpen, idList, data, setData, setIsPwd }) {
  const [contentHeight, setContentHeight] = useState("190px");
  const [selectId, setSelectId] = useState(idList[0]);

  function onClickLogin() {
    // 로그인 클릭 시
    setData({ ...data, id: selectId });
    setIsOpen(false);
  }
  function onClickFindPwd() {
    // 비밀번호 찾기 클릭 시
    setIsPwd({
      isOpen: true,
      data: {
        userId: selectId,
        cellNum: "",
      },
    });
    setIsOpen(false);
  }
  function onChangeRadio(e) {
    // input[radio]
    setSelectId(e.target.value);
  }
  function onClickClose() {
    // 닫기
    setIsOpen(false);
  }

  useEffect(() => {
    const height = 190 + (idList.length - 1) * 40;
    setContentHeight(height + "px");
  }, []);

  return (
    <Modal
      isOpen={isOpen}
      style={{
        ...MODALSTYLES.findIdList,
        content: { ...MODALSTYLES.findIdList.content, height: contentHeight },
      }}
    >
      <div id="find-id-list-modal">
        <div className="find-id-list-header">
          <div className="title">아이디 찾기</div>
          <FontAwesomeIcon
            className="close-button"
            icon={faX}
            onClick={onClickClose}
          />
        </div>

        <div className="find-id-list-main">
          <div className="notice">
            <div>입력하신 정보로 등록된 아이디가</div>
            <div className="cnt">총 {idList.length}개</div>
            <div>있습니다.</div>
          </div>

          <div className="id-list">
            {idList.map((item) => (
              <div key={item} className="id-item">
                <input
                  type="radio"
                  name="id"
                  id={item}
                  value={item}
                  checked={selectId === item}
                  onChange={onChangeRadio}
                />
                <label htmlFor={item}>{item}</label>
              </div>
            ))}
          </div>
        </div>

        <div className="find-id-list-footer">
          <div
            className="button-imp button button-margin"
            onClick={onClickLogin}
          >
            로그인
          </div>
          <div className="button-imp button" onClick={onClickFindPwd}>
            비밀번호 찾기
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default FindIdInform;
