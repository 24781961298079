import { useState, useEffect } from "react";
import Modal from "react-modal";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import locale from "../../../../data/datepicker";

import { dateToString, stringToDate } from "../../../../utils/dateFormat";
import { MODALSTYLES } from "../../../../constants/modalStyles";
import { DATEPICKERSTYLES } from "../../../../constants/datepickerStyles";
import { RTUOPTIONS } from "../../../../constants/rtuOptions";
import { saveEquip } from "../../../../services/internal/equipAPI";
import Bdong from "../../../common/function/Bdong";
import LatLon from "../../../common/function/LatLon";
import Alert from "../../../common/window/Alert";
import Edit from "../../../common/window/Edit";

Modal.setAppElement("#root");

function RtuEdit({ isOpen, setIsOpen, setIsChange }) {
  const [inform, setInform] = useState({});
  const [isBdong, setIsBdong] = useState(false);
  const [isLatLon, setIsLatLon] = useState(false);
  const [isAlert, setIsAlert] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  function onClickBdong() {
    // 코드조회 버튼 클릭시
    setIsBdong(true);
  }
  function onClickLatLon() {
    // 지도조회 버튼 클릭시
    setIsLatLon(true);
  }
  function onClickClose() {
    // 닫기 버튼 클릭시
    setIsOpen(false);
  }
  function onClickEdit() {
    // 수정 버튼 클릭시
    if (
      inform.eqNm === "" ||
      inform.commAddr === "" ||
      (inform.commType === "TCP/IP" && inform.commPort === "") ||
      inform.bdongCd === "" ||
      inform.addr === "" ||
      inform.lat === "" ||
      inform.lon === ""
    ) {
      // 필수 정보 입력X
      setIsAlert(true);
    } else {
      // 필수 정보 입력O
      setIsEdit(true);
    }
  }
  function editRtu() {
    // RTU 수정
    const tokenInform = localStorage.getItem("tokenInform");
    const tokenInformParse = JSON.parse(tokenInform);
    const dateInform = {
      ...inform,
      instlDt: dateToString(inform.instlDt),
      modUsr: tokenInformParse.userId,
    };
    saveEquip(dateInform).then((response) => {
      setIsChange(true);
      setIsOpen(false);
    });
  }

  useEffect(() => {
    const rtuInform = isOpen.data;
    const editInform = {
      ...rtuInform,
      instlDt: stringToDate(rtuInform.instlDt),
    };
    setInform(editInform);
  }, [isOpen.data]);

  return (
    <Modal isOpen={isOpen.isOpen} style={MODALSTYLES.rtu}>
      {
        // 코드조회(법정동코드)
        isBdong && (
          <Bdong
            isOpen={isBdong}
            setIsOpen={setIsBdong}
            bdong={inform}
            setBdong={setInform}
          />
        )
      }
      {
        // 지도조회(위도/경도)
        isLatLon && (
          <LatLon
            isOpen={isLatLon}
            setIsOpen={setIsLatLon}
            data={inform}
            setData={setInform}
          />
        )
      }
      {
        // 필수 정보 입력X
        isAlert && (
          <Alert
            isOpen={isAlert}
            setIsOpen={setIsAlert}
            text="필수 정보를 입력해주세요"
          />
        )
      }
      {
        // RTU 등록
        isEdit && (
          <Edit isOpen={isEdit} setIsOpen={setIsEdit} editData={editRtu} />
        )
      }
      {Object.keys(inform).length > 0 && (
        <>
          <div className="modal_title">
            <div>RTU 수정</div>
          </div>

          <div className="modal_container">
            <div className="modal_main_column">
              {Object.keys(inform).length !== 0 && (
                <div className="rtu-add-edit-modal">
                  <div className="typing-container">
                    <div className="title">RTU명</div>
                    <input
                      className="one-input"
                      type="text"
                      placeholder="필수 입력*"
                      value={inform.eqNm}
                      onChange={(e) =>
                        setInform({ ...inform, eqNm: e.target.value })
                      }
                    />
                  </div>
                  <div className="typing-container">
                    <div className="title">설치일자</div>
                    <DatePicker
                      selected={inform.instlDt}
                      onChange={(date) =>
                        setInform({ ...inform, instlDt: date })
                      }
                      customInput={<DATEPICKERSTYLES.modal />}
                      dateFormat="yyyy-MM-dd"
                      locale={locale.ko}
                    />
                    <div className="inner-title">제조업체</div>
                    <input
                      className="two-input"
                      type="text"
                      placeholder="제조업체 입력"
                      value={inform.mnfct}
                      onChange={(e) =>
                        setInform({ ...inform, mnfct: e.target.value })
                      }
                    />
                  </div>
                  <div className="typing-container">
                    <div className="title">통신</div>
                    <select
                      className="two-select"
                      value={inform.commType}
                      onChange={(e) => {
                        setInform({
                          ...inform,
                          commType: e.target.value,
                          commAddr: "",
                          commPort: "",
                        });
                      }}
                    >
                      {RTUOPTIONS.connectType.map((item) => (
                        <option key={item.value} value={item.value}>
                          {item.text}
                        </option>
                      ))}
                    </select>
                    <div className="inner-title">BaudRate</div>
                    <input
                      className="two-input"
                      type="text"
                      placeholder="BaudRate 입력"
                      value={inform.commSpd}
                      onChange={(e) =>
                        setInform({ ...inform, commSpd: e.target.value })
                      }
                    />
                  </div>
                  <div className="typing-container">
                    {inform.commType === "TCP/IP" ? (
                      <>
                        <div className="title">IP</div>
                        <input
                          className="two-input"
                          type="text"
                          placeholder="필수 입력*"
                          value={inform.commAddr}
                          onChange={(e) =>
                            setInform({ ...inform, commAddr: e.target.value })
                          }
                        />
                        <div className="inner-title">Port</div>
                        <input
                          className="two-input"
                          type="text"
                          placeholder="필수 입력*"
                          value={inform.commPort}
                          onChange={(e) =>
                            setInform({ ...inform, commPort: e.target.value })
                          }
                        />
                      </>
                    ) : (
                      inform.commType === "LTE" && (
                        <>
                          <div className="title">전화번호</div>
                          <input
                            className="one-input"
                            type="number"
                            placeholder="필수 입력*"
                            value={inform.commAddr}
                            onChange={(e) =>
                              setInform({ ...inform, commAddr: e.target.value })
                            }
                          />
                        </>
                      )
                    )}
                  </div>
                  <div className="typing-container">
                    <div className="title">법정동코드</div>
                    <input
                      className="bdong"
                      type="number"
                      placeholder="필수 입력*"
                      value={inform.bdongCd}
                      disabled={true}
                    />
                    <div className="inner-title">주소</div>
                    <input
                      className="addr"
                      type="text"
                      placeholder="필수 입력*"
                      value={inform.addr}
                      disabled={true}
                    />
                    <div className="button-modal button" onClick={onClickBdong}>
                      코드조회
                    </div>
                  </div>
                  <div className="typing-container">
                    <div className="title">상세주소</div>
                    <input
                      className="one-input"
                      type="text"
                      placeholder="상세주소 입력"
                      value={inform.addrDtl}
                      onChange={(e) =>
                        setInform({ ...inform, addrDtl: e.target.value })
                      }
                    />
                  </div>
                  <div className="typing-container">
                    <div className="title">위도</div>
                    <input
                      className="lat-lon"
                      type="number"
                      placeholder="필수 입력*"
                      value={inform.lat}
                      onChange={(e) =>
                        setInform({ ...inform, lat: e.target.value })
                      }
                    />
                    <div className="inner-title">경도</div>
                    <input
                      className="lat-lon"
                      type="number"
                      placeholder="필수 입력*"
                      value={inform.lon}
                      onChange={(e) =>
                        setInform({ ...inform, lon: e.target.value })
                      }
                    />
                    <div
                      className="button-modal button"
                      onClick={onClickLatLon}
                    >
                      지도조회
                    </div>
                  </div>
                  <div className="last-typing-container">
                    <div className="title">메모</div>
                    <textarea
                      type="text"
                      placeholder="메모 입력"
                      name="eqDtl"
                      value={inform.eqDtl}
                      onChange={(e) =>
                        setInform({ ...inform, eqDtl: e.target.value })
                      }
                    />
                  </div>
                </div>
              )}
            </div>

            <div className="modal_footer">
              <div className="button-nor-modal" onClick={onClickClose}>
                닫기
              </div>
              <div className="button-imp right-button" onClick={onClickEdit}>
                수정
              </div>
            </div>
          </div>
        </>
      )}
    </Modal>
  );
}

export default RtuEdit;
