const railStyle = { background: "#1266FF" };
const trackStyle = { background: "#ffffff", borderRadius: "0px" };
const handleStyle = {
  width: "15px",
  height: "5px",
  border: "1px solid #ffffff",
  borderRadius: "0px",
  background: "#ffffff",
  boxShadow: "none",
  cursor: "pointer",
};

export const CONTROLBARSTYLE = {
  railStyle: railStyle,
  trackStyle: trackStyle,
  handleStyle: handleStyle,
};
