import { useState, useEffect, useRef } from "react";
import JSMpeg from "@cycjimmy/jsmpeg-player";
import Draggable from "react-draggable";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faX } from "@fortawesome/free-solid-svg-icons";

import {
  getRainMarkers,
  getLevelMarkers,
  // getSlopeMarkers,
  getGroundMarkers,
  getDisMarkers,
  // getEdbMarkers,
  getBroadMarkers,
  getCCTVMarkers,
} from "../../utils/map/marker";
import { getCCTVVideo, quitCCTVVideo } from "../../services/internal/cctvAPI";
import MapControlBar from "../map/MapControlBar";
import MapType from "../map/MapType";
import MapLegend from "../map/MapLegend";
import SnsrData from "./map/SnsrData";

const { kakao } = window;

function Map({ selected }) {
  const [mapType, setMapType] = useState("satellite"); // 지도 관련
  const [map, setMap] = useState(null);
  const [mapLevel, setMapLevel] = useState(selected.id === 4 ? 2 : 1);
  const mapRef = useRef(null);
  const [dvcPrint, setDvcPrint] = useState([
    "broadcast",
    "cctv",
    "rain",
    "level",
    "ground",
    "displacement",
  ]);
  const [dvcPrintBfr, setDvcPrintBfr] = useState([
    "broadcast",
    "cctv",
    "rain",
    "level",
    "ground",
    "displacement",
  ]);

  const [selectedSnsr, setSelectedSnsr] = useState({});
  const [rainMarker, setRainMarker] = useState([]);
  const [levelMarker, setLevelMarker] = useState([]);
  const [groundMarker, setGroundMarker] = useState([]);
  const [disMarker, setDisMarker] = useState([]);
  const [brdMarker, setBrdMarker] = useState([]);
  const [cctvMarker, setCctvMarker] = useState([]);

  const [selectedCCTV, setSelectedCCTV] = useState({}); // CCTV 관련
  const [cctvPlayer, setCctvPlayer] = useState(null);
  const [cctvPort, setCctvPort] = useState(0);
  const cctvScreenRef = useRef(null);

  /* 마커 이벤트 */
  function markerMouseOver(overlay, clickOverlay) {
    if (clickOverlay.getMap() === null) {
      overlay.setMap(map);
    }
  }
  function markerMouseOut(overlay) {
    overlay.setMap(null);
  }
  function markerClick(overlay, clickOverlay) {
    overlay.setMap(null);
    if (clickOverlay.getMap() === null) {
      clickOverlay.setMap(map);
    } else {
      clickOverlay.setMap(null);
    }
  }
  /* 장비 표시(마커&오버레이) 관련 이벤트 */
  function markerOverlayControl(type, markerInformArr) {
    if (!dvcPrint.includes(type) && dvcPrintBfr.includes(type)) {
      // 표시X
      hiddenMarkerOverlay(markerInformArr);
    }
    if (dvcPrint.includes(type) && !dvcPrintBfr.includes(type)) {
      // 표시O
      printMarkerOverlay(markerInformArr);
    }
  }
  function printMarkerOverlay(markerInformArr) {
    // 장비 표시 O
    if (Array.isArray(markerInformArr) && markerInformArr.length > 0) {
      markerInformArr.forEach((item) => {
        item.marker.setMap(map);
      });
    }
  }
  function hiddenMarkerOverlay(markerInformArr) {
    // 장비 표시 X
    if (Array.isArray(markerInformArr) && markerInformArr.length > 0) {
      markerInformArr.forEach((item) => {
        item.marker.setMap(null);
        item.overlay.setMap(null);
        item.clickOverlay.setMap(null);
      });
    }
  }
  /* 센서 정보 클릭 */
  function onClickSnsr(snsrInf) {
    setSelectedSnsr(snsrInf);
  }
  /* CCTV 클릭 */
  function onClickCCTV(cctvInf) {
    setSelectedCCTV(cctvInf);
  }
  function onClickCCTVClose() {
    setSelectedCCTV({});
  }

  useEffect(() => {
    /* 지도 */
    const container = document.getElementById("allstatus-kakao-map");
    const options = {
      center: new kakao.maps.LatLng(selected.lat, selected.lon),
      level:
        selected !== undefined &&
        Object.keys(selected).length > 0 &&
        selected.id === 4
          ? 2
          : 1,
    };
    const defaultMap = new kakao.maps.Map(container, options);

    kakao.maps.event.addListener(defaultMap, "zoom_changed", function () {
      setMapLevel(defaultMap.getLevel());
    });

    defaultMap.setMinLevel(1);
    defaultMap.setMaxLevel(13);
    defaultMap.setMapTypeId(kakao.maps.MapTypeId.SKYVIEW);

    setMap(defaultMap);
  }, []);
  useEffect(() => {
    /* 지도 확대/축소 레벨 변경 */
    if (map !== null) {
      map.setLevel(mapLevel, { animate: true });
    }
  }, [mapLevel]);
  useEffect(() => {
    /* 지도 위성/일반 변경 */
    if (map !== null) {
      switch (mapType) {
        case "satellite":
          map.setMapTypeId(kakao.maps.MapTypeId.SKYVIEW);
          break;
        case "normal":
          map.setMapTypeId(kakao.maps.MapTypeId.ROADMAP);
          break;
        default:
          break;
      }
    }
  }, [mapType]);
  useEffect(() => {
    /* 지도 변경 -> 마커 생성 */
    if (map !== null) {
      // 강우계
      getRainMarkers(
        selected.id,
        map,
        markerMouseOver,
        markerMouseOut,
        markerClick,
        onClickSnsr
      ).then((response) => {
        setRainMarker(response);
      });
      // 수위계
      getLevelMarkers(
        selected.id,
        map,
        markerMouseOver,
        markerMouseOut,
        markerClick,
        onClickSnsr
      ).then((response) => {
        setLevelMarker(response);
      });
      // 지하수위계
      getGroundMarkers(
        selected.id,
        map,
        markerMouseOver,
        markerMouseOut,
        markerClick,
        onClickSnsr
      ).then((response) => {
        setGroundMarker(response);
      });
      // // 변위계
      getDisMarkers(
        selected.id,
        map,
        markerMouseOver,
        markerMouseOut,
        markerClick,
        onClickSnsr
      ).then((response) => {
        setDisMarker(response);
      });
      // // 방송장비
      getBroadMarkers(
        selected.id,
        map,
        markerMouseOver,
        markerMouseOut,
        markerClick
      ).then((response) => {
        setBrdMarker(response);
      });
      // // CCTV
      getCCTVMarkers(
        selected.id,
        map,
        markerMouseOver,
        markerMouseOut,
        markerClick,
        onClickCCTV
      ).then((response) => {
        setCctvMarker(response);
      });
    }
  }, [map, selected]);

  /* 1분마다 상태 업데이트*/
  useEffect(() => {
    // 강우계
    if (map !== null) {
      const timer = setTimeout(() => {
        getRainMarkers(
          selected.id,
          map,
          markerMouseOver,
          markerMouseOut,
          markerClick,
          onClickSnsr
        ).then((response) => {
          if (Array.isArray(rainMarker) && rainMarker.length > 0) {
            rainMarker.forEach((item) => {
              // 새로운 마커 정보 => 이전 마커 정보와 동일하게
              const target = response.find((item2) => item2.eqId === item.eqId);
              if (target) {
                // 마커 (default: 마커 출력O)
                if (item.marker.getMap() === null) {
                  target.marker.setMap(null);
                }
                // 오버레이 (default: 오버레이 출력X)
                if (item.overlay.getMap() !== null) {
                  target.overlay.setMap(map);
                }
                // 클릭오버레이 (default: 클릭오버레이 출력X)
                if (item.clickOverlay.getMap() !== null) {
                  target.clickOverlay.setMap(map);
                }
              }
              // 이전 마커 정보 setMap(null)
              item.marker.setMap(null);
              item.overlay.setMap(null);
              item.clickOverlay.setMap(null);
            });
          }
          setRainMarker(response);
        });
      }, 60000);
      return () => clearTimeout(timer);
    }
  }, [map, rainMarker, selected]);
  useEffect(() => {
    // 수위계
    if (map !== null) {
      const timer = setTimeout(() => {
        getLevelMarkers(
          selected.id,
          map,
          markerMouseOver,
          markerMouseOut,
          markerClick,
          onClickSnsr
        ).then((response) => {
          if (Array.isArray(levelMarker) && levelMarker.length > 0) {
            levelMarker.forEach((item) => {
              // 새로운 마커 정보 => 이전 마커 정보와 동일하게
              const target = response.find((item2) => item2.eqId === item.eqId);
              if (target) {
                // 마커 (default: 마커 출력O)
                if (item.marker.getMap() === null) {
                  target.marker.setMap(null);
                }
                // 오버레이 (default: 오버레이 출력X)
                if (item.overlay.getMap() !== null) {
                  target.overlay.setMap(map);
                }
                // 클릭오버레이 (default: 클릭오버레이 출력X)
                if (item.clickOverlay.getMap() !== null) {
                  target.clickOverlay.setMap(map);
                }
              }
              // 이전 마커 정보 setMap(null)
              item.marker.setMap(null);
              item.overlay.setMap(null);
              item.clickOverlay.setMap(null);
            });
          }
          setLevelMarker(response);
        });
      }, 60000);
      return () => clearTimeout(timer);
    }
  }, [map, levelMarker, selected]);
  useEffect(() => {
    // 지하수위계
    if (map !== null) {
      const timer = setTimeout(() => {
        getGroundMarkers(
          selected.id,
          map,
          markerMouseOver,
          markerMouseOut,
          markerClick,
          onClickSnsr
        ).then((response) => {
          if (Array.isArray(groundMarker) && groundMarker.length > 0) {
            groundMarker.forEach((item) => {
              // 새로운 마커 정보 => 이전 마커 정보와 동일하게
              const target = response.find((item2) => item2.eqId === item.eqId);
              if (target) {
                // 마커 (default: 마커 출력O)
                if (item.marker.getMap() === null) {
                  target.marker.setMap(null);
                }
                // 오버레이 (default: 오버레이 출력X)
                if (item.overlay.getMap() !== null) {
                  target.overlay.setMap(map);
                }
                // 클릭오버레이 (default: 클릭오버레이 출력X)
                if (item.clickOverlay.getMap() !== null) {
                  target.clickOverlay.setMap(map);
                }
              }
              // 이전 마커 정보 setMap(null)
              item.marker.setMap(null);
              item.overlay.setMap(null);
              item.clickOverlay.setMap(null);
            });
          }
          setGroundMarker(response);
        });
      }, 60000);
      return () => clearTimeout(timer);
    }
  }, [map, groundMarker, selected]);
  useEffect(() => {
    // 변위계
    if (map !== null) {
      const timer = setTimeout(() => {
        getDisMarkers(
          selected.id,
          map,
          markerMouseOver,
          markerMouseOut,
          markerClick,
          onClickSnsr
        ).then((response) => {
          if (Array.isArray(disMarker) && disMarker.length > 0) {
            disMarker.forEach((item) => {
              // 새로운 마커 정보 => 이전 마커 정보와 동일하게
              const target = response.find((item2) => item2.eqId === item.eqId);
              if (target) {
                // 마커 (default: 마커 출력O)
                if (item.marker.getMap() === null) {
                  target.marker.setMap(null);
                }
                // 오버레이 (default: 오버레이 출력X)
                if (item.overlay.getMap() !== null) {
                  target.overlay.setMap(map);
                }
                // 클릭오버레이 (default: 클릭오버레이 출력X)
                if (item.clickOverlay.getMap() !== null) {
                  target.clickOverlay.setMap(map);
                }
              }
              // 이전 마커 정보 setMap(null)
              item.marker.setMap(null);
              item.overlay.setMap(null);
              item.clickOverlay.setMap(null);
            });
          }
          setDisMarker(response);
        });
      }, 60000);
      return () => clearTimeout(timer);
    }
  }, [map, disMarker, selected]);
  useEffect(() => {
    // 방송장비
    if (map !== null) {
      const timer = setTimeout(() => {
        getBroadMarkers(
          selected.id,
          map,
          markerMouseOver,
          markerMouseOut,
          markerClick
        ).then((response) => {
          if (Array.isArray(brdMarker) && brdMarker.length > 0) {
            brdMarker.forEach((item) => {
              // 새로운 마커 정보 => 이전 마커 정보와 동일하게
              const target = response.find((item2) => item2.eqId === item.eqId);
              if (target) {
                // 마커 (default: 마커 출력O)
                if (item.marker.getMap() === null) {
                  target.marker.setMap(null);
                }
                // 오버레이 (default: 오버레이 출력X)
                if (item.overlay.getMap() !== null) {
                  target.overlay.setMap(map);
                }
                // 클릭오버레이 (default: 클릭오버레이 출력X)
                if (item.clickOverlay.getMap() !== null) {
                  target.clickOverlay.setMap(map);
                }
              }
              // 이전 마커 정보 setMap(null)
              item.marker.setMap(null);
              item.overlay.setMap(null);
              item.clickOverlay.setMap(null);
            });
          }
          setBrdMarker(response);
        });
      }, 60000);
      return () => clearTimeout(timer);
    }
  }, [map, brdMarker, selected]);
  useEffect(() => {
    // cctv
    if (map !== null) {
      const timer = setTimeout(() => {
        getCCTVMarkers(
          selected.id,
          map,
          markerMouseOver,
          markerMouseOut,
          markerClick,
          onClickCCTV
        ).then((response) => {
          if (Array.isArray(cctvMarker) && cctvMarker.length > 0) {
            cctvMarker.forEach((item) => {
              // 새로운 마커 정보 => 이전 마커 정보와 동일하게
              const target = response.find((item2) => item2.eqId === item.eqId);
              if (target) {
                // 마커 (default: 마커 출력O)
                if (item.marker.getMap() === null) {
                  target.marker.setMap(null);
                }
                // 오버레이 (default: 오버레이 출력X)
                if (item.overlay.getMap() !== null) {
                  target.overlay.setMap(map);
                }
                // 클릭오버레이 (default: 클릭오버레이 출력X)
                if (item.clickOverlay.getMap() !== null) {
                  target.clickOverlay.setMap(map);
                }
              }
              // 이전 마커 정보 setMap(null)
              item.marker.setMap(null);
              item.overlay.setMap(null);
              item.clickOverlay.setMap(null);
            });
          }
          setCctvMarker(response);
        });
      }, 60000);
      return () => clearTimeout(timer);
    }
  }, [map, cctvMarker, selected]);

  useEffect(() => {
    /* 장비/센서에 대한 마커&오버레이의 활성/비활성화 */
    // 강우계
    markerOverlayControl("rain", rainMarker);
    // 수위계
    markerOverlayControl("level", levelMarker);
    // 지하수위계
    markerOverlayControl("ground", groundMarker);
    // 변위계
    markerOverlayControl("displacement", disMarker);
    // 방송장비
    markerOverlayControl("broadcast", brdMarker);
    // CCTV
    markerOverlayControl("cctv", cctvMarker);
  }, [dvcPrint]);
  useEffect(() => {
    /* CCTV 연결 */
    if (Object.keys(selectedCCTV).length !== 0 && selectedCCTV.connect) {
      getCCTVVideo(selectedCCTV).then((response) => {
        if (response.result === "success") {
          // 연결 성공
          const port = response.port;
          const canvas = document.getElementById("allstatus-cctv-canvas");
          const protocol = window.location.protocol;
          let jsmpeg;
          if (protocol === "https:") {
            jsmpeg = new JSMpeg.Player("wss://121.140.107.240:" + port, {
              canvas: canvas,
            });
          } else {
            jsmpeg = new JSMpeg.Player("ws://121.140.107.240:" + port, {
              canvas: canvas,
            });
          }

          setCctvPort(port);
          setCctvPlayer(jsmpeg);
        } else {
          // 연결 실패
          setCctvPort(0);
          setCctvPlayer(null);
        }
      });
    }
  }, [selectedCCTV]);
  useEffect(() => {
    /* cctv 통신 종료 */
    return () => {
      if (cctvPort !== 0) {
        if (cctvPlayer !== undefined && cctvPlayer !== null) {
          cctvPlayer.destroy();
          setCctvPlayer(null);
        }
        quitCCTVVideo(cctvPort);
      }
    };
  }, [cctvPort]);
  useEffect(() => {
    if (map !== null) {
      const newPosition = new kakao.maps.LatLng(selected.lat, selected.lon);
      map.setCenter(newPosition);
      if (selected.id === 4) {
        setMapLevel(2);
      } else {
        setMapLevel(1);
      }
      setMapType("satellite");

      setSelectedSnsr({});
      if (Array.isArray(rainMarker) && rainMarker.length > 0) {
        rainMarker.forEach((item) => {
          item.marker.setMap(null);
          item.overlay.setMap(null);
          item.clickOverlay.setMap(null);
        });
      }
      if (Array.isArray(levelMarker) && levelMarker.length > 0) {
        levelMarker.forEach((item) => {
          item.marker.setMap(null);
          item.overlay.setMap(null);
          item.clickOverlay.setMap(null);
        });
      }
      if (Array.isArray(groundMarker) && groundMarker.length > 0) {
        groundMarker.forEach((item) => {
          item.marker.setMap(null);
          item.overlay.setMap(null);
          item.clickOverlay.setMap(null);
        });
      }
      if (Array.isArray(disMarker) && disMarker.length > 0) {
        disMarker.forEach((item) => {
          item.marker.setMap(null);
          item.overlay.setMap(null);
          item.clickOverlay.setMap(null);
        });
      }
      if (Array.isArray(brdMarker) && brdMarker.length > 0) {
        brdMarker.forEach((item) => {
          item.marker.setMap(null);
          item.overlay.setMap(null);
          item.clickOverlay.setMap(null);
        });
      }
      if (Array.isArray(cctvMarker) && cctvMarker.length > 0) {
        cctvMarker.forEach((item) => {
          item.marker.setMap(null);
          item.overlay.setMap(null);
          item.clickOverlay.setMap(null);
        });
      }
      setRainMarker([]);
      setLevelMarker([]);
      setGroundMarker([]);
      setDisMarker([]);
      setBrdMarker([]);
      setCctvMarker([]);
      setSelectedCCTV({});
      setDvcPrint([
        "broadcast",
        "cctv",
        "rain",
        "level",
        "ground",
        "displacement",
      ]);
      setDvcPrintBfr([
        "broadcast",
        "cctv",
        "rain",
        "level",
        "ground",
        "displacement",
      ]);
    }
  }, [selected]);

  return (
    <div id="allstatus-map-component">
      {
        // 선택된 센서 정보
        Object.keys(selectedSnsr).length > 0 && (
          <SnsrData selected={selectedSnsr} setSelected={setSelectedSnsr} />
        )
      }

      <div id="allstatus-kakao-map" ref={mapRef} />
      {/* 컨트롤 */}
      <MapControlBar mapLevel={mapLevel} setMapLevel={setMapLevel} />
      {/* 위성/일반 */}
      <MapType mapType={mapType} setMapType={setMapType} />
      {/* 범례 & 장비표시 */}
      <MapLegend
        selected={selected}
        dvcPrint={dvcPrint}
        setDvcPrint={setDvcPrint}
        setDvcPrintBfr={setDvcPrintBfr}
      />
      <div className="map-reservoir-name">{selected.name}</div>

      {/* CCTV 화면 */}
      {Object.keys(selectedCCTV).length > 0 && (
        <Draggable bounds="#allstatus-map-component" nodeRef={cctvScreenRef}>
          <div className="cctv-screen-container" ref={cctvScreenRef}>
            <div className="cctv-screen-header">
              <div>{selectedCCTV.eqNm}</div>
              <FontAwesomeIcon
                icon={faX}
                className="close-button"
                onClick={onClickCCTVClose}
              />
            </div>
            <div className="cctv-screen-main">
              <canvas id="allstatus-cctv-canvas" />
              {(!selectedCCTV.connect || cctvPort === 0) && (
                <div className="error-container">
                  <div>cctv 연결에 실패하였습니다.</div>
                  <div>cctv 정보가 제대로 입력되어 있는지 확인해주세요</div>
                </div>
              )}
            </div>
          </div>
        </Draggable>
      )}
    </div>
  );
}

export default Map;
