import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { setMenu, setSubmenu } from "../../../redux/modules/menu";
import { useNavigate } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHome,
  faList,
  faMessage,
  faDesktop,
} from "@fortawesome/free-solid-svg-icons";

import { tokenCheck } from "../../../utils/tokenCheck";
import {
  getEdboardMessage,
  sendEdboard,
} from "../../../services/internal/edboardAPI";
import Loading from "../../../components/common/window/Loading";
import EdboardList from "../../../components/edboard/send/EdboardList";
import MessageSend from "../../../components/edboard/send/MessageSend";
import EdboardCurr from "../../../components/edboard/send/EdboardCurr";
import Confirm from "../../../components/common/window/Confirm";
import Alert from "../../../components/common/window/Alert";

const PAGE_VALUE = "전광판";
const SUBPAGE_VALUE = "메시지전송";

function EdboardSend() {
  const dispatch = useDispatch();
  const [isToken, setIsToken] = useState(false);
  const movePage = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [edbData, setEdbData] = useState([]);
  const [selectedEdb, setSelectedEdb] = useState({});
  const [selectedMsg, setSelectedMsg] = useState({});
  const [isChange, setIsChange] = useState(false);
  const [isSend, setIsSend] = useState({ isOpen: false, text: "" });
  const [isSendAlert, setIsSendAlert] = useState(false);

  function onClickSend() {
    // 전송 버튼 클릭 시
    if (
      Object.keys(selectedEdb).length !== 0 &&
      Object.keys(selectedMsg).length !== 0
    ) {
      // 토큰 확인
      tokenCheck().then((result) => {
        if (!result) {
          alert("인증 토큰이 만료되었습니다. 다시 로그인 해주세요");
          movePage("/");
        } else {
          setIsSend({ isOpen: true, text: "메시지를 전송하시겠습니까?" });
        }
      });
    }
  }
  function sendMessage() {
    // 메시지 전송
    setIsLoading(true);
    sendEdboard(selectedMsg.ledId).then((response) => {
      setIsLoading(false);
      if (response === "메시지전송 성공") {
        setIsChange(true);
      } else {
        setIsSendAlert(true);
      }
    });
  }

  useEffect(() => {
    dispatch(setMenu(PAGE_VALUE));
    dispatch(setSubmenu(SUBPAGE_VALUE));

    // 토큰 확인
    tokenCheck().then((result) => {
      if (!result) {
        alert("인증 토큰이 만료되었습니다. 다시 로그인 해주세요");
        movePage("/");
      } else {
        setIsToken(true);
        setIsLoading(true);
        getEdboardMessage().then((response) => {
          setIsLoading(false);
          setEdbData(response);

          if (Array.isArray(response) && response.length > 0) {
            setSelectedEdb(response[0]);
          } else {
            setSelectedEdb({});
          }
        });
      }
    });
  }, []);
  useEffect(() => {
    if (isChange) {
      setIsLoading(true);
      getEdboardMessage().then((response) => {
        setIsLoading(false);
        setEdbData(response);

        if (Array.isArray(response) && response.length > 0) {
          setSelectedEdb(response[0]);
        } else {
          setSelectedEdb({});
        }
      });

      setIsChange(false);
    }
  }, [isChange]);
  useEffect(() => {
    if (Object.keys(selectedEdb).length !== 0) {
      if (
        Array.isArray(selectedEdb.msgList) &&
        selectedEdb.msgList.length > 0
      ) {
        const tokenInform = localStorage.getItem("tokenInform");
        const tokenInformParse = JSON.parse(tokenInform);
        const siteMsg = selectedEdb.msgList.filter(
          (item) => item.siteId === tokenInformParse.siteId
        );

        if (Array.isArray(siteMsg) && siteMsg.length > 0) {
          setSelectedMsg(siteMsg[0]);
        } else {
          setSelectedMsg({});
        }
      }
    } else {
      setSelectedMsg({});
    }
  }, [selectedEdb]);

  return (
    <div className="outlet_container">
      {
        // 로딩화면
        isLoading && <Loading isOpen={isLoading} />
      }
      {
        // 메시지 전송
        isSend.isOpen && (
          <Confirm isOpen={isSend} setIsOpen={setIsSend} active={sendMessage} />
        )
      }
      {
        // 메시지 전송 실패
        isSendAlert && (
          <Alert
            isOpen={isSendAlert}
            setIsOpen={setIsSendAlert}
            text="메시지 전송에 실패하였습니다"
          />
        )
      }
      {isToken && (
        <div id="edboard-send-page">
          <div className="header_container">
            <div className="title">
              <div className="title_imp">메시지 전송</div>
            </div>
            <div className="path">
              <FontAwesomeIcon icon={faHome} />
              <div className="text">&gt; 전광판 &gt; 메시지전송</div>
            </div>
          </div>

          <div className="main_container_row">
            {/* 전광판 리스트 */}
            <div className="edboard-list contents_container">
              <div className="contents_header">
                <div className="title">
                  <FontAwesomeIcon icon={faList} />
                  <div className="text">전광판</div>
                </div>
              </div>
              <div className="contents_main">
                <EdboardList
                  edbData={edbData}
                  selected={selectedEdb}
                  setSelected={setSelectedEdb}
                />
              </div>
              <div className="contents_footer"></div>
            </div>

            <div className="not-edboard-list">
              {/* 메시지 전송 */}
              <div className="message-send contents_container">
                <div className="contents_header">
                  <div className="title">
                    <FontAwesomeIcon icon={faMessage} />
                    <div className="text">메시지 전송</div>
                  </div>
                  {edbData.length !== 0 &&
                    Object.keys(selectedEdb).length !== 0 &&
                    Array.isArray(selectedEdb.msgList) &&
                    selectedEdb.msgList.length > 0 && (
                      <div className="button_imp" onClick={onClickSend}>
                        전송
                      </div>
                    )}
                </div>
                <div className="contents_main">
                  {edbData.length !== 0 && (
                    <MessageSend
                      selected={selectedEdb}
                      selectedMsg={selectedMsg}
                      setSelectedMsg={setSelectedMsg}
                    />
                  )}
                </div>
                <div className="contents_footer"></div>
              </div>

              {/* 전광판 현황 */}
              <div className="edboard-curr contents_container">
                <div className="contents_header">
                  <div className="title">
                    <FontAwesomeIcon icon={faDesktop} />
                    <div className="text">전광판 현황</div>
                  </div>
                </div>
                <div className="contents_main">
                  {edbData.length !== 0 && (
                    <EdboardCurr selected={selectedEdb} />
                  )}
                </div>
                <div className="contents_footer"></div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default EdboardSend;
