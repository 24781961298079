import { useState, useEffect } from "react";

function StatisticalData({ data }) {
  const [beforeData, setBeforeData] = useState([]);
  const [standardData, setStandardData] = useState([]);
  const [compareData, setCompareData] = useState([]);

  useEffect(() => {
    if (Array.isArray(data) && data.length !== 0) {
      const totalData = data;
      const before = totalData.find((item) => item.type === "before")?.data;
      const standard = totalData.find((item) => item.type === "standard")?.data;
      const compare = totalData.find((item) => item.type === "compare")?.data;

      setBeforeData(before);
      setStandardData(standard);
      setCompareData(compare);
    }
  }, [data]);

  return (
    <div id="statistical-data-component">
      {/* 구분 */}
      <div className="division-container">
        <div className="division">구분</div>
        <div className="division-slope">
          <div className="slope-title">경사계</div>
          <div className="slope-xy">
            <div className="slope-x">x축</div>
            <div className="slope-y">y축</div>
          </div>
        </div>
        <div className="division-dis">
          <div className="dis-title">변위계</div>
          <div className="dis-mm">mm</div>
        </div>
        <div className="division-manage-level">관리단계</div>
      </div>

      <div className="not-division-container">
        {/* 월 구분 */}
        <div className="month-container">
          <div className="month-item">이전월</div>
          <div className="month-item">기준월</div>
          <div className="month-item month-item-last">증감</div>
        </div>

        {/* 이전월 & 기준월 & 증감 */}
        <div className="before-standard-compare">
          <div className="before-standard">
            <div className="data">
              {/* 이전월 */}
              <div className="before">
                {beforeData.length !== 0 &&
                  beforeData.map((item, index) => (
                    <div
                      key={item.eqNm}
                      className={
                        index === beforeData.length - 1
                          ? "data-container data-container-last"
                          : "data-container"
                      }
                      style={{
                        width:
                          index === beforeData.length - 1
                            ? `calc(100% / ${beforeData.length})`
                            : `calc(100% / ${beforeData.length} - 1px)`,
                      }}
                    >
                      <div className="data-value data-value-title">
                        {item.eqNm}
                      </div>
                      <div className="data-value">{item.x}</div>
                      <div className="data-value">{item.y}</div>
                      <div className="data-value data-value-last">
                        {"mm" in item ? item.mm : "-"}
                      </div>
                    </div>
                  ))}
              </div>

              {/* 기준월 */}
              <div className="standard">
                {standardData.length !== 0 &&
                  standardData.map((item, index) => (
                    <div
                      key={item.eqNm}
                      className={
                        index === standardData.length - 1
                          ? "data-container data-container-last"
                          : "data-container"
                      }
                      style={{
                        width:
                          index === standardData.length - 1
                            ? `calc(100% / ${standardData.length})`
                            : `calc(100% / ${standardData.length} - 1px)`,
                      }}
                    >
                      <div className="data-value data-value-title">
                        {item.eqNm}-
                      </div>
                      <div className="data-value data-bold">{item.x}</div>
                      <div className="data-value data-bold">{item.y}</div>
                      <div className="data-value data-value-last data-bold">
                        {"mm" in item ? item.mm : "-"}
                      </div>
                    </div>
                  ))}
              </div>
            </div>
            <div className="manage-level"></div>
          </div>
          {/* 증감 */}
          <div className="compare">
            {compareData.length !== 0 &&
              compareData.map((item, index) => (
                <div
                  key={item.eqNm}
                  className={
                    index === compareData.length - 1
                      ? "data-container data-container-last"
                      : "data-container"
                  }
                  style={{
                    width:
                      index === compareData.length - 1
                        ? `calc(100% / ${compareData.length})`
                        : `calc(100% / ${compareData.length} - 1px)`,
                  }}
                >
                  <div className="data-value-compare data-value-title-compare">
                    {item.eqNm}-
                  </div>
                  <div className="data-value-compare">{item.x}</div>
                  <div className="data-value-compare">
                    {item.y}
                  </div>
                  <div className="data-value-compare">
                    {"mm" in item ? item.mm : "-"}
                  </div>
                  <div className="data-value-compare data-value-last-compare">
                    {item.status === "normal" && "이상없음"}
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default StatisticalData;
