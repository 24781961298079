import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Menu, Item, useContextMenu } from "react-contexify";
import {
  createColumnHelper,
  flexRender,
  useReactTable,
  getCoreRowModel,
  getSortedRowModel,
} from "@tanstack/react-table";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisVertical } from "@fortawesome/free-solid-svg-icons";

import { tokenCheck } from "../../../utils/tokenCheck";
import EdboardInform from "../../common/inform/EdboardInform";

const MENU_ID = "edboardList";

function EdboardList({ edbData, selected, setSelected }) {
  const movePage = useNavigate();
  const [isInform, setIsInform] = useState(false);
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);
  const columnHelper = createColumnHelper();
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });
  const menuIconColumn = {
    id: "menuIcon",
    accessor: "menu",
    header: "",
    size: 10,
    enableSorting: false,
    cell: ({ row }) => (
      <FontAwesomeIcon className="table_icon" icon={faEllipsisVertical} />
    ),
  };
  const { show } = useContextMenu({
    id: MENU_ID,
  });

  function onClickMenu(e, row) {
    // Context 메뉴.
    e.stopPropagation();
    show({ event: e, props: { data: row } });
  }
  function menuItemClick({ id, props }) {
    // 메뉴 Item 클릭 시.
    // 토큰 확인
    tokenCheck().then((result) => {
      if (!result) {
        alert("인증 토큰이 만료되었습니다. 다시 로그인 해주세요");
        movePage("/");
      } else {
        switch (id) {
          // 정보.
          case "inform":
            setIsInform({ isOpen: true, data: props.data });
            break;
          default:
            break;
        }
      }
    });
  }

  useEffect(() => {
    if (Array.isArray(edbData) && edbData.length === 0) {
      setData([]);
      setColumns([]);
    } else {
      const column = [
        columnHelper.accessor("eqNm", { header: "전광판명", size: 150 }),
        columnHelper.accessor("bdongCd", { header: "법정동코드", size: 80 }),
        columnHelper.accessor("addr", { header: "주소", size: 250 }),
        columnHelper.accessor(
          (row) => {
            const msgList = row.msgList;
            if (
              msgList.length > 0 &&
              msgList.find((item) => item.useYn === "Y")
            ) {
              return "작동중";
            } else {
              return "작동안함";
            }
          },
          { id: "status", header: "상태", size: 70 }
        ),
        menuIconColumn,
      ];

      setData(edbData);
      setColumns(column);
    }
  }, [edbData]);

  return (
    <div id="edboard-send-edboard-list-component">
      {
        // 전광판 정보
        isInform.isOpen && (
          <EdboardInform isOpen={isInform} setIsOpen={setIsInform} />
        )
      }
      {data.length === 0 ? (
        <div className="data-none-container">등록된 전광판이 없습니다</div>
      ) : (
        <div className="list">
          <table className="react_table">
            <thead>
              {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <th
                      key={header.id}
                      onClick={header.column.getToggleSortingHandler()}
                      style={{
                        width: isNaN(header.getSize())
                          ? "auto"
                          : `${header.getSize()}px`,
                        minWidth: isNaN(header.getSize())
                          ? "auto"
                          : `${header.getSize()}px`,
                        maxWidth: isNaN(header.getSize())
                          ? "auto"
                          : `${header.getSize()}px`,
                      }}
                    >
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody>
              {table.getRowModel().rows.map((row, index) => (
                <tr
                  key={row.id}
                  className={
                    selected === row.original
                      ? "tr_select"
                      : index % 2 === 0
                      ? "tr_even"
                      : "tr_odd"
                  }
                  onClick={() => setSelected(row.original)}
                >
                  {row.getVisibleCells().map((cell) => (
                    <td
                      key={cell.id}
                      onClick={(e) => {
                        if (cell.column.id === "menuIcon") {
                          onClickMenu(e, row.original);
                        }
                      }}
                      style={{
                        width: isNaN(cell.column.getSize())
                          ? "auto"
                          : `${cell.column.getSize()}px`,
                        minWidth: isNaN(cell.column.getSize())
                          ? "auto"
                          : `${cell.column.getSize()}px`,
                        maxWidth: isNaN(cell.column.getSize())
                          ? "auto"
                          : `${cell.column.getSize()}px`,
                        textAlign:
                          (cell.column.id === "bdongCd" ||
                            cell.column.id === "status" ||
                            cell.column.id === "menuIcon") &&
                          "center",
                        color:
                          cell.column.id === "status" &&
                          row.original.msgList.length > 0 &&
                          row.original.msgList.find(
                            (item) => item.useYn === "Y"
                          ) &&
                          "#3DC62A",
                      }}
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      {/* Context 메뉴 */}
      <Menu id={MENU_ID}>
        <Item id="inform" onClick={menuItemClick}>
          정보
        </Item>
      </Menu>
    </div>
  );
}

export default EdboardList;
