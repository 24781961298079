import { useState, useEffect } from "react";
import {
  createColumnHelper,
  flexRender,
  useReactTable,
  getCoreRowModel,
} from "@tanstack/react-table";

import { dateTimeToFormat } from "../../../utils/dateFormat";

function SMSDetail({ selected, selectedData }) {
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);
  const columnHelper = createColumnHelper();
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  useEffect(() => {
    if (selectedData !== undefined) {
      const column = [
        columnHelper.accessor("receiver", { header: "수신자" }),
        columnHelper.accessor(
          (row) => {
            if (row.sms_state === "전송중") {
              return "전송요청완료";
            } else {
              return row.sms_state;
            }
          },
          { id: "sms_state", header: "발송결과" }
        ),
      ];

      setData(selectedData);
      setColumns(column);
    }
  }, [selectedData]);

  return (
    <div id="sms-detail-component">
      {data.length > 0 && (
        <>
          <div className="date-container">
            <div className="title">발송일자</div>
            <div className="date-value">
              {dateTimeToFormat(selected.sendDt)}
            </div>
          </div>
          <div className="receiver-container">
            <div className="title">수신자</div>
            <div className="receiver-value">
              <table className="react_table">
                <thead>
                  {table.getHeaderGroups().map((headerGroup) => (
                    <tr key={headerGroup.id}>
                      {headerGroup.headers.map((header) => (
                        <th
                          key={header.id}
                          style={{
                            width: isNaN(header.getSize())
                              ? "auto"
                              : `${header.getSize()}px`,
                          }}
                        >
                          {header.isPlaceholder
                            ? null
                            : flexRender(
                                header.column.columnDef.header,
                                header.getContext()
                              )}
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody>
                  {table.getRowModel().rows.map((row, index) => (
                    <tr
                      key={row.id}
                      className={
                        index % 2 === 0 ? "tr-even-receiver" : "tr-odd-receiver"
                      }
                    >
                      {row.getVisibleCells().map((cell) => (
                        <td
                          key={cell.id}
                          className={cell.column.id === "msg" ? "td-msg" : ""}
                          style={{
                            width: isNaN(cell.column.getSize())
                              ? "auto"
                              : `${cell.column.getSize()}px`,
                            minWidth: isNaN(cell.column.getSize())
                              ? "auto"
                              : `${cell.column.getSize()}px`,
                            maxWidth: isNaN(cell.column.getSize())
                              ? "auto"
                              : `${cell.column.getSize()}px`,
                            textAlign:
                              cell.column.id === "sms_state" && "center",
                            color:
                              cell.column.id === "sms_state" &&
                              row.original.sms_state.includes("발송완료")
                                ? "inherit"
                                : cell.column.id === "sms_state" &&
                                  row.original.sms_state.includes("전송중")
                                ? "#D9A205"
                                : cell.column.id === "sms_state" && "#d24211",
                          }}
                        >
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <div className="msg-container">
            <div className="title">메시지</div>
            <textarea value={selected.sendText} disabled={true} />
          </div>
        </>
      )}
    </div>
  );
}

export default SMSDetail;
