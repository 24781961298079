import { useState, useEffect } from "react";
import {
  createColumnHelper,
  flexRender,
  useReactTable,
  getCoreRowModel,
} from "@tanstack/react-table";

import { sampleData } from "../../constants/data/sampleData";
import loadingImg from "../../assets/common/loading.gif";

function BroadHistory({ selected }) {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);
  const columnHelper = createColumnHelper();
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  function fetchBroad() {
    /* 방송내역 조회 API */
    setIsLoading(true);
    const sampleArr = sampleData.broadHistory;
    const sample =
      Object.keys(selected).length > 0
        ? sampleArr.filter((item) => item.id === selected.id)
        : sampleArr;
    const column = [
      columnHelper.accessor("dvcNm", { header: "방송장비(그룹)", size: 100 }),
      columnHelper.accessor("title", { header: "방송제목", size: 100 }),
      columnHelper.accessor("msg", { header: "방송내용", size: 200 }),
      columnHelper.accessor("sendDt", { header: "방송일자", size: 100 }),
      columnHelper.accessor("status", { header: "결과", size: 50 }),
    ];

    setData(sample);
    setColumns(column);

    setIsLoading(false);
  }

  useEffect(() => {
    fetchBroad();
  }, [selected]);

  return (
    <div id="allstatus-broad-component">
      {isLoading ? (
        <div className="loading-container">
          <img src={loadingImg} alt="" />
        </div>
      ) : !Array.isArray(data) || data.length <= 0 ? (
        <div className="data-none-container" style={{ fontSize: "0.8rem" }}>
          금일 방송내역 조회 결과가 없습니다
        </div>
      ) : (
        <div className="list">
          <table className="react-renewal-table">
            <thead>
              {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <th
                      key={header.id}
                      style={{
                        width: isNaN(header.getSize())
                          ? "auto"
                          : `${header.getSize()}px`,
                        minWidth: isNaN(header.getSize())
                          ? "auto"
                          : `${header.getSize()}px`,
                        maxWidth: isNaN(header.getSize())
                          ? "auto"
                          : `${header.getSize()}px`,
                        textAlign:
                          header.id === "dvcNm" ||
                          header.id === "title" ||
                          header.id === "msg"
                            ? "left"
                            : "center",
                      }}
                    >
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody>
              {table.getRowModel().rows.map((row, index) => (
                <tr key={row.id}>
                  {row.getVisibleCells().map((cell) => (
                    <td
                      key={cell.id}
                      style={{
                        width: isNaN(cell.column.getSize())
                          ? "auto"
                          : `${cell.column.getSize()}px`,
                        minWidth: isNaN(cell.column.getSize())
                          ? "auto"
                          : `${cell.column.getSize()}px`,
                        maxWidth: isNaN(cell.column.getSize())
                          ? "auto"
                          : `${cell.column.getSize()}px`,
                        textAlign:
                          (cell.column.id === "sendDt" ||
                            cell.column.id === "status") &&
                          "center",
                      }}
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}

export default BroadHistory;
