import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { setMenu, setSubmenu } from "../../../redux/modules/menu";
import { useNavigate } from "react-router-dom";
import axios from "axios";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome, faList } from "@fortawesome/free-solid-svg-icons";

import { tokenCheck } from "../../../utils/tokenCheck";
import apiAddress from "../../../services/apiAddress";
import Delete from "../../../components/common/window/Delete";
import Region from "../../../components/setting/region/Region";
import RegionAdd from "../../../components/setting/region/RegionAdd";
import Equip from "../../../components/setting/region/Equip";
import EquipReg from "../../../components/setting/region/EquipReg";

const PAGE_VALUE = "설정";
const SUBPAGE_VALUE = "관리지역";

function SettingRegion() {
  const dispatch = useDispatch();
  const [isToken, setIsToken] = useState(false);
  const movePage = useNavigate();
  /* 관리지역 */
  const [isAdd, setIsAdd] = useState(false);
  const [isAddOk, setIsAddOk] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [search, setSearch] = useState("");
  const [selectedRgnId, setSelectedRgnId] = useState(0);
  /* 장비등록 */
  const [isReg, setIsReg] = useState(false);
  const [isDel, setIsDel] = useState({
    isOpen: false,
    text: "",
  });
  const [isRegOk, setIsRegOk] = useState(false);
  const [isDelOk, setIsDelOk] = useState(false);
  const [delEquip, setDelEquip] = useState([]);

  function onKeyPressSearch(e) {
    // 검색창에 텍스트 입력 후, Enter.
    if (e.key === "Enter") {
      onClickSearch();
    }
  }
  function onClickSearch() {
    // 검색 실행.
    // 토큰 확인
    tokenCheck().then((result) => {
      if (!result) {
        alert("인증 토큰이 만료되었습니다. 다시 로그인 해주세요");
        movePage("/");
      } else {
        setSearch(searchInput);
      }
    });
  }
  function onClickClear() {
    // 토큰 확인
    tokenCheck().then((result) => {
      if (!result) {
        alert("인증 토큰이 만료되었습니다. 다시 로그인 해주세요");
        movePage("/");
      } else {
        setIsDel({
          isOpen: true,
          text: delEquip.length + "개의 장비를 삭제하시겠습니까?",
          data: delEquip,
        });
      }
    });
  }

  /* 관리지역 장비 삭제 API*/
  async function fetchDataDel(data) {
    try {
      for (let i = 0; i < data.length; i++) {
        axios
          .delete(apiAddress.regionEquipDelete + data[i], {
            headers: {
              "Content-Type": "appication/json",
            },
          })
          .then((response) => {
            if (i + 1 === data.length) {
              setIsDelOk(!isDelOk);
              setDelEquip([]);
            }
          });
      }
    } catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    dispatch(setMenu(PAGE_VALUE));
    dispatch(setSubmenu(SUBPAGE_VALUE));

    // 토큰 확인
    tokenCheck().then((result) => {
      if (!result) {
        alert("인증 토큰이 만료되었습니다. 다시 로그인 해주세요");
        movePage("/");
      } else {
        setIsToken(true);
      }
    });
  }, []);
  useEffect(() => {
    setDelEquip([]);
  }, [selectedRgnId]);

  return (
    <div className="outlet_container">
      {isAdd && (
        <RegionAdd
          isOpen={isAdd}
          setIsOpen={setIsAdd}
          isOk={isAddOk}
          setIsOk={setIsAddOk}
        />
      )}
      {isReg && (
        <EquipReg
          isOpen={isReg}
          setIsOpen={setIsReg}
          selectedId={selectedRgnId}
          isOk={isRegOk}
          setIsOk={setIsRegOk}
        />
      )}
      {isDel.isOpen && (
        <Delete isOpen={isDel} setIsOpen={setIsDel} delData={fetchDataDel} />
      )}
      {isToken && (
        <>
          <div className="header_container">
            <div className="title">
              <div className="title_imp">관리 지역</div>
              <div className="title_nor">설정</div>
            </div>
            <div className="path">
              <FontAwesomeIcon icon={faHome} />
              <div className="text">&gt; 설정 &gt; 관리지역</div>
            </div>
          </div>

          <div className="main_container_row">
            {/* 관리 지역 리스트 */}
            <div
              className="contents_container"
              style={{
                width: "calc(40% - 10px)",
                height: "100%",
                marginRight: "10px",
              }}
            >
              {/* 관리 지역 리스트 - Header */}
              <div className="contents_header">
                <div className="title">
                  <FontAwesomeIcon icon={faList} />
                  <div className="text">관리 지역</div>
                  <div
                    className="button_imp"
                    onClick={() => {
                      // 토큰 확인
                      tokenCheck().then((result) => {
                        if (!result) {
                          alert(
                            "인증 토큰이 만료되었습니다. 다시 로그인 해주세요"
                          );
                          movePage("/");
                        } else {
                          setIsAdd(true);
                        }
                      });
                    }}
                    style={{ marginLeft: "20px" }}
                  >
                    지역 추가
                  </div>
                </div>
                <div className="search">
                  <input
                    type="text"
                    placeholder="지역명"
                    value={searchInput}
                    onChange={(e) => setSearchInput(e.target.value)}
                    onKeyPress={onKeyPressSearch}
                    spellCheck="false"
                  />
                  <div
                    className="button_nor"
                    onClick={onClickSearch}
                    style={{ marginLeft: "10px" }}
                  >
                    검색
                  </div>
                </div>
              </div>

              {/* 관리 지역 리스트 - Main (Region Component) */}
              <div className="contents_main">
                <Region
                  isAddOk={isAddOk}
                  search={search}
                  selectedRgnId={selectedRgnId}
                  setSelectedRgnId={setSelectedRgnId}
                />
              </div>

              <div className="contents_footer"></div>
            </div>

            {/* 등록된 장비 리스트 */}
            <div
              className="contents_container"
              style={{
                width: "calc(60% - 10px)",
                height: "100%",
                marginLeft: "10px",
              }}
            >
              {/* 등록된 장비 리스트 - Header */}
              <div className="contents_header">
                <div className="title">
                  <FontAwesomeIcon icon={faList} />
                  <div className="text">소속 장비</div>
                  {selectedRgnId !== 0 && (
                    <div
                      className="button_imp"
                      onClick={() => {
                        // 토큰 확인
                        tokenCheck().then((result) => {
                          if (!result) {
                            alert(
                              "인증 토큰이 만료되었습니다. 다시 로그인 해주세요"
                            );
                            movePage("/");
                          } else {
                            setIsReg(true);
                          }
                        });
                      }}
                      style={{ marginLeft: "20px" }}
                    >
                      추가 등록
                    </div>
                  )}
                </div>
                {delEquip.length > 0 && (
                  <div className="button_del" onClick={onClickClear}>
                    삭제
                  </div>
                )}
              </div>

              <div className="contents_main_column">
                {selectedRgnId !== 0 && (
                  <Equip
                    selectedId={selectedRgnId}
                    isRegOk={isRegOk}
                    isDelOk={isDelOk}
                    delData={delEquip}
                    setDelData={setDelEquip}
                  />
                )}
              </div>

              <div className="contents_footer"></div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default SettingRegion;
