import { useEffect, useState } from "react";
import Modal from "react-modal";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import locale from "../../../data/datepicker";

import { dateToString } from "../../../utils/dateFormat";
import { MODALSTYLES } from "../../../constants/modalStyles";
import { DATEPICKERSTYLES } from "../../../constants/datepickerStyles";
import { latlon } from "../../../constants/latlon";
import { saveEquip } from "../../../services/internal/equipAPI";
import Bdong from "../../common/function/Bdong";
import LatLon from "../../common/function/LatLon";
import Alert from "../../common/window/Alert";
import Add from "../../common/window/Add";

Modal.setAppElement("#root");

function EdboardAdd({ isOpen, setIsOpen, setIsChange }) {
  const [inform, setInform] = useState({
    eqNm: "",
    eqType: "EDB",
    instlDt: new Date(),
    mnfct: "",
    modelNm: "",
    commType: "TCP/IP",
    commAddr: "",
    commPort: "",
    gateway: "",
    netmask: "",
    bdongCd: "",
    addr: "",
    addrDtl: "",
    lat: latlon.lat,
    lon: latlon.lon,
    eqDtl: "",
    useYn: "Y",
  });
  const [isBdong, setIsBdong] = useState(false);
  const [isLatLon, setIsLatLon] = useState(false);
  const [isAlert, setIsAlert] = useState(false);
  const [isAdd, setIsAdd] = useState(false);

  function onClickBdong() {
    // 코드 조회 클릭시
    setIsBdong(true);
  }
  function onClickLatLon() {
    // 지도 조회 클릭시
    setIsLatLon(true);
  }
  function onClickClose() {
    // 닫기 버튼 클릭시
    setIsOpen(false);
  }
  function onClickAdd() {
    // 등록 버튼 클릭 시
    if (
      inform.eqNm === "" ||
      inform.commAddr === "" ||
      inform.commPort === "" ||
      inform.bdongCd === "" ||
      inform.addr === "" ||
      inform.lat === "" ||
      inform.lon === ""
    ) {
      // 필수 정보 입력X
      setIsAlert(true);
    } else {
      // 전광판 등록
      setIsAdd(true);
    }
  }
  function addEdboard() {
    // 전광판 등록
    const tokenInform = localStorage.getItem("tokenInform");
    const tokenInformParse = JSON.parse(tokenInform);

    const dateInform = {
      ...inform,
      instlDt: dateToString(inform.instlDt),
      regUsr: tokenInformParse.userId,
    };
    saveEquip(dateInform).then((response) => {
      setIsChange(true);
      setIsOpen(false);
    });
  }

  return (
    <Modal isOpen={isOpen} style={MODALSTYLES.edboard}>
      {
        // 코드조회(법정동코드)
        isBdong && (
          <Bdong
            isOpen={isBdong}
            setIsOpen={setIsBdong}
            bdong={inform}
            setBdong={setInform}
          />
        )
      }
      {
        // 지도조회(위도/경도)
        isLatLon && (
          <LatLon
            isOpen={isLatLon}
            setIsOpen={setIsLatLon}
            data={inform}
            setData={setInform}
          />
        )
      }
      {
        // 필수 정보 입력X
        isAlert && (
          <Alert
            isOpen={isAlert}
            setIsOpen={setIsAlert}
            text="필수 정보를 입력해주세요"
          />
        )
      }
      {
        // 전광판 등록
        isAdd && (
          <Add isOpen={isAdd} setIsOpen={setIsAdd} addData={addEdboard} />
        )
      }
      <div className="modal_title">
        <div>전광판 등록</div>
      </div>

      <div className="modal_container">
        <div className="modal_main_column">
          <div className="edboard-add-edit-modal">
            <div className="typing-container">
              <div className="title">전광판명</div>
              <input
                className="two-input"
                type="text"
                placeholder="필수 입력 *"
                value={inform.eqNm}
                onChange={(e) => setInform({ ...inform, eqNm: e.target.value })}
              />
              <div className="inner-title">설치일자</div>
              <DatePicker
                selected={inform.instlDt}
                onChange={(date) => setInform({ ...inform, instlDt: date })}
                customInput={<DATEPICKERSTYLES.modal />}
                dateFormat="yyyy-MM-dd"
                locale={locale.ko}
              />
            </div>
            <div className="typing-container">
              <div className="title">제조업체</div>
              <input
                className="two-input"
                type="text"
                placeholder="제조업체 입력"
                value={inform.mnfct}
                onChange={(e) =>
                  setInform({ ...inform, mnfct: e.target.value })
                }
              />
              <div className="inner-title">모델명</div>
              <input
                className="two-input"
                type="text"
                placeholder="모델명 입력"
                value={inform.modelNm}
                onChange={(e) =>
                  setInform({ ...inform, modelNm: e.target.value })
                }
              />
            </div>
            <div className="typing-container">
              <div className="title">IP</div>
              <input
                className="two-input"
                type="text"
                placeholder="필수 입력 *"
                value={inform.commAddr}
                onChange={(e) =>
                  setInform({ ...inform, commAddr: e.target.value })
                }
              />
              <div className="inner-title">Port</div>
              <input
                className="two-input"
                type="number"
                placeholder="필수 입력 *"
                value={inform.commPort}
                onChange={(e) =>
                  setInform({ ...inform, commPort: e.target.value })
                }
              />
            </div>
            <div className="typing-container">
              <div className="title">Gateway</div>
              <input
                className="two-input"
                type="text"
                placeholder="Gateway 입력"
                value={inform.gateway}
                onChange={(e) =>
                  setInform({ ...inform, gateway: e.target.value })
                }
              />
              <div className="inner-title">Netmask</div>
              <input
                className="two-input"
                type="text"
                placeholder="Netmask 입력"
                value={inform.netmask}
                onChange={(e) =>
                  setInform({ ...inform, netmask: e.target.value })
                }
              />
            </div>
            <div className="typing-container">
              <div className="title">법정동코드</div>
              <input
                className="bdong"
                type="number"
                placeholder="필수 입력 *"
                value={inform.bdongCd}
                onChange={(e) =>
                  setInform({ ...inform, bdongCd: e.target.value })
                }
                disabled={true}
              />
              <div className="inner-title">주소</div>
              <input
                className="addr"
                type="text"
                placeholder="필수 입력 *"
                value={inform.addr}
                onChange={(e) => setInform({ ...inform, addr: e.target.value })}
                disabled={true}
              />
              <div className="button-modal button" onClick={onClickBdong}>
                코드조회
              </div>
            </div>
            <div className="typing-container">
              <div className="title">상세주소</div>
              <input
                className="one-input"
                type="text"
                placeholder="상세주소 입력"
                value={inform.addrDtl}
                onChange={(e) =>
                  setInform({ ...inform, addrDtl: e.target.value })
                }
              />
            </div>
            <div className="typing-container">
              <div className="title">위도</div>
              <input
                className="lat-lon"
                type="number"
                placeholder="필수 입력 *"
                value={inform.lat}
                onChange={(e) => setInform({ ...inform, lat: e.target.value })}
              />
              <div className="inner-title">경도</div>
              <input
                className="lat-lon"
                type="number"
                placeholder="필수 입력 *"
                value={inform.lon}
                onChange={(e) => setInform({ ...inform, lon: e.target.value })}
              />
              <div className="button-modal button" onClick={onClickLatLon}>
                지도조회
              </div>
            </div>
            <div className="last-typing-container">
              <div className="title">메모</div>
              <textarea
                type="text"
                placeholder="메모 입력"
                value={inform.eqDtl}
                onChange={(e) =>
                  setInform({ ...inform, eqDtl: e.target.value })
                }
              />
            </div>
          </div>
        </div>

        <div className="modal_footer">
          <div className="button-nor-modal" onClick={onClickClose}>
            닫기
          </div>
          <div className="button-imp right-button" onClick={onClickAdd}>
            등록
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default EdboardAdd;
