/* 현재 시간 가져오기 */
export function getCurrTime() {
  const date = new Date();
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const hour = date.getHours();
  const minute = date.getMinutes();
  const second = date.getSeconds();

  return {
    date: date,
    year: year,
    month: month,
    day: day,
    hour: hour,
    minute: minute,
    second: second,
  };
}

/* 1분 전 시간 구하기 */
export function getMinBefore() {
  const curDate = new Date();

  const date = new Date(curDate);
  date.setMinutes(curDate.getMinutes() - 1);
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const hour = date.getHours();
  const minute = date.getMinutes();

  return {
    date: date,
    year: year,
    month: month,
    day: day,
    hour: hour,
    minute: minute,
  };
}

/* 어제 날짜 구하기 */
export function getYesterday() {
  const curDate = new Date();

  const date = new Date(curDate);
  date.setDate(curDate.getDate() - 1);
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();

  return {
    date: date,
    year: year,
    month: month,
    day: day,
  };
}

// 10보다 작으면 앞에 "0" 붙이기
export function zeroPlusNum(num) {
  if (num < 10) {
    return "0" + num;
  } else {
    return num.toString();
  }
}
