import apiAddress from "../../services/apiAddress";
import axios from "axios";
import { getCurrTime, zeroPlusNum } from "../../utils/time";
import { dataDateToFormat } from "../../utils/data";
import {
  getReservoirDevice,
  getRsvDisSensor,
  getRsvGroundSensor,
  getRsvLevelSensor,
  getRsvRainSensor,
  getRtuDevice,
} from "./rtuAPI";

export function getData(term, fromDt, toDt, dvcId) {
  return axios
    .post(
      apiAddress.getData,
      {
        term: term,
        fromDt: fromDt,
        toDt: toDt,
        dvcId: dvcId,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      return response.data;
    });
}

// 강우계 조회
export function getRainSnsr() {
  return getRtuDevice().then((rtuDevice) => {
    const rainRtu = rtuDevice.filter(
      (rtuDeviceItem) =>
        rtuDeviceItem.dvcData.length > 0 &&
        rtuDeviceItem.dvcData.find(
          (dvcItem) =>
            dvcItem.dvcType === "SNSR" && dvcItem.dvcDtlType === "SNSR01"
        )
    );
    return rainRtu;
  });
}
// 수위계 조회
export function getLevelSnsr() {
  return getRtuDevice().then((rtuDevice) => {
    const levelRtu = rtuDevice.filter(
      (rtuDeviceItem) =>
        rtuDeviceItem.dvcData.length > 0 &&
        rtuDeviceItem.dvcData.find(
          (dvcItem) =>
            dvcItem.dvcType === "SNSR" && dvcItem.dvcDtlType === "SNSR02"
        )
    );
    return levelRtu;
  });
}
// 경사계 조회
export function getSlopeSnsr() {
  return getRtuDevice().then((rtuDevice) => {
    const slopeRtu = rtuDevice.filter(
      (rtuDeviceItem) =>
        rtuDeviceItem.dvcData.length > 0 &&
        rtuDeviceItem.dvcData.find(
          (dvcItem) =>
            dvcItem.dvcType === "SNSR" && dvcItem.dvcDtlType === "SNSR08"
        )
    );
    return slopeRtu;
  });
}
// 변위계 조회
export function getDisSnsr() {
  return getRtuDevice().then((rtuDevice) => {
    const disRtu = rtuDevice.filter(
      (rtuDeviceItem) =>
        rtuDeviceItem.dvcData.length > 0 &&
        rtuDeviceItem.dvcData.find(
          (dvcItem) =>
            dvcItem.dvcType === "SNSR" && dvcItem.dvcDtlType === "SNSR15"
        )
    );
    return disRtu;
  });
}

// 데이터 조회
export function getDataUpdate(term, fromDt, toDt, dvcInform) {
  return axios
    .post(
      apiAddress.getData,
      {
        cdDistObsv: parseInt(dvcInform.dvcId) - 1,
        term: term,
        fromDt: fromDt,
        toDt: toDt,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      const responseData = response.data;
      if (Object.keys(responseData).length > 0) {
        const target = responseData[dvcInform.dvcId - 1];
        if (Array.isArray(target) && target.length > 0) {
          const data = target.map((item) => ({
            date: dataDateToFormat(term, item),
            avgVal: item.avgVal,
          }));
          return data;
        } else {
          return [];
        }
      } else {
        return [];
      }
    });
}
// 저수위
export function getRsvLevel(rsvRtus, term, fromDt, toDt) {
  if (Array.isArray(rsvRtus) && rsvRtus.length > 0) {
    const filterLevel = getRsvLevelSensor(rsvRtus);
    if (Array.isArray(filterLevel) && filterLevel.length > 0) {
      return getDataUpdate(term, fromDt, toDt, { dvcId: 7 }).then((data) => {
        const deviceData = filterLevel.map((item, index) => {
          const tmpData = [...data];
          if (index !== 0) {
            const formatData = tmpData.map((dataItem) => {
              if (dataItem.avgVal !== "-") {
                return {
                  date: dataItem.date,
                  avgVal:
                    index % 2 === 0
                      ? parseFloat(
                          parseFloat(dataItem.avgVal) +
                            index -
                            (index / 10 + 0.03)
                        ).toFixed(2)
                      : parseFloat(
                          parseFloat(dataItem.avgVal) -
                            index +
                            (index / 10 - 0.03)
                        ).toFixed(2),
                };
              } else {
                return dataItem;
              }
            });
            return { ...item, data: formatData };
          } else {
            return { ...item, data: tmpData };
          }
        });
        return deviceData;
      });
    } else {
      return [];
    }
  } else {
    return [];
  }
}
// 지하수위
export function getRsvGroundLevel(rsvRtus, term, fromDt, toDt) {
  if (Array.isArray(rsvRtus) && rsvRtus.length > 0) {
    const filterLevel = getRsvGroundSensor(rsvRtus);
    if (Array.isArray(filterLevel) && filterLevel.length > 0) {
      return getDataUpdate(term, fromDt, toDt, {
        dvcId: 8,
        // dvcId: 7,
      }).then((inData) => {
        return getDataUpdate(term, fromDt, toDt, {
          dvcId: 9,
          // dvcId: 7,
        }).then((outData) => {
          const deviceData = filterLevel.map((item, index) => {
            const tmpInData = [...inData];
            const tmpOutData = [...outData];
            if (index !== 0 && index !== 1) {
              if (item.eqId % 7 === 6) {
                // 내수 지하수위
                const formatData = tmpInData.map((dataItem) => {
                  if (dataItem.avgVal !== "-") {
                    return {
                      date: dataItem.date,
                      avgVal:
                        index % 2 === 0
                          ? parseFloat(
                              parseFloat(dataItem.avgVal) +
                                (index + 1) / 2 -
                                ((index + 1) / 2 / 10 + 0.03)
                            ).toFixed(2)
                          : parseFloat(
                              parseFloat(dataItem.avgVal) -
                                index / 2 +
                                (index / 2 / 10 - 0.03)
                            ).toFixed(2),
                    };
                  } else {
                    return dataItem;
                  }
                });
                return { ...item, data: formatData };
              } else {
                // 외수 지하수위
                const formatData = tmpOutData.map((dataItem) => {
                  if (dataItem.avgVal !== "-") {
                    return {
                      date: dataItem.date,
                      avgVal:
                        index % 2 === 0
                          ? parseFloat(
                              parseFloat(dataItem.avgVal) +
                                index -
                                (index / 10 + 0.03)
                            ).toFixed(2)
                          : parseFloat(
                              parseFloat(dataItem.avgVal) -
                                index +
                                (index / 10 - 0.03)
                            ).toFixed(2),
                    };
                  } else {
                    return dataItem;
                  }
                });
                return { ...item, data: formatData };
              }
            } else {
              return {
                ...item,
                data: index === 0 ? tmpInData : tmpOutData,
              };
            }
          });
          return deviceData;
        });
      });
    } else {
      return [];
    }
  } else {
    return [];
  }
}
// 지표변위
export function getRsvDisplacement(rsvRtus, term, fromDt, toDt) {
  if (Array.isArray(rsvRtus) && rsvRtus.length > 0) {
    const filterLevel = getRsvDisSensor(rsvRtus);
    if (Array.isArray(filterLevel) && filterLevel.length > 0) {
      return getDataUpdate(term, fromDt, toDt, { dvcId: 10 }).then((data) => {
        const deviceData = filterLevel.map((item, index) => {
          const tmpData = [...data];
          if (index !== 0) {
            const formatData = tmpData.map((dataItem) => {
              if (dataItem.avgVal !== "-") {
                return {
                  date: dataItem.date,
                  avgVal:
                    index % 2 === 0
                      ? parseFloat(
                          parseFloat(dataItem.avgVal) + (index / 10 + 0.03)
                        ).toFixed(2)
                      : parseFloat(
                          parseFloat(dataItem.avgVal) - (index / 10 - 0.23)
                        ).toFixed(2),
                };
              } else {
                return dataItem;
              }
            });
            return { ...item, data: formatData };
          } else {
            return { ...item, data: tmpData };
          }
        });
        return deviceData;
      });
    } else {
      return [];
    }
  } else {
    return [];
  }
}
// 강우
export function getRsvRain(rsvRtus, term, fromDt, toDt) {
  if (Array.isArray(rsvRtus) && rsvRtus.length > 0) {
    const filterLevel = getRsvRainSensor(rsvRtus);
    if (Array.isArray(filterLevel) && filterLevel.length > 0) {
      return getDataUpdate(term, fromDt, toDt, { dvcId: 6 }).then((data) => {
        const deviceData = filterLevel.map((item, index) => {
          const tmpData = [...data];
          if (index !== 0) {
            const formatData = tmpData.map((dataItem) => {
              if (dataItem.avgVal !== "-") {
                return {
                  date: dataItem.date,
                  avgVal:
                    index % 2 === 0
                      ? parseFloat(
                          parseFloat(dataItem.avgVal) +
                            index -
                            (index / 10 + 0.03)
                        ).toFixed(2)
                      : parseFloat(
                          parseFloat(dataItem.avgVal) -
                            index +
                            (index / 10 - 0.23)
                        ).toFixed(2),
                };
              } else {
                return dataItem;
              }
            });
            return { ...item, data: formatData };
          } else {
            return { ...item, data: tmpData };
          }
        });
        return deviceData;
      });
    } else {
      return [];
    }
  } else {
    return [];
  }
}

// 센서 통신 상태 조회
export function getSnsrStatus() {
  return axios.get(apiAddress.getStatus).then((response) => {
    const responseData = response.data.sensor_status;
    return responseData;
  });
}
