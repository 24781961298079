import React, { useState, useEffect } from "react";
import {
  createColumnHelper,
  flexRender,
  useReactTable,
  getCoreRowModel,
  getSortedRowModel,
} from "@tanstack/react-table";
import Modal from "react-modal";
import axios from "axios";

import apiAddress from "../../../../services/apiAddress";

Modal.setAppElement("#root");

const snsrTypes = [
  { value: "01", text: "강우량계" },
  { value: "02", text: "수위계" },
  { value: "03", text: "변위계" },
  { value: "04", text: "토양함수비" },
  { value: "05", text: "거리측정기" },
  { value: "06", text: "적설계" },
  { value: "07", text: "지하수위계" },
  { value: "08", text: "경사계" },
  { value: "09", text: "간극수압계" },
  { value: "10", text: "진동계/진동가속도계" },
  { value: "11", text: "지중경사계" },
  { value: "12", text: "하중계" },
  { value: "13", text: "구조물경사계" },
  { value: "14", text: "GNSS(GPS)" },
  { value: "15", text: "지표변위계" },
  { value: "16", text: "조위계" },
  { value: "17", text: "균열감지기" },
  { value: "18", text: "온도계" },
  { value: "19", text: "습도계" },
  { value: "20", text: "침수감지기" },
  { value: "21", text: "가속도계" },
  { value: "22", text: "변형률계" },
  { value: "23", text: "풍향계" },
  { value: "24", text: "유량계" },
  { value: "25", text: "유속계" },
];

function SensorEditSame({
  isOpen,
  setIsOpen,
  setIsAllOpen,
  snsrData,
  selectData,
  isOk,
  setIsOk,
}) {
  const [saveData, setSaveData] = useState([]);
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);
  const [settingValue, setSettingValue] = useState({});
  const columnHelper = createColumnHelper();
  const checkColumn = {
    id: "check",
    accessor: "check",
    header: (
      <input
        type="checkbox"
        onChange={onChangeAllCheck}
        checked={saveData.length - 1 === snsrData.length}
        style={{ cursor: "pointer" }}
      />
    ),
    enableSorting: false,
    size: 10,
    cell: ({ row }) => {
      return (
        <input
          type="checkbox"
          onChange={() => onChangeCheck(row.original)}
          checked={saveData.find((item) => item.dvcId === row.original.dvcId)}
          style={{ cursor: "pointer" }}
        />
      );
    },
  };
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  function onChangeAllCheck() {
    // 전체 체크 변환.
    if (saveData.length - 1 === snsrData.length) {
      // 전체 체크 => 체크 해제.
      setSaveData([{ ...selectData, modUsr: "SHMOD" }]);
    } else {
      if (saveData.length === 1) {
        // 전체 체크 해제 => 전체 체크.
        for (let i = 0; i < snsrData.length; i++) {
          setSaveData([
            ...saveData,
            { ...snsrData[i], ...settingValue, modUsr: "SHMOD" },
          ]);
        }
      } else {
        // 일부 체크 => 전체 체크.
        for (let i = 0; i < snsrData.length; i++) {
          if (!saveData.find((item) => item.dvcId === snsrData[i].dvcId)) {
            setSaveData([
              ...saveData,
              { ...snsrData[i], ...settingValue, modUsr: "SHMOD" },
            ]);
          }
        }
      }
    }
  }
  function onChangeCheck(row) {
    // 체크 변환.
    if (saveData.find((item) => item.dvcId === row.dvcId)) {
      // 체크 => 체크 해제.
      const newData = saveData.filter((item) => item.dvcId !== row.dvcId);
      setSaveData(newData);
    } else {
      // 체크 해제 => 체크.
      setSaveData([...saveData, { ...row, ...settingValue, modUsr: "SHMOD" }]);
    }
  }
  function onClickCancel() {
    // 취소.
    setIsOpen(false);
  }
  function onClickEdit() {
    // 수정.
    fetchEdit();
  }

  /* 센서 정보 수정 API */
  async function fetchEdit() {
    try {
      axios
        .post(apiAddress.groupEquipDeviceSave, saveData, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          setIsOk(!isOk);
          setIsOpen(false);
          setIsAllOpen({
            isOpen: false,
            data: {},
          });
        });
    } catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    const column = [
      checkColumn,
      columnHelper.accessor(
        (row) => {
          return row.info.dvcNm;
        },
        {
          header: "장비명",
          size: 110,
        }
      ),
      columnHelper.accessor(
        (row) => {
          return snsrTypes.find((type) => type.value === row.info.dvcDtlType)
            ?.text;
        },
        {
          header: "타입",
          size: 152.5,
        }
      ),
    ];

    setSettingValue({
      warningMin: selectData.warningMin,
      warningMin2: selectData.warningMin2,
      warningMin3: selectData.warningMin3,
      warningMax: selectData.warningMax,
      warningMax2: selectData.warningMax2,
      warningMax3: selectData.warningMax3,
      warningBrdCntnId: selectData.warningBrdCntnId,
      alertMin: selectData.alertMin,
      alertMin2: selectData.alertMin2,
      alertMin3: selectData.alertMin3,
      alertMax: selectData.alertMax,
      alertMax2: selectData.alertMax2,
      alertMax3: selectData.alertMax3,
      alertBrdCntnId: selectData.alertBrdCntnId,
      evctnMin: selectData.evctnMin,
      evctnMin2: selectData.evctnMin2,
      evctnMin3: selectData.evctnMin3,
      evctnMax: selectData.evctnMax,
      evctnMax2: selectData.evctnMax2,
      evctnMax3: selectData.evctnMax3,
      evctnBrdCntnId: selectData.evctnBrdCntnId,
    });
    setSaveData([{ ...selectData, modUsr: "SHMOD" }]);
    setData(snsrData);
    setColumns(column);
  }, []);
  useEffect(() => {
    const column = [
      checkColumn,
      columnHelper.accessor(
        (row) => {
          return row.info.dvcNm;
        },
        {
          header: "장비명",
          size: 110,
        }
      ),
      columnHelper.accessor(
        (row) => {
          return snsrTypes.find((type) => type.value === row.info.dvcDtlType)
            ?.text;
        },
        {
          header: "타입",
          size: 152.5,
        }
      ),
    ];

    setColumns(column);
  }, [saveData]);

  return (
    <Modal isOpen={isOpen} style={modalStyles}>
      <div className="modal_container" style={{ height: "calc(100% - 40px)" }}>
        {/* Header */}
        <div className="same_header">
          <div className="title">동일 타입 센서 저장</div>
          <div className="sub_title">
            <div>선택하신 센서와 같은 타입의 센서가 존재합니다. </div>
            <div>
              입력하신 정보로 수정할 센서가 있다면 아래 목록에서 선택해 주세요.
            </div>
          </div>
        </div>

        {/* Main */}
        <div
          className="modal_main"
          style={{ marginTop: "20px", overflowX: "hidden", overflowY: "auto" }}
        >
          <table className="react_table">
            <thead>
              {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <th
                      key={header.id}
                      style={{
                        width: isNaN(header.getSize())
                          ? "auto"
                          : `${header.getSize()}px`,
                        minWidth: isNaN(header.getSize())
                          ? "auto"
                          : `${header.getSize()}px`,
                        maxWidth: isNaN(header.getSize())
                          ? "auto"
                          : `${header.getSize()}px`,
                      }}
                    >
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody>
              {table.getRowModel().rows.map((row, index) => (
                <React.Fragment key={row.id}>
                  <tr
                    key={row.id}
                    className={index % 2 === 0 ? "tr_even" : "tr_odd"}
                  >
                    {row.getVisibleCells().map((cell) => (
                      <td
                        key={cell.id}
                        onClick={(e) => {
                          if (cell.column.id === "check") {
                            e.stopPropagation();
                          }
                        }}
                        style={{
                          width: isNaN(cell.column.getSize())
                            ? "auto"
                            : `${cell.column.getSize()}px`,
                          minWidth: isNaN(cell.column.getSize())
                            ? "auto"
                            : `${cell.column.getSize()}px`,
                          maxWidth: isNaN(cell.column.getSize())
                            ? "auto"
                            : `${cell.column.getSize()}px`,
                          textAlign: cell.column.id === "check" && "center",
                        }}
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </td>
                    ))}
                  </tr>
                </React.Fragment>
              ))}
            </tbody>
          </table>
        </div>

        {/* Footer */}
        <div className="modal_footer">
          <div className="button_nor" onClick={onClickCancel}>
            취소
          </div>
          <div
            className="button_imp"
            onClick={onClickEdit}
            style={{ marginLeft: "10px" }}
          >
            확인
          </div>
        </div>
      </div>
    </Modal>
  );
}

const modalStyles = {
  content: {
    width: "470px",
    height: "500px",
    padding: "0px",
    background: "#202020",
    border: "none",
    borderRadius: "5px",
    boxShadow: "-5px 5px 10px rgba(0, 0, 0, 0.7)",
    margin: "auto",
  },
  overlay: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "fixed",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: "rgba(0, 0, 0, 0.0)",
  },
};

export default SensorEditSame;
