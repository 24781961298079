import apiAddress from "../../services/apiAddress";
import axios from "axios";

import { location } from "../../constants/latlon";
import { getCurrTime, zeroPlusNum } from "../../utils/time";

function stringToArray(str) {
  // 문자열의 대괄호 제거 및 쉼표로 나누기
  return str.slice(1, -1).split(",");
}

// 현재날씨 데이터 추출
export function getCurrWeatherInform() {
  // currWeahter: 초단기실황, currWeatherFore: 초단기예보
  return getCurrWeather().then((currWeather) => {
    if (
      currWeather !== undefined &&
      Array.isArray(currWeather) &&
      currWeather.length !== 0
    ) {
      return getCurrWeatherForecast().then((currWeatherFore) => {
        if (
          currWeatherFore !== undefined &&
          Array.isArray(currWeatherFore) &&
          currWeatherFore.length !== 0
        ) {
          const currFore = currWeatherFore.filter(
            (item) => item.category === "SKY"
          );
          return [...currWeather, currFore[0]];
        } else {
          return [];
        }
      });
    } else {
      return [];
    }
  });
}

// 기상예보 데이터 추출
export function getForecastInform() {
  return getForecast().then((forecast) => {
    if (
      forecast !== undefined &&
      Array.isArray(forecast) &&
      forecast.length !== 0
    ) {
      const fore1Hour =
        getCurrTime().hour + 1 > 23
          ? zeroPlusNum(getCurrTime().hour + 1 - 24)
          : zeroPlusNum(getCurrTime().hour + 1);
      const fore2Hour =
        getCurrTime().hour + 2 > 23
          ? zeroPlusNum(getCurrTime().hour + 2 - 24)
          : zeroPlusNum(getCurrTime().hour + 2);
      const fore3Hour =
        getCurrTime().hour + 3 > 23
          ? zeroPlusNum(getCurrTime().hour + 3 - 24)
          : zeroPlusNum(getCurrTime().hour + 3);

      const fore1 = forecast.filter(
        (item) => item.fcstTime === fore1Hour + "00"
      );
      const fore2 = forecast.filter(
        (item) => item.fcstTime === fore2Hour + "00"
      );
      const fore3 = forecast.filter(
        (item) => item.fcstTime === fore3Hour + "00"
      );

      return [
        { time: fore1Hour, data: fore1 },
        { time: fore2Hour, data: fore2 },
        { time: fore3Hour, data: fore3 },
      ];
    } else {
      return [];
    }
  });
}

// 초단기실황
export function getCurrWeather() {
  return axios
    .get(apiAddress.curWeather, {
      params: {
        regionCode: location.regionCode,
      },
    })
    .then((response) => {
      if (response.data.response.body.items.item) {
        const inform = response.data.response.body.items.item;
        return inform;
      }
    })
    .catch((err) => {
      console.log(err);
    });
}

// 초단기예보
export function getCurrWeatherForecast() {
  return axios
    .get(apiAddress.curWeatherFore, {
      params: {
        regionCode: location.regionCode,
      },
    })
    .then((response) => {
      if (response.data.response.body.items.item) {
        const inform = response.data.response.body.items.item;
        return inform;
      }
    })
    .catch((err) => {
      console.log(err);
    });
}

// 단기예보
export function getForecast() {
  return axios
    .get(apiAddress.forecast, {
      params: {
        regionCode: location.regionCode,
      },
    })
    .then((response) => {
      if (response.data.response.body.items.item) {
        const inform = response.data.response.body.items.item;
        return inform;
      }
    })
    .catch((err) => {
      console.log(err);
    });
}

// 위성 영상(이미지)
export function getSatellite() {
  return axios
    .get(apiAddress.satellite, {
      params: {
        regionCode: location.regionCode,
      },
    })
    .then((response) => {
      if (response.data.response.body) {
        const imgList =
          response.data.response.body.items.item[0]["satImgC-file"];
        const imgListArr = stringToArray(imgList[0]);
        const imgUrl = imgListArr[imgListArr.length - 1];
        return imgUrl;
      } else {
        if (
          response.data.response.header.resultMsg.includes(
            "해당 자료가 존재하지 않습니다"
          )
        ) {
          return "";
        }
      }
    })
    .catch((err) => {
      console.log(err);
    });
}

// 레이더 영상(이미지)
export function getRadar() {
  return axios
    .get(apiAddress.radar, {
      params: {
        regionCode: location.regionCode,
      },
    })
    .then((response) => {
      if (response.data.response.body) {
        const imgList =
          response.data.response.body.items.item[0]["rdr-img-file"];
        const imgListArr = stringToArray(imgList[0]);
        const imgUrl = imgListArr[imgListArr.length - 1];
        return imgUrl;
      } else {
        if (
          response.data.response.header.resultMsg.includes(
            "해당 자료가 존재하지 않습니다"
          )
        ) {
          return "";
        }
      }
    })
    .catch((err) => {
      console.log(err);
    });
}

// 기상특보문
export function getWeatherAlert() {
  return axios
    .get(apiAddress.weatherAlert, {
      params: {
        regionCode: location.regionCode,
      },
    })
    .then((response) => {
      if (
        response.data.response.body !== undefined &&
        Array.isArray(response.data.response.body.items.item) &&
        response.data.response.body.items.item.length > 0
      ) {
        return response.data.response.body.items.item;
      } else {
        return [];
      }
    })
    .catch((err) => {
      console.log(err);
    });
}
