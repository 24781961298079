import { useState, useEffect } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faArrowRight,
  faBuilding,
  faCheck,
  faEnvelope,
  faIdCard,
  faLock,
  faMobile,
  faPeopleGroup,
  faPerson,
  faPhone,
  faStar,
  faUser,
} from "@fortawesome/free-solid-svg-icons";

import { checkDuplicate, authCellNum } from "../../services/internal/signAPI";
import { zeroPlusNum } from "../../utils/time";
import Alert from "../common/window/Alert";

function Personal({
  setPage,
  siteId,
  isDuplicate,
  setIsDuplicate,
  duplicateText,
  setDuplicateText,
  isPwdSame,
  setIsPwdSame,
  pwdSameUser,
  setPwdSameUser,
  pwdSameText,
  setPwdSameText,
  isAuthCell,
  setIsAuthCell,
  cellAuthUser,
  setCellAuthUser,
  authCellText,
  setAuthCellText,
  signInform,
  setSignInform,
}) {
  const [isInputAlert, setIsInputAlert] = useState(false);
  const [isDuplicateAlert, setIsDuplicateAlert] = useState(false);
  const [isPwdSameAlert, setIsPwdSameAlert] = useState(false);
  const [isAuthCellAlert, setIsAuthCellAlert] = useState(false);
  const [cellAuthTime, setCellAuthTime] = useState({
    minute: 3,
    second: 0,
  });
  const [cellAuth, setCellAuth] = useState("");

  function onClickDuplicate() {
    // 아이디 중복 검사
    // 중복체크 버튼 클릭
    if (signInform.userId !== "") {
      checkDuplicate(signInform.userId).then((result) => {
        if (result) {
          // 중복된 아이디 존재
          setDuplicateText("이미 사용중인 아이디입니다");
        } else {
          // 중복된 아이디 없음
          setDuplicateText("사용가능한 아이디입니다");
          setIsDuplicate(true);
        }
      });
    }
  }
  function onClickAuth() {
    // 휴대폰 인증, 인증 버튼 클릭
    if (signInform.cellNum !== "") {
      setIsAuthCell("send");
      authCellNum(signInform.cellNum).then((response) => {
        setCellAuth(response);
      });
    }
  }
  function onClickAuthCheck() {
    // 인증확인 버튼 클릭
    if (cellAuthUser !== "") {
      if (cellAuth.toString() === cellAuthUser.toString()) {
        setIsAuthCell(true);
        setAuthCellText("휴대폰 인증이 완료되었습니다");
      } else {
        setAuthCellText("인증번호가 일치하지 않습니다");
      }
    }
  }
  function onClickNextPage() {
    // 다음 페이지 클릭
    if (
      signInform.userId === "" ||
      signInform.userPwd.length < 8 ||
      pwdSameUser === "" ||
      signInform.userNm === "" ||
      signInform.cellNum === "" ||
      signInform.adtnl1 === ""
    ) {
      setIsInputAlert(true);
    } else {
      if (isDuplicate) {
        if (isPwdSame === true) {
          if (isAuthCell === true) {
            setPage(3);
          } else {
            setIsAuthCellAlert(true);
          }
        } else {
          setIsPwdSameAlert(true);
        }
      } else {
        setIsDuplicateAlert(true);
      }
    }
  }

  useEffect(() => {
    if (isAuthCell === "send") {
      const intervalId = setInterval(() => {
        let minute, second;
        if (cellAuthTime.second - 1 < 0) {
          minute = cellAuthTime.minute - 1;
          second = 59;
        } else {
          minute = cellAuthTime.minute;
          second = cellAuthTime.second - 1;
        }

        if (minute === 0 && second === 0) {
          // 인증번호 유효시간 종료
          setIsAuthCell(false);
          setCellAuthTime({
            minute: 3,
            second: 0,
          });
          setCellAuthUser("");
          setCellAuth("");
          setAuthCellText("");
        } else {
          setCellAuthTime({
            minute: minute,
            second: second,
          });
        }
      }, 1000);

      return () => clearInterval(intervalId);
    }
  }, [isAuthCell, cellAuthTime]);

  return (
    <div id="sign-personal-component">
      {
        // 필수 입력 사항 X
        isInputAlert && (
          <Alert
            isOpen={isInputAlert}
            setIsOpen={setIsInputAlert}
            text={"필수 정보를 입력해주세요"}
          />
        )
      }
      {
        // 중복 체크 안하고 계정등록 신청
        isDuplicateAlert && (
          <Alert
            isOpen={isDuplicateAlert}
            setIsOpen={setIsDuplicateAlert}
            text={"아이디 중복 체크를 해주세요"}
          />
        )
      }
      {
        // 비밀번호 일치x
        isPwdSameAlert && (
          <Alert
            isOpen={isPwdSameAlert}
            setIsOpen={setIsPwdSameAlert}
            text={"비밀번호가 일치하지 않습니다"}
          />
        )
      }
      {
        // 휴대폰 인증 안하고 계정등록 신청
        isAuthCellAlert && (
          <Alert
            isOpen={isAuthCellAlert}
            setIsOpen={setIsAuthCellAlert}
            text={"휴대폰 인증을 해주세요"}
          />
        )
      }

      <div className="component-title">
        <div>개인 정보</div>
        <div className="required-text">
          <div className="required">*</div>
          <div className="text">은 필수 입력 사항입니다</div>
        </div>
      </div>

      <div className="component-main personal-container">
        <form>
          <div className="typing-container first">
            <div className="siteId-typing-item">
              <div className="title-container">
                <div className="title">
                  <FontAwesomeIcon className="icon" icon={faStar} />
                  <div className="text">고객사ID</div>
                  <div className="required">*</div>
                </div>
              </div>
              <div className="typing">
                <select
                  value={signInform.siteId}
                  onChange={(e) => {
                    setSignInform({ ...signInform, siteId: e.target.value });
                  }}
                >
                  {siteId.length !== 0 &&
                    siteId.map((siteIdItem) => (
                      <option
                        key={siteIdItem.commonCode}
                        value={siteIdItem.commonCode}
                      >
                        {siteIdItem.commonRef5}({siteIdItem.commonRef6})
                      </option>
                    ))}
                </select>
              </div>
            </div>
          </div>
          <div className="typing-container">
            <div className="typing-item left">
              <div className="title-container">
                <div className="title">
                  <FontAwesomeIcon className="icon" icon={faIdCard} />
                  <div className="text">아이디</div>
                  <div className="required">*</div>
                </div>
                <div
                  className="duplicate"
                  style={{ color: isDuplicate ? "#13e700" : "#ff4040" }}
                >
                  {duplicateText}
                </div>
              </div>
              <div className="typing">
                <input
                  className="id-input"
                  type="text"
                  placeholder="아이디 입력"
                  value={signInform.userId}
                  onChange={(e) => {
                    const newValue = e.target.value.replace(/\s/g, "");
                    setSignInform({ ...signInform, userId: newValue });
                    setIsDuplicate(false);
                    setDuplicateText("");
                  }}
                  autoComplete="username"
                />
                <div className="duplicate-container">
                  <div
                    className="duplicate-button button-imp"
                    onClick={onClickDuplicate}
                  >
                    중복체크
                  </div>
                  {isDuplicate && (
                    <div className="duplicate-check">
                      <FontAwesomeIcon icon={faCheck} />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="typing-container">
            <div className="typing-item left">
              <div className="title-container">
                <div className="title">
                  <FontAwesomeIcon className="icon" icon={faLock} />
                  <div className="text">비밀번호</div>
                  <div className="required">*</div>
                </div>
                {signInform.userPwd !== "" && signInform.userPwd.length < 8 && (
                  <div className="pwd">8자리 이상 입력해주세요</div>
                )}
              </div>
              <div className="typing">
                <input
                  type="password"
                  placeholder="비밀번호 입력 (8자리 이상)"
                  value={signInform.userPwd}
                  onChange={(e) => {
                    const newValue = e.target.value.replace(/\s/g, "");
                    setSignInform({ ...signInform, userPwd: newValue });

                    setPwdSameUser("");
                    setPwdSameText("");
                    setIsPwdSame(false);
                  }}
                  autoComplete="new-password"
                />
              </div>
            </div>
            <div className="typing-item right">
              <div className="title-container">
                <div className="title">
                  <FontAwesomeIcon className="icon" icon={faLock} />
                  <div className="text">비밀번호 확인</div>
                  <div className="required">*</div>
                </div>
                <div
                  className="pwd-same"
                  style={{
                    color: isPwdSame === true ? "#13e700" : "#ff4040",
                  }}
                >
                  {pwdSameText}
                </div>
              </div>
              <div className="typing">
                <input
                  type="password"
                  placeholder="비밀번호 확인 입력"
                  value={pwdSameUser}
                  onChange={(e) => {
                    const newValue = e.target.value.replace(/\s/g, "");
                    setPwdSameUser(newValue);

                    if (e.target.value !== "") {
                      if (e.target.value === signInform.userPwd) {
                        setIsPwdSame(true);
                        setPwdSameText("비밀번호가 일치합니다");
                      } else {
                        setIsPwdSame(false);
                        setPwdSameText("비밀번호가 일치하지 않습니다");
                      }
                    } else {
                      setIsPwdSame(false);
                      setPwdSameText("");
                    }
                  }}
                  autoComplete="new-password"
                  disabled={signInform.userPwd.length >= 8 ? false : true}
                />
              </div>
            </div>
          </div>
          <div className="typing-container">
            <div className="typing-item">
              <div className="title-container">
                <div className="title">
                  <FontAwesomeIcon className="icon" icon={faUser} />
                  <div className="text">이름</div>
                  <div className="required">*</div>
                </div>
              </div>
              <div className="typing">
                <input
                  type="text"
                  placeholder="이름 입력"
                  value={signInform.userNm}
                  onChange={(e) => {
                    const newValue = e.target.value.replace(/\s/g, "");
                    setSignInform({ ...signInform, userNm: newValue });
                  }}
                  autoComplete="name"
                />
              </div>
            </div>
          </div>
          <div className="typing-container">
            <div className="typing-item left">
              <div className="title-container">
                <div className="title">
                  <FontAwesomeIcon className="icon" icon={faMobile} />
                  <div className="text">휴대번호</div>
                  <div className="required">*</div>
                </div>
              </div>
              <div className="typing">
                <input
                  className="cell-input"
                  type="number"
                  placeholder="휴대번호 입력 (숫자만 입력)"
                  value={signInform.cellNum}
                  onChange={(e) => {
                    setSignInform({ ...signInform, cellNum: e.target.value });
                    setIsAuthCell(false);
                    setCellAuthTime({
                      minute: 3,
                      second: 0,
                    });
                    setCellAuthUser("");
                    setCellAuth("");
                    setAuthCellText("");
                  }}
                />
                <div className="auth-container">
                  <div className="auth-button button-imp" onClick={onClickAuth}>
                    인증
                  </div>
                  {isAuthCell === "send" && (
                    <div className="auth-time-container">
                      <div className="auth-time">
                        {zeroPlusNum(cellAuthTime.minute)} :
                        {zeroPlusNum(cellAuthTime.second)}
                      </div>
                    </div>
                  )}
                  {isAuthCell === true && (
                    <div className="auth-check">
                      <FontAwesomeIcon icon={faCheck} />
                    </div>
                  )}
                </div>
              </div>
            </div>
            {isAuthCell !== false && (
              <div className="typing-item right">
                <div className="title-container">
                  <div className="title">
                    <FontAwesomeIcon className="icon" icon={faMobile} />
                    <div className="text">인증번호</div>
                    <div className="required">*</div>
                  </div>
                  <div
                    className="cell-auth"
                    style={{
                      color: isAuthCell === true ? "#13e700" : "#ff4040",
                    }}
                  >
                    {authCellText}
                  </div>
                </div>
                <div className="typing">
                  <input
                    className="cell-input"
                    type="number"
                    placeholder="인증번호 입력"
                    value={cellAuthUser}
                    onChange={(e) => {
                      setCellAuthUser(e.target.value);
                    }}
                    disabled={isAuthCell === true ? true : false}
                  />
                  <div className="auth-check-container">
                    <div
                      className="auth-check-button button-imp"
                      onClick={onClickAuthCheck}
                    >
                      인증확인
                    </div>
                    {isAuthCell === true && (
                      <div className="auth-check">
                        <FontAwesomeIcon icon={faCheck} />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="typing-container">
            <div className="typing-item left">
              <div className="title-container">
                <div className="title">
                  <FontAwesomeIcon className="icon" icon={faPhone} />
                  <div className="text">전화번호</div>
                </div>
              </div>
              <div className="typing">
                <input
                  type="number"
                  placeholder="전화번호 입력 (숫자만 입력)"
                  value={signInform.phoneNum}
                  onChange={(e) =>
                    setSignInform({ ...signInform, phoneNum: e.target.value })
                  }
                />
              </div>
            </div>
            <div className="typing-item right">
              <div className="title-container">
                <div className="title">
                  <FontAwesomeIcon className="icon" icon={faEnvelope} />
                  <div className="text">E-mail</div>
                </div>
              </div>
              <div className="typing">
                <input
                  type="email"
                  placeholder="Email 입력"
                  value={signInform.email}
                  onChange={(e) => {
                    const newValue = e.target.value.replace(/\s/g, "");
                    setSignInform({ ...signInform, email: newValue });
                  }}
                />
              </div>
            </div>
          </div>
          <div className="typing-container">
            <div className="typing-item">
              <div className="title-container">
                <div className="title">
                  <FontAwesomeIcon className="icon" icon={faBuilding} />
                  <div className="text">소속</div>
                  <div className="required">*</div>
                </div>
              </div>
              <div className="typing">
                <input
                  type="text"
                  placeholder="소속 입력"
                  value={signInform.adtnl1}
                  onChange={(e) =>
                    setSignInform({ ...signInform, adtnl1: e.target.value })
                  }
                />
              </div>
            </div>
          </div>
          <div className="typing-container">
            <div className="typing-item left">
              <div className="title-container">
                <div className="title">
                  <FontAwesomeIcon className="icon" icon={faPeopleGroup} />
                  <div className="text">부서</div>
                </div>
              </div>
              <div className="typing">
                <input
                  type="text"
                  placeholder="부서 입력"
                  value={signInform.deptNm}
                  onChange={(e) =>
                    setSignInform({ ...signInform, deptNm: e.target.value })
                  }
                />
              </div>
            </div>
            <div className="typing-item right">
              <div className="title-container">
                <div className="title">
                  <FontAwesomeIcon className="icon" icon={faPerson} />
                  <div className="text">직급</div>
                </div>
              </div>
              <div className="typing">
                <input
                  type="text"
                  placeholder="직급 입력"
                  value={signInform.positionNm}
                  onChange={(e) =>
                    setSignInform({
                      ...signInform,
                      positionNm: e.target.value,
                    })
                  }
                />
              </div>
            </div>
          </div>
        </form>
      </div>

      <div className="button-container">
        <div className="button" onClick={() => setPage(1)}>
          <FontAwesomeIcon className="left-arrow" icon={faArrowLeft} />
          <div className="text">이전</div>
        </div>
        <div className="button" onClick={onClickNextPage}>
          <div className="text">다음</div>
          <FontAwesomeIcon className="right-arrow" icon={faArrowRight} />
        </div>
      </div>
    </div>
  );
}

export default Personal;
