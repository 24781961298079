import { useState, useEffect } from "react";
import {
  createColumnHelper,
  flexRender,
  useReactTable,
  getCoreRowModel,
} from "@tanstack/react-table";

import { dateTimeToFormat } from "../../../utils/dateFormat";

function SMSList({ smsHistory }) {
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);
  const columnHelper = createColumnHelper();
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });
  const [selected, setSelected] = useState({});
  const [selectedData, setSelectedData] = useState([]);
  const [selectedColumns, setSelectedColumns] = useState([]);
  const selectedTable = useReactTable({
    data: selectedData,
    columns: selectedColumns,
    getCoreRowModel: getCoreRowModel(),
  });

  useEffect(() => {
    if (smsHistory !== undefined) {
      const column = [
        columnHelper.accessor(
          (row) => {
            return dateTimeToFormat(row.sendDt);
          },
          { id: "sendDt", header: "발송일자" }
        ),
        columnHelper.accessor("sendText", { header: "내용", size: 450 }),
        columnHelper.accessor("smsState", { header: "결과" }),
      ];

      if (smsHistory.length !== 0) {
        setSelected(smsHistory[0]);
        setSelectedData(smsHistory[0].detail);
      } else {
        setSelected({});
        setSelectedData([]);
      }

      setData(smsHistory);
      setColumns(column);
    }
  }, [smsHistory]);
  useEffect(() => {
    const column = [
      columnHelper.accessor("receiver", { header: "수신자" }),
      columnHelper.accessor(
        (row) => {
          if (row.sms_state === "전송중") {
            return "전송요청완료";
          } else {
            return row.sms_state;
          }
        },
        { id: "sms_state", header: "발송결과" }
      ),
    ];

    setSelectedData(selected.detail);
    setSelectedColumns(column);
  }, [selected]);

  return (
    <div id="sms-trans-history-list-component">
      {data.length === 0 ? (
        <div className="none-data-container">
          금일 SMS 내역 조회 결과가 없습니다
        </div>
      ) : (
        <>
          {/* 내역 리스트 */}
          <div className="sms-trans-history-list-container">
            <div className="sms-trans-history-list-title">
              <div>내</div>
              <div>역</div>
            </div>
            <div className="sms-trans-history-list-table">
              <div className="list">
                <table className="react_table">
                  <thead>
                    {table.getHeaderGroups().map((headerGroup) => (
                      <tr key={headerGroup.id}>
                        {headerGroup.headers.map((header) => (
                          <th
                            key={header.id}
                            style={{
                              width: isNaN(header.getSize())
                                ? "auto"
                                : `${header.getSize()}px`,
                            }}
                          >
                            {header.isPlaceholder
                              ? null
                              : flexRender(
                                  header.column.columnDef.header,
                                  header.getContext()
                                )}
                          </th>
                        ))}
                      </tr>
                    ))}
                  </thead>
                  <tbody>
                    {table.getRowModel().rows.map((row, index) => (
                      <tr
                        key={row.id}
                        onClick={() => setSelected(row.original)}
                        className={
                          selected === row.original
                            ? "tr_select"
                            : index % 2 === 0
                            ? "tr_even"
                            : "tr_odd"
                        }
                      >
                        {row.getVisibleCells().map((cell) => (
                          <td
                            key={cell.id}
                            className={cell.column.id === "msg" ? "td-msg" : ""}
                            style={{
                              width: isNaN(cell.column.getSize())
                                ? "auto"
                                : `${cell.column.getSize()}px`,
                              minWidth: isNaN(cell.column.getSize())
                                ? "auto"
                                : `${cell.column.getSize()}px`,
                              maxWidth: isNaN(cell.column.getSize())
                                ? "auto"
                                : `${cell.column.getSize()}px`,
                              textAlign:
                                (cell.column.id === "sendDt" ||
                                  cell.column.id === "smsState") &&
                                "center",
                              color:
                                cell.column.id === "smsState" &&
                                row.original.smsState.includes("실패")
                                  ? "#F32C2C"
                                  : cell.column.id === "smsState" &&
                                    row.original.smsState.includes(
                                      "전송요청완료"
                                    ) &&
                                    "#D9A205",
                            }}
                          >
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext()
                            )}
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          {/* 상세 내역 */}
          <div className="sms-trans-history-detail-container">
            <div className="sms-trans-history-detail-title">
              <div>상</div>
              <div>세</div>
              <div>정</div>
              <div>보</div>
            </div>
            <div className="sms-trans-history-detail-contents">
              <div className="time-container">
                <div className="time-title">발송일자</div>
                <div className="time-contents">
                  {dateTimeToFormat(selected.sendDt)}
                </div>
              </div>
              <div className="msg-receiver-container">
                <div className="msg-receiver-msg">
                  <div className="msg-title">메시지</div>
                  <textarea
                    type="text"
                    value={selected.sendText}
                    disabled={true}
                  />
                </div>
                <div className="msg-receiver-list">
                  <div className="list-title">수신자 목록</div>
                  <div className="list-table">
                    <table className="react_table">
                      <thead>
                        {selectedTable.getHeaderGroups().map((headerGroup) => (
                          <tr key={headerGroup.id}>
                            {headerGroup.headers.map((header) => (
                              <th
                                key={header.id}
                                style={{
                                  width: isNaN(header.getSize())
                                    ? "auto"
                                    : `${header.getSize()}px`,
                                }}
                              >
                                {header.isPlaceholder
                                  ? null
                                  : flexRender(
                                      header.column.columnDef.header,
                                      header.getContext()
                                    )}
                              </th>
                            ))}
                          </tr>
                        ))}
                      </thead>
                      <tbody>
                        {selectedTable.getRowModel().rows.map((row, index) => (
                          <tr
                            key={row.id}
                            className={
                              index % 2 === 0
                                ? "tr-even-receiver"
                                : "tr-odd-receiver"
                            }
                          >
                            {row.getVisibleCells().map((cell) => (
                              <td
                                key={cell.id}
                                className={
                                  cell.column.id === "msg" ? "td-msg" : ""
                                }
                                style={{
                                  width: isNaN(cell.column.getSize())
                                    ? "auto"
                                    : `${cell.column.getSize()}px`,
                                  minWidth: isNaN(cell.column.getSize())
                                    ? "auto"
                                    : `${cell.column.getSize()}px`,
                                  maxWidth: isNaN(cell.column.getSize())
                                    ? "auto"
                                    : `${cell.column.getSize()}px`,
                                  textAlign:
                                    cell.column.id === "sms_state" && "center",
                                  color:
                                    cell.column.id === "sms_state" &&
                                    row.original.sms_state.includes("발송완료")
                                      ? "inherit"
                                      : cell.column.id === "sms_state" &&
                                        row.original.sms_state.includes(
                                          "전송중"
                                        )
                                      ? "#D9A205"
                                      : cell.column.id === "sms_state" &&
                                        "#F32C2C",
                                }}
                              >
                                {flexRender(
                                  cell.column.columnDef.cell,
                                  cell.getContext()
                                )}
                              </td>
                            ))}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default SMSList;
