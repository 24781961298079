import React, { useState, useEffect } from "react";
import {
  createColumnHelper,
  flexRender,
  useReactTable,
  getCoreRowModel,
  getSortedRowModel,
} from "@tanstack/react-table";

const brdTypes = [
  { value: "01", text: "비상예경보방송기기" },
  { value: "02", text: "마을방송" },
];

function Broadcast({ groupId, eqId, brdData, regData, setRegData }) {
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);
  const columnHelper = createColumnHelper();
  const checkColumn = {
    id: "check",
    accessor: "check",
    header: "",
    enableSorting: false,
    size: 10,
    cell: ({ row }) => {
      return (
        <input
          type="checkbox"
          onChange={() => onChangeCheck(row.original)}
          checked={regData.find((item) => item.dvcId === row.original.dvcId)}
          style={{ cursor: "pointer" }}
        />
      );
    },
  };
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  function onChangeCheck(row) {
    if (regData.find((item) => item.dvcId === row.dvcId)) {
      // 체크 => 체크 해제.
      const newData = regData.filter((item) => item.dvcId !== row.dvcId);
      setRegData(newData);
    } else {
      // 체크 해제 => 체크.
      setRegData([
        ...regData,
        {
          groupId: groupId,
          eqId: eqId,
          dvcId: row.dvcId,
          useYn: "Y",
          regUsr: "SH",
        },
      ]);
    }
  }

  useEffect(() => {
    const column = [
      checkColumn,
      columnHelper.accessor("dvcNm", {
        header: "장비명",
        size: 110,
      }),
      columnHelper.accessor(
        (row) => {
          return brdTypes.find((type) => type.value === row.dvcDtlType)?.text;
        },
        {
          header: "타입",
          size: 176,
        }
      ),
    ];

    setData(brdData);
    setColumns(column);
  }, [brdData]);
  useEffect(() => {
    const column = [
      checkColumn,
      columnHelper.accessor("dvcNm", {
        header: "장비명",
        size: 110,
      }),
      columnHelper.accessor(
        (row) => {
          return brdTypes.find((type) => type.value === row.dvcDtlType)?.text;
        },
        {
          header: "타입",
          size: 176,
        }
      ),
    ];

    setColumns(column);
  }, [regData]);

  return (
    <>
      <div className="setting_alert_header">
        <div>방송장비</div>
      </div>
      <div className="setting_alert_main_setting_brd">
        {data.length > 0 ? (
          <table className="react_table">
            <thead>
              {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <th
                      key={header.id}
                      style={{
                        width: isNaN(header.getSize())
                          ? "auto"
                          : `${header.getSize()}px`,
                        minWidth: isNaN(header.getSize())
                          ? "auto"
                          : `${header.getSize()}px`,
                        maxWidth: isNaN(header.getSize())
                          ? "auto"
                          : `${header.getSize()}px`,
                      }}
                    >
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody>
              {table.getRowModel().rows.map((row, index) => (
                <React.Fragment key={row.id}>
                  <tr key={row.id}>
                    {row.getVisibleCells().map((cell) => (
                      <td
                        key={cell.id}
                        onClick={(e) => {
                          if (cell.column.id === "select") {
                            e.stopPropagation();
                          }
                        }}
                        style={{
                          width: isNaN(cell.column.getSize())
                            ? "auto"
                            : `${cell.column.getSize()}px`,
                          minWidth: isNaN(cell.column.getSize())
                            ? "auto"
                            : `${cell.column.getSize()}px`,
                          maxWidth: isNaN(cell.column.getSize())
                            ? "auto"
                            : `${cell.column.getSize()}px`,
                          textAlign: cell.column.id === "check" && "center",
                        }}
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </td>
                    ))}
                  </tr>
                </React.Fragment>
              ))}
            </tbody>
          </table>
        ) : (
          <div className="setting_data_none_container">
            <div>등록할 방송장비가 없습니다</div>
          </div>
        )}
      </div>
    </>
  );
}

export default Broadcast;
