import { useState, useEffect } from "react";
import Modal from "react-modal";

import { MODALSTYLES } from "../../../constants/modalStyles";
import Graph from "./Graph";
import Table from "./Table";

Modal.setAppElement("#root");

function SnsrData({ selected, setSelected }) {
  const [type, setType] = useState("graph");
  const [inform, setInform] = useState({});

  /* 닫기 버튼 클릭 시 */
  function onClickClose() {
    setSelected({});
  }

  useEffect(() => {
    if (Object.keys(selected).length > 0) {
      setInform(selected);
    } else {
      setInform({});
    }
  }, [selected]);

  return (
    <Modal
      isOpen={Object.keys(selected).length > 0}
      style={MODALSTYLES.snsrData}
    >
      {Object.keys(selected).length > 0 && (
        <div className="allstatus-map-snsr-data-container">
          <div className="modal_title">
            <div>센서 데이터 현황</div>
            <div>{inform.eqNm}</div>
          </div>

          <div className="modal_container">
            <div className="allstatus-map-snsr-data-select-container">
              {/* 탭 선택 */}
              <div className="tab-container">
                <div
                  className={
                    type === "graph"
                      ? "select-item-select right"
                      : "select-item right"
                  }
                  onClick={() => setType("graph")}
                >
                  그래프
                </div>
                <div
                  className={
                    type === "table" ? "select-item-select" : "select-item"
                  }
                  onClick={() => setType("table")}
                >
                  테이블
                </div>
              </div>
            </div>

            <div className="modal_main">
              {type === "graph" ? (
                <Graph selected={selected} />
              ) : (
                type === "table" && <Table selected={selected} />
              )}
            </div>

            <div className="modal_footer">
              <div className="button-nor-modal" onClick={onClickClose}>
                닫기
              </div>
            </div>
          </div>
        </div>
      )}
    </Modal>
  );
}

export default SnsrData;
