import { useState, useEffect } from "react";

import { getEdboardMessageLog } from "../../services/internal/edboardAPI";
import EdboardList from "./edboard/EdboardList";
import MessageInform from "./edboard/MessageInform";

function EdboardCurr() {
  const [edbData, setEdbData] = useState([]);
  const [selectedEdb, setSelectedEdb] = useState({});

  useEffect(() => {
    getEdboardMessageLog().then((response) => {
      if (Array.isArray(response) && response.length > 0) {
        setEdbData(response);
        setSelectedEdb(response[0]);
      } else {
        setEdbData([]);
        setSelectedEdb({});
      }
      setEdbData(response);
    });

    const intervalId = setInterval(() => {
      getEdboardMessageLog().then((response) => {
        if (Array.isArray(response) && response.length > 0) {
          setEdbData(response);
          setSelectedEdb(response[0]);
        } else {
          setEdbData([]);
          setSelectedEdb({});
        }
        setEdbData(response);
      });
    }, 300000); // 5분
    return () => clearInterval(intervalId);
  }, []);

  return (
    <div id="allstatus-edboard-component">
      {edbData.length === 0 ? (
        <div className="data-none-container">등록된 전광판이 없습니다</div>
      ) : (
        <>
          {/* 전광판 리스트 */}
          <div className="edboard-list">
            <EdboardList
              edbData={edbData}
              selected={selectedEdb}
              setSelected={setSelectedEdb}
            />
          </div>

          {/* 해당 전광판 현황 */}
          <div className="edboard-message">
            <MessageInform selected={selectedEdb} />
          </div>
        </>
      )}
    </div>
  );
}

export default EdboardCurr;
