import { useState, useEffect } from "react";
import Modal from "react-modal";

import { getSiteID, getTargetMenu } from "../../services/internal/signAPI";
import { getUser, saveUser } from "../../services/internal/userAPI";
import { saveMenu } from "../../services/internal/signAPI";
import { MODALSTYLES } from "../../constants/modalStyles";
import SuccessAlert from "../common/window/SuccessAlert";
import Alert from "../common/window/Alert";
import Edit from "../common/window/Edit";
import PwdEdit from "./edit/PwdEdit";
import CellEdit from "./edit/CellEdit";

function ProfileEdit({ isOpen, setIsOpen, setIsSuccess }) {
  const [type, setType] = useState("personal");
  const [inform, setInform] = useState(isOpen.data);
  const [informMenu, setInformMenu] = useState([]);

  const [isChange, setIsChange] = useState(false);
  const [isPwdEdit, setIsPwdEdit] = useState(false);
  const [isPwdSuccess, setIsPwdSuccess] = useState(false);
  const [isCellEdit, setIsCellEdit] = useState(false);
  const [isCellSuccess, setIsCellSuccess] = useState(false);

  const [siteIds, setSiteIds] = useState([]);
  const [menuAuth, setMenuAuth] = useState([]);
  const [menuAuthList, setMenuAuthList] = useState([]);
  const [isAlertInput, setIsAlertInput] = useState(false);
  const [isAlertMenu, setIsAlertMenu] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  function compareArray(array1, array2) {
    if (array1.length !== array2.length) return false;
    else return true;
  }
  function onChangeAll() {
    // 전체 checkbox
    if (compareArray(menuAuthList, informMenu)) {
      setInformMenu([]);
    } else {
      setInformMenu(menuAuthList);
    }
  }
  function onChangeMenu(menuInf) {
    // 대메뉴 checkbox
    if (informMenu.find((item) => item.menuId === menuInf.menuId)) {
      const filterMenu = informMenu.filter(
        (item) =>
          item.menuId !== menuInf.menuId && item.parentId !== menuInf.menuId
      );
      setInformMenu(filterMenu);
    } else {
      const menu = [menuInf];
      if (Array.isArray(menuInf.menuList) && menuInf.menuList.length !== 0) {
        menuInf.menuList.forEach((item) => {
          menu.push(item);
        });
      }
      setInformMenu([...informMenu, ...menu]);
    }
  }
  function onChangeSubmenu(submenuInf) {
    // 중메뉴 checkbox
    if (informMenu.find((item) => item.menuId === submenuInf.menuId)) {
      const filterMenu = informMenu.filter(
        (item) =>
          item.menuId !== submenuInf.menuId &&
          item.menuId !== submenuInf.parentId
      );
      setInformMenu(filterMenu);
    } else {
      const parentMenu = menuAuthList.find(
        (item) => item.menuId === submenuInf.parentId
      );
      let submenuCnt = 1;
      informMenu.forEach((item) => {
        if (item.parentId === parentMenu.menuId) {
          submenuCnt = submenuCnt + 1;
        }
      });

      if (
        Array.isArray(parentMenu.menuList) &&
        submenuCnt === parentMenu.menuList.length
      ) {
        setInformMenu([...informMenu, parentMenu, submenuInf]);
      } else {
        setInformMenu([...informMenu, submenuInf]);
      }
    }
  }

  function onClickPwdEdit() {
    // 비밀번호 변경
    setIsPwdEdit(true);
  }
  function onClickCellEdit() {
    // 휴대번호 변경
    setIsCellEdit(true);
  }

  function onClickClose() {
    // 닫기 버튼 클릭시
    setIsOpen(false);
  }
  function onClickEdit() {
    // 수정 버튼 클릭시
    if (inform.userNm === "" || inform.adtnl1 === "") {
      // 필수 정보 입력X
      setIsAlertInput(true);
    } else {
      if (Array.isArray(informMenu) && informMenu.length < 1) {
        // 메뉴 1개 이상 선택X
        setIsAlertMenu(true);
      } else {
        // 수정
        setIsEdit(true);
      }
    }
  }
  function editUser() {
    // 사용자 수정
    const userInform = inform;
    delete userInform.menuAuth;

    const menuauth = [];
    informMenu.forEach((item) => {
      menuauth.push({ userId: inform.userId, menuId: item.menuId });
    });

    saveUser(userInform).then((response) => {
      saveMenu(menuauth).then((response2) => {
        setIsChange(true);
        setIsOpen({
          isOpen: false,
          data: {},
        });
        setIsSuccess(true);
      });
    });
  }

  useEffect(() => {
    getSiteID().then((response) => {
      setSiteIds(response);

      const menu = [...isOpen.data.menuAuth];
      if (Array.isArray(menu) && menu.length !== 0) {
        menu.forEach((item) => {
          if (Array.isArray(item.menuList) && item.menuList.length !== 0) {
            item.menuList.forEach((item2) => {
              menu.push(item2);
            });
          }
        });
      }
      setInformMenu(menu);

      if (isOpen.data.adtnl2 === undefined) {
        setInform({ ...isOpen.data, adtnl2: "" });
      } else {
        setInform(isOpen.data);
      }
    });
  }, []);
  useEffect(() => {
    if (inform.siteId && inform.siteId !== undefined) {
      getTargetMenu(inform.siteId).then((response) => {
        const menu = [...response];
        if (Array.isArray(menu) && menu.length !== 0) {
          menu.forEach((item) => {
            if (Array.isArray(item.menuList) && item.menuList.length !== 0) {
              item.menuList.forEach((item2) => {
                menu.push(item2);
              });
            }
          });
        }
        setMenuAuth(response);
        setMenuAuthList(menu);

        if (inform.siteId === isOpen.data.siteId) {
          const menu = [...isOpen.data.menuAuth];
          if (Array.isArray(menu) && menu.length !== 0) {
            menu.forEach((item) => {
              if (Array.isArray(item.menuList) && item.menuList.length !== 0) {
                item.menuList.forEach((item2) => {
                  menu.push(item2);
                });
              }
            });
          }
          setInformMenu(menu);
        } else {
          setInformMenu([]);
        }
      });
    }
  }, [inform.siteId]);
  useEffect(() => {
    if (isChange === true) {
      getUser(0).then((response) => {
        if (Array.isArray(response) && response.length !== 0) {
          const userInf = response.filter(
            (item) => item.userId === inform.userId
          );

          getSiteID().then((response) => {
            setSiteIds(response);

            const menu = [...userInf.menuAuth];
            if (Array.isArray(menu) && menu.length !== 0) {
              menu.forEach((item) => {
                if (
                  Array.isArray(item.menuList) &&
                  item.menuList.length !== 0
                ) {
                  item.menuList.forEach((item2) => {
                    menu.push(item2);
                  });
                }
              });
            }
            setInformMenu(menu);

            if (userInf.adtnl2 === undefined) {
              setInform({ userInf, adtnl2: "" });
            } else {
              setInform(userInf);
            }

            setIsChange(false);
          });
        }
      });
    }
  }, [isChange]);

  return (
    <Modal isOpen={isOpen.isOpen} style={MODALSTYLES.user}>
      {
        // 비밀번호 변경
        isPwdEdit && (
          <PwdEdit
            isOpen={isPwdEdit}
            setIsOpen={setIsPwdEdit}
            inform={inform}
            setIsChange={setIsChange}
            setIsSuccess={setIsPwdSuccess}
          />
        )
      }
      {
        // 비밀번호 변경 완료
        isPwdSuccess && (
          <SuccessAlert
            isOpen={isPwdSuccess}
            setIsOpen={setIsPwdSuccess}
            text="비밀번호가 변경되었습니다"
          />
        )
      }
      {
        // 휴대번호 변경
        isCellEdit && (
          <CellEdit
            isOpen={isCellEdit}
            setIsOpen={setIsCellEdit}
            inform={inform}
            setIsChange={setIsChange}
            setIsSuccess={setIsCellSuccess}
          />
        )
      }
      {
        // 휴대번호 변경 완료
        isCellSuccess && (
          <SuccessAlert
            isOpen={isCellSuccess}
            setIsOpen={setIsCellSuccess}
            text="휴대번호가 변경되었습니다"
          />
        )
      }

      {
        // 필수 입력 정보X
        isAlertInput && (
          <Alert
            isOpen={isAlertInput}
            setIsOpen={setIsAlertInput}
            text="필수 정보를 입력해주세요"
          />
        )
      }
      {
        // 메뉴 1개 미만 선택
        isAlertMenu && (
          <Alert
            isOpen={isAlertMenu}
            setIsOpen={setIsAlertMenu}
            text="메뉴를 1개 이상 선택해주세요"
          />
        )
      }
      {
        // 사용자 수정
        isEdit && (
          <Edit isOpen={isEdit} setIsOpen={setIsEdit} editData={editUser} />
        )
      }
      <div className="modal_title">
        <div>내 정보 수정</div>
      </div>
      <div className="modal_container">
        {/* 탭 선택 */}
        <div className="my-edit-select-container">
          <div className="button-container">
            <div className="button-imp button" onClick={onClickPwdEdit}>
              비밀번호 변경
            </div>
            <div
              className="button-imp button button-margin"
              onClick={onClickCellEdit}
            >
              휴대번호 변경
            </div>
          </div>

          <div className="select-container">
            <div
              className={
                type === "personal"
                  ? "select-item-select right"
                  : "select-item right"
              }
              onClick={() => setType("personal")}
            >
              개인정보
            </div>
            <div
              className={type === "menu" ? "select-item-select" : "select-item"}
              onClick={() => setType("menu")}
            >
              메뉴권한
            </div>
          </div>
        </div>

        {/* 개인정보 & 메뉴권한 */}
        <div className="modal_main_column">
          <div className="my-edit-modal">
            {type === "personal" ? (
              <>
                {/* 개인정보 */}
                <div className="typing-container">
                  <div className="title">고객사ID</div>
                  <select
                    className="one-select"
                    value={inform.siteId}
                    onChange={(e) =>
                      setInform({ ...inform, siteId: e.target.value })
                    }
                  >
                    {siteIds.map((item) => (
                      <option key={item.commonCode} value={item.commonCode}>
                        {item.commonRef5}({item.commonRef6})
                      </option>
                    ))}
                  </select>
                </div>
                <div className="typing-container">
                  <div className="title">아이디</div>
                  <div className="disable-div">{inform.userId}</div>
                  <div className="inner-title">비밀번호</div>
                  <div className="disable-div">•••••••••••••••••••</div>
                </div>
                <div className="typing-container">
                  <div className="title">이름</div>
                  <input
                    className="two-input"
                    type="text"
                    placeholder="필수 입력*"
                    value={inform.userNm}
                    onChange={(e) =>
                      setInform({ ...inform, userNm: e.target.value })
                    }
                  />
                  <div className="inner-title">Email</div>
                  <input
                    className="two-input"
                    type="email"
                    placeholder="Email 입력"
                    value={inform.email}
                    onChange={(e) =>
                      setInform({ ...inform, email: e.target.value })
                    }
                  />
                </div>
                <div className="typing-container">
                  <div className="title">휴대번호</div>
                  <div className="disable-div">{inform.cellNum}</div>
                  <div className="inner-title">전화번호</div>
                  <input
                    className="two-input"
                    type="number"
                    placeholder="전화번호 입력"
                    value={inform.phoneNum}
                    onChange={(e) =>
                      setInform({ ...inform, phoneNum: e.target.value })
                    }
                  />
                </div>
                <div className="typing-container">
                  <div className="title">소속</div>
                  <input
                    className="one-input"
                    type="text"
                    placeholder="필수 입력*"
                    value={inform.adtnl1}
                    onChange={(e) =>
                      setInform({ ...inform, adtnl1: e.target.value })
                    }
                  />
                </div>
                <div className="typing-container">
                  <div className="title">부서</div>
                  <input
                    className="two-input"
                    type="text"
                    placeholder="부서 입력"
                    value={inform.deptNm}
                    onChange={(e) =>
                      setInform({ ...inform, deptNm: e.target.value })
                    }
                  />
                  <div className="inner-title">직급</div>
                  <input
                    className="two-input"
                    type="text"
                    placeholder="직급 입력"
                    value={inform.positionNm}
                    onChange={(e) =>
                      setInform({ ...inform, positionNm: e.target.value })
                    }
                  />
                </div>
                <div className="last-typing-container">
                  <div className="title">메모</div>
                  <textarea
                    type="text"
                    placeholder="메모 입력"
                    value={inform.adtnl2 ?? ""}
                    onChange={(e) =>
                      setInform({ ...inform, adtnl2: e.target.value })
                    }
                  />
                </div>
              </>
            ) : (
              type === "menu" && (
                <div className="my-edit-modal-menu">
                  {/* 메뉴권한 */}
                  {Array.isArray(menuAuth) && menuAuth.length !== 0 && (
                    <>
                      <div className="all-select-container">
                        <input
                          type="checkbox"
                          id="all"
                          checked={compareArray(menuAuthList, informMenu)}
                          onChange={onChangeAll}
                        />
                        <label htmlFor="all">전체</label>
                      </div>
                      {menuAuth.map((menuInf) => (
                        <div key={menuInf.menuId}>
                          <div className="menu-item">
                            <input
                              type="checkbox"
                              id={menuInf.menuId}
                              checked={
                                !!informMenu.find(
                                  (item) => item.menuId === menuInf.menuId
                                )
                              }
                              onChange={() => onChangeMenu(menuInf)}
                            />
                            <label htmlFor={menuInf.menuId}>
                              {menuInf.menuNm}
                            </label>
                          </div>
                          {Array.isArray(menuInf.menuList) &&
                            menuInf.menuList.length !== 0 && (
                              <div className="submenu-item-container">
                                {menuInf.menuList.map((submenuInf) => (
                                  <div
                                    key={submenuInf.menuId}
                                    className="submenu-item"
                                  >
                                    <input
                                      type="checkbox"
                                      id={submenuInf.menuId}
                                      checked={
                                        !!informMenu.find(
                                          (item) =>
                                            item.menuId === submenuInf.menuId
                                        )
                                      }
                                      onChange={() =>
                                        onChangeSubmenu(submenuInf)
                                      }
                                    />
                                    <label htmlFor={submenuInf.menuId}>
                                      {submenuInf.menuNm}
                                    </label>
                                  </div>
                                ))}
                              </div>
                            )}
                        </div>
                      ))}
                    </>
                  )}
                </div>
              )
            )}
            {/* 메뉴권한 */}
          </div>
        </div>

        <div className="modal_footer">
          <div className="button-nor-modal" onClick={onClickClose}>
            닫기
          </div>
          <div className="button-imp right-button" onClick={onClickEdit}>
            수정
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default ProfileEdit;
