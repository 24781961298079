import { useState, useEffect } from "react";

import {
  getCurrWeatherInform,
  getForecastInform,
} from "../../services/external/kmaAPI";
import CurrWeather from "./currfore/CurrWeather";
import Forecast from "./currfore/Forecast";

function CurrFore() {
  const [type, setType] = useState("curr");
  const [currInform, setCurInform] = useState([]);
  const [foreInform, setForeInform] = useState([]);

  useEffect(() => {
    getCurrWeatherInform().then((currWeather) => {
      getForecastInform().then((forecast) => {
        setCurInform(currWeather);
        setForeInform(forecast);
      });
    });

    const intervalId = setInterval(() => {
      getCurrWeatherInform().then((currWeather) => {
        getForecastInform().then((forecast) => {
          setCurInform(currWeather);
          setForeInform(forecast);
        });
      });
    }, 1800000); // 30분
    return () => clearInterval(intervalId);
  }, []);

  return (
    <div id="allstatus-curr-forecast-component">
      {/* 선택 */}
      <div className="select-container">
        <div
          className={
            type === "curr"
              ? "select-item select-item-select left"
              : "select-item left"
          }
          onClick={() => setType("curr")}
        >
          날씨
        </div>
        <div
          className={
            type === "forecast"
              ? "select-item select-item-select right"
              : "select-item right"
          }
          onClick={() => setType("forecast")}
        >
          예보
        </div>
      </div>

      {/* 컨텐츠 */}
      <div className="curr-fore-content-container">
        {type === "curr" ? (
          <CurrWeather currInform={currInform} />
        ) : (
          <Forecast foreInform={foreInform} />
        )}
      </div>
    </div>
  );
}

export default CurrFore;
