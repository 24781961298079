import { useState, useEffect } from "react";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";

function EdboardCurr({ selected }) {
  const [inform, setInform] = useState({});

  useEffect(() => {
    if (Array.isArray(selected.msgList) && selected.msgList.length > 0) {
      if (selected.msgList.find((item) => item.useYn === "Y")) {
        const currInform = selected.msgList.find((item) => item.useYn === "Y");
        setInform(currInform);
      } else {
        setInform({});
      }
    } else {
      setInform({});
    }
  }, [selected]);

  return (
    <div id="edboard-send-curr-component">
      {Object.keys(inform).length === 0 ? (
        <div className="data-none-container">
          해당 전광판은 작동중이지 않습니다
        </div>
      ) : (
        <>
          <div className="typing-container">
            <div className="title">입장 효과</div>
            <div className="one-div">{inform.entryEffect}</div>
          </div>
          <div className="typing-container">
            <div className="title">표시 시간</div>
            <div className="time-div">{inform.holdTime}</div>
            <div className="inner-title">속도</div>
            <div className="spd-container">
              <div className="spd-slider">
                <Slider
                  className="slider"
                  min={1}
                  max={99}
                  step={1}
                  value={inform.speed}
                  trackStyle={{ background: "#418dff" }}
                  handleStyle={{ borderColor: "#000" }}
                  disabled={true}
                />
              </div>
              <div className="spd-div">{inform.speed}</div>
            </div>
          </div>
          <div className="typing-container flex-typing-container">
            <div className="title">내용</div>
            <textarea type="text" value={inform.msg} disabled={true} />
          </div>
          <div className="typing-container last-typing-container">
            <div className="title">퇴장 효과</div>
            <div className="one-div">{inform.exitEffect}</div>
          </div>
        </>
      )}
    </div>
  );
}

export default EdboardCurr;
