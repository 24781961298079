import { useState, useEffect } from "react";

import { dateTimeToFormat } from "../../../utils/dateFormat";

function MessageInform({ selected }) {
  const [isWorking, setIsWorking] = useState(false);
  const [inform, setInform] = useState({ sendDt: "", message: "" });

  useEffect(() => {
    if (
      Object.keys(selected).length > 0 &&
      Array.isArray(selected.msgList) &&
      selected.msgList.length > 0
    ) {
      if (selected.msgList.find((item) => item.useYn === "Y")) {
        if (Array.isArray(selected.msgLog) && selected.msgLog.length > 0) {
          // 작동중O
          const sortData = selected.msgLog.sort((a, b) => {
            if (a.regDt > b.regDt) return -1;
            if (a.regDt < b.regDt) return 1;
            return 0;
          });
          const messageInform = sortData[0];

          setIsWorking(true);
          setInform({
            sendDt: dateTimeToFormat(messageInform.regDt),
            message: messageInform.sendMsg,
          });
        } else {
          // 작동중X
          setIsWorking(false);
          setInform({ sendDt: "", message: "" });
        }
      } else {
        // 작동중X
        setIsWorking(false);
        setInform({ sendDt: "", message: "" });
      }
    } else {
      // 작동중X
      setIsWorking(false);
      setInform({ sendDt: "", message: "" });
    }
  }, [selected]);

  return (
    <>
      {!isWorking ? (
        <div className="data-none-container" style={{ fontSize: "0.8rem" }}>
          해당 전광판은 작동중이지 않습니다
        </div>
      ) : (
        <>
          <div className="date-container">
            <div className="title">전송일자</div>
            <div className="date">{inform.sendDt}</div>
          </div>
          <div className="message-container">
            <div className="title">내용</div>
            <textarea value={inform.message} disabled={true} />
          </div>
        </>
      )}
    </>
  );
}

export default MessageInform;
