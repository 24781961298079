import React, { useState, useEffect } from "react";
import {
  flexRender,
  useReactTable,
  getCoreRowModel,
  createColumnHelper,
} from "@tanstack/react-table";

import {
  getDataTableColumn,
  getDataTableColumnDate,
  getDataTableColumnDateValue,
} from "../../../utils/data";
import MinDisTable from "./MinDisTable";

function DisTable({ term, disData, selected, setSelected }) {
  const [data, setData] = useState(disData);
  const [columns, setColumns] = useState([]);
  const [dateColumns, setDateColumns] = useState([]);
  const columnHelper = createColumnHelper();
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });
  const [mouseData, setMouseData] = useState({});

  function onClickRow(row) {
    // 행 클릭 시
    setSelected(row.original);
  }
  function onMouseOverRow(row) {
    // 행에 마우스 가져다 댔을 시
    setMouseData(row.original);
  }
  function onMouseOutRow() {
    // 행에서 마우스를 뗏을 시
    setMouseData({});
  }

  useEffect(() => {
    if (disData.find((disItem) => disItem.data.length !== 0)) {
      if (term !== "min") {
        const column = [
          columnHelper.accessor("eqNm", { header: "센서명", size: 200 }),
          columnHelper.accessor(
            (row) => {
              console.log(row.data[0]);
            },
            { header: "구분", size: 50 }
          ),
          ...getDataTableColumn(term),
        ];
        setColumns(column);
        setDateColumns(getDataTableColumnDate(term));
      }
    }
    setData(disData);
  }, [disData]);

  return (
    <div id="data-table-component">
      {term === "min" ? (
        // 분별 조회
        <MinDisTable
          disData={disData}
          selected={selected}
          setSelected={setSelected}
        />
      ) : (
        // 월별/일별/시간 조회
        <div className="list">
          <table className="react-data-table">
            <thead>
              {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <th
                      key={header.id}
                      onClick={header.column.getToggleSortingHandler()}
                      style={{
                        width: isNaN(header.getSize())
                          ? "auto"
                          : `${header.getSize()}px`,
                      }}
                    >
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody>
              {table.getRowModel().rows.map((row, index) => (
                <React.Fragment key={row.id}>
                  <tr
                    className={
                      selected.eqId === row.original.eqId
                        ? "tr-select"
                        : mouseData.eqId === row.original.eqId
                        ? "tr-select tr-pointer"
                        : index % 2 === 0
                        ? "tr-pointer tr-even"
                        : "tr-pointer tr-odd"
                    }
                    onClick={() => onClickRow(row)}
                    onMouseOver={() => onMouseOverRow(row)}
                    onMouseOut={onMouseOutRow}
                  >
                    <td rowSpan={3}>{row.original.eqNm}</td>
                    <td>x</td>
                    {dateColumns.map((dateColumn) => (
                      <td key={`x-${dateColumn}`}>
                        {getDataTableColumnDateValue(
                          term,
                          dateColumn,
                          "x",
                          row
                        )}
                      </td>
                    ))}
                  </tr>
                  <tr
                    className={
                      selected.eqId === row.original.eqId
                        ? "tr-select"
                        : mouseData.eqId === row.original.eqId
                        ? "tr-select tr-pointer"
                        : index % 2 === 0
                        ? "tr-pointer tr-even"
                        : "tr-pointer tr-odd"
                    }
                    onClick={() => onClickRow(row)}
                    onMouseOver={() => onMouseOverRow(row)}
                    onMouseOut={onMouseOutRow}
                  >
                    <td>y</td>
                    {dateColumns.map((dateColumn) => (
                      <td key={`y-${dateColumn}`}>
                        {getDataTableColumnDateValue(
                          term,
                          dateColumn,
                          "y",
                          row
                        )}
                      </td>
                    ))}
                  </tr>
                  <tr
                    className={
                      selected.eqId === row.original.eqId
                        ? "tr-select"
                        : mouseData.eqId === row.original.eqId
                        ? "tr-select tr-pointer"
                        : index % 2 === 0
                        ? "tr-pointer tr-even"
                        : "tr-pointer tr-odd"
                    }
                    onClick={() => onClickRow(row)}
                    onMouseOver={() => onMouseOverRow(row)}
                    onMouseOut={onMouseOutRow}
                  >
                    <td>mm</td>
                    {dateColumns.map((dateColumn) => (
                      <td key={`mm-${dateColumn}`}>
                        {getDataTableColumnDateValue(
                          term,
                          dateColumn,
                          "mm",
                          row
                        )}
                      </td>
                    ))}
                  </tr>
                </React.Fragment>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}

export default DisTable;
