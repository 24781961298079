import { useState, useEffect } from "react";
import {
  createColumnHelper,
  flexRender,
  useReactTable,
  getCoreRowModel,
  getSortedRowModel,
} from "@tanstack/react-table";

import DeviceInform from "../../common/inform/DeviceInform";

const brdTypes = [
  { value: "01", text: "비상예경보방송기기" },
  { value: "02", text: "마을방송" },
];

function BroadDevice({ brdData }) {
  const [isInform, setIsInform] = useState({
    isOpen: false,
    data: {},
  });
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);
  const columnHelper = createColumnHelper();
  const emptyColumn = {
    id: "empty",
    accessor: "empty",
    header: "",
    enableSorting: false,
    size: 10,
  };
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  function onDoubleClickRow(row) {
    // 행 더블 클릭 시.
    setIsInform({
      isOpen: true,
      data: row,
    });
  }

  useEffect(() => {
    const column = [
      emptyColumn,
      columnHelper.accessor("dvcNm", {
        header: "장비명",
        size: 170,
      }),
      columnHelper.accessor(
        (row) => {
          return brdTypes.find((type) => type.value === row.dvcDtlType)?.text;
        },
        { header: "타입", size: "auto" }
      ),
    ];

    setData(brdData);
    setColumns(column);
  }, [brdData]);

  return (
    <table className="sub_table">
      {isInform.isOpen && (
        <DeviceInform isOpen={isInform} setIsOpen={setIsInform} />
      )}
      <tbody>
        {table.getRowModel().rows.map((row, index) => (
          <tr
            key={row.id}
            onDoubleClick={() => onDoubleClickRow(row.original)}
            className={index % 2 === 0 ? "tr_even" : "tr_odd"}
            style={{ cursor: "pointer" }}
          >
            {row.getVisibleCells().map((cell) => (
              <td
                key={cell.id}
                style={{
                  width: isNaN(cell.column.getSize())
                    ? "auto"
                    : `${cell.column.getSize()}px`,
                  minWidth: isNaN(cell.column.getSize())
                    ? "auto"
                    : `${cell.column.getSize()}px`,
                  maxWidth: isNaN(cell.column.getSize())
                    ? "auto"
                    : `${cell.column.getSize()}px`,
                  textAlign:
                    (cell.column.id === "num" ||
                      cell.column.id === "instlDt" ||
                      cell.column.id === "modelNm") &&
                    "center",
                }}
              >
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
}

export default BroadDevice;
