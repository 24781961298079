import { useState, useEffect } from "react";
import {
  flexRender,
  useReactTable,
  getCoreRowModel,
  createColumnHelper,
} from "@tanstack/react-table";

function Table({ selected }) {
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);
  const columnHelper = createColumnHelper();
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  useEffect(() => {
    if (Array.isArray(selected.data) && selected.data.length > 0) {
      const sortData = [...selected.data].reverse();

      const cellCnt = Object.keys(selected.data[0]).length;
      const column = [columnHelper.accessor("date", { header: "측정일자" })];
      if (cellCnt <= 2) {
        column.push(columnHelper.accessor("avgVal", { header: "측정값" }));
      } else {
        column.push(columnHelper.accessor("x", { header: "x" }));
        column.push(columnHelper.accessor("y", { header: "y" }));
        if (cellCnt === 4) {
          column.push(columnHelper.accessor("mm", { header: "mm" }));
        }
      }

      setData(sortData);
      setColumns(column);
    } else {
      setData([]);
      setColumns([]);
    }
  }, [selected]);

  return (
    <div className="table-container">
      {data.length === 0 ? (
        <div className="data-none-container">금일 데이터 내역이 없습니다</div>
      ) : (
        <div className="list">
          <table className="react-data-table">
            <thead>
              {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <th
                      key={header.id}
                      style={{
                        width: isNaN(header.getSize())
                          ? "auto"
                          : `${header.getSize()}px`,
                      }}
                    >
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody>
              {table.getRowModel().rows.map((row, index) => (
                <tr
                  key={row.id}
                  className={
                    index % 2 === 0 ? "tr-data tr-even" : "tr-data tr-odd"
                  }
                  style={{ cursor: "default" }}
                >
                  {row.getVisibleCells().map((cell) => (
                    <td
                      key={cell.id}
                      style={{
                        width: isNaN(cell.column.getSize())
                          ? "auto"
                          : `${cell.column.getSize()}px`,
                        textAlign: "center",
                      }}
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}

export default Table;
