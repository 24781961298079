import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setMenu, setSubmenu } from "../../../redux/modules/menu";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import locale from "../../../data/datepicker";
import ExcelJS from "exceljs";
import saveAs from "file-saver";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome, faChartSimple } from "@fortawesome/free-solid-svg-icons";

import { DATEPICKERSTYLES } from "../../../constants/datepickerStyles";
import { tokenCheck } from "../../../utils/tokenCheck";
import { getReservoirDevice } from "../../../services/internal/rtuAPI";
import {
  getLevelSnsr,
  getDataUpdate,
  getRsvLevel,
} from "../../../services/internal/dataAPI";
import {
  getMonthColumn,
  getDayColumn,
  getHourColumn,
  getMinColumn,
  getDataExcelName,
} from "../../../utils/data";
import { getFromDtAndToDt } from "../../../utils/data";
import Loading from "../../../components/common/window/Loading";
import ReservoirGraph from "../../../components/data/reservoir/ReservoirGraph";
import LevelTable from "../../../components/data/level/LevelTable";

const PAGE_VALUE = "자료관리";
const SUBPAGE_VALUE = "저수위자료";

function DataReservoir() {
  const dispatch = useDispatch();
  const movePage = useNavigate();
  const [isToken, setIsToken] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [searchDate, setSearchDate] = useState(new Date());
  const [searchType, setSearchType] = useState("day");
  const [searchDateOk, setSearchDateOk] = useState(new Date());
  const [searchTypeOk, setSearchTypeOk] = useState("day");

  const [reservoirArr, setReservoirArr] = useState([]);
  const [selected, setSelected] = useState({});

  function onClickQuery() {
    // 조회 클릭 시
    tokenCheck().then((result) => {
      if (!result) {
        alert("인증 토큰이 만료되었습니다. 다시 로그인 해주세요");
        movePage("/");
      } else {
        if (reservoirArr.length > 0) {
          setSearchTypeOk(searchType);
          setSearchDateOk(searchDate);
          fetchData();
        }
      }
    });
  }
  function onClickExcel() {
    // Excel저장 클릭 시
    tokenCheck().then((result) => {
      if (!result) {
        alert("인증 토큰이 만료되었습니다. 다시 로그인 해주세요");
        movePage("/");
      } else {
        fetchExcel();
      }
    });
  }
  async function fetchExcel() {
    // Excel 파일 생성
    if (
      reservoirArr.length > 0 &&
      !reservoirArr.every((item) => item.data.length === 0)
    ) {
      const workBook = new ExcelJS.Workbook();
      reservoirArr.forEach((levelItem) => {
        if (Array.isArray(levelItem.data) && levelItem.data.length > 0) {
          const worksheet = workBook.addWorksheet(levelItem.eqNm);
          worksheet.columns = [
            { header: "측정일자", key: "date", width: 20 },
            { header: "측정값(EL.m)", key: "avgVal", width: 25 },
          ];
          worksheet.columns.forEach((column) => {
            column.font = { size: 10, name: "Arial", bold: true };
            column.alignment = { horizontal: "center" };
          });
          levelItem.data.forEach((item) => {
            const row = worksheet.addRow(item);
            row.font = { size: 10, name: "Arial" }; // 폰트 설정
            row.alignment = { horizontal: "center" };
          });
        }
      });
      const buffer = await workBook.xlsx.writeBuffer();
      const blob = new Blob([buffer], { type: "application/octet-stream" });
      const titleInform = getDataExcelName(searchTypeOk, searchDateOk);
      saveAs(
        blob,
        `스마트조기경보_${titleInform.date}_${titleInform.term}_저수위계_데이터_자료.xlsx`
      );
    }
  }
  function fetchData() {
    /* 수위 데이터 조회 API */
    setIsLoading(true);
    const fromDt = getFromDtAndToDt(searchType, searchDate).fromDt;
    const toDt = getFromDtAndToDt(searchType, searchDate).toDt;
    getReservoirDevice().then((rsvDevice) => {
      getRsvLevel(rsvDevice, searchType, fromDt, toDt).then((response) => {
        setIsLoading(false);
        if (Array.isArray(response) && response.length > 0) {
          const formatData = response.map((item) => {
            if (Array.isArray(item.data) && item.data.length > 0) {
              let dateColumn = [];
              switch (searchType) {
                case "year":
                  dateColumn = getMonthColumn(searchDate);
                  break;
                case "month":
                  dateColumn = getDayColumn(searchDate);
                  break;
                case "day":
                  dateColumn = getHourColumn(searchDate);
                  break;
                default:
                  dateColumn = getMinColumn(searchDate);
                  break;
              }
              const data = dateColumn.map((dateItem) => {
                const check = item.data.find(
                  (dataItem) => dataItem.date === dateItem
                );
                const avgVal =
                  check !== null && check !== undefined
                    ? parseFloat(check.avgVal).toFixed(2)
                    : "-";
                return { date: dateItem, avgVal: avgVal };
              });
              return { ...item, data: data };
            } else {
              return { ...item, data: [] };
            }
          });
          const sortData = [...formatData];
          sortData.sort((a, b) => a.eqId - b.eqId);
          setReservoirArr(sortData);
          setSelected(sortData[0]);
        } else {
          setReservoirArr([]);
          setSelected({});
        }
      });
    });
  }

  useEffect(() => {
    dispatch(setMenu(PAGE_VALUE));
    dispatch(setSubmenu(SUBPAGE_VALUE));

    // 토큰 확인
    tokenCheck().then((result) => {
      if (!result) {
        alert("인증 토큰이 만료되었습니다. 다시 로그인 해주세요");
        movePage("/");
      } else {
        setIsToken(true);
        fetchData();
      }
    });
  }, []);

  return (
    <div className="outlet_container">
      {
        // 로딩화면
        isLoading && <Loading isOpen={isLoading} setIsOpen={setIsLoading} />
      }
      {isToken && (
        <div id="data-page">
          <div className="header_container">
            <div className="title">
              <div className="title_imp">저수위</div>
              <div className="title_nor">자료</div>
            </div>
            <div className="path">
              <FontAwesomeIcon icon={faHome} />
              <div className="text">&gt; 자료관리 &gt; 저수위자료</div>
            </div>
          </div>

          <div className="main_container_column">
            {/* 그래프 */}
            <div className="data-graph-container">
              <ReservoirGraph searchType={searchTypeOk} selected={selected} />
            </div>

            {/* 테이블 */}
            <div className="contents_container data-table-container">
              <div className="contents_header">
                <div className="title">
                  <FontAwesomeIcon icon={faChartSimple} />
                  <div className="text">저수위</div>
                </div>
                {/* 검색조건 & Excel */}
                <div className="data-table-search-excel">
                  <input
                    type="radio"
                    id="year"
                    name="searchType"
                    value="year"
                    onChange={(e) => setSearchType(e.target.value)}
                    checked={searchType === "year"}
                  />
                  <label htmlFor="year">월별</label>
                  <input
                    type="radio"
                    id="month"
                    name="searchType"
                    value="month"
                    onChange={(e) => setSearchType(e.target.value)}
                    checked={searchType === "month"}
                  />
                  <label htmlFor="month">일별</label>
                  <input
                    type="radio"
                    id="day"
                    name="searchType"
                    value="day"
                    onChange={(e) => setSearchType(e.target.value)}
                    checked={searchType === "day"}
                  />
                  <label htmlFor="day">시간</label>
                  <input
                    type="radio"
                    id="min"
                    name="searchType"
                    value="min"
                    onChange={(e) => setSearchType(e.target.value)}
                    checked={searchType === "min"}
                  />
                  <label htmlFor="min">분별</label>

                  <DatePicker
                    selected={searchDate}
                    onChange={(date) => setSearchDate(date)}
                    dateFormat={
                      searchType === "year"
                        ? "yyyy"
                        : searchType === "month"
                        ? "yyyy-MM"
                        : (searchType === "day" || searchType === "min") &&
                          "yyyy-MM-dd"
                    }
                    maxDate={new Date()}
                    showYearPicker={searchType === "year" ? true : false}
                    showMonthYearPicker={searchType === "month" ? true : false}
                    locale={locale.ko}
                    customInput={<DATEPICKERSTYLES.data />}
                  />
                  <div
                    className="data-search-button button-imp"
                    onClick={onClickQuery}
                  >
                    조회
                  </div>
                  <div
                    className="data-excel-button button-excel"
                    onClick={onClickExcel}
                  >
                    Excel저장
                  </div>
                </div>
              </div>

              <div className="contents_main">
                {reservoirArr.length > 0 ? (
                  <LevelTable
                    term={searchTypeOk}
                    levelData={reservoirArr}
                    selected={selected}
                    setSelected={setSelected}
                  />
                ) : (
                  <div className="data-none-container">
                    등록된 저수위계가 없습니다
                  </div>
                )}
              </div>

              <div className="contents_footer"></div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default DataReservoir;
