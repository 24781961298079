import { useState, useEffect } from "react";
import JSMpeg from "@cycjimmy/jsmpeg-player";

import { getCCTVVideo, quitCCTVVideo } from "../../services/internal/cctvAPI";

function CCTVScreens({ cctv, isQuit }) {
  const [result, setResult] = useState(false);
  const [player, setPlayer] = useState(null);
  const [port, setPort] = useState(null);
  const [errText, setErrText] = useState("");

  function fetchCCTV(cctvInf) {
    /* CCTV 영상 조회 */
    getCCTVVideo(cctvInf).then((response) => {
      if (response.result === "success") {
        // 연결 성공
        setResult(true);
        setErrText("");

        const port = response.port;
        setPort(port);
        const canvas = document.getElementById(cctv.eqId + "-cctv-canvas");
        const protocol = window.location.protocol;
        let jsmpeg;
        if (protocol === "https:") {
          jsmpeg = new JSMpeg.Player("wws://121.140.107.240:" + port, {
            canvas: canvas,
          });
        } else {
          jsmpeg = new JSMpeg.Player("ws://121.140.107.240:" + port, {
            canvas: canvas,
          });
        }
        setPlayer(jsmpeg);
      } else {
        // 연결 실패
        setResult(false);
        setErrText("연결 실패");
        setPort(null);
      }
    });
  }

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (port !== null) {
        if (player !== undefined && player !== null) {
          player.destroy();
          setPlayer(null);
        }
        quitCCTVVideo(port);
      }
    };

    // 창이 닫히거나 새로고침될 때 이벤트 추가
    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      if (port !== null) {
        if (player !== undefined && player !== null) {
          player.destroy();
          setPlayer(null);
        }
        quitCCTVVideo(port);
      }
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [port]);
  useEffect(() => {
    if (isQuit) {
      if (port !== null) {
        if (player !== undefined && player !== null) {
          player.destroy();
          setPlayer(null);
        }
        quitCCTVVideo(port);
      }
    }
  }, [isQuit]);

  useEffect(() => {
    if (Object.keys(cctv).length !== 0) {
      fetchCCTV(cctv);
    } else {
      setPort(null);
    }
  }, [cctv]);

  return (
    <>
      <div className="title">
        <div className="name">{cctv.eqNm}</div>
        <div className="err">{errText}</div>
      </div>
      <div className="screen">
        <canvas id={cctv.eqId + "-cctv-canvas"} />
        {!result && <div className="err-screen">연결에 실패하였습니다</div>}
      </div>
    </>
  );
}

export default CCTVScreens;
