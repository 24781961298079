import { useState, useEffect } from "react";
import Modal from "react-modal";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

import { dateTimeToFormat } from "../../utils/dateFormat";
import { getTargetMenu } from "../../services/internal/signAPI";
import { MODALSTYLES } from "../../constants/modalStyles";

function ProfileInform({ isOpen, setIsOpen }) {
  const [type, setType] = useState("personal");
  const [inform, setInform] = useState({});
  const [menuAuth, setMenuAuth] = useState([]);

  function compareArray(array1, array2) {
    if (array1.length !== array2.length) return false;
    else return true;
  }
  function onClickClose() {
    // 닫기 버튼 클릭 시
    setIsOpen({
      isOpen: false,
      data: {},
    });
  }

  useEffect(() => {
    if (Object.keys(isOpen.data).length !== 0) {
      getTargetMenu(isOpen.data.siteId).then((response) => {
        setInform(isOpen.data);
        setMenuAuth(response);
      });
    }
  }, []);

  return (
    <Modal isOpen={isOpen.isOpen} style={MODALSTYLES.user}>
      <div className="modal_title">
        <div>내 정보</div>
      </div>

      <div className="modal_container">
        <div className="my-inform-select-container">
          {/* 탭 선택 */}
          <div
            className={
              type === "personal"
                ? "select-item-select right"
                : "select-item right"
            }
            onClick={() => setType("personal")}
          >
            개인정보
          </div>
          <div
            className={type === "menu" ? "select-item-select" : "select-item"}
            onClick={() => setType("menu")}
          >
            메뉴권한
          </div>
        </div>

        {/* 개인정보 & 메뉴권한 */}
        <div className="modal_main_column">
          <div className="my-inform-modal">
            {type === "personal" ? (
              <>
                <div className="inform-container">
                  <div className="title">고객사ID</div>
                  <div className="inform inform-two">{inform.siteId}</div>
                  <div className="inner-title">등록일자</div>
                  <div className="inform inform-two">
                    {dateTimeToFormat(inform.regDt)}
                  </div>
                </div>
                <div className="inform-container">
                  <div className="title">아이디</div>
                  <div className="inform inform-two">{inform.userId}</div>
                  <div className="inner-title">비밀번호</div>
                  <div className="inform inform-two">•••••••••••••••••••</div>
                </div>
                <div className="inform-container">
                  <div className="title">이름</div>
                  <div className="inform inform-two">{inform.userNm}</div>
                  <div className="inner-title">Email</div>
                  <div className="inform inform-two">
                    {inform.email === "" || inform.email === undefined
                      ? "-"
                      : inform.email}
                  </div>
                </div>
                <div className="inform-container">
                  <div className="title">휴대번호</div>
                  <div className="inform inform-two">
                    {inform.cellNum === "" || inform.cellNum === undefined
                      ? "-"
                      : inform.cellNum}
                  </div>
                  <div className="inner-title">전화번호</div>
                  <div className="inform inform-two">
                    {inform.phoneNum === "" || inform.phoneNum === undefined
                      ? "-"
                      : inform.phoneNum}
                  </div>
                </div>
                <div className="inform-container">
                  <div className="title">소속</div>
                  <div className="inform inform-one">
                    {inform.adtnl1 === "" || inform.adtnl1 === undefined
                      ? "-"
                      : inform.adtnl1}
                  </div>
                </div>
                <div className="inform-container">
                  <div className="title">부서</div>
                  <div className="inform inform-two">
                    {inform.deptNm === "" || inform.deptNm === undefined
                      ? "-"
                      : inform.deptNm}
                  </div>
                  <div className="inner-title">직급</div>
                  <div className="inform inform-two">
                    {inform.positionNm === "" || inform.positionNm === undefined
                      ? "-"
                      : inform.positionNm}
                  </div>
                </div>
                <div className="last-inform-container">
                  <div className="title">메모</div>
                  <textarea
                    value={
                      inform.adtnl2 === "" || inform.adtnl2 === undefined
                        ? "-"
                        : inform.adtnl2
                    }
                    disabled={true}
                  />
                </div>
              </>
            ) : (
              type === "menu" && (
                <div className="my-inform-modal-menu">
                  {menuAuth.length !== 0 && (
                    <>
                      <div className="all-select-container">
                        {compareArray(menuAuth, inform.menuAuth) ? (
                          <div className="checkbox-select">
                            <FontAwesomeIcon icon={faCheck} />
                          </div>
                        ) : (
                          <div className="checkbox" />
                        )}
                        <div className="label">전체</div>
                      </div>
                      {menuAuth.map((menuInf) => (
                        <div key={menuInf.menuId}>
                          <div className="menu-item">
                            {!!inform.menuAuth.find(
                              (item) => item.menuId === menuInf.menuId
                            ) ? (
                              <div className="checkbox-select">
                                <FontAwesomeIcon icon={faCheck} />
                              </div>
                            ) : (
                              <div className="checkbox" />
                            )}
                            <div className="label">{menuInf.menuNm}</div>
                          </div>
                          {menuInf.menuList.length !== 0 && (
                            <div className="submenu-item-container">
                              {menuInf.menuList.map((submenuInf) => (
                                <div
                                  key={submenuInf.menuId}
                                  className="submenu-item"
                                >
                                  {!!menuInf.menuList.find(
                                    (item) => item.menuId === submenuInf.menuId
                                  ) ? (
                                    <div className="checkbox-select">
                                      <FontAwesomeIcon icon={faCheck} />
                                    </div>
                                  ) : (
                                    <div className="checkbox" />
                                  )}
                                  <div className="label">
                                    {submenuInf.menuNm}
                                  </div>
                                </div>
                              ))}
                            </div>
                          )}
                        </div>
                      ))}
                    </>
                  )}
                </div>
              )
            )}
          </div>
        </div>

        <div className="modal_footer">
          <div className="button-nor-modal" onClick={onClickClose}>
            닫기
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default ProfileInform;
