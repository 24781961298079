import { createAction, handleActions } from "redux-actions";

const SETMENU = "menu/SETMENU";
const SETSUBMENU = "menu/SETSUBMENU";

export const setMenu = createAction(SETMENU, (menu) => menu);
export const setSubmenu = createAction(SETSUBMENU, (submenu) => submenu);

const initialState = {
  menu: "allstatus",
  submenu: "",
};

const menu = handleActions(
  {
    [SETMENU]: (state, action) => ({ ...state, menu: action.payload }),
    [SETSUBMENU]: (state, action) => ({ ...state, submenu: action.payload }),
  },
  initialState
);

export default menu;
