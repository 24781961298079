import Modal from "react-modal";

Modal.setAppElement("#root");

function BroadGroupInform({ isOpen, setIsOpen }) {
  function onClickClose() {
    setIsOpen({ isOpen: false, data: {} });
  }

  return (
    <Modal isOpen={isOpen.isOpen} style={modalStyles}>
      <div className="modal_title">
        <div>방송 그룹 정보</div>
      </div>
      <div className="modal_container">
        {/* Main */}
        <div className="modal_main_column">
          <div className="modal_input">
            <div className="title">방송 그룹명</div>
            <input
              value={isOpen.data.groupNm}
              disabled={true}
              spellCheck={false}
            />
          </div>
          <div
            className="modal_input"
            style={{ flex: 1, borderBottom: "none" }}
          >
            <div className="title">메모</div>
            <textarea
              value={isOpen.data.groupDtl}
              disabled={true}
              spellCheck={false}
            />
          </div>
        </div>

        {/* Footer */}
        <div className="modal_footer">
          <div className="button_nor" onClick={onClickClose}>
            닫기
          </div>
        </div>
      </div>
    </Modal>
  );
}

const modalStyles = {
  content: {
    width: "620px",
    height: "300px",
    padding: "0px",
    background: "#202020",
    border: "none",
    borderRadius: "5px",
    boxShadow: "-5px 5px 10px rgba(0, 0, 0, 0.7)",
    margin: "auto",
  },
  overlay: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "fixed",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: "rgba(0, 0, 0, 0.4)",
  },
};

export default BroadGroupInform;
