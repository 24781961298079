import { useState, useEffect } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";

import { sign, saveMenu } from "../../services/internal/signAPI";
import Alert from "../common/window/Alert";
import Confirm from "../common/window/Confirm";

function MenuAuth({
  setIsOpen,
  setIsSignAlert,
  setPage,
  targetMenu,
  menuAuth,
  setMenuAuth,
  signInform,
}) {
  const [allChecked, setAllChecked] = useState(false);
  const [isSignConfirm, setIsSignConfirm] = useState(false);
  const [isFailAlert, setIsFailAlert] = useState(false);

  function onClickAccept() {
    // 신청 클릭
    setIsSignConfirm({
      isOpen: true,
      text: "계정 등록 신청을 진행하시겠습니까?",
    });
  }

  function signFunc() {
    const inputMenuAuth = menuAuth.map((menuId) => {
      return { userId: signInform.userId, menuId: menuId };
    });
    sign(signInform).then((signResult) => {
      if (signResult.data) {
        saveMenu(inputMenuAuth).then((menuResult) => {
          if (menuResult.data) {
            // 계정등록 성공
            setIsOpen(false);
            setIsSignAlert(true);
          } else {
            // 계정등록 실패
            setIsFailAlert(true);
          }
        });
      } else {
        // 계정등록 실패
        setIsFailAlert(true);
      }
    });
  }

  function getAllMenuId() {
    if (Array.isArray(targetMenu) && targetMenu.length !== 0) {
      const allMenu = [];
      targetMenu.forEach((menuItem) => {
        allMenu.push(menuItem.menuId);
        if (
          Array.isArray(menuItem.menuList) &&
          menuItem.menuList.length !== 0
        ) {
          menuItem.menuList.forEach((submenuItem) => {
            allMenu.push(submenuItem.menuId);
          });
        }
      });
      return allMenu;
    } else {
      return [];
    }
  }
  function onChangeAll() {
    // 전체 checkbox
    const allMenu = getAllMenuId();
    if (allMenu.length === menuAuth.length) {
      setMenuAuth([]);
    } else {
      setMenuAuth(allMenu);
    }
  }
  function onChangeMenu(menuItem) {
    // 대메뉴 checkbox
    const menuSubmenu = []; // 해당 메뉴ID와 자식ID들
    menuSubmenu.push(menuItem.menuId);
    if (Array.isArray(menuItem.menuList) && menuItem.menuList.length !== 0) {
      menuItem.menuList.forEach((submenuItem) => {
        menuSubmenu.push(submenuItem.menuId);
      });
    }

    if (menuAuth.find((item) => item === menuItem.menuId)) {
      const newMenuAuth = [];
      menuAuth.forEach((item) => {
        if (!menuSubmenu.find((menuId) => menuId === item)) {
          newMenuAuth.push(item);
        }
      });
      setMenuAuth(newMenuAuth);
    } else {
      setMenuAuth([...menuAuth, ...menuSubmenu]);
    }
  }
  function checkMenu(menuList, menuAuth) {
    // 중메뉴 삭제할 때, 해당 parent menuId도 같이 삭제해야되는지 판별
    let cnt = 0;
    menuList.forEach((item) => {
      if (menuAuth.find((menu) => item === menu)) {
        cnt = cnt + 1;
      }
    });
    if (cnt > 0) {
      return false;
    } else {
      return true;
    }
  }
  function onChangeSubmenu(menuItem, submenuItem) {
    // 중메뉴 checkbox
    const menuList = []; // 해당 parent메뉴에 속해있는 중메뉴 리스트
    if (Array.isArray(menuItem.menuList) && menuItem.menuList.length !== 0) {
      menuItem.menuList.forEach((submenuItem) => {
        menuList.push(submenuItem.menuId);
      });
    }

    if (menuAuth.find((menuId) => menuId === submenuItem.menuId)) {
      const newMenuAuth = menuAuth.filter(
        (menuId) => menuId !== submenuItem.menuId
      );
      if (checkMenu(menuList, newMenuAuth)) {
        const newMenu = newMenuAuth.filter(
          (menuId) => menuId !== menuItem.menuId
        );
        setMenuAuth(newMenu);
      } else {
        setMenuAuth(newMenuAuth);
      }
    } else {
      if (menuAuth.find((menuId) => menuId === menuItem.menuId)) {
        setMenuAuth([...menuAuth, submenuItem.menuId]);
      } else {
        setMenuAuth([...menuAuth, menuItem.menuId, submenuItem.menuId]);
      }
    }
  }

  useEffect(() => {
    const allChecked = getAllMenuId();
    if (allChecked.length === menuAuth.length) {
      setAllChecked(true);
    } else {
      setAllChecked(false);
    }
  }, [menuAuth]);

  return (
    <div id="sign-menuauth-component">
      {
        // 계정등록신청 진행 Confirm
        isSignConfirm.isOpen && (
          <Confirm
            isOpen={isSignConfirm}
            setIsOpen={setIsSignConfirm}
            active={signFunc}
          />
        )
      }
      {
        // 계정등록신청 실패 Confirm
        isFailAlert && (
          <Alert
            isOpen={isFailAlert}
            setIsOpen={setIsFailAlert}
            text="계정 등록 신청에 실패하였습니다. 다시 시도해주세요."
          />
        )
      }

      <div className="component-title">
        <div>메뉴 권한</div>
        <div className="menu-select-text">1개 이상의 메뉴를 선택해주세요</div>
      </div>

      <div className="component-main menuauth-container">
        {Array.isArray(targetMenu) && targetMenu.length !== 0 && (
          <>
            <div className="all-select-container">
              <input
                type="checkbox"
                id="all"
                onChange={onChangeAll}
                checked={allChecked}
              />
              <label htmlFor="all">전체</label>
            </div>
            {targetMenu.map((menuItem) => (
              <div key={menuItem.menuId} className="menu-container">
                <div className="menu-item">
                  <input
                    type="checkbox"
                    id={menuItem.menuId}
                    onChange={() => onChangeMenu(menuItem)}
                    checked={
                      !!menuAuth.find((item) => item === menuItem.menuId)
                    }
                  />
                  <label htmlFor={menuItem.menuId}>{menuItem.menuNm}</label>
                </div>
                {Array.isArray(menuItem.menuList) &&
                  menuItem.menuList.length !== 0 && (
                    <div className="submenu-item-container">
                      {menuItem.menuList.map((submenuItem) => (
                        <div key={submenuItem.menuId} className="submenu-item">
                          <input
                            type="checkbox"
                            id={submenuItem.menuId}
                            onChange={() =>
                              onChangeSubmenu(menuItem, submenuItem)
                            }
                            checked={
                              !!menuAuth.find(
                                (menuId) => menuId === submenuItem.menuId
                              )
                            }
                          />
                          <label htmlFor={submenuItem.menuId}>
                            {submenuItem.menuNm}
                          </label>
                        </div>
                      ))}
                    </div>
                  )}
              </div>
            ))}
          </>
        )}
      </div>

      <div className="button-container">
        <div className="button" onClick={() => setPage(2)}>
          <FontAwesomeIcon className="left-arrow" icon={faArrowLeft} />
          <div className="text">이전</div>
        </div>
        {Array.isArray(menuAuth) && menuAuth.length > 0 && (
          <div className="button-imp" onClick={onClickAccept}>
            신청
          </div>
        )}
      </div>
    </div>
  );
}

export default MenuAuth;
