import {
  getRsvLevel,
  getRsvGroundLevel,
  getRsvDisplacement,
  getRsvRain,
} from "../../services/internal/dataAPI";
import { getReservoirDevice } from "../../services/internal/rtuAPI";
import { getFromDtAndToDt } from "../data";
import { getCCTVAndStatus } from "../../services/internal/cctvAPI";
import {
  rainOverlay,
  levelOverlay,
  groundOverlay,
  disOverlay,
  cctvOverlay,
  brdOverlay,
} from "./overlay";
import RainMarkerImg from "../../assets/map/rainMarker.png";
import LevelMarkerImg from "../../assets/map/levelMarker.png";
import GroundMarkerImg from "../../assets/map/groundMarker.png";
import DisMarkerImg from "../../assets/map/disMarker.png";
import BrdMarkerImg from "../../assets/map/brdMarker.png";
import CctvMarkerImg from "../../assets/map/cctvMarker.png";
import { getMinColumn } from "../data";

const { kakao } = window;

/* 공통 */
function makeMarkerInform(type, item, src, event) {
  const imageSrc = src;
  const imageSize = new kakao.maps.Size(34, 40);
  const imageOption = { offset: new kakao.maps.Point(20, 28) };
  let overlayImg, clickOverlayImg;
  switch (type) {
    case "rain":
      overlayImg = rainOverlay(item, event);
      clickOverlayImg = rainOverlay(item, event);
      break;
    case "level":
      overlayImg = levelOverlay(item, event);
      clickOverlayImg = levelOverlay(item, event);
      break;
    case "ground":
      overlayImg = groundOverlay(item, event);
      clickOverlayImg = groundOverlay(item, event);
      break;
    case "displacement":
      overlayImg = disOverlay(item, event);
      clickOverlayImg = disOverlay(item, event);
      break;
    case "broad":
      overlayImg = brdOverlay(item);
      clickOverlayImg = brdOverlay(item);
      break;
    // case "edboard":
    //   overlayImg = edbOverlay(item, event);
    //   clickOverlayImg = edbOverlay(item, event);
    //   break;
    case "cctv":
      overlayImg = cctvOverlay(item, event);
      clickOverlayImg = cctvOverlay(item, event);
      break;
    default:
      break;
  }

  const markerImage = new kakao.maps.MarkerImage(
    imageSrc,
    imageSize,
    imageOption
  );
  const markerPosition = new kakao.maps.LatLng(item.lat, item.lon);

  const marker = new kakao.maps.Marker({
    position: markerPosition,
    image: markerImage,
  });
  const overlay = new kakao.maps.CustomOverlay({
    content: overlayImg,
    position: marker.getPosition(),
    yAnchor: 1.2,
  });
  const clickOverlay = new kakao.maps.CustomOverlay({
    clickable: true,
    content: clickOverlayImg,
    position: marker.getPosition(),
    yAnchor: 1.2,
  });

  return {
    eqId: item.eqId,
    marker: marker,
    overlay: overlay,
    clickOverlay: clickOverlay,
  };
}

/* 강우계 */
export function getRainMarkers(
  rsvId,
  map,
  mouseOver,
  mouseOut,
  mouseClick,
  snsrClick
) {
  const term = "min";
  const { fromDt, toDt } = getFromDtAndToDt(term, new Date());
  return getRsvRain(term, fromDt, toDt).then((rtuDvc) => {
    if (Array.isArray(rtuDvc) && rtuDvc.length > 0) {
      let filterArr;
      switch (rsvId) {
        case 1:
          filterArr = rtuDvc.filter((item) => item.eqId === 31);
          break;
        case 2:
          filterArr = rtuDvc.filter((item) => item.eqId === 38);
          break;
        case 3:
          filterArr = rtuDvc.filter((item) => item.eqId === 45);
          break;
        case 4:
          filterArr = rtuDvc.filter((item) => item.eqId === 52);
          break;
      }
      if (Array.isArray(filterArr) && filterArr.length > 0) {
        const formatData = filterArr.map((item) => {
          if (Array.isArray(item.data) && item.data.length > 0) {
            let dateColumn = getMinColumn(new Date());
            const dateData = dateColumn.map((dateItem) => {
              const check = item.data.find((item) => item.date === dateItem);
              const val =
                check !== null && check !== undefined
                  ? parseFloat(check.avgVal).toFixed(2)
                  : "-";
              return { date: dateItem, avgVal: val };
            });
            return { ...item, data: dateData };
          } else {
            return { ...item, data: [] };
          }
        });
        const markerInforms = formatData.map((rtuItem) => {
          const markerInform = makeMarkerInform(
            "rain",
            rtuItem,
            RainMarkerImg,
            snsrClick
          );
          // 마커 마우스 이벤트
          function over() {
            mouseOver(markerInform.overlay, markerInform.clickOverlay);
          }
          function out() {
            mouseOut(markerInform.overlay);
          }
          function click() {
            mouseClick(markerInform.overlay, markerInform.clickOverlay);
          }
          markerInform.marker.setMap(map);
          kakao.maps.event.addListener(markerInform.marker, "mouseover", over);
          kakao.maps.event.addListener(markerInform.marker, "mouseout", out);
          kakao.maps.event.addListener(markerInform.marker, "click", click);

          return markerInform;
        });
        return markerInforms;
      } else {
        return [];
      }
    } else {
      return [];
    }
  });
}
/* 수위계 */
export function getLevelMarkers(
  rsvId,
  map,
  mouseOver,
  mouseOut,
  mouseClick,
  snsrClick
) {
  const term = "min";
  const { fromDt, toDt } = getFromDtAndToDt(term, new Date());
  return getRsvLevel(term, fromDt, toDt).then((rtuDvc) => {
    if (Array.isArray(rtuDvc) && rtuDvc.length > 0) {
      let filterArr;
      switch (rsvId) {
        case 1:
          filterArr = rtuDvc.filter((item) => item.eqId === 26);
          break;
        case 2:
          filterArr = rtuDvc.filter((item) => item.eqId === 33);
          break;
        case 3:
          filterArr = rtuDvc.filter((item) => item.eqId === 40);
          break;
        case 4:
          filterArr = rtuDvc.filter((item) => item.eqId === 47);
          break;
      }
      if (Array.isArray(filterArr) && filterArr.length > 0) {
        const formatData = filterArr.map((item) => {
          if (Array.isArray(item.data) && item.data.length > 0) {
            let dateColumn = getMinColumn(new Date());
            const dateData = dateColumn.map((dateItem) => {
              const check = item.data.find((item) => item.date === dateItem);
              const val =
                check !== null && check !== undefined
                  ? parseFloat(check.avgVal).toFixed(2)
                  : "-";
              return { date: dateItem, avgVal: val };
            });
            return { ...item, data: dateData };
          } else {
            return { ...item, data: [] };
          }
        });
        const markerInforms = formatData.map((rtuItem) => {
          const markerInform = makeMarkerInform(
            "level",
            rtuItem,
            LevelMarkerImg,
            snsrClick
          );
          // 마커 마우스 이벤트
          function over() {
            mouseOver(markerInform.overlay, markerInform.clickOverlay);
          }
          function out() {
            mouseOut(markerInform.overlay);
          }
          function click() {
            mouseClick(markerInform.overlay, markerInform.clickOverlay);
          }
          markerInform.marker.setMap(map);
          kakao.maps.event.addListener(markerInform.marker, "mouseover", over);
          kakao.maps.event.addListener(markerInform.marker, "mouseout", out);
          kakao.maps.event.addListener(markerInform.marker, "click", click);

          return markerInform;
        });
        return markerInforms;
      } else {
        return [];
      }
    } else {
      return [];
    }
  });
}
/* 지하수위계 */
export function getGroundMarkers(
  rsvId,
  map,
  mouseOver,
  mouseOut,
  mouseClick,
  snsrClick
) {
  const term = "min";
  const { fromDt, toDt } = getFromDtAndToDt(term, new Date());
  return getRsvGroundLevel(term, fromDt, toDt).then((rtuDvc) => {
    if (Array.isArray(rtuDvc) && rtuDvc.length > 0) {
      let filterArr;
      switch (rsvId) {
        case 1:
          filterArr = rtuDvc.filter(
            (item) => item.eqId === 27 || item.eqId === 28
          );
          break;
        case 2:
          filterArr = rtuDvc.filter(
            (item) => item.eqId === 34 || item.eqId === 35
          );
          break;
        case 3:
          filterArr = rtuDvc.filter(
            (item) => item.eqId === 41 || item.eqId === 42
          );
          break;
        case 4:
          filterArr = rtuDvc.filter(
            (item) => item.eqId === 48 || item.eqId === 49
          );
          break;
      }
      if (Array.isArray(filterArr) && filterArr.length > 0) {
        const formatData = filterArr.map((item) => {
          if (Array.isArray(item.data) && item.data.length > 0) {
            let dateColumn = getMinColumn(new Date());
            const dateData = dateColumn.map((dateItem) => {
              const check = item.data.find((item) => item.date === dateItem);
              const val =
                check !== null && check !== undefined
                  ? parseFloat(check.avgVal).toFixed(2)
                  : "-";
              return { date: dateItem, avgVal: val };
            });
            return { ...item, data: dateData };
          } else {
            return { ...item, data: [] };
          }
        });
        const markerInforms = formatData.map((rtuItem) => {
          const markerInform = makeMarkerInform(
            "ground",
            rtuItem,
            GroundMarkerImg,
            snsrClick
          );
          // 마커 마우스 이벤트
          function over() {
            mouseOver(markerInform.overlay, markerInform.clickOverlay);
          }
          function out() {
            mouseOut(markerInform.overlay);
          }
          function click() {
            mouseClick(markerInform.overlay, markerInform.clickOverlay);
          }
          markerInform.marker.setMap(map);
          kakao.maps.event.addListener(markerInform.marker, "mouseover", over);
          kakao.maps.event.addListener(markerInform.marker, "mouseout", out);
          kakao.maps.event.addListener(markerInform.marker, "click", click);

          return markerInform;
        });
        return markerInforms;
      } else {
        return [];
      }
    } else {
      return [];
    }
  });
}
/* 변위계 */
export function getDisMarkers(
  rsvId,
  map,
  mouseOver,
  mouseOut,
  mouseClick,
  snsrClick
) {
  const term = "min";
  const { fromDt, toDt } = getFromDtAndToDt(term, new Date());
  return getRsvDisplacement(term, fromDt, toDt).then((rtuDvc) => {
    if (Array.isArray(rtuDvc) && rtuDvc.length > 0) {
      let filterArr;
      switch (rsvId) {
        case 1:
          filterArr = rtuDvc.filter(
            (item) => item.eqId === 29 || item.eqId === 30
          );
          break;
        case 2:
          filterArr = rtuDvc.filter(
            (item) => item.eqId === 36 || item.eqId === 37
          );
          break;
        case 3:
          filterArr = rtuDvc.filter(
            (item) => item.eqId === 43 || item.eqId === 44
          );
          break;
        case 4:
          filterArr = rtuDvc.filter(
            (item) => item.eqId === 50 || item.eqId === 51
          );
          break;
      }
      if (Array.isArray(filterArr) && filterArr.length > 0) {
        const formatData = filterArr.map((item) => {
          if (Array.isArray(item.data) && item.data.length > 0) {
            let dateColumn = getMinColumn(new Date());
            const dateData = dateColumn.map((dateItem) => {
              const check = item.data.find((item) => item.date === dateItem);
              const val =
                check !== null && check !== undefined
                  ? parseFloat(check.avgVal).toFixed(2)
                  : "-";
              return { date: dateItem, avgVal: val };
            });
            return { ...item, data: dateData };
          } else {
            return { ...item, data: [] };
          }
        });
        const markerInforms = formatData.map((rtuItem) => {
          const markerInform = makeMarkerInform(
            "displacement",
            rtuItem,
            DisMarkerImg,
            snsrClick
          );
          // 마커 마우스 이벤트
          function over() {
            mouseOver(markerInform.overlay, markerInform.clickOverlay);
          }
          function out() {
            mouseOut(markerInform.overlay);
          }
          function click() {
            mouseClick(markerInform.overlay, markerInform.clickOverlay);
          }
          markerInform.marker.setMap(map);
          kakao.maps.event.addListener(markerInform.marker, "mouseover", over);
          kakao.maps.event.addListener(markerInform.marker, "mouseout", out);
          kakao.maps.event.addListener(markerInform.marker, "click", click);

          return markerInform;
        });
        return markerInforms;
      } else {
        return [];
      }
    } else {
      return [];
    }
  });
}
// /* 경사계 */
// export function getSlopeMarkers(
//   map,
//   mouseOver,
//   mouseOut,
//   mouseClick,
//   snsrClick
// ) {
//   const date = getCurrTime().date;
//   const today =
//     zeroPlusNum(getCurrTime().year) +
//     zeroPlusNum(getCurrTime().month) +
//     zeroPlusNum(getCurrTime().day);
//   const fromDt = today + "000000";
//   const toDt = today + "235959";
//   return getSlopeSnsr().then((rtuDvc) => {
//     if (rtuDvc.length > 0) {
//       const promise = rtuDvc.map((rtuItem) => {
//         const dvcXItem = rtuItem.dvcData[0];
//         const dvcYItem = rtuItem.dvcData[1];
//         return getDataUpdate("min", fromDt, toDt, dvcXItem).then((xData) => {
//           return getDataUpdate("min", fromDt, toDt, dvcYItem).then((yData) => {
//             if (
//               Array.isArray(xData) &&
//               Array.isArray(yData) &&
//               (xData.length > 0 || yData.length > 0)
//             ) {
//               let dateColumn = getMinColumn(date);
//               const dateData = dateColumn.map((dateItem) => {
//                 const xCheck = xData.find((item) => item.date === dateItem);
//                 const yCheck = yData.find((item) => item.date === dateItem);
//                 const xVal = xCheck
//                   ? parseFloat(xCheck.avgVal).toFixed(2)
//                   : "-";
//                 const yVal = yCheck
//                   ? parseFloat(yCheck.avgVal).toFixed(2)
//                   : "-";
//                 return { date: dateItem, x: xVal, y: yVal };
//               });
//               return { ...rtuItem, data: dateData };
//             } else {
//               return { ...rtuItem, data: [] };
//             }
//           });
//         });
//       });

//       return Promise.all(promise).then((promiseResult) => {
//         const markerInforms = promiseResult.map((rtuItem) => {
//           const markerInform = makeMarkerInform(
//             "slope",
//             rtuItem,
//             SlopeMarkerImg,
//             snsrClick
//           );

//           // 마커 마우스 이벤트
//           function over() {
//             mouseOver(markerInform.overlay, markerInform.clickOverlay);
//           }
//           function out() {
//             mouseOut(markerInform.overlay);
//           }
//           function click() {
//             mouseClick(markerInform.overlay, markerInform.clickOverlay);
//           }

//           markerInform.marker.setMap(map);
//           kakao.maps.event.addListener(markerInform.marker, "mouseover", over);
//           kakao.maps.event.addListener(markerInform.marker, "mouseout", out);
//           kakao.maps.event.addListener(markerInform.marker, "click", click);

//           return markerInform;
//         });
//         return markerInforms;
//       });
//     } else {
//       return [];
//     }
//   });
// }
// /* 변위계 */
// export function getDisMarkers(map, mouseOver, mouseOut, mouseClick, snsrClick) {
//   const date = getCurrTime().date;
//   const today =
//     zeroPlusNum(getCurrTime().year) +
//     zeroPlusNum(getCurrTime().month) +
//     zeroPlusNum(getCurrTime().day);
//   const fromDt = today + "000000";
//   const toDt = today + "235959";
//   return getDisSnsr().then((rtuDvc) => {
//     if (rtuDvc.length > 0) {
//       const promise = rtuDvc.map((rtuItem) => {
//         const dvcXItem = rtuItem.dvcData[0];
//         const dvcYItem = rtuItem.dvcData[1];
//         const dvcZItem = rtuItem.dvcData[2];
//         return getDataUpdate("min", fromDt, toDt, dvcXItem).then((xData) => {
//           return getDataUpdate("min", fromDt, toDt, dvcYItem).then((yData) => {
//             return getDataUpdate("min", fromDt, toDt, dvcZItem).then(
//               (zData) => {
//                 if (
//                   Array.isArray(xData) &&
//                   Array.isArray(yData) &&
//                   Array.isArray(zData) &&
//                   (xData.length > 0 || yData.length > 0 || zData.length > 0)
//                 ) {
//                   let dateColumn = getMinColumn(date);
//                   const dateData = dateColumn.map((dateItem) => {
//                     const xCheck = xData.find((item) => item.date === dateItem);
//                     const yCheck = yData.find((item) => item.date === dateItem);
//                     const zCheck = zData.find((item) => item.date === dateItem);
//                     const xVal = xCheck
//                       ? parseFloat(xCheck.avgVal).toFixed(2)
//                       : "-";
//                     const yVal = yCheck
//                       ? parseFloat(yCheck.avgVal).toFixed(2)
//                       : "-";
//                     const zVal = zCheck
//                       ? parseFloat(zCheck.avgVal).toFixed(2)
//                       : "-";
//                     return { date: dateItem, x: xVal, y: yVal, mm: zVal };
//                   });
//                   return { ...rtuItem, data: dateData };
//                 } else {
//                   return { ...rtuItem, data: [] };
//                 }
//               }
//             );
//           });
//         });
//       });

//       return Promise.all(promise).then((promiseResult) => {
//         const markerInforms = promiseResult.map((rtuItem) => {
//           const markerInform = makeMarkerInform(
//             "displacement",
//             rtuItem,
//             DisMarkerImg,
//             snsrClick
//           );

//           // 마커 마우스 이벤트
//           function over() {
//             mouseOver(markerInform.overlay, markerInform.clickOverlay);
//           }
//           function out() {
//             mouseOut(markerInform.overlay);
//           }
//           function click() {
//             mouseClick(markerInform.overlay, markerInform.clickOverlay);
//           }

//           markerInform.marker.setMap(map);
//           kakao.maps.event.addListener(markerInform.marker, "mouseover", over);
//           kakao.maps.event.addListener(markerInform.marker, "mouseout", out);
//           kakao.maps.event.addListener(markerInform.marker, "click", click);

//           return markerInform;
//         });
//         return markerInforms;
//       });
//     } else {
//       return [];
//     }
//   });
// }
// /* 전광판 */
// export function getEdbMarkers(map, mouseOver, mouseOut, mouseClick, edbClick) {
//   return getEdboardMessage().then((edbData) => {
//     if (Array.isArray(edbData) && edbData.length > 0) {
//       const markers = [];
//       edbData.forEach((item) => {
//         const markerInform = makeMarkerInform(
//           "edboard",
//           item,
//           EdbMarkerImg,
//           edbClick
//         );

//         // 마커 마우스 이벤트
//         function over() {
//           mouseOver(markerInform.overlay, markerInform.clickOverlay);
//         }
//         function out() {
//           mouseOut(markerInform.overlay);
//         }
//         function click() {
//           mouseClick(markerInform.overlay, markerInform.clickOverlay);
//         }

//         markerInform.marker.setMap(map);
//         kakao.maps.event.addListener(markerInform.marker, "mouseover", over);
//         kakao.maps.event.addListener(markerInform.marker, "mouseout", out);
//         kakao.maps.event.addListener(markerInform.marker, "click", click);

//         markers.push(markerInform);
//       });
//       return markers;
//     }
//     return [];
//   });
// }

/* 방송장비 */
export function getBroadMarkers(rsvId, map, mouseOver, mouseOut, mouseClick) {
  return getReservoirDevice().then((device) => {
    if (Array.isArray(device) && device.length > 0) {
      let filterArr;
      switch (rsvId) {
        case 1:
          filterArr = device.filter((item) => item.eqId === 32);
          break;
        case 2:
          filterArr = device.filter((item) => item.eqId === 39);
          break;
        case 3:
          filterArr = device.filter((item) => item.eqId === 46);
          break;
        case 4:
          filterArr = device.filter((item) => item.eqId === 53);
          break;
      }
      if (Array.isArray(filterArr) && filterArr.length > 0) {
        const markers = [];
        filterArr.forEach((item) => {
          const markerInform = makeMarkerInform("broad", item, BrdMarkerImg);
          // 마커 마우스 이벤트
          function over() {
            mouseOver(markerInform.overlay, markerInform.clickOverlay);
          }
          function out() {
            mouseOut(markerInform.overlay);
          }
          function click() {
            mouseClick(markerInform.overlay, markerInform.clickOverlay);
          }
          markerInform.marker.setMap(map);
          kakao.maps.event.addListener(markerInform.marker, "mouseover", over);
          kakao.maps.event.addListener(markerInform.marker, "mouseout", out);
          kakao.maps.event.addListener(markerInform.marker, "click", click);
          markers.push(markerInform);
        });
        return markers;
      } else {
        return [];
      }
    } else {
      return [];
    }
  });
}
/* CCTV */
export function getCCTVMarkers(
  rsvId,
  map,
  mouseOver,
  mouseOut,
  mouseClick,
  cctvClick
) {
  return getCCTVAndStatus().then((cctvData) => {
    if (Array.isArray(cctvData) && cctvData.length > 0) {
      let filterArr;
      switch (rsvId) {
        case 1:
          filterArr = cctvData.filter(
            (item) => item.eqId === 54 || item.eqId === 55
          );
          break;
        case 2:
          filterArr = cctvData.filter(
            (item) => item.eqId === 56 || item.eqId === 57
          );
          break;
        case 3:
          filterArr = cctvData.filter(
            (item) => item.eqId === 58 || item.eqId === 59
          );
          break;
        case 4:
          filterArr = cctvData.filter(
            (item) => item.eqId === 60 || item.eqId === 61
          );
          break;
      }
      if (Array.isArray(filterArr) && filterArr.length > 0) {
        const markers = [];
        filterArr.forEach((item) => {
          const markerInform = makeMarkerInform(
            "cctv",
            item,
            CctvMarkerImg,
            cctvClick
          );

          // 마커 마우스 이벤트
          function over() {
            mouseOver(markerInform.overlay, markerInform.clickOverlay);
          }
          function out() {
            mouseOut(markerInform.overlay);
          }
          function click() {
            mouseClick(markerInform.overlay, markerInform.clickOverlay);
          }

          markerInform.marker.setMap(map);
          kakao.maps.event.addListener(markerInform.marker, "mouseover", over);
          kakao.maps.event.addListener(markerInform.marker, "mouseout", out);
          kakao.maps.event.addListener(markerInform.marker, "click", click);

          markers.push(markerInform);
        });
        return markers;
      } else {
        return [];
      }
    }

    return [];
  });
}
