import apiAddress from "../../services/apiAddress";
import axios from "axios";

/* SMS 전송 */
/*
    {
    "success_cnt": 1,
    "msg_type": "SMS",
    "result_code": "1",
    "error_cnt": 0,
    "message": "success",
    "msg_id": "789567167"
    }
*/
export function sendSMS(receiver, msg, setIsOk) {
  const tokenInform = localStorage.getItem("tokenInform");
  const tokenInformParse = JSON.parse(tokenInform);
  const siteId = tokenInformParse.siteId;

  axios
    .post(
      apiAddress.smsSend,
      {
        siteId: siteId,
        receiver: receiver,
        msg: msg,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      if (response.data.message) {
        setIsOk(true);
      }
    });
}

/* SMS 내역 */
/*
{
    "next_yn": "N",
    "result_code": 1,
    "message": "success",
    "list": [
        {
            "msg": "테스트 메시지 입니다.",
            "reg_date": "2024-04-30 08:37:06",
            "sender": "01089726737",
            "sms_count": "1",
            "reserve": "",
            "mid": "789567167",
            "reserve_state": "",
            "type": "SMS",
            "fail_count": 0
        }
    ]
}
*/
export function getSMS(page, pageSize, startDate, endDate) {
  const tokenInform = localStorage.getItem("tokenInform");
  const tokenInformParse = JSON.parse(tokenInform);
  const siteId = tokenInformParse.siteId;

  return axios
    .post(
      apiAddress.smsList + "?page=" + page + "&size=" + pageSize,
      {
        siteId: siteId,
        startDate: startDate,
        endDate: endDate,
        sortBy: "sendDt",
        sortDir: "desc",
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      return response.data;
    });
}

/* SMS 상세내역 */
/*
{
    "next_yn": "N",
    "result_code": 1,
    "message": "success",
    "list": [
        {
            "mdid": "7008023727",
            "reg_date": "2024-04-30 08:37:06",
            "receiver": "01099908533",
            "sender": "01089726737",
            "send_date": "2024-04-30 08:37:06",
            "sms_state": "발송완료",
            "type": "SMS",
            "reserve_date": ""
        }
    ]
}
*/
export function getDetailSMS(page, pageSize, mid) {
  return axios
    .post(
      apiAddress.smsDetailList,
      {
        page: page,
        page_size: pageSize,
        mid: mid,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      return response.data;
    });
}

/* SMS 수신자 (공통코드) */
export function getReceiverSMS() {
  return axios
    .post(
      apiAddress.smsReceiver,
      {
        groupCode: "SMS",
        commonRef5: "SMS01",
        orderBy: "commonRef6",
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      return response.data;
    });
}
