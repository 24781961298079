import { useState, useEffect } from "react";
import {
  createColumnHelper,
  flexRender,
  useReactTable,
  getCoreRowModel,
} from "@tanstack/react-table";

function ReceiverList({ receiverData, receiver, setReceiver }) {
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);
  const columnHelper = createColumnHelper();
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });
  const checkboxColumn = {
    id: "checkbox",
    accessor: "checkbox",
    header: "",
    size: 20,
    enableSorting: false,
    cell: ({ row }) => (
      <input
        type="checkbox"
        onChange={() => onChangeCheck(row)}
        checked={receiver.find((item) => item === row.original)}
        style={{ cursor: "pointer" }}
      />
    ),
  };

  function onChangeCheck(row) {
    if (receiver.find((item) => item === row.original)) {
      // 체크 비활성화
      const newReceiver = receiver.filter((item) => item !== row.original);
      setReceiver(newReceiver);
    } else {
      // 체크 활성화
      setReceiver([...receiver, row.original]);
    }
  }

  useEffect(() => {
    if (receiverData !== undefined && receiverData.length !== 0) {
      const column = [
        checkboxColumn,
        columnHelper.accessor("commonRef6", { header: "이름" }),
        columnHelper.accessor("commonRef7", { header: "번호" }),
      ];

      setData(receiverData);
      setColumns(column);
    } else {
      setData([]);
      setColumns([]);
    }
  }, [receiverData, receiver]);

  return (
    <div id="sms-trans-receiver-component">
      {data.length === 0 ? (
        <div className="none-data-container">SMS발송 대상자가 없습니다</div>
      ) : (
        <table className="react_table">
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th
                    key={header.id}
                    style={{
                      width: isNaN(header.getSize())
                        ? "auto"
                        : `${header.getSize()}px`,
                    }}
                  >
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row, index) => (
              <tr
                key={row.id}
                className={
                  index % 2 === 0 ? "tr_default-even" : "tr_default-odd"
                }
              >
                {row.getVisibleCells().map((cell) => (
                  <td
                    key={cell.id}
                    style={{
                      width: isNaN(cell.column.getSize())
                        ? "auto"
                        : `${cell.column.getSize()}px`,
                      textAlign: cell.column.id === "checkbox" && "center",
                    }}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
}

export default ReceiverList;
