import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { setMenu, setSubmenu } from "../../../redux/modules/menu";
import { useNavigate } from "react-router-dom";
import axios from "axios";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome, faList } from "@fortawesome/free-solid-svg-icons";

import { tokenCheck } from "../../../utils/tokenCheck";
import apiAddress from "../../../services/apiAddress";
import Delete from "../../../components/common/window/Delete";
import GroupAdd from "../../../components/setting/broad-group/GroupAdd";
import Group from "../../../components/setting/broad-group/Group";
import Broad from "../../../components/setting/broad-group/Broad";
import BroadReg from "../../../components/setting/broad-group/BroadReg";

const PAGE_VALUE = "설정";
const SUBPAGE_VALUE = "방송그룹";

function SettingBroadGroup() {
  const dispatch = useDispatch();
  const [isToken, setIsToken] = useState(false);
  const movePage = useNavigate();
  const [isAdd, setIsAdd] = useState(false);
  const [isAddOk, setIsAddOk] = useState(false);
  const [selectedGroupId, setSelectedGroupId] = useState(0);

  const [isReg, setIsReg] = useState(false);
  const [isDel, setIsDel] = useState({
    isOpen: false,
    text: "",
  });
  const [isRegOk, setIsRegOk] = useState(false);
  const [isDelOk, setIsDelOk] = useState(false);
  const [delData, setDelData] = useState([]);

  function onClickDelete() {
    // 등록된 장비 삭제 API.
    // 토큰 확인
    tokenCheck().then((result) => {
      if (!result) {
        alert("인증 토큰이 만료되었습니다. 다시 로그인 해주세요");
        movePage("/");
      } else {
        setIsDel({
          isOpen: true,
          text: delData.length + "개의 장비를 삭제하시겠습니까?",
        });
      }
    });
  }

  /* 그룹-장비 관계 삭제 API */
  async function fetchData() {
    try {
      for (let i = 0; i < delData.length; i++) {
        axios
          .delete(apiAddress.groupEquipDelete + delData[i], {
            headers: {
              "Content-Type": "application/json",
            },
          })
          .then((response) => {
            if (i + 1 === delData.length) {
              setIsDelOk(!isDelOk);
              setDelData([]);
            }
          });
      }
    } catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    dispatch(setMenu(PAGE_VALUE));
    dispatch(setSubmenu(SUBPAGE_VALUE));

    // 토큰 확인
    tokenCheck().then((result) => {
      if (!result) {
        alert("인증 토큰이 만료되었습니다. 다시 로그인 해주세요");
        movePage("/");
      } else {
        setIsToken(true);
      }
    });
  }, []);
  useEffect(() => {
    setDelData([]);
  }, [selectedGroupId]);

  return (
    <div className="outlet_container">
      {isAdd && (
        <GroupAdd
          isOpen={isAdd}
          setIsOpen={setIsAdd}
          isOk={isAddOk}
          setIsOk={setIsAddOk}
        />
      )}
      {isReg && (
        <BroadReg
          selectedId={selectedGroupId}
          isOpen={isReg}
          setIsOpen={setIsReg}
          isOk={isRegOk}
          setIsOk={setIsRegOk}
        />
      )}
      {isDel.isOpen && (
        <Delete isOpen={isDel} setIsOpen={setIsDel} delData={fetchData} />
      )}
      {isToken && (
        <>
          <div className="header_container">
            <div className="title">
              <div className="title_imp">방송 그룹</div>
              <div className="title_nor">설정</div>
            </div>
            <div className="path">
              <FontAwesomeIcon icon={faHome} />
              <div className="text">&gt; 설정 &gt; 방송그룹</div>
            </div>
          </div>

          <div className="main_container_row">
            {/* 방송그룹 리스트 */}
            <div
              className="contents_container"
              style={{
                width: "calc(35% - 10px)",
                height: "100%",
                marginRight: "10px",
              }}
            >
              <div className="contents_header">
                <div className="title">
                  <FontAwesomeIcon icon={faList} />
                  <div className="text">방송그룹</div>
                  <div
                    className="button_imp"
                    onClick={() => {
                      // 토큰 확인
                      tokenCheck().then((result) => {
                        if (!result) {
                          alert(
                            "인증 토큰이 만료되었습니다. 다시 로그인 해주세요"
                          );
                          movePage("/");
                        } else {
                          setIsAdd(true);
                        }
                      });
                    }}
                    style={{ marginLeft: "20px" }}
                  >
                    그룹 추가
                  </div>
                </div>
              </div>

              <div className="contents_main">
                <Group
                  isAddOk={isAddOk}
                  selectedId={selectedGroupId}
                  setSelectedId={setSelectedGroupId}
                />
              </div>

              <div className="contents_footer"></div>
            </div>

            {/* RTU */}
            <div
              className="contents_container"
              style={{
                width: "calc(65% - 10px)",
                height: "100%",
                marginLeft: "10px",
              }}
            >
              <div className="contents_header">
                <div className="title">
                  <FontAwesomeIcon icon={faList} />
                  <div className="text">방송장비</div>
                  {selectedGroupId !== 0 && (
                    <div
                      className="button_imp"
                      onClick={() => {
                        // 토큰 확인
                        tokenCheck().then((result) => {
                          if (!result) {
                            alert(
                              "인증 토큰이 만료되었습니다. 다시 로그인 해주세요"
                            );
                            movePage("/");
                          } else {
                            setIsReg(true);
                          }
                        });
                      }}
                      style={{ marginLeft: "20px" }}
                    >
                      추가 등록
                    </div>
                  )}
                </div>
                <div className="search">
                  {delData.length > 0 && (
                    <div className="button_del" onClick={onClickDelete}>
                      삭제
                    </div>
                  )}
                </div>
              </div>

              <div className="contents_main">
                {selectedGroupId !== 0 && (
                  <Broad
                    selectedId={selectedGroupId}
                    isRegOk={isRegOk}
                    isDelOk={isDelOk}
                    delData={delData}
                    setDelData={setDelData}
                  />
                )}
              </div>

              <div className="contents_footer"></div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default SettingBroadGroup;
