import { useState, useEffect } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocation } from "@fortawesome/free-solid-svg-icons";

import { getForecastInform } from "../../../services/external/kmaAPI";
import { location } from "../../../constants/latlon";
import { getIconAndText } from "../../../utils/kma";
import loadingImg from "../../../assets/common/loading.gif";

function Forecast() {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);

  function fetchFore() {
    /* 기상예보 조회 API */
    setIsLoading(true);
    getForecastInform().then((foreInform) => {
      setIsLoading(false);
      if (Array.isArray(foreInform) && foreInform.length > 0) {
        setData(foreInform);
      } else {
        setData([]);
      }
    });
  }

  useEffect(() => {
    fetchFore();

    const intervalId = setInterval(() => {
      fetchFore();
    }, 1800000); // 30분
    return () => clearInterval(intervalId);
  }, []);

  return (
    <div id="allstatus-fore-component">
      {isLoading ? (
        <div className="loading-container">
          <img src={loadingImg} alt="" />
        </div>
      ) : !Array.isArray(data) || data.length <= 0 ? (
        <div className="data-none-container" style={{ fontSize: "0.8rem" }}>
          자료 조회에 실패하였습니다
        </div>
      ) : (
        <>
          {/* 위치 & 발표시각 */}
          <div className="location-time-container">
            <div className="location-container">
              <FontAwesomeIcon className="location-item" icon={faLocation} />
              <div className="location-item">{location.locationNm}</div>
            </div>
            <div className="time-container">
              <div className="time-container">{data[0].data[0].baseDate}</div>
              <div className="time-container">{data[0].data[0].baseTime}</div>
            </div>
          </div>

          <div className="fore-contents">
            {data.map((item) => (
              <div key={item.time} className="fore-item">
                <div className="time">{`${item.time}시`}</div>
                <img
                  src={
                    getIconAndText(
                      item.data.find((dataItem) => dataItem.category === "SKY")
                        ?.fcstValue,
                      item.data.find((dataItem) => dataItem.category === "PTY")
                        ?.fcstValue
                    ).icon
                  }
                  alt=""
                />
                <div className="temp">
                  {`${
                    item.data.find((dataItem) => dataItem.category === "TMP")
                      ?.fcstValue
                  }°C`}
                </div>
                <div className="rain">
                  <div className="rain-title">강수확률</div>
                  <div className="rain-value">
                    {`${
                      item.data.find((dataItem) => dataItem.category === "POP")
                        ?.fcstValue
                    }%`}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
}

export default Forecast;
