import { token } from "../services/internal/loginAPI";

export function tokenCheck() {
  const tokenInform = localStorage.getItem("tokenInform");
  const promiseToken = [];
  promiseToken.push(token(tokenInform));
  return Promise.all(promiseToken).then((result) => {
    const tokenInformParse = JSON.parse(tokenInform);
    if (tokenInformParse) {
      if (result[0] !== undefined) {
        const newTokenInform = { ...tokenInformParse, refreshToken: result[0] };
        localStorage.setItem("tokenInform", JSON.stringify(newTokenInform));
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  });
}
