import { useState, useEffect } from "react";
import Modal from "react-modal";
import axios from "axios";

import apiAddress from "../../../../services/apiAddress";

Modal.setAppElement("#root");

const dvcTypes = [
  { value: "SNSR", text: "센서" },
  { value: "BRD", text: "방송장비" },
];
const snsrTypes = [
  { value: "01", text: "강우량계" },
  { value: "02", text: "수위계" },
  { value: "03", text: "변위계" },
  { value: "04", text: "토양함수비" },
  { value: "05", text: "거리측정기" },
  { value: "06", text: "적설계" },
  { value: "07", text: "지하수위계" },
  { value: "08", text: "경사계" },
  { value: "09", text: "간극수압계" },
  { value: "10", text: "진동계/진동가속도계" },
  { value: "11", text: "지중경사계" },
  { value: "12", text: "하중계" },
  { value: "13", text: "구조물경사계" },
  { value: "14", text: "GNSS(GPS)" },
  { value: "15", text: "지표변위계" },
  { value: "16", text: "조위계" },
  { value: "17", text: "균열감지기" },
  { value: "18", text: "온도계" },
  { value: "19", text: "습도계" },
  { value: "20", text: "침수감지기" },
  { value: "21", text: "가속도계" },
  { value: "22", text: "변형률계" },
  { value: "23", text: "풍향계" },
  { value: "24", text: "유량계" },
  { value: "25", text: "유속계" },
];

function SensorInform({ isOpen, setIsOpen }) {
  const [data, setData] = useState(isOpen.data);
  const [brdData, setBrdData] = useState([]);

  function onClickClose() {
    // 닫기.
    setIsOpen({
      isOpen: false,
      data: {},
    });
  }

  /* 방송문안 조회 API */
  async function fetchBrd() {
    try {
      axios
        .post(
          apiAddress.broadTextGet,
          {
            brdCntnId: 0,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          const responseData = response.data.filter(
            (item) => item.useYn === "Y"
          );
          setBrdData(responseData);
        });
    } catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    fetchBrd();
  }, []);

  return (
    <Modal isOpen={isOpen.isOpen} style={modalStyles}>
      <div className="modal_title">
        <div>장비 정보</div>
      </div>
      <div className="modal_container">
        {/* Main */}
        <div
          className="modal_main_row"
          style={{ justifyContent: "space-between", border: "none" }}
        >
          {/* 센서 정보 */}
          <div className="setting_alert_snsr_info_container">
            <div className="input_container">
              <div className="title">장비명</div>
              <input
                className="input"
                value={data.info.dvcNm}
                disabled={true}
              />
            </div>
            <div className="input_container">
              <div className="title">장비종류</div>
              <input
                className="inner_input"
                value={
                  dvcTypes.find((item) => item.value === data.info.dvcType)
                    ?.text
                }
                disabled={true}
              />
              <div className="inner_title">센서타입</div>
              <input
                className="inner_input"
                value={
                  snsrTypes.find((item) => item.value === data.info.dvcDtlType)
                    ?.text
                }
                disabled={true}
              />
            </div>
            <div className="input_container">
              <div className="title">설치일자</div>
              <input
                className="inner_input"
                value={data.info.instlDt}
                disabled={true}
              />
              <div className="inner_title">제조업체</div>
              <input
                className="inner_input"
                value={data.info.mnfct}
                disabled={true}
              />
            </div>
            <div className="input_container">
              <div className="title">모델명</div>
              <input
                className="input"
                value={data.info.modelNm}
                disabled={true}
              />
            </div>
            <div className="input_container">
              <div className="title">
                {data.info.dvcDtlType === "08" ||
                data.info.dvcDtlType === "11" ||
                data.info.dvcDtlType === "13" ||
                data.info.dvcDtlType === "14"
                  ? "x축 최소"
                  : "최소"}
              </div>
              <input
                className="inner_input"
                value={data.info.min}
                disabled={true}
              />
              <div className="inner_title">
                {data.info.dvcDtlType === "08" ||
                data.info.dvcDtlType === "11" ||
                data.info.dvcDtlType === "13" ||
                data.info.dvcDtlType === "14"
                  ? "x축 최대"
                  : "최대"}
              </div>
              <input
                className="inner_input"
                value={data.info.max}
                disabled={true}
              />
            </div>
            {(data.info.dvcDtlType === "08" ||
              data.info.dvcDtlType === "11" ||
              data.info.dvcDtlType === "13" ||
              data.info.dvcDtlType === "14") && (
              <div className="input_container">
                <div className="title">y축 최소</div>
                <input
                  className="inner_input"
                  value={data.info.min2}
                  disabled={true}
                />
                <div className="inner_title">y축 최대</div>
                <input
                  className="inner_input"
                  value={data.info.max2}
                  disabled={true}
                />
              </div>
            )}
            {data.info.dvcDtlType === "14" && (
              <div className="input_container">
                <div className="title">z축 최소</div>
                <input
                  className="inner_input"
                  value={data.info.min3}
                  disabled={true}
                />
                <div className="inner_title">z축 최대</div>
                <input
                  className="inner_input"
                  value={data.info.max3}
                  disabled={true}
                />
              </div>
            )}
            <div
              className="input_container"
              style={{ flex: "1", borderBottom: "none" }}
            >
              <div className="title">메모</div>
              <textarea value={data.info.dvcDtl} disabled={true} />
            </div>
          </div>

          {/* 임계치 및 방송 정보 */}
          <div className="setting_alert_snsr_alert_container">
            <div className="input_container">
              <div className="title">주의보</div>
              <div className="not-title">
                <div className="sub_title">
                  <div className="left-title">최소</div>
                  <div className="right-title">최대</div>
                </div>

                <div className="input_container">
                  <input
                    className="left-input"
                    value={data.warningMin}
                    disabled={true}
                  />
                  <input
                    className="right-input"
                    value={data.warningMax}
                    disabled={true}
                  />
                </div>
                {(data.info.dvcDtlType === "08" ||
                  data.info.dvcDtlType === "11" ||
                  data.info.dvcDtlType === "13" ||
                  data.info.dvcDtlType === "14") && (
                  <div className="input_container">
                    <input
                      className="left-input"
                      value={data.warningMin2}
                      disabled={true}
                    />
                    <input
                      className="right-input"
                      value={data.warningMax2}
                      disabled={true}
                    />
                  </div>
                )}
                {data.info.dvcDtlType === "14" && (
                  <div className="input_container">
                    <input
                      className="left-input"
                      value={data.warningMin3}
                      disabled={true}
                    />
                    <input
                      className="right-input"
                      value={data.warningMax3}
                      disabled={true}
                    />
                  </div>
                )}

                <div className="brd_container">
                  <div className="title">방송</div>
                  <input
                    value={
                      brdData.find(
                        (item) => item.brdCntnId === data.warningBrdCntnId
                      )?.brdTitle
                    }
                    disabled={true}
                  />
                </div>
              </div>
            </div>

            <div className="input_container">
              <div className="title">경보</div>
              <div className="not-title">
                <div className="sub_title">
                  <div className="left-title">최소</div>
                  <div className="right-title">최대</div>
                </div>

                <div className="input_container">
                  <input
                    className="left-input"
                    value={data.alertMin}
                    disabled={true}
                  />
                  <input
                    className="right-input"
                    value={data.alertMax}
                    disabled={true}
                  />
                </div>
                {(data.info.dvcDtlType === "08" ||
                  data.info.dvcDtlType === "11" ||
                  data.info.dvcDtlType === "13" ||
                  data.info.dvcDtlType === "14") && (
                  <div className="input_container">
                    <input
                      className="left-input"
                      value={data.alertMin2}
                      disabled={true}
                    />
                    <input
                      className="right-input"
                      value={data.alertMax2}
                      disabled={true}
                    />
                  </div>
                )}
                {data.info.dvcDtlType === "14" && (
                  <div className="input_container">
                    <input
                      className="left-input"
                      value={data.alertMin3}
                      disabled={true}
                    />
                    <input
                      className="right-input"
                      value={data.alertMax3}
                      disabled={true}
                    />
                  </div>
                )}

                <div className="brd_container">
                  <div className="title">방송</div>
                  <input
                    value={
                      brdData.find(
                        (item) => item.brdCntnId === data.alertBrdCntnId
                      )?.brdTitle
                    }
                    disabled={true}
                  />
                </div>
              </div>
            </div>

            <div className="input_container" style={{ borderBottom: "none" }}>
              <div className="title">대피</div>

              <div className="not-title">
                <div className="sub_title">
                  <div className="left-title">최소</div>
                  <div className="right-title">최대</div>
                </div>

                <div className="input_container">
                  <input
                    className="left-input"
                    value={data.evctnMin}
                    disabled={true}
                  />
                  <input
                    className="right-input"
                    value={data.evctnMax}
                    disabled={true}
                  />
                </div>
                {(data.info.dvcDtlType === "08" ||
                  data.info.dvcDtlType === "11" ||
                  data.info.dvcDtlType === "13" ||
                  data.info.dvcDtlType === "14") && (
                  <div className="input_container">
                    <input
                      className="left-input"
                      value={data.evctnMin2}
                      disabled={true}
                    />
                    <input
                      className="right-input"
                      value={data.evctnMax2}
                      disabled={true}
                    />
                  </div>
                )}
                {data.info.dvcDtlType === "14" && (
                  <div className="input_container">
                    <input
                      className="left-input"
                      value={data.evctnMin3}
                      disabled={true}
                    />
                    <input
                      className="right-input"
                      value={data.evctnMax3}
                      disabled={true}
                    />
                  </div>
                )}

                <div className="brd_container">
                  <div className="title">방송</div>
                  <input
                    value={
                      brdData.find(
                        (item) => item.brdCntnId === data.evctnBrdCntnId
                      )?.brdTitle
                    }
                    disabled={true}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* footer */}
        <div className="modal_footer">
          <div className="button_nor" onClick={onClickClose}>
            닫기
          </div>
        </div>
      </div>
    </Modal>
  );
}

const modalStyles = {
  content: {
    width: "1100px",
    height: "600px",
    padding: "0px",
    background: "#202020",
    border: "none",
    borderRadius: "5px",
    boxShadow: "-5px 5px 10px rgba(0, 0, 0, 0.7)",
    margin: "auto",
  },
  overlay: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "fixed",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: "rgba(0, 0, 0, 0.4)",
  },
};

export default SensorInform;
