import apiAddress from "../../services/apiAddress";
import axios from "axios";

import { getDevice } from "./deviceAPI";

export function getRtu() {
  return axios
    .post(
      apiAddress.equipGet,
      {
        eqId: 0,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      const equip = response.data;
      if (equip.length !== 0) {
        const rtu = equip.filter(
          (item) => item.eqType === "RTU" && item.useYn === "Y"
        );
        const sortArray = rtu;
        sortArray.sort((a, b) => {
          return a.eqId - b.eqId;
        });
        return sortArray;
      } else {
        return [];
      }
    });
}

export function getRtuDevice() {
  return getRtu().then((rtu) => {
    if (rtu.length !== 0) {
      const promise = rtu.map((rtuItem) =>
        getDevice(rtuItem.eqId).then((dvc) => ({ ...rtuItem, dvcData: dvc }))
      );

      return Promise.all(promise);
    } else {
      return [];
    }
  });
}
