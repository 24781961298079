import React, { useState, useEffect, useRef } from "react";
import {
  ResponsiveContainer,
  ComposedChart,
  Line,
  Area,
  Bar,
  Legend,
  XAxis,
  YAxis,
  Tooltip,
  ReferenceLine,
  CartesianGrid,
} from "recharts";

import { DEVICEOPTIONS } from "../../../constants/deviceOptions";

function SensorGraph({ searchType, selectedData }) {
  const [rowData, setRowData] = useState({});
  const [data, setData] = useState([]);
  const [showData, setShowData] = useState([]);
  const [minMax, setMinMax] = useState([]);
  const [showMinMax, setShowMinMax] = useState([]);
  const [alertLine, setAlertLine] = useState([]);
  const [changeFromX, setChangeFromX] = useState(""); // XAxis domain
  const [changeToX, setChangeToX] = useState("");
  const chartRef = useRef(null); // YAxis domain
  const [changeFromY, setChangeFromY] = useState("");
  const [changeToY, setChangeToY] = useState("");
  const containerRef = useRef(null); // drag 영역
  const [dragRect, setDragRect] = useState(null);
  const startX = useRef(0);
  const startY = useRef(0);

  const rainColors = [
    "#5FBAE173", // 하늘
    "#CBDF5573", // 노랑
    "#FFC0CB73", // 분홍
    "#82CA9D73", // 초록
    "#8884D873", // 보라
  ];
  const levelColors = [
    "#425FD173", // 파랑
  ];
  const groundColors = [
    "#D1BC4273", // 노랑
    "#9F42D173", // 보라
    "#42D15873", // 초록
    "#D142BC73", // 분홍
  ];
  const disColors = [
    "#0B8A31", // 초록
    "#CA09D5", // 분홍
    "#4600B8", // 보라
    "#82C313", // 연두
    "#0010FF", // 파랑
    "#D1575A", // 연한빨강
    "#13C3BD", // 청록
  ];

  /* AlertLine Custom */
  const AlertCustomLabelUp = (props) => {
    const x = props.viewBox.x;
    const y = props.viewBox.y;
    const value = props.value;
    const color = props.color;
    return (
      <text
        x={x + 5}
        y={y - 5}
        textAnchor="start"
        fill={color}
        fontSize={10}
        fontWeight="bold"
      >
        {value}
      </text>
    );
  };
  const AlertCustomLabelDown = (props) => {
    const x = props.viewBox.x;
    const y = props.viewBox.y;
    const value = props.value;
    const color = props.color;
    return (
      <text
        x={x + 5}
        y={y + 12}
        textAnchor="start"
        fill={color}
        fontSize={10}
        fontWeight="bold"
      >
        {value}
      </text>
    );
  };
  /* Legend Custom */
  const CustomLegend = (props) => {
    const { payload } = props;

    return (
      <ul className="legend-container">
        {payload.map((entry, index) => {
          return (
            <li key={`item-${index}`} className="legend-item">
              <div
                className="item-color"
                style={{
                  background: entry.color,
                  border: `1px solid ${entry.color}`,
                }}
              />
              <div className="item-value">{entry.dataKey}</div>
            </li>
          );
        })}
      </ul>
    );
  };
  /* Tooltip Custom */
  const TooltipCustom = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      const date =
        payload[0].payload.date === undefined ? "" : payload[0].payload.date;

      return (
        <div className="tooltip-container">
          <div className="date-text">{date}</div>
          <div className="value-container">
            {payload.map((item) => (
              <div key={`value-item-${item.dataKey}`} className="value-item">
                <div
                  className="value-color"
                  style={{ background: item.color }}
                />
                <div className="value-title">{item.dataKey}:</div>
                <div className="value">{item.payload[item.dataKey]}</div>
              </div>
            ))}
          </div>
        </div>
      );
    }
  };
  // 임계치
  function updateAlertLine(dvcItem, unit) {
    const alert = {
      unit,
      min: null,
      min2: null,
      min3: null,
      min4: null,
      max: null,
      max2: null,
      max3: null,
      max4: null,
    };
    alert.min =
      dvcItem.min === DEVICEOPTIONS.sensorAnomaly ? null : dvcItem.min;
    alert.min2 =
      dvcItem.min2 === DEVICEOPTIONS.sensorAnomaly ? null : dvcItem.min2;
    alert.min3 =
      dvcItem.min3 === DEVICEOPTIONS.sensorAnomaly ? null : dvcItem.min3;
    alert.min4 =
      dvcItem.min4 === DEVICEOPTIONS.sensorAnomaly ? null : dvcItem.min4;
    alert.max =
      dvcItem.max === DEVICEOPTIONS.sensorAnomaly ? null : dvcItem.max;
    alert.max2 =
      dvcItem.max === DEVICEOPTIONS.sensorAnomaly ? null : dvcItem.max2;
    alert.max3 =
      dvcItem.max === DEVICEOPTIONS.sensorAnomaly ? null : dvcItem.max3;
    alert.max4 =
      dvcItem.max === DEVICEOPTIONS.sensorAnomaly ? null : dvcItem.max4;
    setAlertLine((prevAlertLine) => [...prevAlertLine, alert]);
  }

  /* String => Date */
  function stringToDateYearMonth(dateString) {
    return new Date(dateString);
  }
  function stringToDateDay(dateString) {
    const date = dateString.replace(" ", "T") + ":00";
    return date;
  }
  function stringToDateMin(dateString) {
    const date = dateString.replace(" ", "T");
    return date;
  }
  /* Date 비교 */
  function compareDate(from, to) {
    let fromX = from;
    let toX = to;
    switch (searchType) {
      case "year":
      case "month":
        fromX = stringToDateYearMonth(fromX);
        toX = stringToDateYearMonth(toX);
        break;
      case "day":
        fromX = stringToDateDay(fromX);
        toX = stringToDateDay(toX);
        break;
      default:
        fromX = stringToDateMin(fromX);
        toX = stringToDateMin(toX);
        break;
    }
    if (fromX > toX) {
      return false;
    }
    return true;
  }
  /* chartY => YAxis에 맞게 값 변환 */
  function chartToYAxis(value, min, max) {
    const val = Number(value);
    const chartRect = chartRef.current.container.getBoundingClientRect();
    const chartMin = 50;
    const chartMax = Number(chartRect.height) - 25;
    const yAxisMin = Number(min);
    const yAxisMax = Number(max);
    // 정규화
    const normalize = (chartMax - val) / (chartMax - chartMin);
    // 스케일링
    const tempValue = yAxisMin + normalize * (yAxisMax - yAxisMin);
    return parseFloat(tempValue).toFixed(2);
  }
  /* Zoom Event */
  function onMouseDownChart(e) {
    if (Object.keys(e).length !== 0) {
      setChangeFromX(e.activeLabel);
      setChangeFromY(e.chartY);
    }
  }
  function onMouseMoveChart(e) {
    if (Object.keys(e).length !== 0) {
      if (changeFromX !== "") {
        setChangeToX(e.activeLabel);
      }
      if (changeFromY !== "") {
        setChangeToY(e.chartY);
      }
    }
  }
  /* Drag Event */
  function onMouseDownComponent(e) {
    const rect = containerRef.current.getBoundingClientRect();
    const startXValue = e.clientX - rect.left; // 커서 위치
    const startYValue = e.clientY - rect.top;

    const xLimit = rect.width - 20;
    const yLimit = rect.height - 19;
    if (
      60 * minMax.length <= Number(startXValue) &&
      Number(startXValue) <= Number(xLimit) &&
      50 <= Number(startYValue) &&
      Number(startYValue) <= Number(yLimit)
    ) {
      startX.current = startXValue;
      startY.current = startYValue;
      setDragRect({
        left: startXValue,
        top: startYValue,
        width: 0,
        height: 0,
      });
    }
  }
  function onMouseMoveComponent(e) {
    if (dragRect) {
      const rect = containerRef.current.getBoundingClientRect();
      const currentX = e.clientX - rect.left; // 커서 위치
      const currentY = e.clientY - rect.top;

      const xLimit = rect.width - 20;
      const yLimit = rect.height - 19;
      if (
        60 * minMax.length <= Number(currentX) &&
        Number(currentX) <= Number(xLimit) &&
        50 <= Number(currentY) &&
        Number(currentY) <= Number(yLimit)
      ) {
        setDragRect({
          left: Math.min(currentX, startX.current),
          top: Math.min(currentY, startY.current),
          width: Math.abs(currentX - startX.current),
          height: Math.abs(currentY - startY.current),
        });
      }
    }
  }
  /* Zoom & Drag */
  function handleMouseUp() {
    // drag
    setDragRect(null);

    // zoom
    // XAxis
    let [fromX, toX] = [changeFromX, changeToX];
    if (!fromX || !toX || toX === "") {
      setChangeFromX("");
      setChangeToX("");
      return;
    }

    if (!compareDate(fromX, toX)) {
      [fromX, toX] = [toX, fromX];
    }
    let dataSlice = [...showData];
    switch (searchType) {
      case "year":
      case "month":
      case "day":
        const compareNumFrom = Number(fromX.slice(-2));
        const compareNumTo = Number(toX.slice(-2));
        dataSlice = dataSlice.filter((dataItem) => {
          const value = Number(dataItem.date.slice(-2));
          return compareNumFrom <= value && value <= compareNumTo;
        });
        setShowData(dataSlice);
        break;
      default:
        const compareNumFromHour = Number(fromX.slice(-5, -3));
        const compareNumFromMin = Number(fromX.slice(-2));
        const compareNumToHour = Number(toX.slice(-5, -3));
        const compareNumToMin = Number(toX.slice(-2));
        dataSlice = dataSlice.filter((dataItem) => {
          const hour = Number(dataItem.date.slice(-5, -3));
          const min = Number(dataItem.date.slice(-2));
          return (
            (compareNumFromHour === hour &&
              compareNumToHour === hour &&
              compareNumFromMin <= min &&
              min <= compareNumToMin) ||
            (compareNumFromHour !== compareNumToHour &&
              ((compareNumFromHour === hour && compareNumFromMin <= min) ||
                (compareNumFromHour < hour && hour < compareNumToHour) ||
                (compareNumToHour === hour && compareNumToMin >= min)))
          );
        });
        setShowData(dataSlice);
        break;
    }
    // YAxis
    let [fromY, toY] = [changeFromY, changeToY];
    if (!fromY || !toY || fromY === toX || toY === "") {
      setChangeFromY("");
      setChangeToY("");
      return;
    }

    if (Number(fromY) < Number(toY)) {
      [fromY, toY] = [toY, fromY];
    }
    if (Array.isArray(showMinMax) && showMinMax.length > 0) {
      const newMinMax = showMinMax.map((minMaxItem) => {
        const min = minMaxItem.min;
        const max = minMaxItem.max;
        const changeMin = Number(chartToYAxis(fromY, min, max));
        const changeMax = Number(chartToYAxis(toY, min, max));
        return { ...minMaxItem, min: changeMin, max: changeMax };
      });
      setShowMinMax(newMinMax);
    }

    // 초기화
    setChangeFromX("");
    setChangeToX("");
    setChangeFromY("");
    setChangeToY("");
  }
  /* 초기화 */
  function onClickReset() {
    setShowData(data);
    setShowMinMax(minMax);
  }

  useEffect(() => {
    setMinMax([]); // 초기화
    setShowMinMax([]);
    setAlertLine([]);

    if (Object.keys(selectedData).length > 0) {
      // 강우계
      if (selectedData.rain.length > 0) {
        let max = 0;
        {
          selectedData.rain.forEach((rtuItem) => {
            // 최소&최대
            if (Array.isArray(rtuItem.data) && rtuItem.data.length > 0) {
              rtuItem.data.forEach((dataItem) => {
                for (let key in dataItem) {
                  if (key !== "date") {
                    if (dataItem[key] !== null) {
                      max =
                        max < parseFloat(dataItem[key])
                          ? parseFloat(dataItem[key])
                          : max;
                    }
                  }
                }
              });
            }
            // 임계치
            rtuItem.dvcData.forEach((dvcItem) => {
              updateAlertLine(dvcItem, "mm(강우)");
            });
          });
        }
        setMinMax((prevMinMax) => [
          ...prevMinMax,
          {
            type: "rain",
            typeNm: "강우(mm)",
            min: 0,
            max: Math.round(max) + 10,
          },
        ]);
        setShowMinMax((prevMinMax) => [
          ...prevMinMax,
          {
            type: "rain",
            typeNm: "강우(mm)",
            min: 0,
            max: Math.round(max) + 10,
          },
        ]);
      }
      // 수위계
      if (selectedData.level.length > 0) {
        setMinMax((prevMinMax) => [
          ...prevMinMax,
          { type: "reservoir", typeNm: "저수위(EL.m)", min: 135, max: 235 },
        ]);
        setShowMinMax((prevMinMax) => [
          ...prevMinMax,
          { type: "reservoir", typeNm: "저수위(EL.m)", min: 135, max: 235 },
        ]);
      }
      // 지하수위계
      if (selectedData.ground.length > 0) {
        setMinMax((prevMinMax) => [
          ...prevMinMax,
          { type: "ground", typeNm: "지하수위(EL.m)", min: 135, max: 235 },
        ]);
        setShowMinMax((prevMinMax) => [
          ...prevMinMax,
          { type: "ground", typeNm: "지하수위(EL.m)", min: 135, max: 235 },
        ]);
      }
      // 지표변위계
      if (selectedData.dis.length > 0) {
        let min = 999999,
          max = -999999;
        selectedData.dis.forEach((rtuItem) => {
          // 최소&최대
          rtuItem.data.forEach((dataItem) => {
            for (let key in dataItem) {
              if (key !== "date") {
                if (dataItem[key] !== null) {
                  min =
                    min > parseFloat(dataItem[key])
                      ? parseFloat(dataItem[key])
                      : min;
                  max =
                    max < parseFloat(dataItem[key])
                      ? parseFloat(dataItem[key])
                      : max;
                }
              }
            }
          });
          // 임계치
          rtuItem.dvcData.forEach((dvcItem) => {
            updateAlertLine(dvcItem, "mm(지표변위)");
          });
        });
        setMinMax((prevMinMax) => [
          ...prevMinMax,
          {
            type: "dis",
            typeNm: "지표변위(mm)",
            min: Math.round(min) - 2,
            max: Math.round(max) + 2,
          },
        ]);
        setShowMinMax((prevMinMax) => [
          ...prevMinMax,
          {
            type: "dis",
            typeNm: "지표변위(mm)",
            min: Math.round(min) - 2,
            max: Math.round(max) + 2,
          },
        ]);
      }

      // 데이터 통합
      let integratedData = [];
      Object.keys(selectedData).forEach((key) => {
        if (selectedData[key].length > 0) {
          selectedData[key].forEach((rtuItem) => {
            if (integratedData.length === 0) {
              integratedData = rtuItem.data;
            } else {
              const pushData = integratedData.map((dataItem) => {
                const target = rtuItem.data.find(
                  (item) => item.date === dataItem.date
                );
                return { ...dataItem, ...target };
              });
              integratedData = pushData;
            }
          });
        }
      });
      setRowData(selectedData);
      setData(integratedData);
      setShowData(integratedData);
    } else {
      setRowData({});
      setMinMax([]);
      setShowMinMax([]);
      setAlertLine([]);
      setData([]);
      setShowData([]);
    }
  }, [selectedData]);
  useEffect(() => {
    document.addEventListener("mouseup", handleMouseUp);
    return () => {
      document.removeEventListener("mouseup", handleMouseUp);
    };
  }, [showData, changeFromX, changeToX, changeFromY, changeToY]);

  return (
    <div id="data-sensor-grouping-graph-component">
      {
        // 초기화
        data.length > 0 && (
          <div className="graph-range">
            <div className="button-imp reset-button" onClick={onClickReset}>
              초기화
            </div>
          </div>
        )
      }
      <div
        ref={containerRef}
        className="graph"
        onMouseDown={onMouseDownComponent}
        onMouseMove={onMouseMoveComponent}
      >
        {data.length > 0 && (
          <ResponsiveContainer width="100%" height="100%">
            <ComposedChart
              ref={chartRef}
              data={showData}
              margin={{ left: 0, right: 20, bottom: -10 }}
              onMouseDown={onMouseDownChart}
              onMouseMove={onMouseMoveChart}
            >
              <CartesianGrid
                stroke="#595959"
                strokeWidth={0.5}
                vertical={false}
              />
              <Legend
                verticalAlign="top"
                height={40}
                wrapperStyle={{ paddingBottom: "10px" }}
                content={<CustomLegend />}
              />
              <XAxis
                dataKey="date"
                tick={{ fontSize: 11, fill: "#9B9B9B" }}
                axisLine={false}
              />
              {showMinMax.length > 0 &&
                showMinMax.map((minMaxItem) => {
                  return (
                    <YAxis
                      key={minMaxItem.type}
                      type="number"
                      yAxisId={`${minMaxItem.type}Axis`}
                      domain={[minMaxItem.min, minMaxItem.max]}
                      tick={{ fontSize: 11, fill: "#9B9B9B" }}
                      tickCount={10}
                      label={{
                        value: minMaxItem.typeNm,
                        angle: -90,
                        position: "insideLeft",
                        offset: 18,
                        style: {
                          textAnchor: "middle",
                          fill: "#9B9B9B",
                          fontSize: 12,
                        },
                      }}
                      allowDataOverflow={true}
                    />
                  );
                })}
              <Tooltip
                content={<TooltipCustom />}
                filterNull={true}
                cursor={{
                  stroke: "#757575",
                  strokeWidth: 1,
                  strokeDasharray: "3 3",
                }}
              />

              {/* 강우계 */}
              {rowData.rain.length > 0 &&
                rowData.rain.map((rtuItem) => {
                  return rtuItem.dvcData.map((dvcItem, index) => {
                    return (
                      <Bar
                        key={dvcItem.dvcNm}
                        dataKey={dvcItem.dvcNm}
                        yAxisId="rainAxis"
                        fill={rainColors[index % rainColors.length]}
                        isAnimationActive={false}
                      />
                    );
                  });
                })}
              {/* 수위계 */}
              {rowData.level.length > 0 &&
                rowData.level.map((rtuItem, index) => {
                  return rtuItem.dvcData.map((dvcItem) => {
                    return (
                      <React.Fragment key={dvcItem.dvcNm}>
                        <Area
                          type="monotone"
                          dataKey={dvcItem.dvcNm}
                          yAxisId="reservoirAxis"
                          fill={levelColors[index % levelColors.length]}
                          stroke={levelColors[
                            index % levelColors.length
                          ].substring(0, 7)}
                          isAnimationActive={false}
                        />
                      </React.Fragment>
                    );
                  });
                })}
              {/* 지하수위계 */}
              {rowData.ground.length > 0 &&
                rowData.ground.map((rtuItem, index) => {
                  return rtuItem.dvcData.map((dvcItem) => {
                    return (
                      <React.Fragment key={dvcItem.dvcNm}>
                        <Area
                          type="monotone"
                          dataKey={dvcItem.dvcNm}
                          yAxisId="groundAxis"
                          fill={groundColors[index % groundColors.length]}
                          stroke={groundColors[
                            index % groundColors.length
                          ].substring(0, 7)}
                          isAnimationActive={false}
                        />
                      </React.Fragment>
                    );
                  });
                })}
              {/* 지표변위계 */}
              {rowData.dis.length > 0 &&
                rowData.dis.map((rtuItem, index) => {
                  return rtuItem.dvcData.map((dvcItem) => {
                    return (
                      <Line
                        key={dvcItem.dvcNm}
                        type="monotone"
                        dataKey={dvcItem.dvcNm}
                        yAxisId={
                          dvcItem.dvcNm.includes("mm") ? "disAxis" : "disAxis"
                        }
                        stroke={disColors[index % disColors.length]}
                        strokeWidth={2}
                        dot={false}
                        activeDot={{ r: 4 }}
                      />
                    );
                  });
                })}

              {rowData.level.length > 0 && (
                <ReferenceLine
                  y={214}
                  yAxisId="reservoirAxis"
                  label={
                    <AlertCustomLabelUp
                      value={`관심(관리수위 EL.185m)`}
                      color="#0F80B1"
                    />
                  }
                  stroke="#006B99"
                  strokeDasharray="7 4"
                  strokeWidth={1}
                />
              )}
              {rowData.level.length > 0 && (
                <ReferenceLine
                  y={216}
                  yAxisId="reservoirAxis"
                  label={
                    <AlertCustomLabelUp
                      value={`주의(WL EL.190m)`}
                      color="#A49506"
                    />
                  }
                  stroke="#928400"
                  strokeDasharray="7 4"
                  strokeWidth={1}
                />
              )}
              {rowData.level.length > 0 && (
                <ReferenceLine
                  y={218}
                  yAxisId="reservoirAxis"
                  label={
                    <AlertCustomLabelUp
                      value={`경계(HWL EL.200m)`}
                      color="#B9640A"
                    />
                  }
                  stroke="#A15300"
                  strokeDasharray="7 4"
                  strokeWidth={1}
                />
              )}
              {rowData.level.length > 0 && (
                <ReferenceLine
                  y={220}
                  yAxisId="reservoirAxis"
                  label={
                    <AlertCustomLabelUp
                      value={`심각(PMF EL.220m)`}
                      color="#A00B0B"
                    />
                  }
                  stroke="#8F0303"
                  strokeDasharray="7 4"
                  strokeWidth={1}
                />
              )}
              {alertLine.length > 0 &&
                alertLine.map((alertItem, index) => (
                  <React.Fragment key={`${index}-alert`}>
                    {/* 최소 */}
                    <ReferenceLine
                      y={alertItem.min}
                      yAxisId={
                        alertItem.unit === "mm(강우)" ? "rainAxis" : "disAxis"
                      }
                      label={
                        <AlertCustomLabelDown
                          value={`관심(${alertItem.min}) ${alertItem.unit}`}
                          color="#0F80B1"
                        />
                      }
                      stroke="#006B99"
                      strokeDasharray="7 4"
                      strokeWidth={0.5}
                    />
                    <ReferenceLine
                      y={alertItem.min2}
                      yAxisId={
                        alertItem.unit === "mm(강우)" ? "rainAxis" : "disAxis"
                      }
                      label={
                        <AlertCustomLabelDown
                          value={`주의(${alertItem.min2}) ${alertItem.unit}`}
                          color="#A49506"
                        />
                      }
                      stroke="#928400"
                      strokeDasharray="7 4"
                      strokeWidth={0.5}
                    />
                    <ReferenceLine
                      y={alertItem.min3}
                      yAxisId={
                        alertItem.unit === "mm(강우)" ? "rainAxis" : "disAxis"
                      }
                      label={
                        <AlertCustomLabelDown
                          value={`경계(${alertItem.min3}) ${alertItem.unit}`}
                          color="#B9640A"
                        />
                      }
                      stroke="#A15300"
                      strokeDasharray="7 4"
                      strokeWidth={0.5}
                    />
                    <ReferenceLine
                      y={alertItem.min4}
                      yAxisId={
                        alertItem.unit === "mm(강우)" ? "rainAxis" : "disAxis"
                      }
                      label={
                        <AlertCustomLabelDown
                          value={`심각(${alertItem.min4}) ${alertItem.unit}`}
                          color="#A00B0B"
                        />
                      }
                      stroke="#8F0303"
                      strokeDasharray="7 4"
                      strokeWidth={0.5}
                    />
                    {/* 최대 */}
                    <ReferenceLine
                      y={alertItem.max}
                      yAxisId={
                        alertItem.unit === "mm(강우)" ? "rainAxis" : "disAxis"
                      }
                      label={
                        <AlertCustomLabelUp
                          value={`관심(${alertItem.max}) ${alertItem.unit}`}
                          color="#0F80B1"
                        />
                      }
                      stroke="#006B99"
                      strokeDasharray="7 4"
                      strokeWidth={0.5}
                    />
                    <ReferenceLine
                      y={alertItem.max2}
                      yAxisId={
                        alertItem.unit === "mm(강우)" ? "rainAxis" : "disAxis"
                      }
                      label={
                        <AlertCustomLabelUp
                          value={`주의(${alertItem.max2}) ${alertItem.unit}`}
                          color="#A49506"
                        />
                      }
                      stroke="#928400"
                      strokeDasharray="7 4"
                      strokeWidth={0.5}
                    />
                    <ReferenceLine
                      y={alertItem.max3}
                      yAxisId={
                        alertItem.unit === "mm(강우)" ? "rainAxis" : "disAxis"
                      }
                      label={
                        <AlertCustomLabelUp
                          value={`경계(${alertItem.max3}) ${alertItem.unit}`}
                          color="#B9640A"
                        />
                      }
                      stroke="#A15300"
                      strokeDasharray="7 4"
                      strokeWidth={0.5}
                    />
                    <ReferenceLine
                      y={alertItem.max4}
                      yAxisId={
                        alertItem.unit === "mm(강우)" ? "rainAxis" : "disAxis"
                      }
                      label={
                        <AlertCustomLabelUp
                          value={`심각(${alertItem.max4}) ${alertItem.unit}`}
                          color="#A00B0B"
                        />
                      }
                      stroke="#8F0303"
                      strokeDasharray="7 4"
                      strokeWidth={0.5}
                    />
                  </React.Fragment>
                ))}
            </ComposedChart>
          </ResponsiveContainer>
        )}
        {
          // 드래그 영역
          dragRect && (
            <div
              className="drag-rect"
              style={{
                left: `${dragRect.left}px`,
                top: `${dragRect.top}px`,
                width: `${dragRect.width}px`,
                height: `${dragRect.height}px`,
              }}
            />
          )
        }
      </div>
    </div>
  );
}

export default SensorGraph;
