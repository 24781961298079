import React, { useState, useEffect } from "react";
import {
  createColumnHelper,
  flexRender,
  useReactTable,
  getCoreRowModel,
  getSortedRowModel,
} from "@tanstack/react-table";
import axios from "axios";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleUp, faAngleDown } from "@fortawesome/free-solid-svg-icons";

import apiAddress from "../../../services/apiAddress";
import BroadGroupRtuDevice from "./BroadGroupRtuDevice";

function BroadGroupRtu({ rtuData }) {
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);
  const columnHelper = createColumnHelper();
  const emptyColumn = {
    id: "empty",
    accessor: "empty",
    header: "",
    enableSorting: false,
    size: 10,
  };
  const angleIconColumn = {
    id: "angleIcon",
    accessor: "angle",
    header: "",
    enableSorting: false,
    size: 10,
    cell: ({ row }) => {
      return row.original.angle ? (
        <FontAwesomeIcon icon={faAngleUp} />
      ) : (
        <FontAwesomeIcon icon={faAngleDown} />
      );
    },
  };
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  function onClickRow(row) {
    // 행 클릭 시.
    const newData = data.map((item) => {
      if (item.eqId === row.eqId) {
        return { ...item, angle: !item.angle };
      }
      return item;
    });
    setData(newData);
  }

  /* RTU - 방송장비 조회 API */
  async function fetchData() {
    const fetchDevice = async (item) => {
      try {
        const response = await axios.post(
          apiAddress.deviceGet,
          {
            eqId: item.eqId,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        const responseData = response.data.filter(
          (device) => device.dvcType === "BRD" && device.useYn === "Y"
        );
        return { ...item, brdData: responseData, angle: false };
      } catch (e) {
        console.log(e);
        return null;
      }
    };
    const fetchRtuDevice = async () => {
      const rtuBrd = await Promise.all(rtuData.map(fetchDevice));
      const column = [
        emptyColumn,
        columnHelper.accessor("eqNm", {
          size: "auto",
        }),
        angleIconColumn,
      ];

      setData(rtuBrd);
      setColumns(column);
    };
    fetchRtuDevice();
  }

  useEffect(() => {
    if (rtuData) {
      fetchData();
    }
  }, [rtuData]);

  return (
    <table className="sub_table">
      <tbody>
        {table.getRowModel().rows.map((row, index) => (
          <React.Fragment key={row.id}>
            <tr
              key={row.id}
              className={index % 2 === 0 ? "tr_even" : "tr_odd"}
              onClick={() => {
                onClickRow(row.original);
              }}
              style={{ cursor: "pointer" }}
            >
              {row.getVisibleCells().map((cell) => (
                <td
                  key={cell.id}
                  style={{
                    width: isNaN(cell.column.getSize())
                      ? "auto"
                      : `${cell.column.getSize()}px`,
                    minWidth: isNaN(cell.column.getSize())
                      ? "auto"
                      : `${cell.column.getSize()}px`,
                    maxWidth: isNaN(cell.column.getSize())
                      ? "auto"
                      : `${cell.column.getSize()}px`,
                    textAlign: cell.column.id === "angleIcon" && "center",
                  }}
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
            {row.original.angle && row.original.brdData.length > 0 && (
              <tr>
                <td colSpan={3} style={{ padding: "0" }}>
                  <BroadGroupRtuDevice brdData={row.original.brdData} />
                </td>
              </tr>
            )}
          </React.Fragment>
        ))}
      </tbody>
    </table>
  );
}

export default BroadGroupRtu;
