import apiAddress from "../../services/apiAddress";
import axios from "axios";

/* 로그인 */
/*export function login(userId, userPwd) {
  return axios
    .post(
      apiAddress.login,
      {
        userId: userId,
        userPwd: userPwd,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      return response.data;
    });
}*/

export function login(userId, userPwd) {
  const axiosInstance = axios.create({
    headers: {
      "Content-Type": "application/json",
    },
  });

  axiosInstance.interceptors.response.use(
    (response) => {
      return Promise.resolve(response);
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  axiosInstance.interceptors.request.use(
    (config) => config,
    (error) => Promise.reject(error)
  );

  return axiosInstance
    .post(
      apiAddress.login,
      {
        userId: userId,
        userPwd: userPwd,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (
        error.response &&
        (error.response.status === 401 ||
          error.response.status === 403 ||
          error.response.status === 500)
      ) {
        return error.response.data;
      }
      return false;
    });
}

/* 메뉴 얻기 */
export function getMenu(tokenInform) {
  try {
    const tokenInformParse = JSON.parse(tokenInform);
    if (tokenInformParse) {
      return axios.post(
        apiAddress.getMenu,
        {
          id: tokenInformParse.id,
          siteId: tokenInformParse.siteId,
          userId: tokenInformParse.userId,
          userNm: tokenInformParse.userNm,
          authGrpId: tokenInformParse.authGrpId,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: tokenInformParse.accessToken,
            refreshToken: tokenInformParse.refreshToken,
          },
        }
      );
    } else {
      return [];
    }
  } catch (e) {
    return [];
  }
}

/* 토큰 검증 */
export function token(tokenInform) {
  try {
    const tokenInformParse = JSON.parse(tokenInform);

    if (tokenInformParse) {
      const axiosInstance = axios.create({
        headers: {
          "Content-Type": "application/json",
          Authorization: tokenInformParse.accessToken,
          refreshToken: tokenInformParse.refreshToken,
        },
      });

      axiosInstance.interceptors.response.use(
        (response) => response,
        (error) => {
          if (error.response && error.response.status === 401) {
            return Promise.resolve(false);
          }
          return Promise.reject(error);
        }
      );

      axiosInstance.interceptors.request.use(
        (config) => {
          return config;
        },
        (error) => {
          return Promise.reject(error);
        }
      );

      return axiosInstance
        .post(
          apiAddress.token,
          {
            id: tokenInformParse.id,
            siteId: tokenInformParse.siteId,
            userId: tokenInformParse.userId,
            userNm: tokenInformParse.userNm,
            authGrpId: tokenInformParse.authGrpId,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: tokenInformParse.accessToken,
              refreshToken: tokenInformParse.refreshToken,
            },
          }
        )
        .then((response) => {
          return response.data;
        });
    } else {
      return false;
    }
  } catch (e) {
    return false;
  }
}
