import apiAddress from "../../services/apiAddress";
import axios from "axios";

/* 전광판 정보 */
export function getEdboard() {
  return axios
    .post(
      apiAddress.equipGet,
      { eqId: 0 },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      const equipData = response.data;
      const edboard = equipData.filter(
        (item) => item.eqType === "EDB" && item.useYn === "Y"
      );
      return edboard;
    });
}

/* 해당 전광판 메시지 리스트 */
export function getEdboardMsg(eqId) {
  return axios
    .post(
      apiAddress.getEdbMsg,
      {
        eqId: eqId,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      if (Array.isArray(response.data) && response.data.length > 0) {
        const sortedData = response.data.sort((a, b) => {
          if (a.ledId < b.ledId) return -1;
          if (a.ledId > b.ledId) return 1;
          return 0;
        });
        return sortedData;
      } else {
        return [];
      }
    });
}

/* 해당 전광판 메시지 내역 */
export function getEdboardLog(eqId) {
  return axios
    .post(
      apiAddress.getEdbLog,
      { eqId: eqId },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      if (Array.isArray(response.data) && response.data.length > 0) {
        const filterData = response.data.filter((item) => item.eqId === eqId);
        return filterData;
      } else {
        return [];
      }
    });
}

/* 전광판 Item + 메시지 리스트 */
export function getEdboardMessage() {
  return getEdboard().then((edboard) => {
    if (edboard.length !== 0) {
      const promise = edboard.map((edbItem) =>
        getEdboardMsg(edbItem.eqId).then((msg) => ({
          ...edbItem,
          msgList: msg,
        }))
      );

      return Promise.all(promise);
    } else {
      return [];
    }
  });
}

/* 전광판 Item + 메시지 리스트 + 로그 */
export function getEdboardMessageLog() {
  return getEdboardMessage().then((edbMsg) => {
    if (edbMsg.length !== 0) {
      const promise = edbMsg.map((edbMsgItem) =>
        getEdboardLog(edbMsgItem.eqId).then((log) => ({
          ...edbMsgItem,
          msgLog: log,
        }))
      );

      return Promise.all(promise);
    } else {
      return [];
    }
  });
}

/* 전광판 메시지 저장/수정 */
export function saveEdboardMessage(sendInform) {
  const tokenInform = localStorage.getItem("tokenInform");
  const tokenInformParse = JSON.parse(tokenInform);

  return axios
    .post(
      apiAddress.saveEdbMsg,
      {
        ...sendInform,
        siteId: tokenInformParse.siteId,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      return response.data;
    });
}

/* 전광판 메시지 전송 */
export function sendEdboard(ledId) {
  const tokenInform = localStorage.getItem("tokenInform");
  const tokenInformParse = JSON.parse(tokenInform);

  return axios
    .post(
      apiAddress.sendEdb,
      {
        ledId: ledId,
        regUsr: tokenInformParse.userId,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      return response.data;
    });
}
