export const latlon = {
  lat: "37.44444093416704",
  lon: "126.89492708716011",
}; // 테크노파크쪽 위도 및 경도

export const location = {
  nx: 58,
  ny: 124,
  regionCode: "4121064000",
  locationNm: "광명시 소하1동",
};
