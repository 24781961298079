import { useState, useEffect, useRef } from "react";
import Modal from "react-modal";

import { MODALSTYLES } from "../../../constants/modalStyles";

Modal.setAppElement("#root");

const { kakao } = window;
let map;

function LatLon({ isOpen, setIsOpen, data, setData }) {
  const container = useRef(null);
  let latDefault =
    data.lat === "" || isNaN(parseFloat(data.lat)) ? "37.465981" : data.lat;
  if (33.1 > parseFloat(latDefault) || parseFloat(latDefault) > 38.6) {
    latDefault = "37.465981";
  }
  let lonDefault =
    data.lon === "" || isNaN(parseFloat(data.lon)) ? "128.129975" : data.lon;
  if (125.9 > parseFloat(lonDefault) || parseFloat(lonDefault) > 131.9) {
    lonDefault = "128.129975";
  }
  const [lat, setLat] = useState(latDefault);
  const [lon, setLon] = useState(lonDefault);

  function onClickClose() {
    setIsOpen(false);
  }
  function onClickApply() {
    setData({ ...data, lat: lat, lon: lon });
    setIsOpen(false);
  }

  useEffect(() => {
    // 카카오맵 API 스크립트를 동적으로 추가합니다.
    const script = document.createElement("script");
    script.src =
      "//dapi.kakao.com/v2/maps/sdk.js?appkey=53ae69a5dd6527f49a5119333533ee0d";
    script.async = true;

    // 스크립트가 로드된 후에 실행할 로직을 작성합니다.
    script.onload = () => {
      const options = {
        center: new kakao.maps.LatLng(lat, lon),
        level: 3,
      };
      map = new kakao.maps.Map(container.current, options);
      map.setMaxLevel(13);
      map.setMapTypeId(kakao.maps.MapTypeId.SKYVIEW);

      const marker = new kakao.maps.Marker({
        position: new kakao.maps.LatLng(lat, lon),
      });
      marker.setMap(map);
      marker.setDraggable(true);

      kakao.maps.event.addListener(map, "click", function (mouseEvent) {
        // 마커 이동할 위치 클릭 & 좌표 가져오기
        const latlng = mouseEvent.latLng;
        marker.setPosition(latlng);
        setLat(latlng.Ma);
        setLon(latlng.La);
      });
      kakao.maps.event.addListener(marker, "dragend", function (mouseEvent) {
        // 마커 드래그 & 좌표 가져오기
        const latlng = marker.getPosition();
        setLat(latlng.Ma);
        setLon(latlng.La);
      });
    };

    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, []);

  return (
    <Modal isOpen={isOpen} style={MODALSTYLES.latlon}>
      <div className="modal_title">
        <div>위도/경도 조회</div>
      </div>
      <div className="modal_container">
        <div className="modal_main_column">
          <div className="modal_input">
            <div className="title">위도</div>
            <input
              className="input_disable"
              value={lat}
              onChange={(e) => setLat(e.target.value)}
              style={{ textAlign: "center" }}
              disabled={true}
            />
            <div className="inner_title">경도</div>
            <input
              className="input_disable"
              value={lon}
              onChange={(e) => setLon(e.target.value)}
              style={{ textAlign: "center" }}
              disabled={true}
            />
          </div>
          <div className="map_modal">
            <div className="kakao_map" ref={container} />
          </div>
        </div>

        <div className="modal_footer">
          <div className="button_nor" onClick={onClickClose}>
            닫기
          </div>
          <div
            className="button_imp"
            style={{ marginLeft: "10px" }}
            onClick={onClickApply}
          >
            확인
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default LatLon;
