import { useState, useEffect } from "react";
import Modal from "react-modal";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

import { MODALSTYLES } from "../../../constants/modalStyles";
import { zeroPlusNum } from "../../../utils/time";
import { authCellNum } from "../../../services/internal/signAPI";
import { saveUser } from "../../../services/internal/userAPI";
import Alert from "../../common/window/Alert";
import Confirm from "../../common/window/Confirm";

function CellEdit({ isOpen, setIsOpen, inform, setIsChange, setIsSuccess }) {
  const [newCell, setNewCell] = useState("");
  const [cellAuth, setCellAuth] = useState("");
  const [isCellAuth, setIsCellAuth] = useState(false);
  const [cellAuthTime, setCellAuthTime] = useState({
    minute: 3,
    second: 0,
  });
  const [cellAuthText, setCellAuthText] = useState("");
  const [cellAuthNum, setCellAuthNum] = useState("");
  const [isEdit, setIsEdit] = useState({ isOpen: false, text: "" });

  const [isInputAlert, setIsInputAlert] = useState(false);
  const [isAuthAlert, setIsAuthAlert] = useState(false);

  function onClickAuth() {
    // 인증 버튼 클릭 시
    if (newCell !== "") {
      authCellNum(newCell).then((response) => {
        setIsCellAuth("send");
        setCellAuthNum(response);
      });
    }
  }
  function onClickAuthCheck() {
    // 인증확인 버튼 클릭 시
    if (cellAuth !== "") {
      if (cellAuth.toString() === cellAuthNum.toString()) {
        // 인증번호 일치
        setIsCellAuth(true);
        setCellAuthText("휴대폰 인증이 완료되었습니다");
      } else {
        // 인증번호 일치x
        setCellAuthText("인증번호가 일치하지 않습니다");
      }
    }
  }

  function onClickClose() {
    // 닫기 버튼 클릭 시
    setIsOpen(false);
  }
  function onClickApply() {
    // 변경 버튼 클릭 시
    if (newCell === "" || cellAuth === "") {
      // 정보 입력x
      setIsInputAlert(true);
    } else {
      if (isCellAuth !== true) {
        // 인증x
        setIsAuthAlert(true);
      } else {
        // 휴대번호 변경
        setIsEdit({
          isOpen: true,
          text: "휴대번호를 변경하시겠습니까?",
        });
      }
    }
  }
  function editCell() {
    // 휴대번호 변경
    const delInform = inform;
    delete delInform.menuAuth;
    const editInform = { ...delInform, cellNum: newCell };

    saveUser(editInform).then((response) => {
      setIsChange(true);
      setIsOpen(false);
      setIsSuccess(true);
    });
  }

  useEffect(() => {
    if (isCellAuth === "send") {
      const intervalId = setInterval(() => {
        let minute, second;
        if (cellAuthTime.second - 1 < 0) {
          minute = cellAuthTime.minute - 1;
          second = 59;
        } else {
          minute = cellAuthTime.minute;
          second = cellAuthTime.second - 1;
        }

        if (minute === 0 && second === 0) {
          // 인증번호 유효시간 종료
          setIsCellAuth(false);
          setCellAuth("");
          setCellAuthTime({ minute: 3, second: 0 });
          setCellAuthText(false);
        } else {
          setCellAuthTime({
            minute: minute,
            second: second,
          });
        }
      }, 1000);

      return () => clearInterval(intervalId);
    }
  }, [isCellAuth, cellAuthTime]);

  return (
    <Modal isOpen={isOpen} style={MODALSTYLES.cellEdit}>
      {
        // 정보 입력x
        isInputAlert && (
          <Alert
            isOpen={isInputAlert}
            setIsOpen={setIsInputAlert}
            text="정보를 입력해주세요"
          />
        )
      }
      {
        // 인증x
        isAuthAlert && (
          <Alert
            isOpen={isAuthAlert}
            setIsOpen={setIsAuthAlert}
            text="휴대폰 인증을 해주세요"
          />
        )
      }
      {
        // 휴대번호 변경
        isEdit.isOpen && (
          <Confirm isOpen={isEdit} setIsOpen={setIsEdit} active={editCell} />
        )
      }

      <div className="modal_title">휴대번호 변경</div>
      <div className="modal_container">
        <div className="my-edit-cell-modal">
          <div
            className="cell-auth-text"
            style={{ color: isCellAuth === true ? "#13e700" : "#ff4040" }}
          >
            {cellAuthText}
          </div>
          <div className="new-cell-container">
            <div className="title">새 휴대번호</div>
            <input
              type="number"
              placeholder="휴대번호 입력(숫자만 입력)"
              value={newCell}
              onChange={(e) => {
                setNewCell(e.target.value);
                setIsCellAuth(false);
                setCellAuth("");
                setCellAuthTime({ minute: 3, second: 0 });
                setCellAuthText(false);
              }}
            />
            <div className="button-container">
              <div className="edit-cell-button button" onClick={onClickAuth}>
                인증
              </div>
              {isCellAuth !== false && (
                <div className="disable-button">
                  {isCellAuth === true ? (
                    <FontAwesomeIcon className="auth-check" icon={faCheck} />
                  ) : (
                    isCellAuth === "send" && (
                      <div className="time">
                        {zeroPlusNum(cellAuthTime.minute)} :{" "}
                        {zeroPlusNum(cellAuthTime.second)}
                      </div>
                    )
                  )}
                </div>
              )}
            </div>
          </div>
          <div className="cell-auth-container">
            <div className="title">인증번호</div>
            <div className="input-container">
              <div className="input">
                <input
                  className="cell-auth-input"
                  type="number"
                  placeholder="인증번호 입력"
                  value={cellAuth}
                  onChange={(e) => setCellAuth(e.target.value)}
                  disabled={isCellAuth === true}
                />
                <div className="button-container">
                  <div
                    className="button-modal button"
                    onClick={onClickAuthCheck}
                  >
                    인증확인
                  </div>
                  {isCellAuth === true && (
                    <div className="disable-button">
                      <FontAwesomeIcon className="auth-check" icon={faCheck} />
                    </div>
                  )}
                </div>
              </div>
              {isCellAuth === false && <div className="disable-input" />}
            </div>
          </div>
        </div>

        <div className="modal_footer">
          <div className="button-nor-modal" onClick={onClickClose}>
            닫기
          </div>
          <div className="button-imp right-button" onClick={onClickApply}>
            변경
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default CellEdit;
