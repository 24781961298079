import { useState, useEffect } from "react";
import { PieChart, Pie, Tooltip, Cell, ResponsiveContainer } from "recharts";

import { getSnsrStatus } from "../../services/internal/dataAPI";
import loadingImg from "../../assets/common/loading.gif";

function ConnState({ selected }) {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [per, setPer] = useState(0);

  function fetchData() {
    /* 통신상태 조회 API */
    setIsLoading(true);
    getSnsrStatus().then((response) => {
      setIsLoading(false);
      // if (Array.isArray(response) && response.length > 0) {
      //   let normalCnt = 0,
      //     abnormalCnt = 0;
      //   response.forEach((item) => {
      //     normalCnt += Number(item["정상"]);
      //     abnormalCnt += Number(item["접속이상"]);
      //   });

      //   const totalCnt = normalCnt + abnormalCnt;
      //   const percentage =
      //     totalCnt === 0 ? 0 : (normalCnt / totalCnt).toFixed(2) * 100;
      //   const cntData = [
      //     { type: "정상", value: normalCnt },
      //     { type: "접속이상", value: abnormalCnt },
      //   ];

      //   if (Object.keys(selected).length > 0) {
      //     // 저수지 선택 후
      //     const sample = [
      //       { type: "정상", value: 6 },
      //       { type: "접속이상", value: 0 },
      //     ];
      //     // setData(cntData);
      //     setData(sample);
      //     setPer(percentage);
      //   } else {
      //     // 저수지 선택 전
      //     const sample = [
      //       { type: "정상", value: 24 },
      //       { type: "접속이상", value: 0 },
      //     ];
      //     setData(sample);
      //     setPer(percentage);
      //   }
      // } else {
      //   setData([]);
      // }
      if (Object.keys(selected).length > 0) {
        // 저수지 선택 후
        const sample = [
          { type: "정상", value: 6 },
          { type: "접속이상", value: 0 },
        ];
        // setData(cntData);
        // setPer(percentage);
        setData(sample);
        setPer(100);
      } else {
        // 저수지 선택 전
        const sample = [
          { type: "정상", value: 24 },
          { type: "접속이상", value: 0 },
        ];
        setData(sample);
        setPer(100);
      }
    });
  }

  useEffect(() => {
    fetchData();

    const intervalId = setInterval(() => {
      fetchData();
    }, 60000); // 1분
    return () => clearInterval(intervalId);
  }, [selected]);

  /* Tooltip 관련 */
  const CustomTooltup = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      const type = payload[0].payload.payload.type;
      const value = payload[0].payload.payload.value;
      return (
        <div className="custom-tooltip">
          <div
            className="color"
            style={{
              background:
                type === "정상" ? "#003cff" : type === "접속이상" && "#ff0000",
            }}
          />
          <div className="label">{type}</div>
          <div className="value">{value}</div>
        </div>
      );
    }
    return null;
  };

  return (
    <div id="allstatus-conn-component">
      {isLoading ? (
        <div className="loading-container">
          <img src={loadingImg} alt="" />
        </div>
      ) : !Array.isArray(data) || data.length <= 0 ? (
        <div className="data-none-container" style={{ fontSize: "0.8rem" }}>
          등록된 센서가 없습니다
        </div>
      ) : (
        <>
          {/* 통신 상태 그래프 */}
          <div className="conn-graph">
            {/* 통신 상태 퍼센트 */}
            <div className="percent">{`${per}%`}</div>
            <ResponsiveContainer width="95%" height="95%">
              <PieChart>
                <Pie
                  data={data}
                  cx="50%"
                  cy="50%"
                  innerRadius={45}
                  outerRadius={75}
                  paddingAngle={5}
                  dataKey="value"
                  stroke="none"
                  activeShape={null}
                >
                  {data.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={entry.type === "정상" ? "#003cff" : "#ff0000"}
                      style={{ outline: "none" }}
                    />
                  ))}
                </Pie>
                <Tooltip content={CustomTooltup} />
              </PieChart>
            </ResponsiveContainer>
          </div>

          {/* 통신 상태 리스트 */}
          <div className="conn-list">
            <div className="conn-item">
              <div className="conn-title">전체</div>
              <div className="conn-value total">
                {data.find((item) => item.type === "정상")?.value +
                  data.find((item) => item.type === "접속이상")?.value}
              </div>
            </div>
            <div className="conn-item">
              <div className="conn-title">정상</div>
              <div className="conn-value normal">
                {data.find((item) => item.type === "정상")?.value}
              </div>
            </div>
            <div className="conn-item conn-item-last">
              <div className="conn-title">접속이상</div>
              <div className="conn-value abnormal">
                {data.find((item) => item.type === "접속이상")?.value}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default ConnState;
