import { useState } from "react";
import Modal from "react-modal";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faX } from "@fortawesome/free-solid-svg-icons";

import { MODALSTYLES } from "../../constants/modalStyles";
import { resetPwd } from "../../services/internal/userAPI";
import Alert from "../common/window/Alert";

function FindPwd({ isOpen, setIsOpen, setIsFindPwd }) {
  const [findInform, setFindInform] = useState(isOpen.data);
  const [isInputAlert, setIsInputAlert] = useState(false);
  const [isFindAlert, setIsFindAlert] = useState(false);

  function onClickFind() {
    // 임시 비밀번호 발급 클릭 시
    if (findInform.userId === "" || findInform.cellNum === "") {
      // 정보 입력X
      setIsInputAlert(true);
    } else {
      // 정보 입력O
      resetPwd(findInform.userId, findInform.cellNum).then((response) => {
        if (response.success) {
          // 발급 성공
          setIsFindPwd(true);
          setIsOpen(false);
        } else {
          // 발급 실패
          setIsFindAlert(true);
        }
      });
    }
  }
  function onClickClose() {
    // 닫기
    setIsOpen({
      isOpen: false,
      data: {
        cellNum: "",
        userId: "",
      },
    });
  }

  return (
    <Modal isOpen={isOpen.isOpen} style={MODALSTYLES.findPwd}>
      {
        // 정보입력x
        isInputAlert && (
          <Alert
            isOpen={isInputAlert}
            setIsOpen={setIsInputAlert}
            text="모든 정보를 입력해주세요"
          />
        )
      }
      {
        // 임시 비밀번호 발급 실패
        isFindAlert && (
          <Alert
            isOpen={isFindAlert}
            setIsOpen={setIsFindAlert}
            text="입력하신 정보로 등록된 계정이 없습니다."
          />
        )
      }
      <div id="find-pwd-modal">
        <div className="find-pwd-header">
          <div className="title">비밀번호 찾기</div>
          <FontAwesomeIcon
            className="close-button"
            icon={faX}
            onClick={onClickClose}
          />
        </div>

        <div className="find-pwd-main">
          <div className="notice">
            입력하신 번호로 임시 비밀번호를 발급 받으실 수 있습니다.
            <br />
            발급된 임시 비밀번호로 로그인 후, 보안을 위해 가급적 비밀번호 변경을
            해주시길 바랍니다.
          </div>

          <div className="input">
            <div className="input-container">
              <input
                type="text"
                placeholder="아이디 입력"
                value={findInform.userId}
                onChange={(e) =>
                  setFindInform({ ...findInform, userId: e.target.value })
                }
              />
              <input
                className="input-margin"
                type="number"
                placeholder="휴대번호 입력(숫자만 입력)"
                value={findInform.cellNum}
                onChange={(e) =>
                  setFindInform({ ...findInform, cellNum: e.target.value })
                }
              />
            </div>
            <div className="button-imp button" onClick={onClickFind}>
              임시
              <br />
              비밀번호
              <br />
              발급
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default FindPwd;
