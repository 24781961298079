function Transmission({ inform, setInform }) {
  return (
    <div id="sms-trans-transmission-component">
      <div className="title-input-container">
        <div className="title">제목</div>
        <input
          type="text"
          placeholder="제목 입력"
          value={inform.title}
          onChange={(e) => setInform({ ...inform, title: e.target.value })}
        />
      </div>
      <div className="content-input-container">
        <div className="title">내용</div>
        <textarea
          type="text"
          placeholder="내용 입력"
          value={inform.content}
          onChange={(e) => setInform({ ...inform, content: e.target.value })}
        />
      </div>
    </div>
  );
}

export default Transmission;
