export const latlon = {
  // 테크노파크쪽 위도 및 경도
  // lat: "37.44444093416704",
  // lon: "126.89492708716011",
  // 서흥저수지
  // lat: "35.391244",
  // lon: "127.077146",
  lat: 35.39185,
  lon: 127.079263,
};
export const reservoirs = [
  {
    id: 1,
    name: "광암저수지",
    addr: "순창군 팔덕면 광암리 40",
    lat: "35.397043",
    lon: "127.133071",
  },
  {
    id: 2,
    name: "적곡저수지",
    addr: "순창군 구림면 운남리 943",
    lat: "35.512534",
    lon: "126.950889",
  },
  {
    id: 3,
    name: "남정저수지",
    addr: "순창군 쌍치면 종곡리 216",
    lat: "35.452011",
    lon: "127.083220",
  },
  {
    id: 4,
    name: "백암저수지",
    addr: "순창군 팔덕면 서흥리 477",
    lat: "35.392858",
    lon: "127.079463",
  },
];

export const location = {
  nx: 58,
  ny: 124,
  // regionCode: "4121064000",
  regionCode: "1111053000",
  // locationNm: "광명시 소하1동",
  locationNm: "전북특별자치도 순창군",
};
