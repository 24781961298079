import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  createColumnHelper,
  flexRender,
  useReactTable,
  getCoreRowModel,
  getSortedRowModel,
} from "@tanstack/react-table";

import { tokenCheck } from "../../../../utils/tokenCheck";
import {
  getEdboardMessage,
  sendEdboard,
} from "../../../../services/internal/edboardAPI";
import loadingImg from "../../../../assets/common/loading.gif";
import Confirm from "../../../common/window/Confirm";
import Alert from "../../../common/window/Alert";

function Edboard({ selected, setIsChange }) {
  const movePage = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isSend, setIsSend] = useState({ isOpen: false, text: "" });
  const [isSendAlert, setIsSendAlert] = useState(false);
  const [inform, setInform] = useState([]);
  const [curr, setCurr] = useState({});
  const [selectedMsg, setSelectedMsg] = useState({});

  const [columns, setColumns] = useState([]);
  const columnHelper = createColumnHelper();
  const table = useReactTable({
    data: inform,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  function onClickSend() {
    // 전송 버튼 클릭 시
    // 토큰 확인
    tokenCheck().then((result) => {
      if (!result) {
        alert("인증 토큰이 만료되었습니다. 다시 로그인 해주세요");
        movePage("/");
      } else {
        setIsSend({ isOpen: true, text: "메시지를 전송하시겠습니까?" });
      }
    });
  }

  function sendMessage() {
    // 메시지 전송
    setIsLoading(true);
    sendEdboard(selectedMsg.ledId).then((response) => {
      if (response === "메시지전송 성공") {
        refresh();
      } else {
        setIsLoading(false);
        setIsSendAlert(true);
      }
    });
  }
  function refresh() {
    getEdboardMessage().then((edbData) => {
      setInform([]);
      setColumns([]);
      setCurr({});
      if (Array.isArray(edbData) && edbData.length > 0) {
        const target = edbData.find((item) => item.eqId === selected.eqId);
        if (
          target &&
          Array.isArray(target.msgList) &&
          target.msgList.length > 0
        ) {
          const currInform = target.msgList.find((item) => item.useYn === "Y");
          if (currInform) {
            const column = [
              columnHelper.accessor("msg", { header: "내용", size: 150 }),
              columnHelper.accessor(
                (row) => {
                  if (row.useYn === "Y") {
                    return "표시중";
                  } else {
                    return "표시안함";
                  }
                },
                { id: "status", header: "표시 상태", size: 50 }
              ),
            ];

            setCurr(currInform);
            setColumns(column);
          }
          setInform(target.msgList);
        }
      }
      setIsChange(true);
      setIsLoading(false);
    });
  }
  function onClickRow(row) {
    // 행 클릭 시
    if (selectedMsg.ledId === row.original.ledId) {
      setSelectedMsg({});
    } else {
      setSelectedMsg(row.original);
    }
  }

  useEffect(() => {
    if (
      Object.keys(selected).length > 0 &&
      Array.isArray(selected.msgList) &&
      selected.msgList.length > 0
    ) {
      const currInform = selected.msgList.find((item) => item.useYn === "Y");
      if (currInform) {
        const column = [
          columnHelper.accessor("msg", { header: "내용", size: 150 }),
          columnHelper.accessor(
            (row) => {
              if (row.useYn === "Y") {
                return "표시중";
              } else {
                return "표시안함";
              }
            },
            { id: "status", header: "표시 상태", size: 50 }
          ),
        ];

        setCurr(currInform);
        setColumns(column);
      } else {
        setCurr({});
      }
      setInform(selected.msgList);
    } else {
      setInform([]);
      setColumns([]);
      setCurr({});
    }
  }, [selected]);

  return (
    <div className="edb-marker-inform">
      {
        // 메시지 전송
        isSend.isOpen && (
          <Confirm isOpen={isSend} setIsOpen={setIsSend} active={sendMessage} />
        )
      }
      {
        // 메시지 전송 실패
        isSendAlert && (
          <Alert
            isOpen={isSendAlert}
            setIsOpen={setIsSendAlert}
            text="메시지 전송에 실패하였습니다"
          />
        )
      }
      {/* 전광판 현황 */}
      <div className="edb-curr-container">
        <div className="edb-title-container">
          <div>전광판 현황</div>
        </div>
        <div className="curr-container">
          {Object.keys(curr).length === 0 ? (
            <div className="data-none-container">
              해당 전광판은 작동중이지 않습니다
            </div>
          ) : (
            <>
              <div className="effect-container">
                <div className="effect-item">
                  <div className="effect-title">입장 효과</div>
                  <div className="effect-value">
                    <div className="value">{curr.entryEffect}</div>
                  </div>
                </div>
                <div className="effect-item">
                  <div className="effect-title">표시 시간</div>
                  <div className="effect-value">
                    <div className="value">{curr.holdTime}</div>
                  </div>
                </div>
                <div className="effect-item">
                  <div className="effect-title">속도</div>
                  <div className="effect-value">
                    <div className="value">{curr.speed}</div>
                  </div>
                </div>
                <div className="effect-item effect-item-last">
                  <div className="effect-title">퇴장 효과</div>
                  <div className="effect-value">
                    <div className="value">{curr.exitEffect}</div>
                  </div>
                </div>
              </div>
              <div className="msg-container">
                <div className="msg-title">내용</div>
                <textarea type="text" value={curr.msg} disabled={true} />
              </div>
            </>
          )}
        </div>
      </div>

      {/* 메시지 리스트 */}
      <div className="edb-msg-container">
        <div className="edb-title-container">
          <div>메시지 리스트</div>
          {Object.keys(selectedMsg).length > 0 && (
            <div className="button-imp send-button" onClick={onClickSend}>
              전송
            </div>
          )}
        </div>
        <div className="msg-container">
          {inform.length === 0 ? (
            <div className="data-none-container">
              해당 전광판에 등록된 메시지가 없습니다
            </div>
          ) : (
            <div className="list">
              <table className="react_table">
                <thead>
                  {table.getHeaderGroups().map((headerGroup) => (
                    <tr key={headerGroup.id}>
                      {headerGroup.headers.map((header) => (
                        <th
                          key={header.id}
                          onClick={header.column.getToggleSortingHandler()}
                          style={{
                            width: isNaN(header.getSize())
                              ? "auto"
                              : `${header.getSize()}px`,
                            minWidth: isNaN(header.getSize())
                              ? "auto"
                              : `${header.getSize()}px`,
                            maxWidth: isNaN(header.getSize())
                              ? "auto"
                              : `${header.getSize()}px`,
                          }}
                        >
                          {header.isPlaceholder
                            ? null
                            : flexRender(
                                header.column.columnDef.header,
                                header.getContext()
                              )}
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody>
                  {table.getRowModel().rows.map((row, index) => (
                    <tr
                      key={row.id}
                      className={
                        selectedMsg.ledId === row.original.ledId
                          ? "tr_select"
                          : index % 2 === 0
                          ? "tr_even"
                          : "tr_odd"
                      }
                      onClick={() => onClickRow(row)}
                    >
                      {row.getVisibleCells().map((cell) => (
                        <td
                          key={cell.id}
                          style={{
                            width: isNaN(cell.column.getSize())
                              ? "auto"
                              : `${cell.column.getSize()}px`,
                            minWidth: isNaN(cell.column.getSize())
                              ? "auto"
                              : `${cell.column.getSize()}px`,
                            maxWidth: isNaN(cell.column.getSize())
                              ? "auto"
                              : `${cell.column.getSize()}px`,
                            textAlign: cell.column.id === "status" && "center",
                            color:
                              cell.column.id === "status" &&
                              row.original.useYn === "Y" &&
                              "#3DC62A",
                          }}
                        >
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>
      {
        // 로딩화면
        isLoading && (
          <div className="loading">
            <img src={loadingImg} alt="" />
          </div>
        )
      }
    </div>
  );
}

export default Edboard;
