import { useState, useEffect } from "react";
import Modal from "react-modal";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

import { dateTimeToFormat } from "../../../utils/dateFormat";
import { getTargetMenu } from "../../../services/internal/signAPI";
import { resetPwd, accessId } from "../../../services/internal/userAPI";
import { MODALSTYLES } from "../../../constants/modalStyles";
import Confirm from "../window/Confirm";
import SuccessAlert from "../window/SuccessAlert";
import Alert from "../window/Alert";

function UserInform({ isOpen, setIsOpen, setIsChange }) {
  const [type, setType] = useState("personal");
  const [inform, setInform] = useState({});
  const [menuAuth, setMenuAuth] = useState([]);
  const [isPresetConfirm, setIsPresetConfirm] = useState({
    isOpen: false,
    text: "",
  });
  const [isPresetAlert, setIsPresetAlert] = useState(false);
  const [isPresetSuccess, setIsPresetSuccess] = useState(false);
  const [isAccessConfirm, setIsAccessConfirm] = useState({
    isOpen: false,
    text: "",
  });
  const [isAccessAlert, setIsAccessAlert] = useState(false);
  const [isAccessSuccess, setIsAccessSuccess] = useState(false);

  function onClickPreset() {
    // 비밀번호 초기화 클릭 시
    setIsPresetConfirm({
      isOpen: true,
      text:
        inform.userId +
        "(" +
        inform.userNm +
        ")님의 비밀번호를 초기화 하시겠습니까?",
    });
  }
  function preset() {
    // 비밀번호 초기화
    resetPwd(inform.userId, inform.cellNum).then((response) => {
      if (response.success === true) {
        // 성공
        setIsPresetSuccess(true);
        setIsChange(true);
      } else {
        // 실패
        setIsPresetAlert(true);
      }
    });
  }
  function onClickAccess() {
    // 승인 클릭시
    setIsAccessConfirm({
      isOpen: true,
      text: inform.userId + "(" + inform.userNm + ")님을 승인하시겠습니까?",
    });
  }
  function access() {
    // 승인
    accessId(inform.userId).then((response) => {
      if (response.success === true) {
        // 성공
        setIsAccessSuccess(true);
        setIsChange(true);
        setInform({ ...inform, accntStat: "A" });
      } else {
        // 실패
        setIsAccessAlert(true);
      }
    });
  }

  function compareArray(array1, array2) {
    if (array1.length !== array2.length) return false;
    else return true;
  }
  function onClickClose() {
    // 닫기 버튼 클릭 시
    setIsOpen({
      isOpen: false,
      data: {},
    });
  }

  useEffect(() => {
    if (Object.keys(isOpen.data).length !== 0) {
      getTargetMenu(isOpen.data.siteId).then((response) => {
        setInform(isOpen.data);
        setMenuAuth(response);
      });
    }
  }, [isOpen.data]);

  return (
    <Modal isOpen={isOpen.isOpen} style={MODALSTYLES.user}>
      {
        // 비밀번호 초기화
        isPresetConfirm.isOpen && (
          <Confirm
            isOpen={isPresetConfirm}
            setIsOpen={setIsPresetConfirm}
            active={preset}
          />
        )
      }
      {
        // 비밀번호 초기화 - 실패
        isPresetAlert && (
          <Alert
            isOpen={isPresetAlert}
            setIsOpen={setIsPresetAlert}
            text="초기화에 실패하였습니다"
          />
        )
      }
      {
        // 비밀번호 초기화 - 성공
        isPresetSuccess && (
          <SuccessAlert
            isOpen={isPresetSuccess}
            setIsOpen={setIsPresetSuccess}
            text="임시 비밀번호가 발급되었습니다"
          />
        )
      }
      {
        // 승인
        isAccessConfirm.isOpen && (
          <Confirm
            isOpen={isAccessConfirm}
            setIsOpen={setIsAccessConfirm}
            active={access}
          />
        )
      }
      {
        // 승인 - 실패
        isAccessAlert && (
          <Alert
            isOpen={isAccessAlert}
            setIsOpen={setIsAccessAlert}
            text="승인에 실패하였습니다"
          />
        )
      }
      {
        // 승인 - 성공
        isAccessSuccess && (
          <SuccessAlert
            isOpen={isAccessSuccess}
            setIsOpen={setIsAccessSuccess}
            text="승인에 성공하였습니다"
          />
        )
      }

      <div className="modal_title">
        <div>사용자 정보</div>
      </div>

      <div className="modal_container">
        <div className="user-inform-select-container">
          <div className="button-container">
            <div className="button-imp button" onClick={onClickPreset}>
              비밀번호 초기화
            </div>
            {inform.accntStat === "R" && (
              <div
                className="button-imp button button-margin"
                onClick={onClickAccess}
              >
                승인
              </div>
            )}
          </div>

          {/* 탭 선택 */}
          <div className="tab-container">
            <div
              className={
                type === "personal"
                  ? "select-item-select right"
                  : "select-item right"
              }
              onClick={() => setType("personal")}
            >
              개인정보
            </div>
            <div
              className={type === "menu" ? "select-item-select" : "select-item"}
              onClick={() => setType("menu")}
            >
              메뉴권한
            </div>
          </div>
        </div>

        {/* 개인정보 & 메뉴권한 */}
        <div className="modal_main_column">
          <div className="user-inform-modal">
            {type === "personal" ? (
              <>
                <div className="inform-container">
                  <div className="title">고객사ID</div>
                  <div className="inform inform-two">{inform.siteId}</div>
                  <div className="inner-title">등록일자</div>
                  <div className="inform inform-two">
                    {dateTimeToFormat(inform.regDt)}
                  </div>
                </div>
                <div className="inform-container">
                  <div className="title">아이디</div>
                  <div className="inform inform-two">{inform.userId}</div>
                  <div className="inner-title">비밀번호</div>
                  <div className="inform inform-two">•••••••••••••••••••</div>
                </div>
                <div className="inform-container">
                  <div className="title">이름</div>
                  <div className="inform inform-two">{inform.userNm}</div>
                  <div className="inner-title">Email</div>
                  <div className="inform inform-two">
                    {inform.email === "" || inform.email === undefined
                      ? "-"
                      : inform.email}
                  </div>
                </div>
                <div className="inform-container">
                  <div className="title">휴대번호</div>
                  <div className="inform inform-two">
                    {inform.cellNum === "" || inform.cellNum === undefined
                      ? "-"
                      : inform.cellNum}
                  </div>
                  <div className="inner-title">전화번호</div>
                  <div className="inform inform-two">
                    {inform.phoneNum === "" || inform.phoneNum === undefined
                      ? "-"
                      : inform.phoneNum}
                  </div>
                </div>
                <div className="inform-container">
                  <div className="title">소속</div>
                  <div className="inform inform-one">
                    {inform.adtnl1 === "" || inform.adtnl1 === undefined
                      ? "-"
                      : inform.adtnl1}
                  </div>
                </div>
                <div className="inform-container">
                  <div className="title">부서</div>
                  <div className="inform inform-two">
                    {inform.deptNm === "" || inform.deptNm === undefined
                      ? "-"
                      : inform.deptNm}
                  </div>
                  <div className="inner-title">직급</div>
                  <div className="inform inform-two">
                    {inform.positionNm === "" || inform.positionNm === undefined
                      ? "-"
                      : inform.positionNm}
                  </div>
                </div>
                <div className="last-inform-container">
                  <div className="title">메모</div>
                  <textarea
                    value={
                      inform.adtnl2 === "" || inform.adtnl2 === undefined
                        ? "-"
                        : inform.adtnl2
                    }
                    disabled={true}
                  />
                </div>
              </>
            ) : (
              type === "menu" && (
                <div className="user-inform-modal-menu">
                  {menuAuth.length !== 0 && (
                    <>
                      <div className="all-select-container">
                        {compareArray(menuAuth, inform.menuAuth) ? (
                          <div className="checkbox-select">
                            <FontAwesomeIcon icon={faCheck} />
                          </div>
                        ) : (
                          <div className="checkbox" />
                        )}
                        <div className="label">전체</div>
                      </div>
                      {menuAuth.map((menuInf) => (
                        <div key={menuInf.menuId}>
                          <div className="menu-item">
                            {!!inform.menuAuth.find(
                              (item) => item.menuId === menuInf.menuId
                            ) ? (
                              <div className="checkbox-select">
                                <FontAwesomeIcon icon={faCheck} />
                              </div>
                            ) : (
                              <div className="checkbox" />
                            )}
                            <div className="label">{menuInf.menuNm}</div>
                          </div>
                          {menuInf.menuList.length !== 0 && (
                            <div className="submenu-item-container">
                              {menuInf.menuList.map((submenuInf) => (
                                <div
                                  key={submenuInf.menuId}
                                  className="submenu-item"
                                >
                                  {!!menuInf.menuList.find(
                                    (item) => item.menuId === submenuInf.menuId
                                  ) ? (
                                    <div className="checkbox-select">
                                      <FontAwesomeIcon icon={faCheck} />
                                    </div>
                                  ) : (
                                    <div className="checkbox" />
                                  )}
                                  <div className="label">
                                    {submenuInf.menuNm}
                                  </div>
                                </div>
                              ))}
                            </div>
                          )}
                        </div>
                      ))}
                    </>
                  )}
                </div>
              )
            )}
          </div>
        </div>

        <div className="modal_footer">
          <div className="button-nor-modal" onClick={onClickClose}>
            닫기
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default UserInform;
