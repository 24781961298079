import { useState, useEffect } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocation } from "@fortawesome/free-solid-svg-icons";

import { location } from "../../../constants/latlon";
import { getIconAndText, getWindDirection } from "../../../utils/kma";

function CurrWeather({ currInform }) {
  const [data, setData] = useState(currInform);
  const [inform, setInform] = useState({
    icon: "",
    temp: "",
    iconT: "",
    humi: "",
    rain: "",
    windD: "",
    windS: "",
  });

  useEffect(() => {
    if (Array.isArray(currInform) && currInform.length > 0) {
      const t1h = currInform.find((item) => item.category === "T1H")?.obsrValue; // 온도
      const sky = currInform.find((item) => item.category === "SKY")?.fcstValue; // 하늘상태
      const pty = currInform.find((item) => item.category === "PTY")?.obsrValue; // 강수형태
      const reh = currInform.find((item) => item.category === "REH")?.obsrValue; // 습도
      const rn1 = currInform.find((item) => item.category === "RN1")?.obsrValue; // 1시간 강수량
      const vec = currInform.find((item) => item.category === "VEC")?.obsrValue; // 풍향
      const wsd = currInform.find((item) => item.category === "WSD")?.obsrValue; // 풍속

      setData(currInform);
      setInform({
        icon: getIconAndText(sky, pty).icon,
        temp: t1h,
        iconT: getIconAndText(sky, pty).text,
        humi: reh,
        rain: rn1,
        windD: getWindDirection(vec),
        windS: wsd,
      });
    } else {
      setData([]);
      setInform({
        icon: "",
        temp: "",
        iconT: "",
        humi: "",
        rain: "",
        windD: "",
        windS: "",
      });
    }
  }, [currInform]);

  return (
    <div id="allstatus-curr-component">
      {data.length === 0 ? (
        <div className="data-none-container">자료 조회에 실패하였습니다</div>
      ) : (
        <>
          {/* 위치 & 발표시각 */}
          <div className="location-time-container">
            <div className="item">
              <FontAwesomeIcon className="icon" icon={faLocation} />
              <div className="right">{location.locationNm}</div>
            </div>
            <div className="item">
              <div>{data[0].baseDate}</div>
              <div className="right">{data[0].baseTime}</div>
            </div>
          </div>

          <div className="curr-contents">
            {/* 날씨 메인 */}
            <div className="weather-imp">
              <img src={inform.icon} alt="" />
              <div className="temp">{`${inform.temp} °C`}</div>
              <div className="icon-text">{inform.iconT}</div>
            </div>

            {/* 날씨 상세 */}
            <div className="weather-dtl">
              <div className="dtl-item">
                <div className="item-title">습도</div>
                <div className="item-value">{`${inform.humi}%`}</div>
              </div>
              <div className="dtl-item">
                <div className="item-title">강우량(1H)</div>
                <div className="item-value">{`${inform.rain}mm`}</div>
              </div>
              <div className="dtl-item">
                <div className="item-title">풍향</div>
                <div className="item-value">{`${inform.windD}`}</div>
              </div>
              <div className="dtl-item dtl-item-last">
                <div className="item-title">풍속</div>
                <div className="item-value">{`${inform.windS}m/s`}</div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default CurrWeather;
