import React, { useState, useEffect } from "react";
import {
  createColumnHelper,
  flexRender,
  useReactTable,
  getCoreRowModel,
  getSortedRowModel,
} from "@tanstack/react-table";
import axios from "axios";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faX } from "@fortawesome/free-solid-svg-icons";

import apiAddress from "../../../../services/apiAddress";
import Alert from "../../../common/window/Alert";
import Reg from "../../../common/window/Reg";
import SameTypeSensor from "./SameTypeSensor";

const snsrTypes = [
  { value: "01", text: "강우량계" },
  { value: "02", text: "수위계" },
  { value: "03", text: "변위계" },
  { value: "04", text: "토양함수비" },
  { value: "05", text: "거리측정기" },
  { value: "06", text: "적설계" },
  { value: "07", text: "지하수위계" },
  { value: "08", text: "경사계" },
  { value: "09", text: "간극수압계" },
  { value: "10", text: "진동계/진동가속도계" },
  { value: "11", text: "지중경사계" },
  { value: "12", text: "하중계" },
  { value: "13", text: "구조물경사계" },
  { value: "14", text: "GNSS(GPS)" },
  { value: "15", text: "지표변위계" },
  { value: "16", text: "조위계" },
  { value: "17", text: "균열감지기" },
  { value: "18", text: "온도계" },
  { value: "19", text: "습도계" },
  { value: "20", text: "침수감지기" },
  { value: "21", text: "가속도계" },
  { value: "22", text: "변형률계" },
  { value: "23", text: "풍향계" },
  { value: "24", text: "유량계" },
  { value: "25", text: "유속계" },
];

function Sensor({ groupId, eqId, snsrData, regData, setRegData }) {
  const [isAlert, setIsAlert] = useState(false);
  const [isSave, setIsSave] = useState({
    isOpen: false,
    text: "",
  });
  const [isSame, setIsSame] = useState(false);
  const [selectData, setSelectData] = useState({});
  const [selectSetting, setSelectSetting] = useState({});
  const [brdData, setBrdData] = useState([]);
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);
  const columnHelper = createColumnHelper();
  const checkColumn = {
    id: "check",
    accessor: "check",
    header: "",
    enableSorting: false,
    size: 10,
    cell: ({ row }) => {
      return (
        <input
          type="checkbox"
          onChange={() => {
            onChangeCheck(row.original);
          }}
          checked={regData.find((item) => item.dvcId === row.original.dvcId)}
          style={{ cursor: "pointer" }}
        />
      );
    },
  };
  const settingIconColumn = {
    id: "settingIcon",
    accessor: "setting",
    header: "설정",
    enableSorting: false,
    size: 10,
    cell: ({ row }) => {
      if (row.original.isSetting) {
        // 값 설정된 것.
        return <FontAwesomeIcon icon={faCheck} style={{ color: "#35B62C" }} />;
      } else {
        // 값 설정 안된 것.
        return <FontAwesomeIcon icon={faX} style={{ color: "#C90000" }} />;
      }
    },
  };
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  function onChangeCheck(row) {
    if (regData.find((item) => item.dvcId === row.dvcId)) {
      // 체크 => 체크 해제.
      const newData = regData.filter((item) => item.dvcId !== row.dvcId);
      setRegData(newData);
    } else {
      // 체크 해제 => 체크.
      setRegData([
        ...regData,
        {
          groupId: groupId,
          eqId: eqId,
          dvcId: row.dvcId,
          warningMin: row.warningMin,
          warningMin2: row.warningMin2,
          warningMin3: row.warningMin3,
          warningMax: row.warningMax,
          warningMax2: row.warningMax2,
          warningMax3: row.warningMax3,
          alertMin: row.alertMin,
          alertMin2: row.alertMin2,
          alertMin3: row.alertMin3,
          alertMax: row.alertMax,
          alertMax2: row.alertMax2,
          alertMax3: row.alertMax3,
          evctnMin: row.evctnMin,
          evctnMin2: row.evctnMin2,
          evctnMin3: row.evctnMin3,
          evctnMax: row.evctnMax,
          evctnMax2: row.evctnMax2,
          evctnMax3: row.evctnMax3,
          warningBrdCntnId: row.warningBrdCntnId,
          alertBrdCntnId: row.alertBrdCntnId,
          evctnBrdCntnId: row.evctnBrdCntnId,
          isSetting: row.isSetting,
          useYn: "Y",
          regUsr: "SH",
        },
      ]);
    }
  }
  function onClickRow(row) {
    // 행 클릭 시
    if (selectData.dvcId === row.dvcId) {
      setSelectData({});
    } else {
      setSelectData(row);
    }
  }
  function onClickCancel() {
    // 취소 버튼 클릭 시.
    setSelectData({});
  }
  function onClickSetting() {
    // 확인 버튼 클릭 시.
    if (
      selectSetting.warningMin === "" ||
      selectSetting.warningMax === "" ||
      selectSetting.warningBrdCntnId === 0 ||
      selectSetting.alertMin === "" ||
      selectSetting.alertMax === "" ||
      selectSetting.alertBrdCntnId === 0 ||
      selectSetting.evctnMin === "" ||
      selectSetting.evctnMax === "" ||
      selectSetting.evctnBrdCntnId === 0
    ) {
      setIsAlert(true);
    } else {
      if (
        selectData.dvcDtlType === "08" ||
        selectData.dvcDtlType === "11" ||
        selectData.dvcDtlType === "13" ||
        selectData.dvcDtlType === "14"
      ) {
        if (
          selectSetting.warningMin2 === "" ||
          selectSetting.warningMax2 === "" ||
          selectSetting.alertMin2 === "" ||
          selectSetting.alertMax2 === "" ||
          selectSetting.evctnMin2 === "" ||
          selectSetting.evctnMax2 === ""
        ) {
          setIsAlert(true);
        } else {
          if (selectData.dvcDtlType === "14") {
            if (
              selectSetting.warningMin3 === "" ||
              selectSetting.warningMax3 === "" ||
              selectSetting.alertMin3 === "" ||
              selectSetting.alertMax3 === "" ||
              selectSetting.evctnMin3 === "" ||
              selectSetting.evctnMax3 === ""
            ) {
              setIsAlert(true);
            } else {
              // selectData.dvcDtlType 14인 경우.
              // 값 다 입력시.
              if (
                data.find(
                  (item) =>
                    item.dvcDtlType === selectData.dvcDtlType &&
                    item.dvcId !== selectData.dvcId
                )
              ) {
                // 같은 타입 센서 존재.
                setIsSame(true);
              } else {
                // 같은 타입 센서 존재X.
                setIsSave({
                  isOpen: true,
                  text: "입력하신 정보로 저장하시겠습니까?",
                });
              }
            }
          } else {
            // selectData.dvcDtlType 8/11/13일 경우.
            // 값 다 입력시.
            if (
              data.find(
                (item) =>
                  item.dvcDtlType === selectData.dvcDtlType &&
                  item.dvcId !== selectData.dvcId
              )
            ) {
              // 같은 타입 센서 존재.
              setIsSame(true);
            } else {
              // 같은 타입 센서 존재X.
              setIsSave({
                isOpen: true,
                text: "입력하신 정보로 저장하시겠습니까?",
              });
            }
          }
        }
      } else {
        // selectData.dvcDtlType 8/11/13/14가 아닌경우.
        // 값 다 입력시.
        if (
          data.find(
            (item) =>
              item.dvcDtlType === selectData.dvcDtlType &&
              item.dvcId !== selectData.dvcId
          )
        ) {
          // 같은 타입 센서 존재.
          setIsSame(true);
        } else {
          // 같은 타입 센서 존재X.
          setIsSave({
            isOpen: true,
            text: "입력하신 정보로 저장하시겠습니까?",
          });
        }
      }
    }
  }
  function settingSensor() {
    // 입력한 setting 값 저장.
    const changeObject = { ...selectData, ...selectSetting };
    const newData = data.map((item) => {
      if (item.dvcId === changeObject.dvcId) {
        return changeObject;
      }
      return item;
    });
    const newRegData = regData.map((item) => {
      if (item.dvcId === changeObject.dvcId) {
        return { ...changeObject, useYn: "Y", regUsr: "SH" };
      }
      return item;
    });
    setData(newData);
    setRegData(newRegData);
    setSelectData({});
  }

  /* 방송문안 조회 API */
  async function fetchData() {
    try {
      axios
        .post(
          apiAddress.broadTextGet,
          {
            brdCntnId: 0,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          const responseData = response.data.filter(
            (item) => item.useYn === "Y"
          );
          setBrdData(responseData);
        });
    } catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    fetchData();
    const snsr = snsrData.map((item) => {
      return {
        ...item,
        groupId: groupId,
        warningMin: "",
        warningMin2: "",
        warningMin3: "",
        warningMax: "",
        warningMax2: "",
        warningMax3: "",
        alertMin: "",
        alertMin2: "",
        alertMin3: "",
        alertMax: "",
        alertMax2: "",
        alertMax3: "",
        evctnMin: "",
        evctnMin2: "",
        evctnMin3: "",
        evctnMax: "",
        evctnMax2: "",
        evctnMax3: "",
        warningBrdCntnId: 0,
        alertBrdCntnId: 0,
        evctnBrdCntnId: 0,
        isSetting: false,
      };
    });
    const column = [
      checkColumn,
      columnHelper.accessor("dvcNm", {
        header: "장비명",
        size: 110,
      }),
      columnHelper.accessor(
        (row) => {
          return snsrTypes.find((type) => type.value === row.dvcDtlType)?.text;
        },
        {
          header: "타입",
          size: 115.5,
        }
      ),
      settingIconColumn,
    ];

    setData(
      snsr.sort((a, b) => {
        const dvcNmA = a.dvcNm.toLowerCase();
        const dvcNmB = b.dvcNm.toLowerCase();

        if (dvcNmA < dvcNmB) return -1;
        if (dvcNmA > dvcNmB) return 1;
        return 0;
      })
    );
    setColumns(column);
  }, [snsrData]);
  useEffect(() => {
    const column = [
      checkColumn,
      columnHelper.accessor("dvcNm", {
        header: "장비명",
        size: 110,
      }),
      columnHelper.accessor(
        (row) => {
          return snsrTypes.find((type) => type.value === row.dvcDtlType)?.text;
        },
        {
          header: "타입",
          size: 114.5,
        }
      ),
      settingIconColumn,
    ];

    setColumns(column);
  }, [regData]);
  useEffect(() => {
    setSelectSetting({
      warningMin: selectData.warningMin,
      warningMin2: selectData.warningMin2,
      warningMin3: selectData.warningMin3,
      warningMax: selectData.warningMax,
      warningMax2: selectData.warningMax2,
      warningMax3: selectData.warningMax3,
      alertMin: selectData.alertMin,
      alertMin2: selectData.alertMin2,
      alertMin3: selectData.alertMin3,
      alertMax: selectData.alertMax,
      alertMax2: selectData.alertMax2,
      alertMax3: selectData.alertMax3,
      evctnMin: selectData.evctnMin,
      evctnMin2: selectData.evctnMin2,
      evctnMin3: selectData.evctnMin3,
      evctnMax: selectData.evctnMax,
      evctnMax2: selectData.evctnMax2,
      evctnMax3: selectData.evctnMax3,
      warningBrdCntnId: selectData.warningBrdCntnId,
      alertBrdCntnId: selectData.alertBrdCntnId,
      evctnBrdCntnId: selectData.evctnBrdCntnId,
      isSetting: true,
    });
  }, [selectData]);

  return (
    <>
      {isAlert && (
        <Alert
          isOpen={isAlert}
          setIsOpen={setIsAlert}
          text={"모든 정보를 입력해주세요"}
        />
      )}
      {isSave.isOpen && (
        <Reg isOpen={isSave} setIsOpen={setIsSave} regData={settingSensor} />
      )}
      {isSame && (
        <SameTypeSensor
          isOpen={isSame}
          setIsOpen={setIsSame}
          snsrData={data}
          setSnsrData={setData}
          regData={regData}
          setRegData={setRegData}
          selectData={selectData}
          setSelectData={setSelectData}
          selectSetting={selectSetting}
        />
      )}
      <div className="setting_alert_main_setting_snsr">
        {/* 센서 리스트 */}
        <div className="table_container">
          <div className="setting_alert_header">
            <div>센서</div>
          </div>
          {data.length > 0 ? (
            <div className="table">
              <table className="react_table">
                <thead>
                  {table.getHeaderGroups().map((headerGroup) => (
                    <tr key={headerGroup.id}>
                      {headerGroup.headers.map((header) => (
                        <th
                          key={header.id}
                          style={{
                            width: isNaN(header.getSize())
                              ? "auto"
                              : `${header.getSize()}px`,
                            minWidth: isNaN(header.getSize())
                              ? "auto"
                              : `${header.getSize()}px`,
                            maxWidth: isNaN(header.getSize())
                              ? "auto"
                              : `${header.getSize()}px`,
                          }}
                        >
                          {header.isPlaceholder
                            ? null
                            : flexRender(
                                header.column.columnDef.header,
                                header.getContext()
                              )}
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody>
                  {table.getRowModel().rows.map((row, index) => (
                    <React.Fragment key={row.id}>
                      <tr
                        key={row.id}
                        onClick={() => onClickRow(row.original)}
                        className={
                          selectData.dvcId === row.original.dvcId
                            ? "tr_select"
                            : index % 2 === 0
                            ? "tr_even"
                            : "tr_odd"
                        }
                      >
                        {row.getVisibleCells().map((cell) => (
                          <td
                            key={cell.id}
                            onClick={(e) => {
                              if (cell.column.id === "check") {
                                e.stopPropagation();
                              }
                            }}
                            style={{
                              width: isNaN(cell.column.getSize())
                                ? "auto"
                                : `${cell.column.getSize()}px`,
                              minWidth: isNaN(cell.column.getSize())
                                ? "auto"
                                : `${cell.column.getSize()}px`,
                              maxWidth: isNaN(cell.column.getSize())
                                ? "auto"
                                : `${cell.column.getSize()}px`,
                              textAlign:
                                (cell.column.id === "check" ||
                                  cell.column.id === "settingIcon") &&
                                "center",
                            }}
                          >
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext()
                            )}
                          </td>
                        ))}
                      </tr>
                    </React.Fragment>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <div className="setting_data_none_container">
              등록할 센서가 없습니다
            </div>
          )}
        </div>

        {/* 센서 설정 */}
        {Object.keys(selectData).length !== 0 && (
          <div className="setting_container">
            <div className="title">
              <div className="title_imp" style={{ marginLeft: "0px" }}>
                임계치
              </div>
              <div className="title_nor">및</div>
              <div className="title_imp">방송</div>
              <div className="title_nor">설정</div>
            </div>

            {/* 임계치 및 방송 설정 */}
            <div className="main">
              <div className="threshold_container">
                <div className="title">
                  <div>주의보</div>
                </div>
                <div className="not_title">
                  <div className="input_min_max_brd">
                    <div className="title" style={{ flex: "1" }}>
                      최소
                    </div>
                    <div
                      className="title"
                      style={{ flex: "1", borderRight: "none" }}
                    >
                      최대
                    </div>
                  </div>
                  <div className="input_container">
                    <input
                      className="input"
                      type="number"
                      placeholder={
                        selectData.dvcDtlType === "08" ||
                        selectData.dvcDtlType === "11" ||
                        selectData.dvcDtlType === "13" ||
                        selectData.dvcDtlType === "14"
                          ? "x축 최소"
                          : "최소"
                      }
                      value={selectSetting.warningMin}
                      onChange={(e) =>
                        setSelectSetting({
                          ...selectSetting,
                          warningMin: e.target.value,
                        })
                      }
                    />
                    <input
                      className="input"
                      type="number"
                      placeholder={
                        selectData.dvcDtlType === "08" ||
                        selectData.dvcDtlType === "11" ||
                        selectData.dvcDtlType === "13" ||
                        selectData.dvcDtlType === "14"
                          ? "x축 최대"
                          : "최대"
                      }
                      value={selectSetting.warningMax}
                      onChange={(e) =>
                        setSelectSetting({
                          ...selectSetting,
                          warningMax: e.target.value,
                        })
                      }
                    />
                  </div>
                  {(selectData.dvcDtlType === "08" ||
                    selectData.dvcDtlType === "11" ||
                    selectData.dvcDtlType === "13" ||
                    selectData.dvcDtlType === "14") && (
                    <>
                      <div className="input_container">
                        <input
                          className="input"
                          type="number"
                          placeholder="y축 최소"
                          value={selectSetting.warningMin2}
                          onChange={(e) =>
                            setSelectSetting({
                              ...selectSetting,
                              warningMin2: e.target.value,
                            })
                          }
                        />
                        <input
                          className="input"
                          type="number"
                          placeholder="y축 최대"
                          value={selectSetting.warningMax2}
                          onChange={(e) =>
                            setSelectSetting({
                              ...selectSetting,
                              warningMax2: e.target.value,
                            })
                          }
                        />
                      </div>
                      {selectData.dvcDtlType === "14" && (
                        <div className="input_container">
                          <input
                            className="input"
                            type="number"
                            placeholder="z축 최소"
                            value={selectSetting.warningMin3}
                            onChange={(e) =>
                              setSelectSetting({
                                ...selectSetting,
                                warningMin3: e.target.value,
                              })
                            }
                          />
                          <input
                            className="input"
                            type="number"
                            placeholder="z축 최대"
                            value={selectSetting.warningMax3}
                            onChange={(e) =>
                              setSelectSetting({
                                ...selectSetting,
                                warningMax3: e.target.value,
                              })
                            }
                          />
                        </div>
                      )}
                    </>
                  )}
                  <div
                    className="input_min_max_brd"
                    style={{ borderBottom: "none" }}
                  >
                    <div className="sub_title">방송</div>
                    <select
                      value={selectSetting.warningBrdCntnId}
                      onChange={(e) =>
                        setSelectSetting({
                          ...selectSetting,
                          warningBrdCntnId: e.target.value,
                        })
                      }
                    >
                      <option value={0}>방송 선택</option>
                      {brdData.map((item) => (
                        <option key={item.brdCntnId} value={item.brdCntnId}>
                          {item.brdTitle}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
              <div className="threshold_container">
                <div className="title">
                  <div>경보</div>
                </div>
                <div className="not_title">
                  <div className="input_min_max_brd">
                    <div className="title" style={{ flex: "1" }}>
                      최소
                    </div>
                    <div
                      className="title"
                      style={{ flex: "1", borderRight: "none" }}
                    >
                      최대
                    </div>
                  </div>
                  <div className="input_container">
                    <input
                      className="input"
                      type="number"
                      placeholder={
                        selectData.dvcDtlType === "08" ||
                        selectData.dvcDtlType === "11" ||
                        selectData.dvcDtlType === "13" ||
                        selectData.dvcDtlType === "14"
                          ? "x축 최소"
                          : "최소"
                      }
                      value={selectSetting.alertMin}
                      onChange={(e) =>
                        setSelectSetting({
                          ...selectSetting,
                          alertMin: e.target.value,
                        })
                      }
                    />
                    <input
                      className="input"
                      type="number"
                      placeholder={
                        selectData.dvcDtlType === "08" ||
                        selectData.dvcDtlType === "11" ||
                        selectData.dvcDtlType === "13" ||
                        selectData.dvcDtlType === "14"
                          ? "x축 최대"
                          : "최대"
                      }
                      value={selectSetting.alertMax}
                      onChange={(e) =>
                        setSelectSetting({
                          ...selectSetting,
                          alertMax: e.target.value,
                        })
                      }
                    />
                  </div>
                  {(selectData.dvcDtlType === "08" ||
                    selectData.dvcDtlType === "11" ||
                    selectData.dvcDtlType === "13" ||
                    selectData.dvcDtlType === "14") && (
                    <>
                      <div className="input_container">
                        <input
                          className="input"
                          type="number"
                          placeholder="y축 최소"
                          value={selectSetting.alertMin2}
                          onChange={(e) =>
                            setSelectSetting({
                              ...selectSetting,
                              alertMin2: e.target.value,
                            })
                          }
                        />
                        <input
                          className="input"
                          type="number"
                          placeholder="y축 최대"
                          value={selectSetting.alertMax2}
                          onChange={(e) =>
                            setSelectSetting({
                              ...selectSetting,
                              alertMax2: e.target.value,
                            })
                          }
                        />
                      </div>
                      {selectData.dvcDtlType === "14" && (
                        <div className="input_container">
                          <input
                            className="input"
                            type="number"
                            placeholder="z축 최소"
                            value={selectSetting.alertMin3}
                            onChange={(e) =>
                              setSelectSetting({
                                ...selectSetting,
                                alertMin3: e.target.value,
                              })
                            }
                          />
                          <input
                            className="input"
                            type="number"
                            placeholder="z축 최대"
                            value={selectSetting.alertMax3}
                            onChange={(e) =>
                              setSelectSetting({
                                ...selectSetting,
                                alertMax3: e.target.value,
                              })
                            }
                          />
                        </div>
                      )}
                    </>
                  )}
                  <div
                    className="input_min_max_brd"
                    style={{ borderBottom: "none" }}
                  >
                    <div className="sub_title">방송</div>
                    <select
                      value={selectSetting.alertBrdCntnId}
                      onChange={(e) =>
                        setSelectSetting({
                          ...selectSetting,
                          alertBrdCntnId: e.target.value,
                        })
                      }
                    >
                      <option value={0}>방송 선택</option>
                      {brdData.map((item) => (
                        <option key={item.brdCntnId} value={item.brdCntnId}>
                          {item.brdTitle}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
              <div
                className="threshold_container"
                style={{ borderBottom: "none" }}
              >
                <div className="title">
                  <div>대피</div>
                </div>
                <div className="not_title">
                  <div className="input_min_max_brd">
                    <div className="title" style={{ flex: "1" }}>
                      최소
                    </div>
                    <div
                      className="title"
                      style={{ flex: "1", borderRight: "none" }}
                    >
                      최대
                    </div>
                  </div>
                  <div className="input_container">
                    <input
                      className="input"
                      type="number"
                      placeholder={
                        selectData.dvcDtlType === "08" ||
                        selectData.dvcDtlType === "11" ||
                        selectData.dvcDtlType === "13" ||
                        selectData.dvcDtlType === "14"
                          ? "x축 최소"
                          : "최소"
                      }
                      value={selectSetting.evctnMin}
                      onChange={(e) =>
                        setSelectSetting({
                          ...selectSetting,
                          evctnMin: e.target.value,
                        })
                      }
                    />
                    <input
                      className="input"
                      type="number"
                      placeholder={
                        selectData.dvcDtlType === "08" ||
                        selectData.dvcDtlType === "11" ||
                        selectData.dvcDtlType === "13" ||
                        selectData.dvcDtlType === "14"
                          ? "x축 최대"
                          : "최대"
                      }
                      value={selectSetting.evctnMax}
                      onChange={(e) =>
                        setSelectSetting({
                          ...selectSetting,
                          evctnMax: e.target.value,
                        })
                      }
                    />
                  </div>
                  {(selectData.dvcDtlType === "08" ||
                    selectData.dvcDtlType === "11" ||
                    selectData.dvcDtlType === "13" ||
                    selectData.dvcDtlType === "14") && (
                    <>
                      <div className="input_container">
                        <input
                          className="input"
                          type="number"
                          placeholder="y축 최소"
                          value={selectSetting.evctnMin2}
                          onChange={(e) =>
                            setSelectSetting({
                              ...selectSetting,
                              evctnMin2: e.target.value,
                            })
                          }
                        />
                        <input
                          className="input"
                          type="number"
                          placeholder="y축 최대"
                          value={selectSetting.evctnMax2}
                          onChange={(e) =>
                            setSelectSetting({
                              ...selectSetting,
                              evctnMax2: e.target.value,
                            })
                          }
                        />
                      </div>
                      {selectData.dvcDtlType === "14" && (
                        <div className="input_container">
                          <input
                            className="input"
                            type="number"
                            placeholder="z축 최소"
                            value={selectSetting.evctnMin3}
                            onChange={(e) =>
                              setSelectSetting({
                                ...selectSetting,
                                evctnMin3: e.target.value,
                              })
                            }
                          />
                          <input
                            className="input"
                            type="number"
                            placeholder="z축 최대"
                            value={selectSetting.evctnMax3}
                            onChange={(e) =>
                              setSelectSetting({
                                ...selectSetting,
                                evctnMax3: e.target.value,
                              })
                            }
                          />
                        </div>
                      )}
                    </>
                  )}
                  <div
                    className="input_min_max_brd"
                    style={{ borderBottom: "none" }}
                  >
                    <div className="sub_title">방송</div>
                    <select
                      value={selectSetting.evctnBrdCntnId}
                      onChange={(e) =>
                        setSelectSetting({
                          ...selectSetting,
                          evctnBrdCntnId: e.target.value,
                        })
                      }
                    >
                      <option value={0}>방송 선택</option>
                      {brdData.map((item) => (
                        <option key={item.brdCntnId} value={item.brdCntnId}>
                          {item.brdTitle}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
            </div>

            <div className="button">
              <div className="button_nor" onClick={onClickCancel}>
                취소
              </div>
              <div
                className="button_imp"
                onClick={onClickSetting}
                style={{ marginLeft: "10px" }}
              >
                저장
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default Sensor;
