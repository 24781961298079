import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Menu, Item, useContextMenu } from "react-contexify";
import {
  createColumnHelper,
  flexRender,
  useReactTable,
  getCoreRowModel,
  getSortedRowModel,
} from "@tanstack/react-table";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisVertical } from "@fortawesome/free-solid-svg-icons";

import { tokenCheck } from "../../../utils/tokenCheck";
import EdboardInform from "../../common/inform/EdboardInform";

const MENU_ID = "edboardList";

function EdboardCurr({ edbData, selected, setSelected }) {
  const movePage = useNavigate();
  const [isInform, setIsInform] = useState({ isOpen: false, data: {} }); // 전광판 목록
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);
  const columnHelper = createColumnHelper();
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });
  const menuIconColumn = {
    id: "menuIcon",
    accessor: "menu",
    header: "",
    size: 10,
    enableSorting: false,
    cell: ({ row }) => (
      <FontAwesomeIcon className="table_icon" icon={faEllipsisVertical} />
    ),
  };
  const { show } = useContextMenu({
    id: MENU_ID,
  });
  const [currInform, setCurrInform] = useState({}); // 전광판 현황

  function onClickMenu(e, row) {
    // Context 메뉴.
    e.stopPropagation();
    show({ event: e, props: { data: row } });
  }
  function menuItemClick({ id, props }) {
    // 메뉴 Item 클릭 시.
    // 토큰 확인
    tokenCheck().then((result) => {
      if (!result) {
        alert("인증 토큰이 만료되었습니다. 다시 로그인 해주세요");
        movePage("/");
      } else {
        switch (id) {
          // 정보.
          case "inform":
            setIsInform({ isOpen: true, data: props.data });
            break;
          default:
            break;
        }
      }
    });
  }

  useEffect(() => {
    if (Array.isArray(edbData) && edbData.length > 0) {
      const column = [
        columnHelper.accessor("eqNm", { header: "전광판명", size: 150 }),
        columnHelper.accessor("bdongCd", { header: "법정동코드", size: 80 }),
        columnHelper.accessor("addr", { header: "주소", size: 250 }),
        columnHelper.accessor(
          (row) => {
            const msgList = row.msgList;
            if (
              msgList.length > 0 &&
              msgList.find((item) => item.useYn === "Y")
            ) {
              return "작동중";
            } else {
              return "작동안함";
            }
          },
          { id: "status", header: "상태", size: 70 }
        ),
        menuIconColumn,
      ];

      setData(edbData);
      setColumns(column);
    } else {
      setData([]);
      setColumns([]);
    }
  }, [edbData]);
  useEffect(() => {
    if (Array.isArray(selected.msgList) && selected.msgList.length > 0) {
      if (selected.msgList.find((item) => item.useYn === "Y")) {
        setCurrInform(selected.msgList.find((item) => item.useYn === "Y"));
      } else {
        setCurrInform({});
      }
    } else {
      setCurrInform({});
    }
  }, [selected]);

  return (
    <div id="edboard-list-curr-component">
      {
        // 전광판 정보
        isInform.isOpen && (
          <EdboardInform isOpen={isInform} setIsOpen={setIsInform} />
        )
      }
      {data.length === 0 ? (
        <div className="data-none-container">등록된 전광판이 없습니다</div>
      ) : (
        <>
          {/* 전광판 목록 */}
          <div className="content-title">전광판 목록</div>
          <div className="content-list">
            <div className="list">
              <table className="react_table">
                <thead>
                  {table.getHeaderGroups().map((headerGroup) => (
                    <tr key={headerGroup.id}>
                      {headerGroup.headers.map((header) => (
                        <th
                          key={header.id}
                          onClick={header.column.getToggleSortingHandler()}
                          style={{
                            width: isNaN(header.getSize())
                              ? "auto"
                              : `${header.getSize()}px`,
                            minWidth: isNaN(header.getSize())
                              ? "auto"
                              : `${header.getSize()}px`,
                            maxWidth: isNaN(header.getSize())
                              ? "auto"
                              : `${header.getSize()}px`,
                          }}
                        >
                          {header.isPlaceholder
                            ? null
                            : flexRender(
                                header.column.columnDef.header,
                                header.getContext()
                              )}
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody>
                  {table.getRowModel().rows.map((row, index) => (
                    <tr
                      key={row.id}
                      className={
                        selected === row.original
                          ? "tr_select"
                          : index % 2 === 0
                          ? "tr_even"
                          : "tr_odd"
                      }
                      onClick={() => setSelected(row.original)}
                    >
                      {row.getVisibleCells().map((cell) => (
                        <td
                          key={cell.id}
                          onClick={(e) => {
                            if (cell.column.id === "menuIcon") {
                              onClickMenu(e, row.original);
                            }
                          }}
                          style={{
                            width: isNaN(cell.column.getSize())
                              ? "auto"
                              : `${cell.column.getSize()}px`,
                            minWidth: isNaN(cell.column.getSize())
                              ? "auto"
                              : `${cell.column.getSize()}px`,
                            maxWidth: isNaN(cell.column.getSize())
                              ? "auto"
                              : `${cell.column.getSize()}px`,
                            textAlign:
                              (cell.column.id === "bdongCd" ||
                                cell.column.id === "status" ||
                                cell.column.id === "menuIcon") &&
                              "center",
                            color:
                              cell.column.id === "status" &&
                              row.original.msgList.length > 0 &&
                              row.original.msgList.find(
                                (item) => item.useYn === "Y"
                              ) &&
                              "#3DC62A",
                          }}
                        >
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>

          {/* 전광판 현황 */}
          <div className="content-title">
            <div>전광판 현황</div>
          </div>
          <div className="content-curr">
            {Object.keys(currInform).length === 0 ? (
              <div className="data-none-container">
                해당 전광판은 작동중이지 않습니다
              </div>
            ) : (
              <>
                <div className="typing-container">
                  <div className="title">입장 효과</div>
                  <div className="one-div">{currInform.entryEffect}</div>
                </div>
                <div className="typing-container">
                  <div className="title">표시 시간</div>
                  <div className="time-div">{currInform.holdTime}</div>
                  <div className="inner-title">속도</div>
                  <div className="spd-container">
                    <div className="spd-slider">
                      <Slider
                        className="slider"
                        min={1}
                        max={99}
                        step={1}
                        value={currInform.speed}
                        trackStyle={{ background: "#418dff" }}
                        handleStyle={{ borderColor: "#000" }}
                        disabled={true}
                      />
                    </div>
                    <div className="spd-div">{currInform.speed}</div>
                  </div>
                </div>
                <div className="typing-container flex-typing-container">
                  <div className="title">내용</div>
                  <textarea
                    type="text"
                    value={currInform.msg}
                    disabled={true}
                  />
                </div>
                <div className="typing-container last-typing-container">
                  <div className="title">퇴장 효과</div>
                  <div className="one-div">{currInform.exitEffect}</div>
                </div>
              </>
            )}
          </div>
        </>
      )}

      {/* Context 메뉴 */}
      <Menu id={MENU_ID}>
        <Item id="inform" onClick={menuItemClick}>
          정보
        </Item>
      </Menu>
    </div>
  );
}

export default EdboardCurr;
