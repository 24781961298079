import { useState, useEffect } from "react";
import {
  createColumnHelper,
  flexRender,
  useReactTable,
  getCoreRowModel,
  getSortedRowModel,
} from "@tanstack/react-table";

import EdboardInform from "../../../common/inform/EdboardInform";

function EDBoard({ edbData, delData, setDelData }) {
  const [isInform, setIsInform] = useState({
    isOpen: false,
    data: {},
  });
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);
  const columnHelper = createColumnHelper();
  const checkColumn = {
    id: "check",
    accessor: "check",
    header: "",
    size: 20,
    enableSorting: false,
    cell: ({ row }) => (
      <input
        type="checkbox"
        checked={
          delData.length > 0 &&
          delData.find((item) => item === row.original.rgnEqId)
        }
        onChange={() => onChangeCheck(row.original)}
        style={{ cursor: "pointer" }}
      />
    ),
  };
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  function dateToFormat(date) {
    return date.slice(0, 4) + "-" + date.slice(4, 6) + "-" + date.slice(6, 8);
  }
  function onChangeCheck(row) {
    // 체크 이벤트.
    if (delData.find((item) => item === row.rgnEqId)) {
      // 체크 => 체크해제.
      const clearData = delData.filter((item) => item !== row.rgnEqId);
      setDelData(clearData);
    } else {
      // 체크해제 => 체크.
      setDelData([...delData, row.rgnEqId]);
    }
  }
  function onDoubleClickRow(row) {
    // 행 더블 클릭.
    setIsInform({
      isOpen: true,
      data: row,
    });
  }

  useEffect(() => {
    const column = [
      checkColumn,
      columnHelper.accessor("eqNm", {
        header: "전광판명",
        size: "auto",
      }),
      columnHelper.accessor(
        (row) => {
          return dateToFormat(row.instlDt);
        },
        {
          id: "instlDt",
          header: "설치일자",
          size: 80,
        }
      ),
      columnHelper.accessor("mnfct", { header: "제조업체", size: 120 }),
      columnHelper.accessor("modelNm", { header: "모델", size: 80 }),
      columnHelper.accessor("commAddr", {
        header: "IP",
        size: 100,
      }),
      columnHelper.accessor("commPort", {
        header: "Port",
        size: 50,
      }),
    ];

    setData(edbData);
    setColumns(column);
  }, [edbData]);
  useEffect(() => {
    const column = [
      checkColumn,
      columnHelper.accessor("eqNm", {
        header: "전광판명",
        size: "auto",
      }),
      columnHelper.accessor(
        (row) => {
          return dateToFormat(row.instlDt);
        },
        {
          id: "instlDt",
          header: "설치일자",
          size: 80,
        }
      ),
      columnHelper.accessor("mnfct", { header: "제조업체", size: 120 }),
      columnHelper.accessor("modelNm", { header: "모델", size: 80 }),
      columnHelper.accessor("commAddr", {
        header: "IP",
        size: 100,
      }),
      columnHelper.accessor("commPort", {
        header: "Port",
        size: 50,
      }),
    ];
    setColumns(column);
  }, [delData]);

  return (
    <>
      {isInform.isOpen && (
        <EdboardInform isOpen={isInform} setIsOpen={setIsInform} />
      )}
      {edbData.length > 0 ? ( // 데이터 있을 경우.
        <table className="react_table">
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th
                    key={header.id}
                    onClick={header.column.getToggleSortingHandler()}
                    style={{
                      width: isNaN(header.getSize())
                        ? "auto"
                        : `${header.getSize()}px`,
                      textAlign:
                        header.id === "eqNm" ||
                        header.id === "mnfct" ||
                        header.id === "commAddr"
                          ? "left"
                          : "center",
                    }}
                  >
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row, index) => (
              <tr
                key={row.id}
                onDoubleClick={() => onDoubleClickRow(row.original)}
              >
                {row.getVisibleCells().map((cell) => (
                  <td
                    key={cell.id}
                    onDoubleClick={(e) => {
                      if (cell.column.id === "check") {
                        e.stopPropagation();
                      }
                    }}
                    style={{
                      width: isNaN(cell.column.getSize())
                        ? "auto"
                        : `${cell.column.getSize()}px`,
                      maxWidth: isNaN(cell.column.getSize())
                        ? "auto"
                        : `${cell.column.getSize()}px`,
                      minWidth: isNaN(cell.column.getSize())
                        ? "auto"
                        : `${cell.column.getSize()}px`,
                      textAlign:
                        (cell.column.id === "check" ||
                          cell.column.id === "instlDt" ||
                          cell.column.id === "modelNm" ||
                          cell.column.id === "commPort") &&
                        "center",
                    }}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        // 데이터 없을 경우
        <div className="setting_data_none_container">
          <div>등록된 전광판이 없습니다</div>
        </div>
      )}
    </>
  );
}

export default EDBoard;
