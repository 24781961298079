import { KMAICONS } from "../constants/kmaIcons";

// 풍향
export function getWindDirection(value) {
  const valueInt = parseInt(value);

  if (
    valueInt === 0 ||
    valueInt === 90 ||
    valueInt === 180 ||
    valueInt === 270 ||
    valueInt === 360
  ) {
    if (valueInt === 90) {
      // 동
      return "동";
    } else if (valueInt === 180) {
      // 남
      return "남";
    } else if (valueInt === 270) {
      // 서
      return "서";
    } else {
      // 북
      return "북";
    }
  } else {
    if (0 < valueInt < 90) {
      // 북동
      return "북동";
    } else if (90 < valueInt < 180) {
      // 남동
      return "남동";
    } else if (180 < valueInt && valueInt < 270) {
      // 남서
      return "남서";
    } else {
      // 북서
      return "북서";
    }
  }
}

// 아이콘 & 아이콘 텍스트
export function getIconAndText(sky, pty) {
  let inform = { icon: "", text: "" };
  if (pty === "0") {
    if (sky === "1") {
      // 맑음
      inform = {
        icon: KMAICONS.clear,
        text: "맑음",
      };
    }
    if (sky === "3") {
      // 구름많음
      inform = {
        icon: KMAICONS.partlyCloudy,
        text: "구름많음",
      };
    }
    if (sky === "4") {
      inform = {
        icon: KMAICONS.cloudy,
        text: "흐림",
      };
    }
  } else {
    if (pty === "1") {
      // 비
      inform = {
        icon: KMAICONS.rain,
        text: "비",
      };
    }
    if (pty === "2") {
      // 비/눈
      inform = {
        icon: KMAICONS.rainSnow,
        text: "비 또는 눈",
      };
    }
    if (pty === "3") {
      // 눈
      inform = {
        icon: KMAICONS.snow,
        text: "눈",
      };
    }
    if (pty === "4") {
      // 소나기
      inform = {
        icon: KMAICONS.shower,
        text: "소나기",
      };
    }
    if (pty === "5") {
      // 빗방울
      inform = {
        icon: KMAICONS.rain,
        text: "빗방울",
      };
    }
    if (pty === "6") {
      // 빗방울눈날림
      inform = {
        icon: KMAICONS.rainSnow,
        text: "빗방울 눈날림",
      };
    }
    if (pty === "7") {
      // 눈날림
      inform = {
        icon: KMAICONS.snow,
        text: "눈날림",
      };
    }
  }

  return inform;
}
