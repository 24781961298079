import { useState, useEffect } from "react";

import { getEdboardMessageLog } from "../../services/internal/edboardAPI";
import loadingImg from "../../assets/common/loading.gif";
import EdboardList from "./edboard/EdboardList";
import MessageInform from "./edboard/MessageInform";

function EdboardCurr() {
  const [isLoading, setIsLoading] = useState(false);
  const [edbData, setEdbData] = useState([]);
  const [selectedEdb, setSelectedEdb] = useState({});

  function fetchEdboard() {
    /* 전광판현황 조회 */
    setIsLoading(true);
    getEdboardMessageLog().then((response) => {
      setIsLoading(false);
      if (Array.isArray(response) && response.length > 0) {
        setEdbData(response);
        setSelectedEdb(response[0]);
      } else {
        setEdbData([]);
        setSelectedEdb({});
      }
    });
  }

  useEffect(() => {
    fetchEdboard();

    const intervalId = setInterval(() => {
      fetchEdboard();
    }, 300000); // 5분
    return () => clearInterval(intervalId);
  }, []);

  return (
    <div id="allstatus-edboard-component">
      {isLoading ? (
        <div className="loading-container">
          <img src={loadingImg} alt="" />{" "}
        </div>
      ) : !Array.isArray(edbData) || edbData.length <= 0 ? (
        <div className="data-none-container" style={{ fontSize: "0.8rem" }}>
          등록된 전광판이 없습니다
        </div>
      ) : (
        <>
          {/* 전광판 리스트 */}
          <div className="edboard-list">
            <EdboardList
              edbData={edbData}
              selected={selectedEdb}
              setSelected={setSelectedEdb}
            />
          </div>
          {/* 해당 전광판 현황 */}
          <div className="edboard-message">
            <MessageInform selected={selectedEdb} />
          </div>
        </>
      )}
    </div>
  );
}

export default EdboardCurr;
