import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setMenu } from "../../../redux/modules/menu";

import { tokenCheck } from "../../../utils/tokenCheck";
import Time from "../../../components/allstatus/Time";
import CurrFore from "../../../components/allstatus/CurrFore";
import WeatherAlert from "../../../components/allstatus/WeatherAlert";
import SatelliteRadar from "../../../components/allstatus/SatelliteRadar";
import Map from "../../../components/allstatus/Map";
import ConnState from "../../../components/allstatus/ConnState";
import Data from "../../../components/allstatus/Data";
import BroadHistory from "../../../components/allstatus/BroadHistory";
import EdboardCurr from "../../../components/allstatus/EdboardCurr";

const PAGE_VALUE = "종합현황";

function AllStatus() {
  const dispatch = useDispatch();
  const movePage = useNavigate();
  const [isToken, setIsToken] = useState(false);

  useEffect(() => {
    dispatch(setMenu(PAGE_VALUE));

    // 토큰 확인
    tokenCheck().then((result) => {
      if (!result) {
        alert("인증 토큰이 만료되었습니다. 다시 로그인 해주세요");
        movePage("/");
      } else {
        setIsToken(true);
      }
    });
  }, []);

  return (
    <div id="allstatus-page">
      {isToken && (
        <>
          {/* 시간 */}
          <div className="time">
            <Time />
          </div>

          <div className="allstatus-not-time">
            {/* 날씨 */}
            <div className="allstatus-weather">
              {/* 현재날씨 & 기상예보 */}
              <div className="curr-forecast">
                <div className="title">날씨 & 예보</div>
                <div className="contents">
                  <CurrFore />
                </div>
              </div>

              {/* 기상 특보 */}
              <div className="alert">
                <div className="title">기상 특보</div>
                <div className="contents">
                  <WeatherAlert />
                </div>
              </div>

              {/* 위성 & 레이더 */}
              <div className="sate-radar">
                <div className="title">위성 & 레이더</div>
                <div className="contents">
                  <SatelliteRadar />
                </div>
              </div>
            </div>

            <div className="allstatus-not-weather">
              <div className="allstatus-map-data">
                {/* 지도 */}
                <div className="map">
                  <Map />
                </div>

                <div className="allstatus-data">
                  {/* 센서 통신 상태 */}
                  <div className="conn-state">
                    <div className="title">센서 통신 상태</div>
                    <div className="contents">
                      <ConnState />
                    </div>
                  </div>

                  {/* 데이터 현황 */}
                  <div className="data-curr">
                    <div className="title">데이터 현황</div>
                    <div className="contents">
                      <Data />
                    </div>
                  </div>
                </div>
              </div>

              <div className="allstatus-broad-edboard">
                {/* 방송내역 */}
                <div className="broad">
                  <div className="title">방송 내역</div>
                  <div className="contents">
                    <BroadHistory />
                  </div>
                </div>

                {/* 전광판 내역 */}
                <div className="edboard">
                  <div className="title">전광판 현황</div>
                  <div className="contents">
                    <EdboardCurr />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default AllStatus;
