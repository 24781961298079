function MapType({ mapType, setMapType }) {
  function onClickType(type) {
    setMapType(type);
  }

  return (
    <div className="map-select map-select-type">
      <div
        className={
          mapType === "satellite"
            ? "select-item select-item-left select-item-select"
            : "select-item select-item-left"
        }
        onClick={() => onClickType("satellite")}
      >
        위성
      </div>
      <div
        className={
          mapType === "normal"
            ? "select-item select-item-right select-item-select"
            : "select-item select-item-right"
        }
        onClick={() => onClickType("normal")}
      >
        일반
      </div>
    </div>
  );
}

export default MapType;
