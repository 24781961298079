import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { setMenu, setSubmenu } from "../../../redux/modules/menu";
import { useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ko from "date-fns/locale/ko";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome, faList, faMessage } from "@fortawesome/free-solid-svg-icons";

import { tokenCheck } from "../../../utils/tokenCheck";
import { DATEPICKERSTYLES } from "../../../constants/datepickerStyles";
import { getCurrTime, zeroPlusNum } from "../../../utils/time";
import { getSMS, getDetailSMS } from "../../../services/internal/smsAPI";
import Loading from "../../../components/common/window/Loading";
import SMSHistoryList from "../../../components/sms/smsList/SMSHistoryList";
import SMSDetail from "../../../components/sms/smsList/SMSDetail";

const PAGE_VALUE = "SMS";
const SUBPAGE_VALUE = "SMS내역";

function SMSList() {
  const dispatch = useDispatch();
  const [isToken, setIsToken] = useState(false);
  const movePage = useNavigate();
  // SMS 내역
  const daysAgo2 = new Date();
  daysAgo2.setDate(daysAgo2.getDate() - 2);
  const [fromDt, setFromDt] = useState(daysAgo2);
  const [fromDtOk, setFromDtOk] = useState(daysAgo2);
  const [toDt, setToDt] = useState(new Date());
  const [toDtOk, setToDtOk] = useState(new Date());
  const [curPage, setCurPage] = useState(0);
  const [isNext, setIsNext] = useState(false);
  const [listData, setListData] = useState([]);
  // SMS 상세정보
  const [selected, setSelected] = useState({});
  const [selectedData, setSelectedData] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  function onClickQuery() {
    // SMS 내역 조회
    // 토큰 확인
    tokenCheck().then((result) => {
      if (!result) {
        alert("인증 토큰이 만료되었습니다. 다시 로그인 해주세요");
        movePage("/");
      } else {
        setIsToken(true);
        const fromDtYear = fromDt.getFullYear();
        const fromDtMonth = fromDt.getMonth() + 1;
        const fromDtDay = fromDt.getDate();
        const fromDtString =
          zeroPlusNum(fromDtYear) +
          zeroPlusNum(fromDtMonth) +
          zeroPlusNum(fromDtDay);

        const toDtYear = toDt.getFullYear();
        const toDtMonth = toDt.getMonth() + 1;
        const toDtDay = toDt.getDate();
        const toDtString =
          zeroPlusNum(toDtYear) + zeroPlusNum(toDtMonth) + zeroPlusNum(toDtDay);

        setCurPage(0);
        setFromDtOk(fromDt);
        setToDtOk(toDt);
        fetchSMS(0, fromDtString + "000000", toDtString + "235959");
      }
    });
  }
  function onClickPrevious() {
    // 이전 페이지 조회
    // 토큰 확인
    tokenCheck().then((result) => {
      if (!result) {
        alert("인증 토큰이 만료되었습니다. 다시 로그인 해주세요");
        movePage("/");
      } else {
        const prePage = curPage - 1;
        const fromDtString =
          zeroPlusNum(fromDtOk.getFullYear()) +
          zeroPlusNum(fromDtOk.getMonth() + 1) +
          zeroPlusNum(fromDtOk.getDate());
        const toDtString =
          zeroPlusNum(toDtOk.getFullYear()) +
          zeroPlusNum(toDtOk.getMonth() + 1) +
          zeroPlusNum(toDtOk.getDate());

        setCurPage(prePage);
        fetchSMS(prePage, fromDtString + "000000", toDtString + "235959");
      }
    });
  }
  function onClickNext() {
    // 다음 페이지 조회
    // 토큰 확인
    tokenCheck().then((result) => {
      if (!result) {
        alert("인증 토큰이 만료되었습니다. 다시 로그인 해주세요");
        movePage("/");
      } else {
        const nextPage = curPage + 1;
        const fromDtString =
          zeroPlusNum(fromDtOk.getFullYear()) +
          zeroPlusNum(fromDtOk.getMonth() + 1) +
          zeroPlusNum(fromDtOk.getDate());
        const toDtString =
          zeroPlusNum(toDtOk.getFullYear()) +
          zeroPlusNum(toDtOk.getMonth() + 1) +
          zeroPlusNum(toDtOk.getDate());

        setCurPage(nextPage);
        fetchSMS(nextPage, fromDtString + "000000", toDtString + "235959");
      }
    });
  }

  /* SMS */
  function fetchSMS(page, startDate, endDate) {
    setIsLoading(true);
    getSMS(page, 30, startDate, endDate).then((sms) => {
      // 데이터 이전&이후 페이지 확인
      if (sms.next_yn === "N") {
        setIsNext(false);
      } else {
        setIsNext(true);
      }

      // 데이터 가공
      if (sms.list.length !== 0) {
        const promises = sms.list.map((smsInform) => {
          const msgLog = JSON.parse(smsInform.sendLog);
          return getDetailSMS(1, 30, msgLog.msg_id).then((detail) => {
            let smsState = "";
            let failCount = 0;
            detail.list.forEach((detailInform) => {
              if (
                detailInform.sms_state !== "발송완료" &&
                detailInform.sms_state !== "전송중"
              ) {
                // 발송 실패 건 O
                failCount = failCount + 1;
              }
              if (detailInform.sms_state === "전송중") {
                // 발송 중
                failCount = failCount + 999;
              }
            });

            const smsCnt =
              parseInt(msgLog.success_cnt) + parseInt(msgLog.error_cnt);
            if (failCount >= 999) {
              // 전송중
              smsState = "전송요청완료";
            } else if (failCount > 0) {
              // 전송 실패
              smsState = "전송실패 " + (smsCnt - failCount) + "/" + smsCnt;
            } else {
              // 전송 성공
              smsState = "전송성공 " + smsCnt + "/" + smsCnt;
            }

            return { ...smsInform, smsState: smsState, detail: detail.list };
          });
        });

        Promise.all(promises).then((promise) => {
          setListData(promise);
          setSelected(promise[0]);
          setSelectedData(promise[0].detail);
          setIsLoading(false);
        });
      } else {
        setListData([]);
        setSelected({});
        setSelectedData([]);
        setIsLoading(false);
      }
    });
  }

  useEffect(() => {
    dispatch(setMenu(PAGE_VALUE));
    dispatch(setSubmenu(SUBPAGE_VALUE));

    // 토큰 확인
    tokenCheck().then((result) => {
      if (!result) {
        alert("인증 토큰이 만료되었습니다. 다시 로그인 해주세요");
        movePage("/");
      } else {
        setIsToken(true);
      }
    });

    const daysAgo2 = new Date();
    daysAgo2.setDate(daysAgo2.getDate() - 2);
    const today =
      zeroPlusNum(getCurrTime().year) +
      zeroPlusNum(getCurrTime().month) +
      zeroPlusNum(getCurrTime().day);
    const ago2 =
      zeroPlusNum(daysAgo2.getFullYear()) +
      zeroPlusNum(daysAgo2.getMonth() + 1) +
      zeroPlusNum(daysAgo2.getDate());

    fetchSMS(0, ago2 + "000000", today + "235959");
  }, []);
  useEffect(() => {
    setSelectedData(selected.detail);
  }, [selected]);

  return (
    <div className="outlet_container">
      {isLoading && <Loading isOpen={isLoading} />}
      {isToken && (
        <>
          <div className="header_container">
            <div className="title">
              <div className="title_imp">SMS 내역</div>
            </div>
            <div className="path">
              <FontAwesomeIcon icon={faHome} />
              <div className="text">&gt; SMS &gt; SMS내역</div>
            </div>
          </div>

          <div className="main_container">
            <div id="sms-list-page">
              {/* SMS 내역 */}
              <div className="sms-list-container">
                <div className="contents_container">
                  <div className="contents_header">
                    <div className="title">
                      <FontAwesomeIcon icon={faList} />
                      <div className="text">SMS 내역</div>
                    </div>
                    <div className="sms-list-search-container">
                      <DatePicker
                        selected={fromDt}
                        onChange={(date) => setFromDt(date)}
                        dateFormat="yyyy-MM-dd"
                        maxDate={toDt}
                        locale={ko}
                        customInput={<DATEPICKERSTYLES.sms />}
                      />
                      <div className="search-tilde">~</div>
                      <DatePicker
                        selected={toDt}
                        onChange={(date) => setToDt(date)}
                        dateFormat="yyyy-MM-dd"
                        minDate={fromDt}
                        maxDate={getCurrTime().date}
                        locale={ko}
                        customInput={<DATEPICKERSTYLES.sms />}
                      />
                      <div className="button button_imp" onClick={onClickQuery}>
                        조회
                      </div>
                    </div>
                  </div>
                  <div className="contents_main">
                    <SMSHistoryList
                      listData={listData}
                      selected={selected}
                      setSelected={setSelected}
                    />
                  </div>
                  <div className="contents_footer">
                    <div className="sms-list-page-container">
                      {curPage > 0 && (
                        <div
                          className="page-change previous"
                          onClick={onClickPrevious}
                        >
                          &lt; &lt; 이전페이지
                        </div>
                      )}
                      {isNext && (
                        <div className="page-change next" onClick={onClickNext}>
                          다음페이지 &gt; &gt;
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              {/* SMS 상세정보 */}
              <div className="sms-detail-container">
                <div className="contents_container">
                  <div className="contents_header">
                    <div className="title">
                      <FontAwesomeIcon icon={faMessage} />
                      <div className="text">SMS 상세정보</div>
                    </div>
                  </div>
                  <div className="contents_main">
                    <SMSDetail
                      selected={selected}
                      selectedData={selectedData}
                    />
                  </div>
                  <div className="contents_footer"></div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default SMSList;
