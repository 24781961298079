import { useState, useEffect } from "react";
import Modal from "react-modal";
import axios from "axios";

import apiAddress from "../../../../services/apiAddress";
import Edit from "../../../common/window/Edit";
import SensorEditSame from "./SensorEditSame";

Modal.setAppElement("#root");

function SensorEdit({ isOpen, setIsOpen, snsrData, isOk, setIsOk }) {
  const [isSame, setIsSame] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [data, setData] = useState(isOpen.data);
  const [brdData, setBrdData] = useState([]);

  function onClickClose() {
    // 닫기.
    setIsOpen({
      isOpen: false,
      data: {},
    });
  }
  function onClickEdit() {
    // 수정
    if (
      snsrData
        .filter((item) => item.dvcId !== data.dvcId)
        .find((item) => item.info.dvcDtlType === data.info.dvcDtlType)
    ) {
      setIsSame(true);
    } else {
      setIsEdit(true);
    }
  }

  /* 방송문안 조회 API */
  async function fetchBrd() {
    try {
      axios
        .post(
          apiAddress.broadTextGet,
          {
            brdCntnId: 0,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          const responseData = response.data.filter(
            (item) => item.useYn === "Y"
          );
          setBrdData(responseData);
        });
    } catch (e) {
      console.log(e);
    }
  }
  /* 경보그룹 - dvc 수정 API */
  async function fetchDvc() {
    try {
      axios
        .post(apiAddress.groupEquipDeviceSave, [{ ...data, modUsr: "SHMOD" }], {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          setIsOpen({
            isOpen: false,
            data: {},
          });
          setIsOk(!isOk);
        });
    } catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    fetchBrd();
  }, []);

  return (
    <Modal isOpen={isOpen.isOpen} style={modalStyles}>
      {isEdit && (
        <Edit isOpen={isEdit} setIsOpen={setIsEdit} editData={fetchDvc} />
      )}
      {isSame && (
        <SensorEditSame
          isOpen={isSame}
          setIsOpen={setIsSame}
          setIsAllOpen={setIsOpen}
          snsrData={snsrData.filter(
            (item) =>
              item.dvcId !== data.dvcId &&
              item.info.dvcDtlType === data.info.dvcDtlType
          )}
          selectData={data}
          isOk={isOk}
          setIsOk={setIsOk}
        />
      )}
      <div className="modal_title">
        <div>임계치 및 방송 수정</div>
        <div style={{ fontSize: "0.9rem", fontWeight: "normal" }}>
          {isOpen.data.info.dvcNm}
        </div>
      </div>
      <div className="modal_container">
        {/* Main */}
        <div className="modal_main_column">
          <div className="threshold_input_container">
            <div className="title">
              <div>주의보</div>
            </div>
            <div className="not_title">
              <div className="sub_title_container">
                <div className="left-title">최소</div>
                <div className="right-title">최대</div>
              </div>
              <div className="input_container">
                <input
                  type="number"
                  placeholder={
                    data.info.dvcDtlType === "08" ||
                    data.info.dvcDtlType === "11" ||
                    data.info.dvcDtlType === "13" ||
                    data.info.dvcDtlType === "14"
                      ? "x축 최소"
                      : "최소"
                  }
                  value={data.warningMin}
                  onChange={(e) =>
                    setData({ ...data, warningMin: e.target.value })
                  }
                />
                <input
                  type="number"
                  placeholder={
                    data.info.dvcDtlType === "08" ||
                    data.info.dvcDtlType === "11" ||
                    data.info.dvcDtlType === "13" ||
                    data.info.dvcDtlType === "14"
                      ? "x축 최대"
                      : "최대"
                  }
                  value={data.warningMax}
                  onChange={(e) =>
                    setData({ ...data, warningMax: e.target.value })
                  }
                />
              </div>
              {(data.info.dvcDtlType === "08" ||
                data.info.dvcDtlType === "11" ||
                data.info.dvcDtlType === "13" ||
                data.info.dvcDtlType === "14") && (
                <div className="input_container">
                  <input
                    type="number"
                    placeholder="y축 최소"
                    value={data.warningMin2}
                    onChange={(e) =>
                      setData({ ...data, warningMin2: e.target.value })
                    }
                  />
                  <input
                    type="number"
                    placeholder="y축 최대"
                    value={data.warningMax2}
                    onChange={(e) =>
                      setData({ ...data, warningMax2: e.target.value })
                    }
                  />
                </div>
              )}
              {data.info.dvcDtlType === "14" && (
                <div className="input_container">
                  <input
                    type="number"
                    placeholder="z축 최소"
                    value={data.warningMin3}
                    onChange={(e) =>
                      setData({ ...data, warningMin3: e.target.value })
                    }
                  />
                  <input
                    type="number"
                    placeholder="z축 최대"
                    value={data.warningMax3}
                    onChange={(e) =>
                      setData({ ...data, warningMax3: e.target.value })
                    }
                  />
                </div>
              )}
              <div className="brd_container">
                <div className="title">방송</div>
                <select
                  value={data.warningBrdCntnId}
                  onChange={(e) =>
                    setData({ ...data, warningBrdCntnId: e.target.value })
                  }
                >
                  {brdData.map((item) => (
                    <option key={item.brdCntnId} value={item.brdCntnId}>
                      {item.brdTitle}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className="threshold_input_container">
            <div className="title">경보</div>
            <div className="not_title">
              <div className="sub_title_container">
                <div className="left-title">최소</div>
                <div className="right-title">최대</div>
              </div>
              <div className="input_container">
                <input
                  type="number"
                  placeholder={
                    data.info.dvcDtlType === "08" ||
                    data.info.dvcDtlType === "11" ||
                    data.info.dvcDtlType === "13" ||
                    data.info.dvcDtlType === "14"
                      ? "x축 최소"
                      : "최소"
                  }
                  value={data.alertMin}
                  onChange={(e) =>
                    setData({ ...data, alertMin: e.target.value })
                  }
                />
                <input
                  type="number"
                  placeholder={
                    data.info.dvcDtlType === "08" ||
                    data.info.dvcDtlType === "11" ||
                    data.info.dvcDtlType === "13" ||
                    data.info.dvcDtlType === "14"
                      ? "x축 최대"
                      : "최대"
                  }
                  value={data.alertMax}
                  onChange={(e) =>
                    setData({ ...data, alertMax: e.target.value })
                  }
                />
              </div>
              {(data.info.dvcDtlType === "08" ||
                data.info.dvcDtlType === "11" ||
                data.info.dvcDtlType === "13" ||
                data.info.dvcDtlType === "14") && (
                <div className="input_container">
                  <input
                    type="number"
                    placeholder="y축 최소"
                    value={data.alertMin2}
                    onChange={(e) =>
                      setData({ ...data, alertMin2: e.target.value })
                    }
                  />
                  <input
                    type="number"
                    placeholder="y축 최대"
                    value={data.alertMax2}
                    onChange={(e) =>
                      setData({ ...data, alertMax2: e.target.value })
                    }
                  />
                </div>
              )}
              {data.info.dvcDtlType === "14" && (
                <div className="input_container">
                  <input
                    type="number"
                    placeholder="z축 최소"
                    value={data.alertMin3}
                    onChange={(e) =>
                      setData({ ...data, alertMin3: e.target.value })
                    }
                  />
                  <input
                    type="number"
                    placeholder="z축 최대"
                    value={data.alertMax3}
                    onChange={(e) =>
                      setData({ ...data, alertMax3: e.target.value })
                    }
                  />
                </div>
              )}
              <div className="brd_container">
                <div className="title">방송</div>
                <select
                  value={data.alertBrdCntnId}
                  onChange={(e) =>
                    setData({ ...data, alertBrdCntnId: e.target.value })
                  }
                >
                  {brdData.map((item) => (
                    <option key={item.brdCntnId} value={item.brdCntnId}>
                      {item.brdTitle}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div
            className="threshold_input_container"
            style={{ borderBottom: "none" }}
          >
            <div className="title">대피</div>
            <div className="not_title">
              <div className="sub_title_container">
                <div className="left-title">최소</div>
                <div className="right-title">최대</div>
              </div>
              <div className="input_container">
                <input
                  type="number"
                  placeholder={
                    data.info.dvcDtlType === "08" ||
                    data.info.dvcDtlType === "11" ||
                    data.info.dvcDtlType === "13" ||
                    data.info.dvcDtlType === "14"
                      ? "x축 최소"
                      : "최소"
                  }
                  value={data.evctnMin}
                  onChange={(e) =>
                    setData({ ...data, evctnMin: e.target.value })
                  }
                />
                <input
                  type="number"
                  placeholder={
                    data.info.dvcDtlType === "08" ||
                    data.info.dvcDtlType === "11" ||
                    data.info.dvcDtlType === "13" ||
                    data.info.dvcDtlType === "14"
                      ? "x축 최대"
                      : "최대"
                  }
                  value={data.evctnMax}
                  onChange={(e) =>
                    setData({ ...data, evctnMax: e.target.value })
                  }
                />
              </div>
              {(data.info.dvcDtlType === "08" ||
                data.info.dvcDtlType === "11" ||
                data.info.dvcDtlType === "13" ||
                data.info.dvcDtlType === "14") && (
                <div className="input_container">
                  <input
                    type="number"
                    placeholder="y축 최소"
                    value={data.evctnMin2}
                    onChange={(e) =>
                      setData({ ...data, evctnMin2: e.target.value })
                    }
                  />
                  <input
                    type="number"
                    placeholder="y축 최대"
                    value={data.evctnMax2}
                    onChange={(e) =>
                      setData({ ...data, evctnMax2: e.target.value })
                    }
                  />
                </div>
              )}
              {data.info.dvcDtlType === "14" && (
                <div className="input_container">
                  <input
                    type="number"
                    placeholder="z축 최소"
                    value={data.evctnMin3}
                    onChange={(e) =>
                      setData({ ...data, evctnMin3: e.target.value })
                    }
                  />
                  <input
                    type="number"
                    placeholder="z축 최대"
                    value={data.evctnMax3}
                    onChange={(e) =>
                      setData({ ...data, evctnMax3: e.target.value })
                    }
                  />
                </div>
              )}
              <div className="brd_container">
                <div className="title">방송</div>
                <select
                  value={data.evctnBrdCntnId}
                  onChange={(e) =>
                    setData({ ...data, evctnBrdCntnId: e.target.value })
                  }
                >
                  {brdData.map((item) => (
                    <option key={item.brdCntnId} value={item.brdCntnId}>
                      {item.brdTitle}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        </div>

        {/* Footer */}
        <div className="modal_footer">
          <div className="button_nor" onClick={onClickClose}>
            닫기
          </div>
          <div
            className="button_imp"
            onClick={onClickEdit}
            style={{ marginLeft: "10px" }}
          >
            수정
          </div>
        </div>
      </div>
    </Modal>
  );
}

const modalStyles = {
  content: {
    width: "500px",
    height: "600px",
    padding: "0px",
    background: "#202020",
    border: "none",
    borderRadius: "5px",
    boxShadow: "-5px 5px 10px rgba(0, 0, 0, 0.7)",
    margin: "auto",
  },
  overlay: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "fixed",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: "rgba(0, 0, 0, 0.4)",
  },
};

export default SensorEdit;
