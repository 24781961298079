import Modal from "react-modal";

import { MODALSTYLES } from "../../../constants/modalStyles";

Modal.setAppElement("#root");

function Delete({ isOpen, setIsOpen, delData }) {
  function onClickCancel() {
    setIsOpen({
      isOpen: false,
      text: "",
    });
  }
  function onClickDelete() {
    setIsOpen({
      isOpen: false,
      text: "",
    });
    delData(isOpen.data);
  }

  return (
    <Modal isOpen={isOpen.isOpen} style={MODALSTYLES.delete}>
      <div className="modal_window_container">
        <div className="text">
          <div>{isOpen.text}</div>
        </div>
        <div className="button">
          <div className="button_nor" onClick={onClickCancel}>
            취소
          </div>
          <div
            className="button_imp"
            onClick={onClickDelete}
            style={{ marginLeft: "20px" }}
          >
            확인
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default Delete;
