import { useState, useEffect } from "react";
import {
  createColumnHelper,
  flexRender,
  useReactTable,
  getCoreRowModel,
  getSortedRowModel,
} from "@tanstack/react-table";

import { getCurrTime, zeroPlusNum } from "../../utils/time";
import { dateTimeToFormat } from "../../utils/dateFormat";

function BroadHistory() {
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);
  const columnHelper = createColumnHelper();
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  useEffect(() => {
    const sample = [
      {
        dvcNm: "정보통신부 방송장비",
        title: "테스트 방송",
        sendDt: `${getCurrTime().year}${zeroPlusNum(
          getCurrTime().month
        )}${zeroPlusNum(getCurrTime().day)}152731`,
        status: "정상",
      },
      {
        dvcNm: "계측부 방송장비",
        title: "호우경보 저수지붕괴 주민대피 안내",
        sendDt: `${getCurrTime().year}${zeroPlusNum(
          getCurrTime().month
        )}${zeroPlusNum(getCurrTime().day)}110452`,
        status: "정상",
      },
      {
        dvcNm: "테스트 방송장비",
        title: "테스트 방송",
        sendDt: `${getCurrTime().year}${zeroPlusNum(
          getCurrTime().month
        )}${zeroPlusNum(getCurrTime().day)}084107`,
        status: "정상",
      },
    ];
    const column = [
      columnHelper.accessor("dvcNm", { header: "방송장비(그룹)", size: 140 }),
      columnHelper.accessor("title", { header: "방송제목", size: 150 }),
      columnHelper.accessor(
        (row) => {
          return dateTimeToFormat(row.sendDt);
        },
        { id: "sendDt", header: "방송일자", size: 120 }
      ),
      columnHelper.accessor("status", { header: "결과", size: 50 }),
    ];

    setData(sample);
    setColumns(column);
  }, []);

  return (
    <div id="allstatus-broad-component">
      {data.length === 0 ? (
        <div className="data-none-container">
          금일 방송내역 조회 결과가 없습니다
        </div>
      ) : (
        <div className="list">
          <table className="react_table">
            <thead>
              {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <th
                      key={header.id}
                      onClick={header.column.getToggleSortingHandler()}
                      style={{
                        width: isNaN(header.getSize())
                          ? "auto"
                          : `${header.getSize()}px`,
                        minWidth: isNaN(header.getSize())
                          ? "auto"
                          : `${header.getSize()}px`,
                        maxWidth: isNaN(header.getSize())
                          ? "auto"
                          : `${header.getSize()}px`,
                      }}
                    >
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody>
              {table.getRowModel().rows.map((row, index) => (
                <tr
                  key={row.id}
                  className={index % 2 === 0 ? "tr_even" : "tr_odd"}
                >
                  {row.getVisibleCells().map((cell) => (
                    <td
                      key={cell.id}
                      style={{
                        width: isNaN(cell.column.getSize())
                          ? "auto"
                          : `${cell.column.getSize()}px`,
                        minWidth: isNaN(cell.column.getSize())
                          ? "auto"
                          : `${cell.column.getSize()}px`,
                        maxWidth: isNaN(cell.column.getSize())
                          ? "auto"
                          : `${cell.column.getSize()}px`,
                        textAlign:
                          (cell.column.id === "sendDt" ||
                            cell.column.id === "status") &&
                          "center",
                      }}
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}

export default BroadHistory;
