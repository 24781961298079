import Modal from "react-modal";

Modal.setAppElement("#root");

const effects = [
  { value: "DDD", text: "딩동댕" },
  { value: "SIR", text: "사이렌" },
];
const iterNum = [
  { value: 1, text: "1회" },
  { value: 2, text: "2회" },
  { value: 3, text: "3회" },
  { value: 4, text: "4회" },
  { value: 5, text: "5회" },
];
function TextInform({ isOpen, setIsOpen }) {
  function onClickClose() {
    setIsOpen({
      isOpen: false,
      data: {},
    });
  }

  return (
    <Modal isOpen={isOpen.isOpen} style={modalStyles}>
      <div className="modal_title">
        <div>문안 정보</div>
      </div>
      <div className="modal_container">
        <div className="modal_main_column">
          <div className="modal_input">
            <div className="title">방송제목</div>
            <input
              value={isOpen.data.brdTitle}
              disabled={true}
              spellCheck={false}
            />
          </div>
          <div className="modal_input">
            <div className="title">방송전효과음</div>
            <input
              value={
                effects.find((effect) => effect.value === isOpen.data.sttEfc)
                  ?.text
              }
              disabled={true}
              spellCheck={false}
            />
            <div className="title" style={{ borderLeft: "1px solid #585858" }}>
              반복횟수
            </div>
            <input
              value={
                iterNum.find((num) => num.value === isOpen.data.sttEfcCnt)?.text
              }
              disabled={true}
              spellCheck={false}
            />
          </div>
          <div className="modal_input" style={{ flex: "1" }}>
            <div className="title">방송내용</div>
            <textarea
              value={isOpen.data.brdCntn}
              disabled={true}
              spellCheck={false}
            />
          </div>
          <div className="modal_input">
            <div className="title">방송횟수</div>
            <input
              value={
                iterNum.find((num) => num.value === isOpen.data.brdCnt)?.text
              }
              disabled={true}
              spellCheck={false}
            />
          </div>
          <div className="modal_input">
            <div className="title">방송후효과음</div>
            <input
              value={
                effects.find((effect) => effect.value === isOpen.data.endEfc)
                  ?.text
              }
              disabled={true}
              spellCheck={false}
            />
            <div className="title" style={{ borderLeft: "1px solid #585858" }}>
              반복횟수
            </div>
            <input
              value={
                iterNum.find((num) => num.value === isOpen.data.endEfcCnt)?.text
              }
              disabled={true}
              spellCheck={false}
            />
          </div>
          <div className="modal_input" style={{ borderBottom: "none" }}>
            <div className="title">볼륨</div>
            <input value={isOpen.data.vol} disabled={true} spellCheck={false} />
            <div className="title" style={{ borderLeft: "1px solid #585858" }}>
              속도
            </div>
            <input value={isOpen.data.spd} disabled={true} spellCheck={false} />
          </div>
        </div>

        <div className="modal_footer">
          <div className="button_nor" onClick={onClickClose}>
            닫기
          </div>
        </div>
      </div>
    </Modal>
  );
}

const modalStyles = {
  content: {
    width: "750px",
    height: "700px",
    padding: "0px",
    background: "#202020",
    border: "none",
    borderRadius: "5px",
    boxShadow: "-5px 5px 10px rgba(0, 0, 0, 0.7)",
    margin: "auto",
  },
  overlay: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "fixed",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: "rgba(0, 0, 0, 0.4)",
  },
};

export default TextInform;
