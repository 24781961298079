import { useState, useRef } from "react";
import Modal from "react-modal";
import axios from "axios";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";

import apiAddress from "../../../services/apiAddress";
import Alert from "../../common/window/Alert";
import Add from "../../common/window/Add";

Modal.setAppElement("#root");

const effects = [
  { value: "DDD", text: "딩동댕" },
  { value: "SIR", text: "사이렌" },
];
const iterNum = [
  { value: 1, text: "1회" },
  { value: 2, text: "2회" },
  { value: 3, text: "3회" },
  { value: 4, text: "4회" },
  { value: 5, text: "5회" },
];

function TextAdd({ isOpen, setIsOpen, isOk, setIsOk }) {
  const [isAlert, setIsAlert] = useState(false);
  const [isAdd, setIsAdd] = useState(false);
  const [data, setData] = useState({
    brdTitle: "",
    sttEfc: "DDD",
    sttEfcCnt: 1,
    brdCntn: "",
    brdCnt: 1,
    endEfc: "DDD",
    endEfcCnt: 1,
    vol: 50,
    spd: 50,
  });
  // 볼륨, 속도.
  const options = [];
  for (let i = 0; i <= 100; i++) {
    options.push(
      <option key={i} value={i}>
        {i}
      </option>
    );
  }
  const brdTitleRef = useRef(null);
  const brdCntnRef = useRef(null);

  function onClickClose() {
    setIsOpen(false);
  }
  function onClickAdd() {
    if (data.brdTitle === "" || data.brdCntn === "") {
      setIsAlert(true);
      if (data.brdTitle === "") {
        brdTitleRef.current.focus();
      } else {
        brdCntnRef.current.focus();
      }
    } else {
      setIsAdd(true);
    }
  }

  /* 문안 추가 API */
  async function fetchData() {
    try {
      axios
        .post(
          apiAddress.broadTextSave,
          { ...data, regUsr: "SH" },
          {
            headers: { "Content-Type": "application/json" },
          }
        )
        .then((response) => {
          setIsOk(!isOk);
          setIsOpen(false);
        });
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <Modal isOpen={isOpen} style={modalStyles}>
      {isAlert && (
        <Alert
          isOpen={isAlert}
          setIsOpen={setIsAlert}
          text="필수 정보를 입력해주세요"
        />
      )}
      {isAdd && <Add isOpen={isAdd} setIsOpen={setIsAdd} addData={fetchData} />}
      <div className="modal_title">
        <div>문안 추가</div>
      </div>
      <div className="modal_container">
        <div className="modal_main_column">
          <div className="modal_input">
            <div className="title">방송제목</div>
            <input
              ref={brdTitleRef}
              type="text"
              placeholder="필수 입력 *"
              value={data.brdTitle}
              onChange={(e) => setData({ ...data, brdTitle: e.target.value })}
              spellCheck={false}
            />
          </div>
          <div className="modal_input">
            <div className="title">방송전효과음</div>
            <select
              value={data.sttEfc}
              onChange={(e) => setData({ ...data, sttEfc: e.target.value })}
            >
              {effects.map((effect) => (
                <option key={effect.value} value={effect.value}>
                  {effect.text}
                </option>
              ))}
            </select>
            <div className="title" style={{ borderLeft: "1px solid #585858" }}>
              반복횟수
            </div>
            <select
              value={data.sttEfcCnt}
              onChange={(e) => setData({ ...data, sttEfcCnt: e.target.value })}
            >
              {iterNum.map((num) => (
                <option key={num.value} value={num.value}>
                  {num.text}
                </option>
              ))}
            </select>
          </div>
          <div className="modal_input" style={{ flex: "1" }}>
            <div className="title">방송내용</div>
            <textarea
              ref={brdCntnRef}
              placeholder="필수 입력 *"
              value={data.brdCntn}
              onChange={(e) => setData({ ...data, brdCntn: e.target.value })}
              spellCheck={false}
            />
          </div>
          <div className="modal_input">
            <div className="title">방송횟수</div>
            <select
              value={data.brdCnt}
              onChange={(e) => setData({ ...data, brdCnt: e.target.value })}
            >
              {iterNum.map((num) => (
                <option key={num.value} value={num.value}>
                  {num.text}
                </option>
              ))}
            </select>
          </div>
          <div className="modal_input">
            <div className="title">방송후효과음</div>
            <select
              value={data.endEfc}
              onChange={(e) => setData({ ...data, endEfc: e.target.value })}
              style={{ flex: "1" }}
            >
              {effects.map((effect) => (
                <option key={effect.value} value={effect.value}>
                  {effect.text}
                </option>
              ))}
            </select>
            <div className="title" style={{ borderLeft: "1px solid #585858" }}>
              반복횟수
            </div>
            <select
              value={data.endEfcCnt}
              onChange={(e) => setData({ ...data, endEfcCnt: e.target.value })}
              style={{ flex: "1" }}
            >
              {iterNum.map((num) => (
                <option key={num.value} value={num.value}>
                  {num.text}
                </option>
              ))}
            </select>
          </div>
          <div className="modal_input">
            <div className="title">볼륨</div>
            <select
              className="brd_vol_spd"
              value={data.vol}
              onChange={(e) => setData({ ...data, vol: e.target.value })}
              style={{ borderRight: "1px solid #4d4d4d" }}
            >
              {options}
            </select>
            <div className="brd_slider_container">
              <Slider
                className="brd_slider"
                min={0}
                max={100}
                step={1}
                value={data.vol}
                onChange={(value) => setData({ ...data, vol: value })}
                trackStyle={{ background: "#418dff" }}
                handleStyle={{ borderColor: "#000" }}
                style={{ flex: "1", margin: "0px 15px" }}
              />
            </div>
          </div>
          <div className="modal_input" style={{ border: "none" }}>
            <div className="title">속도</div>
            <select
              className="brd_vol_spd"
              value={data.spd}
              onChange={(e) => setData({ ...data, spd: e.target.value })}
              style={{ borderRight: "1px solid #4d4d4d" }}
            >
              {options}
            </select>
            <div className="brd_slider_container">
              <Slider
                className="brd_slider"
                min={0}
                max={100}
                step={1}
                value={data.spd}
                onChange={(value) => setData({ ...data, spd: value })}
                trackStyle={{ background: "#418dff" }}
                handleStyle={{ borderColor: "#000" }}
                style={{ flex: "1", margin: "0px 15px" }}
              />
            </div>
          </div>
        </div>

        <div className="modal_footer">
          <div className="button_nor" onClick={onClickClose}>
            닫기
          </div>
          <div
            className="button_imp"
            onClick={onClickAdd}
            style={{ marginLeft: "10px" }}
          >
            추가
          </div>
        </div>
      </div>
    </Modal>
  );
}

const modalStyles = {
  content: {
    width: "750px",
    height: "700px",
    padding: "0px",
    background: "#202020",
    border: "none",
    borderRadius: "5px",
    boxShadow: "-5px 5px 10px rgba(0, 0, 0, 0.7)",
    margin: "auto",
  },
  overlay: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "fixed",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: "rgba(0, 0, 0, 0.4)",
  },
};

export default TextAdd;
