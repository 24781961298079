// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.data-none-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  color: #ccccc1;
  font-size: 0.9rem;
}

.loading-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  height: 100%;
}
.loading-container img {
  position: absolute;
  width: 50px;
  height: auto;
}`, "",{"version":3,"sources":["webpack://./src/styles/common/container.scss"],"names":[],"mappings":"AAMA;EALE,aAAA;EACA,mBAAA;EACA,uBAAA;EAKA,WAAA;EACA,YAAA;EACA,cAAA;EACA,iBAAA;AAHF;;AAKA;EAZE,aAAA;EACA,mBAAA;EACA,uBAAA;EAYA,kBAAA;EACA,WAAA;EACA,YAAA;AAAF;AACE;EACE,kBAAA;EACA,WAAA;EACA,YAAA;AACJ","sourcesContent":["@mixin flex-center() {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n.data-none-container {\n  @include flex-center();\n  width: 100%;\n  height: 100%;\n  color: #ccccc1;\n  font-size: 0.9rem;\n}\n.loading-container {\n  @include flex-center();\n  position: relative;\n  width: 100%;\n  height: 100%;\n  img {\n    position: absolute;\n    width: 50px;\n    height: auto;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
