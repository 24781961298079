import React, { useState, useEffect } from "react";
import {
  ResponsiveContainer,
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  Tooltip,
  ReferenceLine,
  CartesianGrid,
} from "recharts";

import { DEVICEOPTIONS } from "../../../constants/deviceOptions";

function RainGraph({ selected }) {
  const [data, setData] = useState([]);
  const [minMax, setMinMax] = useState({ min: 0, max: 0 });
  const [alertLine, setAlertLine] = useState([]);
  const [activeIndex, setActiveIndex] = useState(null);

  /* Tooltip Custom */
  const TooltipCustom = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      const date =
        payload[0].payload.date === undefined ? "" : payload[0].payload.date;

      return (
        <div className="tooltip-container">
          <div className="date-text">{date}</div>
          <div className="value-container">
            {payload.map((item) => (
              <div key={`value-item-${item.dataKey}`} className="value-item">
                <div
                  className="value-color"
                  style={{ background: item.color }}
                />
                <div className="value-title">강우(mm):</div>
                <div className="value">{item.payload.avgVal}</div>
              </div>
            ))}
          </div>
        </div>
      );
    }
  };
  /* Mouse Event에 따라 Bar 색상 변경 */
  function handleMouseOver(index) {
    setActiveIndex(index);
  }
  function handleMouseOut() {
    setActiveIndex(null);
  }

  useEffect(() => {
    /* 데이터가공 & 최대값 찾기 */
    if (Array.isArray(selected.data) && selected.data.length > 0) {
      const selectedData = selected.data;

      let dataMax = 0;
      const nullData = selectedData.map((dataItem) => {
        const tmpItem = { ...dataItem };
        for (let key in dataItem) {
          if (key !== "date") {
            if (dataItem[key] === "-") {
              tmpItem[key] = null; // "-" → null
            } else {
              const val = parseFloat(dataItem[key]); // 최대값 찾기
              dataMax = val > dataMax ? val : dataMax;
            }
          }
        }
        return tmpItem;
      });
      setData(nullData);
      setMinMax({ min: 0, max: Math.round(dataMax + 10) });
    } else {
      setData([]);
      setMinMax({ min: 0, max: 0 });
    }

    /* 임계치 */
    if (Array.isArray(selected.dvcData) && selected.dvcData.length > 0) {
      const dvcData = selected.dvcData;

      const alert = dvcData.map((dvcItem) => {
        const alertItem = {
          unit: "mm",
          min: null,
          min2: null,
          min3: null,
          min4: null,
          max: null,
          max2: null,
          max3: null,
          max4: null,
        };
        for (let key in alertItem) {
          if (key !== "unit") {
            const val =
              dvcItem[key].toString() === DEVICEOPTIONS.sensorAnomaly.toString()
                ? null
                : Number(dvcItem[key]);
            alertItem[key] = val;
          }
        }
        return alertItem;
      });
      setAlertLine(alert);
    }
  }, [selected]);

  return (
    <>
      {Array.isArray(data) && data.length > 0 ? (
        <ResponsiveContainer width="100%" height="90%">
          <BarChart
            data={data}
            margin={{ left: -20, right: 20, top: 10, bottom: -10 }}
          >
            <CartesianGrid
              stroke="#595959"
              strokeWidth={0.5}
              vertical={false}
            />
            <XAxis dataKey="date" tick={false} axisLine={false} />
            <YAxis
              type="number"
              domain={[minMax.min, minMax.max]}
              tick={{ fontSize: 11 }}
              tickCount={10}
              axisLine={false}
            />
            <Tooltip
              content={<TooltipCustom />}
              filterNull={true}
              cursor={{
                stroke: "#757575",
                strokeWidth: 1,
                strokeDasharray: "3 3",
              }}
            />

            <Bar
              dataKey="avgVal"
              fill="#5FBAE173"
              isAnimationActive={false}
              onMouseEnter={(data, index) => handleMouseOver(index)}
              onMouseLeave={handleMouseOut}
            >
              {data.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={activeIndex === index ? "#5FBAE1D9" : "#5FBAE173"} // 색상 변경
                />
              ))}
            </Bar>

            {/* 임계치 */}
            {alertLine.length > 0 &&
              alertLine.map((alertItem, index) => (
                <React.Fragment key={`${index}-alert`}>
                  {/* 최소 */}
                  <ReferenceLine
                    y={alertItem.min}
                    stroke="#006B99"
                    strokeDasharray="7 4"
                    strokeWidth={1}
                  />
                  <ReferenceLine
                    y={alertItem.min2}
                    stroke="#928400"
                    strokeDasharray="7 4"
                    strokeWidth={1}
                  />
                  <ReferenceLine
                    y={alertItem.min3}
                    stroke="#A15300"
                    strokeDasharray="7 4"
                    strokeWidth={1}
                  />
                  <ReferenceLine
                    y={alertItem.min4}
                    stroke="#8F0303"
                    strokeDasharray="7 4"
                    strokeWidth={1}
                  />
                  {/* 최대 */}
                  <ReferenceLine
                    y={alertItem.max}
                    stroke="#006B99"
                    strokeDasharray="7 4"
                    strokeWidth={1}
                  />
                  <ReferenceLine
                    y={alertItem.max2}
                    stroke="#928400"
                    strokeDasharray="7 4"
                    strokeWidth={1}
                  />
                  <ReferenceLine
                    y={alertItem.max3}
                    stroke="#A15300"
                    strokeDasharray="7 4"
                    strokeWidth={1}
                  />
                  <ReferenceLine
                    y={alertItem.max4}
                    stroke="#8F0303"
                    strokeDasharray="7 4"
                    strokeWidth={1}
                  />
                </React.Fragment>
              ))}
          </BarChart>
        </ResponsiveContainer>
      ) : (
        <div className="data-none-container" style={{ fontSize: "0.8rem" }}>
          금일 데이터 내역이 없습니다
        </div>
      )}
    </>
  );
}

export default RainGraph;
