import { useState, useEffect } from "react";
import {
  flexRender,
  useReactTable,
  getCoreRowModel,
  createColumnHelper,
} from "@tanstack/react-table";

function MinSlopeTable({ slopeData, selected, setSelected }) {
  const [snsrData, setSnsrData] = useState(slopeData);
  const [valueData, setValueData] = useState([]);
  const [snsrColumns, setSnsrColumns] = useState([]);
  const [dataColumns, setDataColumns] = useState([]);
  const columnHelper = createColumnHelper();
  const snsrTable = useReactTable({
    data: snsrData,
    columns: snsrColumns,
    getCoreRowModel: getCoreRowModel(),
  });
  const dataTable = useReactTable({
    data: valueData,
    columns: dataColumns,
    getCoreRowModel: getCoreRowModel(),
  });

  function onClickRow(row) {
    // 행 클릭 시
    setSelected(row.original);
  }

  useEffect(() => {
    const column = [columnHelper.accessor("eqNm", { header: "센서명" })];

    setSnsrData(slopeData);
    setSnsrColumns(column);
  }, [slopeData]);
  useEffect(() => {
    if (Object.keys(selected).length > 0 && selected.data.length > 0) {
      const selectedData = selected.data;

      const sortData = [...selectedData].reverse();
      const column = [
        columnHelper.accessor("date", { header: "측정일자" }),
        columnHelper.accessor("x", { header: "x" }),
        columnHelper.accessor("y", { header: "y" }),
      ];

      setValueData(sortData);
      setDataColumns(column);
    } else {
      setValueData([]);
    }
  }, [selected]);

  return (
    <div className="data-minute-table-container">
      {/* RTU(센서) */}
      <div className="snsr-container">
        <div className="title">센서</div>
        <div className="snsr-table">
          <table className="react-data-table">
            <tbody>
              {snsrTable.getRowModel().rows.map((row, index) => (
                <tr
                  key={row.id}
                  className={
                    selected.eqId === row.original.eqId
                      ? "tr-snsr tr-select"
                      : index % 2 === 0
                      ? "tr-snsr tr-pointer tr-even"
                      : "tr-snsr tr-pointer tr-odd"
                  }
                  onClick={() => onClickRow(row)}
                >
                  {row.getVisibleCells().map((cell) => (
                    <td
                      key={cell.id}
                      style={{
                        width: isNaN(cell.column.getSize())
                          ? "auto"
                          : `${cell.column.getSize()}px`,
                      }}
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {/* 데이터 */}
      <div className="data-container">
        <div className="title">데이터</div>
        <div className="data-table">
          {valueData.length === 0 ? (
            <div className="data-none-container">
              데이터 조회 결과가 없습니다
            </div>
          ) : (
            <div className="list">
              <table className="react-data-table">
                <thead>
                  {dataTable.getHeaderGroups().map((headerGroup) => (
                    <tr key={headerGroup.id}>
                      {headerGroup.headers.map((header) => (
                        <th
                          key={header.id}
                          onClick={header.column.getToggleSortingHandler()}
                          style={{
                            width: isNaN(header.getSize())
                              ? "auto"
                              : `${header.getSize()}px`,
                          }}
                        >
                          {header.isPlaceholder
                            ? null
                            : flexRender(
                                header.column.columnDef.header,
                                header.getContext()
                              )}
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody>
                  {dataTable.getRowModel().rows.map((row, index) => (
                    <tr
                      key={row.id}
                      className={
                        index % 2 === 0 ? "tr-data tr-even" : "tr-data tr-odd"
                      }
                    >
                      {row.getVisibleCells().map((cell) => (
                        <td
                          key={cell.id}
                          style={{
                            width: isNaN(cell.column.getSize())
                              ? "auto"
                              : `${cell.column.getSize()}px`,
                          }}
                        >
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default MinSlopeTable;
