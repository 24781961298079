import { useState, useEffect } from "react";
import {
  createColumnHelper,
  flexRender,
  useReactTable,
  getCoreRowModel,
  getSortedRowModel,
} from "@tanstack/react-table";
import axios from "axios";

import apiAddress from "../../../services/apiAddress";

function TextList({ text, setText }) {
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);
  const columnHelper = createColumnHelper();
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  function onClickRow(row) {
    // 행 클릭 시.
    setText(row);
  }

  /* 방송문안 조회 API */
  async function fetchData() {
    try {
      axios
        .post(
          apiAddress.broadTextGet,
          {
            brdCntnId: 0,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          const responseData = response.data.filter(
            (item) => item.useYn === "Y"
          );
          const column = [
            columnHelper.accessor("brdTitle", {
              header: "제목",
            }),
          ];

          setData(responseData);
          setColumns(column);
        });
    } catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <table className="react_table">
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th
                  key={header.id}
                  onClick={header.column.getToggleSortingHandler()}
                  style={{
                    width: isNaN(header.getSize())
                      ? "auto"
                      : `${header.getSize()}px`,
                  }}
                >
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row, index) => (
            <tr
              key={row.id}
              onClick={() => onClickRow(row.original)}
              className={
                text.brdCntnId === row.original.brdCntnId
                  ? "tr_select"
                  : index % 2 === 0
                  ? "tr_even"
                  : "tr_odd"
              }
            >
              {row.getVisibleCells().map((cell) => (
                <td
                  key={cell.id}
                  style={{
                    width: isNaN(cell.column.getSize())
                      ? "auto"
                      : `${cell.column.getSize()}px`,
                  }}
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
}

export default TextList;
