import { useState, useEffect } from "react";
import Modal from "react-modal";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import locale from "../../../../data/datepicker";

import { dateToString, stringToDate } from "../../../../utils/dateFormat";
import { MODALSTYLES } from "../../../../constants/modalStyles";
import { DATEPICKERSTYLES } from "../../../../constants/datepickerStyles";
import { DEVICEOPTIONS } from "../../../../constants/deviceOptions";
import {
  getSensorType,
  getBroadType,
} from "../../../../services/internal/deviceAPI";
import { saveDevice } from "../../../../services/internal/deviceAPI";
import Alert from "../../../common/window/Alert";
import Edit from "../../../common/window/Edit";

Modal.setAppElement("#root");

function DeviceEdit({ isOpen, setIsOpen, setIsChange }) {
  const [inform, setInform] = useState({
    ...isOpen.data,
    instlDt: stringToDate(isOpen.data.instlDt),
  });
  const [snsrType, setSnsrType] = useState([]);
  const [brdType, setBrdType] = useState([]);
  const [isAlert, setIsAlert] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  function onClickClose() {
    // 닫기 버튼 클릭시
    setIsOpen({
      isOpen: false,
      data: {},
    });
  }
  function onClickEdit() {
    // 수정 버튼 클릭시
    if (inform.dvcNm === "") {
      // 필수 정보 입력X
      setIsAlert(true);
    } else {
      // 필수 정보 입력 O
      setIsEdit(true);
    }
  }
  function editDevice() {
    // 장비 수정
    const tokenInform = localStorage.getItem("tokenInform");
    const tokenInformParse = JSON.parse(tokenInform);
    const dateInform = { ...inform, instlDt: dateToString(inform.instlDt) };
    const dvcInform = {
      ...dateInform,
      modUsr: tokenInformParse.userId,
    };

    if (inform.dvcType === "SNSR") {
      const dvcSnsrInform = {
        ...dvcInform,
        min:
          dvcInform.min === ""
            ? DEVICEOPTIONS.sensorAnomaly
            : parseFloat(dvcInform.min),
        min2:
          dvcInform.min2 === ""
            ? DEVICEOPTIONS.sensorAnomaly
            : parseFloat(dvcInform.min2),
        min3:
          dvcInform.min3 === ""
            ? DEVICEOPTIONS.sensorAnomaly
            : parseFloat(dvcInform.min3),
        min4:
          dvcInform.min4 === ""
            ? DEVICEOPTIONS.sensorAnomaly
            : parseFloat(dvcInform.min4),
        max:
          dvcInform.max === ""
            ? DEVICEOPTIONS.sensorAnomaly
            : parseFloat(dvcInform.max),
        max2:
          dvcInform.max2 === ""
            ? DEVICEOPTIONS.sensorAnomaly
            : parseFloat(dvcInform.max2),
        max3:
          dvcInform.max3 === ""
            ? DEVICEOPTIONS.sensorAnomaly
            : parseFloat(dvcInform.max3),
        max4:
          dvcInform.max4 === ""
            ? DEVICEOPTIONS.sensorAnomaly
            : parseFloat(dvcInform.max4),
      };
      saveDevice(dvcSnsrInform).then((response) => {
        setIsChange(true);
        setIsOpen(false);
      });
    } else {
      saveDevice(dvcInform).then((response) => {
        setIsChange(true);
        setIsOpen(false);
      });
    }
  }

  useEffect(() => {
    const dvcInform = isOpen.data;
    const editInform = {
      ...dvcInform,
      instlDt: stringToDate(dvcInform.instlDt),
    };
    if (editInform.dvcType === "SNSR") {
      const snsrInform = {
        ...editInform,
        min:
          editInform.min === DEVICEOPTIONS.sensorAnomaly ? "" : editInform.min,
        min2:
          editInform.min2 === DEVICEOPTIONS.sensorAnomaly
            ? ""
            : editInform.min2,
        min3:
          editInform.min3 === DEVICEOPTIONS.sensorAnomaly
            ? ""
            : editInform.min3,
        min4:
          editInform.min4 === DEVICEOPTIONS.sensorAnomaly
            ? ""
            : editInform.min4,
        max:
          editInform.max === DEVICEOPTIONS.sensorAnomaly ? "" : editInform.max,
        max2:
          editInform.max2 === DEVICEOPTIONS.sensorAnomaly
            ? ""
            : editInform.max2,
        max3:
          editInform.max3 === DEVICEOPTIONS.sensorAnomaly
            ? ""
            : editInform.max3,
        max4:
          editInform.max4 === DEVICEOPTIONS.sensorAnomaly
            ? ""
            : editInform.max4,
      };
      setInform(snsrInform);
    } else {
      setInform(editInform);
    }
  }, [isOpen.data]);
  useEffect(() => {
    getSensorType().then((sensorType) => {
      getBroadType().then((broadType) => {
        setSnsrType(sensorType);
        setBrdType(broadType);
      });
    });
  }, []);

  return (
    <Modal isOpen={isOpen.isOpen} style={MODALSTYLES.device}>
      {
        // 필수 정보 입력X
        isAlert && (
          <Alert
            isOpen={isAlert}
            setIsOpen={setIsAlert}
            text="필수 정보를 입력해주세요"
          />
        )
      }
      {
        // 장비 수정
        isEdit && (
          <Edit isOpen={isEdit} setIsOpen={setIsEdit} editData={editDevice} />
        )
      }
      {Object.keys(inform).length > 0 && (
        <>
          <div className="modal_title">
            <div>장비 수정</div>
          </div>

          <div className="modal_container">
            <div className="modal_main_column">
              <div className="device-add-edit-modal">
                <div className="typing-container">
                  <div className="title">장비명</div>
                  <input
                    className="one-input"
                    type="text"
                    placeholder="필수 입력*"
                    value={inform.dvcNm}
                    onChange={(e) =>
                      setInform({ ...inform, dvcNm: e.target.value })
                    }
                  />
                </div>
                <div className="typing-container">
                  <div className="title">장비종류</div>
                  <select
                    className="two-select"
                    value={inform.dvcType}
                    onChange={(e) => {
                      setInform({
                        ...inform,
                        dvcType: e.target.value,
                        dvcDtlType:
                          e.target.value === "SNSR"
                            ? snsrType[0].commonCode
                            : brdType[0].commonCode,
                        min: "",
                        min2: "",
                        min3: "",
                        min4: "",
                        max: "",
                        max2: "",
                        max3: "",
                        max4: "",
                        slncAft: "",
                        slncBfr: "",
                      });
                    }}
                  >
                    {DEVICEOPTIONS.type.map((item) => (
                      <option key={item.value} value={item.value}>
                        {item.text}
                      </option>
                    ))}
                  </select>
                  {inform.dvcType === "SNSR" ? (
                    <>
                      <div className="inner-title">센서타입</div>
                      <select
                        className="two-select"
                        value={inform.dvcDtlType}
                        onChange={(e) =>
                          setInform({ ...inform, dvcDtlType: e.target.value })
                        }
                      >
                        {snsrType.map((item) => (
                          <option key={item.commonCode} value={item.commonCode}>
                            {item.commonCodeName}
                          </option>
                        ))}
                      </select>
                    </>
                  ) : (
                    inform.dvcType === "BRD" && (
                      <>
                        <div className="inner-title">방송장비타입</div>
                        <select
                          className="two-select"
                          value={inform.dvcDtlType}
                          onChange={(e) =>
                            setInform({ ...inform, dvcDtlType: e.target.value })
                          }
                        >
                          {brdType.map((item) => (
                            <option
                              key={item.commonCode}
                              value={item.commonCode}
                            >
                              {item.commonCodeName}
                            </option>
                          ))}
                        </select>
                      </>
                    )
                  )}
                </div>
                <div className="typing-container">
                  <div className="title">설치일자</div>
                  <DatePicker
                    selected={inform.instlDt}
                    onChange={(date) => setInform({ ...inform, instlDt: date })}
                    customInput={<DATEPICKERSTYLES.modal />}
                    dateFormat="yyyy-MM-dd"
                    locale={locale.ko}
                  />
                  <div className="inner-title">제조업체</div>
                  <input
                    className="two-input"
                    type="text"
                    placeholder="제조업체 입력"
                    value={inform.mnfct}
                    onChange={(e) =>
                      setInform({ ...inform, mnfct: e.target.value })
                    }
                  />
                </div>
                <div className="typing-container">
                  <div className="title">모델명</div>
                  <input
                    className="one-input"
                    type="text"
                    placeholder="모델명 입력"
                    value={inform.modelNm}
                    onChange={(e) =>
                      setInform({ ...inform, modelNm: e.target.value })
                    }
                  />
                </div>
                {inform.dvcType === "SNSR" ? (
                  <>
                    <div className="typing-container">
                      <div className="title title-caution">관심 최소</div>
                      <input
                        className="two-input"
                        type="number"
                        placeholder="관심 최솟값 입력"
                        value={inform.min}
                        onChange={(e) =>
                          setInform({ ...inform, min: e.target.value })
                        }
                      />
                      <div className="inner-title title-caution">관심 최대</div>
                      <input
                        className="two-input"
                        type="number"
                        placeholder="관심 최댓값 입력"
                        value={inform.max}
                        onChange={(e) =>
                          setInform({ ...inform, max: e.target.value })
                        }
                      />
                    </div>
                    <div className="typing-container">
                      <div className="title title-attention">주의 최소</div>
                      <input
                        className="two-input"
                        type="number"
                        placeholder="주의 최솟값 입력"
                        value={inform.min2}
                        onChange={(e) =>
                          setInform({ ...inform, min2: e.target.value })
                        }
                      />
                      <div className="inner-title title-attention">
                        주의 최대
                      </div>
                      <input
                        className="two-input"
                        type="number"
                        placeholder="주의 최댓값 입력"
                        value={inform.max2}
                        onChange={(e) =>
                          setInform({ ...inform, max2: e.target.value })
                        }
                      />
                    </div>
                    <div className="typing-container">
                      <div className="title title-warning">경계 최소</div>
                      <input
                        className="two-input"
                        type="number"
                        placeholder="경계 최솟값 입력"
                        value={inform.min3}
                        onChange={(e) =>
                          setInform({ ...inform, min3: e.target.value })
                        }
                      />
                      <div className="inner-title title-warning">경계 최대</div>
                      <input
                        className="two-input"
                        type="number"
                        placeholder="경계 최댓값 입력"
                        value={inform.max3}
                        onChange={(e) =>
                          setInform({ ...inform, max3: e.target.value })
                        }
                      />
                    </div>
                    <div className="typing-container">
                      <div className="title title-critical">심각 최소</div>
                      <input
                        className="two-input"
                        type="number"
                        placeholder="심각 최솟값 입력"
                        value={inform.min4}
                        onChange={(e) =>
                          setInform({ ...inform, min4: e.target.value })
                        }
                      />
                      <div className="inner-title title-critical">
                        심각 최대
                      </div>
                      <input
                        className="two-input"
                        type="number"
                        placeholder="심각 최댓값 입력"
                        value={inform.max4}
                        onChange={(e) =>
                          setInform({ ...inform, max4: e.target.value })
                        }
                      />
                    </div>
                  </>
                ) : (
                  inform.dvcType === "BRD" && (
                    <>
                      <div className="typing-container">
                        <div className="title">방송전묵음(초)</div>
                        <input
                          className="two-input"
                          type="number"
                          placeholder="방송전묵음(초) 입력"
                          value={inform.slncBfr}
                          onChange={(e) =>
                            setInform({ ...inform, slncBfr: e.target.value })
                          }
                        />
                        <div className="inner-title">방송후묵음(초)</div>
                        <input
                          className="two-input"
                          type="number"
                          placeholder="방송후묵음(초) 입력"
                          value={inform.slncAft}
                          onChange={(e) =>
                            setInform({ ...inform, slncAft: e.target.value })
                          }
                        />
                      </div>
                    </>
                  )
                )}
                <div className="last-typing-container">
                  <div className="title">메모</div>
                  <textarea
                    type="text"
                    placeholder="메모 입력"
                    value={inform.dvcDtl}
                    onChange={(e) =>
                      setInform({ ...inform, dvcDtl: e.target.value })
                    }
                  />
                </div>
              </div>
            </div>

            <div className="modal_footer">
              <div className="button-nor-modal" onClick={onClickClose}>
                닫기
              </div>
              <div className="button-imp right-button" onClick={onClickEdit}>
                수정
              </div>
            </div>
          </div>
        </>
      )}
    </Modal>
  );
}

export default DeviceEdit;
