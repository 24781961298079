import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  createColumnHelper,
  flexRender,
  useReactTable,
  getCoreRowModel,
  getSortedRowModel,
} from "@tanstack/react-table";
import { Menu, Item, useContextMenu } from "react-contexify";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisVertical } from "@fortawesome/free-solid-svg-icons";

import { tokenCheck } from "../../../utils/tokenCheck";
import { dateToFormat } from "../../../utils/dateFormat";
import { getRegionEquip } from "../../../services/internal/regionAPI";
import { saveEquip } from "../../../services/internal/equipAPI";
import { delCCTV } from "../../../services/internal/cctvAPI";
import CCTVInform from "../../common/inform/CCTVInform";
import CCTVEdit from "./CCTVEdit";
import Delete from "../../common/window/Delete";
import Alert from "../../common/window/Alert";

const MENU_ID = "cctvList";

function CCTVList({ cctvData, setIsChange }) {
  const movePage = useNavigate();
  const [isInform, setIsInform] = useState({ isOpen: false, data: {} });
  const [isEdit, setIsEdit] = useState({ isOpen: false, data: {} });
  const [isDel, setIsDel] = useState({ isOpen: false, text: "", data: {} });
  const [isRegionAlert, setIsRegionAlert] = useState(false);
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);
  const columnHelper = createColumnHelper();
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });
  const menuIconColumn = {
    id: "menuIcon",
    accessor: "menu",
    header: "",
    size: 20,
    enableSorting: false,
    cell: ({ row }) => (
      <FontAwesomeIcon className="table_icon" icon={faEllipsisVertical} />
    ),
  };
  const { show } = useContextMenu({
    id: MENU_ID,
  });

  function onClickMenu(e, row) {
    // 메뉴 클릭 시.
    e.stopPropagation();
    show({ event: e, props: { data: row } });
  }
  function menuItemClick({ id, props }) {
    // 메뉴 Item 클릭 시.
    // 토큰 확인
    tokenCheck().then((result) => {
      if (!result) {
        alert("인증 토큰이 만료되었습니다. 다시 로그인 해주세요");
        movePage("/");
      } else {
        switch (id) {
          // 정보.
          case "inform":
            setIsInform({ isOpen: true, data: props.data });
            break;
          case "edit":
            setIsEdit({ isOpen: true, data: props.data });
            break;
          case "delete":
            cctvDelCheck(props.data);
            break;
          default:
            break;
        }
      }
    });
  }
  function cctvDelCheck(data) {
    // CCTV 삭제 가능 여부 조회
    getRegionEquip().then((response) => {
      if (!response.find((item) => item.eqId === data.eqId)) {
        // 관리지역에 등록되어 있음
        setIsRegionAlert(true);
      } else {
        const tokenInform = localStorage.getItem("tokenInform");
        const tokenInformParse = JSON.parse(tokenInform);
        const delData = {
          ...data,
          modUsr: tokenInformParse.userId,
          useYn: "D",
        };
        setIsDel({
          isOpen: true,
          text: `'${data.eqNm}' CCTV를 삭제하시겠습니까?`,
          data: delData,
        });
      }
    });
  }
  function deleteCCTV(data) {
    saveEquip(data).then((response) => {
      delCCTV(data.cctvInfId).then((response2) => {
        setIsChange(true);
      });
    });
  }

  useEffect(() => {
    if (Array.isArray(cctvData) && cctvData.length > 0) {
      const column = [
        columnHelper.accessor("eqNm", { header: "CCTV명", size: 200 }),
        columnHelper.accessor(
          (row) => {
            return dateToFormat(row.instlDt);
          },
          { id: "instlDt", header: "설치일자", size: 100 }
        ),
        columnHelper.accessor(
          (row) => {
            const mnfct = row.mnfct === "" ? "-" : row.mnfct;
            return mnfct;
          },
          { id: "mnfct", header: "제조업체", size: 150 }
        ),
        columnHelper.accessor(
          (row) => {
            const modelNm = row.modelNm === "" ? "-" : row.modelNm;
            return modelNm;
          },
          { id: "modelNm", header: "모델명", size: 120 }
        ),
        columnHelper.accessor("commAddr", { header: "IP", size: 100 }),
        columnHelper.accessor("webPort", { header: "ONVIF Port", size: 40 }),
        columnHelper.accessor("rtspPort", { header: "RTSP Port", size: 40 }),
        columnHelper.accessor("bdongCd", { header: "법정동코드", size: 100 }),
        columnHelper.accessor("addr", { header: "주소", size: 300 }),
        menuIconColumn,
      ];

      setData(cctvData);
      setColumns(column);
    } else {
      setData([]);
      setColumns([]);
    }
  }, [cctvData]);

  return (
    <div id="setting-cctv-list-component">
      {
        // CCTV 정보
        isInform.isOpen && (
          <CCTVInform isOpen={isInform} setIsOpen={setIsInform} />
        )
      }
      {
        // CCTV 수정
        isEdit.isOpen && (
          <CCTVEdit
            isOpen={isEdit}
            setIsOpen={setIsEdit}
            setIsChange={setIsChange}
          />
        )
      }
      {
        // 등록되어 있는 지역 존재, 전광판 삭제 불가
        isRegionAlert && (
          <Alert
            isOpen={isRegionAlert}
            setIsOpen={setIsRegionAlert}
            text="먼저 등록되어 있는 지역에서부터 삭제해주세요"
          />
        )
      }
      {
        // 전광판 삭제
        isDel.isOpen && (
          <Delete
            isOpen={isDel}
            setIsOpen={setIsDel}
            delData={() => deleteCCTV(isDel.data)}
          />
        )
      }
      {data.length === 0 ? (
        <div className="setting-data-none-container">
          등록된 CCTV가 없습니다
        </div>
      ) : (
        <div className="list">
          <table className="react_table">
            <thead>
              {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <th
                      key={header.id}
                      onClick={header.column.getToggleSortingHandler()}
                      style={{
                        width: isNaN(header.getSize())
                          ? "auto"
                          : `${header.getSize()}px`,
                      }}
                    >
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody>
              {table.getRowModel().rows.map((row, index) => (
                <tr
                  key={row.id}
                  className={index % 2 === 0 ? "tr_even" : "tr_odd"}
                >
                  {row.getVisibleCells().map((cell) => (
                    <td
                      key={cell.id}
                      onClick={(e) => {
                        if (cell.column.id === "menuIcon") {
                          onClickMenu(e, row.original);
                        }
                      }}
                      style={{
                        width: isNaN(cell.column.getSize())
                          ? "auto"
                          : `${cell.column.getSize()}px`,
                        textAlign:
                          (cell.column.id === "instlDt" ||
                            cell.column.id === "webPort" ||
                            cell.column.id === "rtspPort" ||
                            cell.column.id === "bdongCd" ||
                            cell.column.id === "menuIcon") &&
                          "center",
                      }}
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      {/* Context 메뉴 */}
      <Menu id={MENU_ID}>
        <Item id="inform" onClick={menuItemClick}>
          정보
        </Item>
        <Item id="edit" onClick={menuItemClick}>
          수정
        </Item>
        <Item id="delete" onClick={menuItemClick}>
          삭제
        </Item>
      </Menu>
    </div>
  );
}

export default CCTVList;
