import { getMinBefore, zeroPlusNum } from "../time";
import { dateTimeMinToFormat } from "../dateFormat";

function isConvertibleToNumber(value) {
  const num = Number(value);
  return !isNaN(num);
}

// 강우계
export function rainOverlay(item, click) {
  function snsrClick() {
    click(item);
  }

  let val = "-";
  const beforeMin =
    getMinBefore().year +
    "-" +
    zeroPlusNum(getMinBefore().month) +
    "-" +
    zeroPlusNum(getMinBefore().day) +
    " " +
    zeroPlusNum(getMinBefore().hour) +
    ":" +
    zeroPlusNum(getMinBefore().minute);
  if (Array.isArray(item.data) && item.data.length > 0) {
    const beforeMinData = item.data.find((item) => item.date === beforeMin);
    if (beforeMinData) {
      val = beforeMinData.avgVal;
    }
  }

  const container = document.createElement("div");
  container.className = "map-overlay-container";
  const titleContainer = document.createElement("div"); // 타이틀
  titleContainer.className = "overlay-title overlay-title-rain";
  const title = document.createElement("div");
  title.className = "name";
  title.appendChild(document.createTextNode(item.eqNm));
  const contentsContainer = document.createElement("div"); // 컨텐츠
  contentsContainer.className = "overlay-snsr-contents";
  contentsContainer.onclick = snsrClick;
  const dateContainer = document.createElement("div"); // 측정시각
  dateContainer.className = "date-item date-item-rain";
  dateContainer.appendChild(document.createTextNode(beforeMin));
  const valContainer = document.createElement("div"); // 측정값
  valContainer.className = "contents-item contents-item-last";
  const valTitle = document.createElement("div");
  valTitle.className = "snsr-title snsr-title-rain snsr-title-last";
  valTitle.appendChild(document.createTextNode("값"));
  const valValue = document.createElement("div");
  valValue.className = "snsr-value";
  valValue.appendChild(document.createTextNode(val));
  const tailContainer = document.createElement("div"); // 꼬리
  tailContainer.className = "overlay-tail";

  valContainer.appendChild(valTitle);
  valContainer.appendChild(valValue);
  contentsContainer.appendChild(dateContainer);
  contentsContainer.appendChild(valContainer);
  titleContainer.appendChild(title);
  container.appendChild(titleContainer);
  container.appendChild(contentsContainer);
  container.append(tailContainer);

  return container;
}
// 수위계
export function levelOverlay(item, click) {
  function snsrClick() {
    click(item);
  }

  let val = "-";
  const beforeMin =
    getMinBefore().year +
    "-" +
    zeroPlusNum(getMinBefore().month) +
    "-" +
    zeroPlusNum(getMinBefore().day) +
    " " +
    zeroPlusNum(getMinBefore().hour) +
    ":" +
    zeroPlusNum(getMinBefore().minute);
  if (Array.isArray(item.data) && item.data.length > 0) {
    const beforeMinData = item.data.find((item) => item.date === beforeMin);
    if (beforeMinData) {
      val = beforeMinData.avgVal;
    }
  }

  const container = document.createElement("div");
  container.className = "map-overlay-container";
  const titleContainer = document.createElement("div"); // 타이틀
  titleContainer.className = "overlay-title overlay-title-level";
  const title = document.createElement("div");
  title.className = "name";
  title.appendChild(document.createTextNode(item.eqNm));
  const contentsContainer = document.createElement("div"); // 컨텐츠
  contentsContainer.className = "overlay-snsr-contents";
  contentsContainer.onclick = snsrClick;
  const dateContainer = document.createElement("div"); // 측정시각
  dateContainer.className = "date-item date-item-level";
  dateContainer.appendChild(document.createTextNode(beforeMin));
  const valContainer = document.createElement("div"); // 측정값
  valContainer.className = "contents-item contents-item-last";
  const valTitle = document.createElement("div");
  valTitle.className = "snsr-title snsr-title-level snsr-title-last";
  valTitle.appendChild(document.createTextNode("값"));
  const valValue = document.createElement("div");
  valValue.className = "snsr-value";
  valValue.appendChild(document.createTextNode(val));
  const tailContainer = document.createElement("div"); // 꼬리
  tailContainer.className = "overlay-tail";

  valContainer.appendChild(valTitle);
  valContainer.appendChild(valValue);
  contentsContainer.appendChild(dateContainer);
  contentsContainer.appendChild(valContainer);
  titleContainer.appendChild(title);
  container.appendChild(titleContainer);
  container.appendChild(contentsContainer);
  container.append(tailContainer);

  return container;
}
// 경사계
export function slopeOverlay(item, click) {
  function snsrClick() {
    click(item);
  }

  let x = "-",
    y = "-";
  const beforeMin =
    getMinBefore().year +
    "-" +
    zeroPlusNum(getMinBefore().month) +
    "-" +
    zeroPlusNum(getMinBefore().day) +
    " " +
    zeroPlusNum(getMinBefore().hour) +
    ":" +
    zeroPlusNum(getMinBefore().minute);
  if (Array.isArray(item.data) && item.data.length > 0) {
    const beforeMinData = item.data.find((item) => item.date === beforeMin);
    if (beforeMinData) {
      x = beforeMinData.x;
      y = beforeMinData.y;
    }
  }

  const container = document.createElement("div");
  container.className = "map-overlay-container";
  const titleContainer = document.createElement("div"); // 타이틀
  titleContainer.className = "overlay-title overlay-title-slope";
  const title = document.createElement("div");
  title.className = "name";
  title.appendChild(document.createTextNode(item.eqNm));
  const contentsContainer = document.createElement("div"); // 컨텐츠
  contentsContainer.className = "overlay-snsr-contents";
  contentsContainer.onclick = snsrClick;
  const dateContainer = document.createElement("div"); // 측정시각
  dateContainer.className = "date-item date-item-slope";
  dateContainer.appendChild(document.createTextNode(beforeMin));
  const xContainer = document.createElement("div"); // x
  xContainer.className = "contents-item";
  const xTitle = document.createElement("div");
  xTitle.className = "snsr-title snsr-title-slope";
  xTitle.appendChild(document.createTextNode("x"));
  const xValue = document.createElement("div");
  xValue.className = "snsr-value";
  xValue.appendChild(document.createTextNode(x));
  const yContainer = document.createElement("div"); // y
  yContainer.className = "contents-item contents-item-last";
  const yTitle = document.createElement("div");
  yTitle.className = "snsr-title snsr-title-slope snsr-title-last";
  yTitle.appendChild(document.createTextNode("y"));
  const yValue = document.createElement("div");
  yValue.className = "snsr-value";
  yValue.appendChild(document.createTextNode(y));
  const tailContainer = document.createElement("div"); // 꼬리
  tailContainer.className = "overlay-tail";

  xContainer.appendChild(xTitle);
  xContainer.appendChild(xValue);
  yContainer.appendChild(yTitle);
  yContainer.appendChild(yValue);
  contentsContainer.appendChild(dateContainer);
  contentsContainer.appendChild(xContainer);
  contentsContainer.appendChild(yContainer);
  titleContainer.appendChild(title);
  container.appendChild(titleContainer);
  container.appendChild(contentsContainer);
  container.append(tailContainer);

  return container;
}

// 변위계
export function disOverlay(item, click) {
  function snsrClick() {
    click(item);
  }

  let x = "-",
    y = "-",
    mm = "-";
  const beforeMin =
    getMinBefore().year +
    "-" +
    zeroPlusNum(getMinBefore().month) +
    "-" +
    zeroPlusNum(getMinBefore().day) +
    " " +
    zeroPlusNum(getMinBefore().hour) +
    ":" +
    zeroPlusNum(getMinBefore().minute);
  if (Array.isArray(item.data) && item.data.length > 0) {
    const beforeMinData = item.data.find((item) => item.date === beforeMin);
    if (beforeMinData) {
      x = beforeMinData.x;
      y = beforeMinData.y;
      mm = beforeMinData.mm;
    }
  }

  const container = document.createElement("div");
  container.className = "map-overlay-container";
  const titleContainer = document.createElement("div"); // 타이틀
  titleContainer.className = "overlay-title overlay-title-dis";
  const title = document.createElement("div");
  title.className = "name";
  title.appendChild(document.createTextNode(item.eqNm));
  const contentsContainer = document.createElement("div"); // 컨텐츠
  contentsContainer.className = "overlay-snsr-contents";
  contentsContainer.onclick = snsrClick;
  const dateContainer = document.createElement("div"); // 측정시각
  dateContainer.className = "date-item date-item-dis";
  dateContainer.appendChild(document.createTextNode(beforeMin));
  const xContainer = document.createElement("div"); // x
  xContainer.className = "contents-item";
  const xTitle = document.createElement("div");
  xTitle.className = "snsr-title snsr-title-dis";
  xTitle.appendChild(document.createTextNode("x"));
  const xValue = document.createElement("div");
  xValue.className = "snsr-value";
  xValue.appendChild(document.createTextNode(x));
  const yContainer = document.createElement("div"); // y
  yContainer.className = "contents-item";
  const yTitle = document.createElement("div");
  yTitle.className = "snsr-title snsr-title-dis";
  yTitle.appendChild(document.createTextNode("y"));
  const yValue = document.createElement("div");
  yValue.className = "snsr-value";
  yValue.appendChild(document.createTextNode(y));
  const mmContainer = document.createElement("div"); // mm
  mmContainer.className = "contents-item contents-item-last";
  const mmTitle = document.createElement("div");
  mmTitle.className = "snsr-title snsr-title-dis snsr-title-last";
  mmTitle.appendChild(document.createTextNode("mm"));
  const mmValue = document.createElement("div");
  mmValue.className = "snsr-value";
  mmValue.appendChild(document.createTextNode(mm));
  const tailContainer = document.createElement("div"); // 꼬리
  tailContainer.className = "overlay-tail";

  xContainer.appendChild(xTitle);
  xContainer.appendChild(xValue);
  yContainer.appendChild(yTitle);
  yContainer.appendChild(yValue);
  mmContainer.appendChild(mmTitle);
  mmContainer.appendChild(mmValue);
  contentsContainer.appendChild(dateContainer);
  contentsContainer.appendChild(xContainer);
  contentsContainer.appendChild(yContainer);
  contentsContainer.appendChild(mmContainer);
  titleContainer.appendChild(title);
  container.appendChild(titleContainer);
  container.appendChild(contentsContainer);
  container.append(tailContainer);

  return container;
}

// 전광판
export function edbOverlay(item, click) {
  function edbClick() {
    if (click !== undefined) {
      click(item);
    }
  }

  const container = document.createElement("div");
  container.className = "map-overlay-container";
  const titleContainer = document.createElement("div"); // 타이틀
  titleContainer.className = "overlay-title overlay-title-edb";
  const title = document.createElement("div");
  title.className = "name";
  title.appendChild(document.createTextNode(item.eqNm));
  const contentsContainer = document.createElement("div"); // 컨텐츠
  contentsContainer.className = "overlay-dvc-contents";
  contentsContainer.onclick = edbClick;
  if (Array.isArray(item.msgList) && item.msgList.length > 0) {
    if (item.msgList.find((item) => item.useYn === "Y")) {
      contentsContainer.appendChild(document.createTextNode("작동중"));
    } else {
      contentsContainer.appendChild(document.createTextNode("작동 안함"));
      contentsContainer.style.color = "#CC0000";
    }
  } else {
    contentsContainer.appendChild(document.createTextNode("작동 안함"));
    contentsContainer.style.color = "#CC0000";
  }
  const tailContainer = document.createElement("div"); // 꼬리
  tailContainer.className = "overlay-tail";

  titleContainer.appendChild(title);
  container.appendChild(titleContainer);
  container.appendChild(contentsContainer);
  container.append(tailContainer);

  return container;
}

// CCTV
export function cctvOverlay(item, click) {
  function cctvClick() {
    click(item);
  }

  const container = document.createElement("div");
  container.className = "map-overlay-container";
  const titleContainer = document.createElement("div"); // 타이틀
  titleContainer.className = "overlay-title overlay-title-cctv";
  const title = document.createElement("div");
  title.className = "name";
  title.appendChild(document.createTextNode(item.eqNm));
  const contentsContainer = document.createElement("div"); // 컨텐츠
  contentsContainer.className = "overlay-dvc-contents";
  contentsContainer.onclick = cctvClick;
  if (item.connect) {
    contentsContainer.appendChild(document.createTextNode("연결 원활"));
  } else {
    contentsContainer.appendChild(document.createTextNode("연결 실패"));
    contentsContainer.style.color = "#CC0000";
  }
  const tailContainer = document.createElement("div"); // 꼬리
  tailContainer.className = "overlay-tail";

  titleContainer.appendChild(title);
  container.appendChild(titleContainer);
  container.appendChild(contentsContainer);
  container.append(tailContainer);

  return container;
}
