import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setMenu, setSubmenu } from "../../../redux/modules/menu";
import DatePicker from "react-datepicker";
import locale from "../../../data/datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHome,
  faList,
  faChartSimple,
} from "@fortawesome/free-solid-svg-icons";

import { tokenCheck } from "../../../utils/tokenCheck";
import {
  getRainSnsr,
  getLevelSnsr,
  getSlopeSnsr,
  getDisSnsr,
  getDataUpdate,
} from "../../../services/internal/dataAPI";
import {
  getFromDtAndToDt,
  getMonthColumn,
  getDayColumn,
  getHourColumn,
  getMinColumn,
} from "../../../utils/data";
import { DATEPICKERSTYLES } from "../../../constants/datepickerStyles";
import Loading from "../../../components/common/window/Loading";
import SensorList from "../../../components/data/sensor-grouping/SensorList";
import SensorGraph from "../../../components/data/sensor-grouping/SensorGraph";

const PAGE_VALUE = "자료관리";
const SUBPAGE_VALUE = "센서그룹핑";

function SensorGrouping() {
  const dispatch = useDispatch();
  const movePage = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isToken, setIsToken] = useState(false);

  const [rtus, setRtus] = useState({
    rain: [],
    level: [],
    slope: [],
    dis: [],
  });
  const [selected, setSelected] = useState({
    rain: [],
    level: [],
    slope: [],
    dis: [],
  });
  const [selectedData, setSelectedData] = useState({});
  const [searchType, setSearchType] = useState("day");
  const [searchDate, setSearchDate] = useState(new Date());

  function onClickQuery() {
    // 조회 클릭 시
    tokenCheck().then((result) => {
      if (!result) {
        alert("인증 토큰이 만료되었습니다. 다시 로그인 해주세요");
        movePage("/");
      } else {
        setIsLoading(true);
        const areAllArraysEmpty = (obj) => {
          return Object.values(obj).every(
            (arr) => Array.isArray(arr) && arr.length === 0
          );
        };
        if (!areAllArraysEmpty(selected)) {
          // fromDt, toDt
          const fromDt = getFromDtAndToDt(searchType, searchDate).fromDt;
          const toDt = getFromDtAndToDt(searchType, searchDate).toDt;
          // dateColumn
          let dateColumn = [];
          switch (searchType) {
            case "year":
              dateColumn = getMonthColumn(searchDate);
              break;
            case "month":
              dateColumn = getDayColumn(searchDate);
              break;
            case "day":
              dateColumn = getHourColumn(searchDate);
              break;
            default:
              dateColumn = getMinColumn(searchDate);
              break;
          }

          // 데이터 수집
          const tempSelected = { ...selected };
          const allPromise = Object.keys(tempSelected).map((key) => {
            if (tempSelected[key].length > 0) {
              const typeData = tempSelected[key].map((rtuItem) => {
                const rtuData = rtuItem.dvcData.map((dvcItem) => {
                  return getDataUpdate(searchType, fromDt, toDt, dvcItem).then(
                    (dvcData) => {
                      const sortData = dateColumn.map((dateItem) => {
                        const check = dvcData.find(
                          (item) => item.date === dateItem
                        );
                        if (check) {
                          return {
                            date: dateItem,
                            [dvcItem.dvcNm]: check.avgVal,
                          };
                        } else {
                          return { date: dateItem, [dvcItem.dvcNm]: null };
                        }
                      });
                      return sortData;
                    }
                  );
                });
                return Promise.all(rtuData).then((rtuData) => {
                  const dateData = dateColumn.map((dateItem) => {
                    const dataItem = { date: dateItem };
                    rtuData.forEach((rtuDataItem) => {
                      const check = rtuDataItem.find(
                        (item) => item.date === dateItem
                      );
                      if (check) {
                        for (let checkKey in check) {
                          if (checkKey !== "date") {
                            dataItem[checkKey] =
                              check[checkKey] === null
                                ? null
                                : parseFloat(check[checkKey]);
                          }
                        }
                      }
                    });
                    return dataItem;
                  });
                  return { ...rtuItem, data: dateData };
                });
              });
              return Promise.all(typeData).then((typeDataResult) => {
                tempSelected[key] = typeDataResult;
              });
            } else {
              return Promise.resolve();
            }
          });
          Promise.all(allPromise).then(() => {
            setSelectedData(tempSelected);
            setIsLoading(false);
          });
        } else {
          setIsLoading(false);
        }
      }
    });
  }

  useEffect(() => {
    dispatch(setMenu(PAGE_VALUE));
    dispatch(setSubmenu(SUBPAGE_VALUE));

    // 토큰 확인
    tokenCheck().then((result) => {
      if (!result) {
        alert("인증 토큰이 만료되었습니다. 다시 로그인 해주세요");
        movePage("/");
      } else {
        setIsToken(true);
        setIsLoading(true);
        getRainSnsr().then((rainRtus) => {
          getLevelSnsr().then((levelRtus) => {
            getSlopeSnsr().then((slopeRtus) => {
              getDisSnsr().then((disRtus) => {
                setRtus({
                  rain: rainRtus,
                  level: levelRtus,
                  slope: slopeRtus,
                  dis: disRtus,
                });
                setIsLoading(false);
              });
            });
          });
        });
      }
    });
  }, []);

  return (
    <div className="outlet_container">
      {
        // 로딩화면
        isLoading && <Loading isOpen={isLoading} setIsOpen={setIsLoading} />
      }
      {isToken && (
        <div id="data-sensor-grouping-page">
          <div className="header_container">
            <div className="title">
              <div className="title_imp">센서 그룹핑</div>
            </div>
            <div className="path">
              <FontAwesomeIcon icon={faHome} />
              <div className="text">&gt; 자료관리 &gt; 센서그룹핑</div>
            </div>
          </div>

          <div className="main_container_row">
            {/* 센서 리스트 */}
            <div className="contents_container sensor-container">
              <div className="contents_header">
                <div className="title">
                  <FontAwesomeIcon icon={faList} />
                  <div className="text">센서</div>
                </div>
              </div>
              <div className="contents_main">
                <SensorList
                  rtus={rtus}
                  selected={selected}
                  setSelected={setSelected}
                />
              </div>
              <div className="contents_footer"></div>
            </div>

            {/* 그래프 */}
            <div className="contents_container graph-container">
              <div className="contents_header">
                <div className="title">
                  <FontAwesomeIcon icon={faChartSimple} />
                  <div className="text">그래프</div>
                </div>
                {/* 검색 조건 */}
                <div className="data-sensor-grouping-search">
                  <input
                    type="radio"
                    id="year"
                    name="searchType"
                    value="year"
                    onChange={(e) => setSearchType(e.target.value)}
                    checked={searchType === "year"}
                  />
                  <label htmlFor="year">월별</label>
                  <input
                    type="radio"
                    id="month"
                    name="searchType"
                    value="month"
                    onChange={(e) => setSearchType(e.target.value)}
                    checked={searchType === "month"}
                  />
                  <label htmlFor="month">일별</label>
                  <input
                    type="radio"
                    id="day"
                    name="searchType"
                    value="day"
                    onChange={(e) => setSearchType(e.target.value)}
                    checked={searchType === "day"}
                  />
                  <label htmlFor="day">시간</label>
                  <input
                    type="radio"
                    id="min"
                    name="searchType"
                    value="min"
                    onChange={(e) => setSearchType(e.target.value)}
                    checked={searchType === "min"}
                  />
                  <label htmlFor="min">분별</label>

                  <DatePicker
                    selected={searchDate}
                    onChange={(date) => setSearchDate(date)}
                    dateFormat={
                      searchType === "year"
                        ? "yyyy"
                        : searchType === "month"
                        ? "yyyy-MM"
                        : (searchType === "day" || searchType === "min") &&
                          "yyyy-MM-dd"
                    }
                    maxDate={new Date()}
                    showYearPicker={searchType === "year" ? true : false}
                    showMonthYearPicker={searchType === "month" ? true : false}
                    locale={locale.ko}
                    customInput={<DATEPICKERSTYLES.data />}
                  />

                  <div
                    className="data-search-button button-imp"
                    onClick={onClickQuery}
                  >
                    조회
                  </div>
                </div>
              </div>
              <div className="contents_main">
                <SensorGraph
                  searchType={searchType}
                  selectedData={selectedData}
                />
              </div>
              <div className="contents_footer"></div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default SensorGrouping;
