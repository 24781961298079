import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setMenu, setSubmenu } from "../../../redux/modules/menu";
import { Menu, Item, useContextMenu } from "react-contexify";
import {
  createColumnHelper,
  flexRender,
  useReactTable,
  getCoreRowModel,
  getSortedRowModel,
} from "@tanstack/react-table";
import axios from "axios";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHome,
  faList,
  faEllipsisVertical,
} from "@fortawesome/free-solid-svg-icons";

import { tokenCheck } from "../../../utils/tokenCheck";
import apiAddress from "../../../services/apiAddress";
import Delete from "../../../components/common/window/Delete";
import TextInform from "../../../components/common/inform/TextInform";
import TextAdd from "../../../components/broadcast/text/TextAdd";
import TextEdit from "../../../components/broadcast/text/TextEdit";

const PAGE_VALUE = "경보방송";
const SUBPAGE_VALUE = "방송문안";
const MENU_ID = "brdCntnList";

function BroadcastText() {
  const dispatch = useDispatch();
  const [isToken, setIsToken] = useState(false);
  const movePage = useNavigate();
  const [isInform, setIsInform] = useState({
    isOpen: false,
    data: {},
  });
  const [isAdd, setIsAdd] = useState(false);
  const [isEdit, setIsEdit] = useState({
    isOpen: false,
    data: {},
  });
  const [isDel, setIsDel] = useState({
    isOpen: false,
    text: "",
  });
  const [isAddOk, setIsAddOk] = useState(false);
  const [isEditOk, setIsEditOk] = useState(false);
  const [isDelOk, setIsDelOk] = useState(false);
  const [search, setSearch] = useState("");
  const [searchInput, setSearchInput] = useState("");
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);
  const columnHelper = createColumnHelper();
  const menuIconColumn = {
    id: "menuIcon",
    accessor: "menu",
    header: "",
    size: 20,
    enableSorting: false,
    cell: ({ row }) => (
      <FontAwesomeIcon className="table_icon" icon={faEllipsisVertical} />
    ),
  };
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });
  const { show } = useContextMenu({
    id: MENU_ID,
  });

  function onClickSearch() {
    // 검색 버튼 클릭.
    // 토큰 확인
    tokenCheck().then((result) => {
      if (!result) {
        alert("인증 토큰이 만료되었습니다. 다시 로그인 해주세요");
        movePage("/");
      } else {
        setSearch(searchInput);
      }
    });
  }
  function onKeyPressSearch(e) {
    // 검색창 Enter.
    if (e.key === "Enter") {
      onClickSearch();
    }
  }
  function onClickAdd() {
    // 문안 추가 시.
    // 토큰 확인
    tokenCheck().then((result) => {
      if (!result) {
        alert("인증 토큰이 만료되었습니다. 다시 로그인 해주세요");
        movePage("/");
      } else {
        setIsAdd(true);
      }
    });
  }
  function onClickMenu(e, row) {
    // 메뉴 클릭 시.
    e.stopPropagation();
    show({ event: e, props: { data: row } });
  }
  function menuItemClick({ id, props }) {
    // 메뉴 Item 클릭 시.
    // 토큰 확인
    tokenCheck().then((result) => {
      if (!result) {
        alert("인증 토큰이 만료되었습니다. 다시 로그인 해주세요");
        movePage("/");
      } else {
        switch (id) {
          // 정보.
          case "inform":
            setIsInform({
              isOpen: true,
              data: props.data,
            });
            break;
          // 수정.
          case "edit":
            setIsEdit({
              isOpen: true,
              data: props.data,
            });
            break;
          // 삭제.
          case "delete":
            setIsDel({
              isOpen: true,
              text: "'" + props.data.brdTitle + "' 문안을 삭제하시겠습니까?",
              data: props.data,
            });
            break;
          default:
            break;
        }
      }
    });
  }

  /* 문안 조회 API */
  async function fetchData() {
    try {
      axios
        .post(
          apiAddress.broadTextGet,
          {
            brdTitle: search,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          const responseData = response.data.filter(
            (item) => item.useYn === "Y"
          );
          const column = [
            columnHelper.accessor("brdTitle", {
              header: "방송제목",
              size: "auto",
            }),
            columnHelper.accessor("brdCntn", {
              header: "내용",
              size: 1300,
            }),
            menuIconColumn,
          ];

          setData(responseData);
          setColumns(column);
        });
    } catch (e) {
      console.log(e);
    }
  }
  /* 문안 삭제 API */
  async function fetchDataDel(data) {
    axios
      .post(apiAddress.broadTextSave, {
        ...data,
        useYn: "D",
        modUsr: "SHDEL",
      })
      .then((response) => {
        setIsDelOk(!isDelOk);
      });
  }

  useEffect(() => {
    dispatch(setMenu(PAGE_VALUE));
    dispatch(setSubmenu(SUBPAGE_VALUE));

    // 토큰 확인
    tokenCheck().then((result) => {
      if (!result) {
        alert("인증 토큰이 만료되었습니다. 다시 로그인 해주세요");
        movePage("/");
      } else {
        setIsToken(true);
      }
    });
  }, []);
  useEffect(() => {
    fetchData();
  }, [search, isAddOk, isEditOk, isDelOk]);

  return (
    <div className="outlet_container">
      {isInform.isOpen && (
        <TextInform isOpen={isInform} setIsOpen={setIsInform} />
      )}
      {isAdd && (
        <TextAdd
          isOpen={isAdd}
          setIsOpen={setIsAdd}
          isOk={isAddOk}
          setIsOk={setIsAddOk}
        />
      )}
      {isEdit.isOpen && (
        <TextEdit
          isOpen={isEdit}
          setIsOpen={setIsEdit}
          isOk={isEditOk}
          setIsOk={setIsEditOk}
        />
      )}
      {isDel.isOpen && (
        <Delete isOpen={isDel} setIsOpen={setIsDel} delData={fetchDataDel} />
      )}
      {isToken && (
        <>
          <div className="header_container">
            <div className="title">
              <div className="title_imp">방송문안</div>
            </div>
            <div className="path">
              <FontAwesomeIcon icon={faHome} />
              <div className="text">&gt; 경보방송 &gt; 방송문안</div>
            </div>
          </div>

          <div className="main_container">
            <div className="contents_container">
              {/* Header */}
              <div className="contents_header">
                <div className="title">
                  <FontAwesomeIcon icon={faList} />
                  <div className="text">방송문안</div>
                  <div
                    className="button_imp"
                    onClick={onClickAdd}
                    style={{ marginLeft: "20px" }}
                  >
                    문안추가
                  </div>
                </div>
                <div className="search">
                  <input
                    type="text"
                    placeholder="방송제목"
                    value={searchInput}
                    onChange={(e) => setSearchInput(e.target.value)}
                    onKeyPress={onKeyPressSearch}
                    spellCheck={false}
                  />
                  <div
                    className="button_nor"
                    onClick={onClickSearch}
                    style={{ marginLeft: "10px" }}
                  >
                    검색
                  </div>
                </div>
              </div>

              {/* Main */}
              <div className="contents_main" style={{ overflow: "auto" }}>
                {data.length > 0 ? (
                  <table className="react_table">
                    <thead>
                      {table.getHeaderGroups().map((headerGroup) => (
                        <tr key={headerGroup.id}>
                          {headerGroup.headers.map((header) => (
                            <th
                              key={header.id}
                              onClick={header.column.getToggleSortingHandler()}
                              style={{
                                width: isNaN(header.getSize())
                                  ? "auto"
                                  : `${header.getSize()}px`,
                                minWidth: isNaN(header.getSize())
                                  ? "auto"
                                  : `${header.getSize()}px`,
                                maxWidth: isNaN(header.getSize())
                                  ? "auto"
                                  : `${header.getSize()}px`,
                                textAlign: "left",
                              }}
                            >
                              {header.isPlaceholder
                                ? null
                                : flexRender(
                                    header.column.columnDef.header,
                                    header.getContext()
                                  )}
                            </th>
                          ))}
                        </tr>
                      ))}
                    </thead>
                    <tbody>
                      {table.getRowModel().rows.map((row, index) => (
                        <tr key={row.id} style={{ cursor: "pointer" }}>
                          {row.getVisibleCells().map((cell) => (
                            <td
                              key={cell.id}
                              onClick={(e) => {
                                if (cell.column.id === "menuIcon") {
                                  onClickMenu(e, row.original);
                                }
                              }}
                              style={{
                                width: isNaN(cell.column.getSize())
                                  ? "auto"
                                  : `${cell.column.getSize()}px`,
                                minWidth: isNaN(cell.column.getSize())
                                  ? "auto"
                                  : `${cell.column.getSize()}px`,
                                maxWidth: isNaN(cell.column.getSize())
                                  ? "auto"
                                  : `${cell.column.getSize()}px`,
                                textAlign:
                                  cell.column.id === "menuIcon" && "center",
                              }}
                            >
                              {flexRender(
                                cell.column.columnDef.cell,
                                cell.getContext()
                              )}
                            </td>
                          ))}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ) : (
                  <div className="brd_data_none_container">
                    <div>등록된 문안이 없습니다</div>
                  </div>
                )}
              </div>

              {/* Footer */}
              <div className="contents_footer"></div>
            </div>
          </div>

          {/* Context 메뉴 */}
          <Menu id={MENU_ID}>
            <Item id="inform" onClick={menuItemClick}>
              정보
            </Item>
            <Item id="edit" onClick={menuItemClick}>
              수정
            </Item>
            <Item id="delete" onClick={menuItemClick}>
              삭제
            </Item>
          </Menu>
        </>
      )}
    </div>
  );
}

export default BroadcastText;
