import {
  getDataUpdate,
  getRainSnsr,
  getLevelSnsr,
  getSlopeSnsr,
  getDisSnsr,
} from "../../services/internal/dataAPI";
import { getCurrTime, zeroPlusNum } from "../time";
import { getEdboardMessage } from "../../services/internal/edboardAPI";
import { getCCTVAndStatus } from "../../services/internal/cctvAPI";

import {
  rainOverlay,
  levelOverlay,
  slopeOverlay,
  disOverlay,
  edbOverlay,
  cctvOverlay,
} from "./overlay";

import RainMarkerImg from "../../assets/map/rainMarker.png";
import LevelMarkerImg from "../../assets/map/levelMarker.png";
import SlopeMarkerImg from "../../assets/map/slopeMarker.png";
import DisMarkerImg from "../../assets/map/disMarker.png";
import EdbMarkerImg from "../../assets/map/edbMarker.png";
import CctvMarkerImg from "../../assets/map/cctvMarker.png";
import { getMinColumn } from "../data";

const { kakao } = window;

/* 공통 */
function makeMarkerInform(type, item, src, event) {
  const imageSrc = src;
  const imageSize = new kakao.maps.Size(34, 40);
  const imageOption = { offset: new kakao.maps.Point(17, 20) };
  let overlayImg, clickOverlayImg;
  switch (type) {
    case "rain":
      overlayImg = rainOverlay(item, event);
      clickOverlayImg = rainOverlay(item, event);
      break;
    case "level":
      overlayImg = levelOverlay(item, event);
      clickOverlayImg = levelOverlay(item, event);
      break;
    case "slope":
      overlayImg = slopeOverlay(item, event);
      clickOverlayImg = slopeOverlay(item, event);
      break;
    case "displacement":
      overlayImg = disOverlay(item, event);
      clickOverlayImg = disOverlay(item, event);
      break;
    case "broad":
      break;
    case "edboard":
      overlayImg = edbOverlay(item, event);
      clickOverlayImg = edbOverlay(item, event);
      break;
    case "cctv":
      overlayImg = cctvOverlay(item, event);
      clickOverlayImg = cctvOverlay(item, event);
      break;
    default:
      break;
  }

  const markerImage = new kakao.maps.MarkerImage(
    imageSrc,
    imageSize,
    imageOption
  );
  const markerPosition = new kakao.maps.LatLng(item.lat, item.lon);

  const marker = new kakao.maps.Marker({
    position: markerPosition,
    image: markerImage,
  });
  const overlay = new kakao.maps.CustomOverlay({
    content: overlayImg,
    position: marker.getPosition(),
    yAnchor: 1.13,
  });
  const clickOverlay = new kakao.maps.CustomOverlay({
    clickable: true,
    content: clickOverlayImg,
    position: marker.getPosition(),
    yAnchor: 1.13,
  });

  return {
    eqId: item.eqId,
    marker: marker,
    overlay: overlay,
    clickOverlay: clickOverlay,
  };
}

/* 강우계 */
export function getRainMarkers(
  map,
  mouseOver,
  mouseOut,
  mouseClick,
  snsrClick
) {
  const date = getCurrTime().date;
  const today =
    zeroPlusNum(getCurrTime().year) +
    zeroPlusNum(getCurrTime().month) +
    zeroPlusNum(getCurrTime().day);
  const fromDt = today + "000000";
  const toDt = today + "235959";
  return getRainSnsr().then((rtuDvc) => {
    if (rtuDvc.length > 0) {
      const promise = rtuDvc.map((rtuItem) => {
        const dvcItem = rtuItem.dvcData[0];
        return getDataUpdate("min", fromDt, toDt, dvcItem).then((data) => {
          if (Array.isArray(data) && data.length > 0) {
            let dateColumn = getMinColumn(date);
            const dateData = dateColumn.map((dateItem) => {
              const check = data.find((item) => item.date === dateItem);
              const val = check ? parseFloat(check.avgVal).toFixed(2) : "-";
              return { date: dateItem, avgVal: val };
            });
            return { ...rtuItem, data: dateData };
          } else {
            return { ...rtuItem, data: [] };
          }
        });
      });

      return Promise.all(promise).then((promiseResult) => {
        const markerInforms = promiseResult.map((rtuItem) => {
          const markerInform = makeMarkerInform(
            "rain",
            rtuItem,
            RainMarkerImg,
            snsrClick
          );

          // 마커 마우스 이벤트
          function over() {
            mouseOver(markerInform.overlay, markerInform.clickOverlay);
          }
          function out() {
            mouseOut(markerInform.overlay);
          }
          function click() {
            mouseClick(markerInform.overlay, markerInform.clickOverlay);
          }

          markerInform.marker.setMap(map);
          kakao.maps.event.addListener(markerInform.marker, "mouseover", over);
          kakao.maps.event.addListener(markerInform.marker, "mouseout", out);
          kakao.maps.event.addListener(markerInform.marker, "click", click);

          return markerInform;
        });
        return markerInforms;
      });
    } else {
      return [];
    }
  });
}
/* 수위계 */
export function getLevelMarkers(
  map,
  mouseOver,
  mouseOut,
  mouseClick,
  snsrClick
) {
  const date = getCurrTime().date;
  const today =
    zeroPlusNum(getCurrTime().year) +
    zeroPlusNum(getCurrTime().month) +
    zeroPlusNum(getCurrTime().day);
  const fromDt = today + "000000";
  const toDt = today + "235959";
  return getLevelSnsr().then((rtuDvc) => {
    if (rtuDvc.length > 0) {
      const promise = rtuDvc.map((rtuItem) => {
        const dvcItem = rtuItem.dvcData[0];
        return getDataUpdate("min", fromDt, toDt, dvcItem).then((data) => {
          if (Array.isArray(data) && data.length > 0) {
            let dateColumn = getMinColumn(date);
            const dateData = dateColumn.map((dateItem) => {
              const check = data.find((item) => item.date === dateItem);
              const val = check ? parseFloat(check.avgVal).toFixed(2) : "-";
              return { date: dateItem, avgVal: val };
            });
            return { ...rtuItem, data: dateData };
          } else {
            return { ...rtuItem, data: [] };
          }
        });
      });

      return Promise.all(promise).then((promiseResult) => {
        const markerInforms = promiseResult.map((rtuItem) => {
          const markerInform = makeMarkerInform(
            "level",
            rtuItem,
            LevelMarkerImg,
            snsrClick
          );

          // 마커 마우스 이벤트
          function over() {
            mouseOver(markerInform.overlay, markerInform.clickOverlay);
          }
          function out() {
            mouseOut(markerInform.overlay);
          }
          function click() {
            mouseClick(markerInform.overlay, markerInform.clickOverlay);
          }

          markerInform.marker.setMap(map);
          kakao.maps.event.addListener(markerInform.marker, "mouseover", over);
          kakao.maps.event.addListener(markerInform.marker, "mouseout", out);
          kakao.maps.event.addListener(markerInform.marker, "click", click);

          return markerInform;
        });
        return markerInforms;
      });
    } else {
      return [];
    }
  });
}
/* 경사계 */
export function getSlopeMarkers(
  map,
  mouseOver,
  mouseOut,
  mouseClick,
  snsrClick
) {
  const date = getCurrTime().date;
  const today =
    zeroPlusNum(getCurrTime().year) +
    zeroPlusNum(getCurrTime().month) +
    zeroPlusNum(getCurrTime().day);
  const fromDt = today + "000000";
  const toDt = today + "235959";
  return getSlopeSnsr().then((rtuDvc) => {
    if (rtuDvc.length > 0) {
      const promise = rtuDvc.map((rtuItem) => {
        const dvcXItem = rtuItem.dvcData[0];
        const dvcYItem = rtuItem.dvcData[1];
        return getDataUpdate("min", fromDt, toDt, dvcXItem).then((xData) => {
          return getDataUpdate("min", fromDt, toDt, dvcYItem).then((yData) => {
            if (
              Array.isArray(xData) &&
              Array.isArray(yData) &&
              (xData.length > 0 || yData.length > 0)
            ) {
              let dateColumn = getMinColumn(date);
              const dateData = dateColumn.map((dateItem) => {
                const xCheck = xData.find((item) => item.date === dateItem);
                const yCheck = yData.find((item) => item.date === dateItem);
                const xVal = xCheck
                  ? parseFloat(xCheck.avgVal).toFixed(2)
                  : "-";
                const yVal = yCheck
                  ? parseFloat(yCheck.avgVal).toFixed(2)
                  : "-";
                return { date: dateItem, x: xVal, y: yVal };
              });
              return { ...rtuItem, data: dateData };
            } else {
              return { ...rtuItem, data: [] };
            }
          });
        });
      });

      return Promise.all(promise).then((promiseResult) => {
        const markerInforms = promiseResult.map((rtuItem) => {
          const markerInform = makeMarkerInform(
            "slope",
            rtuItem,
            SlopeMarkerImg,
            snsrClick
          );

          // 마커 마우스 이벤트
          function over() {
            mouseOver(markerInform.overlay, markerInform.clickOverlay);
          }
          function out() {
            mouseOut(markerInform.overlay);
          }
          function click() {
            mouseClick(markerInform.overlay, markerInform.clickOverlay);
          }

          markerInform.marker.setMap(map);
          kakao.maps.event.addListener(markerInform.marker, "mouseover", over);
          kakao.maps.event.addListener(markerInform.marker, "mouseout", out);
          kakao.maps.event.addListener(markerInform.marker, "click", click);

          return markerInform;
        });
        return markerInforms;
      });
    } else {
      return [];
    }
  });
}
/* 변위계 */
export function getDisMarkers(map, mouseOver, mouseOut, mouseClick, snsrClick) {
  const date = getCurrTime().date;
  const today =
    zeroPlusNum(getCurrTime().year) +
    zeroPlusNum(getCurrTime().month) +
    zeroPlusNum(getCurrTime().day);
  const fromDt = today + "000000";
  const toDt = today + "235959";
  return getDisSnsr().then((rtuDvc) => {
    if (rtuDvc.length > 0) {
      const promise = rtuDvc.map((rtuItem) => {
        const dvcXItem = rtuItem.dvcData[0];
        const dvcYItem = rtuItem.dvcData[1];
        const dvcZItem = rtuItem.dvcData[2];
        return getDataUpdate("min", fromDt, toDt, dvcXItem).then((xData) => {
          return getDataUpdate("min", fromDt, toDt, dvcYItem).then((yData) => {
            return getDataUpdate("min", fromDt, toDt, dvcZItem).then(
              (zData) => {
                if (
                  Array.isArray(xData) &&
                  Array.isArray(yData) &&
                  Array.isArray(zData) &&
                  (xData.length > 0 || yData.length > 0 || zData.length > 0)
                ) {
                  let dateColumn = getMinColumn(date);
                  const dateData = dateColumn.map((dateItem) => {
                    const xCheck = xData.find((item) => item.date === dateItem);
                    const yCheck = yData.find((item) => item.date === dateItem);
                    const zCheck = zData.find((item) => item.date === dateItem);
                    const xVal = xCheck
                      ? parseFloat(xCheck.avgVal).toFixed(2)
                      : "-";
                    const yVal = yCheck
                      ? parseFloat(yCheck.avgVal).toFixed(2)
                      : "-";
                    const zVal = zCheck
                      ? parseFloat(zCheck.avgVal).toFixed(2)
                      : "-";
                    return { date: dateItem, x: xVal, y: yVal, mm: zVal };
                  });
                  return { ...rtuItem, data: dateData };
                } else {
                  return { ...rtuItem, data: [] };
                }
              }
            );
          });
        });
      });

      return Promise.all(promise).then((promiseResult) => {
        const markerInforms = promiseResult.map((rtuItem) => {
          const markerInform = makeMarkerInform(
            "displacement",
            rtuItem,
            DisMarkerImg,
            snsrClick
          );

          // 마커 마우스 이벤트
          function over() {
            mouseOver(markerInform.overlay, markerInform.clickOverlay);
          }
          function out() {
            mouseOut(markerInform.overlay);
          }
          function click() {
            mouseClick(markerInform.overlay, markerInform.clickOverlay);
          }

          markerInform.marker.setMap(map);
          kakao.maps.event.addListener(markerInform.marker, "mouseover", over);
          kakao.maps.event.addListener(markerInform.marker, "mouseout", out);
          kakao.maps.event.addListener(markerInform.marker, "click", click);

          return markerInform;
        });
        return markerInforms;
      });
    } else {
      return [];
    }
  });
}
/* 전광판 */
export function getEdbMarkers(map, mouseOver, mouseOut, mouseClick, edbClick) {
  return getEdboardMessage().then((edbData) => {
    if (Array.isArray(edbData) && edbData.length > 0) {
      const markers = [];
      edbData.forEach((item) => {
        const markerInform = makeMarkerInform(
          "edboard",
          item,
          EdbMarkerImg,
          edbClick
        );

        // 마커 마우스 이벤트
        function over() {
          mouseOver(markerInform.overlay, markerInform.clickOverlay);
        }
        function out() {
          mouseOut(markerInform.overlay);
        }
        function click() {
          mouseClick(markerInform.overlay, markerInform.clickOverlay);
        }

        markerInform.marker.setMap(map);
        kakao.maps.event.addListener(markerInform.marker, "mouseover", over);
        kakao.maps.event.addListener(markerInform.marker, "mouseout", out);
        kakao.maps.event.addListener(markerInform.marker, "click", click);

        markers.push(markerInform);
      });
      return markers;
    }
    return [];
  });
}
/* CCTV */
export function getCCTVMarkers(
  map,
  mouseOver,
  mouseOut,
  mouseClick,
  cctvClick
) {
  return getCCTVAndStatus().then((cctvData) => {
    if (Array.isArray(cctvData) && cctvData.length > 0) {
      const markers = [];
      cctvData.forEach((item) => {
        const markerInform = makeMarkerInform(
          "cctv",
          item,
          CctvMarkerImg,
          cctvClick
        );

        // 마커 마우스 이벤트
        function over() {
          mouseOver(markerInform.overlay, markerInform.clickOverlay);
        }
        function out() {
          mouseOut(markerInform.overlay);
        }
        function click() {
          mouseClick(markerInform.overlay, markerInform.clickOverlay);
        }

        markerInform.marker.setMap(map);
        kakao.maps.event.addListener(markerInform.marker, "mouseover", over);
        kakao.maps.event.addListener(markerInform.marker, "mouseout", out);
        kakao.maps.event.addListener(markerInform.marker, "click", click);

        markers.push(markerInform);
      });
      return markers;
    }

    return [];
  });
}
