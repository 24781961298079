import React, { useState, useEffect } from "react";
import {
  createColumnHelper,
  flexRender,
  useReactTable,
  getCoreRowModel,
  getSortedRowModel,
} from "@tanstack/react-table";
import Modal from "react-modal";
import axios from "axios";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleUp, faAngleDown } from "@fortawesome/free-solid-svg-icons";

import apiAddress from "../../../services/apiAddress";
import AlertRegDevice from "./AlertRegDevice";
import AlertSetting from "./AlertSetting";

Modal.setAppElement("#root");

function AlertReg({ isOpen, setIsOpen, isOk, setIsOk, selectedId }) {
  const [isSelect, setIsSelect] = useState({
    isOpen: false,
    data: {},
  });
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);
  const columnHelper = createColumnHelper();
  const angleIconColumn = {
    id: "angleIcon",
    accessor: "angle",
    header: "",
    enableSorting: false,
    size: 10,
    cell: ({ row }) => {
      return row.original.angle ? (
        <FontAwesomeIcon icon={faAngleUp} />
      ) : (
        <FontAwesomeIcon icon={faAngleDown} />
      );
    },
  };
  const selectColumn = {
    id: "select",
    accessor: "select",
    header: "",
    enableSorting: false,
    size: 70,
    cell: ({ row }) => {
      return (
        <div
          className="button_select"
          onClick={() => onClickSelect(row.original)}
        >
          선택
        </div>
      );
    },
  };
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  function onClickRow(row) {
    // 행 클릭.
    const newData = data.map((item) => {
      if (item.eqId === row.eqId) {
        return { ...item, angle: !item.angle };
      }
      return item;
    });

    setData(newData);
  }
  function onClickSelect(row) {
    // 선택 클릭.
    setIsSelect({
      isOpen: true,
      data: row,
    });
  }
  function onClickClose() {
    // 닫기.
    setIsOpen(false);
  }

  /* RTU 및 DVC 조회 */
  async function fetchData() {
    // 전체 RTU 조회.
    axios
      .post(
        apiAddress.equipGet,
        {
          eqId: 0,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        const rtu = response.data.filter(
          (item) => item.eqType === "RTU" && item.useYn === "Y"
        );

        // 그룹에 이미 등록되어 있는 RTU 조회.
        axios
          .post(
            apiAddress.groupEquipGet,
            {
              groupId: selectedId,
              searchType: "A",
            },
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          )
          .then((response) => {
            const groupRtu = response.data;
            const regRtu = rtu.filter(
              (item) => !groupRtu.some((item2) => item2.eqId === item.eqId)
            );

            // 장비 데이터 조회.
            const fetchDataDev = async (item) => {
              try {
                const response = await axios.post(
                  apiAddress.deviceGet,
                  {
                    eqId: item.eqId,
                  },
                  {
                    headers: {
                      "Content-Type": "application/json",
                    },
                  }
                );

                const responseData = response.data.filter(
                  (device) => device.useYn === "Y"
                );
                return {
                  ...item,
                  dvcData: responseData,
                  angle: false,
                };
              } catch (e) {
                console.log(e);
                return null;
              }
            };

            const fetchRtuDevice = async () => {
              // equip_inf + dvc_inf.
              const rtuDvc = await Promise.all(regRtu.map(fetchDataDev));
              const rtuDvcData = rtuDvc.filter(
                (item) => item.dvcData.length > 0
              );
              // Column.
              const column = [
                columnHelper.accessor("eqNm", {
                  header: "RTU명",
                  size: 150,
                }),
                columnHelper.accessor("commType", {
                  header: "통신",
                  size: 50,
                }),
                columnHelper.accessor("commAddr", {
                  header: "통신정보",
                  size: 90,
                }),
                columnHelper.accessor("bdongCd", {
                  header: "법정동코드",
                  size: 80,
                }),
                columnHelper.accessor("addr", {
                  header: "주소",
                  size: "auto",
                }),
                angleIconColumn,
                selectColumn,
              ];

              setData(rtuDvcData);
              setColumns(column);
            };

            fetchRtuDevice();
          });
      });
  }

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Modal isOpen={isOpen} style={modalStyles}>
      {isSelect.isOpen && (
        /* RTU 선택 후 설정/등록 창 */
        <AlertSetting
          isOpen={isSelect}
          setIsOpen={setIsSelect}
          setIsAllOpen={setIsOpen}
          isOk={isOk}
          setIsOk={setIsOk}
          selectedId={selectedId}
        />
      )}
      <div className="modal_title">
        <div>장비 추가 등록</div>
      </div>
      <div className="modal_container">
        {/* Main */}
        <div className="modal_main_column" style={{ overflow: "auto" }}>
          <table className="react_table">
            <thead>
              {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <th
                      key={header.id}
                      style={{
                        width: isNaN(header.getSize())
                          ? "auto"
                          : `${header.getSize()}px`,
                        minWidth: isNaN(header.getSize())
                          ? "auto"
                          : `${header.getSize()}px`,
                        maxWidth: isNaN(header.getSize())
                          ? "auto"
                          : `${header.getSize()}px`,
                      }}
                    >
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody>
              {table.getRowModel().rows.map((row, index) => (
                <React.Fragment key={row.id}>
                  <tr key={row.id} onClick={() => onClickRow(row.original)}>
                    {row.getVisibleCells().map((cell) => (
                      <td
                        key={cell.id}
                        onClick={(e) => {
                          if (cell.column.id === "select") {
                            e.stopPropagation();
                          }
                        }}
                        style={{
                          width: isNaN(cell.column.getSize())
                            ? "auto"
                            : `${cell.column.getSize()}px`,
                          minWidth: isNaN(cell.column.getSize())
                            ? "auto"
                            : `${cell.column.getSize()}px`,
                          maxWidth: isNaN(cell.column.getSize())
                            ? "auto"
                            : `${cell.column.getSize()}px`,
                          textAlign:
                            (cell.column.id === "commType" ||
                              cell.column.id === "bdongCd" ||
                              cell.column.id === "angleIcon" ||
                              cell.column.id === "select") &&
                            "center",
                        }}
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </td>
                    ))}
                  </tr>
                  {row.original.angle && row.original.dvcData.length > 0 && (
                    <tr key="sub_table-tr">
                      <td
                        className="sub_table-td"
                        colSpan={7}
                        style={{ padding: "0" }}
                      >
                        <AlertRegDevice dvcData={row.original.dvcData} />
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              ))}
            </tbody>
          </table>
        </div>

        {/* Footer */}
        <div className="modal_footer">
          <div className="button_nor" onClick={onClickClose}>
            닫기
          </div>
        </div>
      </div>
    </Modal>
  );
}

const modalStyles = {
  content: {
    width: "990px",
    height: "720px",
    padding: "0px",
    background: "#202020",
    border: "none",
    borderRadius: "5px",
    boxShadow: "-5px 5px 10px rgba(0, 0, 0, 0.7)",
    margin: "auto",
  },
  overlay: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "fixed",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: "rgba(0, 0, 0, 0.4)",
  },
};

export default AlertReg;
