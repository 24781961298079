import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { setMenu, setSubmenu } from "../../../redux/modules/menu";
import { useNavigate } from "react-router-dom";
import axios from "axios";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome, faList } from "@fortawesome/free-solid-svg-icons";

import { tokenCheck } from "../../../utils/tokenCheck";
import apiAddress from "../../../services/apiAddress";
import Delete from "../../../components/common/window/Delete";
import GroupAdd from "../../../components/setting/alert-group/GroupAdd";
import Group from "../../../components/setting/alert-group/Group";
import Device from "../../../components/setting/alert-group/Device";
import AlertReg from "../../../components/setting/alert-group/AlertReg";

const PAGE_VALUE = "설정";
const SUBPAGE_VALUE = "경보그룹";

function SettingAlertGroup() {
  const dispatch = useDispatch();
  const [isToken, setIsToken] = useState(false);
  const movePage = useNavigate();
  const [isAdd, setIsAdd] = useState(false);
  const [isAddOk, setIsAddOk] = useState(false);
  const [selectedGroupId, setSelectedGroupId] = useState(0);

  const [delData, setDelData] = useState([]);
  const [isReg, setIsReg] = useState(false);
  const [isDel, setIsDel] = useState({
    isOpen: false,
    text: "",
  });
  const [isRegOk, setIsRegOk] = useState(false);
  const [isDelOk, setIsDelOk] = useState(false);

  function onClickReg() {
    // 추가 등록.
    // 토큰 확인
    tokenCheck().then((result) => {
      if (!result) {
        alert("인증 토큰이 만료되었습니다. 다시 로그인 해주세요");
        movePage("/");
      } else {
        setIsReg(true);
      }
    });
  }
  function onClickDel() {
    // 삭제.
    // 토큰 확인
    tokenCheck().then((result) => {
      if (!result) {
        alert("인증 토큰이 만료되었습니다. 다시 로그인 해주세요");
        movePage("/");
      } else {
        setIsDel({
          isOpen: true,
          text: delData.length + "개의 장비를 삭제하시겠습니까?",
        });
      }
    });
  }

  /* 경보그룹 - 장비 삭제 API */
  async function fetchData() {
    try {
      // 경보그룹 - rtu 삭제.
      axios
        .post(
          apiAddress.groupEquipGet,
          {
            groupId: selectedGroupId,
            searchType: "A",
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          const rtu = response.data;

          const fetchGroupRtuDevice = async (item) => {
            try {
              const response = await axios.post(
                apiAddress.groupEquipDeviceGet,
                {
                  groupId: selectedGroupId,
                  eqId: item.eqId,
                },
                {
                  headers: {
                    "Content-Type": "application/json",
                  },
                }
              );
              return { ...item, dvcData: response.data };
            } catch (e) {
              console.log(e);
              return null;
            }
          };

          const fetchCompare = async () => {
            const groupRtuDvc = await Promise.all(rtu.map(fetchGroupRtuDevice));

            for (let i = 0; i < groupRtuDvc.length; i++) {
              const delCheck = delData.filter(
                (item) => groupRtuDvc[i].eqId === item.eqId
              );
              if (delCheck.length === groupRtuDvc[i].dvcData.length) {
                axios.delete(
                  apiAddress.groupEquipDelete + groupRtuDvc[i].groupEqId,
                  {
                    headers: {
                      "Content-Type": "application/json",
                    },
                  }
                );
              }
            }

            setDelData([]);
            setIsDelOk(!isDelOk);
          };

          fetchCompare();

          // 경보그룹 - dvc 삭제.
          const fetchDataDvcDel = async (item) => {
            try {
              await axios.delete(
                apiAddress.groupEquipDeviceDelete + item.groupEqDvcId,
                {
                  headers: {
                    "Content-Type": "application/json",
                  },
                }
              );
            } catch (e) {
              console.log(e);
            }
          };
          delData.map(fetchDataDvcDel);
        });
    } catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    dispatch(setMenu(PAGE_VALUE));
    dispatch(setSubmenu(SUBPAGE_VALUE));

    // 토큰 확인
    tokenCheck().then((result) => {
      if (!result) {
        alert("인증 토큰이 만료되었습니다. 다시 로그인 해주세요");
        movePage("/");
      } else {
        setIsToken(true);
      }
    });
  }, []);
  useEffect(() => {
    setDelData([]);
  }, [selectedGroupId]);

  return (
    <div className="outlet_container">
      {isAdd && (
        <GroupAdd
          isOpen={isAdd}
          setIsOpen={setIsAdd}
          isOk={isAddOk}
          setIsOk={setIsAddOk}
        />
      )}
      {isReg && (
        <AlertReg
          isOpen={isReg}
          setIsOpen={setIsReg}
          isOk={isRegOk}
          setIsOk={setIsRegOk}
          selectedId={selectedGroupId}
        />
      )}
      {isDel.isOpen && (
        <Delete isOpen={isDel} setIsOpen={setIsDel} delData={fetchData} />
      )}
      {isToken && (
        <>
          <div className="header_container">
            <div className="title">
              <div className="title_imp">경보 그룹</div>
              <div className="title_nor">설정</div>
            </div>
            <div className="path">
              <FontAwesomeIcon icon={faHome} />
              <div className="text">&gt; 설정 &gt; 경보그룹</div>
            </div>
          </div>

          <div className="main_container_row">
            {/* 경보그룹 리스트 */}
            <div
              className="contents_container"
              style={{
                width: "calc(35% - 10px)",
                height: "100%",
                marginRight: "10px",
              }}
            >
              <div className="contents_header">
                <div className="title">
                  <FontAwesomeIcon icon={faList} />
                  <div className="text">경보그룹</div>
                  <div
                    className="button_imp"
                    onClick={() => {
                      // 토큰 확인
                      tokenCheck().then((result) => {
                        if (!result) {
                          alert(
                            "인증 토큰이 만료되었습니다. 다시 로그인 해주세요"
                          );
                          movePage("/");
                        } else {
                          setIsAdd(true);
                        }
                      });
                    }}
                    style={{ marginLeft: "20px" }}
                  >
                    그룹 추가
                  </div>
                </div>
              </div>

              <div className="contents_main">
                <Group
                  selectedId={selectedGroupId}
                  setSelectedId={setSelectedGroupId}
                  isAddOk={isAddOk}
                />
              </div>
              <div className="contents_footer"></div>
            </div>

            {/* 방송장비/센서 */}
            <div
              className="contents_container"
              style={{
                width: "calc(65% - 10px)",
                height: "100%",
                marginLeft: "10px",
              }}
            >
              <div className="contents_header">
                <div className="title">
                  <FontAwesomeIcon icon={faList} />
                  <div className="text">등록 장비 현황</div>
                  {selectedGroupId !== 0 && (
                    <div
                      className="button_imp"
                      onClick={onClickReg}
                      style={{ marginLeft: "20px" }}
                    >
                      추가 등록
                    </div>
                  )}
                </div>
                {delData.length > 0 && (
                  <div className="button_del" onClick={onClickDel}>
                    삭제
                  </div>
                )}
              </div>

              <div className="contents_main_row">
                {selectedGroupId !== 0 && (
                  <Device
                    selectedId={selectedGroupId}
                    delData={delData}
                    setDelData={setDelData}
                    setIsRegOk={setIsRegOk}
                    isRegOk={isRegOk}
                    isDelOk={isDelOk}
                  />
                )}
              </div>

              <div className="contents_footer"></div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default SettingAlertGroup;
