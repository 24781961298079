import { useState, useEffect } from "react";
import JSMpeg from "@cycjimmy/jsmpeg-player";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faMinus,
  faCaretUp,
  faCaretDown,
  faCaretLeft,
  faCaretRight,
} from "@fortawesome/free-solid-svg-icons";

import {
  getCCTVVideo,
  quitCCTVVideo,
  ptzCCTV,
} from "../../../../services/internal/cctvAPI";

function CCTV({ selected }) {
  const [player, setPlayer] = useState(null);
  const [port, setPort] = useState(null);

  function fetchCCTV(cctvInf) {
    /* CCTV 영상 조회 */
    getCCTVVideo(cctvInf).then((response) => {
      if (response.result === "success") {
        // 연결 성공
        const port = response.port;
        setPort(port);
        const canvas = document.getElementById("map-cctv-canvas");
        const protocol = window.location.protocol;
        let jsmpeg;
        if (protocol === "https:") {
          jsmpeg = new JSMpeg.Player("wss://121.140.107.240:" + port, {
            canvas: canvas,
          });
        } else {
          jsmpeg = new JSMpeg.Player("ws://121.140.107.240:" + port, {
            canvas: canvas,
          });
        }
        setPlayer(jsmpeg);
      } else {
        // 연결 실패
        setPort(null);
      }
    });
  }
  function onClickUp() {
    // 위
    ptzCCTV(selected, "up");
  }
  function onClickLeft() {
    // 좌
    ptzCCTV(selected, "left");
  }
  function onClickRight() {
    // 우
    ptzCCTV(selected, "right");
  }
  function onClickDown() {
    // 아래
    ptzCCTV(selected, "down");
  }
  function onClickZoomIn() {
    // 줌인
    ptzCCTV(selected, "zoom-in");
  }
  function onClickZoomOut() {
    // 줌아웃
    ptzCCTV(selected, "zoom-out");
  }

  useEffect(() => {
    return () => {
      if (port !== null) {
        if (player !== undefined && player !== null) {
          player.destroy();
          setPlayer(null);
        }
        quitCCTVVideo(port);
      }
    };
  }, [port]);
  useEffect(() => {
    setPort(null);
    if (Object.keys(selected).length > 0) {
      fetchCCTV(selected);
    }
  }, [selected]);

  return (
    <div className="cctv-marker-inform">
      <div className="cctv-screen">
        <canvas id="map-cctv-canvas" />
        {(port === null || !selected.connect) && (
          <div className="error-container">
            <div>cctv 연결에 실패하였습니다.</div>
            <div>cctv 정보가 제대로 입력되어 있는지 확인해주세요</div>
          </div>
        )}
      </div>
      <div className="control-container">
        <div className="title-container">
          <div>Pan/Tilt/Zoom Control</div>
        </div>
        <div className="zoom-container">
          <div className="control-title">Zoom</div>
          <div className="pannel-container">
            <div className="zoom-pannel-item plus" onClick={onClickZoomIn}>
              <FontAwesomeIcon icon={faPlus} />
            </div>
            <div className="zoom-pannel-item minus" onClick={onClickZoomOut}>
              <FontAwesomeIcon icon={faMinus} />
            </div>
          </div>
        </div>
        <div className="pan-tilt-container">
          <div className="control-title">Pan/Tilt</div>
          <div className="pannel-container">
            <div className="pt-pannel-item left" onClick={onClickLeft}>
              <FontAwesomeIcon icon={faCaretLeft} />
            </div>
            <div className="pt-pannel-up-down">
              <div className="pt-pannel-item up" onClick={onClickUp}>
                <FontAwesomeIcon icon={faCaretUp} />
              </div>
              <div className="pt-pannel-item down" onClick={onClickDown}>
                <FontAwesomeIcon icon={faCaretDown} />
              </div>
            </div>
            <div className="pt-pannel-item right" onClick={onClickRight}>
              <FontAwesomeIcon icon={faCaretRight} />
            </div>
          </div>
        </div>
        {(port === null || !selected.connect) && (
          <div className="disable-container" />
        )}
      </div>
    </div>
  );
}

export default CCTV;
