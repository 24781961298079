import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setMenu, setSubmenu } from "../../../redux/modules/menu";
import DatePicker from "react-datepicker";
import locale from "../../../data/datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHome,
  faList,
  faChartSimple,
} from "@fortawesome/free-solid-svg-icons";

import { reservoirs } from "../../../constants/latlon";
import { tokenCheck } from "../../../utils/tokenCheck";
import {
  getReservoirDevice,
  getRsvLevelSensor,
  getRsvGroundSensor,
  getRsvDisSensor,
  getRsvRainSensor,
} from "../../../services/internal/rtuAPI";
import {
  getDataUpdate,
  getRsvLevel,
  getRsvGroundLevel,
  getRsvDisplacement,
  getRsvRain,
} from "../../../services/internal/dataAPI";
import {
  getFromDtAndToDt,
  getMonthColumn,
  getDayColumn,
  getHourColumn,
  getMinColumn,
} from "../../../utils/data";
import { DATEPICKERSTYLES } from "../../../constants/datepickerStyles";
import Loading from "../../../components/common/window/Loading";
import SensorList from "../../../components/data/sensor-grouping/SensorList";
import SensorGraph from "../../../components/data/sensor-grouping/SensorGraph";

const PAGE_VALUE = "자료관리";
const SUBPAGE_VALUE = "센서그룹핑";

function SensorGrouping() {
  const dispatch = useDispatch();
  const movePage = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isToken, setIsToken] = useState(false);

  const [selectedRsv, setSelectedRsv] = useState(0);
  const [rsvDevice, setRsvDevice] = useState([]);
  const [rtus, setRtus] = useState({
    rain: [],
    level: [],
    ground: [],
    dis: [],
  });
  const [showRtus, setShowRtus] = useState({
    rain: [],
    level: [],
    ground: [],
    dis: [],
  });
  const [selected, setSelected] = useState({
    rain: [],
    level: [],
    ground: [],
    dis: [],
  });
  const [selectedData, setSelectedData] = useState({});
  const [searchType, setSearchType] = useState("day");
  const [searchDate, setSearchDate] = useState(new Date());

  function onClickQuery() {
    // 조회 클릭 시
    tokenCheck().then((result) => {
      if (!result) {
        alert("인증 토큰이 만료되었습니다. 다시 로그인 해주세요");
        movePage("/");
      } else {
        fetchData();
      }
    });
  }
  const areAllArraysEmpty = (obj) => {
    return Object.values(obj).every(
      (arr) => Array.isArray(arr) && arr.length === 0
    );
  };

  function fetchData() {
    /* 데이터 조회 API */
    setIsLoading(true);
    if (!areAllArraysEmpty(selected)) {
      // fromDt, toDt
      const fromDt = getFromDtAndToDt(searchType, searchDate).fromDt;
      const toDt = getFromDtAndToDt(searchType, searchDate).toDt;
      // dateColumn
      let dateColumn = [];
      switch (searchType) {
        case "year":
          dateColumn = getMonthColumn(searchDate);
          break;
        case "month":
          dateColumn = getDayColumn(searchDate);
          break;
        case "day":
          dateColumn = getHourColumn(searchDate);
          break;
        default:
          dateColumn = getMinColumn(searchDate);
          break;
      }

      // 데이터 수집
      const tempSelected = { ...selected };
      getRsvLevel(rsvDevice, searchType, fromDt, toDt).then((levelData) => {
        getRsvGroundLevel(rsvDevice, searchType, fromDt, toDt).then(
          (groundData) => {
            getRsvDisplacement(rsvDevice, searchType, fromDt, toDt).then(
              (disData) => {
                getRsvRain(rsvDevice, searchType, fromDt, toDt).then(
                  (rainData) => {
                    const filterLevel =
                      Array.isArray(tempSelected.level) &&
                      tempSelected.level.length > 0
                        ? levelData.filter(
                            (item) =>
                              tempSelected.level.find(
                                (item2) => item2.eqId === item.eqId
                              ) !== undefined
                          )
                        : [];
                    const filterGround =
                      Array.isArray(tempSelected.ground) &&
                      tempSelected.ground.length > 0
                        ? groundData.filter(
                            (item) =>
                              tempSelected.ground.find(
                                (item2) => item2.eqId === item.eqId
                              ) !== undefined
                          )
                        : [];
                    const filterDis =
                      Array.isArray(tempSelected.dis) &&
                      tempSelected.dis.length > 0
                        ? disData.filter(
                            (item) =>
                              tempSelected.dis.find(
                                (item2) => item2.eqId === item.eqId
                              ) !== undefined
                          )
                        : [];
                    const filterRain =
                      Array.isArray(tempSelected.rain) &&
                      tempSelected.rain.length > 0
                        ? rainData.filter(
                            (item) =>
                              tempSelected.rain.find(
                                (item2) => item2.eqId === item.eqId
                              ) !== undefined
                          )
                        : [];
                    const filterData = {
                      rain: filterRain,
                      level: filterLevel,
                      ground: filterGround,
                      dis: filterDis,
                    };
                    console.log(filterData);
                    const formatData = Object.keys(filterData).reduce(
                      (acc, key) => {
                        acc[key] = filterData[key].map((rtuItem) => {
                          if (
                            Array.isArray(rtuItem.data) &&
                            rtuItem.data.length > 0
                          ) {
                            return {
                              ...rtuItem, // 기존 rtuItem의 모든 데이터를 유지
                              data: dateColumn.map((dateItem) => {
                                const check = rtuItem.data.find(
                                  (item) => item.date === dateItem
                                );
                                return check
                                  ? {
                                      date: dateItem,
                                      [rtuItem.dvcData[0].dvcNm]: parseFloat(
                                        check.avgVal
                                      ),
                                    }
                                  : {
                                      date: dateItem,
                                      [rtuItem.dvcData[0].dvcNm]: null,
                                    };
                              }),
                            };
                          }
                          return rtuItem; // 데이터가 없는 경우 그대로 반환
                        });
                        return acc;
                      },
                      {}
                    );

                    setSelectedData(formatData);

                    setIsLoading(false);
                  }
                );
              }
            );
          }
        );
      });
    } else {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    dispatch(setMenu(PAGE_VALUE));
    dispatch(setSubmenu(SUBPAGE_VALUE));

    // 토큰 확인
    tokenCheck().then((result) => {
      if (!result) {
        alert("인증 토큰이 만료되었습니다. 다시 로그인 해주세요");
        movePage("/");
      } else {
        setIsToken(true);
        setIsLoading(true);
        getReservoirDevice().then((rsvRtus) => {
          const rainRtus = getRsvRainSensor(rsvRtus);
          const levelRtus = getRsvLevelSensor(rsvRtus);
          const groundRtus = getRsvGroundSensor(rsvRtus);
          const disRtus = getRsvDisSensor(rsvRtus);

          setRsvDevice(rsvRtus);
          setRtus({
            rain: rainRtus,
            level: levelRtus,
            ground: groundRtus,
            dis: disRtus,
          });
          setSelectedRsv(1);
          setIsLoading(false);
        });
      }
    });
  }, []);
  useEffect(() => {
    if (selectedRsv !== 0 && !areAllArraysEmpty(rtus)) {
      setSelected({
        rain: [],
        level: [],
        ground: [],
        dis: [],
      });
      setSelectedData({
        rain: [],
        level: [],
        ground: [],
        dis: [],
      });
      let filterRain = [],
        filterLevel = [],
        filterGround = [],
        filterDis = [];
      switch (selectedRsv) {
        case 1:
          filterLevel = rtus.level.filter((item) => item.eqId === 26);
          filterGround = rtus.ground.filter(
            (item) => item.eqId === 27 || item.eqId === 28
          );
          filterDis = rtus.dis.filter(
            (item) => item.eqId === 29 || item.eqId === 30
          );
          filterRain = rtus.rain.filter((item) => item.eqId === 31);
          break;
        case 2:
          filterLevel = rtus.level.filter((item) => item.eqId === 33);
          filterGround = rtus.ground.filter(
            (item) => item.eqId === 34 || item.eqId === 35
          );
          filterDis = rtus.dis.filter(
            (item) => item.eqId === 36 || item.eqId === 37
          );
          filterRain = rtus.rain.filter((item) => item.eqId === 38);
          break;
        case 3:
          filterLevel = rtus.level.filter((item) => item.eqId === 40);
          filterGround = rtus.ground.filter(
            (item) => item.eqId === 41 || item.eqId === 42
          );
          filterDis = rtus.dis.filter(
            (item) => item.eqId === 43 || item.eqId === 44
          );
          filterRain = rtus.rain.filter((item) => item.eqId === 45);
          break;
        case 4:
          filterLevel = rtus.level.filter((item) => item.eqId === 47);
          filterGround = rtus.ground.filter(
            (item) => item.eqId === 48 || item.eqId === 49
          );
          filterDis = rtus.dis.filter(
            (item) => item.eqId === 50 || item.eqId === 51
          );
          filterRain = rtus.rain.filter((item) => item.eqId === 52);
          break;
        default:
          break;
      }
      setShowRtus({
        rain: filterRain,
        level: filterLevel,
        ground: filterGround,
        dis: filterDis,
      });
    }
  }, [rtus, selectedRsv]);

  return (
    <div className="outlet_container">
      {
        // 로딩화면
        isLoading && <Loading isOpen={isLoading} setIsOpen={setIsLoading} />
      }
      {isToken && (
        <div id="data-sensor-grouping-page">
          <div className="header_container">
            <div className="title">
              <div className="title_imp">센서 그룹핑</div>
            </div>
            <div className="path">
              <FontAwesomeIcon icon={faHome} />
              <div className="text">&gt; 자료관리 &gt; 센서그룹핑</div>
            </div>
          </div>

          <div className="main_container_row">
            {/* 센서 리스트 */}
            <div className="contents_container sensor-container">
              <div className="contents_header">
                <div className="title">
                  <FontAwesomeIcon icon={faList} />
                  <div className="text">센서</div>
                </div>
                <select
                  value={selectedRsv}
                  onChange={(e) => setSelectedRsv(parseInt(e.target.value))}
                >
                  {reservoirs.map((item) => (
                    <option key={item.id} value={item.id}>
                      {item.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="contents_main">
                <SensorList
                  rtus={showRtus}
                  selected={selected}
                  setSelected={setSelected}
                />
              </div>
              <div className="contents_footer"></div>
            </div>

            {/* 그래프 */}
            <div className="contents_container graph-container">
              <div className="contents_header">
                <div className="title">
                  <FontAwesomeIcon icon={faChartSimple} />
                  <div className="text">그래프</div>
                </div>
                {/* 검색 조건 */}
                <div className="data-sensor-grouping-search">
                  <input
                    type="radio"
                    id="year"
                    name="searchType"
                    value="year"
                    onChange={(e) => setSearchType(e.target.value)}
                    checked={searchType === "year"}
                  />
                  <label htmlFor="year">월별</label>
                  <input
                    type="radio"
                    id="month"
                    name="searchType"
                    value="month"
                    onChange={(e) => setSearchType(e.target.value)}
                    checked={searchType === "month"}
                  />
                  <label htmlFor="month">일별</label>
                  <input
                    type="radio"
                    id="day"
                    name="searchType"
                    value="day"
                    onChange={(e) => setSearchType(e.target.value)}
                    checked={searchType === "day"}
                  />
                  <label htmlFor="day">시간</label>
                  <input
                    type="radio"
                    id="min"
                    name="searchType"
                    value="min"
                    onChange={(e) => setSearchType(e.target.value)}
                    checked={searchType === "min"}
                  />
                  <label htmlFor="min">분별</label>

                  <DatePicker
                    selected={searchDate}
                    onChange={(date) => setSearchDate(date)}
                    dateFormat={
                      searchType === "year"
                        ? "yyyy"
                        : searchType === "month"
                        ? "yyyy-MM"
                        : (searchType === "day" || searchType === "min") &&
                          "yyyy-MM-dd"
                    }
                    maxDate={new Date()}
                    showYearPicker={searchType === "year" ? true : false}
                    showMonthYearPicker={searchType === "month" ? true : false}
                    locale={locale.ko}
                    customInput={<DATEPICKERSTYLES.data />}
                  />

                  <div
                    className="data-search-button button-imp"
                    onClick={onClickQuery}
                  >
                    조회
                  </div>
                </div>
              </div>
              <div className="contents_main">
                {!areAllArraysEmpty(selectedData) && (
                  <SensorGraph
                    searchType={searchType}
                    selectedData={selectedData}
                  />
                )}
              </div>
              <div className="contents_footer"></div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default SensorGrouping;
