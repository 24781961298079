import { useState, useEffect } from "react";
import {
  createColumnHelper,
  flexRender,
  useReactTable,
  getCoreRowModel,
  getSortedRowModel,
} from "@tanstack/react-table";

const brdTypes = [
  { value: "01", text: "비상예경보방송기기" },
  { value: "02", text: "마을방송" },
];

function BroadGroupRtuDevice({ brdData }) {
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);
  const columnHelper = createColumnHelper();
  const emptyColumn = {
    id: "empty",
    accessor: "empty",
    header: "",
    enableSorting: false,
    size: 10,
  };
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  useEffect(() => {
    const column = [
      emptyColumn,
      emptyColumn,
      columnHelper.accessor("dvcNm", {
        header: "장비명",
      }),
      columnHelper.accessor(
        (row) => {
          return brdTypes.find((type) => type.value === row.dvcDtlType)?.text;
        },
        {
          header: "타입",
          size: "auto",
        }
      ),
    ];

    setData(brdData);
    setColumns(column);
  }, [brdData]);

  return (
    <table className="sub_table">
      <tbody>
        {table.getRowModel().rows.map((row, index) => (
          <tr key={row.id} style={{ background: "#000000", cursor: "pointer" }}>
            {row.getVisibleCells().map((cell) => (
              <td
                key={cell.id}
                style={{
                  width: isNaN(cell.column.getSize())
                    ? "auto"
                    : `${cell.column.getSize()}px`,
                  minWidth: isNaN(cell.column.getSize())
                    ? "auto"
                    : `${cell.column.getSize()}px`,
                  maxWidth: isNaN(cell.column.getSize())
                    ? "auto"
                    : `${cell.column.getSize()}px`,
                }}
              >
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
}

export default BroadGroupRtuDevice;
