import { useState, useEffect } from "react";
import Modal from "react-modal";

import { dateToFormat } from "../../../utils/dateFormat";
import { MODALSTYLES } from "../../../constants/modalStyles";
import { RTUOPTIONS } from "../../../constants/rtuOptions";

Modal.setAppElement("#root");

function RtuInform({ isOpen, setIsOpen }) {
  const [inform, setInform] = useState({});

  function onClickClose() {
    // 닫기 버튼 클릭시
    setIsOpen({ isOpen: false, data: {} });
  }

  useEffect(() => {
    setInform(isOpen.data);
  }, [isOpen.data]);

  return (
    <Modal isOpen={isOpen.isOpen} style={MODALSTYLES.rtu}>
      <div className="modal_title">
        <div>RTU 정보</div>
      </div>

      <div className="modal_container">
        <div className="modal_main_column">
          <div className="rtu-inform-modal">
            <div className="inform-container">
              <div className="title">RTU명</div>
              <div className="inform inform-one">{inform.eqNm}</div>
            </div>
            <div className="inform-container">
              <div className="title">설치일자</div>
              <div className="inform inform-two">
                {dateToFormat(inform.instlDt)}
              </div>
              <div className="inner-title">제조업체</div>
              <div className="inform inform-two">
                {inform.mnfct === "" ? "-" : inform.mnfct}
              </div>
            </div>
            <div className="inform-container">
              <div className="title">통신</div>
              <div className="inform inform-two">
                {
                  RTUOPTIONS.connectType.find(
                    (item) => item.value === inform.commType
                  )?.text
                }
              </div>
              <div className="inner-title">BaudRate</div>
              <div className="inform inform-two">
                {inform.commSpd === "" ? "-" : inform.commSpd}
              </div>
            </div>
            {inform.commType === "TCP/IP" ? (
              <div className="inform-container">
                <div className="title">IP</div>
                <div className="inform inform-two">{inform.commAddr}</div>
                <div className="inner-title">Port</div>
                <div className="inform inform-two">{inform.commPort}</div>
              </div>
            ) : (
              inform.commType === "LTE" && (
                <div className="inform-container">
                  <div className="title">전화번호</div>
                  <div className="inform inform-one">{inform.commAddr}</div>
                </div>
              )
            )}
            <div className="inform-container">
              <div className="title">법정동코드</div>
              <div className="inform inform-bdong">{inform.bdongCd}</div>
              <div className="inner-title">주소</div>
              <div className="inform inform-addr">{inform.addr}</div>
            </div>
            <div className="inform-container">
              <div className="title">상세주소</div>
              <div className="inform inform-one">
                {inform.addrDtl === "" ? "-" : inform.addrDtl}
              </div>
            </div>
            <div className="inform-container">
              <div className="title">위도</div>
              <div className="inform inform-two">{inform.lat}</div>
              <div className="inner-title">경도</div>
              <div className="inform inform-two">{inform.lon}</div>
            </div>
            <div className="last-inform-container">
              <div className="title">메모</div>
              <textarea
                value={inform.eqDtl === "" ? "-" : inform.eqDtl}
                disabled={true}
              />
            </div>
          </div>
        </div>

        <div className="modal_footer">
          <div className="button-nor-modal" onClick={onClickClose}>
            닫기
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default RtuInform;
