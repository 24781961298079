import { useState, useEffect } from "react";

import { getWeatherAlert } from "../../services/external/kmaAPI";

function WeatherAlert() {
  const [data, setData] = useState([]);
  const [time, setTime] = useState({
    date: "",
    time: "",
  });
  const [contents, setContents] = useState("");

  /* 색상 바꾸기 */
  const renderStyledText = (text) => {
    const alertPattern = /\*\*(.*?)\*\*/g;
    const warningPattern = /\*(.*?)\*/g;
    const combinedPattern = new RegExp(
      `(${alertPattern.source}|${warningPattern.source})`,
      "g"
    );
    let match;
    let indices = [];
    let lastIndex = 0;
    let parts = [];

    while ((match = combinedPattern.exec(text)) !== null) {
      indices.push([match.index, match.index + match[0].length - 1]);
    }
    indices.forEach((item, index) => {
      // 이전 텍스트 push
      parts.push(text.substring(lastIndex, item[0]));
      // 특정 문구 <span>으로 변환하여 push
      const target = text.substring(item[0], item[1] + 1);
      if (target.startsWith("**")) {
        const transform = target.replace(/\*\*(.*?)\:\*\*/g, "**$1**");
        parts.push(
          <span key={`warning-${item[0]}`} style={{ color: "#E72F2F" }}>
            {transform}
          </span>
        );
      }
      if (!target.startsWith("**") && target.startsWith("*")) {
        const transform = target.replace(/\*(.*?)\:\*/g, "*$1*");
        parts.push(
          <span key={`alert-${item[0]}`} style={{ color: "#FFC000" }}>
            {transform}
          </span>
        );
      }
      lastIndex = item[1] + 1; // lastIndex 설정
      // 마지막이면 나머지 문구 다 넣기
      if (index === indices.length - 1) {
        parts.push(text.substring(lastIndex));
      }
    });

    return parts;
  };
  /* *[문자열]:* => *[문자열]* 변환 */
  const transformContents = (text) => {
    return text.replace(/\*(.*?)\*:/g, "*$1*");
  };

  useEffect(() => {
    getWeatherAlert().then((alert) => {
      if (Array.isArray(alert) && alert.length > 0) {
        // 특보 O
        const base = alert[0].tmFc.toString();
        const baseDate = base.slice(0, 8);
        const baseTime = base.slice(8);

        setData(alert);
        setTime({ date: baseDate, time: baseTime });
        const transform = transformContents(
          `${alert[0].t6}${
            alert[0].t7.includes("o 없음") ? "" : `\n\n${alert[0].t7}`
          }`
        );
        setContents(transform);
      } else {
        // 특보 X
        setData([]);
      }
    });

    const intervalId = setInterval(() => {
      getWeatherAlert().then((alert) => {
        if (Array.isArray(alert) && alert.length > 0) {
          // 특보 O
          const base = alert[0].tmFc.toString();
          const baseDate = base.slice(0, 8);
          const baseTime = base.slice(8);

          setData(alert);
          setTime({ date: baseDate, time: baseTime });
          setContents(
            `${alert[0].t6}${
              alert[0].t7.includes("o 없음") ? "" : `\n\n${alert[0].t7}`
            }`
          );
        } else {
          // 특보 X
          setData([]);
        }
      });
    }, 1800000); // 30분
    return () => clearInterval(intervalId);
  }, []);

  return (
    <div id="allstatus-alert-component">
      {data.length === 0 ? (
        <div className="data-none-container">
          현재 발효 중인 기상특보는 없습니다
        </div>
      ) : (
        <div className="alert-contents">
          {/* 발효시각 */}
          <div className="time-container">
            <div className="date-time">
              <div>{time.date}</div>
              <div className="right">{time.time}</div>
            </div>
          </div>

          {/* 특보내용 */}
          <div className="alert-text">{renderStyledText(contents)}</div>
          {/*<textarea value={contents} disabled={true} />*/}
        </div>
      )}
    </div>
  );
}

export default WeatherAlert;
