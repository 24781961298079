import { createColumnHelper } from "@tanstack/react-table";

import { zeroPlusNum } from "./time";
const columnHelper = createColumnHelper();

/* 테이블 Column */
export function getDataTableColumn(term) {
  const columns = [];

  switch (term) {
    case "year":
      for (let i = 1; i <= 12; i++) {
        columns.push(
          columnHelper.accessor(
            (row) => {
              const checkYear = row.data.find(
                (item) => item.date.substring(5, 7) === zeroPlusNum(i)
              );
              if (row.data.length > 0 && checkYear) {
                return checkYear.avgVal;
              } else {
                return "-";
              }
            },
            { header: zeroPlusNum(i), size: 50 }
          )
        );
      }
      break;
    case "month":
      for (let i = 1; i <= 31; i++) {
        columns.push(
          columnHelper.accessor(
            (row) => {
              const checkMonth = row.data.find(
                (item) => item.date.substring(8, 10) === zeroPlusNum(i)
              );
              if (row.data.length > 0 && checkMonth) {
                return checkMonth.avgVal;
              } else {
                return "-";
              }
            },
            { header: zeroPlusNum(i), size: 50 }
          )
        );
      }
      break;
    case "day":
      for (let i = 0; i < 24; i++) {
        columns.push(
          columnHelper.accessor(
            (row) => {
              const checkDay = row.data.find(
                (item) => item.date.substring(11, 13) === zeroPlusNum(i)
              );
              if (row.data.length > 0 && checkDay) {
                return checkDay.avgVal;
              } else {
                return "-";
              }
            },
            { header: zeroPlusNum(i), size: 50 }
          )
        );
      }
      break;
    default:
      break;
  }

  return columns;
}
// 경사계&변위계
export function getDataTableColumnDate(term) {
  const columns = [];

  switch (term) {
    case "year":
      for (let i = 1; i <= 12; i++) {
        columns.push(i);
      }
      break;
    case "month":
      for (let i = 1; i <= 31; i++) {
        columns.push(i);
      }
      break;
    case "day":
      for (let i = 0; i < 24; i++) {
        columns.push(i);
      }
      break;
    default:
      break;
  }

  return columns;
}
export function getDataTableColumnDateValue(term, num, type, row) {
  const data = row.original.data;
  let value = "-";

  switch (term) {
    case "year":
      const checkYear = data.find(
        (item) => item.date.substring(5, 7) === zeroPlusNum(num)
      );
      if (data.length > 0 && checkYear) {
        value = checkYear[type];
      } else {
        value = "-";
      }
      break;
    case "month":
      const checkMonth = data.find(
        (item) => item.date.substring(8, 10) === zeroPlusNum(num)
      );
      if (data.length > 0 && checkMonth) {
        value = checkMonth[type];
      } else {
        value = "-";
      }
      break;
    case "day":
      const checkDay = data.find(
        (item) => item.date.substring(11, 13) === zeroPlusNum(num)
      );
      if (data.length > 0 && checkDay) {
        value = checkDay[type];
      } else {
        value = "-";
      }

      break;
    default:
      break;
  }

  return value;
}

/* Date형식을 String으로 변환 */
export function getFromDtAndToDt(type, date) {
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();

  switch (type) {
    case "year":
      const fromDtYear = zeroPlusNum(year) + "0101000000";
      const toDtYear = zeroPlusNum(year) + "1231235959";
      return { fromDt: fromDtYear, toDt: toDtYear };
    case "month":
      const fromDtMonth = zeroPlusNum(year) + zeroPlusNum(month) + "01000000";
      const toDtMonth = zeroPlusNum(year) + zeroPlusNum(month) + "31235959";
      return { fromDt: fromDtMonth, toDt: toDtMonth };
    default:
      const fromDtDay =
        zeroPlusNum(year) + zeroPlusNum(month) + zeroPlusNum(day) + "000000";
      const toDtDay =
        zeroPlusNum(year) + zeroPlusNum(month) + zeroPlusNum(day) + "235959";
      return { fromDt: fromDtDay, toDt: toDtDay };
  }
}
/* data의 날짜형식 만들기 { date: ?? } */
export function dataDateToFormat(type, data) {
  const year = data.yymm.substring(0, 4);
  const month = data.mm;
  const day = data.dd;
  const hour = data.hh;

  switch (type) {
    case "year":
      return year + "-" + month;
    case "month":
      return year + "-" + month + "-" + day;
    case "day":
      return year + "-" + month + "-" + day + " " + hour;
    default:
      return year + "-" + month + "-" + day + " " + hour + ":" + data.min;
  }
}

/* 월(Month)별로 */
export function getMonthColumn(date) {
  const monthColumn = [];

  const year = date.getFullYear();
  for (let i = 1; i <= 12; i++) {
    monthColumn.push(year + "-" + zeroPlusNum(i));
  }

  return monthColumn;
}
/* 일(Day)별로 */
export function getDayColumn(date) {
  const dayColumn = [];

  const yearMonth = date.getFullYear() + "-" + zeroPlusNum(date.getMonth() + 1);
  for (let i = 1; i <= 31; i++) {
    dayColumn.push(yearMonth + "-" + zeroPlusNum(i));
  }

  return dayColumn;
}
/* 시간(Hour)별로 */
export function getHourColumn(date) {
  const hourColumn = [];

  const yearMonthDay =
    date.getFullYear() +
    "-" +
    zeroPlusNum(date.getMonth() + 1) +
    "-" +
    zeroPlusNum(date.getDate());
  for (let i = 0; i < 24; i++) {
    hourColumn.push(yearMonthDay + " " + zeroPlusNum(i));
  }

  return hourColumn;
}
/* 분(Minute)별로 */
// 오늘) 00:00부터 현재 시간까지 분단위 배열
// 오늘X) 00:00부터 23:59까지 분단위 배열
export function getMinColumn(date) {
  const now = new Date();
  if (
    date.getFullYear() === now.getFullYear() &&
    date.getMonth() === now.getMonth() &&
    date.getDate() === now.getDate()
  ) {
    // 오늘
    const startOfDay = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate()
    );
    const minutesArray = [];

    const formatDate = (dateF) => {
      const year = dateF.getFullYear();
      const month = String(dateF.getMonth() + 1).padStart(2, "0");
      const day = String(dateF.getDate()).padStart(2, "0");
      const hours = String(dateF.getHours()).padStart(2, "0");
      const minutes = String(dateF.getMinutes()).padStart(2, "0");
      return `${year}-${month}-${day} ${hours}:${minutes}`;
    };

    for (let t = startOfDay; t <= date; t.setMinutes(t.getMinutes() + 1)) {
      minutesArray.push(formatDate(new Date(t)));
    }

    return minutesArray;
  } else {
    // 오늘 아닌 다른 날짜
    const startOfDay = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      0,
      0,
      0
    );
    const endOfDay = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      23,
      59,
      0
    );
    const minutesArray = [];

    const formatDate = (date) => {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
      const hours = String(date.getHours()).padStart(2, "0");
      const minutes = String(date.getMinutes()).padStart(2, "0");
      return `${year}-${month}-${day} ${hours}:${minutes}`;
    };

    for (let t = startOfDay; t <= endOfDay; t.setMinutes(t.getMinutes() + 1)) {
      minutesArray.push(formatDate(new Date(t)));
    }

    return minutesArray;
  }
}

/* Excel 파일 이름 */
export function getDataExcelName(term, date) {
  const year = zeroPlusNum(date.getFullYear());
  const month = zeroPlusNum(date.getMonth() + 1);
  const day = zeroPlusNum(date.getDate());

  switch (term) {
    case "year":
      return { date: year, term: "월별" };
    case "month":
      return { date: year + month, term: "일별" };
    case "day":
      return { date: year + month + day, term: "시간별" };
    default:
      return { date: year + month + day, term: "분별" };
  }
}
