import { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setMenu } from "../../../redux/modules/menu";

import { tokenCheck } from "../../../utils/tokenCheck";
import { latlon } from "../../../constants/latlon";
import {
  getRainMarkers,
  getLevelMarkers,
  getSlopeMarkers,
  getDisMarkers,
  getEdbMarkers,
  getCCTVMarkers,
} from "../../../utils/map/marker";
import MapControlBar from "../../../components/map/MapControlBar";
import MapType from "../../../components/map/MapType";
import MapDvcPrint from "../../../components/map/MapDvcPrint";
import MapLegend from "../../../components/map/MapLegend";
import MarkerInform from "../../../components/map/inform/MarkerInform";

const { kakao } = window;
const PAGE_VALUE = "지도";

function Map() {
  const dispatch = useDispatch();
  const movePage = useNavigate();
  const [isToken, setIsToken] = useState(false);
  const [isChange, setIsChange] = useState(false);

  const [mapType, setMapType] = useState("satellite"); // 지도 관련
  const [map, setMap] = useState(null);
  const mapRef = useRef(null);
  const mapContainerRef = useRef(null);
  const [mapLevel, setMapLevel] = useState(3);
  const [dvcPrint, setDvcPrint] = useState([
    "rain",
    "level",
    "slope",
    "displacement",
    "broadcast",
    "edboard",
    "cctv",
  ]);
  const [dvcPrintBfr, setDvcPrintBfr] = useState([
    "rain",
    "level",
    "slope",
    "displacement",
    "broadcast",
    "edboard",
    "cctv",
  ]);

  const [selected, setSelected] = useState({});
  const [rainMarker, setRainMarker] = useState([]);
  const [levelMarker, setLevelMarker] = useState([]);
  const [slopeMarker, setSlopeMarker] = useState([]);
  const [disMarker, setDisMarker] = useState([]);
  const [edbMarker, setEdbMarker] = useState([]);
  const [cctvMarker, setCctvMarker] = useState([]);

  const [isRoadView, setIsRoadView] = useState(false); // 로드뷰
  const isRoadViewRef = useRef(null);
  const [isRoadViewImg, setIsRoadViewImg] = useState(false);
  const [roadOverlay, setRoadOverlay] = useState(null);
  const roadOverlayRef = useRef(null);
  let mouseMoveEventHandler, mouseUpEventHandler;

  /* 마커 이벤트 */
  function markerMouseOver(overlay, clickOverlay) {
    if (clickOverlay.getMap() === null) {
      overlay.setMap(map);
    }
  }
  function markerMouseOut(overlay) {
    overlay.setMap(null);
  }
  function markerClick(overlay, clickOverlay) {
    overlay.setMap(null);
    if (clickOverlay.getMap() === null) {
      clickOverlay.setMap(map);
    } else {
      clickOverlay.setMap(null);
    }
  }
  /* 장비 표시(마커&오버레이) 관련 이벤트 */
  function markerOverlayControl(type, markerInformArr) {
    if (!dvcPrint.includes(type) && dvcPrintBfr.includes(type)) {
      // 표시X
      hiddenMarkerOverlay(markerInformArr);
    }
    if (dvcPrint.includes(type) && !dvcPrintBfr.includes(type)) {
      // 표시O
      printMarkerOverlay(markerInformArr);
    }
  }
  function printMarkerOverlay(markerInformArr) {
    // 장비 표시 O
    if (Array.isArray(markerInformArr) && markerInformArr.length > 0) {
      markerInformArr.forEach((item) => {
        item.marker.setMap(map);
      });
    }
  }
  function hiddenMarkerOverlay(markerInformArr) {
    // 장비 표시 X
    if (Array.isArray(markerInformArr) && markerInformArr.length > 0) {
      markerInformArr.forEach((item) => {
        item.marker.setMap(null);
        item.overlay.setMap(null);
        item.clickOverlay.setMap(null);
      });
    }
  }
  /* 마커 클릭 */
  function onClickMarker(markerInf) {
    setSelected(markerInf);
  }
  /* 로드뷰 이미지 띄우기 */
  function createRoadViewImg(position, overlay) {
    const roadContainer = document.getElementById("map-kakao-road");
    const roadValue = new kakao.maps.Roadview(roadContainer);
    const roadClient = new kakao.maps.RoadviewClient();
    // 좌표가 바뀌었을 때 발생하는 이벤트 등록
    kakao.maps.event.addListener(roadValue, "position_changed", function () {
      const roadPosition = roadValue.getPosition();
      if (mapRef.current !== null) {
        mapRef.current.setCenter(roadPosition);
      }
      overlay.setPosition(roadPosition);
    });
    // viewPoint가 바뀌었을 때 발생하는 이벤트 등록
    kakao.maps.event.addListener(roadValue, "viewpoint_changed", function () {
      const viewPoint = roadValue.getViewpoint();
      changeAngle(viewPoint.pan);
    });
    roadClient.getNearestPanoId(position, 50, function (panoId) {
      if (panoId === null) {
        setIsRoadViewImg(false);
      } else {
        setIsRoadViewImg(true);
        roadValue.setPanoId(panoId, position);
      }
    });
  }
  /* 로드뷰 viewpoint 변경 */
  function changeAngle(angle) {
    const threshold = 22.5;
    for (let i = 0; i < 16; i++) {
      if (angle > threshold * i && angle < threshold * (i + 1)) {
        const contentClass = document.getElementsByClassName("map-walker");
        const className = "m" + i;
        contentClass[0].className =
          contentClass[0].className.split(" ")[0] + " " + className;
        break;
      }
    }
  }
  /* 지도 클릭 이벤트 핸들러 */
  function onClickMap(e) {
    if (isRoadViewRef.current !== null && roadOverlayRef.current !== null) {
      if (!isRoadViewRef.current) {
        return;
      }
      const position = e.latLng;
      roadOverlayRef.current.setPosition(position);
      createRoadViewImg(position, roadOverlayRef.current);
    }
  }
  /* 로드뷰 오버레이 이벤트 핸들러 */
  function onMouseDown(e, overlay) {
    if (e.preventDefault) {
      e.preventDefault();
    } else {
      e.returnValue = false;
    }

    const proj = map.getProjection();
    const overlayPosition = overlay.getPosition();
    kakao.maps.event.preventMap();

    const startX = e.clientX;
    const startY = e.clientY;
    const startOverlayPoint = proj.containerPointFromCoords(overlayPosition);

    mouseMoveEventHandler = (e) =>
      onMouseMove(e, overlay, startX, startY, startOverlayPoint);
    mouseUpEventHandler = () => onMouseUp(overlay);
    document.addEventListener("mousemove", mouseMoveEventHandler);
    document.addEventListener("mouseup", mouseUpEventHandler);
  }
  function onMouseMove(e, overlay, startX, startY, startOverlayPoint) {
    if (e.preventDefault) {
      e.preventDefault();
    } else {
      e.returnValue = false;
    }

    const proj = map.getProjection();
    const deltaX = startX - e.clientX;
    const deltaY = startY - e.clientY;
    const newPoint = new kakao.maps.Point(
      startOverlayPoint.x - deltaX,
      startOverlayPoint.y - deltaY
    );
    const newPosition = proj.coordsFromContainerPoint(newPoint);

    overlay.setPosition(newPosition);
  }
  function onMouseUp(overlay) {
    document.removeEventListener("mousemove", mouseMoveEventHandler);
    document.removeEventListener("mouseup", mouseUpEventHandler);

    const overlayPosition = overlay.getPosition();
    createRoadViewImg(overlayPosition, overlay);
  }

  useEffect(() => {
    dispatch(setMenu(PAGE_VALUE));
    /* 지도 */
    const container = document.getElementById("map-kakao-map");
    const options = {
      center: new kakao.maps.LatLng(latlon.lat, latlon.lon),
      level: 3,
    };
    const defaultMap = new kakao.maps.Map(container, options);

    kakao.maps.event.addListener(defaultMap, "click", onClickMap);
    kakao.maps.event.addListener(defaultMap, "zoom_changed", function () {
      setMapLevel(defaultMap.getLevel());
    });

    defaultMap.setMinLevel(1);
    defaultMap.setMaxLevel(13);
    defaultMap.setMapTypeId(kakao.maps.MapTypeId.SKYVIEW);
    setMap(defaultMap);
    mapRef.current = defaultMap;

    // 지도 width 변화 감지 이벤트 등록
    const resizeObserver = new ResizeObserver((entries) => {
      if (entries[0].contentRect) {
        if (mapRef.current !== null) {
          mapRef.current.relayout();
        }
      }
    });
    if (mapContainerRef.current) {
      resizeObserver.observe(mapContainerRef.current);
    }

    // 토큰 확인
    tokenCheck().then((result) => {
      if (!result) {
        alert("인증 토큰이 만료되었습니다. 다시 로그인 해주세요");
        movePage("/");
      } else {
        setIsToken(true);
      }
    });

    return () => {
      // 지도 width 변화 감지 이벤트 삭제
      if (mapContainerRef.current) {
        resizeObserver.unobserve(mapContainerRef.current);
      }
    };
  }, []);
  useEffect(() => {
    /* 지도 확대/축소 레벨 변경 */
    if (map !== null) {
      map.setLevel(mapLevel, { animate: true });
    }
  }, [mapLevel]);
  useEffect(() => {
    /* 지도 위성/일반 변경 */
    if (map !== null) {
      switch (mapType) {
        case "satellite":
          map.setMapTypeId(kakao.maps.MapTypeId.SKYVIEW);
          break;
        case "normal":
          map.setMapTypeId(kakao.maps.MapTypeId.ROADMAP);
          break;
        default:
          break;
      }
    }
  }, [mapType]);
  useEffect(() => {
    /* 지도 변경 -> 마커 생성 */
    mapRef.current = map;
    if (map !== null) {
      // 강우계
      getRainMarkers(
        map,
        markerMouseOver,
        markerMouseOut,
        markerClick,
        onClickMarker
      ).then((response) => {
        setRainMarker(response);
      });
      // 수위계
      getLevelMarkers(
        map,
        markerMouseOver,
        markerMouseOut,
        markerClick,
        onClickMarker
      ).then((response) => {
        setLevelMarker(response);
      });
      // 경사계
      getSlopeMarkers(
        map,
        markerMouseOver,
        markerMouseOut,
        markerClick,
        onClickMarker
      ).then((response) => {
        setSlopeMarker(response);
      });
      // 변위계
      getDisMarkers(
        map,
        markerMouseOver,
        markerMouseOut,
        markerClick,
        onClickMarker
      ).then((response) => {
        setDisMarker(response);
      });
      // 전광판
      getEdbMarkers(
        map,
        markerMouseOver,
        markerMouseOut,
        markerClick,
        onClickMarker
      ).then((response) => {
        setEdbMarker(response);
      });
      // CCTV
      getCCTVMarkers(
        map,
        markerMouseOver,
        markerMouseOut,
        markerClick,
        onClickMarker
      ).then((response) => {
        setCctvMarker(response);
      });
    }
  }, [map]);

  /* 1분마다 상태 업데이트*/
  useEffect(() => {
    // 강우계
    if (map !== null) {
      const timer = setTimeout(() => {
        getRainMarkers(
          map,
          markerMouseOver,
          markerMouseOut,
          markerClick,
          onClickMarker
        ).then((response) => {
          if (Array.isArray(rainMarker) && rainMarker.length > 0) {
            rainMarker.forEach((item) => {
              // 새로운 마커 정보 => 이전 마커 정보와 동일하게
              const target = response.find((item2) => item2.eqId === item.eqId);
              if (target) {
                // 마커 (default: 마커 출력O)
                if (item.marker.getMap() === null) {
                  target.marker.setMap(null);
                }
                // 오버레이 (default: 오버레이 출력X)
                if (item.overlay.getMap() !== null) {
                  target.overlay.setMap(map);
                }
                // 클릭오버레이 (default: 클릭오버레이 출력X)
                if (item.clickOverlay.getMap() !== null) {
                  target.clickOverlay.setMap(map);
                }
              }

              // 이전 마커 정보 setMap(null)
              item.marker.setMap(null);
              item.overlay.setMap(null);
              item.clickOverlay.setMap(null);
            });
          }
          setRainMarker(response);
        });
      }, 60000);

      return () => clearTimeout(timer);
    }
  }, [map, rainMarker]);
  useEffect(() => {
    // 수위계
    if (map !== null) {
      const timer = setTimeout(() => {
        getLevelMarkers(
          map,
          markerMouseOver,
          markerMouseOut,
          markerClick,
          onClickMarker
        ).then((response) => {
          if (Array.isArray(levelMarker) && levelMarker.length > 0) {
            levelMarker.forEach((item) => {
              // 새로운 마커 정보 => 이전 마커 정보와 동일하게
              const target = response.find((item2) => item2.eqId === item.eqId);
              if (target) {
                // 마커 (default: 마커 출력O)
                if (item.marker.getMap() === null) {
                  target.marker.setMap(null);
                }
                // 오버레이 (default: 오버레이 출력X)
                if (item.overlay.getMap() !== null) {
                  target.overlay.setMap(map);
                }
                // 클릭오버레이 (default: 클릭오버레이 출력X)
                if (item.clickOverlay.getMap() !== null) {
                  target.clickOverlay.setMap(map);
                }
              }

              // 이전 마커 정보 setMap(null)
              item.marker.setMap(null);
              item.overlay.setMap(null);
              item.clickOverlay.setMap(null);
            });
          }
          setLevelMarker(response);
        });
      }, 60000);

      return () => clearTimeout(timer);
    }
  }, [map, levelMarker]);
  useEffect(() => {
    // 경사계
    if (map !== null) {
      const timer = setTimeout(() => {
        getSlopeMarkers(
          map,
          markerMouseOver,
          markerMouseOut,
          markerClick,
          onClickMarker
        ).then((response) => {
          if (Array.isArray(slopeMarker) && slopeMarker.length > 0) {
            slopeMarker.forEach((item) => {
              // 새로운 마커 정보 => 이전 마커 정보와 동일하게
              const target = response.find((item2) => item2.eqId === item.eqId);
              if (target) {
                // 마커 (default: 마커 출력O)
                if (item.marker.getMap() === null) {
                  target.marker.setMap(null);
                }
                // 오버레이 (default: 오버레이 출력X)
                if (item.overlay.getMap() !== null) {
                  target.overlay.setMap(map);
                }
                // 클릭오버레이 (default: 클릭오버레이 출력X)
                if (item.clickOverlay.getMap() !== null) {
                  target.clickOverlay.setMap(map);
                }
              }

              // 이전 마커 정보 setMap(null)
              item.marker.setMap(null);
              item.overlay.setMap(null);
              item.clickOverlay.setMap(null);
            });
          }
          setSlopeMarker(response);
        });
      }, 60000);

      return () => clearTimeout(timer);
    }
  }, [map, slopeMarker]);
  useEffect(() => {
    // 변위계
    if (map !== null) {
      const timer = setTimeout(() => {
        getDisMarkers(
          map,
          markerMouseOver,
          markerMouseOut,
          markerClick,
          onClickMarker
        ).then((response) => {
          if (Array.isArray(disMarker) && disMarker.length > 0) {
            disMarker.forEach((item) => {
              // 새로운 마커 정보 => 이전 마커 정보와 동일하게
              const target = response.find((item2) => item2.eqId === item.eqId);
              if (target) {
                // 마커 (default: 마커 출력O)
                if (item.marker.getMap() === null) {
                  target.marker.setMap(null);
                }
                // 오버레이 (default: 오버레이 출력X)
                if (item.overlay.getMap() !== null) {
                  target.overlay.setMap(map);
                }
                // 클릭오버레이 (default: 클릭오버레이 출력X)
                if (item.clickOverlay.getMap() !== null) {
                  target.clickOverlay.setMap(map);
                }
              }

              // 이전 마커 정보 setMap(null)
              item.marker.setMap(null);
              item.overlay.setMap(null);
              item.clickOverlay.setMap(null);
            });
          }
          setDisMarker(response);
        });
      }, 60000);

      return () => clearTimeout(timer);
    }
  }, [map, disMarker]);
  useEffect(() => {
    // 전광판
    if (map !== null) {
      const timer = setTimeout(() => {
        getEdbMarkers(
          map,
          markerMouseOver,
          markerMouseOut,
          markerClick,
          onClickMarker
        ).then((response) => {
          if (Array.isArray(edbMarker) && edbMarker.length > 0) {
            edbMarker.forEach((item) => {
              // 새로운 마커 정보 => 이전 마커 정보와 동일하게
              const target = response.find((item2) => item2.eqId === item.eqId);
              if (target) {
                // 마커 (default: 마커 출력O)
                if (item.marker.getMap() === null) {
                  target.marker.setMap(null);
                }
                // 오버레이 (default: 오버레이 출력X)
                if (item.overlay.getMap() !== null) {
                  target.overlay.setMap(map);
                }
                // 클릭오버레이 (default: 클릭오버레이 출력X)
                if (item.clickOverlay.getMap() !== null) {
                  target.clickOverlay.setMap(map);
                }
              }

              // 이전 마커 정보 setMap(null)
              item.marker.setMap(null);
              item.overlay.setMap(null);
              item.clickOverlay.setMap(null);
            });
          }
          setEdbMarker(response);
        });
      }, 60000);

      return () => clearTimeout(timer);
    }
  }, [map, edbMarker]);
  useEffect(() => {
    // cctv
    if (map !== null) {
      const timer = setTimeout(() => {
        getCCTVMarkers(
          map,
          markerMouseOver,
          markerMouseOut,
          markerClick,
          onClickMarker
        ).then((response) => {
          if (Array.isArray(cctvMarker) && cctvMarker.length > 0) {
            cctvMarker.forEach((item) => {
              // 새로운 마커 정보 => 이전 마커 정보와 동일하게
              const target = response.find((item2) => item2.eqId === item.eqId);
              if (target) {
                // 마커 (default: 마커 출력O)
                if (item.marker.getMap() === null) {
                  target.marker.setMap(null);
                }
                // 오버레이 (default: 오버레이 출력X)
                if (item.overlay.getMap() !== null) {
                  target.overlay.setMap(map);
                }
                // 클릭오버레이 (default: 클릭오버레이 출력X)
                if (item.clickOverlay.getMap() !== null) {
                  target.clickOverlay.setMap(map);
                }
              }

              // 이전 마커 정보 setMap(null)
              item.marker.setMap(null);
              item.overlay.setMap(null);
              item.clickOverlay.setMap(null);
            });
          }
          setCctvMarker(response);
        });
      }, 60000);

      return () => clearTimeout(timer);
    }
  }, [map, cctvMarker]);

  useEffect(() => {
    /* 장비/센서에 대한 마커&오버레이의 활성/비활성화 */
    // 강우계
    markerOverlayControl("rain", rainMarker);
    // 수위계
    markerOverlayControl("level", levelMarker);
    // 변위계
    markerOverlayControl("displacement", disMarker);
    // 경사계
    markerOverlayControl("slope", slopeMarker);
    // 전광판
    markerOverlayControl("edboard", edbMarker);
    // CCTV
    markerOverlayControl("cctv", cctvMarker);
  }, [dvcPrint]);
  useEffect(() => {
    // 전광판 메시지 전송 이후
    if (isChange) {
      setIsChange(false);
      getEdbMarkers(
        map,
        markerMouseOver,
        markerMouseOut,
        markerClick,
        onClickMarker
      ).then((response) => {
        if (Array.isArray(edbMarker) && edbMarker.length > 0) {
          edbMarker.forEach((item) => {
            // 새로운 마커 정보 => 이전 마커 정보와 동일하게
            const target = response.find((item2) => item2.eqId === item.eqId);
            if (target) {
              // 마커 (default: 마커 출력O)
              if (item.marker.getMap() === null) {
                target.marker.setMap(null);
              }
              // 오버레이 (default: 오버레이 출력X)
              if (item.overlay.getMap() !== null) {
                target.overlay.setMap(map);
              }
              // 클릭오버레이 (default: 클릭오버레이 출력X)
              if (item.clickOverlay.getMap() !== null) {
                target.clickOverlay.setMap(map);
              }
            }

            // 이전 마커 정보 setMap(null)
            item.marker.setMap(null);
            item.overlay.setMap(null);
            item.clickOverlay.setMap(null);
          });
        }
        setEdbMarker(response);
      });
    }
  }, [isChange]);
  /* 로드뷰 버튼 true/false */
  useEffect(() => {
    if (map !== null) {
      if (isRoadView) {
        // 활성화
        map.addOverlayMapTypeId(kakao.maps.MapTypeId.ROADVIEW); // 거리

        const content = document.createElement("div"); // 오버레이
        const figure = document.createElement("div");
        const angleBack = document.createElement("div");
        content.className = "map-walker";
        figure.className = "figure";
        angleBack.className = "angle-back";
        content.appendChild(angleBack);
        content.appendChild(figure);

        const overlay = new kakao.maps.CustomOverlay({
          position: map.getCenter(),
          content: content,
          yAnchor: 1,
        });
        setRoadOverlay(overlay);
        content.addEventListener("mousedown", (e) => onMouseDown(e, overlay));
        overlay.setMap(map);

        const mapCenter = map.getCenter(); // 로드뷰 이미지
        createRoadViewImg(mapCenter, overlay);
      } else {
        // 비활성화
        map.removeOverlayMapTypeId(kakao.maps.MapTypeId.ROADVIEW); // 로드뷰 거리
        roadOverlay.setMap(null); // 로드뷰 마커
        setRoadOverlay(null);
        setIsRoadViewImg(false); // 로드뷰 이미지
      }
    }
  }, [isRoadView]);
  useEffect(() => {
    if (map !== null && roadOverlay !== null) {
      const roadPosition = roadOverlay.getPosition();
      map.setCenter(roadPosition);
    }
  }, [isRoadViewImg]);
  useEffect(() => {
    isRoadViewRef.current = isRoadView;
  }, [isRoadView]);
  useEffect(() => {
    roadOverlayRef.current = roadOverlay;
  }, [roadOverlay]);

  return (
    <div id="map-page">
      <div
        id="map-kakao-map"
        ref={mapContainerRef}
        style={{ width: isRoadViewImg ? "50%" : "100%" }}
      />
      {isToken && (
        <>
          {/* 로드뷰 버튼 */}
          <div
            className={
              isRoadView ? "road-button road-button-select" : "road-button"
            }
            onClick={() => setIsRoadView(!isRoadView)}
          />

          {/* 컨트롤 */}
          <MapControlBar mapLevel={mapLevel} setMapLevel={setMapLevel} />

          {/* 위성/일반 */}
          <MapType mapType={mapType} setMapType={setMapType} />

          {/* 장비표시 */}
          <MapDvcPrint
            dvcPrint={dvcPrint}
            setDvcPrint={setDvcPrint}
            setDvcPrintBfr={setDvcPrintBfr}
          />

          {/* 범례 */}
          <MapLegend />

          {
            // 선택된 마커 정보
            Object.keys(selected).length > 0 && (
              <MarkerInform
                selected={selected}
                setSelected={setSelected}
                setIsChange={setIsChange}
              />
            )
          }
        </>
      )}

      {
        // 로드뷰
        isRoadView && (
          <div
            id="map-road"
            style={{ display: isRoadViewImg ? "block" : "none" }}
          >
            <div id="map-kakao-road" />
            <div
              className="close-button"
              onClick={() => setIsRoadViewImg(false)}
            >
              <img />
            </div>
          </div>
        )
      }
    </div>
  );
}

export default Map;
