import React, { useState, useEffect, useRef } from "react";
import {
  ResponsiveContainer,
  AreaChart,
  Area,
  Legend,
  XAxis,
  YAxis,
  Tooltip,
  ReferenceLine,
  CartesianGrid,
} from "recharts";

import reservoirImg from "../../../assets/reservoir.png";

function GroundLevelGraph({ searchType, selected }) {
  const [data, setData] = useState([]);
  const [showData, setShowData] = useState([]);
  const [minMax, setMinMax] = useState({ min: 0, max: 0 });
  const [showMinMax, setShowMinMax] = useState({ min: 0, max: 0 });
  const [alertLine, setAlertLine] = useState([]);
  const chartRef = useRef(null);

  /* Tooltip Custom */
  const TooltipCustom = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      const date =
        payload[0].payload.date === undefined ? "" : payload[0].payload.date;
      return (
        <div className="tooltip-container">
          <div className="date-text">{date}</div>
          <div className="value-container">
            {payload.map((item) => (
              <div key={`value-item-${item.dataKey}`} className="value-item">
                <div
                  className="value-color"
                  style={{ background: item.color }}
                />
                <div className="value-title">{`${
                  item.dataKey === "avgVal1" ? "내수" : "외수"
                } 지하수위(EL.m):`}</div>
                <div className="value">{item.payload[item.dataKey]}</div>
              </div>
            ))}
          </div>
        </div>
      );
    }
  };

  useEffect(() => {
    /* 데이터가공 & 최대값 찾기 */
    if (Array.isArray(selected) && selected.length > 0) {
      if (selected[0].data.length > 0 || selected[1].data.length > 0) {
        const selectedInData = selected[0].data;
        const selectedOutData = selected[1].data;
        let mergedData;
        if (selectedInData.length > 0) {
          mergedData = selectedInData.map((inItem) => {
            const outItem = selectedOutData.find(
              (outItem) => outItem.date === inItem.date
            );
            if (outItem !== null && outItem !== undefined) {
              return {
                date: inItem.date,
                avgVal1: inItem.avgVal,
                avgVal2: outItem.avgVal,
              };
            } else {
              return {
                date: inItem.date,
                avgVal1: inItem.avgVal,
                avgVal2: "-",
              };
            }
          });
        } else {
          mergedData = selectedOutData.map((outItem) => {
            const inItem = selectedInData.find(
              (inItem) => outItem.date === inItem.date
            );
            if (inItem !== null && inItem !== undefined) {
              return {
                date: outItem.date,
                avgVal1: inItem.avgVal,
                avgVal2: outItem.avgVal,
              };
            } else {
              return {
                date: outItem.date,
                avgVal1: "-",
                avgVal2: outItem.avgVal,
              };
            }
          });
        }

        let dataMax = 0;
        const nullData = mergedData.map((dataItem) => {
          const tmpItem = { ...dataItem };
          for (let key in dataItem) {
            if (key !== "date") {
              if (dataItem[key] === "-") {
                tmpItem[key] = null; // "-" → null
              } else {
                const val = parseFloat(dataItem[key]); // 최대값 찾기
                dataMax = val > dataMax ? val : dataMax;
              }
            }
          }
          return tmpItem;
        });
        setData(nullData);
        setShowData(nullData);
        setMinMax({ min: 135, max: 235 });
        setShowMinMax({ min: 135, max: 235 });
      } else {
        setData([]);
        setShowData([]);
        setMinMax({ min: 135, max: 235 });
        setShowMinMax({ min: 135, max: 235 });
      }
    } else {
      setData([]);
      setShowData([]);
      setMinMax({ min: 135, max: 235 });
      setShowMinMax({ min: 135, max: 235 });
    }
  }, [selected]);

  return (
    <div id="data-graph-component">
      {Array.isArray(data) && data.length > 0 && (
        <div className="reservoir-graph-container">
          {/* 저수지 이미지 */}
          <div className="reservoir-img">
            <div className="img">
              {/* 내수 측 */}
              <div className="ground-in-graph">
                <ResponsiveContainer width="100%" height="100%">
                  <AreaChart
                    data={[...data.slice(0, 1), ...data.slice(0, 1)]}
                    margin={{ left: 0, right: 0, bottom: -10 }}
                  >
                    <CartesianGrid
                      stroke="#595959"
                      strokeWidth={0.5}
                      vertical={false}
                    />
                    <XAxis
                      dataKey="date"
                      tickFormatter={(value) => ``}
                      tick={{ fontSize: 11, fill: "#9B9B9B" }}
                      axisLine={false}
                    />
                    <YAxis
                      type="number"
                      domain={[135, 235]}
                      tickFormatter={(value) => ``}
                      tick={{ fontSize: 11, fill: "#9B9B9B" }}
                      tickCount={10}
                      axisLine={false}
                      allowDataOverflow={true}
                    />
                    <Area
                      type="monotone"
                      dataKey="avgVal1"
                      fill="#425FD1"
                      stroke="#425FD1"
                      isAnimationActive={false}
                    />
                  </AreaChart>
                </ResponsiveContainer>
              </div>
              {/* 외수 측 */}
              <div className="ground-out-graph">
                <ResponsiveContainer width="100%" height="100%">
                  <AreaChart
                    data={[...data.slice(0, 1), ...data.slice(0, 1)]}
                    margin={{ left: 0, right: 0, bottom: -10 }}
                  >
                    <CartesianGrid
                      stroke="#595959"
                      strokeWidth={0.5}
                      vertical={false}
                    />
                    <XAxis
                      dataKey="date"
                      tickFormatter={(value) => ``}
                      tick={{ fontSize: 11, fill: "#9B9B9B" }}
                      axisLine={false}
                    />
                    <YAxis
                      type="number"
                      domain={[135, 235]}
                      tickFormatter={(value) => ``}
                      tick={{ fontSize: 11, fill: "#9B9B9B" }}
                      tickCount={10}
                      axisLine={false}
                      allowDataOverflow={true}
                    />
                    <Area
                      type="monotone"
                      dataKey="avgVal2"
                      fill="#D142BC"
                      stroke="#D142BC"
                      isAnimationActive={false}
                    />
                  </AreaChart>
                </ResponsiveContainer>
              </div>
              <div className="extension-graph-container">
                {/* 저수지 그래프 확장 */}
                <ResponsiveContainer width="100%" height="100%">
                  <AreaChart
                    data={[...data.slice(0, 1), ...data.slice(0, 1)]}
                    margin={{ left: 0, right: 0, bottom: -10 }}
                  >
                    <CartesianGrid
                      stroke="#595959"
                      strokeWidth={0.5}
                      vertical={false}
                    />
                    <XAxis
                      dataKey="date"
                      tickFormatter={(value) => ``}
                      tick={{ fontSize: 11, fill: "#9B9B9B" }}
                      axisLine={false}
                    />
                    <YAxis
                      type="number"
                      domain={[135, 235]}
                      tickFormatter={(value) => ``}
                      tick={{ fontSize: 11, fill: "#9B9B9B" }}
                      tickCount={10}
                      axisLine={false}
                      allowDataOverflow={true}
                    />
                    <defs>
                      <linearGradient id="colorUv1" x1="0" y1="0" x2="0" y2="1">
                        <stop
                          offset="5%"
                          stopColor="#425FD173"
                          stopOpacity={1}
                        />
                        <stop
                          offset="95%"
                          stopColor="#425FD173"
                          stopOpacity={0.3}
                        />
                      </linearGradient>
                    </defs>
                    <defs>
                      <linearGradient id="colorUv2" x1="0" y1="0" x2="0" y2="1">
                        <stop
                          offset="5%"
                          stopColor="#D142BC73"
                          stopOpacity={1}
                        />
                        <stop
                          offset="95%"
                          stopColor="#D142BC73"
                          stopOpacity={0.3}
                        />
                      </linearGradient>
                    </defs>
                    <Area
                      type="monotone"
                      dataKey="avgVal1"
                      fill="url(#colorUv1)"
                      stroke="#425FD1"
                      isAnimationActive={false}
                    />
                    <Area
                      type="monotone"
                      dataKey="avgVal2"
                      fill="url(#colorUv2)"
                      stroke="#D142BC"
                      isAnimationActive={false}
                    />
                  </AreaChart>
                </ResponsiveContainer>
              </div>
              <img src={reservoirImg} alt="" />
            </div>
          </div>

          {/* 그래프 */}
          <div className="reservoir-graph">
            <div className="graph">
              <ResponsiveContainer width="100%" height="100%">
                <AreaChart
                  ref={chartRef}
                  data={showData}
                  margin={{ left: 0, right: 0, bottom: -10 }}
                >
                  <CartesianGrid
                    stroke="#595959"
                    strokeWidth={0.5}
                    vertical={false}
                  />
                  <XAxis
                    dataKey="date"
                    tick={{ fontSize: 11, fill: "#9B9B9B" }}
                    axisLine={false}
                  />
                  <YAxis
                    type="number"
                    domain={[135, 235]}
                    tickFormatter={(value) => `EL.${value}m`}
                    tick={{ fontSize: 11, fill: "#9B9B9B" }}
                    tickCount={10}
                    axisLine={false}
                    allowDataOverflow={true}
                    orientation="right"
                  />
                  <Tooltip
                    content={<TooltipCustom />}
                    filterNull={true}
                    cursor={{
                      stroke: "#757575",
                      strokeWidth: 1,
                      strokeDasharray: "3 3",
                    }}
                  />
                  <defs>
                    <linearGradient id="colorUv1" x1="0" y1="0" x2="0" y2="1">
                      <stop offset="5%" stopColor="#425FD173" stopOpacity={1} />
                      <stop
                        offset="95%"
                        stopColor="#425FD173"
                        stopOpacity={0.3}
                      />
                    </linearGradient>
                  </defs>
                  <defs>
                    <linearGradient id="colorUv2" x1="0" y1="0" x2="0" y2="1">
                      <stop offset="5%" stopColor="#D142BC73" stopOpacity={1} />
                      <stop
                        offset="95%"
                        stopColor="#D142BC73"
                        stopOpacity={0.3}
                      />
                    </linearGradient>
                  </defs>
                  <Area
                    type="monotone"
                    dataKey="avgVal1"
                    fill="url(#colorUv1)"
                    stroke="#425FD1"
                    isAnimationActive={false}
                  />
                  <Area
                    type="monotone"
                    dataKey="avgVal2"
                    fill="url(#colorUv2)"
                    stroke="#D142BC"
                    isAnimationActive={false}
                  />
                </AreaChart>
              </ResponsiveContainer>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default GroundLevelGraph;
