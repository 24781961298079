import React, { useState, useEffect } from "react";
import {
  ResponsiveContainer,
  AreaChart,
  Area,
  Legend,
  XAxis,
  YAxis,
  Tooltip,
  ReferenceLine,
  CartesianGrid,
} from "recharts";

function GroundGraph({ selected }) {
  const [data, setData] = useState([]);
  const [max, setMax] = useState(0);

  /* AlertLine Custom */
  const AlertCustomLabelUp = (props) => {
    const x = props.viewBox.x;
    const y = props.viewBox.y;
    const value = props.value;
    const color = props.color;
    return (
      <text
        x={x + 5}
        y={y - 5}
        textAnchor="start"
        fill={color}
        fontSize={12}
        fontWeight="bold"
      >
        {value}
      </text>
    );
  };
  const AlertCustomLabelDown = (props) => {
    const x = props.viewBox.x;
    const y = props.viewBox.y;
    const value = props.value;
    const color = props.color;
    return (
      <text
        x={x + 5}
        y={y + 12}
        textAnchor="start"
        fill={color}
        fontSize={12}
        fontWeight="bold"
      >
        {value}
      </text>
    );
  };
  /* Tooltip Custom */
  const TooltipCustom = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      const date =
        payload[0].payload.date === undefined ? "" : payload[0].payload.date;
      return (
        <div className="tooltip-container">
          <div className="date-text">{date}</div>
          <div className="value-container">
            {payload.map((item) => (
              <div key={`value-item-${item.dataKey}`} className="value-item">
                <div
                  className="value-color"
                  style={{ background: item.color }}
                />
                <div className="value-title">수위(EL.m):</div>
                <div className="value">{item.payload.avgVal}</div>
              </div>
            ))}
          </div>
        </div>
      );
    }
  };

  useEffect(() => {
    /* 데이터가공 & 최대값 찾기 */
    if (Array.isArray(selected.data) && selected.data.length > 0) {
      const selectedData = selected.data;

      let dataMax = 0;
      const nullData = selectedData.map((dataItem) => {
        const tmpItem = { ...dataItem };
        for (let key in dataItem) {
          if (key !== "date") {
            if (dataItem[key] === "-") {
              tmpItem[key] = null; // "-" → null
            } else {
              const val = parseFloat(dataItem[key]); // 최대값 찾기
              dataMax = val > dataMax ? val : dataMax;
            }
          }
        }
        return tmpItem;
      });

      setData(nullData);
      setMax(Math.round(dataMax + 10));
    } else {
      setData([]);
      setMax(0);
    }
  }, [selected]);

  return (
    <>
      {data.length > 0 && (
        <ResponsiveContainer width="100%" height="90%">
          <AreaChart data={data} margin={{ left: -20, right: 20, bottom: -10 }}>
            <CartesianGrid
              stroke="#595959"
              strokeWidth={0.5}
              vertical={false}
            />
            <XAxis dataKey="date" tick={{ fontSize: 11 }} axisLine={false} />
            <YAxis
              type="number"
              domain={[135, 235]}
              tick={{ fontSize: 11 }}
              tickCount={10}
              axisLine={false}
            />
            <Tooltip
              content={<TooltipCustom />}
              filterNull={true}
              cursor={{
                stroke: "#757575",
                strokeWidth: 1,
                strokeDasharray: "3 3",
              }}
            />

            <defs>
              <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#425FD173" stopOpacity={1} />
                <stop offset="95%" stopColor="#425FD173" stopOpacity={0.3} />
              </linearGradient>
            </defs>
            <Area
              type="monotone"
              dataKey="avgVal"
              fill="url(#colorUv)"
              stroke="#425FD1"
              isAnimationActive={false}
            />
          </AreaChart>
        </ResponsiveContainer>
      )}
    </>
  );
}

export default GroundGraph;
