import { useState, useEffect } from "react";
import Modal from "react-modal";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faX } from "@fortawesome/free-solid-svg-icons";

import { getSiteID, getTargetMenu } from "../../services/internal/signAPI";
import { MODALSTYLES } from "../../constants/modalStyles";
import logoImg from "../../assets/common/logoImg.png";
import Personal from "../../components/sign/Personal";
import MenuAuth from "../../components/sign/MenuAuth";
import TermsCondition from "../../components/sign/TermsCondition";

Modal.setAppElement("#root");

function Sign({ isOpen, setIsOpen, setIsSignAlert }) {
  const [page, setPage] = useState(1);
  const [isAgree, setIsAgree] = useState(false); // 이용약관
  const [siteId, setSiteId] = useState([]); // 개인정보
  const [isDuplicate, setIsDuplicate] = useState(false); // 아이디
  const [duplicateText, setDuplicateText] = useState("");
  const [isPwdSame, setIsPwdSame] = useState(false); // 비밀번호
  const [pwdSameUser, setPwdSameUser] = useState("");
  const [pwdSameText, setPwdSameText] = useState("");
  const [isAuthCell, setIsAuthCell] = useState(false); // 휴대폰
  const [cellAuthUser, setCellAuthUser] = useState("");
  const [authCellText, setAuthCellText] = useState("");
  const [signInform, setSignInform] = useState({
    siteId: "SIT001",
    userId: "",
    userPwd: "",
    userNm: "",
    cellNum: "",
    phoneNum: "",
    email: "",
    adtnl1: "",
    deptNm: "",
    positionNm: "",
  });
  const [targetMenu, setTargetMenu] = useState([]); // 메뉴권한
  const [menuAuth, setMenuAuth] = useState([]);

  function onClickClose() {
    // 창 닫기
    setIsOpen(false);
  }

  useEffect(() => {
    getSiteID().then((siteIds) => {
      setSiteId(siteIds);
    });
  }, []);
  useEffect(() => {
    getTargetMenu(signInform.siteId).then((menu) => {
      setTargetMenu(menu);
      setMenuAuth([]);
    });
  }, [signInform.siteId]);

  return (
    <Modal isOpen={isOpen} style={MODALSTYLES.sign}>
      <div id="sign-modal">
        <div className="sign-header">
          <div className="title-container">
            <img src={logoImg} alt="" />
            <div className="title">계정 등록 신청</div>
          </div>
          <FontAwesomeIcon
            className="close-button"
            onClick={onClickClose}
            icon={faX}
          />
        </div>

        <div className="sign-main">
          {page === 1 ? (
            <TermsCondition
              setPage={setPage}
              isAgree={isAgree}
              setIsAgree={setIsAgree}
            />
          ) : page === 2 ? (
            <Personal
              setPage={setPage}
              siteId={siteId}
              isDuplicate={isDuplicate}
              setIsDuplicate={setIsDuplicate}
              duplicateText={duplicateText}
              setDuplicateText={setDuplicateText}
              isPwdSame={isPwdSame}
              setIsPwdSame={setIsPwdSame}
              pwdSameUser={pwdSameUser}
              setPwdSameUser={setPwdSameUser}
              pwdSameText={pwdSameText}
              setPwdSameText={setPwdSameText}
              isAuthCell={isAuthCell}
              setIsAuthCell={setIsAuthCell}
              cellAuthUser={cellAuthUser}
              setCellAuthUser={setCellAuthUser}
              authCellText={authCellText}
              setAuthCellText={setAuthCellText}
              signInform={signInform}
              setSignInform={setSignInform}
            />
          ) : (
            page === 3 && (
              <MenuAuth
                setIsOpen={setIsOpen}
                setIsSignAlert={setIsSignAlert}
                setPage={setPage}
                targetMenu={targetMenu}
                menuAuth={menuAuth}
                setMenuAuth={setMenuAuth}
                signInform={signInform}
              />
            )
          )}
        </div>
      </div>
    </Modal>
  );
}

export default Sign;
