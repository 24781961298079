import { useState, useEffect } from "react";
import Modal from "react-modal";

import { dateToFormat } from "../../../utils/dateFormat";
import { MODALSTYLES } from "../../../constants/modalStyles";
import { DEVICEOPTIONS } from "../../../constants/deviceOptions";
import {
  getSensorType,
  getBroadType,
} from "../../../services/internal/deviceAPI";

Modal.setAppElement("#root");

function DeviceInform({ isOpen, setIsOpen }) {
  const [inform, setInform] = useState({});
  const [snsrType, setSnsrType] = useState([]);
  const [brdType, setBrdType] = useState([]);

  function onClickClose() {
    // 닫기 버튼 클릭시
    setIsOpen({ isOpen: false, data: {} });
  }

  useEffect(() => {
    getSensorType().then((sensorType) => {
      getBroadType().then((broadType) => {
        setSnsrType(sensorType);
        setBrdType(broadType);
      });
    });
  }, []);
  useEffect(() => {
    setInform(isOpen.data);
  }, [isOpen.data]);

  return (
    <Modal isOpen={isOpen.isOpen} style={MODALSTYLES.device}>
      <div className="modal_title">
        <div>장비 정보</div>
      </div>

      <div className="modal_container">
        <div className="modal_main_column">
          <div className="device-inform-modal">
            <div className="inform-container">
              <div className="title">장비명</div>
              <div className="inform inform-one">{inform.dvcNm}</div>
            </div>
            <div className="inform-container">
              <div className="title">장비종류</div>
              <div className="inform inform-two">
                {
                  DEVICEOPTIONS.type.find(
                    (item) => item.value === inform.dvcType
                  )?.text
                }
              </div>
              {inform.dvcType === "SNSR" ? (
                <>
                  <div className="inner-title">센서타입</div>
                  <div className="inform inform-two">
                    {snsrType.length > 0 &&
                      snsrType.find(
                        (item) => item.commonCode === inform.dvcDtlType
                      )?.commonCodeName}
                  </div>
                </>
              ) : (
                inform.dvcType === "BRD" && (
                  <>
                    <div className="inner-title">방송장비타입</div>
                    <div className="inform inform-two">
                      {brdType.length > 0 &&
                        brdType.find(
                          (item) => item.commonCode === inform.dvcDtlType
                        )?.commonCodeName}
                    </div>
                  </>
                )
              )}
            </div>
            <div className="inform-container">
              <div className="title">설치일자</div>
              <div className="inform inform-two">
                {dateToFormat(inform.instlDt)}
              </div>
              <div className="inner-title">제조업체</div>
              <div className="inform inform-two">
                {inform.mnfct === "" ? "-" : inform.mnfct}
              </div>
            </div>
            <div className="inform-container">
              <div className="title">모델명</div>
              <div className="inform inform-one">
                {inform.modelNm === "" ? "-" : inform.modelNm}
              </div>
            </div>
            {inform.dvcType === "SNSR" ? (
              <>
                <div className="inform-container">
                  <div className="title title-caution">관심 최소</div>
                  <div className="inform inform-two">
                    {inform.min === DEVICEOPTIONS.sensorAnomaly
                      ? "-"
                      : inform.min}
                  </div>
                  <div className="inner-title title-caution">관심 최대</div>
                  <div className="inform inform-two">
                    {inform.max === DEVICEOPTIONS.sensorAnomaly
                      ? "-"
                      : inform.max}
                  </div>
                </div>
                <div className="inform-container">
                  <div className="title title-attention">주의 최소</div>
                  <div className="inform inform-two">
                    {inform.min2 === DEVICEOPTIONS.sensorAnomaly
                      ? "-"
                      : inform.min2}
                  </div>
                  <div className="inner-title title-attention">주의 최대</div>
                  <div className="inform inform-two">
                    {inform.max2 === DEVICEOPTIONS.sensorAnomaly
                      ? "-"
                      : inform.max2}
                  </div>
                </div>
                <div className="inform-container">
                  <div className="title title-warning">경계 최소</div>
                  <div className="inform inform-two">
                    {inform.min3 === DEVICEOPTIONS.sensorAnomaly
                      ? "-"
                      : inform.min3}
                  </div>
                  <div className="inner-title title-warning">경계 최대</div>
                  <div className="inform inform-two">
                    {inform.max3 === DEVICEOPTIONS.sensorAnomaly
                      ? "-"
                      : inform.max3}
                  </div>
                </div>
                <div className="inform-container">
                  <div className="title title-critical">심각 최소</div>
                  <div className="inform inform-two">
                    {inform.min4 === DEVICEOPTIONS.sensorAnomaly
                      ? "-"
                      : inform.min4}
                  </div>
                  <div className="inner-title title-critical">심각 최대</div>
                  <div className="inform inform-two">
                    {inform.max4 === DEVICEOPTIONS.sensorAnomaly
                      ? "-"
                      : inform.max4}
                  </div>
                </div>
              </>
            ) : (
              inform.dvcType === "BRD" && (
                <>
                  <div className="inform-container">
                    <div className="title">방송전묵음(초)</div>
                    <div className="inform inform-two">{inform.slncBfr}</div>
                    <div className="inner-title">방송후묵음(초)</div>
                    <div className="inform inform-two">{inform.slncAft}</div>
                  </div>
                </>
              )
            )}
            <div className="last-inform-container">
              <div className="title">메모</div>
              <textarea
                value={inform.dvcDtl === "" ? "-" : inform.dvcDtl}
                disabled={true}
              />
            </div>
          </div>
        </div>

        <div className="modal_footer">
          <div className="button-nor-modal" onClick={onClickClose}>
            닫기
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default DeviceInform;
