import Modal from "react-modal";

import { MODALSTYLES } from "../../../constants/modalStyles";

Modal.setAppElement("#root");

function Edit({ isOpen, setIsOpen, editData }) {
  function onClickCancel() {
    setIsOpen(false);
  }
  function onClickEdit() {
    setIsOpen(false);
    editData();
  }

  return (
    <Modal isOpen={isOpen} style={MODALSTYLES.edit}>
      <div className="modal_window_container">
        <div className="text">
          <div>입력하신 정보로 수정하시겠습니까?</div>
        </div>
        <div className="button">
          <div className="button_nor" onClick={onClickCancel}>
            취소
          </div>
          <div
            className="button_imp"
            onClick={onClickEdit}
            style={{ marginLeft: "20px" }}
          >
            확인
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default Edit;
