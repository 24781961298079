import { useState, useEffect } from "react";
import JSMpeg from "@cycjimmy/jsmpeg-player";

import { getCCTVVideo, quitCCTVVideo } from "../../services/internal/cctvAPI";

function CCTVScreen({ selectCctv, setCctvErrText, result, setResult }) {
  const [player, setPlayer] = useState(null);
  const [port, setPort] = useState(null);

  function fetchCCTV(cctvInf) {
    /* CCTV 영상 조회 */
    getCCTVVideo(cctvInf).then((response) => {
      if (response.result === "success") {
        // 연결 성공
        setResult(true);
        setCctvErrText("");

        const port = response.port;
        setPort(port);
        const canvas = document.getElementById("cctv-canvas");
        const protocol = window.location.protocol;
        let jsmpeg;
        if (protocol === "https:") {
          jsmpeg = new JSMpeg.Player("wss://121.140.107.240:" + port, {
            canvas: canvas,
          });
        } else {
          jsmpeg = new JSMpeg.Player("ws://121.140.107.240:" + port, {
            canvas: canvas,
          });
        }
        setPlayer(jsmpeg);
      } else {
        // 연결 실패
        setResult(false);
        setCctvErrText("연결 실패");
        setPort(null);
      }
    });
  }

  useEffect(() => {
    return () => {
      if (port !== null) {
        if (player !== undefined && player !== null) {
          player.destroy();
          setPlayer(null);
        }
        quitCCTVVideo(port);
      }
    };
  }, [port]);
  useEffect(() => {
    if (Object.keys(selectCctv).length !== 0) {
      fetchCCTV(selectCctv);
    } else {
      setPort(null);
    }
  }, [selectCctv]);

  return (
    <>
      {Object.keys(selectCctv).length !== 0 && (
        <>
          <canvas id="cctv-canvas" />
          {!result && <div className="err-screen">연결에 실패하였습니다</div>}
        </>
      )}
    </>
  );
}

export default CCTVScreen;
