function MapDvcPrint({ dvcPrint, setDvcPrint, setDvcPrintBfr }) {
  function checkIncludeDvcPrint(dvcType) {
    // 장비 출력에 포함되어 있는지
    if (dvcPrint.find((item) => item === dvcType)) {
      return true;
    } else {
      return false;
    }
  }
  function onClickDvcPrint(dvcType) {
    // 장비 출력 활성/비활성
    setDvcPrintBfr(dvcPrint);
    if (checkIncludeDvcPrint(dvcType)) {
      const newDvcPrint = dvcPrint.filter((item) => item !== dvcType);
      setDvcPrint(newDvcPrint);
    } else {
      setDvcPrint([...dvcPrint, dvcType]);
    }
  }

  return (
    <div className="map-select map-select-dvc">
      <div
        className={
          checkIncludeDvcPrint("rain")
            ? "select-item select-item-left select-item-select"
            : "select-item select-item-left"
        }
        onClick={() => onClickDvcPrint("rain")}
      >
        강우
      </div>
      <div
        className={
          checkIncludeDvcPrint("level")
            ? "select-item select-item-select"
            : "select-item"
        }
        onClick={() => onClickDvcPrint("level")}
      >
        수위
      </div>
      <div
        className={
          checkIncludeDvcPrint("slope")
            ? "select-item select-item-select"
            : "select-item"
        }
        onClick={() => onClickDvcPrint("slope")}
      >
        경사
      </div>
      <div
        className={
          checkIncludeDvcPrint("displacement")
            ? "select-item select-item-select"
            : "select-item"
        }
        onClick={() => onClickDvcPrint("displacement")}
      >
        변위
      </div>
      <div
        className={
          checkIncludeDvcPrint("broadcast")
            ? "select-item select-item-select"
            : "select-item"
        }
        onClick={() => onClickDvcPrint("broadcast")}
      >
        방송
      </div>
      <div
        className={
          checkIncludeDvcPrint("edboard")
            ? "select-item select-item-select"
            : "select-item"
        }
        onClick={() => onClickDvcPrint("edboard")}
      >
        전광판
      </div>
      <div
        className={
          checkIncludeDvcPrint("cctv")
            ? "select-item select-item-right select-item-select"
            : "select-item select-item-right"
        }
        onClick={() => onClickDvcPrint("cctv")}
      >
        CCTV
      </div>
    </div>
  );
}

export default MapDvcPrint;
