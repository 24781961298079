import { useState, useEffect } from "react";
import {
  createColumnHelper,
  flexRender,
  useReactTable,
  getCoreRowModel,
  getSortedRowModel,
} from "@tanstack/react-table";

function SensorTable({ tableData, selected, setSelected }) {
  const [data, setData] = useState(tableData);
  const [columns, setColumns] = useState([]);
  const columnHelper = createColumnHelper();
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  function onClickRow(row) {
    // 행 클릭 시
    setSelected(row.original);
  }

  useEffect(() => {
    if (Array.isArray(tableData) && tableData.length > 0) {
      const column = [
        columnHelper.accessor("type", {
          id: "type",
          header: "센서종류",
          size: 40,
        }),
        columnHelper.accessor(
          (row) => {
            return `${row.cnt}개`;
          },
          { id: "cnt", header: "센서수량", size: 20 }
        ),
        columnHelper.accessor(
          (row) => {
            return `${row.alert}개`;
          },
          { id: "alert", header: "기준치초과", size: 20 }
        ),
      ];

      setData(tableData);
      setColumns(column);
    } else {
      setData([]);
      setColumns([]);
    }
  }, [tableData]);
  return (
    <div className="data-sensor-table-contents">
      {data.length > 0 && (
        <div className="list">
          <table className="react-table">
            <thead>
              {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <th
                      key={header.id}
                      onClick={header.column.getToggleSortingHandler()}
                      style={{
                        width: isNaN(header.getSize())
                          ? "auto"
                          : `${header.getSize()}px`,
                        minWidth: isNaN(header.getSize())
                          ? "auto"
                          : `${header.getSize()}px`,
                        maxWidth: isNaN(header.getSize())
                          ? "auto"
                          : `${header.getSize()}px`,
                      }}
                    >
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody>
              {table.getRowModel().rows.map((row, index) => (
                <tr
                  key={row.id}
                  className={
                    selected && selected.typeId === row.original.typeId
                      ? "tr-select"
                      : index % 2 === 0
                      ? "tr-pointer tr-even"
                      : "tr-pointer tr-odd"
                  }
                  onClick={() => onClickRow(row)}
                >
                  {row.getVisibleCells().map((cell) => (
                    <td
                      key={cell.id}
                      style={{
                        width: isNaN(cell.column.getSize())
                          ? "auto"
                          : `${cell.column.getSize()}px`,
                        minWidth: isNaN(cell.column.getSize())
                          ? "auto"
                          : `${cell.column.getSize()}px`,
                        maxWidth: isNaN(cell.column.getSize())
                          ? "auto"
                          : `${cell.column.getSize()}px`,
                        textAlign:
                          (cell.column.id === "cnt" ||
                            cell.column.id === "alert") &&
                          "center",
                      }}
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}

export default SensorTable;
