import { useState, useEffect } from "react";
import {
  flexRender,
  useReactTable,
  getCoreRowModel,
  createColumnHelper,
} from "@tanstack/react-table";

import { getDataTableColumn } from "../../../utils/data";
import MinGroundTable from "./MinGroundLevelTable";

function GroundLevelTable({ term, levelData, selected, setSelected }) {
  const [data, setData] = useState(levelData);
  const [columns, setColumns] = useState([]);
  const columnHelper = createColumnHelper();
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });
  const [mouseData, setMouseData] = useState({});

  function onClickRow(row) {
    // 행 클릭 시
    if (row.original.eqId % 7 === 6) {
      const outTarget = levelData.find(
        (item) => item.eqId === row.original.eqId + 1
      );
      setSelected([row.original, outTarget]);
    } else {
      const inTarget = levelData.find(
        (item) => item.eqId === row.original.eqId - 1
      );
      setSelected([inTarget, row.original]);
    }
  }
  function onMouseOverRow(row) {
    // 행에 마우스 가져다 댔을 시
    setMouseData(row.original);
  }
  function onMouseOutRow() {
    // 행에서 마우스를 뗏을 시
    setMouseData({});
  }

  useEffect(() => {
    if (levelData.find((levelItem) => levelItem.data.length !== 0)) {
      if (term !== "min") {
        const column = [
          columnHelper.accessor("eqNm", { header: "센서명", size: 200 }),
          ...getDataTableColumn(term),
        ];
        setColumns(column);
      }
    }
    setData(levelData);
  }, [levelData]);

  return (
    <div id="data-table-component">
      {term === "min" ? (
        // 분별 조회
        <MinGroundTable
          levelData={levelData}
          selected={selected}
          setSelected={setSelected}
        />
      ) : (
        // 월별/일별/시간 조회
        <div className="list">
          <table className="react-data-table">
            <thead>
              {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <th
                      key={header.id}
                      style={{
                        width: isNaN(header.getSize())
                          ? "auto"
                          : `${header.getSize()}px`,
                      }}
                    >
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody>
              {table.getRowModel().rows.map((row, index) => (
                <tr
                  key={row.id}
                  className={
                    selected[0]?.eqId === row.original.eqId ||
                    selected[1]?.eqId === row.original.eqId
                      ? "tr-select"
                      : mouseData.eqId - 1 === row.original.eqId ||
                        mouseData.eqId + 1 === row.original.eqId
                      ? "tr-select tr-pointer"
                      : "tr-pointer"
                  }
                  onClick={() => onClickRow(row)}
                  onMouseOver={() => onMouseOverRow(row)}
                  onMouseOut={onMouseOutRow}
                >
                  {row.getVisibleCells().map((cell) => (
                    <td
                      key={cell.id}
                      style={{
                        width: isNaN(cell.column.getSize())
                          ? "auto"
                          : `${cell.column.getSize()}px`,
                      }}
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}

export default GroundLevelTable;
