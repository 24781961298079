import Modal from "react-modal";

import { MODALSTYLES } from "../../../constants/modalStyles";

Modal.setAppElement("#root");

function Confirm({ isOpen, setIsOpen, active }) {
  function onClickCancel() {
    // 취소 버튼 클릭 시.
    setIsOpen({
      isOpen: false,
      text: "",
    });
  }
  function onClickConfirm() {
    // 확인 버튼 클릭 시.
    setIsOpen({
      isOpen: false,
      text: "",
    });
    active();
  }

  return (
    <Modal isOpen={isOpen.isOpen} style={MODALSTYLES.confirm}>
      <div className="modal_window_container">
        <div className="text">
          <div>{isOpen.text}</div>
        </div>
        <div className="button">
          <div className="button_nor" onClick={onClickCancel}>
            닫기
          </div>
          <div
            className="button_imp"
            onClick={onClickConfirm}
            style={{ marginLeft: "20px" }}
          >
            확인
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default Confirm;
