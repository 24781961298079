import { useState, useEffect } from "react";
import Modal from "react-modal";
import axios from "axios";

import apiAddress from "../../../../services/apiAddress";
import Alert from "../../../common/window/Alert";
import Reg from "../../../common/window/Reg";
import Sensor from "../alert-setting/Sensor";
import Broadcast from "../alert-setting/Broadcast";

Modal.setAppElement("#root");

function RtuDeviceReg({ isOpen, setIsOpen, isOk, setIsOk }) {
  const [isAlert, setIsAlert] = useState(false);
  const [isReg, setIsReg] = useState({
    isOpen: false,
    text: "",
  });
  const [regData, setRegData] = useState([]);
  const [snsrData, setSnsrData] = useState([]);
  const [brdData, setBrdData] = useState([]);

  function onClickClose() {
    // 닫기.
    setIsOpen({
      isOpen: false,
    });
  }
  function onClickReg() {
    // 등록.
    if (
      regData.find(
        (item) => item.hasOwnProperty("isSetting") && item.isSetting === false
      )
    ) {
      // 값 설정되지 않은 센서 존재.
      setIsAlert(true);
    } else {
      // 값 설정되지 않은 센서 존재X.
      setIsReg({
        isOpen: true,
        text: regData.length + "개의 장비를 등록하시겠습니까?",
      });
    }
  }

  /* RTU - DVC 조회 API */
  async function fetchData() {
    try {
      axios
        .post(
          apiAddress.deviceGet,
          {
            eqId: isOpen.eqId,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          const allDvc = response.data.filter((item) => item.useYn === "Y");

          // 이미 등록되어 있는 DVC 조회.
          axios
            .post(
              apiAddress.groupEquipDeviceGet,
              {
                groupId: isOpen.groupId,
                eqId: isOpen.eqId,
              },
              {
                headers: {
                  "Content-Type": "application/json",
                },
              }
            )
            .then((response) => {
              const regDvc = response.data;
              const dvc = allDvc.filter(
                (item) => !regDvc.some((item2) => item2.dvcId === item.dvcId)
              );

              const snsr = dvc.filter((item) => item.dvcType === "SNSR");
              const brd = dvc.filter((item) => item.dvcType === "BRD");
              setSnsrData(snsr);
              setBrdData(brd);
            });
        });
    } catch (e) {
      console.log(e);
    }
  }
  /* 경보그룹 - 장비 저장 API */
  async function fetchDataReg() {
    try {
      // 경보그룹 - dvc 저장.
      axios
        .post(apiAddress.groupEquipDeviceSave, regData, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          setIsOk(!isOk);
          setIsOpen({ isOpen: false });
        });
    } catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Modal isOpen={isOpen.isOpen} style={modalStyles}>
      {isAlert && (
        <Alert
          isOpen={isAlert}
          setIsOpen={setIsAlert}
          text="값을 설정하지 않은 센서가 존재합니다"
        />
      )}
      {isReg.isOpen && (
        <Reg isOpen={isReg} setIsOpen={setIsReg} regData={fetchDataReg} />
      )}
      <div className="modal_title">
        <div>장비 추가 등록</div>
        <div style={{ fontSize: "0.9rem", fontWeight: "normal" }}>
          {isOpen.eqNm}
        </div>
      </div>
      <div className="modal_container">
        {/* Main */}
        <div className="modal_main_row">
          {/* 센서 */}
          <div className="setting_alert_setting_snsr_container">
            <Sensor
              groupId={isOpen.groupId}
              eqId={isOpen.eqId}
              snsrData={snsrData}
              regData={regData}
              setRegData={setRegData}
            />
          </div>

          {/* 방송장비 */}
          <div className="setting_alert_setting_brd_container">
            <Broadcast
              groupId={isOpen.groupId}
              eqId={isOpen.eqId}
              brdData={brdData}
              regData={regData}
              setRegData={setRegData}
            />
          </div>
        </div>

        {/* Footer */}
        <div className="modal_footer">
          <div className="button_nor" onClick={onClickClose}>
            닫기
          </div>
          {regData.length > 0 && (
            <div
              className="button_imp"
              onClick={onClickReg}
              style={{ marginLeft: "10px" }}
            >
              등록
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
}

const modalStyles = {
  content: {
    width: "990px",
    height: "720px",
    padding: "0px",
    background: "#202020",
    border: "none",
    borderRadius: "5px",
    boxShadow: "-5px 5px 10px rgba(0, 0, 0, 0.7)",
    margin: "auto",
  },
  overlay: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "fixed",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: "rgba(0, 0, 0, 0.4)",
  },
};

export default RtuDeviceReg;
