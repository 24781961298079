/* npm i bcrypt */
import { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";

import { getMenu, login } from "../../services/internal/loginAPI";
import Alert from "../../components/common/window/Alert";
import SuccessAlert from "../../components/common/window/SuccessAlert";
import Sign from "./Sign";
import FindId from "../../components/login/FindId";
import FindIdInform from "../../components/login/FindIdInform";
import FindPwd from "../../components/login/FindPwd";
import backgroundImg from "../../assets/login/loginBackground.jpg";
import logoImg from "../../assets/common/logoImg.png";
import logoImg3 from "../../assets/common/logoImg3.png";

function Login() {
  const [idAlert, setIdAlert] = useState(false);
  const [pwdAlert, setPwdAlert] = useState(false);
  const [loginAlert, setLoginAlert] = useState(false);
  const [isSign, setIsSign] = useState(false);
  const [isSignAlert, setIsSignAlert] = useState(false);
  const [data, setData] = useState({
    id: "",
    pwd: "",
  });
  const idRef = useRef(null);
  const pwdRef = useRef(null);
  const [errorText, setErrorText] = useState("");
  const movePage = useNavigate();
  const [isPwd, setIsPwd] = useState({
    isOpen: false,
    data: { cellNum: "", userId: "" },
  }); // 비밀번호 찾기
  const [isFindPwd, setIsFindPwd] = useState(false);
  const [isId, setIsId] = useState(false); // 아이디 찾기
  const [isFindId, setIsFindId] = useState(false);
  const [findIdList, setFindIdList] = useState([]);

  function onClickPwd() {
    // 비밀번호 찾기 클릭 시
    setIsPwd({ isOpen: true, data: { cellNum: "", userId: "" } });
  }
  function onClickId() {
    // 아이디 찾기 클릭 시
    setIsId(true);
  }
  function onKeypPressLogin(e) {
    if (e.key === "Enter") {
      onClickLogin();
    }
  }
  function onClickSign() {
    // 계정등록 클릭 시.
    setIsSign(true);
  }
  function onClickLogin() {
    // 로그인 버튼 클릭 시.
    if (data.id === "" || data.pwd === "") {
      if (data.id === "") {
        setIdAlert(true);
        idRef.current.focus();
      } else {
        setPwdAlert(true);
        pwdRef.current.focus();
      }
    } else {
      fetchLogin(data);
    }
  }

  /* 로그인 */
  function fetchLogin(loginInform) {
    login(loginInform.id, loginInform.pwd).then((response) => {
      if (response.status !== "OK") {
        // 로그인 실패
        if (response.status === "ACCOUNT_SUSPENDED") {
          // 잠금
          setErrorText(response.message + "비밀번호를 초기화 해주세요.");
        } else {
          setErrorText(response.message);
        }
      } else {
        // 로그인 성공
        localStorage.setItem("tokenInform", JSON.stringify(response.data));
        getMenu(JSON.stringify(response.data)).then((menuData) => {
          if (Array.isArray(menuData.data) && menuData.data.length !== 0) {
            menuData.data.sort((a, b) => a.menuId - b.menuId);
            const menuUrl = menuData.data[0].linkUrl;
            movePage(menuUrl);
          }
        });
      }
    });
  }

  return (
    <div className="login_all_container">
      {idAlert && (
        <Alert
          isOpen={idAlert}
          setIsOpen={setIdAlert}
          text="아이디를 입력해주세요"
        />
      )}
      {pwdAlert && (
        <Alert
          isOpen={pwdAlert}
          setIsOpen={setPwdAlert}
          text="비밀번호를 입력해주세요"
        />
      )}
      {loginAlert && (
        <Alert
          isOpen={loginAlert}
          setIsOpen={setLoginAlert}
          text="아이디 및 비밀번호를 다시 확인해주세요"
        />
      )}
      {
        // 계정 등록 신청
        isSign && (
          <Sign
            isOpen={isSign}
            setIsOpen={setIsSign}
            setIsSignAlert={setIsSignAlert}
          />
        )
      }
      {
        // 계정 등록 신청 성공
        isSignAlert && (
          <SuccessAlert
            isOpen={isSignAlert}
            setIsOpen={setIsSignAlert}
            text="계정등록 신청을 완료하였습니다"
          />
        )
      }
      {
        // 아이디 찾기
        isId && (
          <FindId
            isOpen={isId}
            setIsOpen={setIsId}
            setIsFindId={setIsFindId}
            setFindIdList={setFindIdList}
          />
        )
      }
      {
        // 아이디 찾기 - 목록
        isFindId && (
          <FindIdInform
            isOpen={isFindId}
            setIsOpen={setIsFindId}
            idList={findIdList}
            data={data}
            setData={setData}
            setIsPwd={setIsPwd}
          />
        )
      }
      {
        // 비밀번호 찾기
        isPwd.isOpen && (
          <FindPwd
            isOpen={isPwd}
            setIsOpen={setIsPwd}
            setIsFindPwd={setIsFindPwd}
          />
        )
      }
      {
        // 비밀번호 찾기 - 임시 비밀번호 발급 성공
        isFindPwd && (
          <SuccessAlert
            isOpen={isFindPwd}
            setIsOpen={setIsFindPwd}
            text="임시 비밀번호가 발급되었습니다"
          />
        )
      }
      <img src={backgroundImg} alt="" />
      <div className="login_container">
        <div className="contents">
          <div className="header">
            <div className="subLogo">
              <img src={logoImg3} alt="" />
            </div>
            <div className="logo">
              <img src={logoImg} alt="" />
            </div>
            {/*<img className="" src={logoImg} alt="" />
            <img className="" src={logoImg3} alt="" />*/}
          </div>
          <div className="main">
            <form>
              <input
                ref={idRef}
                type="text"
                placeholder="ID"
                value={data.id}
                onChange={(e) => setData({ ...data, id: e.target.value })}
                autoComplete="on"
                onKeyPress={onKeypPressLogin}
              />
              <input
                ref={pwdRef}
                type="password"
                placeholder="Password"
                value={data.pwd}
                onChange={(e) => setData({ ...data, pwd: e.target.value })}
                onKeyPress={onKeypPressLogin}
                autoComplete="on"
                style={{ marginTop: "10px" }}
              />
            </form>
            <div className="login_text">{errorText}</div>
          </div>
          <div className="footer">
            <div className="login_button" onClick={onClickLogin}>
              로그인
            </div>
            <div className="login_dtl">
              <div className="click" onClick={onClickPwd}>
                비밀번호 찾기
              </div>
              <div className="divide">|</div>
              <div className="click" onClick={onClickId}>
                아이디 찾기
              </div>
              <div className="divide">|</div>
              <div className="click" onClick={onClickSign}>
                계정등록 신청
              </div>
            </div>
          </div>
        </div>
        <div className="copyright">
          <div>Copyright2023. Ahjoo Engineering Inc. All rights reserved.</div>
        </div>
      </div>
    </div>
  );
}

export default Login;
