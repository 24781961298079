import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setMenu, setSubmenu } from "../../../redux/modules/menu";
import {
  createColumnHelper,
  flexRender,
  useReactTable,
  getCoreRowModel,
  getSortedRowModel,
} from "@tanstack/react-table";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import locale from "../../../data/datepicker";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome, faList } from "@fortawesome/free-solid-svg-icons";

import { DATEPICKERSTYLES } from "../../../constants/datepickerStyles";
import { tokenCheck } from "../../../utils/tokenCheck";
import { sampleData } from "../../../constants/data/sampleData";

const PAGE_VALUE = "경보방송";
const SUBPAGE_VALUE = "방송내역";

function BroadcastList() {
  const dispatch = useDispatch();
  const [isToken, setIsToken] = useState(false);
  const movePage = useNavigate();
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);
  const columnHelper = createColumnHelper();
  const [searchDate, setSearchDate] = useState(new Date());

  function onClickRow() {}
  function onDoubleClickRow() {}
  function onClickSearch() {
    // 검색 버튼 클릭
    // 토큰 확인
    tokenCheck().then((result) => {
      if (!result) {
        alert("인증 토큰이 만료되었습니다. 다시 로그인 해주세요");
        movePage("/");
      } else {
      }
    });
  }
  function onClickExcel() {
    // Excel 버튼 클릭
    // 토큰 확인
    tokenCheck().then((result) => {
      if (!result) {
        alert("인증 토큰이 만료되었습니다. 다시 로그인 해주세요");
        movePage("/");
      } else {
      }
    });
  }

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });
  async function fetchData() {
    const sample = sampleData.broadHistory;
    const column = [
      columnHelper.accessor("dvcNm", {
        header: "방송그룹",
        size: 90,
      }),
      columnHelper.accessor("title", {
        header: "방송제목",
        size: 80,
      }),
      columnHelper.accessor("sendDt", {
        header: "방송시작",
        size: 60,
      }),
      columnHelper.accessor("sendDt", {
        header: "방송종료",
        size: 60,
      }),
      columnHelper.accessor("msg", {
        header: "내용",
        size: 150,
      }),
      columnHelper.accessor(
        () => {
          return "SYSTEM";
        },
        {
          id: "brdUsr",
          header: "사용자",
          size: 50,
        }
      ),
      columnHelper.accessor("status", {
        header: "처리상태",
        size: 30,
      }),
    ];

    setData(sample);
    setColumns(column);
  }

  useEffect(() => {
    dispatch(setMenu(PAGE_VALUE));
    dispatch(setSubmenu(SUBPAGE_VALUE));

    // 토큰 확인
    tokenCheck().then((result) => {
      if (!result) {
        alert("인증 토큰이 만료되었습니다. 다시 로그인 해주세요");
        movePage("/");
      } else {
        setIsToken(true);
      }
    });

    fetchData();
  }, []);

  return (
    <div className="outlet_container">
      {isToken && (
        <>
          <div className="header_container">
            <div className="title">
              <div className="title_imp">방송내역</div>
            </div>
            <div className="path">
              <FontAwesomeIcon icon={faHome} />
              <div className="text">&gt; 경보방송 &gt; 방송내역</div>
            </div>
          </div>

          {/* Main */}
          <div className="main_container">
            <div className="contents_container">
              <div className="contents_header">
                <div className="title">
                  <FontAwesomeIcon icon={faList} />
                  <div className="text">방송내역</div>
                </div>

                <div className="search">
                  <DatePicker
                    selected={searchDate}
                    onChange={(date) => setSearchDate(date)}
                    dateFormat={"yyyy-MM-dd"}
                    maxDate={new Date()}
                    locale={locale.ko}
                    customInput={<DATEPICKERSTYLES.data />}
                  />

                  <input
                    placeholder="방송제목, 방송내용 검색"
                    style={{
                      marginLeft: "10px",
                    }}
                    spellCheck={false}
                  />
                  <div
                    className="button_imp"
                    onClick={onClickSearch}
                    style={{ marginLeft: "15px" }}
                  >
                    검색
                  </div>
                  <div
                    className="button-excel"
                    onClick={onClickExcel}
                    style={{ marginLeft: "10px" }}
                  >
                    Excel저장
                  </div>
                </div>
              </div>

              <div className="contents_main">
                <table className="react_table">
                  <thead>
                    {table.getHeaderGroups().map((headerGroup) => (
                      <tr key={headerGroup.id}>
                        {headerGroup.headers.map((header) => (
                          <th
                            key={header.id}
                            onClick={header.column.getToggleSortingHandler()}
                            style={{
                              width: isNaN(header.getSize())
                                ? "auto"
                                : `${header.getSize()}px`,
                              minWidth: isNaN(header.getSize())
                                ? "auto"
                                : `${header.getSize()}px`,
                              maxWidth: isNaN(header.getSize())
                                ? "auto"
                                : `${header.getSize()}px`,
                            }}
                          >
                            {header.isPlaceholder
                              ? null
                              : flexRender(
                                  header.column.columnDef.header,
                                  header.getContext()
                                )}
                          </th>
                        ))}
                      </tr>
                    ))}
                  </thead>
                  <tbody>
                    {table.getRowModel().rows.map((row, index) => (
                      <tr
                        key={row.id}
                        onClick={() => onClickRow(row.original)}
                        onDoubleClick={() => onDoubleClickRow(row.original)}
                      >
                        {row.getVisibleCells().map((cell) => (
                          <td
                            key={cell.id}
                            style={{
                              width: isNaN(cell.column.getSize())
                                ? "auto"
                                : `${cell.column.getSize()}px`,
                              minWidth: isNaN(cell.column.getSize())
                                ? "auto"
                                : `${cell.column.getSize()}px`,
                              maxWidth: isNaN(cell.column.getSize())
                                ? "auto"
                                : `${cell.column.getSize()}px`,
                              textAlign:
                                cell.column.id === "rgnNm" ||
                                cell.column.id === "brdTitle" ||
                                cell.column.id === "brdCntn"
                                  ? ""
                                  : "center",
                            }}
                          >
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext()
                            )}
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>

              {/* Page */}
              <div className="contents_footer">
                {/* <div className="button_page">&lt;&lt;</div>
                <div className="button_page" style={{ marginLeft: "5px" }}>
                  &lt;
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    margin: "0px 10px",
                  }}
                >
                  <div
                    style={{
                      margin: "0px 7px",
                      color: "#6180D8",
                      fontWeight: "bold",
                    }}
                  >
                    1
                  </div>
                  <div style={{ margin: "0px 7px" }}>2</div>
                  <div style={{ margin: "0px 7px" }}>3</div>
                  <div style={{ margin: "0px 7px" }}>4</div>
                  <div style={{ margin: "0px 7px" }}>5</div>
                  <div style={{ margin: "0px 7px" }}>6</div>
                  <div style={{ margin: "0px 7px" }}>7</div>
                  <div style={{ margin: "0px 7px" }}>8</div>
                  <div style={{ margin: "0px 7px" }}>9</div>
                </div>
                <div className="button_page" style={{ marginLeft: "5px" }}>
                  &gt;
                </div>
                <div className="button_page" style={{ marginLeft: "5px" }}>
                  &gt;&gt;
                </div> */}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default BroadcastList;
