import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setMenu } from "../../../redux/modules/menu";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faBars,
  faSun,
  faTemperatureLow,
} from "@fortawesome/free-solid-svg-icons";

import { tokenCheck } from "../../../utils/tokenCheck";
import { reservoirs } from "../../../constants/latlon";
import CurrFore from "../../../components/allstatus/CurrFore";
import WeatherAlert from "../../../components/allstatus/WeatherAlert";
import SatelliteRadar from "../../../components/allstatus/SatelliteRadar";
import AllMap from "../../../components/allstatus/AllMap";
import Map from "../../../components/allstatus/Map";
import ConnState from "../../../components/allstatus/ConnState";
// import Data from "../../../components/allstatus/Data";
import DataReservoir from "../../../components/allstatus/DataReservior";
import BroadHistory from "../../../components/allstatus/BroadHistory";
import EdboardCurr from "../../../components/allstatus/EdboardCurr";

const PAGE_VALUE = "종합현황";

function AllStatus() {
  const dispatch = useDispatch();
  const movePage = useNavigate();
  const [isToken, setIsToken] = useState(false);
  const [selected, setSelected] = useState({});
  const reservoirMenuRef = useRef(null);
  const weatherMenuRef = useRef(null);
  const menuDetailRef = useRef(null);
  const [isReservoirShow, setIsReservoirShow] = useState(false);
  const [isWeatherShow, setIsWeatherShow] = useState(false);

  function onClickBack() {
    // 뒤로가기 아이콘 클릭 시
    setSelected({});
  }
  function onClickReservoir() {
    // 저수지 아이콘 클릭 시
    setIsReservoirShow(true);
    setIsWeatherShow(false);
  }
  function onClickReservoirItem(item) {
    // 저수지 아이템 클릭 시
    setSelected(item);
    setIsReservoirShow(false);
  }
  function onClickWeather() {
    // 날씨 아이콘 클릭 시
    setIsWeatherShow(true);
    setIsReservoirShow(false);
  }
  useEffect(() => {
    dispatch(setMenu(PAGE_VALUE));

    // 메뉴 이외의 곳을 클릭 시, 메뉴 정보 접음
    const handleClickOutside = (event) => {
      // ref 영역 외부 클릭 감지
      if (
        menuDetailRef.current &&
        !menuDetailRef.current.contains(event.target)
      ) {
        if (
          reservoirMenuRef.current &&
          !reservoirMenuRef.current.contains(event.target)
        ) {
          setIsReservoirShow(false);
        }
        if (
          weatherMenuRef.current &&
          !weatherMenuRef.current.contains(event.target)
        ) {
          setIsWeatherShow(false);
        }
      }
    };
    document.addEventListener("mousedown", handleClickOutside);

    // 토큰 확인
    tokenCheck().then((result) => {
      if (!result) {
        alert("인증 토큰이 만료되었습니다. 다시 로그인 해주세요");
        movePage("/");
      } else {
        setIsToken(true);
      }
    });

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div id="allstatus-page">
      {isToken && (
        <>
          <div className="allstatus-menu-container">
            <div className="allstatus-menu-item-back" onClick={onClickBack}>
              <FontAwesomeIcon icon={faArrowLeft} />
            </div>
            <div
              ref={reservoirMenuRef}
              className={`allstatus-menu-item ${
                isWeatherShow ? "allstatus-menu-item-select" : ""
              }`}
              onClick={onClickReservoir}
            >
              <FontAwesomeIcon icon={faBars} />
            </div>
            {Object.keys(selected).length > 0 && (
              <div
                ref={weatherMenuRef}
                className={`allstatus-menu-item ${
                  isReservoirShow ? "allstatus-menu-item-select" : ""
                }`}
                onClick={onClickWeather}
              >
                <FontAwesomeIcon icon={faSun} />
              </div>
            )}
          </div>
          <div
            ref={menuDetailRef}
            className={`allstatus-menu-detail-container ${
              isReservoirShow || isWeatherShow
                ? "allstatus-menu-detail-container-expanded"
                : ""
            }`}
          >
            {
              // 저수지 목록
              isReservoirShow && (
                <div className="allstatus-reservoir-container">
                  {reservoirs.map((item) => (
                    <div
                      className={`item ${
                        selected.id === item.id ? "item-select" : ""
                      }`}
                      key={item.id}
                      onClick={() => onClickReservoirItem(item)}
                    >
                      {item.name}
                    </div>
                  ))}
                </div>
              )
            }
            {
              // 날씨
              isWeatherShow && (
                <div className="allstatus-weather-container">
                  {/* 현재날씨 & 기상예보 */}
                  <div className="curr-fore-container">
                    <div className="allstatus-title">날씨 & 예보</div>
                    <div className="allstatus-contents">
                      <CurrFore />
                    </div>
                  </div>
                  {/* 기상특보 */}
                  <div className="alert-container">
                    <div className="allstatus-title">기상 특보</div>
                    <div className="allstatus-contents">
                      <WeatherAlert />
                    </div>
                  </div>
                  {/* 위성 레이더 */}
                  <div className="sate-radar-container">
                    <div className="allstatus-title">위성 & 레이더</div>
                    <div className="allstatus-contents">
                      <SatelliteRadar />
                    </div>
                  </div>
                </div>
              )
            }
          </div>
          <div
            className={`not-weather-container ${
              isReservoirShow || isWeatherShow
                ? "not-weather-container-collapsed"
                : ""
            }`}
          >
            <div className="map-broad-container">
              {/* 지도 */}
              <div className="map-container">
                {Object.keys(selected).length > 0 ? (
                  <Map selected={selected} />
                ) : (
                  <>
                    <AllMap selected={selected} setSelected={setSelected} />
                  </>
                )}
              </div>
              {/* 방송내역 */}
              <div className="broad-container">
                <div className="allstatus-title">방송내역</div>
                <div className="allstatus-contents">
                  <BroadHistory selected={selected} />
                </div>
              </div>
            </div>
            <div className="conn-data-container">
              {/* 통신상태 */}
              <div className="conn-container">
                <div className="allstatus-title">센서 통신 상태</div>
                <div className="allstatus-contents">
                  <ConnState selected={selected} />
                </div>
              </div>
              {/* 데이터현황 */}
              <div className="data-container">
                <div className="allstatus-title">데이터 현황</div>
                <div className="allstatus-contents">
                  {Object.keys(selected).length > 0 && (
                    <DataReservoir selected={selected} />
                    // <Data selected={selected} />
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default AllStatus;
