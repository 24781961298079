import React, { useState, useEffect, useRef } from "react";
import {
  ResponsiveContainer,
  AreaChart,
  Area,
  Legend,
  XAxis,
  YAxis,
  Tooltip,
  ReferenceLine,
  CartesianGrid,
} from "recharts";

import { DEVICEOPTIONS } from "../../../constants/deviceOptions";
import reservoirImg from "../../../assets/reservoir.png";

function ReservoirGraph({ searchType, selected }) {
  const [data, setData] = useState([]);
  const [showData, setShowData] = useState([]);
  const [minMax, setMinMax] = useState({ min: 0, max: 0 });
  const [showMinMax, setShowMinMax] = useState({ min: 0, max: 0 });
  const [alertLine, setAlertLine] = useState([]);
  const chartRef = useRef(null);

  /* AlertLine Custom */
  const AlertCustomLabelUp = (props) => {
    const x = props.viewBox.x;
    const y = props.viewBox.y;
    const value = props.value;
    const color = props.color;
    return (
      <text
        x={x + 5}
        y={y - 5}
        textAnchor="start"
        fill={color}
        fontSize={12}
        fontWeight="bold"
      >
        {value}
      </text>
    );
  };
  const AlertCustomLabelDown = (props) => {
    const x = props.viewBox.x;
    const y = props.viewBox.y;
    const value = props.value;
    const color = props.color;
    return (
      <text
        x={x + 5}
        y={y + 12}
        textAnchor="start"
        fill={color}
        fontSize={12}
        fontWeight="bold"
      >
        {value}
      </text>
    );
  };
  /* Legend Custom */
  const CustomLegend = (props) => {
    const { payload } = props;
    return (
      <ul className="legend-container">
        {payload.map((entry, index) => {
          return (
            <li key={`item-${index}`} className="legend-item">
              <div
                className="item-color"
                style={{ background: "#425FD173", border: "1px solid #425FD1" }}
              />
              <div className="item-value">수위</div>
            </li>
          );
        })}
      </ul>
    );
  };
  /* Tooltip Custom */
  const TooltipCustom = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      const date =
        payload[0].payload.date === undefined ? "" : payload[0].payload.date;
      return (
        <div className="tooltip-container">
          <div className="date-text">{date}</div>
          <div className="value-container">
            {payload.map((item) => (
              <div key={`value-item-${item.dataKey}`} className="value-item">
                <div
                  className="value-color"
                  style={{ background: item.color }}
                />
                <div className="value-title">수위:</div>
                <div className="value">{item.payload.avgVal}</div>
              </div>
            ))}
          </div>
        </div>
      );
    }
  };

  /* String => Date */
  function stringToDateYearMonth(dateString) {
    return new Date(dateString);
  }
  function stringToDateDay(dateString) {
    const date = dateString.replace(" ", "T") + ":00";
    return date;
  }
  function stringToDateMin(dateString) {
    const date = dateString.replace(" ", "T");
    return date;
  }
  /* Date 비교 */
  function compareDate(from, to) {
    let fromX = from;
    let toX = to;
    switch (searchType) {
      case "year":
      case "month":
        fromX = stringToDateYearMonth(fromX);
        toX = stringToDateYearMonth(toX);
        break;
      case "day":
        fromX = stringToDateDay(fromX);
        toX = stringToDateDay(toX);
        break;
      default:
        fromX = stringToDateMin(fromX);
        toX = stringToDateMin(toX);
        break;
    }
    if (fromX > toX) {
      return false;
    }
    return true;
  }
  /* chartY => YAxis에 맞게 값 변환 */
  function chartToYAxis(value) {
    const val = Number(value);
    const chartRect = chartRef.current.container.getBoundingClientRect();
    const chartMin = 50;
    const chartMax = Number(chartRect.height) - 25;
    const yAxisMin = Number(showMinMax.min);
    const yAxisMax = Number(showMinMax.max);
    // 정규화
    const normalize = (chartMax - val) / (chartMax - chartMin);
    // 스케일링
    const tempValue = yAxisMin + normalize * (yAxisMax - yAxisMin);
    return parseFloat(tempValue).toFixed(2);
  }

  useEffect(() => {
    /* 데이터가공 & 최대값 찾기 */
    if (Array.isArray(selected.data) && selected.data.length > 0) {
      const selectedData = selected.data;

      let dataMax = 0;
      const nullData = selectedData.map((dataItem) => {
        const tmpItem = { ...dataItem };
        for (let key in dataItem) {
          if (key !== "date") {
            if (dataItem[key] === "-") {
              tmpItem[key] = null; // "-" → null
            } else {
              const val = parseFloat(dataItem[key]); // 최대값 찾기
              dataMax = val > dataMax ? val : dataMax;
            }
          }
        }
        return tmpItem;
      });
      setData(nullData);
      setShowData(nullData);
      setMinMax({ min: 0, max: Math.round(dataMax + 10) });
      setShowMinMax({ min: 0, max: Math.round(dataMax + 10) });
    } else {
      setData([]);
      setShowData([]);
      setMinMax({ min: 0, max: 0 });
      setShowMinMax({ min: 0, max: 0 });
    }

    /* 임계치 */
    if (Array.isArray(selected.dvcData) && selected.dvcData.length > 0) {
      const dvcData = selected.dvcData;

      const alert = dvcData.map((dvcItem) => {
        const alertItem = {
          unit: "m",
          min: null,
          min2: null,
          min3: null,
          min4: null,
          max: null,
          max2: null,
          max3: null,
          max4: null,
        };

        for (let key in alertItem) {
          if (key !== "unit") {
            const val =
              dvcItem[key].toString() === DEVICEOPTIONS.sensorAnomaly.toString()
                ? null
                : Number(dvcItem[key]);
            alertItem[key] = val;
          }
        }

        return alertItem;
      });

      setAlertLine(alert);
    }
  }, [selected]);

  return (
    <div id="data-graph-component">
      {data.length > 0 && (
        <div className="reservoir-graph-container">
          {/* 저수지 이미지 */}
          <div className="reservoir-img">
            <div className="img">
              <img src={reservoirImg} alt="" />
            </div>
          </div>

          {/* 그래프 */}
          <div className="reservoir-graph">
            <div className="graph">
              <ResponsiveContainer width="100%" height="100%">
                <AreaChart
                  ref={chartRef}
                  data={showData}
                  margin={{ left: -30, right: 0, bottom: -10 }}
                >
                  <CartesianGrid
                    stroke="#595959"
                    strokeWidth={0.5}
                    vertical={false}
                  />
                  {/*<Legend
                    verticalAlign="top"
                    height={40}
                    wrapperStyle={{ paddingBottom: "10px" }}
                    content={<CustomLegend />}
                  />*/}
                  <XAxis
                    dataKey="date"
                    tick={{ fontSize: 11, fill: "#9B9B9B" }}
                    axisLine={false}
                  />
                  <YAxis
                    type="number"
                    // domain={[showMinMax.min, showMinMax.max]}
                    domain={[0, 100]}
                    tick={{ fontSize: 11, fill: "#9B9B9B" }}
                    tickCount={10}
                    axisLine={false}
                    allowDataOverflow={true}
                    orientation="right"
                  />
                  <Tooltip
                    content={<TooltipCustom />}
                    filterNull={true}
                    cursor={{
                      stroke: "#757575",
                      strokeWidth: 1,
                      strokeDasharray: "3 3",
                    }}
                  />

                  <defs>
                    <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                      <stop offset="5%" stopColor="#425FD173" stopOpacity={1} />
                      <stop
                        offset="95%"
                        stopColor="#425FD173"
                        stopOpacity={0.3}
                      />
                    </linearGradient>
                  </defs>
                  <Area
                    type="monotone"
                    dataKey="avgVal"
                    fill="url(#colorUv)"
                    stroke="#425FD1"
                    isAnimationActive={false}
                  />

                  {alertLine.length > 0 &&
                    alertLine.map((alertItem, index) => (
                      <React.Fragment key={`${index}-alert`}>
                        {/* 최소 */}
                        <ReferenceLine
                          y={alertItem.min}
                          label={
                            <AlertCustomLabelDown
                              value={`관심(${alertItem.min}) ${alertItem.unit}`}
                              color="#0F80B1"
                            />
                          }
                          stroke="#006B99"
                          strokeDasharray="7 4"
                          strokeWidth={1}
                        />
                        <ReferenceLine
                          y={alertItem.min2}
                          label={
                            <AlertCustomLabelDown
                              value={`주의(${alertItem.min2}) ${alertItem.unit}`}
                              color="#A49506"
                            />
                          }
                          stroke="#928400"
                          strokeDasharray="7 4"
                          strokeWidth={1}
                        />
                        <ReferenceLine
                          y={alertItem.min3}
                          label={
                            <AlertCustomLabelDown
                              value={`경계(${alertItem.min3}) ${alertItem.unit}`}
                              color="#B9640A"
                            />
                          }
                          stroke="#A15300"
                          strokeDasharray="7 4"
                          strokeWidth={1}
                        />
                        <ReferenceLine
                          y={alertItem.min4}
                          label={
                            <AlertCustomLabelDown
                              value={`심각(${alertItem.min4}) ${alertItem.unit}`}
                              color="#A00B0B"
                            />
                          }
                          stroke="#8F0303"
                          strokeDasharray="7 4"
                          strokeWidth={1}
                        />
                        {/* 최대 */}
                        <ReferenceLine
                          y={alertItem.max}
                          label={
                            <AlertCustomLabelUp
                              value={`관심(${alertItem.max}) ${alertItem.unit}`}
                              color="#0F80B1"
                            />
                          }
                          stroke="#006B99"
                          strokeDasharray="7 4"
                          strokeWidth={1}
                        />
                        <ReferenceLine
                          y={alertItem.max2}
                          label={
                            <AlertCustomLabelUp
                              value={`주의(${alertItem.max2}) ${alertItem.unit}`}
                              color="#A49506"
                            />
                          }
                          stroke="#928400"
                          strokeDasharray="7 4"
                          strokeWidth={1}
                        />
                        <ReferenceLine
                          y={alertItem.max3}
                          label={
                            <AlertCustomLabelUp
                              value={`경계(${alertItem.max3}) ${alertItem.unit}`}
                              color="#B9640A"
                            />
                          }
                          stroke="#A15300"
                          strokeDasharray="7 4"
                          strokeWidth={1}
                        />
                        <ReferenceLine
                          y={alertItem.max4}
                          label={
                            <AlertCustomLabelUp
                              value={`심각(${alertItem.max4}) ${alertItem.unit}`}
                              color="#A00B0B"
                            />
                          }
                          stroke="#8F0303"
                          strokeDasharray="7 4"
                          strokeWidth={1}
                        />
                      </React.Fragment>
                    ))}
                </AreaChart>
              </ResponsiveContainer>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default ReservoirGraph;
