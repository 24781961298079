import apiAddress from "../../services/apiAddress";
import axios from "axios";

// 해당 Equip에 속하는 장비 얻기
export function getDevice(eqId) {
  return axios
    .post(
      apiAddress.deviceGet,
      {
        eqId: eqId,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      const device = response.data;
      if (device.length > 0) {
        const deviceY = device.filter((item) => item.useYn === "Y");
        const sortArray = deviceY;
        sortArray.sort((a, b) => {
          return a.eqId - b.eqId;
        });
        return sortArray;
      } else {
        return [];
      }
    });
}

export function getDeviceInform(dvcId) {
  return axios
    .post(
      apiAddress.deviceGet,
      {
        dvcId: dvcId,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      return response.data;
    });
}

/* 장비 저장 */
export function saveDevice(dvcInform) {
  return axios
    .post(apiAddress.deviceSave, dvcInform, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response.data;
    });
}

/* 센서 종류 */
export function getSensorType() {
  return axios
    .post(
      apiAddress.getCommonCode,
      {
        groupCode: "SNS",
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      return response.data;
    });
}

/* 방송장비 종류 */
export function getBroadType() {
  return axios
    .post(
      apiAddress.getCommonCode,
      {
        groupCode: "BRD",
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      return response.data;
    });
}
