import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { setMenu, setSubmenu } from "../../../redux/modules/menu";
import { useNavigate } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHome,
  faDesktop,
  faMessage,
} from "@fortawesome/free-solid-svg-icons";

import { tokenCheck } from "../../../utils/tokenCheck";
import { getEdboardMessage } from "../../../services/internal/edboardAPI";
import Loading from "../../../components/common/window/Loading";
import AddMessage from "../../../components/edboard/message/AddMessage";
import EdboardList from "../../../components/edboard/message/EdboardList";
import MessageList from "../../../components/edboard/message/MessageList";

const PAGE_VALUE = "전광판";
const SUBPAGE_VALUE = "메시지등록";

function EdboardMessage() {
  const dispatch = useDispatch();
  const [isToken, setIsToken] = useState(false);
  const movePage = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [edbData, setEdbData] = useState([]);
  const [selectedEdb, setSelectedEdb] = useState({});
  const [isAddMsg, setIsAddMsg] = useState(false);
  const [isChange, setIsChange] = useState(false);

  function onClickAddMessage() {
    // 메시지 등록 클릭 시
    setIsAddMsg(true);
  }

  useEffect(() => {
    dispatch(setMenu(PAGE_VALUE));
    dispatch(setSubmenu(SUBPAGE_VALUE));

    // 토큰 확인
    tokenCheck().then((result) => {
      if (!result) {
        alert("인증 토큰이 만료되었습니다. 다시 로그인 해주세요");
        movePage("/");
      } else {
        setIsToken(true);
        setIsLoading(true);
        getEdboardMessage().then((response) => {
          setIsLoading(false);
          setEdbData(response);
          if (response.length > 0) {
            setSelectedEdb(response[0]);
          } else {
            setSelectedEdb({});
          }
        });
      }
    });
  }, []);
  useEffect(() => {
    if (isChange) {
      setIsLoading(true);
      getEdboardMessage().then((response) => {
        setIsLoading(false);
        setEdbData(response);
        if (response.length > 0) {
          setSelectedEdb(response[0]);
        } else {
          setSelectedEdb({});
        }
      });

      setIsChange(false);
    }
  }, [isChange]);

  return (
    <div className="outlet_container">
      {
        // 로딩화면
        isLoading && <Loading isOpen={isLoading} />
      }
      {
        // 메시지 등록
        isAddMsg && (
          <AddMessage
            isOpen={isAddMsg}
            setIsOpen={setIsAddMsg}
            selected={selectedEdb}
            setIsChange={setIsChange}
          />
        )
      }
      {isToken && (
        <div id="edboard-message-page">
          <div className="header_container">
            <div className="title">
              <div className="title_imp">메시지 등록</div>
            </div>
            <div className="path">
              <FontAwesomeIcon icon={faHome} />
              <div className="text">&gt; 전광판 &gt; 메시지등록</div>
            </div>
          </div>

          <div className="main_container_row">
            {/* 전광판 리스트 */}
            <div className="edboard-list contents_container">
              <div className="contents_header">
                <div className="title">
                  <FontAwesomeIcon icon={faDesktop} />
                  <div className="text">전광판</div>
                </div>
              </div>
              <div className="contents_main">
                <EdboardList
                  edbData={edbData}
                  selected={selectedEdb}
                  setSelected={setSelectedEdb}
                />
              </div>
              <div className="contents_footer"></div>
            </div>

            {/* 메시지 리스트 */}
            <div className="message-list contents_container">
              <div className="contents_header">
                <div className="title">
                  <FontAwesomeIcon icon={faMessage} />
                  <div className="text">메시지</div>
                  {Object.keys(selectedEdb).length !== 0 && (
                    <div
                      className="button_imp msg-add-button"
                      onClick={onClickAddMessage}
                    >
                      메시지 등록
                    </div>
                  )}
                </div>
              </div>
              <div className="contents_main">
                {Object.keys(selectedEdb).length !== 0 && (
                  <MessageList
                    selected={selectedEdb}
                    setIsChange={setIsChange}
                  />
                )}
              </div>
              <div className="contents_footer"></div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default EdboardMessage;
