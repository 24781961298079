import { useState, useEffect } from "react";

import RainGraph from "./sensor/graph/RainGraph";
import LevelGraph from "./sensor/graph/LevelGraph";
import SlopeGraph from "./sensor/graph/SlopeGraph";
import DisGraph from "./sensor/graph/DisGraph";
import Table from "./sensor/Table";

function Sensor({ selected }) {
  const [dvcType, setDvcType] = useState("");

  useEffect(() => {
    if (Object.keys(selected).length > 0) {
      const checkTarget = selected.dvcData[0];
      setDvcType(checkTarget.dvcDtlType);
    }
  }, [selected]);

  return (
    <div className="sensor-marker-inform">
      {Array.isArray(selected.data) && selected.data.length > 0 ? (
        <>
          {/* 그래프 */}
          <div className="graph-container">
            {dvcType === "SNSR01" ? (
              <RainGraph selected={selected} />
            ) : dvcType === "SNSR02" ? (
              <LevelGraph selected={selected} />
            ) : dvcType === "SNSR08" ? (
              <SlopeGraph selected={selected} />
            ) : (
              dvcType === "SNSR15" && <DisGraph selected={selected} />
            )}
          </div>

          {/* 센서 */}
          <div className="table-container">
            <Table selected={selected} />
          </div>
        </>
      ) : (
        <div className="data-none-container">금일 데이터 내역이 없습니다</div>
      )}
    </div>
  );
}

export default Sensor;
