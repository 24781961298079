import apiAddress from "../../services/apiAddress";
import axios from "axios";

/* 사용자 조회 */
export function getUser(page) {
  return axios
    .get(apiAddress.getUser, {
      params: {
        page: page,
        size: 30,
      },
    })
    .then((response) => {
      if (response.data.content.length !== 0) {
        const content = response.data.content;
        const promise = content.map((userInform) =>
          getMenu(userInform).then((menuAuth) => ({
            ...userInform,
            menuAuth: menuAuth,
          }))
        );
        return Promise.all(promise).then((content) => {
          return { ...response.data, content: content };
        });
      } else {
        return response.data;
      }
    });
}

/* 사용자 수정 */
export function saveUser(userInform) {
  return axios
    .put(apiAddress.saveUser + `/${userInform.userId}`, userInform, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response.data;
    });
}

/* 사용자 삭제 */
export function delUser(userId) {
  return axios.delete(apiAddress.delUser + `/${userId}`).then((response) => {
    return response.data;
  });
}

/* 사용자 메뉴 얻기 */
export function getMenu(userInform) {
  try {
    const tokenInform = localStorage.getItem("tokenInform");
    const tokenInformParse = JSON.parse(tokenInform);
    if (tokenInformParse) {
      return axios
        .post(
          apiAddress.getMenu,
          {
            id: userInform.id,
            siteId: userInform.siteId,
            userId: userInform.userId,
            userNm: userInform.userNm,
            authGrpId: userInform.authGrpId,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          console.log(response.data);
          return response.data;
        });
    } else {
      return [];
    }
  } catch (e) {
    return [];
  }
}

/* 사용자 비밀번호 변경 */
export function changePwd(pwdInform) {
  const axiosInstance = axios.create({
    headers: {
      "Content-Type": "application/json",
    },
  });

  axiosInstance.interceptors.response.use(
    (response) => {
      return Promise.resolve(response);
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  axiosInstance.interceptors.request.use(
    (config) => config,
    (error) => Promise.reject(error)
  );

  return axiosInstance
    .put(
      apiAddress.changePwd,
      {
        userId: pwdInform.userId,
        oldPassword: pwdInform.oldPassword,
        newPassword: pwdInform.newPassword,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response && error.response.status === 404) {
        return error.response.data;
      }
      return false;
    });
}

/* 사용자 비밀번호 초기화 (임시 비밀번호 발급) */
export function resetPwd(resetId, cellNum) {
  const axiosInstance = axios.create({
    headers: {
      "Content-Type": "application/json",
    },
  });

  axiosInstance.interceptors.response.use(
    (response) => {
      return Promise.resolve(response);
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  axiosInstance.interceptors.request.use(
    (config) => config,
    (error) => Promise.reject(error)
  );

  return axiosInstance
    .put(
      apiAddress.resetPwd,
      {
        userId: resetId,
        cellNum: cellNum,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response && error.response.status === 404) {
        return error.response.data;
      }
      return false;
    });
}

/* 사용자 상태 변경(승인) */
export function accessId(id) {
  const tokenInform = localStorage.getItem("tokenInform");
  const tokenInformParse = JSON.parse(tokenInform);

  const axiosInstance = axios.create({
    headers: {
      "Content-Type": "application/json",
    },
  });

  axiosInstance.interceptors.response.use(
    (response) => {
      return Promise.resolve(response);
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  axiosInstance.interceptors.request.use(
    (config) => config,
    (error) => Promise.reject(error)
  );

  return axiosInstance
    .put(
      apiAddress.accessId,
      {
        userId: id,
        newStatus: "A",
        modUser: tokenInformParse.userId,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response && error.response.status === 404) {
        return error.response.data;
      }
      return false;
    });
}

/* 사용자 아이디 찾기 */
export function findId(inform) {
  const axiosInstance = axios.create({
    headers: {
      "Content-Type": "application/json",
    },
  });

  axiosInstance.interceptors.response.use(
    (response) => {
      return Promise.resolve(response);
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  axiosInstance.interceptors.request.use(
    (config) => config,
    (error) => Promise.reject(error)
  );

  return axiosInstance
    .post(
      apiAddress.findId,
      {
        userNm: inform.userNm,
        cellNum: inform.cellNum,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response && error.response.status === 404) {
        return error.response.data;
      }
      return false;
    });
}
