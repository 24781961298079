import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { setMenu, setSubmenu } from "../../../redux/modules/menu";
import { useNavigate } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome, faList } from "@fortawesome/free-solid-svg-icons";

import { tokenCheck } from "../../../utils/tokenCheck";
import { getUser } from "../../../services/internal/userAPI";
import Loading from "../../../components/common/window/Loading";
import UserList from "../../../components/setting/user/UserList";

const PAGE_VALUE = "설정";
const SUBPAGE_VALUE = "사용자";

function SettingUser() {
  const dispatch = useDispatch();
  const movePage = useNavigate();
  const [isToken, setIsToken] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isNext, setIsNext] = useState(false);
  const [curPage, setCurPage] = useState(0);
  const [userData, setUserData] = useState([]);
  const [isChange, setIsChange] = useState(false);

  function onClickPrevious() {
    // 이전 페이지 클릭
    setCurPage(curPage - 1);
  }
  function onClickNext() {
    // 다음 페이지 클릭
    setCurPage(curPage + 1);
  }
  function fetchUser(page) {
    // 사용자 조회
    getUser(page).then((response) => {
      setIsLoading(false);
      if (response.content.length !== 0) {
        setUserData(response.content);
      } else {
        setUserData([]);
      }

      if (!response.last) {
        setIsNext(true);
      } else {
        setIsNext(false);
      }
    });
  }

  useEffect(() => {
    dispatch(setMenu(PAGE_VALUE));
    dispatch(setSubmenu(SUBPAGE_VALUE));

    // 토큰 확인
    tokenCheck().then((result) => {
      if (!result) {
        alert("인증 토큰이 만료되었습니다. 다시 로그인 해주세요");
        movePage("/");
      } else {
        setIsToken(true);
        setIsLoading(true);
        fetchUser(0);
      }
    });
  }, []);
  useEffect(() => {
    if (isChange) {
      setIsLoading(true);
      fetchUser(curPage);

      setIsChange(false);
    }
  }, [isChange]);

  return (
    <div className="outlet_container">
      {
        // 로딩 화면
        isLoading && <Loading isOpen={isLoading} />
      }
      {isToken && (
        <div id="setting-user-page">
          <div className="header_container">
            <div className="title">
              <div className="title_imp">사용자</div>
              <div className="title_nor">설정</div>
            </div>
            <div className="path">
              <FontAwesomeIcon icon={faHome} />
              <div className="text">&gt; 설정 &gt; 사용자</div>
            </div>
          </div>

          <div className="main_container">
            <div className="contents_container setting-user-list">
              <div className="contents_header">
                <div className="title">
                  <FontAwesomeIcon icon={faList} />
                  <div className="text">사용자</div>
                </div>
              </div>

              <div className="contents_main">
                <UserList userData={userData} setIsChange={setIsChange} />
              </div>

              <div className="contents_footer">
                <div className="setting-user-page-container">
                  {curPage > 0 && (
                    <div
                      className="page-change previous"
                      onClick={onClickPrevious}
                    >
                      &lt; &lt; 이전페이지
                    </div>
                  )}
                  {isNext && (
                    <div className="page-change next" onClick={onClickNext}>
                      다음페이지 &gt; &gt;
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default SettingUser;
