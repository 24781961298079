import { useState, useEffect } from "react";
import {
  createColumnHelper,
  flexRender,
  useReactTable,
  getCoreRowModel,
  getSortedRowModel,
} from "@tanstack/react-table";
import axios from "axios";
import Modal from "react-modal";

import { MODALSTYLES } from "../../../constants/modalStyles";

Modal.setAppElement("#root");

const APIURL = "https://apis.data.go.kr/1741000/StanReginCd/getStanReginCdList";
const APIKEY =
  "vPWlgrsRUcnkAoK9+HV45WJ8PwsA1I/PInEHs6k8uZQ3NgpOmAT6awcSEzAwPhWVjqpi/nrRSzeJ95sTg2KeUA=="; // Decoding.

function Bdong({ isOpen, setIsOpen, bdong, setBdong }) {
  // 검색.
  const [search, setSearch] = useState("");
  // data.
  const [data, setData] = useState([]);
  const columnHelper = createColumnHelper();
  const columns = [
    columnHelper.accessor("region_cd", { header: "법정동코드", size: 150 }),
    columnHelper.accessor("locatadd_nm", { header: "법정동명", size: "auto" }),
  ];
  // page.
  const [totalData, setTotalData] = useState(0);
  const [totalPage, setTotalPage] = useState("1");
  const [curPage, setCurPage] = useState(1);
  const [goPage, setGoPage] = useState(1);

  // 검색.
  function onKeyPressSearch(e) {
    if (e.key === "Enter") onClickSearch();
  }
  function onClickSearch() {
    setCurPage(1);
    setGoPage(1);
    fetchData();
  }
  // 데이터 선택.
  function onClickRow(row) {
    setBdong({ ...bdong, bdongCd: row.region_cd, addr: row.locatadd_nm });
    setIsOpen(false);
  }
  // 페이지 이동.
  function onClickFirst() {
    setCurPage(1);
  }
  function onClickPre() {
    curPage !== 1 && setCurPage(curPage - 1);
  }
  function onClickNext() {
    curPage !== totalPage && setCurPage(curPage + 1);
  }
  function onClickLast() {
    setCurPage(totalPage);
  }
  function onClickGo() {
    if (1 <= goPage && goPage <= totalPage) {
      setCurPage(goPage);
    } else {
      setCurPage(1);
      setGoPage(1);
    }
  }
  function onKeyPressGo(e) {
    if (e.key === "Enter") {
      onClickGo();
    }
  }

  function onClickClose() {
    setIsOpen(false);
  }

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  async function fetchData() {
    try {
      axios
        .get(APIURL, {
          params: {
            ServiceKey: APIKEY,
            type: "JSON",
            pageNo: curPage,
            numOfRows: "10",
            flag: "N",
            locatadd_nm: search,
          },
        })
        .then((response) => {
          if (response.data.StanReginCd) {
            const responseTotal =
              response.data.StanReginCd[0].head[0].totalCount;
            const responseData = response.data.StanReginCd[1].row;
            setTotalData(responseTotal);
            setTotalPage(Math.ceil(responseTotal / 10));
            setData(responseData);
          } else {
            setTotalData(0);
            setTotalPage(0);
            setData([]);
          }
        });
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    fetchData();
  }, [curPage]);

  return (
    <Modal isOpen={isOpen} style={MODALSTYLES.bdong}>
      <div className="modal_title">
        <div>법정동코드 및 주소 조회</div>
      </div>
      <div className="modal_container">
        <div className="modal_main_column" style={{ border: "none" }}>
          {/* 검색 */}
          <div className="bdong_search">
            <input
              type="text"
              placeholder="법정동명으로 검색"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              onKeyPress={onKeyPressSearch}
              spellCheck="false"
            />
            <div
              className="button_imp"
              style={{ marginLeft: "10px" }}
              onClick={onClickSearch}
            >
              검색
            </div>
          </div>

          {/* 데이터 */}
          <div className="bdong_data">
            <table className="react_table">
              <thead>
                {table.getHeaderGroups().map((headerGroup) => (
                  <tr key={headerGroup.id}>
                    {headerGroup.headers.map((header) => (
                      <th
                        key={header.id}
                        onClick={header.column.getToggleSortingHandler()}
                        style={{
                          width: isNaN(header.getSize())
                            ? "auto"
                            : `${header.getSize()}px`,
                        }}
                      >
                        {header.isPlaceholder
                          ? null
                          : flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody>
                {table.getRowModel().rows.map((row, index) => (
                  <tr
                    key={row.id}
                    onClick={() => onClickRow(row.original)}
                    style={{ cursor: "pointer" }}
                  >
                    {row.getVisibleCells().map((cell) => (
                      <td
                        key={cell.id}
                        style={{
                          width: isNaN(cell.column.getSize())
                            ? "auto"
                            : `${cell.column.getSize()}px`,
                          minWidth: isNaN(cell.column.getSize())
                            ? "auto"
                            : `${cell.column.getSize()}px`,
                          maxWidth: isNaN(cell.column.getSize())
                            ? "auto"
                            : `${cell.column.getSize()}px`,
                          textAlign: cell.column.id === "region_cd" && "center",
                        }}
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          {/* 페이지 */}
          <div
            className="bdong_page"
            style={{
              display: totalData === 0 ? "none" : "",
            }}
          >
            <div className="page_item">
              <div className="button_page" onClick={onClickFirst}>
                &lt;&lt;
              </div>
              <div
                className="button_page"
                onClick={onClickPre}
                style={{ marginLeft: "5px" }}
              >
                &lt;
              </div>
              <div
                className="button_page"
                onClick={onClickNext}
                style={{ marginLeft: "5px" }}
              >
                &gt;
              </div>
              <div
                className="button_page"
                onClick={onClickLast}
                style={{ marginLeft: "5px" }}
              >
                &gt;&gt;
              </div>
              <div style={{ marginLeft: "10px", fontSize: "0.9em" }}>
                Page {curPage} of {totalPage}
              </div>
            </div>

            <div className="page_item">
              <div>Go to Page</div>
              <input
                type="number"
                value={goPage}
                onChange={(e) => setGoPage(e.target.value)}
                onKeyPress={onKeyPressGo}
              />
              <div
                className="button_modal"
                onClick={onClickGo}
                style={{
                  width: "70px",
                  height: "25px",
                  padding: "2px 0px",
                  borderRadius: "3px",
                }}
              >
                이동
              </div>
            </div>
          </div>
        </div>

        <div className="modal_footer">
          <div className="button_nor" onClick={onClickClose}>
            닫기
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default Bdong;
