import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  createColumnHelper,
  flexRender,
  useReactTable,
  getCoreRowModel,
  getSortedRowModel,
} from "@tanstack/react-table";
import { Menu, Item, useContextMenu } from "react-contexify";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisVertical } from "@fortawesome/free-solid-svg-icons";

import { tokenCheck } from "../../../utils/tokenCheck";
import { dateTimeToFormat } from "../../../utils/dateFormat";
import {
  delUser,
  resetPwd,
  accessId,
} from "../../../services/internal/userAPI";
import UserInform from "../../common/inform/UserInform";
import UserEdit from "./UserEdit";
import Delete from "../../common/window/Delete";
import Confirm from "../../common/window/Confirm";
import SuccessAlert from "../../common/window/SuccessAlert";
import Alert from "../../common/window/Alert";

const MENU_ID = "userList";

function UserList({ userData, setIsChange }) {
  const movePage = useNavigate();
  const [isInform, setIsInform] = useState({
    isOpen: false,
    data: {},
  });
  const [isEdit, setIsEdit] = useState({
    isOpen: false,
    data: {},
  });
  const [isDel, setIsDel] = useState({
    isOpen: false,
    text: "",
    data: {},
  });
  const [state, setState] = useState("A");
  const [confirmData, setConfirmData] = useState({});
  const [isPresetConfirm, setIsPresetConfirm] = useState({
    isOpen: false,
    text: "",
  });
  const [isPresetAlert, setIsPresetAlert] = useState(false);
  const [isPresetSuccess, setIsPresetSuccess] = useState(false);
  const [isAccessConfirm, setIsAccessConfirm] = useState({
    isOpen: false,
    text: "",
  });
  const [isAccessAlert, setIsAccessAlert] = useState(false);
  const [isAccessSuccess, setIsAccessSuccess] = useState(false);
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);
  const columnHelper = createColumnHelper();
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });
  const menuIconColumn = {
    id: "menuIcon",
    accessor: "menu",
    header: "",
    size: 20,
    enableSorting: false,
    cell: ({ row }) => (
      <FontAwesomeIcon className="table_icon" icon={faEllipsisVertical} />
    ),
  };
  const { show } = useContextMenu({
    id: MENU_ID,
  });

  function onClickPreset(row) {
    // 초기화 클릭시
    // 토큰 확인
    tokenCheck().then((result) => {
      if (!result) {
        alert("인증 토큰이 만료되었습니다. 다시 로그인 해주세요");
        movePage("/");
      } else {
        setConfirmData(row.original);
        setIsPresetConfirm({
          isOpen: true,
          text:
            row.original.userId +
            "(" +
            row.original.userNm +
            ")님의 비밀번호를 초기화 하시겠습니까?",
        });
      }
    });
  }
  function preset() {
    // 비밀번호 초기화
    resetPwd(confirmData.userId, confirmData.cellNum).then((response) => {
      if (response.success === true) {
        // 성공
        setIsPresetSuccess(true);
        setIsChange(true);
      } else {
        // 실패
        setIsPresetAlert(true);
      }
    });
  }
  function onClickAccess(row) {
    // 승인 클릭시
    // 토큰 확인
    tokenCheck().then((result) => {
      if (!result) {
        alert("인증 토큰이 만료되었습니다. 다시 로그인 해주세요");
        movePage("/");
      } else {
        setConfirmData(row.original);
        setIsAccessConfirm({
          isOpen: true,
          text:
            row.original.userId +
            "(" +
            row.original.userNm +
            ")님을 승인하시겠습니까?",
        });
      }
    });
  }
  function access() {
    // 승인
    accessId(confirmData.userId).then((response) => {
      if (response.success === true) {
        // 성공
        setIsAccessSuccess(true);
        setIsChange(true);
      } else {
        // 실패
        setIsAccessAlert(true);
      }
    });
  }
  function onClickMenu(e, row) {
    // 메뉴 클릭 시.
    e.stopPropagation();
    show({ event: e, props: { data: row } });
  }
  function menuItemClick({ id, props }) {
    // 메뉴 Item 클릭 시.
    // 토큰 확인
    tokenCheck().then((result) => {
      if (!result) {
        alert("인증 토큰이 만료되었습니다. 다시 로그인 해주세요");
        movePage("/");
      } else {
        switch (id) {
          // 정보.
          case "inform":
            setIsInform({
              isOpen: true,
              data: props.data,
            });
            break;
          case "edit":
            setIsEdit({
              isOpen: true,
              data: props.data,
            });
            break;
          case "delete":
            setIsDel({
              isOpen: true,
              text: "'" + props.data.userId + "' 사용자를 삭제하시겠습니까?",
              data: props.data,
            });
            break;
          default:
            break;
        }
      }
    });
  }
  function deleteUser(data) {
    delUser(data.userId).then((response) => {
      setIsChange(true);
    });
  }

  useEffect(() => {
    if (userData.length !== 0) {
      const column = [
        columnHelper.accessor(
          (row) => {
            if (row.accntStat === "R") {
              // 승인대기
              return "승인대기";
            } else if (row.accntStat === "S") {
              // 정지
              return "계정정지";
            } else {
              // 정상
              return "정상";
            }
          },
          { id: "accntStat", header: "상태", size: 50 }
        ),
        columnHelper.accessor("siteId", { header: "고객사ID", size: 50 }),
        columnHelper.accessor("userId", { header: "ID" }),
        columnHelper.accessor("userNm", { header: "이름", size: 100 }),
        columnHelper.accessor(
          (row) => {
            const positionNm = row.positionNm === "" ? "-" : row.positionNm;
            return positionNm;
          },
          { id: "positionNm", header: "직급", size: 50 }
        ),
        columnHelper.accessor(
          (row) => {
            const deptNm = row.deptNm === "" ? "-" : row.deptNm;
            return deptNm;
          },
          { id: "deptNm", header: "부서" }
        ),
        columnHelper.accessor(
          (row) => {
            const adtnl1 =
              row.adtnl1 === "" || row.adtnl1 === undefined ? "-" : row.adtnl1;
            return adtnl1;
          },
          { id: "adtnl1", header: "소속" }
        ),
        columnHelper.accessor(
          (row) => {
            const cellNum =
              row.cellNum === "" || row.cellNum === undefined
                ? "-"
                : row.cellNum;
            return cellNum;
          },
          { id: "cellNum", header: "휴대번호" }
        ),
        columnHelper.accessor(
          (row) => {
            const email = row.email === "" ? "-" : row.email;
            return email;
          },
          { id: "email", header: "Email" }
        ),
        columnHelper.accessor(
          (row) => {
            return dateTimeToFormat(row.regDt);
          },
          { id: "regDt", header: "등록일자" }
        ),
        menuIconColumn,
      ];
      const user = userData;
      user.sort((a, b) => {
        // siteId가 없는 경우를 처리
        if (!a.siteId) return 1;
        if (!b.siteId) return -1;

        // siteId가 있는 경우 사전순 비교
        return a.siteId.localeCompare(b.siteId);
      });

      setData(user);
      setColumns(column);
    } else {
      setData([]);
      setColumns([]);
    }
  }, [userData]);

  return (
    <div id="setting-user-list-component">
      {
        // 사용자 정보
        isInform.isOpen && (
          <UserInform
            isOpen={isInform}
            setIsOpen={setIsInform}
            setIsChange={setIsChange}
          />
        )
      }
      {
        // 사용자 수정
        isEdit.isOpen && (
          <UserEdit
            isOpen={isEdit}
            setIsOpen={setIsEdit}
            setIsChange={setIsChange}
          />
        )
      }
      {
        // 사용자 삭제
        isDel.isOpen && (
          <Delete isOpen={isDel} setIsOpen={setIsDel} delData={deleteUser} />
        )
      }
      {
        // 비밀번호 초기화
        isPresetConfirm.isOpen && (
          <Confirm
            isOpen={isPresetConfirm}
            setIsOpen={setIsPresetConfirm}
            active={preset}
          />
        )
      }
      {
        // 비밀번호 초기화 - 실패
        isPresetAlert && (
          <Alert
            isOpen={isPresetAlert}
            setIsOpen={setIsPresetAlert}
            text="초기화에 실패하였습니다"
          />
        )
      }
      {
        // 비밀번호 초기화 - 성공
        isPresetSuccess && (
          <SuccessAlert
            isOpen={isPresetSuccess}
            setIsOpen={setIsPresetSuccess}
            text="임시 비밀번호가 발급되었습니다"
          />
        )
      }
      {
        // 승인
        isAccessConfirm.isOpen && (
          <Confirm
            isOpen={isAccessConfirm}
            setIsOpen={setIsAccessConfirm}
            active={access}
          />
        )
      }
      {
        // 승인 - 실패
        isAccessAlert && (
          <Alert
            isOpen={isAccessAlert}
            setIsOpen={setIsAccessAlert}
            text="승인에 실패하였습니다"
          />
        )
      }
      {
        // 승인 - 성공
        isAccessSuccess && (
          <SuccessAlert
            isOpen={isAccessSuccess}
            setIsOpen={setIsAccessSuccess}
            text="승인에 성공하였습니다"
          />
        )
      }
      {data.length === 0 ? (
        <div className="setting-data-none-container"></div>
      ) : (
        <div className="list">
          <table className="react_table">
            <thead>
              {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <th
                      key={header.id}
                      onClick={header.column.getToggleSortingHandler()}
                      style={{
                        width: isNaN(header.getSize())
                          ? "auto"
                          : `${header.getSize()}px`,
                        textAlign:
                          header.id === "userId" ||
                          header.id === "deptNm" ||
                          header.id === "adtnl1" ||
                          header.id === "email"
                            ? "left"
                            : "center",
                      }}
                    >
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody>
              {table.getRowModel().rows.map((row, index) => (
                <tr key={row.id}>
                  {row.getVisibleCells().map((cell) => (
                    <td
                      key={cell.id}
                      onClick={(e) => {
                        if (cell.column.id === "menuIcon") {
                          onClickMenu(e, row.original);
                        }
                      }}
                      onMouseOver={() => {
                        if (cell.column.id === "accntStat") {
                          setState(row.original.accntStat);
                        }
                      }}
                      onMouseOut={() => {
                        setState("A");
                      }}
                      style={{
                        width: isNaN(cell.column.getSize())
                          ? "auto"
                          : `${cell.column.getSize()}px`,
                        color:
                          cell.column.id === "accntStat" &&
                          row.original.accntStat === "R"
                            ? "#D9A205"
                            : cell.column.id === "accntStat" &&
                              row.original.accntStat === "S" &&
                              "#ff4040",
                        textAlign:
                          (cell.column.id === "accntStat" ||
                            cell.column.id === "siteId" ||
                            cell.column.id === "userNm" ||
                            cell.column.id === "positionNm" ||
                            cell.column.id === "cellNum" ||
                            cell.column.id === "regDt" ||
                            cell.column.id === "menuIcon") &&
                          "center",
                      }}
                    >
                      {cell.column.id === "accntStat" &&
                      row.original.accntStat === "R" &&
                      state === "R" ? (
                        <div className="accntStat-td">
                          <div
                            className="button button-imp"
                            onClick={() => onClickAccess(row)}
                          >
                            승인
                          </div>
                        </div>
                      ) : cell.column.id === "accntStat" &&
                        row.original.accntStat === "S" &&
                        state === "S" ? (
                        <div className="accntStat-td">
                          <div
                            className="button button-imp"
                            onClick={() => onClickPreset(row)}
                          >
                            초기화
                          </div>
                        </div>
                      ) : (
                        flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      {/* Context 메뉴 */}
      <Menu id={MENU_ID}>
        <Item id="inform" onClick={menuItemClick}>
          정보
        </Item>
        <Item id="edit" onClick={menuItemClick}>
          수정
        </Item>
        <Item id="delete" onClick={menuItemClick}>
          삭제
        </Item>
      </Menu>
    </div>
  );
}

export default UserList;
