import React, { useState, useEffect, useRef } from "react";
import {
  ResponsiveContainer,
  AreaChart,
  Area,
  Legend,
  XAxis,
  YAxis,
  Tooltip,
  ReferenceLine,
  CartesianGrid,
} from "recharts";

import reservoirImg from "../../../assets/reservoir.png";

function ReservoirGraph({ searchType, selected }) {
  const [data, setData] = useState([]);
  const [showData, setShowData] = useState([]);
  const [inData, setInData] = useState([]);
  const [outData, setOutData] = useState([]);
  const [minMax, setMinMax] = useState({ min: 0, max: 0 });
  const [showMinMax, setShowMinMax] = useState({ min: 0, max: 0 });
  const [alertLine, setAlertLine] = useState([]);
  const chartRef = useRef(null);

  /* AlertLine Custom */
  const AlertCustomLabelUp1 = (props) => {
    const x = props.viewBox.x;
    const y = props.viewBox.y;
    const value = props.value;
    const color = props.color;
    return (
      <text
        x={x + 5}
        y={y - 20}
        textAnchor="start"
        fill={color}
        fontSize={12}
        fontWeight="bold"
      >
        {value}
      </text>
    );
  };
  const AlertCustomLabelUp2 = (props) => {
    const x = props.viewBox.x;
    const y = props.viewBox.y;
    const value = props.value;
    const color = props.color;
    return (
      <text
        x={x + 140}
        y={y - 15}
        textAnchor="start"
        fill={color}
        fontSize={12}
        fontWeight="bold"
      >
        {value}
      </text>
    );
  };
  const AlertCustomLabelUp3 = (props) => {
    const x = props.viewBox.x;
    const y = props.viewBox.y;
    const value = props.value;
    const color = props.color;
    return (
      <text
        x={x + 250}
        y={y - 10}
        textAnchor="start"
        fill={color}
        fontSize={12}
        fontWeight="bold"
      >
        {value}
      </text>
    );
  };
  const AlertCustomLabelUp4 = (props) => {
    const x = props.viewBox.x;
    const y = props.viewBox.y;
    const value = props.value;
    const color = props.color;
    return (
      <text
        x={x + 370}
        y={y - 5}
        textAnchor="start"
        fill={color}
        fontSize={12}
        fontWeight="bold"
      >
        {value}
      </text>
    );
  };
  /* Tooltip Custom */
  const TooltipCustom = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      const date =
        payload[0].payload.date === undefined ? "" : payload[0].payload.date;
      return (
        <div className="tooltip-container">
          <div className="date-text">{date}</div>
          <div className="value-container">
            {payload.map((item) => (
              <div key={`value-item-${item.dataKey}`} className="value-item">
                <div
                  className="value-color"
                  style={{ background: item.color }}
                />
                <div className="value-title">저수위(EL.m):</div>
                <div className="value">{item.payload.avgVal}</div>
              </div>
            ))}
          </div>
        </div>
      );
    }
  };

  useEffect(() => {
    /* 데이터가공 & 최대값 찾기 */
    if (Array.isArray(selected.data) && selected.data.length > 0) {
      const selectedData = selected.data;

      let dataMax = 0;
      const nullData = selectedData.map((dataItem) => {
        const tmpItem = { ...dataItem };
        for (let key in dataItem) {
          if (key !== "date") {
            if (dataItem[key] === "-") {
              tmpItem[key] = null; // "-" → null
            } else {
              const val = parseFloat(dataItem[key]); // 최대값 찾기
              dataMax = val > dataMax ? val : dataMax;
            }
          }
        }
        return tmpItem;
      });
      setData(nullData);
      setShowData(nullData);
      setMinMax({ min: 135, max: 235 });
      setShowMinMax({ min: 135, max: 235 });

      const inMinus =
        selected.eqId === 26
          ? 32
          : selected.eqId === 33
          ? 31
          : selected.eqId === 40
          ? 33
          : 28;
      const outMinus =
        selected.eqId === 26
          ? 38
          : selected.eqId === 33
          ? 40
          : selected.eqId === 40
          ? 45
          : 43;
      const firstData = [...nullData.slice(0, 1)];
      const tmpIn = [...firstData];
      const formatIn = {
        ...tmpIn[0],
        avgVal: parseFloat(tmpIn[0].avgVal) - inMinus,
      };
      const tmpOut = [...firstData];
      const formatOut = {
        ...tmpOut[0],
        avgVal: parseFloat(tmpOut[0].avgVal) - outMinus,
      };
      setInData([formatIn, formatIn]);
      setOutData([formatOut, formatOut]);
    } else {
      setData([]);
      setShowData([]);
      setMinMax({ min: 135, max: 235 });
      setShowMinMax({ min: 135, max: 235 });
    }
  }, [selected]);
  useEffect(() => {
    console.log(inData, outData);
  }, [inData, outData]);

  return (
    <div id="data-graph-component">
      {Array.isArray(data) && data.length > 0 && (
        <div className="reservoir-graph-container">
          {/* 저수지 이미지 */}
          <div className="reservoir-img">
            <div className="img">
              {/* 내수 측 */}
              <div className="ground-in-graph">
                <ResponsiveContainer width="100%" height="100%">
                  <AreaChart
                    data={inData}
                    margin={{ left: 0, right: 0, bottom: -10 }}
                  >
                    <CartesianGrid
                      stroke="#595959"
                      strokeWidth={0.5}
                      vertical={false}
                    />
                    <XAxis
                      dataKey="date"
                      tickFormatter={(value) => ``}
                      tick={{ fontSize: 11, fill: "#9B9B9B" }}
                      axisLine={false}
                    />
                    <YAxis
                      type="number"
                      domain={[135, 235]}
                      tickFormatter={(value) => ``}
                      tick={{ fontSize: 11, fill: "#9B9B9B" }}
                      tickCount={10}
                      axisLine={false}
                      allowDataOverflow={true}
                    />
                    <Area
                      type="monotone"
                      dataKey="avgVal"
                      fill="#425FD1"
                      stroke="#425FD1"
                      isAnimationActive={false}
                    />
                  </AreaChart>
                </ResponsiveContainer>
              </div>
              {/* 외수 측 */}
              <div className="ground-out-graph">
                <ResponsiveContainer width="100%" height="100%">
                  <AreaChart
                    data={outData}
                    margin={{ left: 0, right: 0, bottom: -10 }}
                  >
                    <CartesianGrid
                      stroke="#595959"
                      strokeWidth={0.5}
                      vertical={false}
                    />
                    <XAxis
                      dataKey="date"
                      tickFormatter={(value) => ``}
                      tick={{ fontSize: 11, fill: "#9B9B9B" }}
                      axisLine={false}
                    />
                    <YAxis
                      type="number"
                      domain={[135, 235]}
                      tickFormatter={(value) => ``}
                      tick={{ fontSize: 11, fill: "#9B9B9B" }}
                      tickCount={10}
                      axisLine={false}
                      allowDataOverflow={true}
                    />
                    <Area
                      type="monotone"
                      dataKey="avgVal"
                      fill="#425FD1"
                      stroke="#425FD1"
                      isAnimationActive={false}
                    />
                  </AreaChart>
                </ResponsiveContainer>
              </div>
              <div className="extension-graph-container">
                {/* 저수지 그래프 확장 */}
                <ResponsiveContainer width="100%" height="100%">
                  <AreaChart
                    data={data.slice(0, 2)}
                    margin={{ left: 0, right: 0, bottom: -10 }}
                  >
                    <CartesianGrid
                      stroke="#595959"
                      strokeWidth={0.5}
                      vertical={false}
                    />
                    <XAxis
                      dataKey="date"
                      tickFormatter={(value) => ``}
                      tick={{ fontSize: 11, fill: "#9B9B9B" }}
                      axisLine={false}
                    />
                    <YAxis
                      type="number"
                      domain={[135, 235]}
                      tickFormatter={(value) => ``}
                      tick={{ fontSize: 11, fill: "#9B9B9B" }}
                      tickCount={10}
                      axisLine={false}
                      allowDataOverflow={true}
                    />
                    <defs>
                      <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                        <stop
                          offset="5%"
                          stopColor="#425FD173"
                          stopOpacity={1}
                        />
                        <stop
                          offset="95%"
                          stopColor="#425FD173"
                          stopOpacity={0.3}
                        />
                      </linearGradient>
                    </defs>
                    <Area
                      type="monotone"
                      dataKey="avgVal"
                      fill="url(#colorUv)"
                      stroke="#425FD1"
                      isAnimationActive={false}
                    />
                    {/* 임계치 */}
                    <ReferenceLine
                      y={214}
                      stroke="#006B99"
                      strokeDasharray="7 4"
                      strokeWidth={1}
                    />
                    <ReferenceLine
                      y={216}
                      stroke="#928400"
                      strokeDasharray="7 4"
                      strokeWidth={1}
                    />
                    <ReferenceLine
                      y={218}
                      stroke="#A15300"
                      strokeDasharray="7 4"
                      strokeWidth={1}
                    />
                    <ReferenceLine
                      y={220}
                      stroke="#8F0303"
                      strokeDasharray="7 4"
                      strokeWidth={1}
                    />
                  </AreaChart>
                </ResponsiveContainer>
              </div>
              <img src={reservoirImg} alt="" />
            </div>
          </div>

          {/* 그래프 */}
          <div className="reservoir-graph">
            <div className="graph">
              <ResponsiveContainer width="100%" height="100%">
                <AreaChart
                  ref={chartRef}
                  data={showData}
                  margin={{ left: 0, right: 0, bottom: -10 }}
                >
                  <CartesianGrid
                    stroke="#595959"
                    strokeWidth={0.5}
                    vertical={false}
                  />
                  {/*<Legend
                    verticalAlign="top"
                    height={40}
                    wrapperStyle={{ paddingBottom: "10px" }}
                    content={<CustomLegend />}
                  />*/}
                  <XAxis
                    dataKey="date"
                    tick={{ fontSize: 11, fill: "#9B9B9B" }}
                    axisLine={false}
                  />
                  <YAxis
                    type="number"
                    // domain={[showMinMax.min, showMinMax.max]}
                    domain={[135, 235]}
                    tickFormatter={(value) => `EL.${value}m`}
                    tick={{ fontSize: 11, fill: "#9B9B9B" }}
                    tickCount={10}
                    axisLine={false}
                    allowDataOverflow={true}
                    orientation="right"
                  />
                  <Tooltip
                    content={<TooltipCustom />}
                    filterNull={true}
                    cursor={{
                      stroke: "#757575",
                      strokeWidth: 1,
                      strokeDasharray: "3 3",
                    }}
                  />

                  <defs>
                    <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                      <stop offset="5%" stopColor="#425FD173" stopOpacity={1} />
                      <stop
                        offset="95%"
                        stopColor="#425FD173"
                        stopOpacity={0.3}
                      />
                    </linearGradient>
                  </defs>
                  <Area
                    type="monotone"
                    dataKey="avgVal"
                    fill="url(#colorUv)"
                    stroke="#425FD1"
                    isAnimationActive={false}
                  />

                  {/* 임계치 */}
                  <ReferenceLine
                    y={214}
                    label={
                      <AlertCustomLabelUp1
                        value={`관심(관리수위 EL.185m)`}
                        color="#0F80B1"
                      />
                    }
                    stroke="#006B99"
                    strokeDasharray="7 4"
                    strokeWidth={1}
                  />
                  <ReferenceLine
                    y={216}
                    label={
                      <AlertCustomLabelUp2
                        value={`주의(WL EL.190m)`}
                        color="#A49506"
                      />
                    }
                    stroke="#928400"
                    strokeDasharray="7 4"
                    strokeWidth={1}
                  />
                  <ReferenceLine
                    y={218}
                    label={
                      <AlertCustomLabelUp3
                        value={`경계(HWL EL.200m)`}
                        color="#B9640A"
                      />
                    }
                    stroke="#A15300"
                    strokeDasharray="7 4"
                    strokeWidth={1}
                  />
                  <ReferenceLine
                    y={220}
                    label={
                      <AlertCustomLabelUp4
                        value={`심각(PMF EL.220m)`}
                        color="#A00B0B"
                      />
                    }
                    stroke="#8F0303"
                    strokeDasharray="7 4"
                    strokeWidth={1}
                  />
                </AreaChart>
              </ResponsiveContainer>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default ReservoirGraph;
