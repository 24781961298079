import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Menu, Item, useContextMenu } from "react-contexify";
import {
  createColumnHelper,
  flexRender,
  useReactTable,
  getCoreRowModel,
  getSortedRowModel,
} from "@tanstack/react-table";
import axios from "axios";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisVertical } from "@fortawesome/free-solid-svg-icons";

import { tokenCheck } from "../../../utils/tokenCheck";
import apiAddress from "../../../services/apiAddress";
import Alert from "../../common/window/Alert";
import Delete from "../../common/window/Delete";
import BroadGroupInform from "../../common/inform/BroadGroupInform";
import GroupEdit from "./GroupEdit";

const MENU_ID = "groupList";

function Group({ isAddOk, selectedId, setSelectedId }) {
  const movePage = useNavigate();
  const [isAlert, setIsAlert] = useState(false);
  const [isInform, setIsInform] = useState({
    isOpen: false,
    data: {},
  });
  const [isEdit, setIsEdit] = useState({
    isOpen: false,
    data: {},
  });
  const [isDel, setIsDel] = useState({
    isOpen: false,
    text: "",
    data: {},
  });
  const [isEditOk, setIsEditOk] = useState(false);
  const [isDelOk, setIsDelOk] = useState(false);
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);
  const columnHelper = createColumnHelper();
  const menuIconColumn = {
    id: "menuIcon",
    accessor: "menu",
    header: "",
    size: 20,
    enableSorting: false,
    cell: ({ row }) => (
      <FontAwesomeIcon className="table_icon" icon={faEllipsisVertical} />
    ),
  };
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });
  const { show } = useContextMenu({
    id: MENU_ID,
  });

  function onClickRow(row) {
    // 행 클릭.
    if (selectedId === row.groupId) {
      setSelectedId(0);
    } else {
      setSelectedId(row.groupId);
    }
  }
  function onClickMenu(e, row) {
    // 메뉴 클릭 시.
    e.stopPropagation();
    show({ event: e, props: { data: row } });
  }
  function menuItemClick({ id, props }) {
    // 메뉴 Item 클릭 시.
    // 토큰 확인
    tokenCheck().then((result) => {
      if (!result) {
        alert("인증 토큰이 만료되었습니다. 다시 로그인 해주세요");
        movePage("/");
      } else {
        switch (id) {
          // 정보.
          case "inform":
            setIsInform({ isOpen: true, data: props.data });
            break;
          // 수정.
          case "edit":
            setIsEdit({
              isOpen: true,
              data: props.data,
            });
            break;
          // 삭제.
          case "delete":
            fetchDelCheck(props.data);
            break;
          default:
            break;
        }
      }
    });
  }

  /* 그룹 조회 API */
  async function fetchData() {
    try {
      axios
        .post(
          apiAddress.groupGet,
          {
            groupId: 0,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          const responseData = response.data;
          const brdData = responseData.filter(
            (item) => item.groupType === "BRD"
          );
          const column = [
            columnHelper.accessor("groupNm", {
              header: "그룹명",
              size: "auto",
            }),
            menuIconColumn,
          ];
          setData(brdData);
          setColumns(column);
        });
    } catch (e) {
      console.log(e);
    }
  }
  /* 그룹 삭제 체크 API */
  async function fetchDelCheck(data) {
    try {
      axios
        .post(
          apiAddress.groupEquipGet,
          {
            groupId: data.groupId,
            searchType: "A",
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          if (response.data.length > 0) {
            // 등록된 장비 존재 O.
            setIsAlert(true);
          } else {
            // 등록된 장비 존재 X.
            setIsDel({
              isOpen: true,
              text: "'" + data.groupNm + "' 방송그룹을 삭제하시겠습니까?",
              data: data,
            });
          }
        });
    } catch (e) {
      console.log(e);
    }
  }
  /* 그룹 삭제 API */
  async function fetchDataDel(data) {
    try {
      axios
        .delete(apiAddress.groupDelete + data.groupId, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          setIsDelOk(!isDelOk);
        });
    } catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    fetchData();
  }, [isAddOk, isEditOk, isDelOk]);

  return (
    <>
      {isAlert && (
        <Alert
          isOpen={isAlert}
          setIsOpen={setIsAlert}
          text="등록된 장비부터 삭제해주세요"
        />
      )}
      {isInform.isOpen && (
        <BroadGroupInform isOpen={isInform} setIsOpen={setIsInform} />
      )}
      {isEdit.isOpen && (
        <GroupEdit
          isOpen={isEdit}
          setIsOpen={setIsEdit}
          isOk={isEditOk}
          setIsOk={setIsEditOk}
        />
      )}
      {isDel.isOpen && (
        <Delete isOpen={isDel} setIsOpen={setIsDel} delData={fetchDataDel} />
      )}
      {data.length > 0 ? (
        <table className="react_table">
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th
                    key={header.id}
                    onClick={header.column.getToggleSortingHandler()}
                    style={{
                      width: isNaN(header.getSize())
                        ? "auto"
                        : `${header.getSize()}px`,
                    }}
                  >
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row, index) => (
              <tr
                key={row.id}
                className={
                  row.original.groupId === selectedId ? "tr_select" : ""
                }
                onClick={() => onClickRow(row.original)}
              >
                {row.getVisibleCells().map((cell) => (
                  <td
                    key={cell.id}
                    onClick={(e) => {
                      if (cell.column.id === "menuIcon") {
                        onClickMenu(e, row.original);
                      }
                    }}
                    style={{
                      width: isNaN(cell.column.getSize())
                        ? "auto"
                        : `${cell.column.getSize()}px`,
                      minWidth: isNaN(cell.column.getSize())
                        ? "auto"
                        : `${cell.column.getSize()}px`,
                      maxWidth: isNaN(cell.column.getSize())
                        ? "auto"
                        : `${cell.column.getSize()}px`,
                      textAlign: cell.column.id === "menuIcon" && "center",
                    }}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <div className="setting_data_none_container">
          <div>등록된 그룹이 없습니다</div>
        </div>
      )}

      {/* Context 메뉴 */}
      <Menu id={MENU_ID}>
        <Item id="inform" onClick={menuItemClick}>
          정보
        </Item>
        <Item id="edit" onClick={menuItemClick}>
          수정
        </Item>
        <Item id="delete" onClick={menuItemClick}>
          삭제
        </Item>
      </Menu>
    </>
  );
}

export default Group;
