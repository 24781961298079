import { useState, useEffect } from "react";
import axios from "axios";

import apiAddress from "../../../services/apiAddress";
import Rtu from "./equip/Rtu";
import EDBoard from "./equip/EDBoard";
import CCTV from "./equip/CCTV";

function Equip({ selectedId, isRegOk, isDelOk, delData, setDelData }) {
  const [rtuData, setRtuData] = useState([]);
  const [edbData, setEdbData] = useState([]);
  const [cctvData, setCctvData] = useState([]);

  /* 관리지역 장비 등록 조회 API */
  async function fetchData() {
    try {
      axios
        .post(
          apiAddress.regionEquipGet,
          {
            rgnId: selectedId,
            searchType: "A",
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          const responseData = response.data;
          const rtu = responseData.filter((item) => item.eqType === "RTU");
          const edb = responseData.filter((item) => item.eqType === "EDB");
          const cctv = responseData.filter((item) => item.eqType === "CCTV");

          if (cctv.length > 0) {
            axios
              .post(
                apiAddress.cctvGet,
                {
                  cctvInfId: 0,
                },
                {
                  headers: {
                    "Content-Type": "application/json",
                  },
                }
              )
              .then((response) => {
                const cctvDtl = response.data;
                const data = cctv.map((equip) => {
                  const dataItem = cctvDtl.find(
                    (dtl) => equip.eqId === dtl.eqId
                  );

                  if (dataItem) {
                    return { ...equip, ...dataItem };
                  }
                  return equip;
                });
                setCctvData(data);
              });
          } else {
            setCctvData(cctv);
          }
          setRtuData(rtu);
          setEdbData(edb);
        });
    } catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    fetchData();
  }, [selectedId, isRegOk, isDelOk]);

  return (
    <>
      {/* RTU */}
      <div className="setting_rgn_equip_header">
        <div>RTU</div>
      </div>
      <div className="setting_rgn_equip_main">
        <Rtu rtuData={rtuData} delData={delData} setDelData={setDelData} />
      </div>

      {/* 전광판 */}
      <div className="setting_rgn_equip_header">
        <div>전광판</div>
      </div>
      <div className="setting_rgn_equip_main">
        <EDBoard edbData={edbData} delData={delData} setDelData={setDelData} />
      </div>

      {/* CCTV */}
      <div className="setting_rgn_equip_header">
        <div>CCTV</div>
      </div>
      <div className="setting_rgn_equip_main">
        <CCTV cctvData={cctvData} delData={delData} setDelData={setDelData} />
      </div>
    </>
  );
}

export default Equip;
