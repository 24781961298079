import apiAddress from "../../services/apiAddress";
import axios from "axios";

export function getCCTV() {
  /* CCTV 조회 */
  return axios
    .post(
      apiAddress.equipGet,
      {
        eqId: 0,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      const equip = response.data;
      if (equip.length > 0) {
        const cctv = equip.filter(
          (item) => item.eqType === "CCTV" && item.useYn === "Y"
        );
        if (cctv.length > 0) {
          const promise = [];
          cctv.forEach((cctvItem) => {
            promise.push(getCCTVDetail(cctvItem.eqId));
          });
          return Promise.all(promise).then((cctvDetail) => {
            const cctvTotal = cctvDetail.map((cctvDtl) => {
              const cctvInf = cctv.find((item) => item.eqId === cctvDtl.eqId);
              delete cctvInf.eqId;
              return { ...cctvInf, ...cctvDtl };
            });

            const reservoirEqId = [];
            for (let i = 54; i <= 61; i++) {
              reservoirEqId.push(i);
            }
            const filterCctv = cctvTotal.filter((item) =>
              reservoirEqId.includes(parseInt(item.eqId))
            );
            if (Array.isArray(filterCctv) && filterCctv.length > 0) {
              return filterCctv;
            } else {
              return [];
            }
          });
        } else {
          return [];
        }
      } else {
        return [];
      }
    });
}
/* CCTV + CCTV 통신상태 */
export function getCCTVAndStatus() {
  return getCCTV().then((cctvInf) => {
    if (cctvInf.length !== 0) {
      const promise = cctvInf.map((cctvItem) => {
        return getCCTVStatus(cctvItem).then((result) => ({
          ...cctvItem,
          connect: result,
        }));
      });
      return Promise.all(promise);
    } else {
      return [];
    }
  });
}

/* CCTV 상세정보 조회 */
function getCCTVDetail(eqId) {
  return axios
    .post(
      apiAddress.cctvGet,
      {
        eqId: eqId,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      return response.data[0];
    });
}

/* CCTV 상세정보 저장 */
export function saveCCTV(cctvInform) {
  return axios
    .post(apiAddress.cctvSave, cctvInform, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response.data;
    });
}

/* CCTV 상세정보 삭제 */
export function delCCTV(cctvInfId) {
  return axios.delete(apiAddress.cctvDelete + cctvInfId).then((response) => {
    return response.data;
  });
}

/* CCTV 통신 체크 */
function getCCTVStatus(cctvInf) {
  return axios
    .post(apiAddress.cctvCheck, {
      commAddr: cctvInf.commAddr,
      cctvId: cctvInf.cctvId,
      cctvPwd: cctvInf.cctvPwd,
      webPort: cctvInf.webPort,
    })
    .then((response) => {
      if (response.data.result === "Connect Success") {
        return true;
      }
      return false;
    });
}

export function getCCTVVideo(cctvInf) {
  /* CCTV 영상 */
  return axios
    .post(apiAddress.cctvVideo, cctvInf, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response.data;
    });
}

export function quitCCTVVideo(port) {
  /* CCTV Stream 종료 */
  axios.post(
    apiAddress.cctvQuit,
    { port: port },
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
}

export function ptzCCTV(cctvInf, command) {
  /* CCTV PTZ 제어 */
  axios.post(
    apiAddress.cctvPtz,
    {
      cctv: cctvInf,
      command: command,
    },
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
}
