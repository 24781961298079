import Modal from "react-modal";

Modal.setAppElement("#root");

function RegionInform({ isOpen, setIsOpen }) {
  function onClickClose() {
    setIsOpen({ isOpen: false, data: {} });
  }
  return (
    <Modal isOpen={isOpen.isOpen} style={modalStyles}>
      <div className="modal_title">
        <div>관리 지역 정보</div>
      </div>
      <div className="modal_container">
        <div className="modal_main_column">
          <div className="modal_input">
            <div className="title">관리지역명</div>
            <input
              type="text"
              value={isOpen.data.rgnNm}
              disabled={true}
              spellCheck="false"
            />
          </div>
          <div className="modal_input">
            <div className="title">법정동코드</div>
            <input
              className="bdong_input"
              type="text"
              value={isOpen.data.bdongCd}
              disabled={true}
              spellCheck="false"
            />
            <div className="inner_title">주소</div>
            <input
              type="text"
              value={isOpen.data.addr}
              disabled={true}
              spellCheck="false"
            />
          </div>
          <div
            className="modal_input"
            style={{ flex: "1", borderBottom: "none" }}
          >
            <div className="title">메모</div>
            <textarea
              value={isOpen.data.rgnDtl}
              disabled={true}
              spellCheck="false"
            />
          </div>
        </div>

        <div className="modal_footer">
          <div className="button_nor" onClick={onClickClose}>
            닫기
          </div>
        </div>
      </div>
    </Modal>
  );
}

const modalStyles = {
  content: {
    width: "730px",
    height: "350px",
    padding: "0px",
    background: "#202020",
    border: "none",
    borderRadius: "5px",
    boxShadow: "-5px 5px 10px rgba(0, 0, 0, 0.7)",
    margin: "auto",
  },
  overlay: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "fixed",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: "rgba(0, 0, 0, 0.4)",
  },
};

export default RegionInform;
