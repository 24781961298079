import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setMenu, setSubmenu } from "../../../redux/modules/menu";
import isEqual from "lodash/isEqual";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHome,
  faList,
  faFileLines,
  faBullhorn,
} from "@fortawesome/free-solid-svg-icons";

import { tokenCheck } from "../../../utils/tokenCheck";
import BroadGroup from "../../../components/broadcast/send/BroadGroup";
import BroadRtu from "../../../components/broadcast/send/BroadRtu";
import TextList from "../../../components/broadcast/send/TextList";
import SendInform from "../../../components/broadcast/send/SendInform";
import BroadList from "../../../components/broadcast/send/BroadList";

const PAGE_VALUE = "경보방송";
const SUBPAGE_VALUE = "방송전송";

function BroadcastSend() {
  const dispatch = useDispatch();
  const [isToken, setIsToken] = useState(false);
  const movePage = useNavigate();
  const [listShowType, setListShowType] = useState("group");
  const [selectBrdText, setSelectBrdText] = useState({});
  const [brdText, setBrdText] = useState({
    vol: 10,
    spd: 10,
  });

  function onClickSend() {
    // 방송 송출 버튼 클릭
    // 토큰 확인
    tokenCheck().then((result) => {
      if (!result) {
        alert("인증 토큰이 만료되었습니다. 다시 로그인 해주세요");
        movePage("/");
      } else {
      }
    });
  }
  function onClickPreview() {
    /* 미리 듣기 function. */
    const utterThis = new SpeechSynthesisUtterance(brdText.brdCntn);
    const repUtterThis = new SpeechSynthesisUtterance(
      "다시 한번 말씀드립니다."
    );

    const voices = window.speechSynthesis.getVoices();
    const voice = voices.find((item) => item.lang === "ko-KR");

    utterThis.voice = voice;
    utterThis.pitch = 1.5;
    utterThis.rate = brdText.spd / 10;
    utterThis.volume = brdText.vol / 100;

    repUtterThis.voice = voice;
    repUtterThis.pitch = 1.5;
    repUtterThis.rate = brdText.spd / 10;
    repUtterThis.volume = brdText.vol / 100;

    window.speechSynthesis.speak(utterThis);
    for (let i = 1; i < brdText.brdCnt; i++) {
      console.log(i);
      window.speechSynthesis.speak(repUtterThis);
      window.speechSynthesis.speak(utterThis);
    }
  }

  useEffect(() => {
    dispatch(setMenu(PAGE_VALUE));
    dispatch(setSubmenu(SUBPAGE_VALUE));

    // 토큰 확인
    tokenCheck().then((result) => {
      if (!result) {
        alert("인증 토큰이 만료되었습니다. 다시 로그인 해주세요");
        movePage("/");
      } else {
        setIsToken(true);
      }
    });

    return () => {
      window.speechSynthesis.cancel();
    };
  }, []);

  useEffect(() => {
    if (Object.keys(selectBrdText).length !== 0) {
      setBrdText(selectBrdText);
    }
    window.speechSynthesis.cancel();
  }, [selectBrdText]);
  useEffect(() => {
    if (!isEqual(selectBrdText, brdText)) {
      setSelectBrdText({});
    }
  }, [brdText]);

  return (
    <div className="outlet_container">
      {isToken && (
        <>
          <div className="header_container">
            <div className="title">
              <div className="title_imp">방송전송</div>
            </div>
            <div className="path">
              <FontAwesomeIcon icon={faHome} />
              <div className="text">&gt; 경보방송 &gt; 방송전송</div>
            </div>
          </div>

          {/* Main */}
          <div className="main_container_column">
            <div className="brd_send_top_container">
              {/* 방송장비 리스트 */}
              <div
                className="contents_container"
                style={{
                  width: "30%",
                  height: "100%",
                }}
              >
                <div
                  className="contents_header"
                  style={{ position: "relative" }}
                >
                  <div className="title">
                    <FontAwesomeIcon icon={faList} />
                    <div className="text">방송장비</div>
                  </div>
                  <div className="brd_send_list_select_container">
                    <div
                      className={
                        listShowType === "group"
                          ? "select_item_select"
                          : "select_item"
                      }
                      onClick={() => setListShowType("group")}
                    >
                      그룹
                    </div>
                    <div
                      className={
                        listShowType === "device"
                          ? "select_item_select"
                          : "select_item"
                      }
                      onClick={() => setListShowType("device")}
                    >
                      장비
                    </div>
                  </div>
                </div>
                <div
                  className="contents_main_column"
                  style={{ overflow: "auto" }}
                >
                  {listShowType === "group" ? (
                    <BroadGroup />
                  ) : (
                    listShowType === "device" && <BroadRtu />
                  )}
                </div>
                <div className="contents_footer"></div>
              </div>

              {/* 방송문안 리스트 */}
              <div
                className="contents_container"
                style={{
                  width: "calc(30% - 40px)",
                  height: "100%",
                  margin: "0px 20px",
                }}
              >
                <div className="contents_header">
                  <div className="title">
                    <FontAwesomeIcon icon={faFileLines} />
                    <div className="text">방송문안</div>
                  </div>
                </div>
                <div className="contents_main">
                  <TextList text={selectBrdText} setText={setSelectBrdText} />
                </div>
                <div className="contents_footer"></div>
              </div>
              <div className="contents_container" style={{ flex: "1" }}>
                <div className="contents_header">
                  <div className="title">
                    <FontAwesomeIcon icon={faBullhorn} />
                    <div className="text">방송전송</div>
                    {brdText.brdCntn !== "" &&
                      brdText.hasOwnProperty("brdCntn") && (
                        <div
                          className="button_excel"
                          onClick={onClickPreview}
                          style={{ marginLeft: "15px" }}
                        >
                          미리듣기
                        </div>
                      )}
                  </div>
                  <div
                    className="button_imp"
                    onClick={onClickSend}
                    style={{ marginLeft: "10px" }}
                  >
                    방송송출
                  </div>
                </div>
                <div className="contents_main_column">
                  <SendInform cntnInf={brdText} setCntnInf={setBrdText} />
                </div>
                <div className="contents_footer"></div>
              </div>
            </div>

            {/* 최근 방송 내역 */}
            <div
              className="contents_container"
              style={{
                width: "100%",
                height: "calc(30% - 20px)",
                marginTop: "20px",
              }}
            >
              <div className="contents_header">
                <div className="title">
                  <FontAwesomeIcon icon={faList} />
                  <div className="text">최근 방송 내역</div>
                </div>
              </div>
              <div className="contents_main">
                <BroadList />
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default BroadcastSend;
