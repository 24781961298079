import RainMarkerImg from "../../assets/map/rainMarker.png";
import LevelMarkerImg from "../../assets/map/levelMarker.png";
import SlopeMarkerImg from "../../assets/map/slopeMarker.png";
import DisMarkerImg from "../../assets/map/disMarker.png";
import RtuMarkerImg from "../../assets/map/rtuMarker.png";
import BroadMarkerImg from "../../assets/map/brdMarker.png";
import EdbMarkerImg from "../../assets/map/edbMarker.png";
import CctvMarkerImg from "../../assets/map/cctvMarker.png";

function MapLegend() {
  return (
    <div className="map-legend">
      <div className="legend-title">장비구분</div>
      <img className="legend-marker" src={RtuMarkerImg} alt="" />
      <div className="legend-marker-name">RTU</div>
      <img className="legend-marker" src={BroadMarkerImg} alt="" />
      <div className="legend-marker-name">방송</div>
      <img className="legend-marker" src={EdbMarkerImg} alt="" />
      <div className="legend-marker-name">전광판</div>
      <img className="legend-marker" src={CctvMarkerImg} alt="" />
      <div className="legend-marker-name">CCTV</div>

      <div className="legend-title legend-title-snsr">센서구분</div>
      <img className="legend-marker" src={RainMarkerImg} alt="" />
      <div className="legend-marker-name">강우계</div>
      <img className="legend-marker" src={LevelMarkerImg} alt="" />
      <div className="legend-marker-name">수위계</div>
      <img className="legend-marker" src={SlopeMarkerImg} alt="" />
      <div className="legend-marker-name">경사계</div>
      <img className="legend-marker" src={DisMarkerImg} alt="" />
      <div className="legend-marker-name">변위계</div>
    </div>
  );
}

export default MapLegend;
