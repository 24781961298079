import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  createColumnHelper,
  flexRender,
  useReactTable,
  getCoreRowModel,
  getSortedRowModel,
} from "@tanstack/react-table";
import { Menu, Item, useContextMenu } from "react-contexify";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisVertical } from "@fortawesome/free-solid-svg-icons";

import { tokenCheck } from "../../utils/tokenCheck";
import { dateToFormat } from "../../utils/dateFormat";
import CCTVInform from "../common/inform/CCTVInform";

const MENU_ID = "cctvList";

function CCTVList({
  cctv,
  selectCctv,
  setSelectCctv,
  showCctvs,
  setShowCctvs,
}) {
  const movePage = useNavigate();
  const [isInform, setIsInform] = useState({ isOpen: false, data: {} });
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);
  const columnHelper = createColumnHelper();
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });
  const checkColumn = {
    id: "check",
    accessor: "check",
    header: "",
    size: 20,
    enableSorting: false,
    cell: ({ row }) => (
      <input
        type="checkbox"
        onChange={() => {
          if (showCctvs.find((item) => item === row.original)) {
            const filter = showCctvs.filter((item) => item !== row.original);
            setShowCctvs(filter);
          } else {
            setShowCctvs([...showCctvs, row.original]);
          }
        }}
        checked={showCctvs.find((item) => item === row.original)}
        style={{ cursor: "pointer" }}
      />
    ),
  };
  const menuIconColumn = {
    id: "menuIcon",
    accessor: "menu",
    header: "",
    size: 20,
    enableSorting: false,
    cell: ({ row }) => (
      <FontAwesomeIcon className="table_icon" icon={faEllipsisVertical} />
    ),
  };
  const { show } = useContextMenu({
    id: MENU_ID,
  });

  function onClickRow(row) {
    // 행 클릭시
    // 토큰 확인
    tokenCheck().then((result) => {
      if (!result) {
        alert("인증 토큰이 만료되었습니다. 다시 로그인 해주세요");
        movePage("/");
      } else {
        if (selectCctv === row.original) {
          setSelectCctv({});
        } else {
          setSelectCctv(row.original);
        }
      }
    });
  }
  function onClickMenu(e, row) {
    // 메뉴 클릭 시.
    e.stopPropagation();
    show({ event: e, props: { data: row } });
  }
  function menuItemClick({ id, props }) {
    // 메뉴 Item 클릭 시.
    // 토큰 확인
    tokenCheck().then((result) => {
      if (!result) {
        alert("인증 토큰이 만료되었습니다. 다시 로그인 해주세요");
        movePage("/");
      } else {
        switch (id) {
          // 정보.
          case "inform":
            setIsInform({ isOpen: true, data: props.data });
            break;
          default:
            break;
        }
      }
    });
  }

  useEffect(() => {
    const column = [
      checkColumn,
      columnHelper.accessor("eqNm", {
        header: "CCTV명",
      }),
      columnHelper.accessor(
        (row) => {
          return dateToFormat(row.instlDt);
        },
        {
          id: "instlDt",
          header: "설치일자",
        }
      ),
      columnHelper.accessor("mnfct", {
        header: "제조업체",
      }),
      columnHelper.accessor("modelNm", {
        header: "모델",
      }),
      columnHelper.accessor("commAddr", {
        header: "IP",
      }),
      columnHelper.accessor("webPort", {
        header: "ONVIF Port",
      }),
      columnHelper.accessor("rtspPort", {
        header: "RTSP Port",
      }),
      menuIconColumn,
    ];

    setData(cctv);
    setColumns(column);
  }, [cctv]);
  useEffect(() => {
    const column = [
      checkColumn,
      columnHelper.accessor("eqNm", {
        header: "CCTV명",
      }),
      columnHelper.accessor(
        (row) => {
          return dateToFormat(row.instlDt);
        },
        {
          id: "instlDt",
          header: "설치일자",
        }
      ),
      columnHelper.accessor("mnfct", {
        header: "제조업체",
      }),
      columnHelper.accessor("modelNm", {
        header: "모델",
      }),
      columnHelper.accessor("commAddr", {
        header: "IP",
      }),
      columnHelper.accessor("webPort", {
        header: "ONVIF Port",
      }),
      columnHelper.accessor("rtspPort", {
        header: "RTSP Port",
      }),
      menuIconColumn,
    ];

    setColumns(column);
  }, [showCctvs]);

  return (
    <div id="cctv-list-component">
      {
        // CCTV 정보
        isInform.isOpen && (
          <CCTVInform isOpen={isInform} setIsOpen={setIsInform} />
        )
      }
      {data.length === 0 ? (
        <div className="data-none-container">등록된 CCTV가 없습니다</div>
      ) : (
        <div className="list">
          <table className="react_table">
            <thead>
              {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <th
                      key={header.id}
                      onClick={header.column.getToggleSortingHandler()}
                      style={{
                        width: isNaN(header.getSize())
                          ? "auto"
                          : `${header.getSize()}px`,
                        textAlign:
                          header.id === "eqNm" ||
                          header.id === "mnfct" ||
                          header.id === "commAddr"
                            ? "left"
                            : "center",
                      }}
                    >
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody>
              {table.getRowModel().rows.map((row, index) => (
                <tr
                  key={row.id}
                  className={selectCctv === row.original ? "tr_select" : ""}
                  onClick={() => onClickRow(row)}
                >
                  {row.getVisibleCells().map((cell) => (
                    <td
                      key={cell.id}
                      onClick={(e) => {
                        if (cell.column.id === "check") {
                          e.stopPropagation();
                        }
                        if (cell.column.id === "menuIcon") {
                          onClickMenu(e, row.original);
                        }
                      }}
                      style={{
                        width: isNaN(cell.column.getSize())
                          ? "auto"
                          : `${cell.column.getSize()}px`,
                        textAlign:
                          (cell.column.id === "check" ||
                            cell.column.id === "instlDt" ||
                            cell.column.id === "modelNm" ||
                            cell.column.id === "webPort" ||
                            cell.column.id === "rtspPort" ||
                            cell.column.id === "menuIcon") &&
                          "center",
                      }}
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      {/* Context 메뉴 */}
      <Menu id={MENU_ID}>
        <Item id="inform" onClick={menuItemClick}>
          정보
        </Item>
      </Menu>
    </div>
  );
}

export default CCTVList;
