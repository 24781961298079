import React, { useState, useEffect } from "react";
import {
  ResponsiveContainer,
  LineChart,
  Line,
  Legend,
  XAxis,
  YAxis,
  Tooltip,
  ReferenceLine,
  CartesianGrid,
} from "recharts";

import { DEVICEOPTIONS } from "../../../../constants/deviceOptions";

function SlopeGraph({ selected }) {
  const [data, setData] = useState([]);
  const [minMax, setMinMax] = useState({ min: 999999, max: -999999 });
  const [alertLine, setAlertLine] = useState([]);

  /* AlertLine Custom */
  const AlertCustomLabelUp = (props) => {
    const x = props.viewBox.x;
    const y = props.viewBox.y;
    const value = props.value;
    const color = props.color;
    return (
      <text
        x={x + 5}
        y={y - 5}
        textAnchor="start"
        fill={color}
        fontSize={12}
        fontWeight="bold"
      >
        {value}
      </text>
    );
  };
  const AlertCustomLabelDown = (props) => {
    const x = props.viewBox.x;
    const y = props.viewBox.y;
    const value = props.value;
    const color = props.color;
    return (
      <text
        x={x + 5}
        y={y + 12}
        textAnchor="start"
        fill={color}
        fontSize={12}
        fontWeight="bold"
      >
        {value}
      </text>
    );
  };
  /* Tooltip Custom */
  const TooltipCustom = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      const date =
        payload[0].payload.date === undefined ? "" : payload[0].payload.date;

      return (
        <div className="tooltip-container">
          <div className="date-text">{date}</div>
          <div className="value-container">
            {payload.map((item) => (
              <div key={`value-item-${item.dataKey}`} className="value-item">
                <div
                  className="value-color"
                  style={{ background: item.color }}
                />
                <div className="value-title">{item.dataKey}:</div>
                <div className="value">{item.payload[item.dataKey]}</div>
              </div>
            ))}
          </div>
        </div>
      );
    }
  };

  useEffect(() => {
    /* 데이터가공 & 최대값 찾기 */
    if (Array.isArray(selected.data) && selected.data.length > 0) {
      const selectedData = selected.data;

      let dataMin = 999999;
      let dataMax = -999999;
      const nullData = selectedData.map((dataItem) => {
        const tmpItem = { ...dataItem };
        for (let key in dataItem) {
          if (key !== "date") {
            if (dataItem[key] === "-") {
              tmpItem[key] = null; // "-" → null
            } else {
              const val = parseFloat(dataItem[key]); // 최소/최대값 찾기
              dataMin = val < dataMin ? val : dataMin;
              dataMax = val > dataMax ? val : dataMax;
            }
          }
        }
        return tmpItem;
      });

      setData(nullData);
      setMinMax({ min: Math.round(dataMin - 2), max: Math.round(dataMax + 2) });
    } else {
      setData([]);
      setMinMax({ min: 999999, max: -999999 });
    }

    /* 임계치 */
    if (Array.isArray(selected.dvcData) && selected.dvcData.length > 0) {
      const dvcData = selected.dvcData;

      const alert = dvcData.map((dvcItem) => {
        const alertItem = {
          unit: "degree",
          min: null,
          min2: null,
          min3: null,
          min4: null,
          max: null,
          max2: null,
          max3: null,
          max4: null,
        };

        for (let key in alertItem) {
          if (key !== "unit") {
            const val =
              dvcItem[key].toString() === DEVICEOPTIONS.sensorAnomaly.toString()
                ? null
                : Number(dvcItem[key]);
            alertItem[key] = val;
          }
        }

        return alertItem;
      });

      setAlertLine(alert);
    }
  }, [selected]);

  return (
    <>
      {data.length > 0 && (
        <ResponsiveContainer width="100%" height="90%">
          <LineChart data={data} margin={{ left: -20, right: 20, bottom: -10 }}>
            <CartesianGrid
              stroke="#595959"
              strokeWidth={0.5}
              vertical={false}
            />
            <XAxis dataKey="date" tick={{ fontSize: 11 }} axisLine={false} />
            <YAxis
              type="number"
              domain={[minMax.min, minMax.max]}
              tick={{ fontSize: 11 }}
              tickCount={10}
              axisLine={false}
            />
            <Tooltip
              content={<TooltipCustom />}
              filterNull={true}
              cursor={{
                stroke: "#757575",
                strokeWidth: 1,
                strokeDasharray: "3 3",
              }}
            />

            <Line
              type="monotone"
              dataKey="x"
              stroke="#0B8A31"
              strokeWidth={2}
              dot={false}
              activeDot={{ r: 4 }}
            />
            <Line
              type="monotone"
              dataKey="y"
              stroke="#CA09D5"
              strokeWidth={2}
              dot={false}
              activeDot={{ r: 4 }}
            />

            {alertLine.length > 0 &&
              alertLine.map((alertItem, index) => (
                <React.Fragment key={`${index}-alert`}>
                  {/* 최소 */}
                  <ReferenceLine
                    y={alertItem.min}
                    label={
                      <AlertCustomLabelDown
                        value={`관심(${alertItem.min}) ${alertItem.unit}`}
                        color="#0F80B1"
                      />
                    }
                    stroke="#006B99"
                    strokeDasharray="7 4"
                    strokeWidth={1}
                  />
                  <ReferenceLine
                    y={alertItem.min2}
                    label={
                      <AlertCustomLabelDown
                        value={`주의(${alertItem.min2}) ${alertItem.unit}`}
                        color="#A49506"
                      />
                    }
                    stroke="#928400"
                    strokeDasharray="7 4"
                    strokeWidth={1}
                  />
                  <ReferenceLine
                    y={alertItem.min3}
                    label={
                      <AlertCustomLabelDown
                        value={`경계(${alertItem.min3}) ${alertItem.unit}`}
                        color="#B9640A"
                      />
                    }
                    stroke="#A15300"
                    strokeDasharray="7 4"
                    strokeWidth={1}
                  />
                  <ReferenceLine
                    y={alertItem.min4}
                    label={
                      <AlertCustomLabelDown
                        value={`심각(${alertItem.min4}) ${alertItem.unit}`}
                        color="#A00B0B"
                      />
                    }
                    stroke="#8F0303"
                    strokeDasharray="7 4"
                    strokeWidth={1}
                  />
                  {/* 최대 */}
                  <ReferenceLine
                    y={alertItem.max}
                    label={
                      <AlertCustomLabelUp
                        value={`관심(${alertItem.max}) ${alertItem.unit}`}
                        color="#0F80B1"
                      />
                    }
                    stroke="#006B99"
                    strokeDasharray="7 4"
                    strokeWidth={1}
                  />
                  <ReferenceLine
                    y={alertItem.max2}
                    label={
                      <AlertCustomLabelUp
                        value={`주의(${alertItem.max2}) ${alertItem.unit}`}
                        color="#A49506"
                      />
                    }
                    stroke="#928400"
                    strokeDasharray="7 4"
                    strokeWidth={1}
                  />
                  <ReferenceLine
                    y={alertItem.max3}
                    label={
                      <AlertCustomLabelUp
                        value={`경계(${alertItem.max3}) ${alertItem.unit}`}
                        color="#B9640A"
                      />
                    }
                    stroke="#A15300"
                    strokeDasharray="7 4"
                    strokeWidth={1}
                  />
                  <ReferenceLine
                    y={alertItem.max4}
                    label={
                      <AlertCustomLabelUp
                        value={`심각(${alertItem.max4}) ${alertItem.unit}`}
                        color="#A00B0B"
                      />
                    }
                    stroke="#8F0303"
                    strokeDasharray="7 4"
                    strokeWidth={1}
                  />
                </React.Fragment>
              ))}
          </LineChart>
        </ResponsiveContainer>
      )}
    </>
  );
}

export default SlopeGraph;
