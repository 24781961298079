import apiAddress from "../../services/apiAddress";
import axios from "axios";

export function saveEquip(eqInform) {
  const tokenInform = localStorage.getItem("tokenInform");
  const tokenInformParse = JSON.parse(tokenInform);

  return axios
    .post(
      apiAddress.equipSave,
      { ...eqInform, siteId: tokenInformParse.siteId },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      return response.data;
    });
}
