import { useState, useEffect } from "react";
import {
  createColumnHelper,
  flexRender,
  useReactTable,
  getCoreRowModel,
  getSortedRowModel,
} from "@tanstack/react-table";

function EdboardList({ edbData, selected, setSelected }) {
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);
  const columnHelper = createColumnHelper();
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  useEffect(() => {
    if (Array.isArray(edbData) && edbData.length > 0) {
      const column = [
        columnHelper.accessor("eqNm", { header: "전광판명", size: 150 }),
        columnHelper.accessor(
          (row) => {
            const msgList = row.msgList;
            if (
              msgList.length > 0 &&
              msgList.find((item) => item.useYn === "Y")
            ) {
              return "작동중";
            } else {
              return "작동안함";
            }
          },
          { id: "status", header: "상태", size: 70 }
        ),
      ];

      setData(edbData);
      setColumns(column);
    } else {
      setData([]);
      setColumns([]);
    }
  }, [edbData]);

  return (
    <div className="edboard-list-contents">
      <div className="list">
        <table className="react_table">
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th
                    key={header.id}
                    onClick={header.column.getToggleSortingHandler()}
                    style={{
                      width: isNaN(header.getSize())
                        ? "auto"
                        : `${header.getSize()}px`,
                      minWidth: isNaN(header.getSize())
                        ? "auto"
                        : `${header.getSize()}px`,
                      maxWidth: isNaN(header.getSize())
                        ? "auto"
                        : `${header.getSize()}px`,
                    }}
                  >
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row, index) => (
              <tr
                key={row.id}
                className={
                  selected === row.original
                    ? "tr_select"
                    : index % 2 === 0
                    ? "tr_even"
                    : "tr_odd"
                }
                onClick={() => setSelected(row.original)}
              >
                {row.getVisibleCells().map((cell) => (
                  <td
                    key={cell.id}
                    style={{
                      width: isNaN(cell.column.getSize())
                        ? "auto"
                        : `${cell.column.getSize()}px`,
                      minWidth: isNaN(cell.column.getSize())
                        ? "auto"
                        : `${cell.column.getSize()}px`,
                      maxWidth: isNaN(cell.column.getSize())
                        ? "auto"
                        : `${cell.column.getSize()}px`,
                      textAlign: cell.column.id === "status" && "center",
                      color:
                        cell.column.id === "status" &&
                        row.original.msgList.length > 0 &&
                        row.original.msgList.find(
                          (item) => item.useYn === "Y"
                        ) &&
                        "#3DC62A",
                    }}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default EdboardList;
