import { useState, useEffect } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

import { getTermCondition } from "../../services/internal/signAPI";

function TermsCondition({ setPage, isAgree, setIsAgree }) {
  const [termCondition, setTermCondition] = useState("");

  useEffect(() => {
    getTermCondition().then((data) => {
      setTermCondition(data.stndArtIncorp);
    });
  }, []);

  return (
    <div id="sign-term-condition-component">
      <div className="component-title">이용 약관</div>

      <div className="component-main term-condition-container">
        <div className="notice">
          ※ 이용 약관에 동의하셔야 계정 등록 신청을 하실 수 있습니다
        </div>
        <textarea value={termCondition} disabled={false} readOnly={true} />
        <div className="accept">
          <input
            type="checkbox"
            id="accept"
            onChange={() => setIsAgree(!isAgree)}
            checked={isAgree}
          />
          <label htmlFor="accept">위 약관에 동의합니다</label>
        </div>
      </div>

      <div className="button-container">
        {isAgree === true && (
          <div className="arrow-button" onClick={() => setPage(2)}>
            <div className="text">다음</div>
            <FontAwesomeIcon className="arrow" icon={faArrowRight} />
          </div>
        )}
      </div>
    </div>
  );
}

export default TermsCondition;
