import { useState, useEffect } from "react";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";

const effects = [
  { value: "DDD", text: "딩동댕" },
  { value: "SIR", text: "사이렌" },
];
const iterNum = [
  { value: 1, text: "1회" },
  { value: 2, text: "2회" },
  { value: 3, text: "3회" },
  { value: 4, text: "4회" },
  { value: 5, text: "5회" },
];

function SendInform({ cntnInf, setCntnInf }) {
  // 볼륨, 속도.
  const vol = [];
  const spd = [];
  for (let i = 0; i <= 100; i = i + 10) {
    vol.push(
      <option key={"vol" + i} value={i}>
        {i}
      </option>
    );
  }
  for (let i = 1; i <= 100; i++) {
    spd.push(
      <option key={"spd" + i} value={i}>
        {i}
      </option>
    );
  }

  return (
    <>
      <div className="brd_send_input">
        <div className="title">방송제목</div>
        <input
          type="text"
          placeholder="제목 입력"
          value={cntnInf.brdTitle}
          onChange={(e) => {
            setCntnInf({ ...cntnInf, brdTitle: e.target.value });
          }}
          spellCheck={false}
        />
      </div>
      <div className="brd_send_input">
        <div className="title">방송전효과음</div>
        <select
          value={cntnInf.sttEfc}
          onChange={(e) => {
            setCntnInf({ ...cntnInf, sttEfc: e.target.value });
          }}
        >
          {effects.map((item) => (
            <option key={item.value} value={item.value}>
              {item.text}
            </option>
          ))}
        </select>
        <div className="inner_title">반복횟수</div>
        <select
          value={cntnInf.sttEfcCnt}
          onChange={(e) => {
            setCntnInf({ ...cntnInf, sttEfcCnt: e.target.value });
          }}
        >
          {iterNum.map((item) => (
            <option key={item.value} value={item.value}>
              {item.text}
            </option>
          ))}
        </select>
      </div>
      <div className="brd_send_input" style={{ flex: "1" }}>
        <div className="title">방송내용</div>
        <textarea
          placeholder="내용 입력"
          value={cntnInf.brdCntn}
          onChange={(e) => {
            setCntnInf({ ...cntnInf, brdCntn: e.target.value });
          }}
          spellCheck={false}
        />
      </div>
      <div className="brd_send_input">
        <div className="title">방송횟수</div>
        <select
          value={cntnInf.brdCnt}
          onChange={(e) => {
            setCntnInf({ ...cntnInf, brdCnt: e.target.value });
          }}
        >
          {iterNum.map((item) => (
            <option key={item.value} value={item.value}>
              {item.text}
            </option>
          ))}
        </select>
      </div>
      <div className="brd_send_input">
        <div className="title">방송후효과음</div>
        <select
          value={cntnInf.endEfc}
          onChange={(e) => setCntnInf({ ...cntnInf, endEfc: e.target.value })}
        >
          {effects.map((item) => (
            <option key={item.value} value={item.value}>
              {item.text}
            </option>
          ))}
        </select>
        <div className="inner_title">반복횟수</div>
        <select
          value={cntnInf.endEfcCnt}
          onChange={(e) =>
            setCntnInf({ ...cntnInf, endEfcCnt: e.target.value })
          }
        >
          {iterNum.map((item) => (
            <option key={item.value} value={item.value}>
              {item.text}
            </option>
          ))}
        </select>
      </div>
      <div className="brd_send_input">
        <div className="title">볼륨</div>
        <select
          className="brd_vol_spd"
          value={cntnInf.vol}
          onChange={(e) => setCntnInf({ ...cntnInf, vol: e.target.value })}
        >
          {vol}
        </select>
        <div className="brd_send_slider_container">
          <Slider
            className="brd_slider"
            min={0}
            max={100}
            step={10}
            value={cntnInf.vol}
            onChange={(value) => setCntnInf({ ...cntnInf, vol: value })}
            trackStyle={{ background: "#418dff", height: 10 }}
            handleStyle={{ borderColor: "#000" }}
          />
        </div>
      </div>
      <div className="brd_send_input" style={{ borderBottom: "none" }}>
        <div className="title">속도</div>
        <select
          className="brd_vol_spd"
          value={cntnInf.spd}
          onChange={(e) => setCntnInf({ ...cntnInf, spd: e.target.value })}
        >
          {spd}
        </select>
        <div className="brd_send_slider_container">
          <Slider
            className="brd_slider"
            min={1}
            max={100}
            step={1}
            value={cntnInf.spd}
            onChange={(value) => setCntnInf({ ...cntnInf, spd: value })}
            trackStyle={{ background: "#418dff" }}
            handleStyle={{ borderColor: "#000" }}
          />
        </div>
      </div>
    </>
  );
}

export default SendInform;
