import { useState, useEffect } from "react";

import RainGraph from "./rsv-graph/RainGraph";
import LevelGraph from "./rsv-graph/LevelGraph";
import GroundGraph from "./rsv-graph/GroundGraph";
import DisGraph from "./rsv-graph/DisGraph";

function Graph({ selected }) {
  const [dvcType, setDvcType] = useState("");

  useEffect(() => {
    if (Object.keys(selected).length > 0) {
      const checkTarget = selected.dvcData[0];
      setDvcType(checkTarget.dvcDtlType);
    }
  }, [selected]);

  return (
    <div className="graph-container">
      {selected.data === 0 ? (
        <div className="data-none-container">금일 데이터 내역이 없습니다</div>
      ) : dvcType === "SNSR01" ? (
        <RainGraph selected={selected} />
      ) : dvcType === "SNSR02" ? (
        <LevelGraph Graph selected={selected} />
      ) : dvcType === "SNSR07" ? (
        <GroundGraph selected={selected} />
      ) : (
        dvcType === "SNSR15" && <DisGraph selected={selected} />
      )}
    </div>
  );
}

export default Graph;
