import { useState, useEffect } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faCaretUp } from "@fortawesome/free-solid-svg-icons";

import List from "./sensor-list/List";

function SensorList({ rtus, selected, setSelected }) {
  const [rainRtu, setRainRtu] = useState([]);
  const [levelRtu, setLevelRtu] = useState([]);
  const [groundRtu, setGroundRtu] = useState([]);
  const [disRtu, setDisRtu] = useState([]);
  const [isRainHold, setIsRainHold] = useState(false); // 리스트 펼치기/접기
  const [isLevelHold, setIsLevelHold] = useState(false);
  const [isGroundHold, setIsGroundHold] = useState(false);
  const [isDisHold, setIsDisHold] = useState(false);

  function onChangeCheck(e, rtus) {
    // checkbox 이벤트
    if (rtus.length !== 0) {
      const type = e.target.value;
      if (selected[type].length !== rtus.length) {
        setSelected({ ...selected, [type]: rtus });
      } else {
        setSelected({ ...selected, [type]: [] });
      }
    }
  }

  useEffect(() => {
    if (Array.isArray(rtus.rain) && rtus.rain.length > 0) {
      setRainRtu(rtus.rain);
    }
    if (Array.isArray(rtus.level) && rtus.level.length > 0) {
      setLevelRtu(rtus.level);
    }
    if (Array.isArray(rtus.ground) && rtus.ground.length > 0) {
      setGroundRtu(rtus.ground);
    }
    if (Array.isArray(rtus.dis) && rtus.dis.length > 0) {
      setDisRtu(rtus.dis);
    }
  }, [rtus]);

  return (
    <div id="data-sensor-grouping-sensor-component">
      <div className="list">
        {/* 강우계 */}
        <div className="sensor-li" onClick={() => setIsRainHold(!isRainHold)}>
          <div className="sensor">
            <input
              type="checkbox"
              value="rain"
              onChange={(e) => onChangeCheck(e, rainRtu)}
              checked={
                rainRtu.length !== 0 && selected.rain.length === rainRtu.length
              }
              onClick={(e) => e.stopPropagation()}
            />
            <div className="name">강우계</div>
            <div className="count">{`(${rainRtu.length}개)`}</div>
          </div>
          <FontAwesomeIcon icon={isRainHold ? faCaretUp : faCaretDown} />
        </div>
        {rainRtu.length > 0 && isRainHold && (
          <List
            type="rain"
            rtus={rainRtu}
            selected={selected}
            setSelected={setSelected}
          />
        )}
        {/* 수위계 */}
        <div className="sensor-li" onClick={() => setIsLevelHold(!isLevelHold)}>
          <div className="sensor">
            <input
              type="checkbox"
              value="level"
              onChange={(e) => onChangeCheck(e, levelRtu)}
              checked={
                levelRtu.length !== 0 &&
                selected.level.length === levelRtu.length
              }
              onClick={(e) => e.stopPropagation()}
            />
            <div className="name">수위계</div>
            <div className="count">{`(${levelRtu.length}개)`}</div>
          </div>
          <FontAwesomeIcon icon={isLevelHold ? faCaretUp : faCaretDown} />
        </div>
        {levelRtu.length > 0 && isLevelHold && (
          <List
            type="level"
            rtus={levelRtu}
            selected={selected}
            setSelected={setSelected}
          />
        )}
        {/* 지하수위계 */}
        <div
          className="sensor-li"
          onClick={() => setIsGroundHold(!isGroundHold)}
        >
          <div className="sensor">
            <input
              type="checkbox"
              value="ground"
              onChange={(e) => onChangeCheck(e, groundRtu)}
              checked={
                groundRtu.length !== 0 &&
                selected.ground.length === groundRtu.length
              }
              onClick={(e) => e.stopPropagation()}
            />
            <div className="name">지하수위계</div>
            <div className="count">{`(${groundRtu.length}개)`}</div>
          </div>
          <FontAwesomeIcon icon={isGroundHold ? faCaretUp : faCaretDown} />
        </div>
        {groundRtu.length > 0 && isGroundHold && (
          <List
            type="ground"
            rtus={groundRtu}
            selected={selected}
            setSelected={setSelected}
          />
        )}
        {/* 변위계 */}
        <div className="sensor-li" onClick={() => setIsDisHold(!isDisHold)}>
          <div className="sensor">
            <input
              type="checkbox"
              value="dis"
              onChange={(e) => onChangeCheck(e, disRtu)}
              checked={
                disRtu.length !== 0 && selected.dis.length === disRtu.length
              }
              onClick={(e) => e.stopPropagation()}
            />
            <div className="name">지표변위계</div>
            <div className="count">{`(${disRtu.length}개)`}</div>
          </div>
          <FontAwesomeIcon icon={isDisHold ? faCaretUp : faCaretDown} />
        </div>
        {disRtu.length > 0 && isDisHold && (
          <List
            type="dis"
            rtus={disRtu}
            selected={selected}
            setSelected={setSelected}
          />
        )}
      </div>
    </div>
  );
}

export default SensorList;
