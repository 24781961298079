import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  createColumnHelper,
  flexRender,
  useReactTable,
  getCoreRowModel,
  getSortedRowModel,
} from "@tanstack/react-table";
import { Menu, Item, useContextMenu } from "react-contexify";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisVertical } from "@fortawesome/free-solid-svg-icons";

import { tokenCheck } from "../../../utils/tokenCheck";
import { dateToFormat } from "../../../utils/dateFormat";
import { getRegionEquip } from "../../../services/internal/regionAPI";
import { saveEquip } from "../../../services/internal/equipAPI";
import EdboardInform from "../../common/inform/EdboardInform";
import EdboardEdit from "./EdboardEdit";
import Delete from "../../common/window/Delete";
import Alert from "../../common/window/Alert";

const MENU_ID = "edboardList";

function EdboardList({ edbData, setIsChange }) {
  const movePage = useNavigate();
  const [isInform, setIsInform] = useState({ isOpen: false, data: {} });
  const [isEdit, setIsEdit] = useState({ isOpen: false, data: {} });
  const [isDel, setIsDel] = useState({ isOpen: false, text: "", data: {} });
  const [isRegionAlert, setIsRegionAlert] = useState(false);
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);
  const columnHelper = createColumnHelper();
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });
  const menuIconColumn = {
    id: "menuIcon",
    accessor: "menu",
    header: "",
    size: 20,
    enableSorting: false,
    cell: ({ row }) => (
      <FontAwesomeIcon className="table_icon" icon={faEllipsisVertical} />
    ),
  };
  const { show } = useContextMenu({
    id: MENU_ID,
  });

  function onClickMenu(e, row) {
    // 메뉴 클릭 시.
    e.stopPropagation();
    show({ event: e, props: { data: row } });
  }
  function menuItemClick({ id, props }) {
    // 메뉴 Item 클릭 시.
    // 토큰 확인
    tokenCheck().then((result) => {
      if (!result) {
        alert("인증 토큰이 만료되었습니다. 다시 로그인 해주세요");
        movePage("/");
      } else {
        switch (id) {
          // 정보.
          case "inform":
            setIsInform({ isOpen: true, data: props.data });
            break;
          case "edit":
            setIsEdit({ isOpen: true, data: props.data });
            break;
          case "delete":
            edbDelCheck(props.data);
            break;
          default:
            break;
        }
      }
    });
  }
  function edbDelCheck(data) {
    // 전광판 삭제 가능 여부 조회
    getRegionEquip().then((response) => {
      if (!response.find((item) => item.eqId === data.eqId)) {
        // 관리지역에 등록되어 있음
        setIsRegionAlert(true);
      } else {
        const tokenInform = localStorage.getItem("tokenInform");
        const tokenInformParse = JSON.parse(tokenInform);
        const delData = {
          ...data,
          modUsr: tokenInformParse.userId,
          useYn: "D",
        };
        setIsDel({
          isOpen: true,
          text: `'${data.eqNm}' 전광판을 삭제하시겠습니까?`,
          data: delData,
        });
      }
    });
  }
  function deleteEdboard(data) {
    saveEquip(data).then((response) => {
      setIsChange(true);
    });
  }

  useEffect(() => {
    if (Array.isArray(edbData) && edbData.length !== 0) {
      const column = [
        columnHelper.accessor("eqNm", { header: "전광판명", size: 150 }),
        columnHelper.accessor(
          (row) => {
            return dateToFormat(row.instlDt);
          },
          { id: "instlDt", header: "설치일자", size: 50 }
        ),
        columnHelper.accessor(
          (row) => {
            const mnfct = row.mnfct === "" ? "-" : row.mnfct;
            return mnfct;
          },
          { id: "mnfct", header: "제조업체" }
        ),
        columnHelper.accessor(
          (row) => {
            const modelNm = row.modelNm === "" ? "-" : row.modelNm;
            return modelNm;
          },
          { id: "modelNm", header: "모델명" }
        ),
        columnHelper.accessor("commAddr", { header: "IP", size: 80 }),
        columnHelper.accessor("commPort", { header: "Port", size: 40 }),
        columnHelper.accessor(
          (row) => {
            const gateway = row.gateway === "" ? "-" : row.gateway;
            return gateway;
          },
          { id: "gateway", header: "Gateway", size: 80 }
        ),
        columnHelper.accessor(
          (row) => {
            const netmask = row.gateway === "" ? "-" : row.netmask;
            return netmask;
          },
          { id: "netmask", header: "Netmask", size: 80 }
        ),
        columnHelper.accessor("bdongCd", { header: "법정동코드", size: 50 }),
        columnHelper.accessor("addr", { header: "주소", size: 200 }),
        menuIconColumn,
      ];

      setData(edbData);
      setColumns(column);
    }
  }, [edbData]);

  return (
    <div id="setting-edboard-list-component">
      {
        // 전광판 정보
        isInform.isOpen && (
          <EdboardInform isOpen={isInform} setIsOpen={setIsInform} />
        )
      }
      {
        // 전광판 수정
        isEdit.isOpen && (
          <EdboardEdit
            isOpen={isEdit}
            setIsOpen={setIsEdit}
            setIsChange={setIsChange}
          />
        )
      }
      {
        // 등록되어 있는 지역 존재, 전광판 삭제 불가
        isRegionAlert && (
          <Alert
            isOpen={isRegionAlert}
            setIsOpen={setIsRegionAlert}
            text="먼저 등록되어 있는 지역에서부터 삭제해주세요"
          />
        )
      }
      {
        // 전광판 삭제
        isDel.isOpen && (
          <Delete
            isOpen={isDel}
            setIsOpen={setIsDel}
            delData={() => deleteEdboard(isDel.data)}
          />
        )
      }
      {data.length === 0 ? (
        <div className="setting-data-none-container">
          등록된 전광판이 없습니다
        </div>
      ) : (
        <div className="list">
          <table className="react_table">
            <thead>
              {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <th
                      key={header.id}
                      onClick={header.column.getToggleSortingHandler()}
                      style={{
                        width: isNaN(header.getSize())
                          ? "auto"
                          : `${header.getSize()}px`,
                        textAlign:
                          header.id === "eqNm" ||
                          header.id === "mnfct" ||
                          header.id === "commAddr" ||
                          header.id === "addr"
                            ? "left"
                            : "center",
                      }}
                    >
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody>
              {table.getRowModel().rows.map((row, index) => (
                <tr key={row.id}>
                  {row.getVisibleCells().map((cell) => (
                    <td
                      key={cell.id}
                      onClick={(e) => {
                        if (cell.column.id === "menuIcon") {
                          onClickMenu(e, row.original);
                        }
                      }}
                      style={{
                        width: isNaN(cell.column.getSize())
                          ? "auto"
                          : `${cell.column.getSize()}px`,
                        textAlign:
                          (cell.column.id === "instlDt" ||
                            cell.column.id === "modelNm" ||
                            cell.column.id === "commPort" ||
                            cell.column.id === "bdongCd" ||
                            cell.column.id === "menuIcon") &&
                          "center",
                      }}
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      {/* Context 메뉴 */}
      <Menu id={MENU_ID}>
        <Item id="inform" onClick={menuItemClick}>
          정보
        </Item>
        <Item id="edit" onClick={menuItemClick}>
          수정
        </Item>
        <Item id="delete" onClick={menuItemClick}>
          삭제
        </Item>
      </Menu>
    </div>
  );
}

export default EdboardList;
