import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { setMenu, setSubmenu } from "../../../redux/modules/menu";
import { useNavigate } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome, faList } from "@fortawesome/free-solid-svg-icons";

import { tokenCheck } from "../../../utils/tokenCheck";
import { getCCTV } from "../../../services/internal/cctvAPI";
import Loading from "../../../components/common/window/Loading";
import CCTVAdd from "../../../components/setting/cctv/CCTVAdd";
import CCTVList from "../../../components/setting/cctv/CCTVList";

const PAGE_VALUE = "설정";
const SUBPAGE_VALUE = "CCTV";

function SettingCCTV() {
  const dispatch = useDispatch();
  const [isToken, setIsToken] = useState(false);
  const movePage = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [cctvData, setCctvData] = useState([]);
  const [isAdd, setIsAdd] = useState(false);
  const [isChange, setIsChange] = useState(false);

  function onClickAdd() {
    // CCTV 등록 버튼 클릭시
    setIsAdd(true);
  }

  useEffect(() => {
    dispatch(setMenu(PAGE_VALUE));
    dispatch(setSubmenu(SUBPAGE_VALUE));

    // 토큰 확인
    tokenCheck().then((result) => {
      if (!result) {
        alert("인증 토큰이 만료되었습니다. 다시 로그인 해주세요");
        movePage("/");
      } else {
        setIsToken(true);
        setIsLoading(true);
        getCCTV().then((response) => {
          setIsLoading(false);
          setCctvData(response);
        });
      }
    });
  }, []);
  useEffect(() => {
    if (isChange) {
      setIsLoading(true);
      getCCTV().then((response) => {
        setIsLoading(false);
        setCctvData(response);
      });

      setIsChange(false);
    }
  }, [isChange]);

  return (
    <div className="outlet_container">
      {
        // 로딩 화면
        isLoading && <Loading isOpen={isLoading} />
      }
      {
        // CCTV 등록
        isAdd && (
          <CCTVAdd
            isOpen={isAdd}
            setIsOpen={setIsAdd}
            setIsChange={setIsChange}
          />
        )
      }
      {isToken && (
        <div id="setting-cctv-page">
          <div className="header_container">
            <div className="title">
              <div className="title_imp">CCTV</div>
              <div className="title_nor">설정</div>
            </div>
            <div className="path">
              <FontAwesomeIcon icon={faHome} />
              <div className="text">&gt; 설정 &gt; CCTV</div>
            </div>
          </div>

          <div className="main_container">
            <div className="setting-cctv-container contents_container">
              <div className="contents_header">
                <div className="title">
                  <FontAwesomeIcon icon={faList} />
                  <div className="text">CCTV</div>
                  <div
                    className="button_imp cctv-add-button"
                    onClick={onClickAdd}
                  >
                    CCTV 등록
                  </div>
                </div>
              </div>

              <div className="contents_main">
                <CCTVList cctvData={cctvData} setIsChange={setIsChange} />
              </div>

              <div className="contents_footer"></div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default SettingCCTV;
