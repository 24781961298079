import { useState, useEffect } from "react";
import { Outlet, Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Menu, Item, useContextMenu } from "react-contexify";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUser,
  faArrowRightFromBracket,
} from "@fortawesome/free-solid-svg-icons";

import { sampleData } from "../constants/data/sampleData";
import { getMenu } from "../services/internal/loginAPI";
import { getUser } from "../services/internal/userAPI";
import logoImg from "../assets/common/logoLayoutImg.png";
import Confirm from "./common/window/Confirm";
import ProfileInform from "./layout/ProfileInform";
import ProfileEdit from "./layout/ProfileEdit";
import SuccessAlert from "./common/window/SuccessAlert";
import LayoutTime from "./LayoutTime";

const MENU_ID = "profileList";

function Layout() {
  const currMenu = useSelector((state) => state.menu.menu);
  const currSubmenu = useSelector((state) => state.menu.submenu);
  const [menuAuth, setMenuAuth] = useState([]);
  const [menuHover, setMenuHover] = useState("none");
  const [isProfile, setIsProfile] = useState({
    isOpen: false,
    data: {},
  });
  const [isProfileEdit, setIsProfileEdit] = useState({
    isOpen: false,
    data: {},
  });
  const [isProfileEditSuccess, setIsProfileEditSuccess] = useState(false);
  const [isLogout, setIsLogout] = useState({
    isOpen: false,
    text: "",
  });
  const movePage = useNavigate();
  const { show } = useContextMenu({
    id: MENU_ID,
  });

  function onClickMenu(e) {
    // 메뉴 클릭 시.
    e.stopPropagation();
    show({ event: e, position: { x: e.clientX - 50, y: e.clientY + 10 } });
  }
  function menuItemClick({ id, props }) {
    // 메뉴 Item 클릭 시.
    // 토큰 확인
    switch (id) {
      // 정보.
      case "inform":
        getUser(0).then((response) => {
          const tokenInform = localStorage.getItem("tokenInform");
          const tokenInformParse = JSON.parse(tokenInform);
          const userInf = response.content.find(
            (item) => item.userId === tokenInformParse.userId
          );
          setIsProfile({ isOpen: true, data: userInf });
        });
        break;
      case "edit":
        getUser(0).then((response) => {
          const tokenInform = localStorage.getItem("tokenInform");
          const tokenInformParse = JSON.parse(tokenInform);
          const userInf = response.content.find(
            (item) => item.userId === tokenInformParse.userId
          );
          setIsProfileEdit({ isOpen: true, data: userInf });
        });
        break;
      default:
        break;
    }
  }

  function onClickLogout() {
    // 로그아웃 버튼 클릭 시.
    setIsLogout({
      isOpen: true,
      text: "로그아웃 하시겠습니까?",
    });
  }
  function logout() {
    // 로그아웃.
    localStorage.clear();
    movePage("/");
  }

  useEffect(() => {
    // 토큰 확인
    const tokenInform = localStorage.getItem("tokenInform");
    getMenu(tokenInform).then((response) => {
      setMenuAuth(sampleData.menu);
      // setMenuAuth(response.data);
    });
  }, []);
  useEffect(() => {
    if (isProfileEditSuccess) {
      const tokenInform = localStorage.getItem("tokenInform");
      getMenu(tokenInform).then((menuData) => {
        setMenuAuth(sampleData.menu);
        // setMenuAuth(menuData.data);
        if (Array.isArray(menuData.data) && menuData.data.length !== 0) {
          menuData.data.sort((a, b) => a.menuId - b.menuId);
          const menuUrl = menuData.data[0].linkUrl;
          movePage(menuUrl, { replace: true });
        }
        setIsProfileEditSuccess(false);
      });
    }
  }, [isProfileEditSuccess]);

  return (
    <div id="layout">
      {
        // 프로필
        isProfile.isOpen && (
          <ProfileInform isOpen={isProfile} setIsOpen={setIsProfile} />
        )
      }
      {
        // 프로필 수정
        isProfileEdit.isOpen && (
          <ProfileEdit
            isOpen={isProfileEdit}
            setIsOpen={setIsProfileEdit}
            setIsSuccess={setIsProfileEditSuccess}
          />
        )
      }
      {
        // 프로필 수정 성공
        isProfileEditSuccess && (
          <SuccessAlert
            isOpen={isProfileEditSuccess}
            setIsOpen={setIsProfileEditSuccess}
            text="정보 수정에 성공하였습니다"
          />
        )
      }
      {
        // 로그아웃 확인
        isLogout.isOpen && (
          <Confirm isOpen={isLogout} setIsOpen={setIsLogout} active={logout} />
        )
      }
      {/* Header */}
      <header>
        <div className="logo-menu">
          {/* 로고 */}
          <Link to="/allstatus" className="logo">
            <img src={logoImg} alt="" />
          </Link>

          {/* 메뉴 */}
          <div className="menu-container">
            {Array.isArray(menuAuth) &&
              menuAuth.length !== 0 &&
              menuAuth.map((menuItem) => (
                <div
                  key={menuItem.menuId}
                  className="menu-item-container"
                  onMouseOver={() => setMenuHover(menuItem.menuId)}
                  onMouseOut={() => setMenuHover("none")}
                >
                  <Link
                    to={menuItem.linkUrl}
                    className={
                      menuHover === menuItem.menuId ||
                      currMenu === menuItem.menuNm
                        ? "menu-item menu-item-select"
                        : "menu-item"
                    }
                  >
                    {menuItem.menuNm}
                  </Link>
                  <div className="submenu-item-container">
                    {menuHover === menuItem.menuId &&
                      Array.isArray(menuItem.menuList) &&
                      menuItem.menuList.length !== 0 &&
                      menuItem.menuList.map((submenuItem) => (
                        <Link
                          key={submenuItem.menuId}
                          to={submenuItem.linkUrl}
                          className={
                            currMenu === menuItem.menuNm &&
                            currSubmenu === submenuItem.menuNm
                              ? "submenu-item submenu-item-select"
                              : "submenu-item"
                          }
                        >
                          {submenuItem.menuNm}
                        </Link>
                      ))}
                  </div>
                </div>
              ))}
          </div>
        </div>

        {/* 시간 & 로그아웃 & 프로필 */}
        <div className="time-profile-logout">
          <LayoutTime />
          <FontAwesomeIcon
            className="profile"
            icon={faUser}
            onClick={onClickMenu}
          />
          <FontAwesomeIcon
            className="logout"
            icon={faArrowRightFromBracket}
            onClick={onClickLogout}
          />
        </div>
      </header>

      {/* Main */}
      <main>
        <Outlet id="outlet" />
      </main>

      {/* Footer */}
      <footer>
        <div>Copyright2023. Ahjoo Engineering Inc. All rights reserved.</div>
      </footer>
      {/* Context 메뉴 */}
      <Menu id={MENU_ID}>
        <Item id="inform" onClick={menuItemClick}>
          내 정보
        </Item>
        <Item id="edit" onClick={menuItemClick}>
          내 정보 수정
        </Item>
      </Menu>
    </div>
  );
}

export default Layout;
