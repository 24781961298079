import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { setMenu, setSubmenu } from "../../../redux/modules/menu";
import { useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import locale from "../../../data/datepicker";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome, faDatabase, faPrint } from "@fortawesome/free-solid-svg-icons";

import { tokenCheck } from "../../../utils/tokenCheck";
import { STATISTICALOPTIONS } from "../../../constants/statisticalOptions";
import { DATEPICKERSTYLES } from "../../../constants/datepickerStyles";
import StatisticalData from "../../../components/data/device/StatisticalData";

const PAGE_VALUE = "자료관리";
const SUBPAGE_VALUE = "통계자료";

// 임의 데이터
const sampleData = [
  {
    type: "before",
    data: [
      { eqNm: "경사계01", x: 0.02, y: 0 },
      { eqNm: "경사계02", x: 0.01, y: 0.04 },
      { eqNm: "경사계03", x: -0.01, y: -0.03 },
      { eqNm: "복합계01", x: 0.02, y: 0.21, mm: 10.77 },
      { eqNm: "복합계02", x: -0.09, y: -0.03, mm: 0.49 },
    ],
  },
  {
    type: "standard",
    data: [
      { eqNm: "경사계01", x: 0.04, y: 0.01 },
      { eqNm: "경사계02", x: 0.03, y: 0.04 },
      { eqNm: "경사계03", x: -0.01, y: -0.04 },
      { eqNm: "복합계01", x: 0.04, y: 0.24, mm: 12.58 },
      { eqNm: "복합계02", x: -0.08, y: 0, mm: 0.61 },
    ],
  },
  {
    type: "compare",
    data: [
      { eqNm: "경사계01", x: 0.02, y: 0.01, status: "normal" },
      { eqNm: "경사계02", x: 0.02, y: 0, status: "normal" },
      { eqNm: "경사계03", x: 0, y: -0.01, status: "normal" },
      { eqNm: "복합계01", x: 0.02, y: 0.03, mm: 1.81, status: "normal" },
      { eqNm: "복합계02", x: -0.08, y: 0, mm: 0.12, status: "normal" },
    ],
  },
];

function DataDevice() {
  const dispatch = useDispatch();
  const [isToken, setIsToken] = useState(false);
  const movePage = useNavigate();
  const [contentType, setContentType] = useState("data-manage");
  const [searchDate, setSearchDate] = useState(new Date());
  const [searchDateOk, setSearchDateOk] = useState(new Date());
  const [userInput, setUserInput] = useState(""); // 사용자 의견

  useEffect(() => {
    dispatch(setMenu(PAGE_VALUE));
    dispatch(setSubmenu(SUBPAGE_VALUE));

    // 토큰 확인
    tokenCheck().then((result) => {
      if (!result) {
        alert("인증 토큰이 만료되었습니다. 다시 로그인 해주세요");
        movePage("/");
      } else {
        setIsToken(true);
      }
    });
  }, []);

  return (
    <div className="outlet_container">
      {isToken && (
        <div id="data-device-page">
          <div className="header_container">
            <div className="title">
              <div className="title_imp">통계</div>
              <div className="title_nor">자료</div>
            </div>
            <div className="path">
              <FontAwesomeIcon icon={faHome} />
              <div className="text">&gt; 자료관리 &gt; 통계자료</div>
            </div>
          </div>

          <div className="main_container">
            <div className="contents_container">
              <div className="contents_header">
                <div className="title">
                  <FontAwesomeIcon icon={faDatabase} />
                  <div className="text">통계</div>

                  <select
                    className="content-type"
                    value={contentType}
                    onChange={(e) => setContentType(e.target.value)}
                  >
                    {STATISTICALOPTIONS.type.map((item) => (
                      <option key={item.value} value={item.value}>
                        {item.text}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="data-device-search-excel-print">
                  <DatePicker
                    selected={searchDate}
                    onChange={(date) => setSearchDate(date)}
                    dateFormat="yyyy-MM"
                    maxDate={new Date()}
                    showMonthYearPicker={true}
                    locale={locale.ko}
                    customInput={<DATEPICKERSTYLES.data />}
                  />
                  <div className="data-device-search-button button-imp">
                    조회
                  </div>
                  <div className="data-device-excel-button button-excel">
                    Excel저장
                  </div>
                  <div className="data-device-print-button button-excel">
                    {/* <FontAwesomeIcon icon={faPrint} />
                    <div className="text">출력</div> */}
                    출력
                  </div>
                </div>
              </div>

              <div className="contents_main data-device-contents">
                <div className="title">통계 데이터</div>
                {/* 통계 데이터 */}
                <div className="data-device-data">
                  <StatisticalData data={sampleData} />
                </div>

                <div className="title">사용자 의견</div>
                {/* 사용자 입력 */}
                <div className="data-device-input">
                  <div className="input-title">향후 운영 방안</div>
                  <textarea
                    type="text"
                    placeholder="향후 운영 방안 입력"
                    value={userInput}
                    onChange={(e) => setUserInput(e.target.value)}
                  />
                </div>
              </div>

              <div className="contents_footer"></div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default DataDevice;
