import { useState, useEffect } from "react";
import Modal from "react-modal";

import { dateToFormat } from "../../../utils/dateFormat";
import { MODALSTYLES } from "../../../constants/modalStyles";

Modal.setAppElement("#root");

function CCTVInform({ isOpen, setIsOpen }) {
  const [inform, setInform] = useState({});

  function onClickClose() {
    // 닫기 버튼 클릭시
    setIsOpen({ isOpen: false, data: {} });
  }

  useEffect(() => {
    setInform(isOpen.data);
  }, [isOpen.data]);

  return (
    <Modal isOpen={isOpen.isOpen} style={MODALSTYLES.cctv}>
      <div className="modal_title">
        <div>CCTV 정보</div>
      </div>

      <div className="modal_container">
        <div className="modal_main_column">
          <div className="cctv-inform-modal">
            <div className="inform-container">
              <div className="title">CCTV명</div>
              <div className="inform inform-two">{inform.eqNm}</div>
              <div className="inner-title">설치일자</div>
              <div className="inform inform-two">
                {dateToFormat(inform.instlDt)}
              </div>
            </div>
            <div className="inform-container">
              <div className="title">제조업체</div>
              <div className="inform inform-two">
                {inform.mnfct === "" ? "-" : inform.mnfct}
              </div>
              <div className="inner-title">모델명</div>
              <div className="inform inform-two">
                {inform.modelNm === "" ? "-" : inform.modelNm}
              </div>
            </div>
            <div className="inform-container">
              <div className="title">IP</div>
              <div className="inform inform-one">{inform.commAddr}</div>
            </div>
            <div className="inform-container">
              <div className="title">ONVIF Port</div>
              <div className="inform inform-two">{inform.webPort}</div>
              <div className="inner-title">RTSP Port</div>
              <div className="inform inform-two">{inform.rtspPort}</div>
            </div>
            <div className="inform-container">
              <div className="title">CCTV 아이디</div>
              <div className="inform inform-two">{inform.cctvId}</div>
              <div className="inner-title">CCTV 비밀번호</div>
              <div className="inform inform-two">*****</div>
            </div>
            <div className="inform-container">
              <div className="title">릴레이명</div>
              <div className="inform inform-one">
                {inform.relayNm === "" ? "-" : inform.relayNm}
              </div>
            </div>
            <div className="inform-container">
              <div className="title">릴레이 IP</div>
              <div className="inform inform-two">
                {inform.relayIp === "" ? "-" : inform.relayIp}
              </div>
              <div className="inner-title">릴레이 Port</div>
              <div className="inform inform-two">
                {inform.relayPort === "" ? "-" : inform.relayPort}
              </div>
            </div>
            <div className="inform-container">
              <div className="title">법정동코드</div>
              <div className="inform inform-bdong">{inform.bdongCd}</div>
              <div className="inner-title">주소</div>
              <div className="inform inform-addr">{inform.addr}</div>
            </div>
            <div className="inform-container">
              <div className="title">상세주소</div>
              <div className="inform inform-one">
                {inform.addrDtl === "" ? "-" : inform.addrDtl}
              </div>
            </div>
            <div className="inform-container">
              <div className="title">위도</div>
              <div className="inform inform-two">{inform.lat}</div>
              <div className="inner-title">경도</div>
              <div className="inform inform-two">{inform.lon}</div>
            </div>
            <div className="last-inform-container">
              <div className="title">메모</div>
              <textarea
                value={inform.eqDtl === "" ? "-" : inform.eqDtl}
                disabled={true}
              />
            </div>
          </div>
        </div>

        <div className="modal_footer">
          <div className="button-nor-modal" onClick={onClickClose}>
            닫기
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default CCTVInform;
