import React, { useState, useEffect, useRef } from "react";
import {
  ResponsiveContainer,
  AreaChart,
  Area,
  Legend,
  XAxis,
  YAxis,
  Tooltip,
  ReferenceLine,
  CartesianGrid,
} from "recharts";

import { DEVICEOPTIONS } from "../../../constants/deviceOptions";

function LevelGraph({ searchType, selected }) {
  const [data, setData] = useState([]);
  const [showData, setShowData] = useState([]);
  const [minMax, setMinMax] = useState({ min: 0, max: 0 });
  const [showMinMax, setShowMinMax] = useState({ min: 0, max: 0 });
  const [alertLine, setAlertLine] = useState([]);
  const [changeFromX, setChangeFromX] = useState(""); // XAxis domain
  const [changeToX, setChangeToX] = useState("");
  const chartRef = useRef(null); // YAxis domain
  const [changeFromY, setChangeFromY] = useState("");
  const [changeToY, setChangeToY] = useState("");
  const containerRef = useRef(null); // drag 영역
  const [dragRect, setDragRect] = useState(null);
  const startX = useRef(0);
  const startY = useRef(0);
  const [inputMinMax, setInputMinMax] = useState({ min: 0, max: 0 }); // 범위 설정

  /* AlertLine Custom */
  const AlertCustomLabelUp = (props) => {
    const x = props.viewBox.x;
    const y = props.viewBox.y;
    const value = props.value;
    const color = props.color;
    return (
      <text
        x={x + 5}
        y={y - 5}
        textAnchor="start"
        fill={color}
        fontSize={12}
        fontWeight="bold"
      >
        {value}
      </text>
    );
  };
  const AlertCustomLabelDown = (props) => {
    const x = props.viewBox.x;
    const y = props.viewBox.y;
    const value = props.value;
    const color = props.color;
    return (
      <text
        x={x + 5}
        y={y + 12}
        textAnchor="start"
        fill={color}
        fontSize={12}
        fontWeight="bold"
      >
        {value}
      </text>
    );
  };
  /* Legend Custom */
  const CustomLegend = (props) => {
    const { payload } = props;
    return (
      <ul className="legend-container">
        {payload.map((entry, index) => {
          return (
            <li key={`item-${index}`} className="legend-item">
              <div
                className="item-color"
                style={{ background: "#425FD173", border: "1px solid #425FD1" }}
              />
              <div className="item-value">수위</div>
            </li>
          );
        })}
      </ul>
    );
  };
  /* Tooltip Custom */
  const TooltipCustom = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      const date =
        payload[0].payload.date === undefined ? "" : payload[0].payload.date;
      return (
        <div className="tooltip-container">
          <div className="date-text">{date}</div>
          <div className="value-container">
            {payload.map((item) => (
              <div key={`value-item-${item.dataKey}`} className="value-item">
                <div
                  className="value-color"
                  style={{ background: item.color }}
                />
                <div className="value-title">수위:</div>
                <div className="value">{item.payload.avgVal}</div>
              </div>
            ))}
          </div>
        </div>
      );
    }
  };

  /* String => Date */
  function stringToDateYearMonth(dateString) {
    return new Date(dateString);
  }
  function stringToDateDay(dateString) {
    const date = dateString.replace(" ", "T") + ":00";
    return date;
  }
  function stringToDateMin(dateString) {
    const date = dateString.replace(" ", "T");
    return date;
  }
  /* Date 비교 */
  function compareDate(from, to) {
    let fromX = from;
    let toX = to;
    switch (searchType) {
      case "year":
      case "month":
        fromX = stringToDateYearMonth(fromX);
        toX = stringToDateYearMonth(toX);
        break;
      case "day":
        fromX = stringToDateDay(fromX);
        toX = stringToDateDay(toX);
        break;
      default:
        fromX = stringToDateMin(fromX);
        toX = stringToDateMin(toX);
        break;
    }
    if (fromX > toX) {
      return false;
    }
    return true;
  }
  /* chartY => YAxis에 맞게 값 변환 */
  function chartToYAxis(value) {
    const val = Number(value);
    const chartRect = chartRef.current.container.getBoundingClientRect();
    const chartMin = 50;
    const chartMax = Number(chartRect.height) - 25;
    const yAxisMin = Number(showMinMax.min);
    const yAxisMax = Number(showMinMax.max);
    // 정규화
    const normalize = (chartMax - val) / (chartMax - chartMin);
    // 스케일링
    const tempValue = yAxisMin + normalize * (yAxisMax - yAxisMin);
    return parseFloat(tempValue).toFixed(2);
  }
  /* Zoom Event */
  function onMouseDownChart(e) {
    if (Object.keys(e).length !== 0) {
      setChangeFromX(e.activeLabel);
      setChangeFromY(e.chartY);
    }
  }
  function onMouseMoveChart(e) {
    if (Object.keys(e).length !== 0) {
      if (changeFromX !== "") {
        setChangeToX(e.activeLabel);
      }
      if (changeFromY !== "") {
        setChangeToY(e.chartY);
      }
    }
  }
  /* Drag Event */
  function onMouseDownComponent(e) {
    const rect = containerRef.current.getBoundingClientRect();
    const startXValue = e.clientX - rect.left; // 커서 위치
    const startYValue = e.clientY - rect.top;

    const xLimit = rect.width - 20;
    const yLimit = rect.height - 19;
    if (
      65 <= Number(startXValue) &&
      Number(startXValue) <= Number(xLimit) &&
      50 <= Number(startYValue) &&
      Number(startYValue) <= Number(yLimit)
    ) {
      startX.current = startXValue;
      startY.current = startYValue;
      setDragRect({
        left: startXValue,
        top: startYValue,
        width: 0,
        height: 0,
      });
    }
  }
  function onMouseMoveComponent(e) {
    if (dragRect) {
      const rect = containerRef.current.getBoundingClientRect();
      const currentX = e.clientX - rect.left; // 커서 위치
      const currentY = e.clientY - rect.top;

      const xLimit = rect.width - 20;
      const yLimit = rect.height - 19;
      if (
        65 <= Number(currentX) &&
        Number(currentX) <= Number(xLimit) &&
        50 <= Number(currentY) &&
        Number(currentY) <= Number(yLimit)
      ) {
        setDragRect({
          left: Math.min(currentX, startX.current),
          top: Math.min(currentY, startY.current),
          width: Math.abs(currentX - startX.current),
          height: Math.abs(currentY - startY.current),
        });
      }
    }
  }
  /* Zoom & Drag */
  function handleMouseUp() {
    // drag
    setDragRect(null);

    // zoom
    // xAxis
    let [fromX, toX] = [changeFromX, changeToX];
    if (!fromX || !toX || toX === "") {
      setChangeFromX("");
      setChangeToX("");
      return;
    }

    if (!compareDate(fromX, toX)) {
      [fromX, toX] = [toX, fromX];
    }
    let dataSlice = [...showData];
    switch (searchType) {
      case "year":
      case "month":
      case "day":
        const compareNumFrom = Number(fromX.slice(-2));
        const compareNumTo = Number(toX.slice(-2));
        dataSlice = dataSlice.filter((dataItem) => {
          const value = Number(dataItem.date.slice(-2));
          return compareNumFrom <= value && value <= compareNumTo;
        });
        setShowData(dataSlice);
        break;
      default:
        const compareNumFromHour = Number(fromX.slice(-5, -3));
        const compareNumFromMin = Number(fromX.slice(-2));
        const compareNumToHour = Number(toX.slice(-5, -3));
        const compareNumToMin = Number(toX.slice(-2));
        dataSlice = dataSlice.filter((dataItem) => {
          const hour = Number(dataItem.date.slice(-5, -3));
          const min = Number(dataItem.date.slice(-2));
          return (
            (compareNumFromHour === hour &&
              compareNumToHour === hour &&
              compareNumFromMin <= min &&
              min <= compareNumToMin) ||
            (compareNumFromHour !== compareNumToHour &&
              ((compareNumFromHour === hour && compareNumFromMin <= min) ||
                (compareNumFromHour < hour && hour < compareNumToHour) ||
                (compareNumToHour === hour && compareNumToMin >= min)))
          );
        });
        setShowData(dataSlice);
        break;
    }
    //yAxis
    let [fromY, toY] = [changeFromY, changeToY];
    if (!fromY || !toY || fromY === toX || toY === "") {
      setChangeFromY("");
      setChangeToY("");
      return;
    }

    if (Number(fromY) < Number(toY)) {
      [fromY, toY] = [toY, fromY];
    }
    setShowMinMax({
      min: chartToYAxis(fromY) < 0 ? 0 : Number(chartToYAxis(fromY)),
      max: Number(chartToYAxis(toY)),
    });

    // 초기화
    setChangeFromX("");
    setChangeToX("");
    setChangeFromY("");
    setChangeToY("");
  }
  /* 그래프 범위 설정 */
  function onClickSetting() {
    let inputMin = Number(inputMinMax.min);
    let inputMax = Number(inputMinMax.max);
    if (inputMin > inputMax) {
      [inputMin, inputMax] = [inputMax, inputMin];
    }
    inputMin = inputMin < 0 ? 0 : Number(parseFloat(inputMin).toFixed(2));
    inputMax = Number(parseFloat(inputMax).toFixed(2));
    setShowMinMax({ min: inputMin, max: inputMax });
  }
  /* 그래프 범위 초기화 */
  function onClickReset() {
    setShowData(data);
    setShowMinMax(minMax);
  }

  useEffect(() => {
    /* 데이터가공 & 최대값 찾기 */
    if (Array.isArray(selected.data) && selected.data.length > 0) {
      const selectedData = selected.data;

      let dataMax = 0;
      const nullData = selectedData.map((dataItem) => {
        const tmpItem = { ...dataItem };
        for (let key in dataItem) {
          if (key !== "date") {
            if (dataItem[key] === "-") {
              tmpItem[key] = null; // "-" → null
            } else {
              const val = parseFloat(dataItem[key]); // 최대값 찾기
              dataMax = val > dataMax ? val : dataMax;
            }
          }
        }
        return tmpItem;
      });
      setData(nullData);
      setShowData(nullData);
      setMinMax({ min: 0, max: Math.round(dataMax + 10) });
      setShowMinMax({ min: 0, max: Math.round(dataMax + 10) });
      setChangeFromX("");
      setChangeToX("");
      setChangeFromY("");
      setChangeToY("");
    } else {
      setData([]);
      setShowData([]);
      setMinMax({ min: 0, max: 0 });
      setShowMinMax({ min: 0, max: 0 });
      setChangeFromX("");
      setChangeToX("");
      setChangeFromY("");
      setChangeToY("");
    }

    /* 임계치 */
    if (Array.isArray(selected.dvcData) && selected.dvcData.length > 0) {
      const dvcData = selected.dvcData;

      const alert = dvcData.map((dvcItem) => {
        const alertItem = {
          unit: "m",
          min: null,
          min2: null,
          min3: null,
          min4: null,
          max: null,
          max2: null,
          max3: null,
          max4: null,
        };

        for (let key in alertItem) {
          if (key !== "unit") {
            const val =
              dvcItem[key].toString() === DEVICEOPTIONS.sensorAnomaly.toString()
                ? null
                : Number(dvcItem[key]);
            alertItem[key] = val;
          }
        }

        return alertItem;
      });

      setAlertLine(alert);
    }
  }, [selected]);
  useEffect(() => {
    document.addEventListener("mouseup", handleMouseUp);
    return () => {
      document.removeEventListener("mouseup", handleMouseUp);
    };
  }, [showData, changeFromX, changeToX, changeFromY, changeToY]);
  useEffect(() => {
    setInputMinMax(showMinMax);
  }, [showMinMax]);

  return (
    <div id="data-graph-component">
      {
        // 그래프 범위 & 초기화
        data.length > 0 && (showMinMax.min !== 0 || showMinMax.max !== 0) && (
          <div className="graph-range">
            {/* 범위 설정 */}
            <div className="title">범위(m):</div>
            <input
              type="number"
              value={inputMinMax.min}
              onChange={(e) =>
                setInputMinMax({ ...inputMinMax, min: e.target.value })
              }
            />
            <div className="tilde">~</div>
            <input
              type="number"
              value={inputMinMax.max}
              onChange={(e) =>
                setInputMinMax({ ...inputMinMax, max: e.target.value })
              }
            />
            <div className="button-imp setting-button" onClick={onClickSetting}>
              설정
            </div>
            {/* 초기화 */}
            <div className="button-imp reset-button" onClick={onClickReset}>
              초기화
            </div>
          </div>
        )
      }
      {data.length > 0 && (
        <div
          ref={containerRef}
          className="graph"
          onMouseDown={onMouseDownComponent}
          onMouseMove={onMouseMoveComponent}
        >
          <ResponsiveContainer width="100%" height="100%">
            <AreaChart
              ref={chartRef}
              data={showData}
              margin={{ left: 0, right: 20, bottom: -10 }}
              onMouseDown={onMouseDownChart}
              onMouseMove={onMouseMoveChart}
            >
              <CartesianGrid
                stroke="#595959"
                strokeWidth={0.5}
                vertical={false}
              />
              <Legend
                verticalAlign="top"
                height={40}
                wrapperStyle={{ paddingBottom: "10px" }}
                content={<CustomLegend />}
              />
              <XAxis
                dataKey="date"
                tick={{ fontSize: 11, fill: "#9B9B9B" }}
                axisLine={false}
              />
              <YAxis
                type="number"
                domain={[showMinMax.min, showMinMax.max]}
                tick={{ fontSize: 11, fill: "#9B9B9B" }}
                tickCount={10}
                label={{
                  value: "수위",
                  angle: -90,
                  position: "insideLeft",
                  style: {
                    textAnchor: "middle", // 텍스트 정렬
                    fill: "#9B9B9B",
                    fontSize: 12,
                  },
                }}
                axisLine={false}
                allowDataOverflow={true}
              />
              <Tooltip
                content={<TooltipCustom />}
                filterNull={true}
                cursor={{
                  stroke: "#757575",
                  strokeWidth: 1,
                  strokeDasharray: "3 3",
                }}
              />

              <defs>
                <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#425FD173" stopOpacity={1} />
                  <stop offset="95%" stopColor="#425FD173" stopOpacity={0.3} />
                </linearGradient>
              </defs>
              <Area
                type="monotone"
                dataKey="avgVal"
                fill="url(#colorUv)"
                stroke="#425FD1"
                isAnimationActive={false}
              />

              {alertLine.length > 0 &&
                alertLine.map((alertItem, index) => (
                  <React.Fragment key={`${index}-alert`}>
                    {/* 최소 */}
                    <ReferenceLine
                      y={alertItem.min}
                      label={
                        <AlertCustomLabelDown
                          value={`관심(${alertItem.min}) ${alertItem.unit}`}
                          color="#0F80B1"
                        />
                      }
                      stroke="#006B99"
                      strokeDasharray="7 4"
                      strokeWidth={1}
                    />
                    <ReferenceLine
                      y={alertItem.min2}
                      label={
                        <AlertCustomLabelDown
                          value={`주의(${alertItem.min2}) ${alertItem.unit}`}
                          color="#A49506"
                        />
                      }
                      stroke="#928400"
                      strokeDasharray="7 4"
                      strokeWidth={1}
                    />
                    <ReferenceLine
                      y={alertItem.min3}
                      label={
                        <AlertCustomLabelDown
                          value={`경계(${alertItem.min3}) ${alertItem.unit}`}
                          color="#B9640A"
                        />
                      }
                      stroke="#A15300"
                      strokeDasharray="7 4"
                      strokeWidth={1}
                    />
                    <ReferenceLine
                      y={alertItem.min4}
                      label={
                        <AlertCustomLabelDown
                          value={`심각(${alertItem.min4}) ${alertItem.unit}`}
                          color="#A00B0B"
                        />
                      }
                      stroke="#8F0303"
                      strokeDasharray="7 4"
                      strokeWidth={1}
                    />
                    {/* 최대 */}
                    <ReferenceLine
                      y={alertItem.max}
                      label={
                        <AlertCustomLabelUp
                          value={`관심(${alertItem.max}) ${alertItem.unit}`}
                          color="#0F80B1"
                        />
                      }
                      stroke="#006B99"
                      strokeDasharray="7 4"
                      strokeWidth={1}
                    />
                    <ReferenceLine
                      y={alertItem.max2}
                      label={
                        <AlertCustomLabelUp
                          value={`주의(${alertItem.max2}) ${alertItem.unit}`}
                          color="#A49506"
                        />
                      }
                      stroke="#928400"
                      strokeDasharray="7 4"
                      strokeWidth={1}
                    />
                    <ReferenceLine
                      y={alertItem.max3}
                      label={
                        <AlertCustomLabelUp
                          value={`경계(${alertItem.max3}) ${alertItem.unit}`}
                          color="#B9640A"
                        />
                      }
                      stroke="#A15300"
                      strokeDasharray="7 4"
                      strokeWidth={1}
                    />
                    <ReferenceLine
                      y={alertItem.max4}
                      label={
                        <AlertCustomLabelUp
                          value={`심각(${alertItem.max4}) ${alertItem.unit}`}
                          color="#A00B0B"
                        />
                      }
                      stroke="#8F0303"
                      strokeDasharray="7 4"
                      strokeWidth={1}
                    />
                  </React.Fragment>
                ))}
            </AreaChart>
          </ResponsiveContainer>

          {
            // 드래그 영역
            dragRect && (
              <div
                className="drag-rect"
                style={{
                  left: `${dragRect.left}px`,
                  top: `${dragRect.top}px`,
                  width: `${dragRect.width}px`,
                  height: `${dragRect.height}px`,
                }}
              />
            )
          }
        </div>
      )}
    </div>
  );
}

export default LevelGraph;
