import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import axios from "axios";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";

import apiAddress from "../../../services/apiAddress";
import Alert from "../../common/window/Alert";
import Reg from "../../common/window/Reg";
import Sensor from "./alert-setting/Sensor";
import Broadcast from "./alert-setting/Broadcast";

function AlertSetting({
  isOpen,
  setIsOpen,
  setIsAllOpen,
  isOk,
  setIsOk,
  selectedId,
}) {
  const [isAlert, setIsAlert] = useState(false);
  const [isReg, setIsReg] = useState({
    isOpen: false,
    text: "",
  });
  const [snsrData, setSnsrData] = useState([]);
  const [brdData, setBrdData] = useState([]);
  const [regData, setRegData] = useState([]);

  function onClickBack() {
    // 뒤로가기.
    setIsOpen(false);
  }
  function onClickReg() {
    // 등록.
    if (
      regData.find(
        (item) => item.hasOwnProperty("isSetting") && item.isSetting === false
      )
    ) {
      // 값 설정되지 않은 센서 존재.
      setIsAlert(true);
    } else {
      // 값 설정되지 않은 센서 존재X.
      setIsReg({
        isOpen: true,
        text: regData.length + "개의 장비를 등록하시겠습니까?",
      });
    }
  }
  function onClickClose() {
    // 닫기.
    setIsOpen(false);
    setIsAllOpen(false);
  }

  /* 경보그룹 - 장비 저장 API */
  async function fetchData() {
    try {
      // 경보그룹 - equip 저장.
      axios
        .post(
          apiAddress.groupEquipSave,
          [
            {
              groupId: selectedId,
              eqId: isOpen.data.eqId,
              useYn: "Y",
              regUsr: "SH",
            },
          ],
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          // 경보그룹 - dvc 저장.
          axios
            .post(apiAddress.groupEquipDeviceSave, regData, {
              headers: {
                "Content-Type": "application/json",
              },
            })
            .then((response) => {
              setIsOk(!isOk);
              setIsOpen({
                isOpen: false,
                data: {},
              });
              setIsAllOpen(false);
            });
        });
    } catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    const data = isOpen.data.dvcData;
    const snsr = data.filter((item) => item.dvcType === "SNSR");
    const brd = data.filter((item) => item.dvcType === "BRD");

    setSnsrData(snsr);
    setBrdData(brd);
  }, []);

  return (
    <Modal isOpen={isOpen.isOpen} style={modalStyles}>
      {isAlert && (
        <Alert
          isOpen={isAlert}
          setIsOpen={setIsAlert}
          text="값을 설정하지 않은 센서가 존재합니다"
        />
      )}
      {isReg.isOpen && (
        <Reg isOpen={isReg} setIsOpen={setIsReg} regData={fetchData} />
      )}
      <div className="modal_title">
        <FontAwesomeIcon
          icon={faArrowLeft}
          onClick={onClickBack}
          style={{ cursor: "pointer" }}
        />
        <div style={{ fontSize: "0.9rem" }}>{isOpen.data.eqNm}</div>
      </div>
      <div className="modal_container">
        {/* Main */}
        <div className="modal_main_row">
          {/* 센서 */}
          <div className="setting_alert_setting_snsr_container">
            <Sensor
              groupId={selectedId}
              eqId={isOpen.data.eqId}
              snsrData={snsrData}
              regData={regData}
              setRegData={setRegData}
            />
          </div>
          {/* 방송장비 */}
          <div className="setting_alert_setting_brd_container">
            <Broadcast
              groupId={selectedId}
              eqId={isOpen.data.eqId}
              brdData={brdData}
              regData={regData}
              setRegData={setRegData}
            />
          </div>
        </div>

        {/* Footer */}
        <div className="modal_footer">
          <div className="button_nor" onClick={onClickClose}>
            닫기
          </div>
          {regData.length > 0 && (
            <div
              className="button_imp"
              onClick={onClickReg}
              style={{ marginLeft: "10px" }}
            >
              등록
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
}

const modalStyles = {
  content: {
    width: "990px",
    height: "720px",
    padding: "0px",
    background: "#202020",
    border: "none",
    borderRadius: "5px",
    margin: "auto",
  },
  overlay: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "fixed",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: "rgba(0, 0, 0, 0.0)",
  },
};

export default AlertSetting;
