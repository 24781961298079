import Modal from "react-modal";

import { MODALSTYLES } from "../../../constants/modalStyles";

Modal.setAppElement("#root");

function Reg({ isOpen, setIsOpen, regData }) {
  function onClickCancel() {
    setIsOpen({
      isOpen: false,
      text: "",
    });
  }
  function onClickReg() {
    regData();
    setIsOpen({
      isOpen: false,
      text: "",
    });
  }

  return (
    <Modal isOpen={isOpen.isOpen} style={MODALSTYLES.reg}>
      <div className="modal_window_container">
        <div className="text">
          <div>{isOpen.text}</div>
        </div>
        <div className="button">
          <div className="button_nor" onClick={onClickCancel}>
            취소
          </div>
          <div
            className="button_imp"
            onClick={onClickReg}
            style={{ marginLeft: "20px" }}
          >
            확인
          </div>
        </div>
      </div>
    </Modal>
  );
}
const modalStyles = {
  content: {
    width: "450px",
    height: "120px",
    padding: "20px",
    background: "#202020",
    border: "none",
    borderRadius: "5px",
    boxShadow: "-5px 5px 10px rgba(0, 0, 0, 0.7)",
    margin: "auto",
  },
  overlay: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "fixed",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 999,
    backgroundColor: "rgba(0, 0, 0, 0)",
  },
};

export default Reg;
