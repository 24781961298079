import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  createColumnHelper,
  flexRender,
  useReactTable,
  getCoreRowModel,
  getSortedRowModel,
} from "@tanstack/react-table";
import { Menu, Item, useContextMenu } from "react-contexify";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisVertical } from "@fortawesome/free-solid-svg-icons";

import { tokenCheck } from "../../../../utils/tokenCheck";
import { dateToFormat } from "../../../../utils/dateFormat";
import { DEVICEOPTIONS } from "../../../../constants/deviceOptions";
import {
  getSensorType,
  getBroadType,
} from "../../../../services/internal/deviceAPI";
import { saveDevice } from "../../../../services/internal/deviceAPI";
import { getGroupEquipDevice } from "../../../../services/internal/groupAPI";
import Alert from "../../../common/window/Alert";
import DeviceInform from "../../../common/inform/DeviceInform";
import DeviceEdit from "./DeviceEdit";
import Delete from "../../../common/window/Delete";

const MENU_ID = "deviceList";

function DeviceList({ selected, setIsChange }) {
  const movePage = useNavigate();
  const [snsrType, setSnsrType] = useState([]);
  const [brdType, setBrdType] = useState([]);
  const [isInform, setIsInform] = useState({
    isOpen: false,
    data: {},
  });
  const [isEdit, setIsEdit] = useState({
    isOpen: false,
    data: {},
  });
  const [isAlert, setIsAlert] = useState(false);
  const [isDel, setIsDel] = useState({
    isOpen: false,
    text: "",
    data: {},
  });
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);
  const columnHelper = createColumnHelper();
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });
  const menuIconColumn = {
    id: "menuIcon",
    accessor: "menu",
    header: "",
    size: 20,
    enableSorting: false,
    cell: ({ row }) => (
      <FontAwesomeIcon className="table_icon" icon={faEllipsisVertical} />
    ),
  };
  const { show } = useContextMenu({
    id: MENU_ID,
  });

  function onClickMenu(e, row) {
    // 메뉴 클릭 시.
    e.stopPropagation();
    show({ event: e, props: { data: row } });
  }
  function menuItemClick({ id, props }) {
    // 메뉴 Item 클릭 시.
    // 토큰 확인
    tokenCheck().then((result) => {
      if (!result) {
        alert("인증 토큰이 만료되었습니다. 다시 로그인 해주세요");
        movePage("/");
      } else {
        switch (id) {
          // 정보.
          case "inform":
            setIsInform({
              isOpen: true,
              data: props.data,
            });
            break;
          case "edit":
            setIsEdit({
              isOpen: true,
              data: props.data,
            });
            break;
          case "delete":
            deviceDelCheck(props.data);
            break;
          default:
            break;
        }
      }
    });
  }
  function deviceDelCheck(data) {
    getGroupEquipDevice(data.dvcId).then((response) => {
      if (response.length > 0) {
        // 등록되어 있는 그룹 존재
        setIsAlert(true);
      } else {
        // 삭제 가능
        const tokenInform = localStorage.getItem("tokenInform");
        const tokenInformParse = JSON.parse(tokenInform);
        const delData = {
          ...data,
          modUsr: tokenInformParse.userId,
          useYn: "D",
        };
        setIsDel({
          isOpen: true,
          text: "'" + data.dvcNm + "' 장비를 삭제하시겠습니까?",
          data: delData,
        });
      }
    });
  }
  function deleteDevice(data) {
    saveDevice(data).then((response) => {
      setIsChange(true);
    });
  }

  useEffect(() => {
    if (Object.keys(selected).length !== 0) {
      if (selected.dvcData.length !== 0) {
        const column = [
          columnHelper.accessor("dvcNm", { header: "장비명" }),
          columnHelper.accessor(
            (row) => {
              return dateToFormat(row.instlDt);
            },
            { id: "instlDt", header: "설치일자", size: 50 }
          ),
          columnHelper.accessor(
            (row) => {
              return DEVICEOPTIONS.type.find(
                (item) => item.value === row.dvcType
              )?.text;
            },
            { id: "dvcType", header: "장비종류", size: 50 }
          ),
          columnHelper.accessor(
            (row) => {
              if (row.dvcType === "SNSR") {
                return snsrType.find(
                  (item) => item.commonCode === row.dvcDtlType
                )?.commonCodeName;
              } else {
                return brdType.find(
                  (item) => item.commonCode === row.dvcDtlType
                )?.commonCodeName;
              }
            },
            { id: "dvcDtlType", header: "타입", size: 100 }
          ),
          columnHelper.accessor(
            (row) => {
              const mnfct = row.mnfct === "" ? "-" : row.mnfct;
              return mnfct;
            },
            { id: "mnfct", header: "제조업체" }
          ),
          columnHelper.accessor(
            (row) => {
              const modelNm = row.modelNm === "" ? "-" : row.modelNm;
              return modelNm;
            },
            { id: "modelNm", header: "모델명" }
          ),
          menuIconColumn,
        ];

        setData(selected.dvcData);
        setColumns(column);
      } else {
        setData([]);
        setColumns([]);
      }
    } else {
      setData([]);
      setColumns([]);
    }
  }, [selected]);
  useEffect(() => {
    getSensorType().then((sensorType) => {
      getBroadType().then((broadType) => {
        setSnsrType(sensorType);
        setBrdType(broadType);
      });
    });
  }, []);

  return (
    <div id="setting-device-list-component">
      {
        // 장비 정보
        isInform.isOpen && (
          <DeviceInform isOpen={isInform} setIsOpen={setIsInform} />
        )
      }
      {
        // 장비 수정
        isEdit.isOpen && (
          <DeviceEdit
            isOpen={isEdit}
            setIsOpen={setIsEdit}
            setIsChange={setIsChange}
          />
        )
      }
      {
        // 등록되어 있는 그룹 존재, 장비 삭제 불가
        isAlert && (
          <Alert
            isOpen={isAlert}
            setIsOpen={setIsAlert}
            text="먼저 등록되어 있는 그룹에서부터 삭제해주세요"
          />
        )
      }
      {
        // 장비 삭제
        isDel.isOpen && (
          <Delete
            isOpen={isDel}
            setIsOpen={setIsDel}
            delData={() => deleteDevice(isDel.data)}
          />
        )
      }
      {data.length === 0 ? (
        <div className="setting-data-none-container">
          등록된 장비가 없습니다
        </div>
      ) : (
        <div className="list">
          <table className="react_table">
            <thead>
              {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <th
                      key={header.id}
                      onClick={header.column.getToggleSortingHandler()}
                      style={{
                        width: isNaN(header.getSize())
                          ? "auto"
                          : `${header.getSize()}px`,
                      }}
                    >
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody>
              {table.getRowModel().rows.map((row, index) => (
                <tr
                  key={row.id}
                  className={index % 2 === 0 ? "tr_even" : "tr_odd"}
                >
                  {row.getVisibleCells().map((cell) => (
                    <td
                      key={cell.id}
                      onClick={(e) => {
                        if (cell.column.id === "menuIcon") {
                          onClickMenu(e, row.original);
                        }
                      }}
                      style={{
                        width: isNaN(cell.column.getSize())
                          ? "auto"
                          : `${cell.column.getSize()}px`,
                        textAlign:
                          (cell.column.id === "dvcType" ||
                            cell.column.id === "instlDt" ||
                            cell.column.id === "menuIcon") &&
                          "center",
                      }}
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      {/* Context 메뉴 */}
      <Menu id={MENU_ID}>
        <Item id="inform" onClick={menuItemClick}>
          정보
        </Item>
        <Item id="edit" onClick={menuItemClick}>
          수정
        </Item>
        <Item id="delete" onClick={menuItemClick}>
          삭제
        </Item>
      </Menu>
    </div>
  );
}

export default DeviceList;
