import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faR,
  faTowerBroadcast,
  faDesktop,
  faVideo,
  faCloudRain,
  faWater,
  faMountain,
  faArrowUpFromGroundWater,
  faLocationCrosshairs,
} from "@fortawesome/free-solid-svg-icons";

function MapLegend({ dvcPrint, setDvcPrint, setDvcPrintBfr }) {
  function checkIncludeDvcPrint(dvcType) {
    // 장비 출력에 포함되어 있는지
    if (Array.isArray(dvcPrint) && dvcPrint.length > 0) {
      const target = dvcPrint.find((item) => item === dvcType);
      if (target !== null && target !== undefined) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }
  function onClickDvcPrint(dvcType) {
    // 장비 출력 활성/비활성
    setDvcPrintBfr(dvcPrint);
    if (checkIncludeDvcPrint(dvcType)) {
      const newDvcPrint = dvcPrint.filter((item) => item !== dvcType);
      setDvcPrint(newDvcPrint);
    } else {
      setDvcPrint([...dvcPrint, dvcType]);
    }
  }

  return (
    <div className="map-legend">
      <div className="legend-title">장비구분</div>
      <div className="legend-equip-marker-container">
        {/* <div className="marker-item">
          <div className="marker-button">
            <div
              className={`button ${
                checkIncludeDvcPrint("rtu") ? "button-rtu" : "button-disable"
              }`}
              onClick={() => onClickDvcPrint("rtu")}
            >
              <FontAwesomeIcon icon={faR} />
            </div>
          </div>
          <div className="marker-name">RTU</div>
        </div> */}
        <div className="marker-item">
          <div className="marker-button">
            <div
              className={`button ${
                checkIncludeDvcPrint("broadcast")
                  ? "button-broad"
                  : "button-disable"
              }`}
              onClick={() => onClickDvcPrint("broadcast")}
            >
              <FontAwesomeIcon icon={faTowerBroadcast} />
            </div>
          </div>
          <div className="marker-name">방송</div>
        </div>
        <div className="marker-item">
          <div className="marker-button">
            <div
              className={`button ${
                checkIncludeDvcPrint("cctv") ? "button-cctv" : "button-disable"
              }`}
              onClick={() => onClickDvcPrint("cctv")}
            >
              <FontAwesomeIcon icon={faVideo} />
            </div>
          </div>
          <div className="marker-name">CCTV</div>
        </div>
      </div>
      <div className="legend-title">센서구분</div>
      <div className="legend-sensor-marker-container">
        <div className="marker-item">
          <div className="marker-button">
            <div
              className={`button ${
                checkIncludeDvcPrint("rain") ? "button-rain" : "button-disable"
              }`}
              onClick={() => onClickDvcPrint("rain")}
            >
              <FontAwesomeIcon icon={faCloudRain} />
            </div>
          </div>
          <div className="marker-name">강우계</div>
        </div>
        <div className="marker-item">
          <div className="marker-button">
            <div
              className={`button ${
                checkIncludeDvcPrint("level")
                  ? "button-level"
                  : "button-disable"
              }`}
              onClick={() => onClickDvcPrint("level")}
            >
              <FontAwesomeIcon icon={faWater} />
            </div>
          </div>
          <div className="marker-name">수위계</div>
        </div>
        <div className="marker-item">
          <div className="marker-button">
            <div
              className={`button ${
                checkIncludeDvcPrint("ground")
                  ? "button-edboard"
                  : "button-disable"
              }`}
              onClick={() => onClickDvcPrint("ground")}
            >
              <FontAwesomeIcon icon={faArrowUpFromGroundWater} />
            </div>
          </div>
          <div className="marker-name">지하수위계</div>
        </div>
        <div className="marker-item">
          <div className="marker-button">
            <div
              className={`button ${
                checkIncludeDvcPrint("displacement")
                  ? "button-displacement"
                  : "button-disable"
              }`}
              onClick={() => onClickDvcPrint("displacement")}
            >
              <FontAwesomeIcon icon={faLocationCrosshairs} />
            </div>
          </div>
          <div className="marker-name">지표변위계</div>
        </div>
      </div>
    </div>
  );
}

export default MapLegend;
