import { useState, useEffect } from "react";
import {
  createColumnHelper,
  flexRender,
  useReactTable,
  getCoreRowModel,
  getSortedRowModel,
} from "@tanstack/react-table";

const dvcTypes = [
  { value: "SNSR", text: "센서" },
  { value: "BRD", text: "방송장비" },
];
const snsrTypes = [
  { value: "01", text: "강우량계" },
  { value: "02", text: "수위계" },
  { value: "03", text: "변위계" },
  { value: "04", text: "토양함수비" },
  { value: "05", text: "거리측정기" },
  { value: "06", text: "적설계" },
  { value: "07", text: "지하수위계" },
  { value: "08", text: "경사계" },
  { value: "09", text: "간극수압계" },
  { value: "10", text: "진동계/진동가속도계" },
  { value: "11", text: "지중경사계" },
  { value: "12", text: "하중계" },
  { value: "13", text: "구조물경사계" },
  { value: "14", text: "GNSS(GPS)" },
  { value: "15", text: "지표변위계" },
  { value: "16", text: "조위계" },
  { value: "17", text: "균열감지기" },
  { value: "18", text: "온도계" },
  { value: "19", text: "습도계" },
  { value: "20", text: "침수감지기" },
  { value: "21", text: "가속도계" },
  { value: "22", text: "변형률계" },
  { value: "23", text: "풍향계" },
  { value: "24", text: "유량계" },
  { value: "25", text: "유속계" },
];
const brdTypes = [
  { value: "01", text: "비상예경보방송기기" },
  { value: "02", text: "마을방송" },
];

function AlertRegDevice({ dvcData }) {
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);
  const columnHelper = createColumnHelper();
  const emptyColumn = {
    id: "empty",
    accessor: "empty",
    header: "",
    enableSorting: false,
    size: 10,
  };
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  useEffect(() => {
    const column = [
      emptyColumn,
      columnHelper.accessor("dvcNm", {
        header: "장비명",
        size: 130,
      }),
      columnHelper.accessor(
        (row) => {
          return dvcTypes.find((type) => type.value === row.dvcType)?.text;
        },
        {
          header: "종류",
          size: 70,
        }
      ),
      columnHelper.accessor(
        (row) => {
          if (row.dvcType === "SNSR") {
            return snsrTypes.find((type) => type.value === row.dvcDtlType)
              ?.text;
          } else if (row.dvcType === "BRD") {
            return brdTypes.find((type) => type.value === row.dvcDtlType)?.text;
          } else {
            return row.dvcDtlType;
          }
        },
        { header: "타입", size: "auto" }
      ),
    ];

    setData(dvcData);
    setColumns(column);
  }, [dvcData]);

  return (
    <table className="sub_table">
      <tbody>
        {table.getRowModel().rows.map((row, index) => (
          <tr key={row.id} className={index % 2 === 0 ? "tr_even" : "tr_odd"}>
            {row.getVisibleCells().map((cell) => (
              <td
                key={cell.id}
                style={{
                  width: isNaN(cell.column.getSize())
                    ? "auto"
                    : `${cell.column.getSize()}px`,
                  minWidth: isNaN(cell.column.getSize())
                    ? "auto"
                    : `${cell.column.getSize()}px`,
                  maxWidth: isNaN(cell.column.getSize())
                    ? "auto"
                    : `${cell.column.getSize()}px`,
                }}
              >
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
}

export default AlertRegDevice;
