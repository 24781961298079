import { useState, useEffect } from "react";
import {
  flexRender,
  useReactTable,
  getCoreRowModel,
  createColumnHelper,
} from "@tanstack/react-table";

function MinGroundLevelTable({ levelData, selected, setSelected }) {
  const [snsrData, setSnsrData] = useState(levelData);
  const [valueData, setValueData] = useState([]);
  const [snsrColumns, setSnsrColumns] = useState([]);
  const [dataColumns, setDataColumns] = useState([]);
  const columnHelper = createColumnHelper();
  const snsrTable = useReactTable({
    data: snsrData,
    columns: snsrColumns,
    getCoreRowModel: getCoreRowModel(),
  });
  const dataTable = useReactTable({
    data: valueData,
    columns: dataColumns,
    getCoreRowModel: getCoreRowModel(),
  });
  const [mouseData, setMouseData] = useState({});

  function onClickRow(row) {
    // 행 클릭 시
    if (row.original.eqId % 7 === 6) {
      const outTarget = levelData.find(
        (item) => item.eqId === row.original.eqId + 1
      );
      setSelected([row.original, outTarget]);
    } else {
      const inTarget = levelData.find(
        (item) => item.eqId === row.original.eqId - 1
      );
      setSelected([inTarget, row.original]);
    }
  }
  function onMouseOverRow(row) {
    // 행에 마우스 가져다 댔을 시
    setMouseData(row.original);
  }
  function onMouseOutRow() {
    // 행에서 마우스를 뗏을 시
    setMouseData({});
  }

  useEffect(() => {
    const column = [columnHelper.accessor("eqNm", { header: "센서명" })];

    setSnsrData(levelData);
    setSnsrColumns(column);
  }, [levelData]);
  useEffect(() => {
    if (
      Array.isArray(selected) &&
      selected.length > 0 &&
      (selected[0].data.length > 0 || selected[1].data.length > 0)
    ) {
      const selectedInData = selected[0].data;
      const selectedOutData = selected[1].data;
      let mergedData;
      if (selectedInData.length > 0) {
        mergedData = selectedInData.map((inItem) => {
          const outItem = selectedOutData.find(
            (outItem) => outItem.date === inItem.date
          );
          if (outItem !== null && outItem !== undefined) {
            return {
              date: inItem.date,
              avgVal1: inItem.avgVal,
              avgVal2: outItem.avgVal,
            };
          } else {
            return { date: inItem.date, avgVal1: inItem.avgVal, avgVal2: "-" };
          }
        });
      } else {
        mergedData = selectedOutData.map((outItem) => {
          const inItem = selectedInData.find(
            (inItem) => outItem.date === inItem.date
          );
          if (inItem !== null && inItem !== undefined) {
            return {
              date: outItem.date,
              avgVal1: inItem.avgVal,
              avgVal2: outItem.avgVal,
            };
          } else {
            return {
              date: outItem.date,
              avgVal1: "-",
              avgVal2: outItem.avgVal,
            };
          }
        });
      }

      const sortData = [...mergedData].reverse();
      const column = [
        columnHelper.accessor("date", { header: "측정일자" }),
        columnHelper.accessor("avgVal1", { header: "내수 측정값(EL.m)" }),
        columnHelper.accessor("avgVal2", { header: "외수 측정값(EL.m)" }),
      ];

      setValueData(sortData);
      setDataColumns(column);
    } else {
      setValueData([]);
    }
  }, [selected]);

  return (
    <div className="data-minute-table-container">
      {/* RTU(센서) */}
      <div className="snsr-container">
        <div className="title">센서</div>
        <div className="snsr-table">
          <div className="list">
            <table className="react-data-table">
              <tbody>
                {snsrTable.getRowModel().rows.map((row, index) => (
                  <tr
                    key={row.id}
                    className={
                      selected[0].eqId === row.original.eqId ||
                      selected[1].eqId === row.original.eqId
                        ? "tr-snsr-double tr-select"
                        : mouseData.eqId - 1 === row.original.eqId ||
                          mouseData.eqId + 1 === row.original.eqId
                        ? "tr-snsr-double tr-select tr-pointer"
                        : "tr-snsr-double tr-pointer"
                    }
                    onClick={() => onClickRow(row)}
                    onMouseOver={() => onMouseOverRow(row)}
                    onMouseOut={onMouseOutRow}
                  >
                    {row.getVisibleCells().map((cell) => (
                      <td
                        key={cell.id}
                        style={{
                          width: isNaN(cell.column.getSize())
                            ? "auto"
                            : `${cell.column.getSize()}px`,
                        }}
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {/* 데이터 */}
      <div className="data-container">
        <div className="title">데이터</div>
        <div className="data-table">
          {valueData.length === 0 ? (
            <div className="data-none-container">
              데이터 조회 결과가 없습니다
            </div>
          ) : (
            <div className="list">
              <table className="react-data-table">
                <thead>
                  {dataTable.getHeaderGroups().map((headerGroup) => (
                    <tr key={headerGroup.id}>
                      {headerGroup.headers.map((header) => (
                        <th
                          key={header.id}
                          onClick={header.column.getToggleSortingHandler()}
                          style={{
                            width: isNaN(header.getSize())
                              ? "auto"
                              : `${header.getSize()}px`,
                          }}
                        >
                          {header.isPlaceholder
                            ? null
                            : flexRender(
                                header.column.columnDef.header,
                                header.getContext()
                              )}
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody>
                  {dataTable.getRowModel().rows.map((row, index) => (
                    <tr key={row.id} className="tr-data">
                      {row.getVisibleCells().map((cell) => (
                        <td
                          key={cell.id}
                          style={{
                            width: isNaN(cell.column.getSize())
                              ? "auto"
                              : `${cell.column.getSize()}px`,
                          }}
                        >
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default MinGroundLevelTable;
