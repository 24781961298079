import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { setMenu, setSubmenu } from "../../../redux/modules/menu";
import { useNavigate } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome, faList } from "@fortawesome/free-solid-svg-icons";

import { tokenCheck } from "../../../utils/tokenCheck";
import { getEdboard } from "../../../services/internal/edboardAPI";
import Loading from "../../../components/common/window/Loading";
import EdboardList from "../../../components/setting/edboard/EdboardList";
import EdboardAdd from "../../../components/setting/edboard/EdboardAdd";

const PAGE_VALUE = "설정";
const SUBPAGE_VALUE = "전광판";

function SettingEdboard() {
  const dispatch = useDispatch();
  const [isToken, setIsToken] = useState(false);
  const movePage = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [edbData, setEdbData] = useState([]);
  const [isAdd, setIsAdd] = useState(false);
  const [isChange, setIsChange] = useState(false);

  function onClickAdd() {
    // 전광판 등록 버튼 클릭 시
    // 토큰 확인
    tokenCheck().then((result) => {
      if (!result) {
        alert("인증 토큰이 만료되었습니다. 다시 로그인 해주세요");
        movePage("/");
      } else {
        setIsAdd(true);
      }
    });
  }

  useEffect(() => {
    dispatch(setMenu(PAGE_VALUE));
    dispatch(setSubmenu(SUBPAGE_VALUE));

    // 토큰 확인
    tokenCheck().then((result) => {
      if (!result) {
        alert("인증 토큰이 만료되었습니다. 다시 로그인 해주세요");
        movePage("/");
      } else {
        setIsToken(true);
        setIsLoading(true);
        getEdboard().then((response) => {
          setIsLoading(false);
          setEdbData(response);
        });
      }
    });
  }, []);
  useEffect(() => {
    if (isChange) {
      setIsLoading(true);
      getEdboard().then((response) => {
        setIsLoading(false);
        setIsChange(false);

        setEdbData(response);
      });
    }
  }, [isChange]);

  return (
    <div className="outlet_container">
      {
        // 로딩화면
        isLoading && <Loading isOpen={isLoading} />
      }
      {
        //전광판 등록
        isAdd && (
          <EdboardAdd
            isOpen={isAdd}
            setIsOpen={setIsAdd}
            setIsChange={setIsChange}
          />
        )
      }
      {isToken && (
        <div id="setting-edboard-page">
          <div className="header_container">
            <div className="title">
              <div className="title_imp">전광판</div>
              <div className="title_nor">설정</div>
            </div>
            <div className="path">
              <FontAwesomeIcon icon={faHome} />
              <div className="text">&gt; 설정 &gt; 전광판</div>
            </div>
          </div>

          <div className="main_container">
            <div className="setting-edboard-container contents_container">
              <div className="contents_header">
                <div className="title">
                  <FontAwesomeIcon icon={faList} />
                  <div className="text">전광판</div>
                  <div
                    className="button_imp edb-add-button"
                    onClick={onClickAdd}
                  >
                    전광판 등록
                  </div>
                </div>
              </div>

              <div className="contents_main">
                <EdboardList edbData={edbData} setIsChange={setIsChange} />
              </div>

              <div className="contents_footer"></div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default SettingEdboard;
