import React, { useState, useEffect } from "react";
import {
  ResponsiveContainer,
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  ReferenceLine,
  CartesianGrid,
} from "recharts";

import { DEVICEOPTIONS } from "../../../../constants/deviceOptions";

function SlopeGraph({ selected }) {
  const [data, setData] = useState([]);
  const [minMax, setMinMax] = useState({ min: 999999, max: -999999 });
  const [alertLine, setAlertLine] = useState([]);
  const colors = [
    "#0B8A31", // 초록
    "#CA09D5", // 분홍
    "#4600B8", // 보라
    "#82C313", // 연두
    "#0042B8", // 파랑
    "#D1575A", // 연한빨강
    "#13C3BD", // 청록
  ];

  /* AlertLine Custom */
  const AlertCustomLabelUp = (props) => {
    const x = props.viewBox.x;
    const y = props.viewBox.y;
    const value = props.value;
    const color = props.color;
    return (
      <text
        x={x + 5}
        y={y - 5}
        textAnchor="start"
        fill={color}
        fontSize={10}
        fontWeight="bold"
      >
        {value}
      </text>
    );
  };
  const AlertCustomLabelDown = (props) => {
    const x = props.viewBox.x;
    const y = props.viewBox.y;
    const value = props.value;
    const color = props.color;
    return (
      <text
        x={x + 5}
        y={y + 12}
        textAnchor="start"
        fill={color}
        fontSize={10}
        fontWeight="bold"
      >
        {value}
      </text>
    );
  };
  /* Tooltip Custom */
  const TooltipCustom = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      const date =
        payload[0].payload.date === undefined ? "" : payload[0].payload.date;

      return (
        <div className="tooltip-container">
          <div className="date-text">{date}</div>
          <div className="value-container">
            {payload.map((item) => (
              <div key={`value-item-${item.dataKey}`} className="value-item">
                <div
                  className="value-color"
                  style={{ background: item.color }}
                />
                <div className="value-title">{item.dataKey}:</div>
                <div className="value">{item.payload[item.dataKey]}</div>
              </div>
            ))}
          </div>
        </div>
      );
    }
  };

  useEffect(() => {
    const slopeRtus = selected.object;

    let integratedData = [];
    let max = -999999,
      min = 999999;
    let alertLines = [];
    slopeRtus.forEach((slopeRtu) => {
      const targetData = slopeRtu.data;
      const targetDvcData = slopeRtu.dvcData;

      // 데이터 (합치기)
      if (Array.isArray(targetData) && targetData.length > 0) {
        if (integratedData.length === 0) {
          integratedData = [...targetData];
        } else {
          const newData = integratedData.map((dataItemI) => {
            const match = targetData.find(
              (dataItem) => dataItem.date === dataItemI.date
            );
            return {
              ...dataItemI,
              ...match,
            };
          });
          integratedData = newData;
        }
      }

      // 최소&최대값 찾기
      targetData.forEach((dataItem) => {
        for (let key in dataItem) {
          if (key !== "date") {
            if (dataItem[key] !== "-") {
              max = max < Number(dataItem[key]) ? Number(dataItem[key]) : max;
              min = min > Number(dataItem[key]) ? Number(dataItem[key]) : min;
            }
          }
        }
      });

      // 임계치
      if (Array.isArray(targetDvcData) && targetDvcData.length > 0) {
        targetDvcData.forEach((dvcItem) => {
          const alert = {
            unit: "degree",
            max: null,
            max2: null,
            max3: null,
            max4: null,
            min: null,
            min2: null,
            min3: null,
            min4: null,
          };
          alert.max =
            dvcItem.max !== DEVICEOPTIONS.sensorAnomaly ? dvcItem.max : null;
          alert.max2 =
            dvcItem.max2 !== DEVICEOPTIONS.sensorAnomaly ? dvcItem.max2 : null;
          alert.max3 =
            dvcItem.max3 !== DEVICEOPTIONS.sensorAnomaly ? dvcItem.max3 : null;
          alert.max4 =
            dvcItem.max4 !== DEVICEOPTIONS.sensorAnomaly ? dvcItem.max4 : null;
          alert.min =
            dvcItem.min !== DEVICEOPTIONS.sensorAnomaly ? dvcItem.min : null;
          alert.min2 =
            dvcItem.min2 !== DEVICEOPTIONS.sensorAnomaly ? dvcItem.min2 : null;
          alert.min3 =
            dvcItem.min3 !== DEVICEOPTIONS.sensorAnomaly ? dvcItem.min3 : null;
          alert.min4 =
            dvcItem.min4 !== DEVICEOPTIONS.sensorAnomaly ? dvcItem.min4 : null;
          alertLines.push(alert);
        });
      }
    });

    // 데이터 ("-" => null)
    const nullData = integratedData.map((dataItem) => {
      const tempDataItem = { ...dataItem };
      for (let key in tempDataItem) {
        if (key !== "date") {
          if (tempDataItem[key] === "-") {
            tempDataItem[key] = null;
          }
        }
      }
      return tempDataItem;
    });
    setData(nullData);
    // 최대값
    setMinMax({ min: Math.round(min) - 2, max: Math.round(max) + 2 });
    // 임계치
    setAlertLine(alertLines);
  }, [selected]);

  return (
    <div className="data-graph-contents">
      {data.length > 0 && (
        <ResponsiveContainer width="100%" height="90%">
          <LineChart
            data={data}
            margin={{ left: -20, right: 20, top: 10, bottom: -10 }}
          >
            <CartesianGrid
              stroke="#595959"
              strokeWidth={0.5}
              vertical={false}
            />
            <XAxis dataKey="date" tick={false} axisLine={false} />
            <YAxis
              type="number"
              domain={[minMax.min, minMax.max]}
              tick={{ fontSize: 11 }}
              tickCount={10}
              axisLine={false}
            />
            <Tooltip
              content={<TooltipCustom />}
              filterNull={true}
              cursor={{
                stroke: "#757575",
                strokeWidth: 1,
                strokeDasharray: "3 3",
              }}
            />

            {Object.keys(data[0])
              .filter((key) => key !== "date")
              .map((key, index) => (
                <Line
                  key={key}
                  type="monotone"
                  dataKey={key}
                  stroke={colors[index % colors.length]}
                  strokeWidth={2}
                  dot={false}
                  activeDot={{ r: 4 }}
                />
              ))}

            {alertLine.length > 0 &&
              alertLine.map((alertItem, index) => (
                <React.Fragment key={`${index}-alert`}>
                  {/* 최소 */}
                  <ReferenceLine
                    y={alertItem.min}
                    label={
                      <AlertCustomLabelDown
                        value={`관심(${alertItem.min}) ${alertItem.unit}`}
                        color="#0F80B1"
                      />
                    }
                    stroke="#006B99"
                    strokeDasharray="7 4"
                    strokeWidth={0.5}
                  />
                  <ReferenceLine
                    y={alertItem.min2}
                    label={
                      <AlertCustomLabelDown
                        value={`주의(${alertItem.min2}) ${alertItem.unit}`}
                        color="#A49506"
                      />
                    }
                    stroke="#928400"
                    strokeDasharray="7 4"
                    strokeWidth={0.5}
                  />
                  <ReferenceLine
                    y={alertItem.min3}
                    label={
                      <AlertCustomLabelDown
                        value={`경계(${alertItem.min3}) ${alertItem.unit}`}
                        color="#B9640A"
                      />
                    }
                    stroke="#A15300"
                    strokeDasharray="7 4"
                    strokeWidth={0.5}
                  />
                  <ReferenceLine
                    y={alertItem.min4}
                    label={
                      <AlertCustomLabelDown
                        value={`심각(${alertItem.min4}) ${alertItem.unit}`}
                        color="#A00B0B"
                      />
                    }
                    stroke="#8F0303"
                    strokeDasharray="7 4"
                    strokeWidth={0.5}
                  />
                  {/* 최대 */}
                  <ReferenceLine
                    y={alertItem.max}
                    label={
                      <AlertCustomLabelUp
                        value={`관심(${alertItem.max}) ${alertItem.unit}`}
                        color="#0F80B1"
                      />
                    }
                    stroke="#006B99"
                    strokeDasharray="7 4"
                    strokeWidth={0.5}
                  />
                  <ReferenceLine
                    y={alertItem.max2}
                    label={
                      <AlertCustomLabelUp
                        value={`주의(${alertItem.max2}) ${alertItem.unit}`}
                        color="#A49506"
                      />
                    }
                    stroke="#928400"
                    strokeDasharray="7 4"
                    strokeWidth={0.5}
                  />
                  <ReferenceLine
                    y={alertItem.max3}
                    label={
                      <AlertCustomLabelUp
                        value={`경계(${alertItem.max3}) ${alertItem.unit}`}
                        color="#B9640A"
                      />
                    }
                    stroke="#A15300"
                    strokeDasharray="7 4"
                    strokeWidth={0.5}
                  />
                  <ReferenceLine
                    y={alertItem.max4}
                    label={
                      <AlertCustomLabelUp
                        value={`심각(${alertItem.max4}) ${alertItem.unit}`}
                        color="#A00B0B"
                      />
                    }
                    stroke="#8F0303"
                    strokeDasharray="7 4"
                    strokeWidth={0.5}
                  />
                </React.Fragment>
              ))}
          </LineChart>
        </ResponsiveContainer>
      )}
    </div>
  );
}

export default SlopeGraph;
