import { useState } from "react";
import Modal from "react-modal";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faX } from "@fortawesome/free-solid-svg-icons";

import { MODALSTYLES } from "../../constants/modalStyles";
import { findId } from "../../services/internal/userAPI";
import Alert from "../common/window/Alert";

Modal.setAppElement("#root");

function FindId({ isOpen, setIsOpen, setIsFindId, setFindIdList }) {
  const [findInform, setFindInform] = useState({
    userNm: "",
    cellNum: "",
  });
  const [isInputAlert, setIsInputAlert] = useState(false);
  const [isFindAlert, setIsFindAlert] = useState(false);

  function onClickFind() {
    // 아이디 찾기
    if (findInform.userNm === "" || findInform.cellNum === "") {
      // 정보 입력x
      setIsInputAlert(true);
    } else {
      // 정보 입력O
      findId(findInform).then((response) => {
        if (response.success) {
          // 아이디 찾음
          const message = response.message;
          const regex = /\[([^\]]+)\]/;

          const match = message.match(regex);

          // 배열로 변환
          let arr = [];
          if (match && match[1]) {
            arr = match[1].split(",").map((item) => item.trim()); // 각 항목의 공백 제거
          }

          setFindIdList(arr);
          setIsFindId(true);
          setIsOpen(false);
        } else {
          // 아이디 없음 || 잘못된 정보
          setIsFindAlert(true);
        }
      });
    }
  }
  function onClickClose() {
    // 닫기
    setIsOpen(false);
  }

  return (
    <Modal isOpen={isOpen} style={MODALSTYLES.findId}>
      {
        // 정보입력x
        isInputAlert && (
          <Alert
            isOpen={isInputAlert}
            setIsOpen={setIsInputAlert}
            text="모든 정보를 입력해주세요"
          />
        )
      }
      {
        // 아이디 없음 || 잘못된 정보
        isFindAlert && (
          <Alert
            isOpen={isFindAlert}
            setIsOpen={setIsFindAlert}
            text="입력하신 정보로 등록된 아이디가 없습니다."
          />
        )
      }
      <div id="find-id-modal">
        <div className="find-id-header">
          <div className="title">아이디 찾기</div>

          <FontAwesomeIcon
            className="close-button"
            icon={faX}
            onClick={onClickClose}
          />
        </div>

        <div className="find-id-main">
          <div className="input-container">
            <input
              type="text"
              placeholder="이름 입력"
              value={findInform.userNm}
              onChange={(e) => {
                const newValue = e.target.value.replace(/\s/g, "");
                setFindInform({ ...findInform, userNm: newValue });
              }}
            />
            <input
              className="input-margin"
              type="number"
              placeholder="휴대번호 입력(숫자만 입력)"
              value={findInform.cellNum}
              onChange={(e) =>
                setFindInform({ ...findInform, cellNum: e.target.value })
              }
            />
          </div>

          <div className="button-imp button" onClick={onClickFind}>
            아이디
            <br />
            찾기
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default FindId;
