import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setMenu, setSubmenu } from "../../../redux/modules/menu";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome, faList } from "@fortawesome/free-solid-svg-icons";

import { tokenCheck } from "../../../utils/tokenCheck";
import { getRtuDevice } from "../../../services/internal/rtuAPI";
import Loading from "../../../components/common/window/Loading";
import RtuList from "../../../components/setting/device/rtu/RtuList";
import DeviceList from "../../../components/setting/device/device/DeviceList";
import RtuAdd from "../../../components/setting/device/rtu/RtuAdd";
import DeviceAdd from "../../../components/setting/device/device/DeviceAdd";

const PAGE_VALUE = "설정";
const SUBPAGE_VALUE = "센서/방송장비";

function SettingDevice() {
  const dispatch = useDispatch();
  const movePage = useNavigate();
  const [isToken, setIsToken] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [rtuData, setRtuData] = useState([]);
  const [selected, setSelected] = useState({});
  const [isRtuAdd, setIsRtuAdd] = useState(false); // rtu
  const [isDvcAdd, setIsDvcAdd] = useState(false); // device
  const [isChange, setIsChange] = useState(false);

  function onClickRtuAdd() {
    // RTU 추가 버튼 클릭
    // 토큰 확인
    tokenCheck().then((result) => {
      if (!result) {
        alert("인증 토큰이 만료되었습니다. 다시 로그인 해주세요");
        movePage("/");
      } else {
        setIsRtuAdd(true);
      }
    });
  }
  function onClickDvcAdd() {
    // 장비 추가 버튼 클릭
    // 토큰 확인
    tokenCheck().then((result) => {
      if (!result) {
        alert("인증 토큰이 만료되었습니다. 다시 로그인 해주세요");
        movePage("/");
      } else {
        setIsDvcAdd(true);
      }
    });
  }

  useEffect(() => {
    dispatch(setMenu(PAGE_VALUE));
    dispatch(setSubmenu(SUBPAGE_VALUE));

    // 토큰 확인
    tokenCheck().then((result) => {
      if (!result) {
        alert("인증 토큰이 만료되었습니다. 다시 로그인 해주세요");
        movePage("/");
      } else {
        setIsToken(true);
        setIsLoading(true);
        getRtuDevice().then((response) => {
          setIsLoading(false);
          if (response.length !== 0) {
            setRtuData(response);
            setSelected(response[0]);
          } else {
            setRtuData([]);
            setSelected({});
          }
        });
      }
    });
  }, []);
  useEffect(() => {
    if (isChange) {
      setIsLoading(true);
      getRtuDevice().then((response) => {
        setIsLoading(false);
        if (response.length !== 0) {
          setRtuData(response);
          setSelected(response[0]);
        } else {
          setRtuData([]);
          setSelected({});
        }

        if (isChange) {
          setIsChange(false);
        }
      });
    }
  }, [isChange]);

  return (
    <div className="outlet_container">
      <div id="setting-device-page">
        {
          // 로딩화면
          isLoading && <Loading isOpen={isLoading} />
        }
        {
          // RTU 추가
          isRtuAdd && (
            <RtuAdd
              isOpen={isRtuAdd}
              setIsOpen={setIsRtuAdd}
              setIsChange={setIsChange}
            />
          )
        }
        {
          // 장비 추가
          isDvcAdd && (
            <DeviceAdd
              selected={selected}
              isOpen={isDvcAdd}
              setIsOpen={setIsDvcAdd}
              setIsChange={setIsChange}
            />
          )
        }
        {isToken && (
          <>
            <div className="header_container">
              <div className="title">
                <div className="title_imp">센서/방송장비</div>
                <div className="title_nor">설정</div>
              </div>
              <div className="path">
                <FontAwesomeIcon icon={faHome} />
                <div className="text">&gt; 설정 &gt; 센서/방송장비</div>
              </div>
            </div>

            <div className="main_container_row">
              {/* RTU */}
              <div className="contents_container rtu">
                <div className="contents_header">
                  <div className="title">
                    <FontAwesomeIcon icon={faList} />
                    <div className="text">RTU</div>
                    <div
                      className="button_imp rtu-add-button"
                      onClick={onClickRtuAdd}
                    >
                      RTU 등록
                    </div>
                  </div>
                </div>
                <div className="contents_main">
                  <RtuList
                    rtuData={rtuData}
                    setIsChange={setIsChange}
                    selected={selected}
                    setSelected={setSelected}
                  />
                </div>
                <div className="contents_footer"></div>
              </div>

              {/* 센서/방송장비 */}
              <div className="contents_container sensor-broad">
                <div className="contents_header">
                  <div className="title">
                    <FontAwesomeIcon icon={faList} />
                    <div className="text">센서/방송장비</div>
                    {Object.keys(selected).length !== 0 && (
                      <div
                        className="button_imp device-add-button"
                        onClick={onClickDvcAdd}
                      >
                        장비 등록
                      </div>
                    )}
                  </div>
                </div>
                <div className="contents_main">
                  <DeviceList selected={selected} setIsChange={setIsChange} />
                </div>
                <div className="contents_footer"></div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default SettingDevice;
